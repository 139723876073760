import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ComparisionTableLift from './comparisonTableSection/ComparisionTableLift.component';
import AttributableVsIncrementalComponent from './attributableVsIncremental/AttributableVsIncremental.component';
import SummaryHeader from '../../summaryHeader/SummaryHeader.component';
/**import TopIncrementalGoals from './topIncrementalGoals/TopIncrementalGoals.component';*/
import AnalysisComponent from './analysisSection/Analysis.component';
import './liftTab.css';
import TopIncrementalGoals from './topIncrementalGoals/TopIncrementalGoals.component';

const LiftComponent = ({
  campaign,
  setCampaign,
  hasSetCampaign,
  properStartDateString,
  properLookbackDateString,
  setProperLookbackDateString,
  setProperStartDateString,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  haveSetDates,
  setHaveSetDates,
  placement,
  setPlacement,
  defaultPlacement,
  setDefaultPlacement,
  mediaPartner,
  setMediaPartner,
  defaultMediaPartner,
  setDefaultMediaPartner,
  creativeType,
  setCreativeType,
  defaultCreativeType,
  setDefaultCreativeType,
  isDisabled,
  setIsDisabled,
  getReportsDetails,
  isErrorReportDetails,
  isErrorCampaignList,
  isErrorPlacementList,
  getCampaignList,
  getPlacementList,
  campaignList,
  placementList,
  setPlacementList,
  mediaPartnerList,
  setMediaPartnerList,
  creativeTypeList,
  setCreativeTypeList,
  isLoading,
  dateRange,
  setDateRange,
  summaryData,
  setSummaryData,
  ifSelectedAll,
  updateIsLoading,
  getPartnerList,
  getCreativeTypeList,
  isErrorPartnerList,
  isErrorCreativeTypeList,
  presentTimeFrameOptions,
  timeFrame,
  setTimeFrame,
  parametersIsLoading,
  reportName
}) => {
  return (
    <div className='liftOuterWrapper'>
      <SummaryHeader
        campaign={campaign}
        setCampaign={setCampaign}
        properStartDateString={properStartDateString}
        properLookbackDateString={properLookbackDateString}
        setProperLookbackDateString={setProperLookbackDateString}
        setProperStartDateString={setProperStartDateString}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setHaveSetDates={setHaveSetDates}
        placement={placement}
        setPlacement={setPlacement}
        defaultPlacement={defaultPlacement}
        setDefaultPlacement={setDefaultPlacement}
        mediaPartner={mediaPartner}
        setMediaPartner={setMediaPartner}
        defaultMediaPartner={defaultMediaPartner}
        setDefaultMediaPartner={setDefaultMediaPartner}
        creativeType={creativeType}
        setCreativeType={setCreativeType}
        defaultCreativeType={defaultCreativeType}
        setDefaultCreativeType={setDefaultCreativeType}
        isDisabled={{
          campaign: true,
          mediaPartner: true,
          creativeType: true,
          placement: true
        }}
        setIsDisabled={setIsDisabled}
        getReportsDetails={getReportsDetails}
        isErrorReportDetails={isErrorReportDetails}
        isErrorCampaignList={isErrorCampaignList}
        isErrorPlacementList={isErrorPlacementList}
        getCampaignList={getCampaignList}
        getPlacementList={getPlacementList}
        campaignList={campaignList}
        placementList={placementList}
        setPlacementList={setPlacementList}
        mediaPartnerList={mediaPartnerList}
        setMediaPartnerList={setMediaPartnerList}
        creativeTypeList={creativeTypeList}
        setCreativeTypeList={setCreativeTypeList}
        isLoading={isLoading}
        dateRange={dateRange}
        setDateRange={setDateRange}
        labels={[]}
        endpoint={''}
        summaryData={summaryData}
        setSummaryData={setSummaryData}
        ifSelectedAll={ifSelectedAll}
        updateIsLoading={updateIsLoading}
        getPartnerList={getPartnerList}
        getCreativeTypeList={getCreativeTypeList}
        isErrorPartnerList={isErrorPartnerList}
        isErrorCreativeTypeList={isErrorCreativeTypeList}
        presentTimeFrameOptions={presentTimeFrameOptions}
        timeFrame={timeFrame}
        setTimeFrame={setTimeFrame}
        isFromLiftTab
        setNoData={() => {}}
      />
      <Container fluid className='removePaddingOrMargin'>
        <Row className='removePaddingOrMargin'>
          <Col lg={6} md={6} sm={12} className='removePaddingOrMargin'>
            <AttributableVsIncrementalComponent
              campaign={campaign}
              startDate={startDate}
              endDate={endDate}
              placement={placement}
              mediaPartner={mediaPartner}
              creativeType={creativeType}
              ifSelectedAll={ifSelectedAll}
              parametersIsLoading={parametersIsLoading}
            />
          </Col>
          <Col lg={6} md={6} sm={12} className='removePaddingOrMargin'>
            <TopIncrementalGoals
              campaign={campaign}
              startDate={startDate}
              endDate={endDate}
              placement={placement}
              mediaPartner={mediaPartner}
              creativeType={creativeType}
              ifSelectedAll={ifSelectedAll}
              parametersIsLoading={parametersIsLoading}
            />
          </Col>
        </Row>
      </Container>
      <AnalysisComponent campaign={campaign} startDate={startDate} endDate={endDate} />

      <ComparisionTableLift
        campaign={campaign}
        mediaPartner={mediaPartner}
        mediaPartnerList={mediaPartnerList}
        startDate={startDate}
        endDate={endDate}
        parametersIsLoading={{
          campaign: isLoading.campaign,
          mediaPartner: isLoading.mediaPartner,
        }}
        reportName={reportName}
      />
    </div>
  );
};

export default LiftComponent;
