import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  CommonHeadingComponent,
  CommonSearch,
  CommonTablesComponent,
  CommonButtonComponent,
  ErrorComponent,
} from '../../../../components';
import { createColumns } from './placementsList.utils';
import { baseEndpoints } from '../../../../constants';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import ImportButtonComponent from '../../../../components/importButton/ImportButton.component';
import ImportFileModal from '../../../../components/importButton/ImportFileModal.js';
import moment from 'moment';

import './placementsList.css';
import { usePostAPIInterceptor } from '../../../../hooks/postAPIInterceptor.js';
import { axiosPostRequest } from '../../../../services/http/axiosCalls.js';

const PlacementsListComponent = ({ campaignId, campaignName, restatementDetails, getRestatementDetails }) => {
  const client = useSelector((state) => state.client);
  const advertiser = useSelector((state) => state.advertiser);

  const navigate = useNavigate();

  const [isPlacementMounted, setIsPlacementMounted] = useState(false);
  const [placementParams, setPlacementParams] = useState(null);
  const [placementEndpoint, setPlacementEndpoint] = useState(null);
  const [impressionData, setImpressionData] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchInputCall, setSearchInputCall] = useState('');
  const [sortData, setSortData] = useState({
    sort_field: 'placement_id',
    order: 'desc',
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10); // records per page in grid
  const [expandOrCollapse, setExpandOrCollapse] = useState(false)
  const [showImportFileModal, setShowImportFileModal] = useState(false);
  const { data, isLoading, isError } = useGetApiInterceptor(
    isPlacementMounted,
    placementParams,
    placementEndpoint
  ); // States for Advertiser Categories
  const [columns, setColumns] = useState(createColumns(campaignId, navigate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageNumber(1);
      setSearchInputCall(searchInput);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  useEffect(() => {
    setPlacementParams({
      ...sortData,
      search_Param: searchInputCall,
      page_number: pageNumber,
      page_size: pageSize,
    });
    setPlacementEndpoint(`${baseEndpoints.campaigns}/${campaignId}/placements`);
    setIsPlacementMounted(true);
    getRestatementDetails();
  }, [
    campaignId,
    client,
    advertiser,
    pageNumber,
    sortData,
    pageSize,
    searchInputCall,
  ]);

  const getImpressions = async () => {
    try {
      const impressionData = await axiosPostRequest(
        `${baseEndpoints.reports}/media-delivery-tab/impressions-all`,
        {
          campaign_id: campaignId,
          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          placement_ids: data?.table_data?.map((item) => item.placement_id),
          from_placement_list: true,
        }
      );
      if (impressionData) {
        data?.table_data?.map((item) => {
          item.impressions = impressionData.data?.impressions?.filter(
            (impression) => impression.placement_id == item.placement_id
          )[0]?.nonIvt ?? -1;
        });
        setImpressionData(impressionData.data?.impressions);
        setColumns(createColumns(campaignId, navigate));
      }
    } catch(e) {
      console.log(e);
    }
    
  }

  const updateDataWithRestatement = () => {
    const placementsInQueue = 
      restatementDetails?.map((item) => item.placement_id);
    data?.table_data?.map(item => {
      if (placementsInQueue?.includes(item.placement_id)) {
        const restatementStatus = {};
          if (restatementDetails.some(placement => {
          if (item.placement_id === placement.placement_id) {
            return placement.status_id === 0;
          }
        })) {
          restatementStatus.failed = true;
        } else if (restatementDetails.some(placement => {
          if (item.placement_id === placement.placement_id) {
            return placement.status_id !== 4;
          }
        })) {
          restatementStatus.queued = true;
        } else {
          restatementStatus.passed = true;
        }
        item.restatementStatus = restatementStatus;
      }
    })
  }

  useEffect(() => {
    if (data) {
      getImpressions();
      updateDataWithRestatement();
    }
  }, [data]);

  const handleCollapsible = () => {
    setExpandOrCollapse(!expandOrCollapse)
  }

  const handleNewPlacement = () => {
    navigate('placement/new', { state: { campaignName } })
  }

  const onClickImportFile = () => {
    setShowImportFileModal(true);
  }
  
  const renderCollapsableView = () => {
    return (
      <div>
        <div className='campaignListSubheader justify-content-between'>
          <div className='d-flex'>
            <CommonSearch
              placeholder={'Search placements'}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              disabled={advertiser.selectedAdvertiser == -1 ? true : false}
              uniqueIdentifier='campaignDetailsAllPlacements'
            />
          </div>
          <div className='placementButtons'>
            <ImportButtonComponent
              label='Import Placements'
              appendWrapperClassName='importBtnWrapper'
              appendButtonClassName='importBtnBody'
              onClickCTA={onClickImportFile}
              buttonUi='campaignDetailsImportPlacements'
            />
            <ImportFileModal
              show={showImportFileModal}
              open={!showImportFileModal}
              setShowImportFileModal={setShowImportFileModal}
              showImportFileModal={showImportFileModal}
              campaign={campaignId}
              advertiserId={advertiser.selectedAdvertiser}
              clientId={client.selectedClient}
              header={'Import Placements'}
            />
            <CommonButtonComponent
              label='Create Placement'
              appendWrapperClassName='newPlacementBtnWrapper'
              appendButtonClassName='newPlacementBtnBody'
              onClickCTA={handleNewPlacement}
              buttonUI='campaignDetailsCreatePlacement'
            />
          </div>
        </div>
        {isError ? (
          <ErrorComponent refreshAction={() => setParams({ ...params })} />
        ) : (
          <CommonTablesComponent
            uniqueId='placementsList'
            data={data?.table_data ?? []}
            columns={columns}
            isLoading={isLoading}
            customTableHeader='Placements'
            setSortData={(sd) =>
              setSortData({
                ...sortData,
                sort_field: sd.sortField,
                order: sd.order,
              })
            } // sorting
            sortColumnIds={[
              'placement_id',
              'placement_name',
              'start_date',
              'end_date',
              'media_partner',
              'placement_type',
            ]} // sorting
            defaultSortingApplied={{ column: 'placement_id', order: 'asc' }} // sorting
            isPaginationApplied={true}
            totalDataCount={data?.data_count || []}
            pageIndex={pageNumber}
            setPageIndex={setPageNumber}
            dataPerPage={pageSize}
            paginationOptions={{
              noRowsPerPage: false,
            }}
            setDataPerPage={setPageSize}
            noDataMessage='No Placements Available'
            noDataMessageFontSize={15}
            noDataCompWidth='180'
            noDataCompHeight='180'
            foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
          />
        )}
      </div>
    );
  }

  return (
    <div className='placementListWrapper container-fluid'>
      <CommonHeadingComponent
        headingLabel='Placements'
        headingCTALabel={!expandOrCollapse ? 'COLLAPSE' : 'EXPAND'}
        url={null}
        isDisabled={false}
        onClickCTA={handleCollapsible}
        uniqueIdentifier='campaignDetailsAllPlacementsCollapsable'
      />
      {!expandOrCollapse && renderCollapsableView()}
    </div>
  );
};

export default PlacementsListComponent;
