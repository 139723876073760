import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  CommonHeadingComponent,
  MultiselectDropdownComponent,
  ErrorComponent,
  NoDataComponent,
  LoaderComponent,
  CommonButtonComponent,
} from '../../../../../../components';
import {
  checkCondition,
  exportCSV,
  getArrayOfData,
  setPlaceHolderLabel,
  dateFormat,
  checkSelectAll,
  getAllSelectedOrNot,
} from '../../../../../../utils/functions';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import TreeTableList from './TreeTable';
import { baseEndpoints } from '../../../../../../constants';
import './style.css';

//Render comparison table with partner filter
const ComparisionTable = ({
  campaign,
  mediaPartner,
  mediaPartnerList,
  startDate,
  endDate,
  parametersIsLoading,
  reportName,
}) => {
  const [tableHeader, setTableHeader] = useState([]),
    [filteredData, setFilteredData] = useState([]);

  const [partnerList, setPartnerList] = useState(null),
    [selectedPartner, setSelectedPartner] = useState(null);

  const [cancelController, setCancelController] = useState(null),
    [isLoading, setIsLoading] = useState(true),
    [isError, setIsError] = useState(false);

  const [showPartnerDropdown, setShowPartnerDropdown] = useState(false);

  const partnerListRef = useRef(null);

  //Getting the comparison partner list data from the API
  const getPartnersData = async (controller) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const response = await axiosSendRequest(
        `${baseEndpoints.reports}/attribution-tab/comparison`,
        controller.signal,
        {
          campaign_id: campaign?.campaign_id,
          if_all_partners: getAllSelectedOrNot(
            mediaPartnerList,
            selectedPartner,
            'partner_id'
          ),
          partner_id: getArrayOfData(selectedPartner ?? [], 'partner_id'),
          start_date: startDate,
          end_date: endDate,
        }
      );
      if (response?.status === 200) {
        setFilteredData(response.data?.data ?? []);
        setTableHeader(response.data?.headers ?? []);
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err.message);
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      resetData();
    }

    if (selectedPartner?.length && startDate && endDate) {
      const controller = new AbortController();
      setCancelController(controller);
      getPartnersData(controller);

      return () => {
        controller.abort();
      };
    }
  }, [startDate, endDate, selectedPartner]);

  useEffect(() => {
    if (mediaPartner?.length) {
      setPartnerList(mediaPartner);
      setSelectedPartner(mediaPartner);
    } else {
      setPartnerList(null);
      setSelectedPartner(null);
    }
    partnerListRef.current = mediaPartner?.length ? [...mediaPartner] : null;
  }, [mediaPartner]);

  useEffect(() => {
    if (
      !Object.values(parametersIsLoading).includes(true) &&
      !selectedPartner?.length
    ) {
      setIsLoading(false);
    }
  }, [parametersIsLoading]);

  const resetData = () => {
    setTableHeader([]);
    setFilteredData([]);
  };

  return (
    <div className='attrComparisonTable'>
      <div className='racTitle'>
        <CommonHeadingComponent
          headingLabel='Comparison Table'
          headingCTALabel={
            <RenderHeaderRightElement
              campaign={campaign}
              partnerList={partnerList}
              setPartnerList={setPartnerList}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
              partnerListRef={partnerListRef}
              isLoading={isLoading}
              parametersIsLoading={parametersIsLoading}
              filteredData={filteredData}
              tableHeader={tableHeader}
              reportName={reportName}
              startDate={startDate}
              endDate={endDate}
              setShowPartnerDropdown={setShowPartnerDropdown}
              showPartnerDropdown={showPartnerDropdown}
            />
          }
          ifDropdown={true}
        />
      </div>
      <div className='rac-partner-table'>
        <RenderElement
          isLoading={isLoading}
          parametersIsLoading={parametersIsLoading}
          isError={isError}
          getPartnersData={getPartnersData}
          cancelController={cancelController}
          filteredData={filteredData}
          tableHeader={tableHeader}
        />
      </div>
    </div>
  );
};

export default ComparisionTable;

const determineSummaryHeaderLoading = (parametersIsLoading) => {
  const { campaign, mediaPartner } = parametersIsLoading;
  return campaign || mediaPartner;
};

const RenderHeaderRightElement = ({
  campaign,
  partnerList,
  setPartnerList,
  selectedPartner,
  setSelectedPartner,
  partnerListRef,
  isLoading,
  parametersIsLoading,
  filteredData,
  tableHeader,
  reportName,
  startDate,
  endDate,
  setShowPartnerDropdown,
  showPartnerDropdown,
}) => {
  const formattedStartDate = dateFormat(startDate),
    formattedEndDate = dateFormat(endDate);

  return (
    <div className='headerRightElementWrapper'>
      <MultiselectDropdownComponent
        dropdownId='comparisionPartnerSH'
        dropdownDataList={
          determineSummaryHeaderLoading(parametersIsLoading)
            ? null
            : partnerList
        }
        dataId='partner_id'
        dataName='partner_name'
        label=''
        dropdownData={selectedPartner}
        setDropdownData={(item) => {
          if (item?.length) {
            return setSelectedPartner(item);
          }
          setPartnerList(
            partnerListRef.current ? [...partnerListRef.current] : null
          );
        }}
        ifIdSearchAvailable={true}
        placeholder={setPlaceHolderLabel(
          determineSummaryHeaderLoading(parametersIsLoading),
          'Partner'
        )}
        isAllCheckedInitially={checkSelectAll(
          partnerList,
          selectedPartner,
          'partner_id'
        )}
        labelWidth={{ margin: 0 }}
        dropdownWidth={{
          width: '250px',
        }}
        isDisabled={
          !partnerList?.length ||
          isLoading ||
          determineSummaryHeaderLoading(parametersIsLoading)
        }
        uniqueIdentifier='reportDetailsAttributionComparisonTablePartners'
        allSelectedPlaceHolder='All Partners selected'
        txtAll='All Partners'
        txtSearch='Search by Partner'
        isOpen={setShowPartnerDropdown}
      />
      <div className='downloadTagsWrapper'>
        <CommonButtonComponent
          label={checkCondition(
            isLoading || determineSummaryHeaderLoading(parametersIsLoading),
            <LoaderComponent importedClassNames='downloadFilesButtonLoader' />,
            'Export to Excel'
          )}
          appendWrapperClassName='downloadTagsBtnWrapper'
          appendButtonClassName='downloadTagsBtnBody'
          onClickCTA={() =>
            exportCSV(
              filteredData,
              reportName +
                '-Comparison Table-' +
                formattedStartDate +
                '-' +
                formattedEndDate,
              'attrPartnerComparison',
              tableHeader
            )
          }
          isDisabled={
            !partnerList?.length ||
            isLoading ||
            showPartnerDropdown ||
            determineSummaryHeaderLoading(parametersIsLoading)
          }
          buttonUI='attrPartnerComparison'
        />
      </div>
    </div>
  );
};

const RenderElement = ({
  isLoading,
  parametersIsLoading,
  isError,
  getPartnersData,
  cancelController,
  filteredData,
  tableHeader,
}) => {
  if (isLoading || determineSummaryHeaderLoading(parametersIsLoading)) {
    return <LoaderComponent headerClass='attrComparisonLoaderWrapper' />;
  }

  if (isError) {
    return (
      <ErrorComponent
        refreshAction={getPartnersData}
        controller={cancelController}
      />
    );
  }

  if (!filteredData?.length) {
    return (
      <NoDataComponent
        type='table'
        width='180'
        height='180'
        messageTextFontSize={15}
        foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
        messageText='No Data Available'
      />
    );
  }

  return <TreeTableList tableData={filteredData} headers={tableHeader} />;
};
