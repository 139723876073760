import React from 'react';
import { LineChart, Line, XAxis, YAxis } from 'recharts';
import Moment from 'moment';
import { dateFormat, formatNumberInThousands } from '../../utils/functions';
import aaColors from '../../constants/colors';

const CustomizedTicksDate = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor='middle'
        color={aaColors.darkGray}
        fontWeight={600}
        fontSize={12}
      >
        {dateFormat(payload.value, 'MMM DD')}
      </text>
    </g>
  );
};

const getCustomizedLabel = (props) => {
  const { x, y, value } = props;
  return (
    <text
      x={x}
      y={y}
      dy={-10}
      fontSize={14}
      textAnchor='middle'
      fontWeight={600}
      color={aaColors.black}
    >
      {formatNumberInThousands(value)}
    </text>
  );
};

const TooltipTrendChartComponent = ({
  chartData,
  startDate = '2022-11-28',
  endDate = '2022-12-01',
  dotRadius = 5,
  strokeColor = aaColors.lightGray,
  dotColors = { active: aaColors.blue, inactive: aaColors.red },
}) => {
  const getCustomizedDot = React.useCallback((props) => {
    const { key, cx, cy, payload } = props;
    return (
      <circle
        key={key}
        r={dotRadius}
        stroke={aaColors.darkGray}
        strokeWidth='1'
        fill={`${
          Moment(payload.date).diff(Moment(startDate).format('YYYY-MM-DD')) >= 0 &&
          Moment(payload.date).diff(Moment(endDate).format('YYYY-MM-DD')) <= 0
            ? dotColors.active
            : dotColors.inactive
        }`}
        cx={cx}
        cy={cy}
        className='recharts-dot recharts-line-dot'
      />
    );
  });

  return (
    <LineChart
      width={510}
      height={120}
      data={chartData}
      margin={{
        top: 25,
        right: 38,
        left: 37,
        bottom: 0,
      }}
    >
      <XAxis
        dataKey='date'
        axisLine={true}
        width={1}
        interval={0}
        tick={<CustomizedTicksDate />}
      />
      <YAxis
        axisLine={false}
        width={0}
        opacity='0'
        style={{ display: 'none' }}
      />
      <Line
        type='linear'
        dataKey='impressions'
        stroke={strokeColor}
        strokeWidth={2}
        dot={getCustomizedDot}
        label={getCustomizedLabel}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

export default TooltipTrendChartComponent;
