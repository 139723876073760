import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { upcomingTableColumns } from './upcomingCampaigns.utils';
import {
  CommonHeadingComponent,
  CommonTablesComponent,
  ErrorComponent,
} from '../../../../components';
import { baseEndpoints } from '../../../../constants';
import { removeTableClass, setAdvertiserId } from '../../../../utils/functions';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import { baseInputParams } from '../';

import './upcomingCampaigns.css';

const UpcomingCampaignsComponent = () => {
  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser),
    role = useSelector((state) => state.user.role);

  const [params, setParams] = useState(null),
    [isMounted, setIsMounted] = useState(false),
    [inputParams, setInputParams] = useState(null),
    [totalData, setTotalData] = useState(0);

  const { data, dataCount, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    `${baseEndpoints.campaigns}/upcoming`
  );

  useEffect(() => {
    if (inputParams) {
      const advertiserId = setAdvertiserId(advertiser.selectedAdvertiser, advertiser.selectedClientsAdvertiser, role);

      setParams({
        timezone: null,
        user_id: client.selectedClient === -1 ? null : client.selectedClient,
        advertiser_id: advertiserId,
        ...inputParams,
      });
      setIsMounted(true);
    }
  }, [inputParams]);

  useEffect(() => {
    setInputParams({ ...baseInputParams });
  }, [client.selectedClient, advertiser.selectedAdvertiser]);

  useEffect(() => {
    setTotalData(dataCount);
    removeTableClass('.upcomingCampaignsWrapper');
  }, [data]);

  return (
    <div className='upcomingCampaignsWrapper'>
      <CommonHeadingComponent headingLabel='Upcoming Campaigns' />
      {isError ? (
        <ErrorComponent
          refreshAction={getUpcomingCampaignsData}
          controller={cancelController}
          status={status}
        />
      ) : (
        <CommonTablesComponent
          uniqueId='upcomingCampaigns'
          data={data}
          columns={upcomingTableColumns}
          isLoading={isLoading}
          customTableHeader='Upcoming Campaigns'
          isPaginationApplied={true}
          totalDataCount={totalData}
          pageIndex={inputParams?.page_number}
          setPageIndex={(page_number) =>
            setInputParams({ ...inputParams, page_number })
          }
          dataPerPage={inputParams?.page_size}
          noDataMessage='No Upcoming Campaigns'
          noDataMessageFontSize={15}
          noDataCompWidth='180'
          noDataCompHeight='180'
          foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
          showColumnShowHide={false}
        />
      )}
    </div>
  );
};

export default UpcomingCampaignsComponent;
