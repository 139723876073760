/**functions to return routes based on roles */

const routeArray = (data) => {
  return Array.isArray(data) && data.length;
};

export const getAllowedRoutes = (routes, roles) => {
  return routes.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    } else if (!routeArray(permissions)) {
      return true;
    } else {
      return permissions.includes(roles);
    }
  });
};
