import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { Divider, withAuthenticator } from "@aws-amplify/ui-react";
import { configureAmplify } from "./services";
import { useDispatch, useSelector } from "react-redux";
import { update } from "./redux/userSlice";
import { updateSelectedClient, updateClientList } from "./redux/clientSlice";
import {
  updateAdvertiserList,
  updateSelectedClientsAdvertiser,
  updateSelectedAdvertiser,
} from "./redux/advertiserSlice";
import {
  FooterComponent,
  GlobalUtilityBar,
  HeaderComponent,
} from "./components/utilityBar";
import { ErrorComponent, LoaderComponent } from "./components";
import { axiosGetRequest, axiosPostRequest } from "./services/http/axiosCalls";
import {
  dataHandlingForLS,
  getEncryptedOrDecryptedData,
  getSortedArrayOfObj,
  reportExpirationTime,
} from "./utils/functions";
import { ReactNotifications } from "react-notifications-component";
import CreateRoute from "./routes/CreateRoute";

import "bootstrap/dist/css/bootstrap.min.css";
import "@aws-amplify/ui-react/styles.css";
import "./styles/custom.css";
import "react-notifications-component/dist/theme.css";
import { Login } from "./pages";
import endpoints, { baseEndpoints } from "./constants/endpoints";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

configureAmplify();

const App = () => {
  const username = localStorage.getItem("username");
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(200);
  const [isError, setIsError] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [haveSetShowLogin, setHaveSetShowLogin] = useState(false);
  const [authenticatedNotCreated, setAuthenticatedNotCreated] = useState(true);
  const blockingLoader = useSelector((state) => state.blockingLoader);

  const showUtilityBar = useSelector(
    (state) => state.utilitybarMode.showUtilityBar
  );

  const dispatch = useDispatch();

  const signOut = async () => {
    try {
      // const signOutRequest = await axiosGetRequest(endpoints.authLogout);

      // if (signOutRequest.status === 200) {
      //   window.location.reload();
      //   window.location.pathname = "/";
      // }

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
    
  }

  const accessTokenCheck = async () => {
    try {
      const checkTokenRequest = await axiosGetRequest(baseEndpoints.auth);
      return checkTokenRequest.status === 200;
    } catch (err) {
      return false;
    }
  };

  const refreshTokenCheck = async () => {
    try {
      const refreshTokenRequest = await axiosPostRequest(endpoints.authRefresh, {
        username: localStorage.getItem("username"),
        refreshToken: localStorage.getItem('refreshToken'),
      });
      if (refreshTokenRequest.status === 200) {
        localStorage.setItem('accessToken', refreshTokenRequest.data.accessToken);
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  const getUserInfo = async (controller) => {
    try {
      setIsLoading(true);
      const data = await axiosGetRequest(
        `${baseEndpoints.users}/info`,
        controller.signal,
        { email: username }
      );

      if (data.status === 200) {
        let { user_info, client_list, advertiser_list } = data.data;
        advertiser_list = getSortedArrayOfObj(advertiser_list, "name");
        client_list.forEach((item) => {
          advertiser_list = [
            { id: 0, name: 'All', user_id: item.user_id, status: 2 },
            ...advertiser_list,
          ];
        });
        dispatch(update({ ...user_info }));
        dispatch(
          updateClientList(getSortedArrayOfObj(client_list, "organization"))
        );
        dispatch(updateAdvertiserList(advertiser_list));
        setDefaultAdvertiser(advertiser_list, client_list, user_info);
      } else {
        setIsError(true);
        if (status === 403) setAuthenticatedNotCreated(true);
      }
    } catch (err) {
      ifError(err, !axios.isCancel(err), setStatus);
      setIsError(true);
      if (status === 403) setAuthenticatedNotCreated(true);
    } finally {
      setIsLoading(false);
    }
  };

  const authenticatedNotCreatedXML = (
    <div className='authenticatedNotCreated'>
      You Have Been Authenticated But Not Created In the Application.<br/>
      <div className='authenticatedNotCreatedSignOut' onClick={signOut}>Sign Out</div>
    </div>
  );

  const setDefaultAdvertiser = (advertisers, clients, userInfo) => {
    if (userInfo.default_adv < 0) {
      dispatch(updateSelectedClient(Math.abs(userInfo.default_adv)));
      dispatch(updateSelectedAdvertiser(0));
      dispatch(
        updateSelectedClientsAdvertiser({
          userId: Math.abs(userInfo.default_adv),
          advertiserList: advertisers,
        })
      );
    } else {
      let advertiserFound = advertisers.find(
        (advt) => advt.id == userInfo.default_adv
      );
      if (advertiserFound) {
        let clientFound = clients.find(
          (client) => client.user_id == advertiserFound.user_id
        );
        if (clientFound) {
          dispatch(updateSelectedClient(clientFound.user_id));
          dispatch(
            updateSelectedClientsAdvertiser({
              userId: advertiserFound.user_id,
              advertiserList: advertisers,
            })
          );
          dispatch(updateSelectedAdvertiser(advertiserFound.id));
        }
      } else {
        dispatch(updateSelectedClient(userInfo.user_id));
        dispatch(updateSelectedAdvertiser(0));
        dispatch(
          updateSelectedClientsAdvertiser({
            userId: userInfo.user_id,
            advertiserList: advertisers,
          })
        );
      }
    }
  };

  const tokenCheck = async () => {

    const tokenValidity = await accessTokenCheck();
    if (!tokenValidity) {
      const refreshTokenValidity = await refreshTokenCheck();
      if (refreshTokenValidity) {
        setHaveSetShowLogin(true);
        setShowLogin(false);
      } else {
        setHaveSetShowLogin(true);
        setShowLogin(true);
      } 
      setIsLoading(false);
      return;
    }
    setHaveSetShowLogin(true);
    setShowLogin(false);
    setIsLoading(false);
  };

  if (!haveSetShowLogin) {
    tokenCheck();
  }

  useEffect(() => {
    if (!showLogin && haveSetShowLogin) {
      slidingExpirationFunction();
      setInterval(() => {
        slidingExpirationFunction();
      }, reportExpirationTime);
    }
  }, [showLogin, haveSetShowLogin]);

  useEffect(() => {
    const controller = new AbortController();
    if (!showLogin && haveSetShowLogin) {
      getUserInfo(controller);
    }

    if (showLogin && (window.location.pathname !== '/' && window.location.pathname !== '/reset-password')) {
      window.location.pathname = '/';
    }

    return () => {
      controller.abort();
    };
  }, [showLogin, haveSetShowLogin]);

  return isLoading ? (
    <div className="pageLoaderWrapper">
      <LoaderComponent />
    </div>
  ) : (
    <div className="root">
      {!isError ? (
        <Router>
          <ReactNotifications />
          {showLogin ? (
            <React.Fragment>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Login
                      setShowLogin={setShowLogin}
                      setHaveSetShowLogin={setHaveSetShowLogin}
                    />
                  }
                />
                <Route path="/reset-password" element={<ResetPassword />} />
              </Routes>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <HeaderComponent />
              <GlobalUtilityBar signOut={signOut} />
              <Outlet />
              <div
                className={`elementWrapper${
                  !showUtilityBar ? " elementWrapperWithNoUtilityBar" : ""
                }`}
              >
                <CreateRoute />
              </div>
              <FooterComponent />
            </React.Fragment>
          )}
        </Router>
      ) : (
        <div className="pageErrorWrapper">
          <ErrorComponent ifNotAvailable={authenticatedNotCreated} expiredMessage={authenticatedNotCreatedXML} status={status} />
        </div>
      )}
      {blockingLoader.active ? (
        <div className="pageLoaderWrapper overlay">
          <LoaderComponent />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default App;

const slidingExpirationFunction = () => {
  const lsAll = localStorage.getItem("AllReports"),
    timestamp = new Date().getTime();

  if (lsAll) {
    const decryptedAllLs = JSON.parse(
      getEncryptedOrDecryptedData(lsAll, "decrypt")
    );

    if (decryptedAllLs.length > 0) {
      const allDeletingReports = decryptedAllLs.filter((item) => {
        return timestamp - item.timestamp >= reportExpirationTime;
      });

      if (allDeletingReports.length > 0) {
        dataHandlingForLS({
          type: "removeElementsFromAllReports",
          nameAll: "AllReports",
          removingElements: allDeletingReports.map((item) => item.name),
        });
        allDeletingReports.forEach((item) => {
          const ls = localStorage.getItem(item.name);
          if (ls) {
            dataHandlingForLS({
              type: "deleteAReport",
              nameReport: item.name,
            });
          }
        });
      }
    }
  }
};

const ifError = (err, notCanceled, setStatus) => {
  if (notCanceled) {
    if (err.response) {
      setStatus(err.response.status);
    } else {
      setStatus(500);
    }
  }
};
