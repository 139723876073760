import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { axiosGetRequest } from '../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../constants';
import { timeFrameDropdownImpressionsDashboard } from '../../../../constants/dropdownLabels';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  LineChart,
  Line,
} from 'recharts';
import {
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  DatePickerComponent,
  CommonButtonComponent,
  SearchableDropdownComponent,
  NoDataComponent,
} from '../../../../components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  approximateNumberToMils,
  getCurrentESTDate,
  getStartAndEndDateTimeFrameImpressionsDashboard,
  checkCondition,
  daysToFormatWRTDates,
} from '../../../../utils/functions';
import { range, set, update } from 'lodash';
import '../../dashboard.css';

const timeFrameDropdownOptions = [...timeFrameDropdownImpressionsDashboard];

export default function ImpressionsVsSpend() {
  const defaultDateFormat = 'YYYY-MM-DD';
  const currentDate = getCurrentESTDate(defaultDateFormat);
  const advertiser = useSelector((state) => state.advertiser);
  const client = useSelector((state) => state.client);

  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState(200);

  // States for Graph Timeframe
  const [numberOfDays, setNumberOfDays] = useState(timeFrameDropdownOptions[5]);
  const [isClicked, setIsClicked] = useState(false);

  const [disableApplyButton, setDisableApplyButton] = useState(true); //state update for apply button conditionaly (enable, disable)
  const [disableResetButton, setDisableResetButton] = useState(true); //state update for reset button conditionaly (enable, disable)
  const [currentSelectedView, setCurrentSelectedView] = useState(
    timeFrameDropdownOptions[5]
  ); //state update to maintain / get current selected value

  const updateGraphData = () => {
    setDisableResetButton(true); //disable reset button once clicked on Apply button
    setDisableApplyButton(true); //disable apply button once clicked on Apply button
    setNumberOfDays(currentSelectedView); //set number of days value to current selected value
    setStartDate(currentStartDate); //set start date value to current selected value
    setEndDate(currentEndDate); //set end date value to current selected value
    setIsClicked(!isClicked);
  };

  const resetGraphData = () => {
    setCurrentSelectedView(numberOfDays); //set number of days value to previously selected value ( last selected option)
    setDisableApplyButton(true); //disable apply button once clicked on reset button
    setDisableResetButton(true); //disable reset button once clicked on reset button
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
  };
  //Ends

  const handleCurrentSelectedChange = (option) => {
    if (option !== numberOfDays) {
      setDisableApplyButton(false); //enable apply button once the drop down values change
      setDisableResetButton(false); //enable reset button once the drop down values change
      setCurrentSelectedView(option); //set current selected value
    } else {
      setDisableApplyButton(true); //disable apply button once the drop down values change
      setDisableResetButton(true); //disable reset button once the drop down values change
      setCurrentSelectedView(option); //set current selected value
    }
  };

  const completeReset = () => {
    setNumberOfDays(timeFrameDropdownOptions[5]);
    setCurrentSelectedView(timeFrameDropdownOptions[5]);
    setStartDate(null);
    setEndDate(null);
    setCurrentStartDate(null);
    setCurrentEndDate(null);
    setStartYear(null);
    setEndYear(null);
    setDisableApplyButton(true);
    setDisableResetButton(true);
    setIsClicked(!isClicked);
    timeFrameDropdownOptions.map((option) => {
      option.dateRange = [null, null];
    });
  };

  const getImpressions = async (controller) => {
    setIsLoading(true);
    setIsError(false);

    try {
      let response = await axiosGetRequest(
        `${baseEndpoints.campaigns}/impressions-with-spend-daily`,
        controller.signal,
        {
          user_id: client.selectedClient,
          advertiser_id: advertiser.selectedAdvertiser,
          dateRangeOption: numberOfDays?.id,
          startDate: startDate,
          endDate: endDate,
        }
      );

      if (response) {
        const data = response.data?.impressions;
        setStatus(response.status);
        if (response.status === 200 && data) {
          const tmp = calcImpressions(data);
          setData(tmp);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        if (err.response) {
          setStatus(err.response.status);
        } else {
          setStatus(500);
        }
        setIsError(true);
        setIsLoading(false);
      }
    }
  };

  const calcImpressions = (data) => {
    let tmp = [];
    if (data && data.length > 0) {
      tmp = data.map((element) => {
        return {
          date: element.date.substr(0, 10),
          name: moment(element.date).format('MM/DD/YYYY'),
          ImpressionsDaily: parseInt(element.impressions),
          SpendDaily: parseInt(element.spend),
          Impressions: parseInt(element.impressions),
          Spend: parseInt(element.spend),
        };
      });
      tmp.forEach((element, index, array) => {
        if (index > 0) {
          element['Impressions'] += array[index - 1]['Impressions'];
          element['Spend'] += array[index - 1]['Spend'];
        }
      });

      // tmp = tmp.slice(0,20)
      return tmp;
    }
  };

  // write a useEffect to run complete reset when client or advertiser changes
  useEffect(() => {
    completeReset();
  }, [client.selectedClient, advertiser.selectedAdvertiser]);

  useEffect(() => {
    const controller = new AbortController();

    getImpressions(controller);
    updateTimeFrame();
    return () => {
      controller.abort();
    };
  }, [advertiser, isClicked]);

  const getEarliestCampaignDate = async (controller) => {
    const ealiestCampaignDate = await axiosGetRequest(
      baseEndpoints.campaigns + '/earliest-campaign-date',
      controller.signal,
      {
        user_id: client.selectedClient,
        advertiser_id: advertiser.selectedAdvertiser,
      }
    );
    if (ealiestCampaignDate) {
      return ealiestCampaignDate;
    }
  };

  const updateTimeFrame = async () => {
    const controller = new AbortController();
    const result = await getEarliestCampaignDate(controller);
    const earliestCampaignDate =
      result?.data?.date?.start_date ??
      moment(currentDate).subtract(1, 'd').format(defaultDateFormat);
    timeFrameDropdownOptions.map((option) => {
      const { newStartDate, newEndDate, isDisabled } =
        getStartAndEndDateTimeFrameImpressionsDashboard(
          option.id,
          currentDate,
          moment(earliestCampaignDate.substring(0, 10)).format(
            defaultDateFormat
          ),
          moment(currentDate).subtract(1, 'd').format(defaultDateFormat)
        );

      option.dateRange = [newStartDate, newEndDate];
      option.isDisabled = isDisabled;
    });
    if (currentSelectedView.id !== 0) {
      setStartYear(currentSelectedView.dateRange[0]);
      setEndYear(currentSelectedView.dateRange[1]);
      setCurrentStartDate(currentSelectedView.dateRange[0]);
      setCurrentEndDate(currentSelectedView.dateRange[1]);
      setStartDate(currentSelectedView.dateRange[0]);
      setEndDate(currentSelectedView.dateRange[1]);
    }
  };

  useEffect(() => {
    if (currentSelectedView.id !== 0) {
      setCurrentStartDate(currentSelectedView.dateRange[0]);
      setCurrentEndDate(currentSelectedView.dateRange[1]);
      setStartYear(currentSelectedView.dateRange[0]);
      setEndYear(currentSelectedView.dateRange[1]);
    } else if (numberOfDays.id === 0) {
      setCurrentStartDate(startDate);
      setCurrentEndDate(endDate);
      setStartYear(numberOfDays.dateRange[0]);
      setEndYear(numberOfDays.dateRange[1]);
    } else {
      setCurrentStartDate(null);
      setCurrentEndDate(null);
      setStartYear(currentSelectedView.dateRange[0]);
      setEndYear(currentSelectedView.dateRange[1]);
    }
  }, [currentSelectedView]);

  return (
    <div>
      <CommonHeadingComponent headingLabel='All Campaign Impressions' />
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} style={{ height: '5em' }}>
          <div
            className='analysisSectionInnerSubHeaderWrapper dashboardAllCampaignImpressionFilterWrapper'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              paddingTop: '2em',
            }}
          >
            <SearchableDropdownComponent
              options={timeFrameDropdownOptions}
              label='name'
              id='id'
              dropdownId='viewNumberOfDays'
              dropdownLabel='View'
              labelWidth={{ width: '30px' }}
              dropdownWidth={{
                width: '320px',
              }}
              placeholderLabel={'Number of Days'}
              selectedValue={currentSelectedView}
              handleChange={handleCurrentSelectedChange}
              isSearchable={false}
              uniqueIdentifier='reportDetailsMediaDeliveryAnalysisDateRange'
            />
            <DatePickerComponent
              datePickerId='dashboardAllCampaignImpressionFilterDateRange'
              label='Date Range'
              labelWidth={{ width: '80px' }}
              datePickerBodyWidth={{
                width: 'calc(100% - 90px)',
                minWidth: '200px',
              }}
              placeholderLabel='Start Date - End Date'
              minDate={startYear}
              maxDate={moment(endYear)
                .format(defaultDateFormat)}
              isDateRangePicker={true}
              dateRange={[currentStartDate, currentEndDate]}
              setDateRange={(range) => {
                setCurrentStartDate(range[0]);
                setCurrentEndDate(range[1]);
                setDisableApplyButton(false); //enable apply button once the date range values change
                setDisableResetButton(false); //enable reset button once the date range values change
              }}
              isDisabled={currentSelectedView?.id !== 0}
              format='MM/DD/YYYY'
              currentYear={checkCondition(
                endYear && daysToFormatWRTDates(endYear) <= 0,
                moment(endYear).year(),
                moment(currentDate).year()
              )}
              startYear={checkCondition(
                startYear,
                moment(startYear).year() - 1,
                moment(currentDate).year() - 1
              )}
              uniqueIdentifier='createOnDemandRpt'
            />
            <CommonButtonComponent
              label='Apply'
              appendWrapperClassName='btnPrimaryWrapper'
              appendButtonClassName='btnPrimary hoverEffect'
              onClickCTA={updateGraphData}
              isDisabled={disableApplyButton} //state for enable / disable apply button
              buttonUI='dashboardAllCampaignImpressionFilterApply'
            />
            <CommonButtonComponent
              label='Reset'
              appendWrapperClassName='btnPrimaryWrapper buttonLeftPadding'
              appendButtonClassName='btnPrimary hoverEffect'
              onClickCTA={resetGraphData}
              isDisabled={disableResetButton} //state for enable / disable reset button
              buttonUI='dashboardAllCampaignImpressionFilterReject'
            />
          </div>
        </Col>
      </Row>
      <MainBlock
        isLoading={isLoading}
        data={data}
        isError={isError}
        status={status}
      />
    </div>
  );
}

function MainBlock({ isLoading, data, isError, status }) {
  if (isLoading) {
    return (
      <div style={{ padding: '2em 0' }}>
        <LoaderComponent />
      </div>
    );
  }
  if (!data || data.length <= 0) {
    return (
      <>
        <NoDataComponent
          type='line'
          customLayerStyle={{ backgroundSize: '30%' }}
          customImageStyle={{ backgroundSize: '10%' }}
        />
      </>
    );
  }
  return (
    <Container fluid>
      <Row>
        <Col style={{ textAlign: 'center', marginTop: 2 + 'em' }}>
          <span>&nbsp;</span>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} style={{ height: '20.5em' }}>
          {isError ? (
            <ErrorComponent status={status} />
          ) : (
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart
                width={500}
                height={700}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid vertical={false} strokeDasharray='3 3' />
                <XAxis interval={150} dataKey='name' />
                <YAxis
                  yAxisId='left'
                  tickCount={10}
                  tickLine={false}
                  axisLine={false}
                  width={100}
                  domain={[
                    0,
                    (dataMax) => Math.ceil((dataMax * 1.5) / 100) * 100,
                  ]}
                  tickFormatter={approximateNumberToMils}
                />
                <YAxis
                  yAxisId='right'
                  orientation='right'
                  tickCount={10}
                  tickLine={false}
                  axisLine={false}
                  width={100}
                  domain={[
                    0,
                    (dataMax) => Math.ceil((dataMax * 1.5) / 100) * 100,
                  ]}
                  tickFormatter={approximateNumberToMils}
                />
                <Tooltip content={<CustomizedTooltip />} />
                <Line
                  type='linear'
                  legendType='square'
                  //   dot={{ stroke: "#03bdc9", strokeWidth: 3, radius: 6 }}
                  activeDot={true}
                  label={false}
                  dataKey='Impressions'
                  stroke='#004064'
                  strokeWidth={2}
                  strokeOpacity={1}
                  dot={false}
                  yAxisId='left'
                />
                {/* <Line
                  legendType='square'
                  //   dot={{ stroke: "#ff7c1f", strokeWidth: 3 }}
                  activeDot={true}
                  label={false}
                  dataKey='Spend'
                  stroke='#81AA08'
                  strokeWidth={2}
                  dot={false}
                  yAxisId='right'
                /> */}
                <Legend height={36} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
}

const CustomizedTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-impression-chart-tooltip'>
        <div>{label}</div>
        <div>
          {`Cumulative Impressions: `}
          <span>{new Intl.NumberFormat('en').format(payload[0].value)}</span>
        </div>
        <div>
          {`Daily Impressions: `}
          <span>
            {new Intl.NumberFormat('en').format(
              payload[0]?.payload?.ImpressionsDaily
            )}
          </span>
        </div>
      </div>
    );
  }
  return null;
};
