// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/error-image.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorContent{display:flex;justify-content:center;align-items:center;flex:1;padding:20px}.errorContentInner{display:flex;align-items:center;max-width:650px}.error-img{display:inline-block;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center/contain;width:80px;height:68px;margin-right:24px}.errorStatus,.errorMessage{font-size:16px}.errorContent .errorMessage span{font-weight:500;color:var(--aa-teal-link);cursor:pointer}.errorStatus{margin-bottom:.5rem}.activeCampaignsOuterWrapper .errorContent,.upcomingCampaignsOuterWrapper .errorContent{height:250px}`, "",{"version":3,"sources":["webpack://./src/components/errorComponent/errorComponent.css"],"names":[],"mappings":"AAAA,cACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,MAAA,CACA,YAAA,CAGF,mBACE,YAAA,CACA,kBAAA,CACA,eAAA,CAGF,WACE,oBAAA,CACA,kFAAA,CAEA,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,2BAEE,cAAA,CAGF,iCACE,eAAA,CACA,yBAAA,CACA,cAAA,CAGF,aACE,mBAAA,CAGF,wFAEE,YAAA","sourcesContent":[".errorContent {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n  padding: 20px;\n}\n\n.errorContentInner {\n  display: flex;\n  align-items: center;\n  max-width: 650px;\n}\n\n.error-img {\n  display: inline-block;\n  background: url(../../assets/images/error-image.svg) no-repeat center center /\n    contain;\n  width: 80px;\n  height: 68px;\n  margin-right: 24px;\n}\n\n.errorStatus,\n.errorMessage {\n  font-size: 16px;\n}\n\n.errorContent .errorMessage span {\n  font-weight: 500;\n  color: var(--aa-teal-link);\n  cursor: pointer;\n}\n\n.errorStatus {\n  margin-bottom: 0.5rem;\n}\n\n.activeCampaignsOuterWrapper .errorContent,\n.upcomingCampaignsOuterWrapper .errorContent {\n  height: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
