import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import {
  CommonHeadingComponent,
  MultiselectDropdownComponent,
  SearchableDropdownComponent,
  CommonButtonComponent,
  ErrorComponent,
  LoaderComponent,
  NoDataComponent,
  CommonPixelTable
} from '../../../components'
import {
  checkSelectAll,
  setPlaceHolderLabel,
} from '../../../utils/functions'
import { messages, baseEndpoints } from '../../../constants'
import { axiosGetRequest, axiosPostRequest } from '../../../services/http/axiosCalls'
import { set } from 'lodash'
import { commonNotification } from '../../../utils/common-notifications'

/**
 * UI component to show pixel raw data for Campaigns
 * @returns UI component to render on screen
 */
export const PixelRawData = () => {
  const dimensionOptions = [{ dimension: 'Impressions', id: 'imps' }, { dimension: 'Clicks', id: 'clicks' }]

  const { id } = useParams()
  const [expandOrCollapse, setExpandOrCollapse] = useState(false)
  const [error, setError] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [placementLoading, setPlacementLoading] = useState(false)
  const [placementData, setPlacementData] = useState([])
  const [placement, setPlacement] = useState(null)
  const [dimension, setDimension] = useState({ dimension: 'Impressions', id: 'imps' })
  const [isDisabled, setIsDisabled] = useState({
    placement: true
  })
  const [pixelData, setPixelData] = useState(null)
  const [pixelLoading, setPixelLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController()
    getPlacementData(controller)
    return () => {
      controller.abort()
    }
  }, [])

  const getPlacementData = async (controller) => {
    setError('')
    setPlacementLoading(true)
    try {
      const data = await axiosGetRequest(
        `${baseEndpoints.campaigns}/${id}/placements`,
        controller ? controller.signal : null
      )
      if (data.status === 200) {
        if (data.data?.data_count === 0) {
          setError('No placement found.')
        } else {
          setPlacementData(data.data?.table_data)
          setPlacement(data.data?.table_data)
          setIsDisabled({
            ...isDisabled,
            placement: false
          })
        }
      } else {
        setError('placement_api_error')
      }
      setPlacementLoading(false)
    } catch (e) {
      console.log(e)
      setError('placement_api_error')
      setPlacementLoading(false)
    }
  }

  const handleCollapsible = () => {
    setExpandOrCollapse(!expandOrCollapse)
  }

  // Method to parse Response object
  const parseQueryResponse = (data) => {
    let tempArr = data.map(item => {
      // Parse Query Key Response
      let str = item.query
      var keyValuePairs = str.slice(1, -1).split(', ')
      // Create an object to store the key-value pairs
      var jsonObject = {};

      // Loop through the key-value pairs and add them to the object
      for (var i = 0; i < keyValuePairs.length; i++) {
        var pair = keyValuePairs[i].split('=');
        jsonObject[pair[0]] = pair[1]
      }
      return {...item, ...jsonObject}
    })
    return tempArr
  }

  // function retries api request recursively
  const pixelDataRequest = async (selectedPlacementIDs, recursed = false) => {
    const pixelData = await axiosPostRequest(`${baseEndpoints.pixels}/placement`, {
      dimension: dimension.id,
      placements: selectedPlacementIDs,
      type: 'placements',
      all_selected: placementData.length === placement.length,
      campaign_id: id
    });
    if (pixelData?.success) {
      // let newParsedArray = parseQueryResponse(pixelData.data)
      setPixelData(pixelData.data.slice(0, 100))
      setErrorMessage('')
      setError('')
      setPixelLoading(false);
    } else if (pixelData?.errorCode === 'InvalidRequestException') {
      setPixelData(null);
      setError('query_running_error');
      // recursively calls function to retry data request, waits 20 seconds at first and then 5
      setTimeout(() => {
        setErrorMessage("This report is being created. Please wait. The report will load when the data is ready.");
        pixelDataRequest(selectedPlacementIDs, true);
      }, recursed ? 5000 : 10000);
    } else {
      setPixelData(null)
      setError('pixel_api_error')
      setPixelLoading(false);
    }
  }

  const getPixelRawData = async () => {
    try {
      setError('')
      setErrorMessage('');
      setPixelLoading(true)
      const selectedPlacementIDs = placement.map(val => val.placement_id)

      if(placementData.length === placement.length || selectedPlacementIDs.length <= 300) {
        pixelDataRequest(selectedPlacementIDs);
      } else if (selectedPlacementIDs.length > 300) {
        commonNotification({ msg: "You may not select more than 300 individual placements.  Please either select all or fewer than 300 individual placements.", type: "danger" })
        setErrorMessage("You can select a maximum of 300 individual placements at a time or all placements. Please select all or fewer placements and try again.")
        setError('too_many_placements_error')
        setPixelLoading(false)
        return
      }
    } catch (e) {
      console.log(e)
      setError('pixel_api_error')
    }
  }

  const renderPixelItem = (pixel, i) => {
    // Now contains an set instead of string, which has [key-in-response-object, label-needs-to-display-in-UI]
    const previewRows = [
      ['placement', 'Placement'],
      ['partner', 'Partner'],
      ['Click ID', 'Click ID'],
      ['user_agent', 'User Agent'],
      ['raw_ip', 'IP Address'],
      ['idfa', 'IDFA/AAID'],
      ['ord', 'ORD'],
      // ['cv1', 'Custom Variable 1'],
      // ['cv2', 'Custom Variable 2']
      ['query', 'Query']
    ]
    const allRows = [
      ['placement', 'Placement'],
      ['partner','Partner'],
      ['Click ID', 'Click ID'],
      ['user_agent', 'User Agent'],
      ['raw_ip', 'IP Address'],
      ['cookie', 'Cookie ID'],
      ['idfa', 'IDFA/AAID'],
      ['ord','ORD'],
      // ['cv1','Custom Variable 1'],
      // ['cv2','Custom Variable 2'],
      // ['cv3','Custom Variable 3'],
      // ['cv4','Custom Variable 4']
      ['query', 'Query']
    ]
    return <CommonPixelTable key={i} pixel={pixel} i={i} previewRows={previewRows} allRows={allRows} />
  }

  const renderPixelItemList = () => {
    return (
      <Container fluid>
        {pixelData.map((pixel, i) => renderPixelItem(pixel, i))}
      </Container>
    )
  }

  const renderPixelRawData = () => {
    if (pixelLoading || placementLoading) {
      return <LoaderComponent />
    } else if (pixelData) {
      return (
        <Row>
          <Col>
          <div className='pixelRawDataLabel'>
            {messages.pixelRawDataInfo}
          </div>
          <div className='pixelDataContainer' style={pixelData.length === 0 ? { padding: 0 } : {}}>
            {pixelData.length === 0 ? <div className='noPixelData'>No Data</div> : renderPixelItemList()}
          </div>
          </Col>
        </Row>
      )
    }
    return <div />
  }
  
  const renderErrorComponent = () => {
    if (error) {
      if (error.includes('api_error')) {
        return <ErrorComponent refreshAction={error === 'placement_api_error' ? getPlacementData : getPixelRawData} />
      } else if (error.includes('query_running_error')) {
        if (errorMessage) {
          return (<div className='pixelErrorMessageWrapper'>
            {errorMessage}
          </div>);
        }
      } else {
        return <NoDataComponent />
      }
    }
  }

  const renderCollapsibleView = () => {
    return (
      <Container fluid>
        <Row className='pixelSelector'>
          <Col
            xl={5}
            lg={5}
            md={4}
            sm={4}
          >
            <MultiselectDropdownComponent
              dropdownId='placementPRD'
              dropdownDataList={placementData}
              dataId='placement_id'
              dataName='placement_name'
              dataType='placement_type'
              label='Placement'
              dropdownData={placement}
              setDropdownData={(data) => {
                setError('')
                if (data) {
                  setPlacement(data)
                } else {
                  setPlacement([])
                }
              }}
              ifIdSearchAvailable={true}
              placeholder={setPlaceHolderLabel(
                isDisabled.placement,
                'Placement'
              )}
              txtSearch='Search by Placement'
              isAllCheckedInitially={checkSelectAll(
                placementData,
                placement,
                'placement_id'
              )}
              labelWidth={{ width: '86px' }}
              dropdownWidth={{
                width: 'calc(100% - 86px)'
              }}
              isDisabled={isDisabled.placement}
              uniqueIdentifier='campaignDetailsPixelRawDataPlacement'
              ifIdVisibleOnLabel={true}
            />
          </Col>
          <Col
            xl={4}
            lg={5}
            md={4}
            sm={4}
          >
          <SearchableDropdownComponent
              options={dimensionOptions}
              label='dimension'
              id='dimension'
              dropdownId='statusPRD'
              dropdownLabel='Dimension:'
              labelWidth={{ width: '100px' }}
              dropdownWidth={{
                width: '350px',
                minWidth: '120px'
              }}
              selectedValue={dimension}
              isSearchable={false}
              handleChange={(val) => {
                setError('')
                setDimension(val)
              }}
              isDisabled={isDisabled.placement}
              wrapperClass={'requiredDD'}
              uniqueIdentifier='campaignDetailsPixelRawDataDimension'
            />
          </Col>
          <Col
            xl={2}
            lg={2}
            md={4}
            sm={4}
          >
            <div className="placementRawDataButtons">
              <CommonButtonComponent
                label='Generate Report'
                isDisabled={!placement || placement.length === 0 || pixelLoading}
                appendButtonClassName='generateReportButtonBody'
                appendWrapperStyle={{ padding: '0px' }}
                onClickCTA={() => getPixelRawData()}
                showLoader={false}
                buttonUI='campaignDetailsPixelRawDataGenerate'
              />
            </div>
            
          </Col>
        </Row>
        {renderPixelRawData()}
        {renderErrorComponent()}
      </Container>
    )
  }

  return (
    <div className='campaignListWrapper container-fluid pixelContainer'>
      <CommonHeadingComponent
        headingLabel='Placement Pixel Raw Data'
        headingCTALabel={!expandOrCollapse ? 'COLLAPSE' : 'EXPAND'}
        isDisabled={false}
        onClickCTA={handleCollapsible}
        uniqueIdentifier='campaignDetailsPlacementPixelRawDataCollapsable'
      />
      {!expandOrCollapse && renderCollapsibleView()}
    </div>
  )
}
