import React from 'react';
import moment from 'moment';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { approximateNumberToMils } from '../../../../../../utils/functions';

const AttributionLineChart = ({ data }) => {
  return (
    <div>
      <ResponsiveContainer width='100%' height={300}>
        <LineChart
          data={data}
          margin={{ top: 30, bottom: 0, right: 25, left: 20 }}
        >
          <CartesianGrid
            vertical={false}
            horizontal={true}
            strokeDasharray='3 3'
          />
          <XAxis
            tickCount={5}
            axisLine={true}
            dataKey='est_date'
            width={1}
            interval={0}
            hide={false}
            tick={false}
            tickLine={false}
            stroke='#000'
            padding={{ left: 10, right: 10 }}
          />
          <YAxis
            tickCount={10}
            axisLine={false}
            domain={[0, (dataMax) => Math.ceil((dataMax * 1.2) / 100) * 100]}
            tickFormatter={approximateNumberToMils}
            width={60}
            interval={0}
            hide={false}
            tick={true}
            tickLine={false}
          />
          <Tooltip
            cursor={false}
            isAnimationActive={false}
            content={customTooltip}
            wrapperStyle={{ outline: 'none' }}
            formatter={(value) => Intl.NumberFormat("en").format(value)}
          />
          <Line
            type='linear'
            dataKey='attributable_events'
            stroke='#04BDC9'
            strokeDasharray='5 5'
            strokeWidth={2}
            dot={{ fill: '#04BDC9', r: 2.5, strokeDasharray: 0 }}
            activeDot={{
              stroke: '#04BDC9',
              fill: '#04BDC9',
              r: 4,
              strokeDasharray: 0,
            }}
          />
          <Line
            type='linear'
            dataKey='incremental_events'
            stroke='#F56600'
            strokeWidth={2}
            dot={{ fill: '#F56600', r: 2.5 }}
            activeDot={{ stroke: '#F56600', fill: '#F56600', r: 4 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AttributionLineChart;

const customTooltip = ({ payload }) => {
  const data = payload ? payload[0]?.payload : null;

  return (
    !(data === undefined || data === null) && (
      <div className="attrVsIncTooltipWrapper">
        <div className="attrVsIncTooltipLabel">{data.goal_name}</div>
        <div className="attrVsIncTooltipBody">
          <div>Attributable: </div>
          <div style={{ color: payload[0]?.color }}>
            {Intl.NumberFormat("en").format(data.attributable_events)}
          </div>
        </div>
        <div className="attrVsIncTooltipBody">
          <div>Incremental: </div>
          <div style={{ color: payload[1]?.color }}>
            {Intl.NumberFormat("en").format(data.incremental_events)}
          </div>
        </div>
        <div className="attrVsIncTooltipBody">
          <div>Lift %: </div>
          <div>{data.percent_of_lift}%</div>
        </div>
        <div className="attrVsIncTooltipBody">
          <div>Property: </div>
          <div>{data.property_name}</div>
        </div>
        <div className="attrVsIncTooltipBody">
          <div>Interval: </div>
          <div>
            {moment(data.start_date).format("MM/DD/YY")} -{" "}
            {moment(data.end_date).format("MM/DD/YY")}
          </div>
        </div>
      </div>
    )
  );
};
