import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { breadCrumbHeader } from "../../constants";
import { PropertiesListComponent } from "./components";

const Properties = () => {
  document.title = "Properties";

  return (
    <React.Fragment>
      <div className="breadCrumbOuterWrapper">
        <div className="breadCrumbInnerWrapper">
          <Link to="/">
            <div className="homeBlue"></div>
          </Link>
          <div className="breadCrumbBody">
            <span>/</span>
            {breadCrumbHeader.properties}
          </div>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            className="activeCampaignsOuterWrapper"
          >
            <PropertiesListComponent></PropertiesListComponent>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Properties;
