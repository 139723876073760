import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createColumns } from './activeCampaigns.utils';
import {
  CommonHeadingComponent,
  CommonTablesComponent,
  ErrorComponent,
  CommonTooltipTrendComponent,
} from '../../../../components';
import { baseEndpoints } from '../../../../constants';
import { removeTableClass, setAdvertiserId } from '../../../../utils/functions';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import { baseInputParams } from '../';

import './activeCampaigns.css';

const baseTooltipActions = {
    showTooltip: false,
    tooltipPosition: null,
    effectiveCampaignId: null,
    isTooltipOnTop: true,
  };

const ActiveCampaignsComponent = () => {
  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser),
    role = useSelector((state) => state.user.role);

  const { width } = useWindowDimensions();

  const [params, setParams] = useState(null),
    [isMounted, setIsMounted] = useState(false),
    [inputParams, setInputParams] = useState(null),
    [isLoadingTab, setIsLoadingTab] = useState(true),
    [columns, setColumns] = useState([]),
    [tableBaseData, setTableBaseData] = useState([]),
    [tableTrendChartData, setTableTrendChartData] = useState(null),
    [tooltipActions, setTooltipActions] = useState(baseTooltipActions),
    [totalData, setTotalData] = useState(0);

  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    `${baseEndpoints.campaigns}/active`
  );

  const resetData = () => {
    setColumns([]);
    setTableBaseData([]);
    setTableTrendChartData([]);
    setTooltipActions(baseTooltipActions);
    setTotalData(0);
  };

  useEffect(() => {
    if (inputParams) {
      setIsLoadingTab(true);
      resetData();

      const advertiserId = setAdvertiserId(advertiser.selectedAdvertiser, advertiser.selectedClientsAdvertiser, role);
      setParams({
        timezone: null,
        user_id: client.selectedClient === -1 ? null : client.selectedClient,
        advertiser_id: advertiserId,
        ...inputParams,
      });
      setIsMounted(true);
    }
  }, [inputParams]);

  useEffect(() => {
    setInputParams({
      ...baseInputParams,
    });
  }, [client.selectedClient, advertiser.selectedAdvertiser]);

  useEffect(() => {
    if (isLoading) {
      setIsLoadingTab(true);
    } else {
      setTimeout(() => {
        setIsLoadingTab(false);
      }, 100);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data?.table_base_data?.length) {
      setTotalData(data.data_count);
      setTableBaseData(data.table_base_data);
      setTableTrendChartData(data.trend_chart_data);
      setColumns(
        createColumns(
          data.trend_chart_data,
          data.ratio_chart_data,
          tooltipActions,
          setTooltipActions,
          width
        )
      );

      removeTableClass('.activeCampaignsWrapper');
    }
  }, [data, width]);

  return (
    <div className='activeCampaignsWrapper'>
      {tooltipActions.showTooltip && !isLoadingTab && (
        <CommonTooltipTrendComponent
          isTooltipOnTop={tooltipActions.isTooltipOnTop}
          tooltipPosition={tooltipActions.tooltipPosition}
          effectiveCampaignId={tooltipActions.effectiveCampaignId}
          tableTrendChartData={tableTrendChartData}
          tableBaseData={tableBaseData}
          header='Impressions: 7 day snapshot'
          offsetWidth={25}
        />
      )}
      <CommonHeadingComponent headingLabel='Active Campaigns' />
      {isError ? (
        <ErrorComponent refreshAction={() => setParams({ ...params })} />
      ) : (
        <CommonTablesComponent
          uniqueId='activeCampaigns'
          data={tableBaseData}
          columns={columns}
          isLoading={isLoadingTab}
          customTableHeader='Active Campaigns'
          isPaginationApplied={true}
          totalDataCount={totalData}
          pageIndex={inputParams?.page_number}
          setPageIndex={(page_number) =>
            setInputParams({ ...inputParams, page_number })
          }
          dataPerPage={inputParams?.page_size}
          noDataMessage='No Active Campaigns'
          noDataMessageFontSize={15}
          noDataCompWidth='180'
          noDataCompHeight='180'
          foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
          showColumnShowHide={false}
        />
      )}
    </div>
  );
};

export default ActiveCampaignsComponent;
