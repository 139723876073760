import React, { useRef, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import { NoDataComponent } from '../../../../../../../../components';
import { lineChartsColors } from '../../../../../../../../constants';
import {
  approximateNumberToMils,
  formatNumberInThousands,
} from '../../../../../../../../utils/functions';
import './lineChart.css';

function RenderAnalysisLineChart({
  data = [],
  showVertical = true,
  xAxisLine = true,
  yAxisLine = true,
  height = 300,
  xAxisDataKey,
  isDate = false,
  colors = lineChartsColors,
  hideXAxis = false,
  lineType = 'linear',
  lineStrokeWidth = 1,
  strokeDasharray = '3 3',
  xAxisWidth = 1,
  xAxisTickLineShow = false,
  yAxisWidth = 80,
  yAxisTickLineShow = false,
  xAxisTickShow = false,
  isCustomLabel = false,
  customLabelOne = '',
  customLabelTwo = '',
  onlyForAnalysisLine = false,
  customLayerStyle = { backgroundSize: '30%' },
  customImageStyle = { backgroundSize: '10%' },
}) {
  let arr = data?.length > 0 ? Object.keys(data[0]) : [];
  let result = arr.filter((word) => word !== xAxisDataKey);
  const [tooltip, setTooltip] = useState(false);
  const [tooltipX, setTooltipX] = useState('');
  const [tooltipY, setTooltipY] = useState('');
  const [tooltipColor, setTooltipColor] = useState('#fff');

  const lables = [{ key: 'name' }, { key: 'impressions' }];

  const [lineProps, setLineProps] = useState(
    lables.reduce(
      (a, { key }) => {
        a[key] = false;
        return a;
      },
      { hover: null }
    )
  );

  const renderColorfulLegendText = (value, entry) => {
    const { color } = entry;
    return <span style={{ color }}>{value.slice(value.indexOf('-') + 1)}</span>;
  };

  const selectLine = (e) => {
    setLineProps({
      ...lineProps,
      [e.dataKey]: !lineProps[e.dataKey],
      hover: null,
    });
  };

  const renderComponent = () => {
    if (!data || data?.length <= 0) {
      return (
        <NoDataComponent
          type='line'
          customLayerStyle={customLayerStyle}
          customImageStyle={customImageStyle}
        />
      );
    } else {
      return (
        <div className='customMultiLineChart'>
          <ResponsiveContainer width='100%' height={height}>
            <LineChart data={data} margin={{ top: 30, bottom: 20 }}>
              <CartesianGrid
                vertical={showVertical}
                strokeDasharray={strokeDasharray}
              />
              <XAxis
                dataKey={xAxisDataKey}
                padding={{ left: 30, right: 30 }}
                axisLine={xAxisLine}
                interval={0}
                hide={hideXAxis}
                width={xAxisWidth}
                tickLine={xAxisTickLineShow}
                tick={xAxisTickShow}
                stroke='#000'
              />
              <YAxis
                tickCount={10}
                tickFormatter={approximateNumberToMils}
                axisLine={yAxisLine}
                width={yAxisWidth}
                tickLine={yAxisTickLineShow}
              />
              <Tooltip
                isAnimationActive={false}
                cursor={false}
                content={
                  <CustomTooltip
                    tooltipX={tooltipX}
                    tooltipY={tooltipY}
                    tooltipColor={tooltipColor}
                    isDate={isDate}
                    isCustomLabel={isCustomLabel}
                    customLabelOne={customLabelOne}
                    customLabelTwo={customLabelTwo}
                    onlyForAnalysisLine={onlyForAnalysisLine}
                  />
                }
                wrapperStyle={{
                  display: tooltip ? 'block' : 'none',
                  outline: 'none',
                }}
              />
              <Legend
                iconType={'square'}
                formatter={renderColorfulLegendText}
                wrapperStyle={{ bottom: 0 }}
                onClick={selectLine}
              />
              {result?.map((elem, index) => (
                <Line
                  type={lineType}
                  dataKey={result[index]}
                  key={`${elem}`}
                  stroke={colors[index]}
                  dot={{
                    stroke: colors[index],
                    strokeWidth: 2,
                    r: 3,
                  }}
                  activeDot={
                    <CustomActiveDot
                      stroke={colors[index]}
                      setTooltip={setTooltip}
                      setTooltipX={setTooltipX}
                      setTooltipY={setTooltipY}
                      setTooltipColor={setTooltipColor}
                    />
                  }
                  strokeWidth={lineStrokeWidth}
                  hide={lineProps[result[index]] === true}
                  animationEasing={'ease-in-out'}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    }
  };

  return renderComponent();
}

const CustomTooltip = ({
  active,
  payload,
  label,
  isDate,
  tooltipX,
  tooltipY,
  tooltipColor,
  isCustomLabel,
  customLabelOne,
  customLabelTwo,
  onlyForAnalysisLine,
}) => {
  if (active && payload && payload?.length) {
    return (
      <div className='line-chart-custom-tooltip'>
        <div className='custom-label'>
          {!isDate ? `${label}` : `${moment(label).format('MM/DD/YYYY')}`}
        </div>
        <div className='desc-wrapper' key={`${tooltipX}:${tooltipY}`}>
          <div className='ledger'>
            {isCustomLabel && <div className='CTT-name'>{customLabelOne}</div>}
            <div style={{ color: `${tooltipColor}` }}>
              {onlyForAnalysisLine ? tooltipX.split('-')[1] : tooltipX}
            </div>
          </div>
          <div className='ledger-value'>
            {isCustomLabel && <div className='CTT-value'>{customLabelTwo}</div>}
            <div>{formatNumberInThousands(tooltipY)}</div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const CustomActiveDot = (props) => {
  const {
    cx,
    cy,
    stroke,
    setTooltip,
    dataKey,
    value,
    setTooltipX,
    setTooltipY,
    setTooltipColor,
  } = props;
  const ref = useRef({ cx: 0, cy: 0 });
  return (
    <svg
      onMouseEnter={(e) => {
        ref.current = { cx, cy };
      }}
      onMouseLeave={() => {
        ref.current = { cx: 0, cy: 0 };
      }}
      x={cx - 10}
      y={cy - 10}
      width={40}
      height={40}
      fill={'#000'}
      viewBox='0 0 200 200'
    >
      {ref.current.cx == cx && cy == ref.current.cy ? (
        <circle
          cx='50'
          cy='50'
          r='40'
          strokeWidth='3'
          fill={stroke}
          onMouseEnter={(e) => {
            setTooltipX(dataKey);
            setTooltipY(value);
            setTooltipColor(stroke);
            setTooltip(true);
          }}
          onMouseLeave={() => {
            setTooltip(false);
            setTooltipX('');
            setTooltipY('');
            setTooltipColor('#fff');
          }}
        />
      ) : (
        <circle
          cx='50'
          cy='50'
          r='30'
          stroke='transparent'
          strokeWidth='2'
          fill='transparent'
          onMouseEnter={(e) => {
            setTooltipX(dataKey);
            setTooltipY(value);
            setTooltipColor(stroke);
            setTooltip(true);
          }}
          onMouseLeave={() => {
            setTooltip(false);
            setTooltipX('');
            setTooltipY('');
            setTooltipColor('#fff');
          }}
        />
      )}
    </svg>
  );
};

export default RenderAnalysisLineChart;
