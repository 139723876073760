import React from 'react';
import LoaderComponent from '../loaderComponent/Loader.component';
import './commonButton.css';

const CommonButtonComponent = ({
  label,
  appendWrapperClassName = '',
  appendWrapperStyle = {},
  showArrow = false,
  arrowClassName,
  isDisabled = false,
  showLoader = false,
  onClickCTA,
  appendButtonClassName = '',
  inverted = false,
  isNonHeader = true,
  buttonUI
}) => {
  return (
    <div
      className={`buttonOuterWrapper buttonLeftRightPadding ${appendWrapperClassName} ${
        inverted ? 'inverted' : ''
      }`}
      style={appendWrapperStyle}
    >
      <div className='buttonWrapper'>
        <button
          id={buttonUI ? buttonUI+'CTA' : undefined}
          className={`buttonBody${isNonHeader ? ' nonHeader' : ''} ${appendButtonClassName}`}
          name={buttonUI ? buttonUI+'CTA' : undefined}
          disabled={isDisabled}
          onClick={onClickCTA}
        >
          <div>{label}</div>
          {showArrow && <div className={arrowClassName}></div>}
        </button>
      </div>
      {showLoader && (
        <div className='buttonLoaderWrapper'>
          <LoaderComponent importedClassNames='dropdownsAndDates' />
        </div>
      )}
    </div>
  );
};

export default CommonButtonComponent;
