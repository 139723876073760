const Interceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest)
  axiosInstance.interceptors.response.use(onResponseSuccess, onResponseError)
  return axiosInstance
}

const onRequest = async (config) => {
  if (process.env.NEXT_PUBLIC_x_api_key) {
    config.headers['x-api-key'] = process.env.NEXT_PUBLIC_x_api_key
  }

  config.headers = {
    Authorization: localStorage.getItem('accessToken'),
    Accept: 'application/json'
  }
  return config
}

const onResponseSuccess = (response) => {
  return response.data
}

const onResponseError = (error) => {
  if (
    error.response &&
    error.response.status === 401 &&
    error.config.url !== "/auth" &&
    error.config.url !== "/auth/refresh" &&
    error.config.url !== "/auth/login" &&
    !error.config.url.includes("/auth/reset-password")
  ) {
    window.location.reload();
  }
  return Promise.reject(error)
}

export default Interceptor
