import React from 'react';
import { Link } from 'react-router-dom';
import { getEncryptedOrDecryptedData } from '../../../../utils/functions';
import moment from 'moment';

export const upcomingTableColumns = [
  {
    id: 'campaignIdUC',
    name: 'CAMPAIGN ID',
    selector: (row) => (
      <div className='tableCellFontStyle tableCampNameFontStyle'>
        <Link
          to={`/campaign/${
            row.campaign_id
          }?params=${getEncryptedOrDecryptedData(
            { name: row.campaign_name },
            'encrypt'
          )}`}
        >
          <div className='campaignNameStyleAC'>{row.campaign_id}</div>
        </Link>
      </div>
    ),
    sortable: false,
    width: '132px',
    right: true,
  },
  {
    id: 'campaignNameUC',
    name: 'CAMPAIGN NAME',
    selector: (row) => (
      <div className='tableCellFontStyle tableCampNameFontStyle'>
        <Link
          to={`/campaign/${
            row.campaign_id
          }?params=${getEncryptedOrDecryptedData(
            { name: row.campaign_name },
            'encrypt'
          )}`}
        >
          <div className='campaignNameStyleUC'>{row.campaign_name}</div>
        </Link>
      </div>
    ),
    sortable: false,
    style: { minWidth: '120px' },
  },
  {
    id: 'startingInfoUC',
    name: 'STARTING',
    selector: (row) => (
      <div className='upcomingStartingWrapper'>
        <div className='tableCellFontStyle'>
          {moment.utc(row.start_date).format('MM/DD/YYYY')}
        </div>
      </div>
    ),
    sortable: false,
    width: '135px',
  },
  {
    id: 'runReportUC',
    name: 'RUN REPORT',
    selector: (row) => (
      <button
        className='runReportIcon runReportEndable'
        onClick={() =>
          window.open(
            `/reports/run/?rd1=${row.campaign_id}`,
            '_blank',
            'noreferrer'
          )
        }
      />
    ),
    sortable: false,
    style: { justifyContent: 'center' },
    width: '110px',
  },
];
