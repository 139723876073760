import React from 'react'
import {
  formatNumberInThousands
} from '../../../../utils/functions'

export const pixelSummaryTableColumns = () => {
  return [
    {
      id: 'goals',
      name: 'Goals',
      selector: (row) => 'All Goals',
      sortable: false
    },
    {
      id: 'gross',
      name: 'Gross',
      selector: (row) => formatNumberInThousands(row?.gross || 0),
      sortable: false,
      right: true,
      style: { justifyContent: 'flex-end' }
    },
    {
      id: 'ivt',
      name: 'Invalid Traffic',
      selector: (row) => formatNumberInThousands(row?.ivt || 0),
      sortable: false,
      right: true,
      style: { justifyContent: 'flex-end' }
    },
    {
      id: 'net',
      name: 'Net',
      selector: (row) => formatNumberInThousands(row?.net || 0),
      sortable: false,
      right: true,
      style: { justifyContent: 'flex-end' }
    }
  ]
}

export const pixelSummaryGoalsTableColumns = () => {
  return [
    {
      id: 'goal',
      name: 'Goal',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='goalNameStyle'>{row.goal_name}</div>
        </div>
      ),
      sortable: false
    },
    {
      id: 'goalType',
      name: 'Goal Type',
      selector: (row) => row?.goal_type ? row.goal_type.charAt(0).toUpperCase() + row.goal_type.slice(1) : '',
      sortable: false
    },
    {
      id: 'gross',
      name: 'Gross',
      selector: (row) => formatNumberInThousands(row?.gross || 0),
      sortable: false,
      right: true,
      style: { justifyContent: 'flex-end' }
    },
    {
      id: 'ivt',
      name: 'Invalid Traffic',
      selector: (row) => formatNumberInThousands(row?.ivt || 0),
      sortable: false,
      right: true,
      style: { justifyContent: 'flex-end' }
    },
    {
      id: 'net',
      name: 'Net',
      selector: (row) => formatNumberInThousands(row?.net || 0),
      sortable: false,
      right: true,
      style: { justifyContent: 'flex-end' }
    }
  ]
}
