import React from 'react';
import { Link } from 'react-router-dom';

import './pageNotFound.css';

const PageNotFound = () => {
  const type = window.location.pathname;
  return paths.includes(type) ? <AccessDenied /> : <NotFound />; //rendering component of the basis of if the path is available
};

export default PageNotFound;

/**array of all the paths available */
const paths = [
  '/dashboard',
  '/campaigns',
  '/reports',
  '/advertisers',
  '/properties',
  '/users',
  '/admin',
];

const NotFound = () => {
  return (
    <div className='pageNotFoundWrapper'>
      <h1>The page you are looking for is not available.</h1>
      <Link to='/campaigns'>Go to Campaigns</Link>
    </div>
  );
};

const AccessDenied = () => {
  return (
    <div className='pageNotFoundWrapper'>
      <h1>You do not have access to this page.</h1>
      <Link to='/campaigns'>Go to Campaigns</Link>
    </div>
  );
};
