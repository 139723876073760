import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    uid: '',
    userId: '',
    defaultAdv: '',
  },
  reducers: {
    update: (state, action) => {
      state.email = action.payload.user_email;
      state.firstName = action.payload.first_name;
      state.lastName = action.payload.last_name;
      state.role = action.payload.role;
      state.uid = action.payload.uid;
      state.userId = action.payload.user_id;
      state.defaultAdv = action.payload.default_adv;
    },
    remove: (state) => {
      state.email = '';
      state.firstName = '';
      state.lastName = '';
      state.role = '';
      state.uid = '';
      state.userId = '';
      state.defaultAdv = '';
    },
  },
});

export const { update, remove } = userSlice.actions;
export default userSlice.reducer;
