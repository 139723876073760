
//constant for days option 
export const daysOption = [
    { code: 2, label: "2 Days" },
    { code: 3, label: "3 Days" },
    { code: 4, label: "4 Days"},
    { code: 7, label: "7 Days" },
    { code: 14, label: "14 Days" },
    { code: 15, label: "15 Days" },
    { code: 30, label: "30 Days" },
    { code: 45, label: "45 Days" },
    { code: 60, label: "60 Days" },
];

//const for the various type options available wrt properties
export const typeOptions = [
    { code: 1, label: "Website" },
    { code: 2, label: "iOS App" },
    { code: 3, label: "Android App" },
];

//the various status options with their code 
export const statusOptions = [
    { code: 1, label: "Active" },
    { code: 0, label: "Inactive" },
];

//get the values based on the current index value 
export const getIndex = (arr, val, id = 'code') => arr.findIndex(item=>item[id] === val)
