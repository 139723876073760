
// const for the various type options available wrt properties
export const reportOptions = [
  { code: 'Y', label: 'Yes' },
  { code: 'N', label: 'No' }
]

// the various status options with their code
export const statusOptions = [
  { code: 'Y', label: 'Active' },
  { code: 'N', label: 'InActive' }
]

// get the values based on the current index value
export const getAdvertiser = (arr, val) => {
  const index = arr.findIndex(item => Number(item.id) === Number(val))
  return arr[index]
}
