import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import moment from 'moment';
import {
  axiosPostRequest,
  axiosGetRequest,
} from '../../services/http/axiosCalls';
import {
  CommonButtonComponent,
  SearchableDropdownComponent,
  CommonInput,
  DatePickerComponent,
  RedirectModalComponent,
  CommonHeadingComponent,
  CommonModal
} from '../../components';
import { commonNotification } from '../../utils/common-notifications';
import { breadCrumbHeader, baseEndpoints } from '../../constants';
import {
  checkCondition,
  getEncryptedOrDecryptedData,
} from '../../utils/functions';
import { Panel } from '../CampaignDetails/components/CampaignDetails.component';
import { reportOptions, statusOptions, getAdvertiser } from './helper';
import { setBlockingLoaderState } from '../../redux/blockingLoader';
import {
  updateSelectedAdvertiser
} from '../../redux/advertiserSlice';
import './CreateCampaign.css';

// CreateCampaign used to create the campaign in the application with required fields
const CreateCampaign = () => {
  document.title = 'Campaigns | Create Campaign';

  const client = useSelector((state) => state.client);
  const user = useSelector((state) => state.user);
  const advertiser = useSelector((state) => state.advertiser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const [availableProperties, setAvailableProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [tabSelected, setTabSelected] = useState(false);
  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false);
  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(() => {
    const campaignStartDate = moment(state?.campaignData.start_date).format('YYYY-MM-DD')
    return state?.campaignData ? moment(campaignStartDate, 'YYYY-MM-DD').isBefore(moment(today, 'YYYY-MM-DD')) ? tomorrow : campaignStartDate : null;
  });
  const [endDate, setEndDate] = useState(() => {
    const campaignEndDate = moment(state?.campaignData.end_date).format('YYYY-MM-DD')
    return state?.campaignData ? moment(campaignEndDate, 'YYYY-MM-DD').isBefore(moment(startDate, 'YYYY-MM-DD')) ? null : campaignEndDate : null;
  });

  const [validName, setValidName] = useState({
    valid: true, errorMsg: ''
  }),
  [validStartDate, setValidStartDate] = useState({
    valid: true,
    errorMsg: ''
  }),
  [validEndDate, setValidEndDate] = useState({
    valid: true,
    errorMsg: ''
  });
  const [validOrderNumber, setValidOrderNumber] = useState({
    valid: true,
    errorMsg: ''
  });

  // For rendering Modal
  const [showModal, setShowModal] = useState(false),
    [createdCampaignId, setCreatedCampaignId] = useState(null),
    [createdCampaignName, setCreatedCampaignName] = useState(null);

  const liftOption = () =>
    state.campaignData.lift_report_enabled === 1
      ? reportOptions[0]
      : reportOptions[1];
  const baseFormData = {
    // name: state?.campaignData ? `${state.campaignData.name}_clone` : '',
    name: state?.campaignData
      ? getSuggestedCloneName(`${state.campaignData.name}`)
      : '',
    description: state?.campaignData?.description || '',
    currentAdvertiser: getAdvertiser(
      advertiser.selectedClientsAdvertiser,
      advertiser.selectedAdvertiser
    ),
    status: statusOptions[0],
    liftReport: state?.campaignData ? liftOption() : reportOptions[1],
    orderNumber: state?.campaignData?.order_number || '',
    selectedProperty: state?.selectedProperties
      ? state?.selectedProperties.map((property) => property.property_id)
      : [],
  };

  // Getting all the detailsField data for the form with default values and updating the state for Create Campaign
  const [formData, setFormData] = useState({ ...baseFormData });

  let {
    name,
    description,
    currentAdvertiser,
    status,
    liftReport,
    orderNumber,
    selectedProperty,
  } = formData;

  // Initial Load Selected Properties
  useEffect(() => {
    setSelectedProperties(
      state?.selectedProperties
        ? state?.selectedProperties.map((item) => ({
            id: item.property_id,
            name: item.name,
            selected: true,
          }))
        : []
    );
    // setStartDate(moment().format('YYYY-MM-DD'))
    // setEndDate(moment().add(1, 'days').format('YYYY-MM-DD'))
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      selectedProperty: selectedProperties?.map((property) => property.id),
    });
  }, [selectedProperties]);

  // updating the selected advertiser if the user updates the header section with change of advertiser
  useEffect(() => {
    const val = getAdvertiser(
      advertiser.selectedClientsAdvertiser,
      advertiser.selectedAdvertiser
    );
    setFormData({ ...formData, currentAdvertiser: val, selectedProperty: [] });
    setSelectedProperties([]);

    // Setting current Advertiser, from clone campaign
    if (state?.campaignData) {
      setFormData({
        ...formData,
        currentAdvertiser: getAdvertiser(
          advertiser.selectedClientsAdvertiser,
          state.campaignData.advertiser_id
        ),
      });
    }
  }, [advertiser.selectedAdvertiser]);

  useEffect(() => {
    if (formData.currentAdvertiser.id) {
      getProperties();
    } else {
      setAvailableProperties([]);
    }
  }, [formData.currentAdvertiser]);

  useEffect(()=>{
   if(startDate && moment().format('YYYY-MM-DD')>moment(startDate).format('YYYY-MM-DD')){
    setValidStartDate({valid: false, errorMsg: 'Start Date can not be in the past'})
   } else if (endDate && startDate > endDate) {
    setValidStartDate({valid: false, errorMsg: 'Start Date can not be after End Date'})
   } else if (startDate === 'Invalid date') {
    setValidStartDate({valid: false, errorMsg: 'Invalid Start Date'})
   } else {
    setValidStartDate({valid: true, errorMsg: ''})
   }
  },[startDate, endDate])

  useEffect(()=>{
    if(endDate && moment().format('YYYY-MM-DD')>moment(endDate).format('YYYY-MM-DD')){
     setValidEndDate({valid: false, errorMsg: 'End Date can not be in the past'})
    } else if (endDate === 'Invalid date') {
    setValidEndDate({valid: false, errorMsg: 'Invalid End Date'})
    } else {
     setValidEndDate({valid: true, errorMsg: ''})
    }
   },[endDate])

  const getProperties = () => {
    dispatch(setBlockingLoaderState({ active: true }));
    const url = `${baseEndpoints.properties}`;
    const requestPromise = axiosGetRequest(url, null, {
      user_id: client.selectedClient,
      advertiser_id: currentAdvertiser.id,
    });
    requestPromise.then(
      (response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.length > 0
        ) {
          const selectedPropertyIds =
            state?.selectedProperties.map((item) => item.property_id) || [];
          const allProperties = response.data.filter((p) => p.name !== '');
          const list = allProperties.map((item) => {
            // Check to add selected properites in View All & Selected
            if (selectedPropertyIds.includes(item.property_id)) {
              setSelectedProperties((oldArray) => [
                ...oldArray,
                { id: item.property_id, name: item.name, selected: true },
              ]);
              return {
                id: item.property_id,
                name: item.name,
                selected: true,
              };
            }
            return {
              id: item.property_id,
              name: item.name,
              selected: false,
            };
          });
          setAvailableProperties(list);
        } else {
          showNoPropertyWarningPopUp();
          setAvailableProperties([]);
        }
        dispatch(setBlockingLoaderState({ active: false }));
      },
      () => {
        showNoPropertyWarningPopUp();
        setAvailableProperties([]);
        dispatch(setBlockingLoaderState({ active: false }));
      }
    );
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      currentAdvertiser: getAdvertiser(
        advertiser.selectedClientsAdvertiser,
        advertiser.selectedAdvertiser
      ),
      status: statusOptions[0],
      liftReport: reportOptions[1],
      orderNumber: '',
      selectedProperty: [],
    });
  };

  // To save campaign with the respective payload
  const handleSave = () => {
    dispatch(setBlockingLoaderState({ active: true }));
    const payload = {
      clientId: client.selectedClient,
      uid: user.uid,
      advertiserId: currentAdvertiser.id,
      name: name.trim(),
      description,
      startDate,
      endDate,
      status: status.code,
      orderNumber,
      liftReportEnabled: liftReport.code,
      properties: selectedProperty,
    };

    const requestPromise = axiosPostRequest(baseEndpoints.campaigns, payload);
    requestPromise.then(
      (response) => {
              
        if (response.status === 200) {
          setCreatedCampaignId(response.id);
          setCreatedCampaignName(name.trim());
          setShowModal(true);
        } else {         
          commonNotification({
            msg: response.error || 'Something went wrong',
            type: 'danger',
          });
        }
        dispatch(setBlockingLoaderState({ active: false }));
      },
      (err) => {
        if(err?.response?.status === 409){
          commonNotification({
            msg: err?.response?.data?.error_message,
            type: 'danger',
          });
          setValidName({valid:false, errorMsg: err?.response?.data?.error_message})
          const searchElem = document.getElementById(`createCampaignName`);
          searchElem?.focus();
          dispatch(setBlockingLoaderState({ active: false }));
          return;}
        commonNotification({
          msg: getErrorMessage(err),
          type: 'danger',
        });
        dispatch(setBlockingLoaderState({ active: false }));
      }
    );
  };

  // Change handler for handling each form value change and updating each form data
  const changeHandler = (val, name) => {
    setFormData({ ...formData, [name]: val });
    // Reset selected properteis, if advertiser change
    if (name === 'currentAdvertiser') {
      setSelectedProperties([]);
    }
  };

  // Handler for cancel - navigates to the campaign page
  const handleCancel = () => {
    if (state?.campaignData) {
      navigate(-1);
    } else {
      navigate('/campaigns');
    }
  };

  const enableSave =
    name.trim().length > 2 &&
    currentAdvertiser.name !== 'All' &&
    startDate &&
    endDate &&
    startDate <= endDate;

  const closeModal = () => {
    setStartDate(null);
    setEndDate(null);
    setCreatedCampaignId(null);
    setCreatedCampaignName(null);
    setSelectedProperties([]);
    setAvailableProperties(
      availableProperties?.map((item) => {
        return {
          ...item,
          selected: false,
        };
      })
    );
    setTabSelected(false);
    setShowModal(false);
    resetForm();
  };

  //Created this function so that when the user picks a start date
  //and this is placed in the minDate of the endDate date picker, it will
  //make it so users cannot choose a date before the start date.
  const dayAfterStart = () => {
    if (startDate) {
      return moment.utc(startDate).add(1, 'days').format('YYYY-MM-DD');
    }
  };

  function getSuggestedCloneName(campaignName) {
    let campaignMaxLength = 45;
    let cloneLength = 5;
    let nameLength = campaignName.length;
    let suggestedCampaignName;

    if (nameLength >= campaignMaxLength) {
      suggestedCampaignName = campaignName.substring(0, 38) + '_clone';
    } else {
      let lastIndexOfCloneWord = campaignName.lastIndexOf('clone');

      if (lastIndexOfCloneWord === -1) {
        suggestedCampaignName = campaignName + '_clone';
      } else {
        let lengthOfNameShouldBe = lastIndexOfCloneWord + cloneLength;
        let numberOfExtraChar = nameLength - lengthOfNameShouldBe;
        let extraChar = campaignName.substr(
          lengthOfNameShouldBe,
          numberOfExtraChar
        );

        if (lengthOfNameShouldBe === nameLength) {
          suggestedCampaignName = campaignName + 1;
        } else if (isNaN(extraChar)) {
          suggestedCampaignName = campaignName + '_clone';
        } else {
          let nextNumber = Number(extraChar) + 1;
          suggestedCampaignName =
            campaignName.substring(0, lengthOfNameShouldBe) + nextNumber;
        }
      }
    }
    return suggestedCampaignName;
  }

  const showNoPropertyWarningPopUp = () => {
    setShowCreatePropertyModal(true);
  };


  const cancelCreatePropertyModal = () => {
    setShowCreatePropertyModal(false);
  };

  const navigateToCreateProperty = () => {
    dispatch(
      updateSelectedAdvertiser(currentAdvertiser.id)
    );
    navigate('/properties/new');
  };

  return (
    <div className='create-campaign'>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <Link to='/'>
            <div className='homeBlue'></div>
          </Link>
          <div className='breadCrumbBody'>
            /
            <Link to='/campaigns'>
              <div className='active'>{breadCrumbHeader.campaigns}</div>
            </Link>
          </div>
          <div className='breadCrumbBody'>
            <span>/</span>
            {state?.campaignData ? "Clone Campaign" : "Create Campaign"}
          </div>
        </div>
      </div>

      <div className='createCampaignWrapper'>
        <CommonHeadingComponent headingLabel={state?.campaignData ? "Clone Campaign" : "Create Campaign"}/>
        <Container>
          <Row className='noMarginPadding createCampaignBodyWrapper'>
            <Col sm={12} className='detailsField dataFieldRelative'>
            <CommonInput
                  label={'Name'}
                  inputValue={name}
                  setInputValue={(val) => {
                    changeHandler(val, 'name')
                    if(val.length>100){
                      setValidName({valid:false, errorMsg: 'Maximum allowed characters for Name is 100'})
                    } else{
                      setValidName({valid: true, errorMsg: ''})
                    }
                  }}
                  labelClass={'requiredInput'}
                  uniqueIdentifier='createCampaignName'
                  placeholder={'Enter Name of Campaign'}
                  onBlurHandle={(val)=>{
                    if(!val.target.value.length){
                      setValidName({valid:false, errorMsg: 'Please enter a Campaign Name'})
                    } else if (val.target.value.length < 3){
                      setValidName({valid:false, errorMsg: 'Minimum 3 characters is required for Name'})
                    } else if(val.target.value.length > 100){
                      setValidName({valid:false, errorMsg: 'Maximum allowed characters for Name is 100'})
                    } else{
                      setValidName({valid: true, errorMsg: ''})
                    }
                  }}
                  className={validName.valid? 'validInput' : 'invalidInput'}
                />
              {!validName.valid&&<span className='error'>{validName.errorMsg}</span>}
            </Col>
            <Col lg={6} md={12} className='detailsField'>
              <SearchableDropdownComponent
                options={advertiser.selectedClientsAdvertiser?.slice(1)}
                label='name'
                id='name'
                dropdownLabel='Advertiser'
                selectedValue={
                  currentAdvertiser.name === 'All' ? '' : currentAdvertiser
                }
                handleChange={(val) => {
                  if (val !== null) {
                    changeHandler(val, 'currentAdvertiser');
                  }
                }}
                wrapperClass='requiredDD'
                dropdownId='advertiser'
                isDisabled={advertiser.selectedAdvertiser > 0}
                placeholderLabel='Select an Advertiser'
                uniqueIdentifier='createCampaignAdvertiserList'
              />
            </Col>
            <Col lg={6} md={12} className='detailsField'>
              <SearchableDropdownComponent
                options={statusOptions}
                label='label'
                id='label'
                dropdownLabel='Status'
                selectedValue={status}
                handleChange={(val) => changeHandler(val, 'status')}
                wrapperClass='requiredDD'
                dropdownId='status'
                isSearchable={false}
                uniqueIdentifier='createCampaignStatus'
              />
            </Col>

            <Col lg={6} md={12} className='detailsField dataFieldRelative'>
                <DatePickerComponent
                  datePickerId='cCStartDate'
                  label='Start Date'
                  placeholderLabel='Start Date'
                  minDate={today && moment.utc(today)}
                  startOrEndDate={startDate}
                  setStartOrEndDate={setStartDate}
                  isMandatory
                  format='MM/DD/YYYY'
                  needYearSelector={true}
                  futureYearNeeded={true}
                  startYear={moment.utc(today).year() - 1}
                  futureYear={moment.utc(today).year() + 9}
                  uniqueIdentifier='createCampaignStart'
                  className={validStartDate.valid?'inputField inputCalendar cursorPointer': 
                  'inputField inputCalendar cursorPointer invalidInput'}                  
                />
              {!validStartDate.valid&&<span className='error'>{validStartDate.errorMsg}</span>}
            </Col>
            <Col lg={6} md={12} className='detailsField dataFieldRelative'>
                <DatePickerComponent
                  datePickerId='cCEndDate'
                  label='End Date'
                  placeholderLabel='End Date'
                  minDate={startDate || tomorrow}
                  startOrEndDate={endDate}
                  setStartOrEndDate={setEndDate}
                  isMandatory
                  format='MM/DD/YYYY'
                  needYearSelector={true}
                  futureYearNeeded={true}
                  startYear={moment.utc(today).year() - 1}
                  futureYear={moment.utc(today).year() + 9}
                  uniqueIdentifier='createCampaignEnd'
                  className={validEndDate.valid?'inputField inputCalendar cursorPointer': 
                  'inputField inputCalendar cursorPointer invalidInput'}
                />
              {!validEndDate.valid&&<span className='error'>{validEndDate.errorMsg}</span>}
            </Col>
            <Col lg={6} md={12} className='detailsField'>
              <SearchableDropdownComponent
                options={reportOptions}
                label='label'
                id='label'
                dropdownLabel='Lift Report'
                selectedValue={liftReport}
                handleChange={(val) => changeHandler(val, 'liftReport')}
                wrapperClass='requiredDD'
                dropdownId='liftReport'
                isSearchable={false}
                uniqueIdentifier='createCampaignLiftReport'
              />
            </Col>
            <Col lg={6} md={12} className='detailsField dataFieldRelative'>
              <CommonInput
                label='Order Number'
                inputValue={orderNumber}
                setInputValue={(val) => {
                  changeHandler(val, 'orderNumber')
                  if (val.length > 50) {
                    setValidOrderNumber({valid:false, errorMsg: 'Maximum allowed characters for Order Number is 50'})
                  } else{
                    setValidOrderNumber({valid: true, errorMsg: ''})
                  }
                }}
                uniqueIdentifier='createCampaignOrderNumber'
                placeholder='Enter Order Number'
              />
              {!validOrderNumber.valid && <span className='error'>{validOrderNumber.errorMsg}</span>}
            </Col>
            <Col lg={6} md={12} className='detailsField'>
              <div className='fieldLabel'>Description</div>
              <textarea
                id='createCampaignDescription'
                name='createCampaignDescription'
                value={description}
                onChange={(e) => changeHandler(e.target.value, 'description')}
                placeholder='Enter Description'
              />
            </Col>
            <Col lg={6} md={12} className='detailsField'>
              <Panel
                label='Attached Properties'
                nameField='name'
                values={checkCondition(
                  tabSelected,
                  selectedProperties,
                  availableProperties
                )}
                setTabSelected={setTabSelected}
                tabSelected={tabSelected}
                selectedData={selectedProperties}
                setSelectedData={setSelectedProperties}
                availableData={availableProperties}
                setAvailableData={setAvailableProperties}
                showModal={showModal}
                uniqueIdentifier='createCampaign'
              />
            </Col>
          </Row>
          <Row className='noMarginPadding createCampaignButtonWrapper'>
            <Col
              sm={12}
              className='campaignListButtonWrapper text-center mt-3 mb-4'
            >
              <CommonButtonComponent
                label='Save'
                Create
                User
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnPrimary'
                isDisabled={!enableSave || !validName.valid ||
                !validStartDate.valid || !validEndDate.valid || !validOrderNumber.valid}
                onClickCTA={handleSave}
                buttonUI='createdCampaignSave'
              />
              <CommonButtonComponent
                label='Cancel'
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnSecondary'
                onClickCTA={handleCancel}
                buttonUI='createdCampaignCancel'
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Component for redirect modal */}
      {showModal && (
        <RedirectModalComponent
          modalBodyElements={[
            'createPlacement',
            'createCampaign2',
            'campaignList',
            'campaignDetails',
            'reportingDashboard',
            'createProperty',
          ]}
          messageType='Campaign'
          detailsElements={{
            createPlacement: {
              routeParam: [
                { param: `${createdCampaignId}/placement/new`, index: 0 },
              ],
            },
            campaignDetails: {
              routeParam: [{ param: createdCampaignId, index: 0 }],
              queryParam: [
                {
                  params: getEncryptedOrDecryptedData(
                    {
                      name: createdCampaignName,
                    },
                    'encrypt'
                  ),
                },
              ],
            },
          }}
          onCloseNav={`/campaign/${createdCampaignId}?params=${getEncryptedOrDecryptedData(
            { name: createdCampaignName },
            'encrypt'
          )}`}
          samePageRedirect='createCampaign2'
          handleSamePageRedirect={() => {
            closeModal();
            getProperties();
          }}
        />
      )}
      {<CommonModal
        show={showCreatePropertyModal}
        header=''
        body={
          <div>
            <br />
            No property is available for this advertiser. Would you like to create a new one?
            <br />
            <br />
          </div>
        }
        close={cancelCreatePropertyModal}
        reject={cancelCreatePropertyModal}
        rejectBtnText='Cancel'
        acceptBtnText='Create Property'
        accept={navigateToCreateProperty}
        size='md2'
      />}
    </div>
  );
};

export default CreateCampaign;

const getErrorMessage = (err) => {
  if (err?.response?.data?.message) {
    return err.response.data.message;
  }
  
  if (err?.response?.data?.error?.code) {
    if (err.response.data.error.code === 'ER_DUP_ENTRY') {
      return 'A Campaign with the same Start Date and Name already exists. Please change the Campaign Name.'
    }
    return err.response.data.error?.message;
  }

  if (err?.error) {
    return err.error;
  }

  return 'Something went wrong';
}
