import React from 'react';
import { Link } from 'react-router-dom';
import { LoaderComponent } from '../../../../components';

const CommonAccountBreadCrum = ({ isLoading, name }) => {
  return (
    <div className='breadCrumbOuterWrapper'>
      <div className='breadCrumbInnerWrapper'>
        <Link to='/'>
          <div className='homeBlue'></div>
        </Link>
        <>
          <div className='breadCrumbBody'>
            /
            <Link to='/admin/accounts'>
              <div className='active'>Accounts</div>
            </Link>
          </div>
          <div className='breadCrumbBody'>
            <span>/</span>
            {setHeading(isLoading, name)}
          </div>
        </>
      </div>
    </div>
  );
};

export default CommonAccountBreadCrum;

const setHeading = (isLoading, name) => {
  if (!isLoading) {
    return name;
  }
  return <LoaderComponent importedClassNames='dropdownsAndDates' />;
};
