import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Bar,
  Cell
} from "recharts";
import moment from 'moment';
import {
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  NoDataComponent,
  SearchableDropdownComponent,
} from '../../../../../../components';
import { axiosSendRequest, axiosGetRequest } from '../../../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../../../constants';
import { performanceDropdown, performanceOrderByDropdownEngagement, performanceOrderByDropdownConversion, performanceSortingDropdown } from '../../../../../../constants/dropdownLabels';
import {
  getAllSelectedOrNot,
  getArrayOfData,
  getGoalListAndType,
} from '../../../../../../utils/functions';
import './campaignPerformance.css';


const CampaignPerformance = ({
  campaign,
  startDate,
  endDate,
  mediaPartnerList,
  mediaPartner,
  placementList,
  placement,
  creativeTypeList,
  creativeType,
  parametersIsLoading,
  ifSelectedAll,
}) => {

  const [cancelController, setCancelController] = useState(null);
  const [usableGoalListOpts, setUsableGoalListOpts] = useState([]);
  const [goalList, setGoalList] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isGoalsLoading, setIsGoalsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [performanceOptions, setPerformanceOptions] = useState(performanceDropdown);
  const [basedOnSelector, setBasedOnSelector] = useState(performanceDropdown[0]);
  const [orderBySelector, setOrderBySelector] = useState(performanceOrderByDropdownEngagement[0]);
  const [orderByDropdownOptions, setOrderByDropdownOptions] = useState(performanceOrderByDropdownEngagement);
  const [sortingSelector, setSortingSelector] = useState(performanceSortingDropdown[1]);
  const [performanceData, setPerformanceData] = useState({});
  const [effectivenessData, setEffectivenessData] = useState({});
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setCancelController(controller);
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      resetData();
    }
    if (
      !parametersIsLoading.campaign &&
      !parametersIsLoading.mediaPartner &&
      !parametersIsLoading.creativeType &&
      !parametersIsLoading.placement &&
      startDate &&
      endDate
    ) {
      getGoals();
    }
  }, [startDate, endDate, placement]);

  useEffect(() => {
    const generatedGoalList = generateUsableGoalList(goalList);
    setUsableGoalListOpts(generatedGoalList);
    if (generatedGoalList?.length) {
      setSelectedGoal(generatedGoalList[0]);
    }
  }, [goalList]);

  useEffect(() => {
    if (selectedGoal) {
      if (selectedGoal.type === 'conversion') {
        setOrderBySelector(performanceOrderByDropdownConversion[0]);
        setOrderByDropdownOptions(performanceOrderByDropdownConversion);
      } else {
        setOrderBySelector(performanceOrderByDropdownEngagement[0]);
        setOrderByDropdownOptions(performanceOrderByDropdownEngagement);
      }
      getCampaignPerformance(basedOnSelector.id  === 2);
    }
  }, [selectedGoal]);

  useEffect(() => {
    if (orderBySelector) {
      setDataSorting();
    }
  }, [orderBySelector, sortingSelector]);

  const getGoals = async () => {
    setIsGoalsLoading(true);
    try {
      const goalList = await axiosSendRequest(
        `${baseEndpoints.reports}/goal-list`,
        cancelController,
        {
          campaign_id: campaign.campaign_id,
          placement_id: getArrayOfData(placement, 'placement_id'),
          start_date: startDate,
          end_date: endDate,
        }
      );
      if (goalList?.status === 200 && goalList?.data?.length > 0) {
        setGoalList(goalList.data);
      }
    } catch(e) {
      if (!axios.isCancel(e)) {
        console.log(e.message);
        setIsError('Goals_Error');
      }
    } finally {
      setIsGoalsLoading(false);
    }
  };

  const getCampaignPerformance = async (placementBasedDropdownSelected) => {
    let partner_id, setPlacementBased = false;
    setIsLoadingData(true);
    setNoDataAvailable(false);
    setIsError(null);
    try {
      const { goal_id, goal_type } = getGoalListAndType(selectedGoal);
      const if_all_partners = getAllSelectedOrNot(mediaPartnerList, mediaPartner, 'partner_id');
      const if_all_placements = getAllSelectedOrNot(placementList, placement, 'placement_id');
      const if_all_creative_types = getAllSelectedOrNot(creativeTypeList, creativeType, 'value');
      if (!if_all_creative_types || !if_all_placements) {
        setIsError('Data_Not_Selected');
        return;
      }
      if (!if_all_partners) {
        // only allow data based on placements
        setBasedOnSelector(performanceDropdown[1]);
        setPerformanceOptions([performanceDropdown[1]]);
        setPlacementBased = true;
        partner_id = getArrayOfData(mediaPartner, 'partner_id').join(',');
      }
      // Not allowing future dates
      if (moment(startDate).isAfter(moment())) {
        startDate = moment().format('YYYY-MM-DD');
      }
      if (moment(endDate).isAfter(moment())) {
        endDate = moment().format('YYYY-MM-DD');
      }
      const performanceData = await axiosGetRequest(
        `${baseEndpoints.reports}/attribution-tab/performance`,
        cancelController?.signal,
        {
          campaign_id: campaign.campaign_id,
          partner_id,
          start_date: startDate,
          end_date: endDate,
          goal_type: goal_type || 'engagement',
          goal_id,
          placement_based: placementBasedDropdownSelected || setPlacementBased
        }
      );
      if (performanceData?.status === 200 && performanceData?.data?.percentages?.length > 0) {
        setDataSorting(performanceData?.data);
      } else if (performanceData?.status === 200) {
        setPerformanceData({});
        setEffectivenessData({});
        setNoDataAvailable(true);
      } else {
        setIsError('Performance_Error');
      }
    } catch(e) {
      if (!axios.isCancel(e)) {
        console.log(e.message);
        setIsError('Performance_Error');
      }
    } finally {
      setIsLoadingData(false);
    }
  };

  const generateUsableGoalList = (goalList) => {
    if (!goalList?.length) {
      return [];
    }
  
    const allGoalOption = [];
    const engagementOptions = [
        {
          id: -2,
          name: 'Engagement Goals',
          type: 'engagement',
          isParent: true,
        },
      ],
      conversionOptions = [
        {
          id: -3,
          name: 'Conversion Goals',
          type: 'conversion',
          isParent: true,
        },
      ];
  
    goalList.map((goal) => {
      if (goal.type === 'engagement') {
        engagementOptions.push({ ...goal, isParent: false });
      } else {
        conversionOptions.push({ ...goal, isParent: false });
      }
    });
  
    const newGoalOptions = allGoalOption.concat(
      engagementOptions.length > 1 ? engagementOptions : [],
      conversionOptions.length > 1 ? conversionOptions : []
    );
  
    if (newGoalOptions.length > 1) {
      return newGoalOptions;
    }
  
    return [];
  };

  const setDataSorting = (data) => {
    let { goal_type } = getGoalListAndType(selectedGoal);
    const sortVar = (orderBySelector.id === 1)
      ? "impressionCount" 
      : (goal_type === 'conversion') ? 'conversionCount' : 'engagementCount';
    const effectivenssSortVar = 'index';
    let sortedData, effectivenessSortedData;
    if (data) {
      sortedData = JSON.parse(JSON.stringify(data));
      effectivenessSortedData = JSON.parse(JSON.stringify(data));
    } else {
      sortedData = { ...performanceData };
      effectivenessSortedData = { ...effectivenessData };
    }
    if (sortingSelector.id === 1) {
      sortedData?.percentages?.sort((a, b) => {
        return a[sortVar] - b[sortVar];
      });
      effectivenessSortedData?.percentages?.sort((a, b) => {
        return a[effectivenssSortVar] - b[effectivenssSortVar];
      });
    } else {
      sortedData?.percentages?.sort((a, b) => {
        return b[sortVar] - a[sortVar];
      });
      effectivenessSortedData?.percentages?.sort((a, b) => {
        return b[effectivenssSortVar] - a[effectivenssSortVar];
      });
    }
    setPerformanceData(sortedData);
    setEffectivenessData(effectivenessSortedData);
  };

  const resetData = () => {
    setPerformanceData({});
    setEffectivenessData({});
    setGoalList(null);
    setSelectedGoal(null);
    setUsableGoalListOpts([]);
    setPerformanceOptions(performanceDropdown);
  };

  const getConversionBar = () => {
    let { goal_type } = getGoalListAndType(selectedGoal);
    if(goal_type === 'conversion') {
      return ( 
        <Bar
          dataKey="conversionPercentage"
          name="% Conversions"
          fill="#ff7c1f"
        />
      ); 
    } else {
      return ( 
        <Bar
          dataKey="engagementPercentage"
          name="% Engagements"
          fill="#03bdc9"
        />
      ); 
    } 
  }

  const renderWarningMessage = () => {
    return (
      <div className='performanceWarningWrapper'>Feature is available when all placements are selected.</div>
    );
  };

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      let {
        impressionCount,
        conversionCount,
        engagementCount,
        impressionPercentage,
        conversionPercentage,
        engagementPercentage,
        effectivenessIndex,
      } = payload[0].payload;
  
      impressionPercentage = impressionPercentage
        ? Math.round(impressionPercentage * 100) / 100 : 0;
      conversionPercentage = conversionPercentage
        ? Math.round(conversionPercentage * 100) / 100 : 0;
      engagementPercentage = engagementPercentage
        ? Math.round(engagementPercentage * 100) / 100 : 0;
  
      impressionCount = impressionCount ? impressionCount.toLocaleString() : 0;
      conversionCount = conversionCount ? Math.round(conversionCount).toLocaleString() : 0;
      engagementCount = engagementCount ? Math.round(engagementCount).toLocaleString() : 0;
  
      return (
        <div
          className="recharts-default-tooltip"
          style={{
            background: "#ffffff",
            border: "1px solid lightgray",
            padding: "5px",
          }}
        >
          <p className="recharts-tooltip-label">{label}</p>
          <p
            className="recharts-tooltip-label"
            style={{ color: "#271350" }}
          >{`Impressions: ${impressionPercentage}%, Total: ${impressionCount}`}</p>
          {payload[1] && payload[1].name == '% Engagements' && [
            <p
              key={label}
              className="recharts-tooltip-label"
              style={{ color: "#47a1ab" }}
            >{`Engagements: ${engagementPercentage}%, Total: ${engagementCount}`}</p>
          ]}
          {payload[1] && payload[1].name == '% Conversions' && [
            <p
              key={label}
              className="recharts-tooltip-label"
              style={{ color: "#f56600" }}
            >{`Conversions: ${conversionPercentage}%, Total: ${conversionCount}`}</p>
          ]}
          <p
            className="recharts-tooltip-label"
            style={{ color: "#6b8f00" }}
          >{`Index: ${Math.round(effectivenessIndex)}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const renderPerformanceChart = () => {
    let chartHeight, chartData;
    chartData = performanceData && performanceData.percentages ? performanceData.percentages : [];
    chartHeight = chartData?.length < 11 ? 450 : chartData.length * 50;
    return (
      <div className='performanceChartWrapper'>
        <ResponsiveContainer
          height={chartHeight}
        >
          <BarChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 5,
            }}
            barSize={12}
            layout="vertical"
            barCategoryGap={20}
          >
            <CartesianGrid
              strokeDasharray="2 2"
              stroke="#cccccc55"
              vertical={false}
            />
            <XAxis type="number" tickFormatter={(value) => `${value}%`} />
            <YAxis
              dataKey="name"
              type="category"
              width={220}
              tickFormatter={(value) => {
                return value.length > 20
                  ? `${value.substring(0, 20)}...`
                  : value;
              }}
              height={500}
            />
            <Tooltip
              content={renderTooltip}
              cursor={{ fill: "transparent" }}
            />
            <Legend verticalAlign="top" height={48} />
            
            <Bar
              dataKey="impressionPercentage"
              name="% Impressions"
              fill="#271350"
            />
            { getConversionBar() }
            </BarChart>
            </ResponsiveContainer>
            <div style={{ textAlign: "center", marginTop: '25px' }}>
              Performance by {basedOnSelector?.bottomLabel} (All {selectedGoal?.type.charAt(0).toUpperCase() + selectedGoal?.type.slice(1)})
            </div>
      </div>
    );
  };

  const renderEffectivenessChart = () => {
    let chartHeight, chartData;
    chartData = effectivenessData && effectivenessData.percentages ? effectivenessData.percentages : [];
    chartHeight = chartData?.length < 11 ? 450 : chartData.length * 50;
    return (
      <div className='performanceChartWrapper'>
        <ResponsiveContainer
          height={chartHeight}
        >
          <BarChart
            data={chartData.filter((item) => {
              return item.impressionPercentage > 1;
            })}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 5,
            }}
            barSize={10}
            layout="vertical"
          >
            <CartesianGrid
              strokeDasharray="2 2"
              stroke="#cccccc55"
              vertical={false}
            />
            <XAxis
              type="number"
              tickFormatter={(item) => {
                return (parseInt(item, 10) + 100).toLocaleString();
              }}
              domain={[-100, (dataMax) => Math.ceil(dataMax / 100) * 100]}
              interval={"preserveStart"}
              tickCount={10}
            />
            <YAxis
              dataKey="name"
              type="category"
              width={200}
              tickFormatter={(value) => {
                return value.length > 20
                  ? `${value.substring(0, 20)}...`
                  : value;
              }}
            />
            <ReferenceLine x={0} stroke="darkgray" />
            <Tooltip
              cursor={{ fill: "transparent" }}
              labelFormatter={(label) => label}
              formatter={(value, type, node) => {
                return `${Math.round(value + 100).toLocaleString()}`;
              }}
            />
            <Legend verticalAlign="top" height={48} />
            <Bar
              dataKey="index"
              name="Index of Effectiveness"
              fill="#47a1ab"
            >
              {chartData
                .filter((item) => item.impressionPercentage > 1)
                .map((node) => {
                  return (
                    <Cell fill={node.index > 0 ? "#47a1ab" : "#f56600"} />
                  );
                })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <div style={{ textAlign: "center", marginTop: '25px' }}>
          Effectiveness by {basedOnSelector?.bottomLabel} (All {selectedGoal?.type.charAt(0).toUpperCase() + selectedGoal?.type.slice(1)})
        </div>
        <div style={{ textAlign: "center", fontSize: '12px' }}>
          *Only showing {basedOnSelector?.bottomLabel}s with Impression Percentage
          greater than 1%
        </div>
        <div style={{ textAlign: "center", fontSize: '12px' }}>
          {campaign?.lookback && (
            <>
              Impressions are inclusive of {campaign?.lookback} days prior to the
              user-selected time period to reflect the attribution window.
            </>
          )}
        </div>
      </div>
    );
  };

  const renderCampaignPerformance = () => {
    return (
      <div>
      <div className='campaignPerformanceWrapper'>
        <CommonHeadingComponent
          headingLabel='Campaign Performance Drivers'
          headingCTALabel={
            <HeadeingSearchField
              goalList={usableGoalListOpts}
              selectedGoal={selectedGoal}
              setSelectedGoal={setSelectedGoal}
              isLoading={isGoalsLoading}
              ifSelectedAll={ifSelectedAll}
              parametersIsLoading={parametersIsLoading}
              isError={isError}
            />
          }
          ifDropdown={true}
        />
      </div>
      {!(isLoadingData
        || determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
        || isError === 'Data_Not_Selected')
        && <div className='partnerPlacementButtonWrapper'>
        <SearchableDropdownComponent
          options={performanceOptions}
          label='name'
          id='id'
          dropdownId='performanceSelectorAT'
          dropdownLabel='Based On'
          labelWidth={{ marginRight: '15px' }}
          dropdownWidth={{
            width: '110px',
          }}
          singleSelectDropdownFieldClass={'basedOnSelectorCSS'}
          selectedValue={basedOnSelector}
          handleChange={async (val) => {
            if (val.id !== basedOnSelector.id) {
              setBasedOnSelector(val);
              getCampaignPerformance(val.id === 2);
            }
          }}
          isSearchable={false}
          isDisabled={!campaign}
          uniqueIdentifier='reportDetailsAttributionCampaignPerformanceSelector'
        />
        <SearchableDropdownComponent
          options={orderByDropdownOptions}
          label='name'
          id='id'
          dropdownId='performanceSelectorAT'
          dropdownLabel='Order By'
          labelWidth={{ marginRight: '15px' }}
          wrapperClass={'performanceFilterMargin'}
          dropdownWidth={{
            width: '125px'
          }}
          singleSelectDropdownFieldClass={'basedOnSelectorCSS'}
          selectedValue={orderBySelector}
          handleChange={setOrderBySelector}
          isSearchable={false}
          isDisabled={!campaign}
          uniqueIdentifier='reportDetailsAttributionCampaignPerformanceSelector'
        />
        <SearchableDropdownComponent
          options={performanceSortingDropdown}
          label='name'
          id='id'
          dropdownId='performanceSelectorAT'
          dropdownLabel='Sorting'
          labelWidth={{ marginRight: '15px' }}
          wrapperClass={'performanceFilterMargin'}
          dropdownWidth={{
            width: '125px'
          }}
          singleSelectDropdownFieldClass={'basedOnSelectorCSS'}
          selectedValue={sortingSelector}
          handleChange={setSortingSelector}
          isSearchable={false}
          isDisabled={!campaign}
          uniqueIdentifier='reportDetailsAttributionCampaignPerformanceSelector'
        />
      </div>}
      <div>
        {(isLoadingData || determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll))
          ? <LoaderComponent headerClass='performanceLoaderWrapper' />
          : isError === 'Performance_Error' || isError === 'Goals_Error' ? <ErrorComponent
            refreshAction={() => getCampaignPerformance()}
            controller={cancelController}
        /> : isError === 'Data_Not_Selected' ? renderWarningMessage() :
        noDataAvailable ? <NoDataComponent
          type='bar'
          customLayerStyle={{ backgroundSize: '30%' }}
          customImageStyle={{ backgroundSize: '20%' }}
        /> : renderPerformanceChart()}
      </div>
    </div>
    );
  };

  const renderEffectivenessByMediaPartner = () => {
    return (
      <div>
        <div className='campaignPerformanceWrapper'>
          <div>
            {(isLoadingData || determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll))
              ? <LoaderComponent headerClass='performanceLoaderWrapper' />
              : isError === 'Performance_Error' || isError === 'Goals_Error' ? <ErrorComponent
                refreshAction={() => getCampaignPerformance()}
                controller={cancelController}
            /> : isError === 'Data_Not_Selected' ? <div/> :
            noDataAvailable ? <NoDataComponent
              type='bar'
              customLayerStyle={{ backgroundSize: '30%' }}
              customImageStyle={{ backgroundSize: '20%' }}
            /> : renderEffectivenessChart()}
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div>
      {renderCampaignPerformance()}
      {renderEffectivenessByMediaPartner()}
    </div>
  );

};

const determineSummaryHeaderLoading = (parametersIsLoading, ifSelectedAll) => {
  return Object.values(parametersIsLoading).includes(true) || !ifSelectedAll;
};

const HeadeingSearchField = ({
    goalList,
    selectedGoal,
    setSelectedGoal,
    isLoading,
    ifSelectedAll,
    parametersIsLoading,
    isError
  }) => (
    <SearchableDropdownComponent
      options={goalList}
      label='name'
      id='id'
      dataType='goal'
      dropdownId='attrEngagementAndConversionGoal'
      dropdownLabel=''
      labelWidth={{ margin: 0 }}
      dropdownWidth={{
        width: '200px',
      }}
      wrapperClass=''
      placeholderLabel={goalList?.length ? 'Select Goal' : 'Goal unavailable'}
      selectedValue={selectedGoal}
      handleChange={setSelectedGoal}
      isSearchable={false}
      isLoading={
        isLoading ||
        determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
      }
      isLoadingAvailable={2}
      loaderWidth={{ width: '22px' }}
      isDisabled={
        !goalList?.length ||
        determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll) ||
        isError === 'Data_Not_Selected'
      }
      isParentChildBasedOption={true}
      parentIdentificationKey='isParent'
      uniqueIdentifier='attrEngagementAndConversionGoals'
      ifIdVisableOnLabel={true}
    />
  );

export default CampaignPerformance;

// Validating prop types 
CampaignPerformance.propTypes = {
  campaign: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  mediaPartnerList: PropTypes.array,
  mediaPartner: PropTypes.array,
  placementList: PropTypes.array,
  placement: PropTypes.array,
  creativeTypeList: PropTypes.array,
  creativeType: PropTypes.array,
  parametersIsLoading: PropTypes.object,
  ifSelectedAll: PropTypes.bool
}

HeadeingSearchField.propTypes = {
  goalList: PropTypes.array,
  selectedGoal: PropTypes.object,
  setSelectedGoal: PropTypes.func,
  isLoading: PropTypes.bool,
  ifSelectedAll: PropTypes.bool,
  parametersIsLoading: PropTypes.object,
  isError: PropTypes.string
} 