import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../../utils/functions';

export default [
  {
    id: 'organization',
    name: 'ACCOUNTS',
    selector: (row) => (
      <div className='accountListCellWrapper'>
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link to={'' + row?.user_id}>
            <div className='linkStyleLR'>{row?.organization}</div>
          </Link>
        </div>
        {Boolean(row.is_partner) && (
          <div className='partnerAccountWrapper'>Partner account</div>
        )}
      </div>
    ),
    sortable: true,
    sortField: 'organization',
    // width: '145px',
    grow: 5,
  },
  {
    id: 'email',
    name: 'EMAIL',
    selector: (row) => (
      <div className='tableCellFontStyle tableCampNameFontStyle'>
        <Link to={'' + row?.user_id}>
          <div className='linkStyleLR'>{row.email}</div>
        </Link>
      </div>
    ),
    sortable: true,
    sortField: 'email',
    // style: { minWidth: '120px' },
    grow: 5,
  },
  {
    id: 'created',
    name: 'CREATED',
    selector: (row) => (
      <div className='tableCellFontStyle '>
        {row?.created ? dateFormat(row?.created, 'MM/DD/YYYY') : ''}
      </div>
    ),
    sortable: true,
    sortField: 'created',
    // style: { minWidth: '120px' },
    grow: 2,
  },
  {
    id: 'last_connect',
    name: 'LAST CONNECT',
    selector: (row) => {
      if (row?.last_connect) {
        return (
          <div className='tableCellFontStyle'>
            {dateFormat(row.last_connect, 'MM/DD/YYYY')}
          </div>
        );
      }

      return (
        <div className='tableCellFontStyle accountListLastConnect'>
          -
        </div>
      );
    },
    sortable: true,
    sortField: 'last_connect',
    // width: '140px',
    grow: 2,
  },
  {
    id: 'status',
    name: 'STATUS',
    selector: (row) => (
      <div className={`tableCellFontStyle status ${row.status}`}>
        {row?.status}
      </div>
    ),
    sortable: true,
    sortField: 'status',
    // width: '140px',
    grow: 1,
    // right: true
  },
  {
    id: 'modified',
    name: 'UPDATED',
    selector: (row) => {
      if (row?.modified) {
        return (
          <div className='tableCellFontStyle'>
            {dateFormat(row.modified, 'MM/DD/YYYY')}
          </div>
        );
      }

      return (
        <div className='tableCellFontStyle accountListModified'>
          -
        </div>
      );
    },
    sortable: true,
    sortField: 'modified',
    // style: { minWidth: '120px' },
    grow: 2,
  },
];
