import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetClientMode,
  updateClientMode,
  updateShowUtilityBar,
} from '../../redux/utilitybarSlice';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useGetApiInterceptor } from '../../hooks/useGetApiInterceptor';
import { baseEndpoints, statusOptionList } from '../../constants';
import { Col, Container, Row } from 'react-bootstrap';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  SearchableDropdownComponent,
} from '../../components';

import './partnerDetails.css';
import {
  isValidateEmail,
  isValidateURL,
  toggleClasses,
} from '../../utils/functions';
import { commonNotification } from '../../utils/common-notifications';
import { usePatchAPIInterceptor } from '../../hooks/patchAPIInterceptor';
import { setBlockingLoaderState } from '../../redux/blockingLoader';

const PartnerDetails = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams(),
    partnerName = searchParams.get('name');
  document.title = 'Admin | Partner Details';

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isMounted, setIsMounted] = useState(false),
    [saveMount, setSaveMount] = useState(false),
    [savePayload, setSavePayload] = useState(null),
    [params, setParams] = useState(null),
    [defaultData, setDefaultData] = useState(null),
    [newData, setNewData] = useState(null),
    [isChanged, setChanged] = useState({
      name: false,
      status: false,
      click_tracking_only: false,
      website: false,
      email: false,
      documentation_link: false,
      description: false,
    }),
    [isValid, setValid] = useState({
      name: true,
      status: true,
      click_tracking_only: true,
      website: true,
      email: true,
      documentation_link: true,
      description: true,
    }),
    [nameError, setNameError] = useState({
      error: false,
      message: '',
    }),
    [isUpdating, setIsUpdating] = useState(false),
    [statusInfo, setStatusInfo] = useState(null);

  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.admin + '/partner-details'
  );

  usePatchAPIInterceptor(
    saveMount,
    savePayload,
    baseEndpoints.admin + `/partners/${defaultData?.partner_id}`,
    (status, data, error) => {
      if (status === 200 && data) {
        const msg = `Partner has been successfully updated. Redirecting to view all partners...`;
        commonNotification({ msg, type: 'success' });
        setTimeout(() => {
          navigate(`/admin/partners`);
        }, 2000);
      } else if (status === 422 && data.error_type === 'ER_DUP_ENTRY') {
        commonNotification({
          msg: 'Cannot have duplicate name for Partners',
          type: 'danger',
        });
        setNameError({
          error: true,
          message: 'Name already exists. Please enter a different name ',
        });
        setValid({
          ...isValid,
          name: false,
        });
        const searchElem = document.getElementById(
          'partnerDetailsPartnerNameInput'
        );
        searchElem?.focus();
      } else {
        commonNotification({
          msg: `Internal Server Error. Partner couldn't be updated`,
          type: 'danger',
        });
      }
      dispatch(setBlockingLoaderState({ active: false }));
      setIsUpdating(false);
    }
  );

  const validateURL = (urlToValidate) => {
    if (!urlToValidate?.length) {
      return true;
    }
    return isValidateURL(urlToValidate);
  };

  const validateEmail = (email) => {
    if (!email?.length) {
      return true;
    }
    return isValidateEmail(email);
  };

  useEffect(() => {
    setParams({ partner_id: id });
    setIsMounted(true);
  }, [id]);

  useEffect(() => {
    dispatch(updateShowUtilityBar(false));

    return () => {
      dispatch(updateShowUtilityBar(true));
    };
  }, []);

  useEffect(() => {
    dispatch(updateClientMode(true));

    return () => {
      dispatch(resetClientMode());
    };
  }, []);

  useEffect(() => {
    if (data?.length) {
      setDefaultData({ ...data[0] });
      setNewData({ ...data[0] });
      setStatusInfo(
        statusOptionList.find((item) => item.id === data[0]?.status)
      );
    } else {
      setDefaultData(null);
      setNewData(null);
    }
  }, [data]);

  useEffect(() => {
    if (newData?.name.length > 100) {
      setValid({
        ...isValid,
        name: false,
      });
      setNameError({
        error: true,
        message: 'Maximum allowed characters for Partner Name is 100',
      });
    } else if (newData?.name.length <= 0) {
      setValid({
        ...isValid,
        name: false,
      });
      setNameError({ error: true, message: 'Please enter Partner Name' });
    } else {
      setValid({
        ...isValid,
        name: true,
      });
    }
  }, [newData?.name]);

  useEffect(() => {
    if (statusInfo) {
      setNewData({ ...newData, status: statusInfo?.id });
    }
  }, [statusInfo]);

  useEffect(() => {
    if (newData?.status !== undefined)
      checkChange(
        'status',
        defaultData,
        newData?.status,
        isChanged,
        setChanged
      );
  }, [newData?.status]);

  /**Function to render Error component if there is an error in the API response */
  const checkError = () =>
    isError ? (
      <ErrorComponent refreshAction={() => setParams({ ...params })} />
    ) : (
      renderElement()
    );

  /**Function to render the form */
  const renderElement = () => (
    <>
      <Row className='basicDetailsWrapper noMarginPadding'>
        <Col xl={6} lg={6} md={12} sm={12} className='dataFieldRelative'>
          <DetailsField
            label='Partner Name'
            value={newData?.name}
            data={newData}
            setData={setNewData}
            field='name'
            defaultData={defaultData}
            isChanged={isChanged}
            setChanged={setChanged}
            uniqueIdentifier='partnerDetailsPartnerNameInput'
            mandatoryField={true}
            placeholder='Enter Partner Name'
            className={!isValid?.name ? 'invalidInput' : ''}
          />
          {!isValid?.name ? (
            <span className='error'>{nameError?.message}</span>
          ) : (
            <></>
          )}
        </Col>
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className='detailsField'>
            <div className='fieldLabel'>
              <span>Status</span>
              <span className='mandatoryField'>*</span>
            </div>
            <SearchableDropdownComponent
              options={statusOptionList}
              label='name'
              id='id'
              dropdownId='partnerDetailsStatus'
              dropdownLabel=''
              labelWidth={{ margin: 0 }}
              dropdownWidth={{
                width: '100%',
              }}
              placeholderLabel='Select Partner Status'
              selectedValue={statusInfo}
              handleChange={setStatusInfo}
              isSearchable={false}
              uniqueIdentifier='partnerDetailsStatuses'
            />
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} className='dataFieldRelative'>
          <div className='detailsField'>
            <div className='fieldLabel'>
              <span>Website</span>
            </div>
            <input
              id={'editPartnerDetailsWebsite'}
              name={'editPartnerDetailsWebsite'}
              defaultValue={newData?.website ?? ''}
              onChange={(e) => {
                if (!isValid.website) {
                  setValid({ ...isValid, website: true });
                }
                setNewData({ ...newData, website: e.target.value });
                checkChange(
                  'website',
                  defaultData,
                  e.target.value,
                  isChanged,
                  setChanged
                );
              }}
              onBlur={(e) => {
                setValid({
                  ...isValid,
                  website: validateURL(e.target.value),
                });
              }}
              className={
                !isValid?.website ? 'fieldInput invalidInput' : 'fieldInput'
              }
              placeholder={'Enter Website'}
            />
          </div>
          {!isValid?.website ? (
            <span className='error'>Enter a valid website</span>
          ) : (
            <></>
          )}
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} className='dataFieldRelative'>
          <div className='detailsField'>
            <div className='fieldLabel'>
              <span>email</span>
            </div>
            <input
              id={'editPartnerDetailsEmail'}
              name={'editPartnerDetailsEmail'}
              defaultValue={newData?.email}
              onChange={(e) => {
                if (!isValid.email) {
                  setValid({ ...isValid, email: true });
                }
                setNewData({ ...newData, email: e.target.value });
                checkChange(
                  'email',
                  defaultData,
                  e.target.value,
                  isChanged,
                  setChanged
                );
              }}
              onBlur={(e) => {
                setValid({
                  ...isValid,
                  email: validateEmail(e.target.value),
                });
              }}
              className={
                !isValid?.email ? 'fieldInput invalidInput' : 'fieldInput'
              }
              placeholder={'Enter Email'}
            />
          </div>
          {!isValid?.email ? (
            <span className='error'>Enter a valid email</span>
          ) : (
            <></>
          )}
        </Col>
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className='detailsField'>
            <div className='fieldLabel'>Description</div>
            <textarea
              id='editPartnerDetailsDescription'
              name='editPartnerDetailsDescription'
              value={newData?.description}
              onChange={(e) => {
                setNewData({
                  ...newData,
                  description: e.target.value,
                });
                checkChange(
                  'description',
                  defaultData,
                  e.target.value,
                  isChanged,
                  setChanged
                );
              }}
              placeholder='Enter Description'
            />
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12}>
          <DetailsField
            label='Documentation Link'
            value={newData?.documentation_link}
            data={newData}
            setData={setNewData}
            field='documentation_link'
            defaultData={defaultData}
            isChanged={isChanged}
            setChanged={setChanged}
            uniqueIdentifier='editPartnerDetailsDocumentationLink'
            placeholder='Enter Documentation Link'
          />
          <div className='detailsField'>
            <div className='fieldLabel'>Click Tracking Only</div>
            <div className='toggleSwitch'>
              <input
                type='checkbox'
                className='checkbox'
                name={'click_tracking_only'}
                id={'click_tracking_only'}
                checked={newData?.click_tracking_only || false}
                onChange={(e) => {
                  setNewData({
                    ...newData,
                    click_tracking_only: newData?.click_tracking_only ? 0 : 1,
                  });
                  checkChange(
                    'click_tracking_only',
                    defaultData,
                    !newData?.click_tracking_only ? 1 : 0,
                    isChanged,
                    setChanged
                  );
                }}
              />
              <label className='label' htmlFor={'click_tracking_only'}>
                <span className='inner' />
                <span className='switch' />
              </label>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          className='partnerDetailstButtonWrapper noMarginPadding'
        >
          <CommonButtonComponent
            label={'Save'}
            appendWrapperClassName='btnPrimaryWrapper'
            appendButtonClassName={toggleClasses(
              isValid.name && isChanged.name,
              'btnPrimary hoverEffect',
              'btnPrimary'
            )}
            onClickCTA={() => {
              setSavePayload({
                ...newData,
                name: newData?.name.trim(),
                website: newData?.website.trim(),
                email: newData?.email.trim(),
                documentation_link: newData?.documentation_link.trim(),
                description: newData?.description.trim(),
              });
              setSaveMount(true);
              setIsUpdating(true);
              dispatch(setBlockingLoaderState({ active: true }));
            }}
            isDisabled={
              !Object.values(isChanged).includes(true) ||
              Object.values(isValid).includes(false) ||
              isUpdating
            }
            buttonUI='editCampaignSave'
          />
          <CommonButtonComponent
            label='Cancel'
            appendWrapperClassName='btnPrimaryWrapper'
            appendButtonClassName='btnSecondary'
            onClickCTA={() => navigate('/admin/partners')}
            buttonUI='editCampaignReset'
          />
        </Col>
      </Row>
    </>
  );

  return (
    <div className='adminWrapper'>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <Link to='/'>
            <div className='homeBlue'></div>
          </Link>
          <div className='breadCrumbBody'>
            /
            <Link to='/admin/partners'>
              <div className='active'>{'partners'}</div>
            </Link>
          </div>
          <div className='breadCrumbBody'>
            <span>/</span>
            {partnerName ?? defaultData?.name}
          </div>
        </div>
        <div className='partnerNameWrapper'>
          <div>
            <h1>{partnerName ?? defaultData?.name}</h1>
          </div>
        </div>
      </div>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div className='partnerDetailsWrapper'>
          <CommonHeadingComponent headingLabel='Partner Details' />
          <Container>{checkError()}</Container>
        </div>
      )}
    </div>
  );
};

export default PartnerDetails;

const DetailsField = ({
  label,
  value,
  data,
  setData,
  field,
  defaultData,
  isChanged,
  setChanged,
  uniqueIdentifier,
  mandatoryField = false,
  disable = false,
  placeholder = '',
  className = '',
}) => {
  return (
    <div className='detailsField'>
      <div className='fieldLabel'>
        <span>{label}</span>
        {mandatoryField ? <span className='mandatoryField'>*</span> : <></>}
      </div>
      <input
        id={uniqueIdentifier}
        name={uniqueIdentifier}
        value={value ?? ''}
        disabled={disable}
        onChange={(e) => {
          setData({ ...data, [field]: e.target.value });
          checkChange(
            field,
            defaultData,
            e.target.value,
            isChanged,
            setChanged
          );
        }}
        className={`fieldInput ${className}`}
        placeholder={placeholder}
      />
    </div>
  );
};

const checkChange = (field, defaultData, newData, isChanged, setChanged) => {
  if (defaultData[field] != newData) {
    setChanged({ ...isChanged, [field]: true });
  } else {
    setChanged({ ...isChanged, [field]: false });
  }
};
