import React from 'react';
import { LineChart, Line, XAxis, YAxis } from 'recharts';

const CommonTrendChartComponent = ({
  chartData,
  dotRadius = 0,
  strokeColor = '#646463',
  width = 100,
}) => {
  return (
    <div>
      <LineChart
        width={width}
        height={70}
        data={chartData}
        margin={{
          top: 12,
          right: 4,
          left: 4,
          bottom: 12,
        }}
      >
        <XAxis
          dataKey='date'
          axisLine={false}
          opacity='0'
          style={{ display: 'none' }}
          width={0}
        />
        <YAxis
          axisLine={false}
          opacity='0'
          style={{ display: 'none' }}
          width={0}
        />
        <Line
          type='linear'
          dataKey='impressions'
          stroke={strokeColor}
          strokeWidth={2}
          dot={{ fill: strokeColor, r: dotRadius }}
          activeDot={false}
          isAnimationActive={false}
        />
      </LineChart>
    </div>
  );
};

export default CommonTrendChartComponent;
