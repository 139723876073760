import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  CommonInput,
  SearchableDropdownComponent,
  CommonButtonComponent,
  CommonHeadingComponent,
} from '../../../../components';
import endpoints, { baseEndpoints } from '../../../../constants/endpoints';
import { useNavigate } from 'react-router-dom';
import '../../admin.css';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../services/http/axiosCalls';
import { commonNotification } from '../../../../utils/common-notifications';
import axios from 'axios';
import { isEmail } from 'react-multi-email'; // for checking if valid email
import { returnAcceptedFiles } from '@aws-amplify/ui';
import { isValidateEmail } from '../../../../utils/functions';

const AccountCreate = () => {
  const [accName, setAccName] = useState(''),
    [accEmail, setAccEmail] = useState(''),
    [accPartner, setAccPartner] = useState(''),
    [accDM, setAccDM] = useState(''),
    [partnerList, setPartnerList] = useState(null),
    [mountedPartner, setMountedPartner] = useState(false),
    [isValid, setIsValid] = useState({
      accName: true,
      accEmail: true
    }),
    [errorMsg, setErrorMsg] = useState({
      accName: '',
      accEmail: 'Please enter a valid E-mail'
    });

  const navigate = useNavigate();

  const saveAccount = async () => {
    try {
      if (!accName) {
        commonNotification({
          msg: 'Must Provide an Account Name',
          type: 'danger',
        });
        return;
      } else if (accEmail && !isEmail(accEmail)) {
        commonNotification({
          msg: 'Must Provide a Valid Email',
          type: 'danger',
        });
        return;
      }
      const result = await axiosPostRequest(
        baseEndpoints.admin + '/accounts/create',
        {
          name: accName,
          email: accEmail,
          partner: accPartner.user_id,
          directMail: accDM.id,
        }
      );
      if (result.status === 201) {
        commonNotification({
          msg: 'Account Has Been Successfully Created',
          type: 'success',
        });
        setTimeout(() => {
          navigate(`/admin/accounts`);
        }, 2000);
      }
    } catch (err) {
      if (err.response?.data?.error?.message) {
        commonNotification({
          msg: err.response.data.error.message,
          type: 'danger',
        });
      } else {
        commonNotification({
          msg: err.message ?? 'Something went wrong',
          type: 'danger',
        });
      }
    }
  };

  const getPartnerList = async (controller) => {
    try {
      const result = await axiosGetRequest(
        baseEndpoints.admin + '/accounts',
        controller.signal,
        {
          page_size: -1,
        }
      );

      if (result.status === 200) {
        setPartnerList(result.data.table_data);
        setMountedPartner(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        setPartnerList(null);
        setMountedPartner(true);
        commonNotification({ msg: 'Something Went Wrong', type: 'danger' });
      }
    }
  };

  const checkValid = (key, val) => {
    if (key === 'name') {
      if (val?.length > 100) {
        setIsValid({ ...isValid, accName: false });
        setErrorMsg({
          ...errorMsg,
          accName: 'Maximum allowed characters for Account Name is 100',
        });
      } else if (!val?.length) {
        setIsValid({ ...isValid, accName: false });
        setErrorMsg({ ...errorMsg, accName: 'Please enter an Account Name' });
      } else {
        setIsValid({ ...isValid, accName: true });
        setErrorMsg({ ...errorMsg, accName: '' });
      }
      return;
    }
    if (key === 'email') {
      if (!val.length) {
        setIsValid({ ...isValid, accEmail: true });
        return;
      }
      if (val?.length > 120) {
        setIsValid({ ...isValid, accEmail: false });
        setErrorMsg({
          ...errorMsg,
          accEmail: 'Maximum allowed characters for E-mail is 120',
        });
      } else if (val.length && val.length <= 120) {
        setIsValid({ ...isValid, accEmail: isValidateEmail(val) });
        setErrorMsg({ ...errorMsg, accEmail: 'Please enter a valid E-mail' });
      } else {
        setIsValid({ ...isValid, accEmail: true });
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (!partnerList && !mountedPartner) {
      getPartnerList(controller);
    }
    if (partnerList && partnerList[0]?.id != 0) {
      setPartnerList([
        { id: 0, organization: 'Not a Partner Account' },
        ...partnerList,
      ]);
    }

    return () => {
      controller.abort();
    };
  }, [partnerList]);

  return (
    <div className='accCreateFormOuterWrapper'>
    <CommonHeadingComponent headingLabel={'Create Account'}/>
      <Container className='accCreateFormWrapper'>
        <Row style={{width: '100%'}}>
          <Col lg={6} md={12} style={{padding: 0}} className='relativeField'>
            <CommonInput
              placeholder='Name'
              inputValue={accName}
              setInputValue={(val)=>{
                setAccName(val)
                if(val?.length>100){
                  setIsValid({...isValid, accName: false})
                  setErrorMsg({...errorMsg, accName: 'Maximum allowed characters for Account Name is 100'})
                } else {
                  setIsValid({...isValid, accName: true})
                }
              }}
              disabled={false}
              className={isValid.accName?'accountInput':'accountInput error'}
              label='Account Name '
              labelClass='requiredInput'
              isLoading={false}
              uniqueIdentifier={'Name'}
              onBlurHandle={(val)=>{
                checkValid('name',val.target.value)
              }}
            />
            {!isValid.accName && <span className='errorField'>{errorMsg.accName}</span>}
          </Col>
          <Col lg={6} md={12} style={{padding: 0}} className='relativeField'>
            <CommonInput
              placeholder='Email'
              inputValue={accEmail}
              setInputValue={(val)=>{
                setAccEmail(val)
                if(val?.length>120){
                  setIsValid({...isValid, accEmail: false})
                  setErrorMsg({...errorMsg, accEmail: 'Maximum allowed characters for E-mail is 120'})
                } else {
                  setIsValid({...isValid, accEmail: true})
                }
              }}
              disabled={false}
              className={isValid.accEmail?'accountInput':'accountInput error'}
              label='Account Email'
              isLoading={false}
              uniqueIdentifier={'Email'}
              onBlurHandle={(val)=>{
                checkValid('email',val.target.value)
              }}
            />
            {!isValid.accEmail && <span className='errorField'>{errorMsg.accEmail}</span>}

          </Col>
          <Col lg={6} md={12} style={{padding: 0}}>
            <SearchableDropdownComponent
              options={partnerList ?? []}
              label='organization'
              id='organization'
              dropdownId='partnerDD'
              dropdownLabel='Partner Account'
              placeholderLabel='Select'
              selectedValue={accPartner}
              handleChange={(val) => setAccPartner(val)}
              isLoading={false}
              isDisabled={false}
              uniqueIdentifier={'Partner'}
              ifIdVisableOnLabel={true}
            />
          </Col>
          <Col lg={6} md={12} style={{padding: 0}}>
            <SearchableDropdownComponent
              options={[
                { name: 'Not a Direct Mail Account', id: 0 },
                { name: 'Direct Mail Account', id: 1 },
              ]}
              label='name'
              id='name'
              dropdownId='categoryDD'
              dropdownLabel='Direct Mail Account'
              placeholderLabel='Select'
              selectedValue={accDM}
              handleChange={(val) => setAccDM(val)}
              isLoading={false}
              isDisabled={false}
              uniqueIdentifier={'DirectMail'}
            />
          </Col>
        </Row>
        <Row>
          <Col className='flex text-center' style={{padding: 0, margin: '40px 0 20px'}}>
            <CommonButtonComponent
              label='Save'
              isDisabled={Object.values(isValid).includes(false)||!accName}
              buttonUI={'Save'}
              onClickCTA={saveAccount}
              appendWrapperClassName='btnPrimaryWrapper'
              appendButtonClassName='btnPrimary'
            />
            <CommonButtonComponent
              label='Cancel'
              isDisabled={false}
              showLoader={false}
              buttonUI={'Cancel'}
              onClickCTA={() => navigate('/admin/accounts')}
              appendWrapperClassName='btnPrimaryWrapper'
              appendButtonClassName='btnSecondary'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccountCreate;


const checkValidEmail =(email)=>{
  if(email?.length>0&&email?.length<=50){
    return isValidateEmail(email)
  }else{
    return false
  }
}