import React, { useState, useEffect, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  CommonHeadingComponent,
  MultiselectDropdownComponent,
  CommonButtonComponent,
  ErrorComponent
} from '../../../../components'
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor'
import LoaderComponent from '../../../../components/loaderComponent/Loader.component'
import NoDataComponent from '../../../../components/commonTable/NoData.component'
import { toggleClasses, checkSelectAll, setPlaceHolderLabel } from '../../../../utils/functions'
import { baseEndpoints, messages } from '../../../../constants'
import { CommonPixelTable } from '../../../../components/commonPixelTable/commonPixelTable.component'
import { axiosPostRequest } from '../../../../services/http/axiosCalls'
import './PixelRaw.css'
import { set } from 'lodash'

/**
 * Component for showing property pixel raw data based on goals selected.
 * @param {string} propertyId Current property ID
 * @returns Render value for property pixel raw data
 */
const PixelRawData = ({ propertyId }) => {
  const goalsListRef = useRef(null)
  const [clickButton, setClickButton] = useState(false) // To handle button clicks and fixing multiple selection issue
  const [expandOrCollapse, setExpandOrCollapse] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [goalsListUrl, setGoalsListUrl] = useState('') // API URL for interceptor
  const [selectedGoals, setSelectedGoals] = useState([]) // User selected goals in multiple goal selection component
  const [pixelLoading, setPixelLoading] = useState(false)
  const [pixelError, setPixelError] = useState('')
  const [pixelData, setPixelData] = useState(null)
  const params = null
  // API Interceptor to get all goals for current property
  const { data: goalsData, isLoading: isGoalsLoading, isError: isGoalsError } = useGetApiInterceptor(isMounted, params, goalsListUrl) // States for Goal Data

  useEffect(() => {
    // Max page size given to get all available goals
    setGoalsListUrl(`${baseEndpoints.properties}/${propertyId}/goals?column=goal_name&order=asc`)
    setIsMounted(true)
  }, [])

  useEffect(() => {
    /**
     * Setting all goals list current ref whenver all goals change to populate data in
     * multiple selection component
     */
    if (goalsData?.table_data) setSelectedGoals(goalsData.table_data)
    goalsListRef.current = goalsData?.table_data ? [...goalsData.table_data] : null
  }, [goalsData])

  // To handle 'Get Reports' button clicks
  useEffect(() => {
    if (clickButton === true) {
      getPixelRawData()
      setClickButton(false)
    }
  }, [clickButton])
  // Method to get pixel raw data for property based on selected goal(s) using legacy AA API, retries recursively
  const getPixelRawData = async (recursed = false) => {
    try {
      setPixelLoading(true)
      const selectedGoalNames = selectedGoals.map(val => val.goal_name)
      const pixelRawData = await axiosPostRequest(`${baseEndpoints.pixels}/goals`, {
         property_id: propertyId,
         goals: selectedGoalNames,
         type: 'goals',
         all_selected: selectedGoalNames.length === goalsData?.table_data?.length
      })
      if (pixelRawData?.success) {
        setPixelData(pixelRawData.data?.slice(0, 100))
        setPixelError('');
        setPixelLoading(false);
      } else {
        setPixelData(null)
        if(pixelRawData?.error.includes("Query currently")){
          setTimeout(() => {
            setPixelError("This report is being created. Please wait. The report will load when the data is ready.")
            getPixelRawData();
          }, pixelError ? 5000 : 10000)
        } else {
          setPixelError('pixel_api_error')
          setPixelLoading(false);
        }
      }
    } catch (e) {
      console.error(e)
      setPixelError('pixel_api_error')
      setPixelLoading(false);
    }
  }

  const renderPixelItem = (pixel, i) => {
    const previewRows = [
      ['goal_name', 'Goal Name'],
      ['user_agent', 'User Agent'],
      ['raw_ip', 'IP Address'],
      ['idfa', 'IDFA/AAID'],
      ['ord', 'ORD'],
      ['query', 'Query'],
    ]
    const allRows = [
      ['goal_name', 'Goal Name'],
      ['user_agent', 'User Agent'],
      ['raw_ip', 'IP Address'],
      ['idfa', 'IDFA/AAID'],
      ['ord', 'ORD'],
      ['click_id', 'Click ID'],
      ['cookie', 'Cookie ID'],
      ['query', 'Query'],
      ['referer', 'Referrer']
    ]
    return <CommonPixelTable key={i} pixel={pixel} i={i} previewRows={previewRows} allRows={allRows} />
  }

  const renderPixelItemList = () => {
    return (
      <Container fluid>
        {pixelData.map((pixel, i) => renderPixelItem(pixel, i))}
      </Container>
    )
  }

  const renderPixelRawData = () => {
    return (
      <Row>
        <Col>
        <div className='pixelRawDataLabel'>
          {messages.pixelRawDataInfo}
        </div>
        <div className='pixelDataContainer' style={pixelData.length === 0 ? { padding: 0 } : {}}>
          {pixelData.length === 0 ? <div className='noPixelData'>No Data</div> : renderPixelItemList()}
        </div>
        </Col>
      </Row>
    )
  }

  const renderErrorComponent = () => {
    if(pixelError){
      if(pixelError.includes('api_error')){
        <ErrorComponent
              refreshAction={
                isGoalsError
                  ? () => { setPixelError(''); setGoalsListUrl(`${baseEndpoints.properties}/${propertyId}/goals?column=goal_name&order=asc`); }
                  : () => getPixelRawData()
              }
            />
      } else if(pixelError.includes('This report is')){
          return (<div className='pixelErrorMessageWrapper'>
          {pixelError}
        </div>);
      } else {
        return (
            <NoDataComponent
              type='table'
              width='180'
              height='180'
              messageTextFontSize={15}
              foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
              messageText='No Data Available'
            />)
      }
    }
  }
  const renderCollapsibleView = () => {
    return (
      <Container fluid>
        <Row className='pixelSelector'>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={8}
          >
            <MultiselectDropdownComponent
              dropdownId='goalsDB_PixelRawData'
              dropdownDataList={goalsData?.table_data ? goalsData.table_data : []}
              dataId='goal_id'
              dataName='goal_name'
              label='Goals'
              dropdownData={selectedGoals ?? []}
              setDropdownData={(data) => {
                setPixelError('')
                if (data) {
                  setSelectedGoals(data)
                } else {
                  setSelectedGoals(
                    goalsListRef.current
                      ? [...goalsListRef.current]
                      : null
                  )
                }
              }}
              ifIdSearchAvailable={true}
              placeholder={setPlaceHolderLabel(
                !(goalsData?.table_data && goalsData.table_data.length > 0),
                'Goals'
              )}
              txtSearch='Search by Goal'
              isAllCheckedInitially={checkSelectAll(
                goalsData?.table_data ? goalsData.table_data : [],
                selectedGoals,
                'goal_id'
              )}
              labelWidth={{ width: '86px' }}
              dropdownWidth={{
                width: 'calc(100% - 86px)'
              }}
              isDisabled={!(goalsData?.table_data && goalsData.table_data.length > 0)}
              uniqueIdentifier='propertyDetailsPixelRawDataGoalName'
              ifIdVisibleOnLabel={true}
            />
          </Col>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={4}
          >
            <div className='pixelRawDatabuttons'>
            <CommonButtonComponent
              label='Generate Report'
              isDisabled={!(goalsData?.table_data && goalsData.table_data.length > 0) || pixelLoading}
              appendButtonClassName='generateReportButtonBody'
              appendWrapperStyle={{ padding: '0px' }}
              onClickCTA={() => {
                setTimeout(() => {
                  setClickButton(true)
                }, 50)
              }}
              showLoader={false}
              buttonUI='propertyDetailsPixelRawDataGenerate'
            />
            </div>
          </Col>
        </Row>
        {/* Handle No data, Error and Loading scnerios for goals and pixel data */}
        {(pixelLoading || isGoalsLoading) ? <LoaderComponent headerClass='pixelLoadedWrapper' /> : pixelData ? renderPixelRawData() : <div />}
        {renderErrorComponent()}
      </Container>
    )
  }

  return (
    <div
      className={toggleClasses(
        expandOrCollapse,
        'pixelRawGridWrapper',
        'pixelRawGridWrapper expand'
      )}
    >
      <CommonHeadingComponent
        headingLabel="Pixel Raw Data"
        headingCTALabel={!expandOrCollapse ? 'COLLAPSE' : 'EXPAND'}
        isDisabled={false}
        onClickCTA={() => setExpandOrCollapse(!expandOrCollapse)}
        uniqueIdentifier='propertyDetailsPixelRawDataCollapsable'
      />
      {!expandOrCollapse && renderCollapsibleView()}
    </div>
  )
}

PixelRawData.propTypes = {
  propertyId: PropTypes.string
}

export default PixelRawData
