import React, { useEffect } from 'react';
import { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import './tooltipStyle.css';

const CommonRatioChartComponent = ({ chartData, baseColors }) => {

  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipContent, setTooltipContent] = useState('');

  const handleMouseEnter = (e) =>{
    setTooltipData(chartData);
  }

  useEffect(() => {
    if(tooltipData)
      {
        const content = setCustomTooltip();
        setTooltipContent(content);
      }
  }, [tooltipData]);

  const setCustomTooltip = () => {
    
    return (
        <div>
          {tooltipData.length > 0 && (
            <>
              <div style={{color: baseColors[0]}} id="leftDiv">Days Left: {tooltipData[0].value}</div>
              <div style={{color: baseColors[1]}} id="rightDiv">Days Completed: {tooltipData[1].value}</div>
            </>
          )}
        </div>
    )
  };

  return (
     <div className='pie-chart-wrapper'>
        <div className='item'>
          <div className='popup'></div>
        </div>
        <PieChart width={26} height={26} onMouseEnter={handleMouseEnter}>
          <Pie
            data={chartData}
            outerRadius={11}
            startAngle={90}
            endAngle={450}
            labelLine={false}
            dataKey='value'
            isAnimationActive={false}
            strokeWidth={0}
            margin={{
              top: 2,
              right: 2,
              left: 2,
              bottom: 2,
            }}
          >
            {chartData?.map((entry, index) => (
              <Cell
                key={`cell-${entry}`}
                fill={baseColors[index % baseColors.length]}
              />
            ))}
          </Pie>
        </PieChart>
        <div className='popup-content'>{tooltipContent}</div>
    </div>
  );
};

export default CommonRatioChartComponent;
