import React, { useEffect, useState } from 'react';
import { isValidateEmail } from '../../../../utils/functions';
import './styles.css';

/**
 * Render input field for enter email address
 * Add multiple email address
 * Display added email list in below email field
 */
const MultiRecipients = ({
  emails = [],
  setEmails,
}) => {
  const [items, setItems] = useState([]),
    [value, setValue] = useState(''),
    [error, setError] = useState(null);

    useEffect(() => {
      if (emails?.length && JSON.stringify(emails) !== JSON.stringify(items)) {
        setItems(emails);
      }
    }, [emails]);
  

  // Check entered email address is valid or not one enter key press
  const handleKeyDown = (event) => {
    if (['Enter', 'Tab', ','].includes(event.key) || event.type === 'blur') {
      event.preventDefault();

      const newValue = value?.trim().toLowerCase();

      if (newValue && isValid(newValue)) {
        setEmails([...items, newValue]);

        setItems([...items, newValue]);
        setValue('');
      }
    }
  };


  // Updating state variable on input value change
  const handleChange = (evt) => {
    setValue(evt.target.value);
    setError(null);
  };

  // Removing email item from the list on click of close icon
  const handleDelete = (item) => {
    const newItemList = items.filter((i) => i !== item);
    setItems(newItemList);
    setEmails(newItemList);
  };

  // Checking email validation and is already added in list or not
  const isValid = (email) => {
    if (isInList(email)) {
      setError(`${email} has already been added.`);
      return false;
    }

    if (!isValidateEmail(email)) {
      setError(`${email} is not a valid email address.`);
      return false;
    }

    return true;
  };

  //Checking list  for already contains or not
  const isInList = (email) => {
    return items.includes(email);
  };

  return (
    <div className='add-multi-email'>
      <input
        className={
          'add-multi-email-input form-control input' + (error && ' has-error')
        }
        value={value}
        placeholder='Type email and press `Enter`'
        onKeyDown={handleKeyDown}
        onBlur={handleKeyDown}
        onChange={handleChange}
      />
      {items.length > 0 && (
        <div className='email-items'>
          {items.map((item) => (
            <div className='tag-item' key={item}>
              {item}
              <button
                type='button'
                className='button'
                onClick={() => handleDelete(item)}
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      )}
      {error && <p className='error'>{error}</p>}
    </div>
  );
};

export default MultiRecipients;
