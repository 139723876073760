import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import {
  resetClientMode,
  updateClientMode,
  updateShowUtilityBar,
} from '../../../../redux/utilitybarSlice';
import { setBlockingLoaderState } from '../../../../redux/blockingLoader';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import { usePatchAPIInterceptor } from '../../../../hooks/patchAPIInterceptor';
import { baseEndpoints, statusOptionList } from '../../../../constants';
import CommonAccountBreadCrum from './CommonAccountBreadCrum';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  CommonInput,
  ErrorComponent,
  LoaderComponent,
  SearchableDropdownComponent,
} from '../../../../components';
import {
  FormatLabel,
  getNotification,
  isValidateEmail,
  isValidateURL,
  removeNotification,
} from '../../../../utils/functions';

import './accountDetails.css';

const statusOptions = [...statusOptionList],
  directMailAccountOptions = [
    { id: 0, name: 'Not a Direct Mail Account' },
    { id: 1, name: 'Direct Mail Account' },
  ],
  accountMessages = {
    partnerAccount:
      'This is only needed for when we are going a Partners UI login to a partner. So they can see their own reporting and macro setup.',
    directMailAccount:
      'Direct Mail accounts will be limited to direct mail-related items in the UI.',
  },
  errorMessages = {
    noOrganization: 'Must enter the name of the Organization',
    exceedsOrgNameCharacters:
      'Maximum characters allowed for organization name is 100',
    noEmail: 'Must enter an email id (valid) for Organization Email Address',
    notValidEmail: 'Organization Email Address is not valid',
    notValidProductDomain: 'Product domain is not valid',
    notValidUATDomain: 'UAT domain is not valid',
  };

const AccountDetailsComponent = () => {
  document.title = 'Admin | Account Details';

  const { accountId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [paramsForUpdateAccount, setParamsForUpdateAccount] = useState(null),
    [isMountedForGetDetails, setIsMountedForGetDetails] = useState(false),
    [isMountedForUpdateAccount, setIsMountedForUpdateAccount] = useState(false);

  const [partnerAccountOptions, setPartnerAccountOptions] = useState([]),
    [selectedAccountInfo, setSelectedAccountInfo] = useState(null),
    [selectedPrevAccountInfo, setSelectedPrevAccountInfo] = useState(null),
    [isSaveDisabled, setIsSaveDisabled] = useState(true),
    [pageHeading, setPageHeading] = useState(null),
    [isErrorFields, setIsErrorFields] = useState({
      organization: { isError: false, message: '' },
      organizationEmail: { isError: false, message: '' },
      productDomain: { isError: false, message: '' },
      uatDomain: { isError: false, message: '' },
    });

  // GET API to fetch Account details
  const { data, isLoading, isError } = useGetApiInterceptor(
    isMountedForGetDetails,
    null,
    baseEndpoints.admin + '/accounts/' + accountId
  );

  useEffect(() => {
    setIsMountedForGetDetails(true);
  }, []);

  useEffect(() => {
    dispatch(updateShowUtilityBar(false));
    dispatch(updateClientMode(true));

    return () => {
      dispatch(updateShowUtilityBar(true));
      dispatch(resetClientMode());
    };
  }, []);

  useEffect(() => {
    if (data.length) {
      const usableClientList = [
        {
          id: 0,
          name: 'Not a Partner Account',
          is_disabled: 0,
          no_id: true,
        },
        ...data[1],
      ];
      setPartnerAccountOptions([...usableClientList]);

      setPageHeading(data[0][0].organization);
      setSelectedAccountInfo({
        organization: data[0][0].organization,
        organizationEmail: data[0][0].email,
        status: statusOptions.find((item) => item.id === data[0][0].enabled),
        productDomain: data[0][0].domain_prod,
        uatDomain: data[0][0].domain_uat,
        partnerAccount: usableClientList.find(
          (item) => item.id === data[0][0].partner_account
        ),
        directMailAccount: directMailAccountOptions.find(
          (item) => item.id === data[0][0].dm_account
        ),
      });
    }
  }, [data]);

  useEffect(() => {
    if (selectedAccountInfo) {
      const newIsErrorObj = updateIsErrorFields(
        selectedAccountInfo,
        selectedPrevAccountInfo,
        isErrorFields
      );
      setIsErrorFields({ ...newIsErrorObj });
      setIsSaveDisabled(
        checkForDisabledSave(data[0], selectedAccountInfo, newIsErrorObj)
      );
      setSelectedPrevAccountInfo({ ...selectedAccountInfo });
    }
  }, [selectedAccountInfo]);

  useEffect(() => {
    if (data?.length) {
      setIsSaveDisabled(
        checkForDisabledSave(data[0], selectedAccountInfo, isErrorFields)
      );
    }
  }, [isErrorFields]);

  useEffect(() => {
    if (isError) {
      setIsMountedForGetDetails(false);
    }
  }, [isError]);

  const handleSave = () => {
    const validationOfRecords = validateRecordAndGetErrorMessage(
      selectedAccountInfo,
      isErrorFields,
      setIsErrorFields
    );

    if (validationOfRecords.isError) {
      setIsSaveDisabled(true);
      return getNotification('danger', validationOfRecords.errorMessage);
    }

    initiateSave();
  };

  const initiateSave = () => {
    dispatch(setBlockingLoaderState({ active: true }));

    const requestParams = {
      organization: selectedAccountInfo.organization?.trim(),
      email: selectedAccountInfo.organizationEmail?.trim(),
      enabled: selectedAccountInfo.status.id,
      domain_prod: selectedAccountInfo.productDomain?.trim(),
      domain_uat: selectedAccountInfo.uatDomain?.trim(),
      partner_account: selectedAccountInfo.partnerAccount.id,
      dm_account: selectedAccountInfo.directMailAccount.id,
    };

    setParamsForUpdateAccount(requestParams);
    setIsMountedForUpdateAccount(true);
  };

  const updateAccountCallback = (status, data, error) => {
    if (status !== 200) {
      if (status === 422) {
        setIsErrorFields({
          ...isErrorFields,
          organization: {
            isError: true,
            message: data?.message,
          },
        });
        setIsSaveDisabled(true);
      }
      dispatch(setBlockingLoaderState({ active: false }));
      return getNotification('danger', data?.error ? data?.message : error);
    }

    setPageHeading(selectedAccountInfo.organization?.trim());
    dispatch(setBlockingLoaderState({ active: false }));
    getNotification(
      'success',
      data?.message || `Account #${accountId} has been updated successfully.`
    );
    navigate('/admin/accounts?param=1');
  };

  // Post API Interceptor to save updated/modified Account
  usePatchAPIInterceptor(
    isMountedForUpdateAccount,
    paramsForUpdateAccount,
    baseEndpoints.admin + '/accounts/' + accountId,
    updateAccountCallback
  );

  const handleCancel = () => {
    removeNotification();
    navigate('/admin/accounts');
  };

  const handleRefresh = () => {
    setIsMountedForGetDetails(true);
  };

  return (
    <div className='accountDetailsWrapper'>
      <CommonAccountBreadCrum isLoading={isLoading} name={pageHeading} />

      <RenderHTMLCompenent
        isLoading={isLoading}
        isError={isError}
        pageHeading={pageHeading}
        partnerAccountOptions={partnerAccountOptions}
        selectedAccountInfo={selectedAccountInfo}
        setSelectedAccountInfo={setSelectedAccountInfo}
        handleRefresh={handleRefresh}
        isSaveDisabled={isSaveDisabled}
        handleSave={handleSave}
        handleCancel={handleCancel}
        isErrorFields={isErrorFields}
        setIsErrorFields={setIsErrorFields}
      />
    </div>
  );
};

export default AccountDetailsComponent;

// Logic for enabling or disabling the save button
const checkForDisabledSave = (data, updatedData, isErrorFields) => {
  return (
    (updatedData?.organization?.trim() === data[0]?.organization &&
      updatedData?.organizationEmail?.trim() === data[0]?.email &&
      updatedData?.status?.id === data[0]?.enabled &&
      updatedData?.productDomain?.trim() === data[0]?.domain_prod &&
      updatedData?.uatDomain?.trim() === data[0]?.domain_uat &&
      updatedData?.partnerAccount?.id === data[0]?.partner_account &&
      updatedData?.directMailAccount?.id === data[0]?.dm_account) ||
    isErrorFields?.organization?.isError ||
    isErrorFields?.organizationEmail?.isError ||
    isErrorFields?.productDomain?.isError ||
    isErrorFields?.uatDomain?.isError
  );
};

// Validation with notification
const validateRecordAndGetErrorMessage = (
  record,
  isErrorFields,
  setIsErrorFields
) => {
  const { organization, organizationEmail, productDomain, uatDomain } = record;
  let errorMessage = '',
    newErrorFields = { ...isErrorFields };

  const usableOrganization = organization.trim();
  if (!usableOrganization) {
    newErrorFields = {
      ...newErrorFields,
      organization: { isError: true, message: errorMessages.noOrganization },
    };
    errorMessage = <div>{errorMessages.noOrganization}</div>;
  } else if (usableOrganization.length > 100) {
    newErrorFields = {
      ...newErrorFields,
      organization: {
        isError: true,
        message: errorMessages.exceedsOrgNameCharacters,
      },
    };
    errorMessage = <div>{errorMessages.exceedsOrgNameCharacters}</div>;
  }

  const usableOrgEmail = organizationEmail.trim();
  /**if (!organizationEmail.trim()) {
    newErrorFields = {
      ...newErrorFields,
      organizationEmail: { isError: true, message: errorMessages.noEmail },
    };
    errorMessage = constructErrorMessage(errorMessage, 'noEmail');
  } else*/
  if (usableOrgEmail && !isValidateEmail(usableOrgEmail)) {
    newErrorFields = {
      ...newErrorFields,
      organizationEmail: {
        isError: true,
        message: errorMessages.notValidEmail,
      },
    };
    errorMessage = constructErrorMessage(errorMessage, 'notValidEmail');
  }

  if (productDomain) {
    const usableProductDomain = productDomain.trim();
    if (usableProductDomain && !isValidateURL(usableProductDomain)) {
      newErrorFields = {
        ...newErrorFields,
        productDomain: {
          isError: true,
          message: errorMessages.notValidProductDomain,
        },
      };
      errorMessage = constructErrorMessage(
        errorMessage,
        'notValidProductDomain'
      );
    }
  }

  if (uatDomain) {
    const usableUATDomain = uatDomain.trim();
    if (usableUATDomain && !isValidateURL(usableUATDomain)) {
      newErrorFields = {
        ...newErrorFields,
        uatDomain: { isError: true, message: errorMessages.notValidUATDomain },
      };
      errorMessage = constructErrorMessage(errorMessage, 'notValidUATDomain');
    }
  }

  setIsErrorFields({ ...newErrorFields });

  return {
    isError: Boolean(errorMessage),
    errorMessage,
  };
};

const constructErrorMessage = (errorMessage, type) => {
  return errorMessage ? (
    <div>
      {errorMessage} <div>{errorMessages[type]}</div>
    </div>
  ) : (
    <div>{errorMessages[type]}</div>
  );
};

const updateIsErrorFields = (
  selectedAccountInfo,
  selectedPrevAccountInfo,
  isErrorFields
) => {
  if (isErrorFields.organization.isError) {
    if (
      selectedAccountInfo.organization !== selectedPrevAccountInfo.organization
    ) {
      isErrorFields.organization = {
        isError: false,
        message: '',
      };
    }
  }

  if (isErrorFields.organizationEmail.isError) {
    if (
      selectedAccountInfo.organizationEmail !==
      selectedPrevAccountInfo.organizationEmail
    ) {
      isErrorFields.organizationEmail = {
        isError: false,
        message: '',
      };
    }
  }

  if (isErrorFields.productDomain.isError) {
    if (
      selectedAccountInfo.productDomain !==
      selectedPrevAccountInfo.productDomain
    ) {
      isErrorFields.productDomain = {
        isError: false,
        message: '',
      };
    }
  }

  if (isErrorFields.uatDomain.isError) {
    if (selectedAccountInfo.uatDomain !== selectedPrevAccountInfo.uatDomain) {
      isErrorFields.uatDomain = {
        isError: false,
        message: '',
      };
    }
  }

  return isErrorFields;
};

// Validation on blur
const onBlurHandle = (element, isErrorFields, setIsErrorFields, type) => {
  const usableElement = element.trim();

  setTimeout(() => {
    switch (type) {
      case 'organization':
        if (!usableElement) {
          return setIsErrorFields({
            ...isErrorFields,
            organization: {
              isError: true,
              message: errorMessages.noOrganization,
            },
          });
        }

        if (usableElement.length > 100) {
          return setIsErrorFields({
            ...isErrorFields,
            organization: {
              isError: true,
              message: errorMessages.exceedsOrgNameCharacters,
            },
          });
        }
        break;
      case 'organizationEmail':
        /**if (!usableElement) {
          return setIsErrorFields({
            ...isErrorFields,
            organizationEmail: {
              isError: true,
              message: errorMessages.noEmail,
            },
          });
        }*/

        if (usableElement && !isValidateEmail(usableElement)) {
          return setIsErrorFields({
            ...isErrorFields,
            organizationEmail: {
              isError: true,
              message: errorMessages.notValidEmail,
            },
          });
        }
        break;
      case 'productDomain':
        if (usableElement && !isValidateURL(usableElement)) {
          return setIsErrorFields({
            ...isErrorFields,
            productDomain: {
              isError: true,
              message: errorMessages.notValidProductDomain,
            },
          });
        }
        break;
      case 'uatDomain':
        if (usableElement && !isValidateURL(usableElement)) {
          return setIsErrorFields({
            ...isErrorFields,
            uatDomain: {
              isError: true,
              message: errorMessages.notValidUATDomain,
            },
          });
        }
        break;
      default:
      // No default code
    }
  }, 100);
};

const RenderHTMLCompenent = ({
  isLoading,
  isError,
  pageHeading,
  partnerAccountOptions,
  selectedAccountInfo,
  setSelectedAccountInfo,
  handleRefresh,
  isSaveDisabled,
  handleSave,
  handleCancel,
  isErrorFields,
  setIsErrorFields,
}) => {
  if (isLoading) {
    return <LoaderComponent headerClass='accountDetailsLoaderWrapper' />;
  }

  if (isError) {
    return <ErrorComponent refreshAction={() => handleRefresh()} />;
  }

  return (
    <div className='accountDetailsInnerWrapper'>
      <div className='accountDetailsHeader'>
        <h1>{pageHeading}</h1>
      </div>
      <div className='accountDetailsHeadingTabWrapper'>
        <CommonHeadingComponent headingLabel='Account Details' />
      </div>
      <Container>
        <Row className='noMarginPadding elementAlignLeft '>
          <Col md={12} className='accountDetailsElementWrapper'>
            <FormatLabel
              label='Organization Name'
              isMandatory
              customClass='field-name'
            />
            <CommonInput
              label=''
              placeholder='Enter the name of the Organization'
              inputValue={selectedAccountInfo?.organization ?? ''}
              setInputValue={(organization) =>
                setSelectedAccountInfo({ ...selectedAccountInfo, organization })
              }
              onBlurHandle={(e) =>
                onBlurHandle(
                  e.target.value,
                  isErrorFields,
                  setIsErrorFields,
                  'organization'
                )
              }
              className={`accountDetailsField${
                isErrorFields.organization.isError ? ' accountDetailsError' : ''
              }`}
            />
            {isErrorFields.organization.isError && (
              <div className='accountDetailsErrorMessage'>
                {isErrorFields.organization.message}
              </div>
            )}
          </Col>
          <Col sm={12} md={6} className='accountDetailsElementWrapper'>
            <FormatLabel
              label='Organization Email Address'
              customClass='field-name'
            />
            <CommonInput
              label=''
              placeholder='Enter the email of The Organization'
              inputValue={selectedAccountInfo?.organizationEmail ?? ''}
              setInputValue={(organizationEmail) =>
                setSelectedAccountInfo({
                  ...selectedAccountInfo,
                  organizationEmail,
                })
              }
              onBlurHandle={(e) =>
                onBlurHandle(
                  e.target.value,
                  isErrorFields,
                  setIsErrorFields,
                  'organizationEmail'
                )
              }
              className={`accountDetailsField${
                isErrorFields.organizationEmail.isError
                  ? ' accountDetailsError'
                  : ''
              }`}
            />
            {isErrorFields.organizationEmail.isError && (
              <div className='accountDetailsErrorMessage'>
                {isErrorFields.organizationEmail.message}
              </div>
            )}
          </Col>
          <Col sm={12} md={6} className='accountDetailsElementWrapper'>
            <FormatLabel label='Status' isMandatory customClass='field-name' />
            <SearchableDropdownComponent
              options={statusOptions}
              label='name'
              id='id'
              dropdownId='accountDetailsStatus'
              dropdownLabel=''
              labelWidth={{ margin: 0 }}
              dropdownWidth={{
                width: '100%',
              }}
              placeholderLabel='Select Account Status'
              selectedValue={selectedAccountInfo?.status}
              handleChange={(status) =>
                setSelectedAccountInfo({ ...selectedAccountInfo, status })
              }
              isSearchable={false}
              uniqueIdentifier='accountDetailsStatuses'
            />
          </Col>
          <Col sm={12} md={6} className='accountDetailsElementWrapper'>
            <FormatLabel label='Product Domain' customClass='field-name' />
            <CommonInput
              label=''
              placeholder='Enter Product Domain'
              inputValue={selectedAccountInfo?.productDomain ?? ''}
              setInputValue={(productDomain) =>
                setSelectedAccountInfo({
                  ...selectedAccountInfo,
                  productDomain,
                })
              }
              onBlurHandle={(e) =>
                onBlurHandle(
                  e.target.value,
                  isErrorFields,
                  setIsErrorFields,
                  'productDomain'
                )
              }
              className={`accountDetailsField${
                isErrorFields.productDomain.isError
                  ? ' accountDetailsError'
                  : ''
              }`}
            />
            {isErrorFields.productDomain.isError && (
              <div className='accountDetailsErrorMessage'>
                {isErrorFields.productDomain.message}
              </div>
            )}
          </Col>
          <Col sm={12} md={6} className='accountDetailsElementWrapper'>
            <FormatLabel label='UAT Domain' customClass='field-name' />
            <CommonInput
              label=''
              placeholder='Enter UAT Domain'
              inputValue={selectedAccountInfo?.uatDomain ?? ''}
              setInputValue={(uatDomain) =>
                setSelectedAccountInfo({ ...selectedAccountInfo, uatDomain })
              }
              onBlurHandle={(e) =>
                onBlurHandle(
                  e.target.value,
                  isErrorFields,
                  setIsErrorFields,
                  'uatDomain'
                )
              }
              className={`accountDetailsField${
                isErrorFields.uatDomain.isError ? ' accountDetailsError' : ''
              }`}
            />
            {isErrorFields.uatDomain.isError && (
              <div className='accountDetailsErrorMessage'>
                {isErrorFields.uatDomain.message}
              </div>
            )}
          </Col>
          <Col sm={12} md={6} className='accountDetailsElementWrapper'>
            <FormatLabel label='Partner Account' customClass='field-name' />
            <SearchableDropdownComponent
              options={partnerAccountOptions}
              label='name'
              id='id'
              dropdownId='accountDetailsPartnerAccount'
              dropdownLabel=''
              labelWidth={{ margin: 0 }}
              dropdownWidth={{
                width: '100%',
              }}
              placeholderLabel='Select Partner Account'
              selectedValue={selectedAccountInfo?.partnerAccount}
              handleChange={(partnerAccount) =>
                setSelectedAccountInfo({
                  ...selectedAccountInfo,
                  partnerAccount,
                })
              }
              ifShowDisabledOptions={true}
              disabledOptionId='is_disabled'
              ifIdVisableOnLabel={true}
              excludeIdVisableOnLabel='no_id'
              uniqueIdentifier='accountDetailsPartnerAccounts'
            />
            <div className='accountDetailsAccountMessage'>
              {accountMessages.partnerAccount}
            </div>
          </Col>
          <Col sm={12} md={6} className='accountDetailsElementWrapper'>
            <FormatLabel label='Direct Mail Account' customClass='field-name' />
            <SearchableDropdownComponent
              options={directMailAccountOptions}
              label='name'
              id='id'
              dropdownId='directMailAccount'
              dropdownLabel=''
              labelWidth={{ margin: 0 }}
              dropdownWidth={{
                width: '100%',
              }}
              placeholderLabel='Select Direct Mail Account'
              selectedValue={selectedAccountInfo?.directMailAccount}
              handleChange={(directMailAccount) =>
                setSelectedAccountInfo({
                  ...selectedAccountInfo,
                  directMailAccount,
                })
              }
              isSearchable={false}
              uniqueIdentifier='directMailAccounts'
            />
            <div className='accountDetailsAccountMessage'>
              {accountMessages.directMailAccount}
            </div>
          </Col>
          <Col sm={12} className='accountDetailsCTAs'>
            <CommonButtonComponent
              label='Save'
              appendWrapperClassName='btnADCPrimaryWrapper'
              appendButtonClassName='btnADCPrimary'
              isDisabled={isSaveDisabled}
              onClickCTA={handleSave}
              buttonUI='accountDetailsSave'
            />
            <CommonButtonComponent
              label='Cancel'
              appendWrapperClassName='btnADCSecondaryWrapper'
              appendButtonClassName='btnADCSecondary'
              onClickCTA={handleCancel}
              buttonUI='accountDetailsCancel'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
