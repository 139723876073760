import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  DatePickerComponent,
  ErrorComponent,
  LoaderComponent,
  MultiselectDropdownComponent,
  SearchableDropdownComponent,
} from '../../../../components';
import {
  formatNumberInThousands,
  daysToFormatWRTDates,
  setPlaceHolderLabel,
  getArrayOfData,
  checkCondition,
  getCurrentESTDate,
  getAllSelectedOrNot,
} from '../../../../utils/functions';
import { axiosSendRequest } from '../../../../services/http/axiosCalls';
import { Col, Container, Row } from 'react-bootstrap';
import './summaryHeader.css';

const nullImpFreqWarn =
  'There are 0 impressions during this time for this Campaign.';

function SummaryHeader({
  campaign,
  setCampaign,
  properStartDateString,
  properLookbackDateString,
  setProperLookbackDateString,
  setProperStartDateString,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setHaveSetDates,
  placement,
  setPlacement,
  defaultPlacement,
  setDefaultPlacement,
  mediaPartner,
  setMediaPartner,
  defaultMediaPartner,
  setDefaultMediaPartner,
  creativeType,
  setCreativeType,
  defaultCreativeType,
  setDefaultCreativeType,
  isDisabled,
  setIsDisabled,
  getReportsDetails,
  isErrorReportDetails,
  isErrorCampaignList,
  isErrorPlacementList,
  getCampaignList,
  getPlacementList,
  campaignList,
  placementList,
  setPlacementList,
  mediaPartnerList,
  setMediaPartnerList,
  creativeTypeList,
  setCreativeTypeList,
  isLoading,
  labels = ['label_1', 'label_2', 'label_3'],
  endpoint,
  dateRange,
  setDateRange,
  summaryData,
  setSummaryData,
  ifSelectedAll,
  updateIsLoading,
  getPartnerList,
  getCreativeTypeList,
  isErrorPartnerList,
  isErrorCreativeTypeList,
  presentTimeFrameOptions,
  timeFrame,
  setTimeFrame,
  isFromMediaDelivery = false,
  isFromLiftTab = false,
  setNoData=()=>{}
}) {
  const currentDate = getCurrentESTDate('YYYY-MM-DD');

  const [cancelController, setCancelController] = useState(null),
    [isErrorMediaDelivery, setIsErrorMediaDelivery] = useState(false),
    [dataLoading, setDataLoading] = useState(true),
    [isDateEnabled, setIsDateEnabled] = useState(false);

  const placementListRef = useRef(null),
    placementRef = useRef(null),
    mediaPartnerListRef = useRef(null),
    mediaPartnerRef = useRef(null),
    creativeTypeListRef = useRef(null),
    creativeTypeRef = useRef(null);

  const [showPartnerDropdown, setShowPartnerDropdown] = useState(false),
    [showCreativeTypeDropdown, setShowCreativeTypeDropdown] = useState(false),
    [showPlacementDropdown, setShowPlacementDropdown] = useState(false);

  const [formattedDateRange, setFormattedDateRange] = useState([]);
  const getMediaDelivery = async (controller) => {
    if (isFromLiftTab) return;
    setDataLoading(true);
    setIsErrorMediaDelivery(false);

    try {
      const response = await axiosSendRequest(endpoint, controller, {
        campaign_id: campaign?.campaign_id,
        partner_id: getArrayOfData(mediaPartner, 'partner_id'),
        placement_id: getArrayOfData(placement, 'placement_id'),
        if_all_partners: getAllSelectedOrNot(mediaPartnerList, mediaPartner, 'partner_id'),
        if_all_placements: getAllSelectedOrNot(placementList, placement, 'placement_id'),
        if_all_creative_types: getAllSelectedOrNot(creativeTypeList, creativeType, 'value'),
        start_date: dateRange[0],
        end_date: dateRange[1],
        new_attribution_projection_enabled:
          campaign?.new_attribution_projection_enabled,
      });

      if (response.status === 200) {
        const data = response.data;
        checkNoData(isFromMediaDelivery, setNoData, data)
        setSummaryData({
          dataOne: data.values[data.keys[0]],
          dataTwo: data.values[data.keys[1]],
          dataThree: isFromMediaDelivery ? data.values[data.keys[3]] : data.values[data.keys[2]],
          dataFour: data.values[data.keys[2]]
        });
        setDataLoading(false);
        return;
      }
      setIsErrorMediaDelivery(true);
      setDataLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsErrorMediaDelivery(true);
        setDataLoading(false);
      }
    }
  };

  useEffect(() => {
    placementRef.current = null;
  }, [campaign]);

  useEffect(() => {
    if (
      validateDataAvailability() &&
      checkForSamePlacements(
        placement,
        placementRef.current ?? [],
        'placement_id'
      )
    ) {
      const controller = new AbortController();
      setCancelController(controller);

      placementRef.current = [...placement];
      mediaPartnerRef.current = [...mediaPartner];
      creativeTypeRef.current = [...creativeType];
      getMediaDelivery(controller);
      return () => {
        controller.abort();
      };
    }

    if ((!placement || placement?.length == 0) && !isLoading.placement) {
      setSummaryData({
        dataOne: 0,
        dataTwo: 0,
        dataThree: 0,
      });
      setNoData(true)
      setDataLoading(false);
    }
  }, [placement, isLoading]);

  useEffect(() => {
    if ((!campaign || dateRange[0]) && !isLoading.campaign) {
      setHaveSetDates(true);
    }

    if(dateRange[0]?.length > 10 && dateRange[1]?.length > 10){
      setStartDate(dateRange[0] ? dateRange[0].slice(0, dateRange[0].length - 1) : null);
      setEndDate(dateRange[1] ? dateRange[1].slice(0, dateRange[1].length - 1) : null);
    } else {
      setStartDate(dateRange[0])
      setEndDate(dateRange[1])
    }
    
    setIsDateEnabled(true);

    if (validateDataAvailability() && isDateEnabled) {
      const controller = new AbortController();
      setCancelController(controller);

      placementRef.current = [...placement];
      mediaPartnerRef.current = [...mediaPartner];
      creativeTypeRef.current = [...creativeType];
      getMediaDelivery(controller);
      return () => {
        controller.abort();
      };
    }
  }, [dateRange, isLoading.campaign]);

  const validateDataAvailability = () => {
    return (
      campaign &&
      mediaPartner &&
      creativeType &&
      placement?.length > 0 &&
      dateRange?.length === 2 &&
      dateRange[0] !== null &&
      dateRange[1] !== null
    );
  };

  useEffect(() => {
    placementListRef.current = placementList ? [...placementList] : null;
  }, [placementList]);

  useEffect(() => {
    mediaPartnerListRef.current = mediaPartnerList
      ? [...mediaPartnerList]
      : null;
  }, [mediaPartnerList]);

  useEffect(() => {
    creativeTypeListRef.current = creativeTypeList
      ? [...creativeTypeList]
      : null;
  }, [creativeTypeList]);

  useEffect(() => {
    const newDateRange = dateRange?.map(date => date ? date.split('T')[0] : null);
    setFormattedDateRange(newDateRange);
  }, [dateRange]);

    const renderSummaryComponent = () => {
    return (
      <>
        <div className='mediaDeliveryWrapper sticky'>
          <Container
            fluid
            className='noMarginPadding mediaDeliverySubheaderWrapper'
          >
            <Row className='noMarginPadding'>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='noMarginPadding mediaDeliveryFilterWrapper'
              >
                <SearchableDropdownComponent
                  options={campaignList ?? []}
                  label='campaign_name'
                  id='campaign_id'
                  dropdownId='campaignNameSH'
                  dropdownLabel='Campaign'
                  labelWidth={{ width: '80px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 80px)',
                    minWidth: '193px',
                  }}
                  placeholderLabel={setPlaceHolderLabel(
                    isDisabled.campaign || !campaignList?.length,
                    'Campaign'
                  )}
                  selectedValue={campaign}
                  handleChange={(val) => {
                    if (
                      (!campaign && val) ||
                      (val && campaign.campaign_id !== val.campaign_id)
                    ) {
                      updateIsLoading(false, true, true, false);
                      setPlacement(null);
                      setMediaPartner(null);
                      setCreativeType(null);
                      setCampaign(val);
                      setProperStartDateString(moment(val?.start_date.slice(0, val?.start_date.length - 1)).format('MM/DD/yyyy'));
                      setProperLookbackDateString(moment(val?.lookback_date.slice(0, val?.lookback_date. length - 1)).format('MM/DD/yyyy'));
                    }
                  }}
                  isLoading={isLoading.campaign}
                  isDisabled={isDisabled.campaign || !campaignList?.length}
                  uniqueIdentifier='reportDetailsSummaryHeaderCampaigns'
                  ifIdVisableOnLabel={true}
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='noMarginPadding mediaDeliveryFilterWrapper'
              >
                <MultiselectDropdownComponent
                  dropdownId='mediaPartnerSH'
                  dropdownDataList={mediaPartnerList}
                  dataId='partner_id'
                  dataName='partner_name'
                  label='Media Partner'
                  dropdownData={mediaPartner}
                  setDropdownData={(data) => {
                    setDropdownData(
                      data,
                      setMediaPartner,
                      mediaPartnerRef,
                      mediaPartnerListRef,
                      setDefaultMediaPartner,
                      setMediaPartnerList
                    );
                  }}
                  ifIdSearchAvailable={true}
                  placeholder={setPlaceHolderLabel(
                    isDisabled.mediaPartner || !mediaPartnerList?.length,
                    'Media Partner'
                  )}
                  txtSearch='Search by Partner'
                  isAllCheckedInitially={checkForAllSelected(
                    mediaPartnerList,
                    mediaPartner,
                    'partner_id'
                  )}
                  labelWidth={{ width: '120px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 120px)',
                    minWidth: '162px',
                  }}
                  isLoading={isLoading.mediaPartner}
                  isDisabled={
                    isDisabled.mediaPartner ||
                    !mediaPartnerList?.length ||
                    showCreativeTypeDropdown ||
                    showPlacementDropdown
                  }
                  uniqueIdentifier='reportDetailsSummaryHeaderPartners'
                  isOpen={setShowPartnerDropdown}
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='noMarginPadding mediaDeliveryFilterWrapper'
              >
                <MultiselectDropdownComponent
                  dropdownId='creativeTypeSH'
                  dropdownDataList={creativeTypeList}
                  dataId='value'
                  dataName='name'
                  label='Creative Type'
                  dropdownData={creativeType}
                  setDropdownData={(data) => {
                    setDropdownData(
                      data,
                      setCreativeType,
                      creativeTypeRef,
                      creativeTypeListRef,
                      setDefaultCreativeType,
                      setCreativeTypeList
                    );
                  }}
                  ifIdSearchAvailable={true}
                  placeholder={setPlaceHolderLabel(
                    isDisabled.creativeType || !creativeTypeList?.length,
                    'Creative Type'
                  )}
                  txtSearch='Search by Creative Type'
                  isAllCheckedInitially={checkForAllSelected(
                    creativeTypeList,
                    creativeType,
                    'name'
                  )}
                  labelWidth={{ width: '120px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 120px)',
                    minWidth: '162px',
                  }}
                  isLoading={isLoading.creativeType}
                  isDisabled={
                    isDisabled.creativeType ||
                    !creativeTypeList?.length ||
                    showPartnerDropdown ||
                    showPlacementDropdown
                  }
                  uniqueIdentifier='reportDetailsSummaryHeaderCreativeTypes'
                  isOpen={setShowCreativeTypeDropdown}
                  alignType='right'
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='noMarginPadding mediaDeliveryFilterWrapper'
              >
                <MultiselectDropdownComponent
                  dropdownId='placementSH'
                  dropdownDataList={placementList}
                  dataId='placement_id'
                  dataName='placement_name'
                  dataType='type'
                  label='Placement'
                  dropdownData={placement}
                  setDropdownData={(data) => {
                    setDropdownData(
                      data,
                      setPlacement,
                      placementRef,
                      placementListRef,
                      setDefaultPlacement,
                      setPlacementList
                    );
                  }}
                  ifIdSearchAvailable={true}
                  placeholder={setPlaceHolderLabel(
                    isDisabled.placement || !placementList?.length,
                    'Placement'
                  )}
                  txtSearch='Search by Placement'
                  isAllCheckedInitially={checkForAllSelected(
                    placementList,
                    placement,
                    'placement_id'
                  )}
                  labelWidth={{ width: '81px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 81px)',
                    minWidth: '190px',
                  }}
                  isLoading={isLoading.placement}
                  isDisabled={
                    isDisabled.placement ||
                    !placementList?.length ||
                    showPartnerDropdown ||
                    showCreativeTypeDropdown
                  }
                  uniqueIdentifier='reportDetailsSummaryHeaderPlacements'
                  isOpen={setShowPlacementDropdown}
                  ifIdVisibleOnLabel={true}
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='noMarginPadding mediaDeliveryFilterWrapper'
              >
                <SearchableDropdownComponent
                  options={presentTimeFrameOptions}
                  label='name'
                  id='id'
                  dropdownId='reportDetailsSummaryHeaderTimeFrame'
                  dropdownLabel='Time Frame'
                  labelWidth={{ width: '100px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 100px)',
                    minWidth: '173px',
                  }}
                  placeholderLabel={setPlaceHolderLabel(
                    !campaign,
                    'Time Frame'
                  )}
                  selectedValue={timeFrame}
                  handleChange={setTimeFrame}
                  isSearchable={false}
                  isDisabled={!campaign}
                  uniqueIdentifier='reportDetailsSummaryHeaderTimeFrames'
                  ifShowWarningAtOptions={true}
                  warningOptionId='isDisabled'
                  warningMessage={'Warning! ' + nullImpFreqWarn}
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='noMarginPadding mediaDeliveryFilterWrapper'
              >
                <DatePickerComponent
                  datePickerId='reportDetailsSummaryHeaderDateRange'
                  label='Date Range'
                  labelWidth={{ width: '90px' }}
                  datePickerBodyWidth={{
                    width: 'calc(100% - 90px)',
                    minWidth: '180px',
                  }}
                  placeholderLabel='Start Date - End Date'
                  minDate={properStartDateString}
                  maxDate={properLookbackDateString}
                  isDateRangePicker={true}
                  dateRange={formattedDateRange}
                  setDateRange={setDateRange}
                  isDisabled={timeFrame?.id !== 0}
                  format='MM/DD/YYYY'
                  currentYear={checkCondition(
                    campaign &&
                      daysToFormatWRTDates(properLookbackDateString) <= 0,
                    moment(properLookbackDateString).year(),
                    moment(currentDate).year()
                  )}
                  startYear={checkCondition(
                    campaign,
                    moment(properStartDateString).year() - 1,
                    2010
                  )}
                  uniqueIdentifier='reportDetailsSummaryHeader'
                />
              </Col>
            </Row>
          </Container>
        </div>
        {!isFromLiftTab && checkCondition(
          dataLoading ||
            !ifSelectedAll ||
            isLoading?.campaign ||
            isLoading?.mediaPartner ||
            isLoading?.creativeType ||
            isLoading?.placement,
          renderLoadingComponent(),
          renderDataComponent()
        )}
      </>
    );
  };

  const renderLoadingComponent = () => {
    return (
      <div className='mediaDeliveryOuterContentWrapper'>
        <LoaderComponent headerClass='summaryHeaderLoader' />
      </div>
    );
  };

  const renderDataComponent = () => {
    return (
      <div className='mediaDeliveryOuterContentWrapper'>
        <div className='mediaDeliveryInnerContentWrapper'>
          <div className='mediaDeliveryContentDigit'>
            {formatNumberInThousands(summaryData.dataOne ?? 0)}
          </div>
          <div className='mediaDeliveryContentDescription'>{labels[0]}</div>
        </div>
        <div className='mediaDeliveryInnerContentWrapper'>
          <div className='mediaDeliveryContentDigit'>
            {formatNumberInThousands(summaryData.dataTwo ?? 0)}
          </div>
          <div className='mediaDeliveryContentDescription'>{labels[1]}</div>
        </div>
        <div className='mediaDeliveryInnerContentWrapper'>
          <div className='mediaDeliveryContentDigit'>
            {formatNumberInThousands(summaryData.dataThree ?? 0)}
          </div>
          <div className='mediaDeliveryContentDescription'>{labels[2]}</div>
        </div>
        {isFromMediaDelivery && <div className='mediaDeliveryInnerContentWrapper'>
          <div className='mediaDeliveryContentDigit'>
            {formatNumberInThousands(summaryData.dataFour ?? 0)}
          </div>
          <div className='mediaDeliveryContentDescription'>{labels[3]}</div>
        </div>}
      </div>
    );
  };

  const renderErrorComponent = () => {
    return (
      <ErrorComponent
        refreshAction={[
          getCampaignList,
          getReportsDetails,
          getPartnerList,
          getCreativeTypeList,
          getPlacementList,
          getMediaDelivery,
        ]}
        controller={cancelController}
      />
    );
  };

  return checkCondition(
    isErrorCampaignList ||
      isErrorPartnerList ||
      isErrorCreativeTypeList ||
      isErrorPlacementList ||
      isErrorMediaDelivery ||
      isErrorReportDetails,
    renderErrorComponent(),
    renderSummaryComponent()
  );
}

export default SummaryHeader;

const setDropdownData = (
  data,
  setData,
  dataRef,
  dataListRef,
  setDefaultData,
  setDataList
) => {
  if (data) {
    setData(data);
  } else {
    setDataList(dataListRef.current ? [...dataListRef.current] : null);
  }
  setDefaultData(null);
};

const compareData = (dataList, selectedData, id) => {
  return selectedData
    .map((elem) =>
      dataList.find((el) => {
        if (el[id] === elem[id]) return el;
      })
    )
    .filter((elem) => elem !== undefined);
};

const getSortedArray = (data, id) => {
  return data.map((elem) => elem[id]).sort((a, b) => a - b);
};

const checkForSamePlacements = (dataList, selectedData, id) => {
  if (!dataList || dataList.length <= 0) {
    return false;
  }

  if (selectedData.length <= 0) {
    return true;
  }

  return (
    dataList.length !== selectedData.length ||
    JSON.stringify(getSortedArray(dataList, id)) !==
      JSON.stringify(getSortedArray(selectedData, id))
  );
};

const checkForAllSelected = (dataList, selectedData, id) => {
  if (!selectedData?.length || !dataList?.length) {
    return false;
  }

  return compareData(dataList, selectedData, id).length === dataList.length;
};


const checkNoData = (isFromMediaDelivery, setNoData, data) => {
  if (isFromMediaDelivery) {
    !Number(data.values[data.keys[0]]) &&
    !Number(data.values[data.keys[1]]) &&
    !Number(data.values[data.keys[2]]) &&
    !Number(data.values[data.keys[3]])
      ? setNoData(true)
      : setNoData(false);
  } else {
    !Number(data.values[data.keys[0]]) &&
    !Number(data.values[data.keys[1]]) &&
    !Number(data.values[data.keys[2]])
      ? setNoData(true)
      : setNoData(false);
  }
};