import React from 'react';
import { CommonHeadingComponent } from '../../../../../../components';
import EngagementConversionGoalsComponent from './EngagementConversionGoals.component';
import './analysis.css';

const AnalysisComponent = ({ campaign, startDate, endDate }) => {
  return (
    <div className='liftAnalysisWrapper'>
      <CommonHeadingComponent headingLabel='Analysis' />
      <EngagementConversionGoalsComponent campaign={campaign} startDate={startDate} endDate={endDate} />
    </div>
  );
};

export default AnalysisComponent;
