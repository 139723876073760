import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import {
  CommonHeadingComponent,
  LoaderComponent,
  NoDataComponent,
  SearchableDropdownComponent,
  ErrorComponent,
} from '../../../../../../components';
import AttributionLineChart from './AttributionLineChart';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../../../constants';
import {
  dateFormat,
  formatNumberInThousands,
} from '../../../../../../utils/functions';
import './attributableVsIncremental.css';

const AttributableVsIncrementalComponent = ({
  campaign,
  placement,
  mediaPartner,
  creativeType,
  ifSelectedAll,
  parametersIsLoading,
  startDate,
  endDate
}) => {
  const [graphData, setGraphData] = useState(null),
    [goalList, setGoalList] = useState([]),
    [usableGoalListOpts, setUsableGoalListOpts] = useState([]),
    [selectedGoal, setSelectedGoal] = useState(null);

  const [isError, setIsError] = useState(false),
    [isLoadingGoals, setIsLoadingGoals] = useState(true),
    [isLoading, setIsLoading] = useState(true),
    [cancelController, setCancelController] = useState(null);

  // Fetching goal list and set the 1st goal as selected one
  const getGoalsList = async (controller) => {
    setIsLoadingGoals(true);
    setIsError(false);
    try {
      const response = await axiosSendRequest(
        `${baseEndpoints.reports}/lift-tab/goal-list`,
        controller.signal,
        {
          campaign_id: campaign?.campaign_id,
          partner_id: getArrayOfItems(mediaPartner, 'partner_id'),
          creative_type: getArrayOfItems(creativeType, 'name'),
          placement_id: getArrayOfItems(placement, 'placement_id'),
          from: dateFormat(startDate, 'YYYY-MM-DD'),
          to: dateFormat(endDate, 'YYYY-MM-DD'),
        }
      );

      // if status is 200 then data fetching is successful, hence updating the list
      if (response.status === 200) {
        if (response.data && response.data.length > 0) {
          setGoalList(response.data);
          setIsLoading(true);
        } else {
          setGoalList([]);
          setSelectedGoal(null);
        }
        setIsLoadingGoals(false);
        return;
      }
      setIsError(true);
      setGoalList([]);
      setSelectedGoal(null);
      setIsLoadingGoals(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err);
        setGoalList([]);
        setSelectedGoal(null);
        setIsLoadingGoals(false);
      }
    }
  };

  // fetching graph data from backend
  const getChartData = async (controller) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const responseGraphData = await axiosSendRequest(
        `${baseEndpoints.reports}/lift-tab/attributable-vs-incremental`,
        controller,
        {
          campaign_id: campaign?.campaign_id,
          from: dateFormat(startDate, 'YYYY-MM-DD'),
          to: dateFormat(endDate, 'YYYY-MM-DD'),
          mock_data: false,
          goal_id: selectedGoal.goal_id,
        }
      );

      // if status is 200 then data fetching is successful, hence updating the state
      if (responseGraphData.status === 200) {
        setGraphData(responseGraphData.data);
        setIsLoading(false);
        return;
      }

      setIsError(true);
      setGraphData(null);
      setIsLoading(false);
    } catch (err) {
      // if request has not ben cancelled, then treated as error
      if (!axios.isCancel(err)) {
        setGraphData(null);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (placement) {
      const controller = new AbortController();
      setCancelController(controller);
      getGoalsList(controller);
      return () => {
        controller.abort();
      };
    } else {
      setGoalList([]);
      setSelectedGoal(null);
      setIsLoadingGoals(false);
    }
  }, [placement, startDate, endDate]);

  useEffect(() => {
    // if goal is selected, then initializing the request
    if (selectedGoal) {
      const controller = new AbortController();
      setCancelController(controller);
      getChartData(controller);

      return () => {
        // if request has been cancelled, then aborting it
        controller.abort();
      };
    } else {
      setGraphData(null);
      setIsLoading(false);
    }
  }, [selectedGoal]);

  useEffect(() => {
    const generatedGoalList = generateUsableGoalList(goalList);

    setUsableGoalListOpts(generatedGoalList);

    if (generatedGoalList?.length) {
      setSelectedGoal(generatedGoalList[1]);
    }
  }, [goalList]);

  return (
    <div className='attrVsIncEventsWrapper'>
      <CommonHeadingComponent
        headingLabel='Attributable Vs Incremental Events'
        headingCTALabel={
          <HeadeingSearchField
            goalList={usableGoalListOpts}
            selectedGoal={selectedGoal}
            setSelectedGoal={setSelectedGoal}
            isLoading={isLoadingGoals}
            ifSelectedAll={ifSelectedAll}
            parametersIsLoading={parametersIsLoading}
          />
        }
        ifDropdown={true}
      />
      {isLoadingGoals || isLoading || !ifSelectedAll ? (
        <LoaderComponent headerClass='leadingHeaderClass' />
      ) : (
        <>
          {isError ? (
            <ErrorComponent
              refreshAction={getGoalsList}
              controller={cancelController}
            />
          ) : (
            <>
              {/* {!selectedGoal ? ( */}
              {!graphData ? (
                <NoDataComponent
                  type='line'
                  width='180'
                  height='180'
                  messageTextFontSize={15}
                  foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
                  messageText='No Data Available'
                />
              ) : (
                <Container
                  fluid
                  className='removePaddingOrMargin attrVsIncEventsBody'
                >
                  <Row className='removePaddingOrMargin'>
                    <Col className='removePaddingOrMargin'>
                      <AttributionLineChart data={graphData?.all_data} />
                    </Col>
                  </Row>
                  <Row className='removePaddingOrMargin attrVsIncEventsDataWrapper'>
                    <Col
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      className='removePaddingOrMargin attrVsIncEventsDataElements'
                    >
                      <div className='attrVsIncEventsDataValue'>
                        {formatNumberInThousands(
                          graphData?.last_data?.incremental_events
                        )}
                      </div>
                      <div className='attrVsIncEventsDataLabel'>
                        <span style={{ textTransform: 'capitalize' }}>
                          {graphData?.last_data?.goal_type}
                        </span>{' '}
                        Inc. Events
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AttributableVsIncrementalComponent;

const getArrayOfItems = (data, type) => {
  return data.map((item) => item[type]);
};

const generateUsableGoalList = (goalList) => {
  if (!goalList?.length) {
    return [];
  }

  const engagementOptions = [
      {
        goal_id: -1,
        goal_name: 'Engagement Goals',
        goal_type: 'engagement',
        isParent: true,
        isHeading: true,
      },
    ],
    conversionOptions = [
      {
        goal_id: -2,
        goal_name: 'Conversion Goals',
        goal_type: 'conversion',
        isParent: true,
        isHeading: true,
      },
    ];

  goalList.map((goal) => {
    if (goal.goal_type === 'engagement') {
      engagementOptions.push({ ...goal, isParent: false });
    } else {
      conversionOptions.push({ ...goal, isParent: false });
    }
  });

  const newGoalOptions = [].concat(
    engagementOptions.length > 1 ? engagementOptions : [],
    conversionOptions.length > 1 ? conversionOptions : []
  );

  return newGoalOptions;
};

const determineSummaryHeaderLoading = (parametersIsLoading, ifSelectedAll) => {
  return Object.values(parametersIsLoading).includes(true) || !ifSelectedAll;
};

const HeadeingSearchField = ({
  goalList,
  selectedGoal,
  setSelectedGoal,
  isLoading,
  ifSelectedAll,
  parametersIsLoading,
}) => (
  <SearchableDropdownComponent
    options={goalList}
    label='goal_name'
    id='goal_id'
    dropdownId='attrVsIncGoalList'
    dropdownLabel=''
    labelWidth={{ margin: 0 }}
    dropdownWidth={{
      width: '180px',
    }}
    wrapperClass=''
    placeholderLabel={goalList?.length ? 'Select Goal' : 'Goal unavailable'}
    selectedValue={selectedGoal}
    handleChange={setSelectedGoal}
    isSearchable={false}
    // isLoading={
    //   isLoading ||
    //   determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    // }
    // isLoadingAvailable={2}
    // loaderWidth={{ width: '22px' }}
    isDisabled={
      !goalList?.length ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    }
    isParentChildBasedOption={true}
    parentIdentificationKey='isParent'
    uniqueIdentifier='reportDetailsLiftAttributableVsIncrementalGoals'
    ifIdVisableOnLabel={true}
  />
);
