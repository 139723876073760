import React from 'react';
import { Link } from 'react-router-dom';
import { capitalizeRoles, dateFormat } from '../../../../utils/functions';
import { resendUserInvite } from '../UserEdit.component';
import { CommonButtonComponent } from '../../../../components';
import { useDispatch } from 'react-redux';
import '../../users.css';

export default (profile, showReinviteButton, showInviteStatus, setIsMounted) => {
  const dispatch = useDispatch();

  let columns = [
    {
      id: 'lastName',
      name: 'Last Name',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          <div className='campaignNameStyleCL'>{row.lastName}</div>
        </div>
      ),
      sortable: true,
      sortField: 'last_name', // sorting
      width: '20%',
      minWidth: '200px !important',
    },
    {
      id: 'firstName',
      name: 'First Name',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          <div className='campaignNameStyleCL'>{row.firstName}</div>
        </div>
      ),
      sortable: true, // sorting
      sortField: 'first_name', // sorting
      width: '20%',
      minWidth: '200px !important',
    },
    {
      id: 'email',
      name: 'Email Address',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link to={`/users/${row.id}`}>
            <div className='campaignNameStyleCL'>{row.email}</div>
          </Link>
        </div>
      ),
      sortable: true, // sorting
      sortField: 'email', // sorting
      width: '32%',
      minWidth: '300px !important',
    },
    {
      id: 'role',
      name: 'Role',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          <div className='campaignNameStyleCL'>{capitalizeRoles(row.role)}</div>
        </div>
      ),
      sortable: true, // sorting
      sortField: 'role', // sorting
      width: '10%',
      minWidth: '150px !important',
    },
    {
      id: 'lastConnect',
      name: 'Last Signed In',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          <div className='campaignNameStyleCL'>
            {row.lastConnect ? dateFormat(row.lastConnect, 'MM/DD/YYYY') : ''}
          </div>
        </div>
      ),
      sortable: true, // sorting
      sortField: 'last_connect', // sorting
      width: '10%',
      minWidth: '150px !important',
    },
    {
      id: 'statusLabel',
      name: 'Status',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          <div className='campaignNameStyleCL'>{row.statusLabel}</div>
        </div>
      ),
      sortable: true, // sorting
      sortField: 'statusLabel', // sorting
      width: '8%',
      minWidth: '120px !important',
    },
    {
      id: 'organization',
      name: 'Organization',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          <div className='campaignNameStyleCL'>{row.organization}</div>
        </div>
      ),
      sortable: true, // sorting
      sortField: 'organization', // sorting
      width: '10.5%',
      minWidth: '150px !important',
      omit: profile !== 'super admin',
    },
    {
      id: 'invitationStatusLabel',
      name: 'Invite Status',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          <div className='campaignNameStyleCL'>{!row.sso_managed && row.cognitoInviteStatus}</div>
        </div>
      ),
      sortable: false, // sorting
      sortField: 'invitationStatusLabel', // sorting
      width: '8%',
      minWidth: '120px !important',
      omit: !showInviteStatus,
    },
    {
      id: 'reinviteButton',
      omitDropdownName: 'Reinvite Button',
      name: '',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          <div className='campaignNameStyleCL'>
            {row.cognitoInviteStatus === 'Expired' && !row.sso_managed && (
              <CommonButtonComponent
                label='Reinvite'
                isDisabled={false}
                showLoader={false}
                onClickCTA={() => {
                  resendUserInvite(row.email, setIsMounted, dispatch);
                }}
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnSecondary'
                appendWrapperStyle={{ padding: '0' }}
              />
            )}
          </div>
        </div>
      ),
      sortable: false, // sorting
      sortField: 'invitationStatusLabel', // sorting
      width: '8%',
      minWidth: '120px !important',
      omit: !showReinviteButton,
    },
  ];

return columns
};
