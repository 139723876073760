import React, { useEffect, useRef } from 'react';
import { toggleClasses } from '../../utils/functions';

const CommonSearch = ({
  placeholder,
  searchInput,
  setSearchInput,
  disabled,
  customStyle = null,
  uniqueIdentifier,
  autofocus = false
}) => {

  const inputRef = useRef(null);

  useEffect(() => {
    if(inputRef.current && !disabled && autofocus) {
      inputRef.current.focus();
    }
  }, [disabled, autofocus]);

  return (
    <div className='searchInfo'>
      <input
        type='text'
        id={uniqueIdentifier ? uniqueIdentifier + 'SearchField' : undefined}
        name={uniqueIdentifier ? uniqueIdentifier + 'SearchField' : undefined}
        value={searchInput}
        placeholder={placeholder}
        onChange={(e) => setSearchInput(e.target.value)}
        className={toggleClasses(
          searchInput?.length > 0,
          'utilitySearch',
          'utilitySearch magnifyGlass'
        )}
        disabled={disabled}
        style={customStyle}
        autoComplete='off'
        ref={inputRef}
      />
    </div>
  );
};

export default CommonSearch;
