import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CommonButtonComponent } from '../../../../components';
import endpoints from '../../../../constants/endpoints';
import { axiosGetRequest } from '../../../../services/http/axiosCalls';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const createColumns = (campaignId, navigate = () => {}) => {
  const onClickClone = (campaignId, placementId) => {
    axiosGetRequest(`/placements/detail/${campaignId}/${placementId}`).then(
      (res) => {
        navigate(`/campaign/${campaignId}/placement/new`, {
          state: {
            placementData: res.data.table_data[0],
            isClonePlacement: true,
          },
        });
      }
    );
  };

  const inRestatementQueueCheck = (
    row,
    firstClass,
    secondClass,
    thirdClass,
    defaultClass
  ) => {
    return row.restatementStatus?.passed
      ? firstClass
      : row.restatementStatus?.queued
      ? secondClass
      : row.restatementStatus?.failed
      ? thirdClass
      : defaultClass;
  };

  return [
    {
      id: 'action',
      name: 'ACTION',
      selector: (row) => {
        return (
          <CommonButtonComponent
            label={'Clone'}
            appendWrapperClassName='btnPrimaryWrapperPlacementClone'
            appendButtonClassName='btnPrimaryPlacementClone'
            onClickCTA={() => {
              onClickClone(campaignId, row.placement_id);
            }}
          />
        );
      },
      width: '10%',
    },
    {
      id: 'placement_id',
      name: 'PLACEMENT ID',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${campaignId}/placement/${row.placement_id}/edit`}
          >
            <div className='campaignNameStyleCL'>{row.placement_id}</div>
          </Link>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'placement_id', // sorting
      width: '10%',
      minWidth: '145px !important',
    },
    {
      id: 'placement_status',
      name: 'STATUS*',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <OverlayTrigger
            key={`placementStatusHeader`}
            overlay={
              <Tooltip id='tooltip-disabled'>
                {/* {'Green - An Impression Has Fired In the Last Hour.'}
                <br /> */}
                {row.impressions >= 1 &&
                  'Live - An Impression Has Fired Today.'}
                {/* <br /> */}
                {row.impressions < 1 &&
                  'Not Live - No Impressions Have Fired Today'}
                {(row.restatementStatus) &&
                <div>
                  <br />
                </div>
                }
                {row.restatementStatus?.failed &&
                  'Red - Restatement Has Failed For This Placement'}
                {row.restatementStatus?.queued &&
                  'Orange - This Placement Has Been Queued For Restatement'}
                {row.restatementStatus?.passed &&
                  'Green - This Placement Has Been Restated'}
              </Tooltip>
            }
          >
            <div className='tableCellPlacementStatusWrapper'>
              <div
                style={{
                  color: row.impressions >= 1 ? 'green' : 'red',
                }}
                className={`tableCellFontStyle tableCellPlacementStatusImpressions bi 
              ${
                row.impressions >= 1 ? 'bi-check-circle' : 'bi-x-octagon'
              }
              `}
              />
              &nbsp;&nbsp;
              <div
                style={{
                  color: inRestatementQueueCheck(row, 'green', 'orange', 'red', ''),
                }}
                className={`tableCellFontStyle tableCellPlacementStatusRestatement bi 
                ${row.restatementStatus && 'bi-r-circle'} `}
              />
            </div>
          </OverlayTrigger>
        </div>
      ),
      sortable: false, // sorting
      width: '84px',
    },
    {
      id: 'placement_name',
      name: 'PLACEMENT NAME',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${campaignId}/placement/${row.placement_id}/edit`}
          >
            <div className='campaignNameStyleCL'>{row.placement_name}</div>
          </Link>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'placement_name', // sorting
      width: '34%',
    },
    {
      id: 'start_date',
      name: 'START DATE',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle'>
            {moment.utc(row.start_date).format('MM/DD/YYYY')}
          </div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'start_date', // sorting
      width: '12%',
    },
    {
      id: 'end_date',
      name: 'END DATE',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle'>
            {moment.utc(row.end_date).format('MM/DD/YYYY')}
          </div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'end_date', // sorting
      width: '12%',
    },
    {
      id: 'media_partner',
      name: 'MEDIA PARTNER',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle'>{row.media_partner}</div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'media_partner', // sorting
      width: '14%',
    },
    {
      id: 'placement_type',
      name: 'TYPE',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle'>{row.placement_type}</div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'placement_type', // sorting
      width: '16%',
    },
  ];
};
