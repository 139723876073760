import React from 'react';
import './errorComponent.css';

const ErrorComponent = ({
  refreshAction = null,
  controller = null,
  status = null,
  ifNotAvailable = false,
  expiredMessage = null,
  customWrapperClass = null,
}) => {
  const refreshPageOrModule = () => {
    if (!refreshAction) {
      window.location.reload();
    } else if (typeof refreshAction === 'object') {
      refreshAction.map((value, _index) => {
        value(controller);
      });
    } else {
      refreshAction(controller);
    }
  };

  return (
    <div className={`errorContent ${customWrapperClass ?? ''}`}>
      <div className='errorContentInner'>
        <span className='error-img'></span>
        {!ifNotAvailable ? (
          <div>
            <h3>Something went wrong!</h3>
            <div className='errorMessage'>
              Please <span onClick={refreshPageOrModule}>Refresh</span>
            </div>
          </div>
        ) : (
          <div>
            <div className='errorMessage'>{expiredMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorComponent;
