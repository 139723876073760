import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  ErrorComponent,
  CommonInput,
  SearchableDropdownComponent,
  CommonButtonComponent,
} from "../../../../components";
import { commonNotification } from "../../../../utils/common-notifications";

import { useGetApiInterceptor } from "../../../../hooks/useGetApiInterceptor";
import { baseEndpoints } from "../../../../constants";
import {
  axiosPatchRequest,
  axiosPostRequest,
} from "../../../../services/http/axiosCalls";
import { setBlockingLoaderState } from '../../../../redux/blockingLoader';
import "../goals.css";
import { isValidateURL } from "../../../../utils/functions";

// Goal Listing Component
const GoalEdit = ({ propertyId, goalId, navigateBackCallback, setGoalsReload = () => {} }) => {
  const newPropertyId = Number(useParams()?.id);
  const [searchParams] = useSearchParams(),
    dataParams = searchParams.get('params');
  
  const typesData = [
    { id: "engagement", label: "Engagement" },
    { id: "conversion", label: "Conversion" },
  ];
  const [isMounted, setIsMounted] = useState(false);
  const [url, setUrl] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [goalData, setGoalData] = useState({
    name: '',
    type: '',
    description: '',
    postback_url: '',
  }); // State to handle Goal Name in form input

  const [defaultGoalData, setDefaultGoalData] = useState({
    name: '',
    type: '',
    description: '',
    postback_url: '',
  })

  const [isChanged, setIsChanged] = useState({
    name: false,
    type: false,
    description: false,
    postback_url: false,
  });

  const [validName, setValidName] = useState({
    valid: true,
    errorMsg: ''
  });

  const [createMode, setCreateMode] = useState(false); // State to define, if in Create or Edit Goal
  const [editInitialized, setEditInitialized] = useState(false); // State to handle Edit Goal initialization

  const { data, isLoading, isError } = useGetApiInterceptor(isMounted, null, url); // Goal Details

  const validateURL = urlToValidate => {
    if (!urlToValidate) {
      return true;
    }
    return isValidateURL(urlToValidate);
  }
  
  const checkChange = (value, key)=>{
    if(key === 'type'){
      setIsChanged({...isChanged, [key]: value?.id !== defaultGoalData[key]['id']})
      return
    }
    setIsChanged({...isChanged, [key]: value !== defaultGoalData[key]})
  }

  // Goal Form for Create/Edit Goal
  const goalForm = () => {
    if (!createMode && !editInitialized && data.data_count) {
      let _goal = data.table_data[0];
      _goal.type = typesData.filter((tp) => tp.id == _goal.type)[0];
      setGoalData(_goal);
      setDefaultGoalData(_goal);
      setEditInitialized(true);
    }

    return (
      <div className="goalComponentWrapper advFormWrapper">
        <Row style={{padding: 0}}>
          <Col lg={6} className='goalComponentElemWrapper relative'>
            <CommonInput
              placeholder={"Goal Name"}
              inputValue={goalData.name}
              setInputValue={(value) => {
                setGoalData({
                  ...goalData,
                  name: value,
                });
                checkChange(value, 'name')
                if(value?.length>100){
                  setValidName({valid: false, errorMsg: 'Maximum characters allowed for Name is 100'})
                } else if (value.match(/[^a-z0-9_]/)) {
                  setValidName({ valid: false, errorMsg: 'Goal Name Cannot Contain Spaces, Special Characters, Or Capital Letters. Underscores Are Allowed.' })
                } else {
                  setValidName({valid: true, errorMsg: ''})
                }
              }}
              disabled={!createMode && isLoading}
              className={validName.valid?'goalInput' : 'goalInput errorInput'}
              label='Name'
              labelClass='requiredInput'
              inputStyle={setElementStyles()}
              isLoading={!createMode && isLoading}
              uniqueIdentifier={getPrefixOfUniqueIdentifier(goalId) + 'Name'}
              onBlurHandle={(val)=>{
                if(!val?.target?.value){
                  setValidName({valid: false, errorMsg: 'Please enter a Name'})
                } else if(val?.target?.value?.length>100){
                  setValidName({valid: false, errorMsg: 'Maximum characters allowed for Name is 100'})
                } else if (val?.target?.value?.match(/[^a-z0-9_]/)) {
                  setValidName({ valid: false, errorMsg: 'Goal Name Cannot Contain Spaces, Special Characters, Or Capital Letters. Underscores Are Allowed.' })
                } else {
                  setValidName({valid: true, errorMsg: ''})
                }
              }}
            />
            {!validName.valid&&<span className='error'>{validName.errorMsg}</span>}
          </Col>
          <Col lg={6} className='goalComponentElemWrapper'>
            <SearchableDropdownComponent
              options={typesData}
              label="label"
              id="id"
              dropdownId="typesDD"
              dropdownLabel="Type"
              isSearchable={false}
              placeholderLabel='Set Type'
              dropdownWidth={setElementStyles()}
              selectedValue={goalData.type}
              handleChange={(value) => {
                setGoalData({
                  ...goalData,
                  type: value,
                });
                checkChange(value, 'type')
              }}
              isLoading={!createMode && isLoading}
              isDisabled={!createMode && isLoading}
              wrapperClass='requiredDD'
              uniqueIdentifier={getPrefixOfUniqueIdentifier(goalId) + 'Type'}
            />
          </Col>
          <Col lg={6} className='goalComponentElemWrapper'>
            <CommonInput
              placeholder={"Description"}
              inputValue={goalData.description}
              setInputValue={(value) => {
                setGoalData({
                  ...goalData,
                  description: value,
                });
                checkChange(value, 'description')
              }}
              disabled={!createMode && isLoading}
              className={"goalInput"}
              label={"Description "}
              inputStyle={setElementStyles()}
              isLoading={!createMode && isLoading}
              uniqueIdentifier={getPrefixOfUniqueIdentifier(goalId) + 'Description'}
            />
          </Col>
          <Col lg={6} className='goalComponentElemWrapper'>
            <CommonInput
              placeholder={"Postback"}
              inputValue={goalData.postback_url}
              setInputValue={(value) => {
                setGoalData({
                  ...goalData,
                  postback_url: value,
                });
                checkChange(value, 'postback_url')
              }}
              disabled={!createMode && isLoading}
              className={"goalInput"}
              label={"Postback URL "}
              inputStyle={setElementStyles()}
              isLoading={!createMode && isLoading}
              uniqueIdentifier={getPrefixOfUniqueIdentifier(goalId) + 'PostBackURL'}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className='goalComponentButtonWrapper'>
            <CommonButtonComponent
              label="Save"
              isDisabled={!goalData.name || !goalData?.type || 
                !validName.valid || !Object.values(isChanged).includes(true)}
              showLoader={false}
              onClickCTA={() => {
                saveGoal();
              }}
              buttonUI={getPrefixOfUniqueIdentifier(goalId) + 'Save'}
              appendWrapperClassName='btnPrimaryWrapper'
              appendButtonClassName='btnPrimary'
            />
            <CommonButtonComponent
              label="Cancel"
              isDisabled={false}
              showLoader={false}
              onClickCTA={
                propertyId
                  ? navigateBackCallback
                  : () =>
                      navigate(
                        '/properties/' +
                          newPropertyId +
                          '/?params=' +
                          dataParams
                      )
              }
              inverted={true}
              buttonUI={getPrefixOfUniqueIdentifier(goalId) + 'Cancel'} 
              appendWrapperClassName='btnPrimaryWrapper'
              appendButtonClassName='btnSecondary'
            />
          </Col>
        </Row>
      </div>
    );
  };

  // Method to Save Goal, for Create and Edit Goal
  const saveGoal = () => {
    if (!validateURL(goalData.postback_url)) {
      commonNotification({ msg: "Must be a valid URL", type: "danger" });
      return;
    }
    
    dispatch(setBlockingLoaderState({active: true}));
    let payload = {
      ...goalData,
      type: goalData.type.id,
      name: goalData.name.trim(),
      description: goalData.description.trim(),
      postback_url: goalData.postback_url.trim()
    };

    let requestPromise;
    if (createMode) {
      requestPromise = axiosPostRequest(url, payload);
    } else {
      requestPromise = axiosPatchRequest(url, null, payload);
    }

    requestPromise.then(
      (response) => {
        if (response.status === 200) {
          if (propertyId) {
            navigateBackCallback(true, !goalId);
          } else {
            navigate('/properties/'+newPropertyId+'/?params='+dataParams);
          }
        } else if (response.status == 400) {
          commonNotification({ msg: response.error, type: "danger" });
        } else {
          commonNotification({ msg: "Something went wrong", type: "danger" });
        }
        dispatch(setBlockingLoaderState({active: false}));
        setGoalsReload(true);
      },
      (err) => {
        if(err?.response?.status === 409){
          commonNotification({
            msg: err?.response?.data?.error,
            type: 'danger',
          });
          setValidName({valid:false, errorMsg:err?.response?.data?.error})
          const searchElem = document.getElementById(`${getPrefixOfUniqueIdentifier(goalId)}Name`);
          searchElem?.focus();
          dispatch(setBlockingLoaderState({ active: false }));
          return;
        }
        commonNotification({ msg: typeof err?.response?.data === 'string' ? err.response.data : err?.response?.data?.error, type: "danger" });
        dispatch(setBlockingLoaderState({active: false}));
      }
    );
  };

  /**
   * First time to initialize api calls and set up data
   * @dependencies : client, page-number, sort-data, per-page-data
   */
  useEffect(() => {
    if (!goalId) {
      setHeaderTitle("Create Goal");
      setGoalData({ ...goalData, type: typesData[0] });
      setCreateMode(true);
      setUrl(`${baseEndpoints.properties}/${propertyId ? propertyId : newPropertyId}/goals`);
    } else {
      setHeaderTitle("Edit Goal");
      setUrl(`${baseEndpoints.properties}/${propertyId}/goals/${goalId}`);
      setIsMounted(true);
    }
  }, [goalId]);

  return (
    <React.Fragment>
      <Container fluid className={!propertyId ? 'newGoalElementWrapper': ''}>
        <Row style={{padding: 0}}>
          <div
            id={getPrefixOfUniqueIdentifier(goalId) + 'BackToAllGoalsCTA'}
            name={getPrefixOfUniqueIdentifier(goalId) + 'BackToAllGoalsCTA'}
            className='backToAllBtn'
            onClick={
              propertyId
                ? navigateBackCallback
                : () =>
                    navigate(
                      '/properties/' +
                        newPropertyId +
                        '/?params=' +
                        dataParams
                    )
            }
          >
            <div>&larr; Back to All Goals</div>
          </div>
        </Row>
        <Row style={{padding: 0}}>
          <h3 className="font-bold"> {headerTitle}</h3>
        </Row>
      </Container>
      <Container>
        <Row style={{padding: 0}}>
          <Col lg={12}>
            <div className="goalsOuterWrapper">
              {isError ? (
                <ErrorComponent
                  refreshAction={setUrl}
                  ifNotAvailable
                  expiredMessage={<ErrorHTML />}
                />
              ) : (
                <div>{goalForm()}</div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GoalEdit;

// Error HTML for navigating user to Goal List or Create Goal
const ErrorHTML = () => {
  return (
    <div>
      <h3>The goal does not exist!</h3>
      <div className="errorMessage">
        Please navigate to <Link to={"/goals"}>Goals List</Link> or{" "}
        <Link to={"/goals/new"}>Create New Goal</Link>
      </div>
    </div>
  );
};

const setElementStyles = () => {
  return {
    width: '100%',
    minWidth: '175px',
  };
};

const getPrefixOfUniqueIdentifier = (goalId) => {
  return goalId ? 'editGoal' : 'createGoal';
}
