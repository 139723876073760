import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { dateFormat, FormatLabel } from '../../../../utils/functions';

const CreateReportModalBody = ({
  campaign = null,
  placement = null,
  partner = null,
  timeFrame = null,
  startDate = null,
  endDate = null,
  reportName = null,
  creativeType = null,
  setReportName,
  showRunAndSave,
  reportNameMaxLength,
}) => {
  return (
    <div className='createReportModalWrapper'>
      <Container
        fluid
        className='removePaddingOrMargin createReportModalInputContainer'
      >
        <Row className='removePaddingOrMargin createReportModalLineSpacing'>
          <Col xl={4} lg={4} md={4} sm={4} className='removePaddingOrMargin'>
            {<FormatLabel label='Report Name' isMandatory={showRunAndSave} />}
          </Col>
          <Col xl={8} lg={8} md={8} sm={8} className='removePaddingOrMargin'>
            <input
              type='text'
              id='createReportNewReportName'
              name='createReportNewReportName'
              maxLength={reportNameMaxLength}
              value={reportName ?? ''}
              className='createReportModalInput'
              onChange={(e) => {
                setReportName(e.target.value);
              }}
              placeholder='Enter the name of the Report'
              autoComplete='off'
            />
          </Col>
          {reportName?.length >= reportNameMaxLength && (
            <div className='createReportModalNameError'>
              Report Name Is Limited To {reportNameMaxLength} Characters - Current: {reportName.length}
            </div>
          )}
        </Row>
      </Container>
      <Container
        fluid
        className='removePaddingOrMargin createReportModalStaticContainer'
      >
        <Row className='removePaddingOrMargin createModalLineSpacing'>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={4}
            className='removePaddingOrMargin staticLabel'
          >
            Campaign
          </Col>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={8}
            className='removePaddingOrMargin staticData'
          >
            {campaign}
          </Col>
        </Row>
        <Row className='removePaddingOrMargin createModalLineSpacing'>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={4}
            className='removePaddingOrMargin staticLabel'
          >
            Media Partner
          </Col>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={8}
            className='removePaddingOrMargin staticData'
          >
            {partner}
          </Col>
        </Row>
        <Row className='removePaddingOrMargin createModalLineSpacing'>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={4}
            className='removePaddingOrMargin staticLabel'
          >
            Creative Type
          </Col>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={8}
            className='removePaddingOrMargin staticData'
          >
            {creativeType}
          </Col>
        </Row>
        <Row className='removePaddingOrMargin createModalLineSpacing'>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={4}
            className='removePaddingOrMargin staticLabel'
          >
            Placement
          </Col>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={8}
            className='removePaddingOrMargin staticData'
          >
            {placement}
          </Col>
        </Row>
        {timeFrame?.id ? (
          <Row className='removePaddingOrMargin createModalLineSpacing'>
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={4}
              className='removePaddingOrMargin staticLabel'
            >
              Time Frame
            </Col>
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={8}
              className='removePaddingOrMargin staticData'
            >
              {timeFrame.name}
            </Col>
          </Row>
        ) : (
          <Row className='removePaddingOrMargin createModalLineSpacing'>
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={4}
              className='removePaddingOrMargin staticLabel'
            >
              Custome Date Range
            </Col>
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={8}
              className='removePaddingOrMargin staticData'
            >
              {dateFormat(startDate)} - {dateFormat(endDate)}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default CreateReportModalBody;
