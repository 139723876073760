import { createSlice } from '@reduxjs/toolkit';

export const blockingLoaderSlice = createSlice({
  name: 'blockingLoader',
  initialState: {
    active: false
  },
  reducers: {
    setBlockingLoaderState: (state, newState) => {
      state.active = newState.payload.active;
    },
  },
});

export const {
  setBlockingLoaderState
} = blockingLoaderSlice.actions;
export default blockingLoaderSlice.reducer;
