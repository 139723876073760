import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  CampaignsSnapshotComponent,
  AdvertisersYTD,
  CampaignsYTD,
  RunReportComponent,
  ImpressionsVsSpend,
} from './components';
import { breadCrumbHeader } from '../../constants';

const Dashboard = () => {
  document.title = 'Dashboard';

  const username = useSelector((state) => state.user.firstName);

  return (
    <React.Fragment>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <div className='homeGray'></div>
          <div className='breadCrumbBody'>{breadCrumbHeader.dashboard}</div>
        </div>
        <div>
          <h1>Welcome, {username}</h1>
        </div>
      </div>

      <Container fluid>
        <Row id='dashboardGrid'>
          <Col
            xl={8}
            lg={7}
            md={12}
            sm={12}
            className='activeCampaignsOuterWrapper'
          >
            <CampaignsSnapshotComponent />
          </Col>
          <Col
            xl={4}
            lg={5}
            md={12}
            sm={12}
            className='upcomingCampaignsOuterWrapper'
          >
            <RunReportComponent />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col
            xl={8}
            lg={12}
            md={12}
            sm={12}
            className='activeCampaignsOuterWrapper'
          >
            <CampaignsYTD />
          </Col>
          <Col
            xl={4}
            lg={12}
            md={12}
            sm={12}
            className='activeCampaignsOuterWrapper'
          >
            <AdvertisersYTD />
          </Col>
        </Row>
      </Container>
      {/* <Container fluid>
          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="activeCampaignsOuterWrapper"
            >
              <CampaignListComponent />
            </Col>
          </Row>
        </Container> */}
      <Container fluid>
        <Row>
          <Col>
            <ImpressionsVsSpend />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
