import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CommonDonutChart from '../../../../components/charts/CommonDonutChart.component';
import { Container, Row, Col } from 'react-bootstrap';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  SearchableDropdownComponent,
} from '../../../../components';
import { axiosGetRequest } from '../../../../services/http/axiosCalls';
import { baseEndpoints, timeFrameDropdownCampaignsYTD } from '../../../../constants';

import '../../dashboard.css';

const partnersColors = [
    '#dc4d00',
    '#fec241',
    '#ff9730',
    '#f56600',
    '#FF8935',
    '#FFA260',
    '#C15000',
    '#973F00',
  ],
  mediaColors = [
    '#016ba7',
    '#96fdfe',
    '#59d6ff',
    '#219ae1',
    '#69FCFC',
    '#CEFFFF',
    '#96FEFE',
    '#3FF3F3',
  ],
  attributionLiftColors = ['#71308d', '#dd85ff'],
  baseData = {
    byPartner: [],
    byMedia: [],
    byAttributionLift: [],
  },
  timeFrameDropdownOptions = [...timeFrameDropdownCampaignsYTD];

export default function CampaignsYTD() {
  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser);

  const [data, setData] = useState(baseData);

  const [isLoading, setIsLoading] = useState(true),
    [isError, setIsError] = useState(false),
    [cancelController, setCancelController] = useState(null);

  const [numberOfDays, setNumberOfDays] = useState(timeFrameDropdownOptions[5]);
  const [isClicked, setIsClicked ] = useState(false);
  const [disableApplyButton, setDisableApplyButton ] = useState(true);//state update for apply button conditionaly (enable, disable)
  const [disableResetButton, setDisableResetButton ] = useState(true);//state update for reset button conditionaly (enable, disable)
  const [lastSelectedView, setLastSelectedView] = useState(null);//state update to store previous selected value 
  const [currentSelectedView, setCurrentSelectedView] = useState(timeFrameDropdownOptions[5]);//state update to maintain / get current selected value
  const [last2UpdatedViews, setLast2UpdatedViews] = useState([timeFrameDropdownOptions[5], timeFrameDropdownOptions[5]]);

  const getCampaignsYTDData = async (controller, numberOfDays) => {
    setIsError(false);
    setIsLoading(true);

    setLast2UpdatedViews([last2UpdatedViews[1], numberOfDays])

    try {
      const response = await axiosGetRequest(
          `${baseEndpoints.campaigns}/ytd`,
          controller.signal,
          {
            user_id: client.selectedClient,
            advertiser_id: advertiser.selectedAdvertiser,
            dateRangeOption: numberOfDays?.id
          }
        ),
        data = response.data;

      if (response.status === 200) {
        const tmp = {};

        if (!data?.byPartner?.length) {
          tmp.byPartner = [];
        } else {
          const byPartner = data.byPartner.map((item) => {
            return { name: item.name, value: parseInt(item.campaignsCount) };
          });
          tmp.byPartner = byPartner.slice(0, 7);

          const countByPartnerOthers = byPartner.reduce(
            (accumulator, currentItem) => accumulator + parseInt(currentItem.value),
            0
          );
          // If there are more than 7 Partners in response object
          if(byPartner.length > 7){
            tmp.byPartner.push({ name: 'Others', value: countByPartnerOthers });
          }
        }

        if (!data?.byMedia?.length) {
          tmp.byMedia = [];
        } else {
          const byMedia = data.byMedia.map((item) => {
            return { name: item.type, value: parseInt(item.campaignsCount) };
          });
          tmp.byMedia = byMedia.slice(0, 7);

          const countByMediaOthers = byMedia.reduce(
            (accumulator, currentItem) => accumulator + parseInt(currentItem.value),
            0
          );
          // If there are more than 7 Media Type in response object
          if(byMedia.length > 7){
            tmp.byMedia.push({ name: 'Others', value: countByMediaOthers });
          }
        }

        const byAttributionLift = data.byAttributionLift
          .filter((item) => [1, 2].indexOf(item.attributionFlag) > -1)
          .map((item) => {
            switch (item.attributionFlag) {
              case 1:
                return { name: 'Attribution', value: parseInt(item.campaignsCount) };
              case 2:
                return {
                  name: 'Attribution + Lift',
                  value: parseInt(item.campaignsCount),
                };
            }
          });
        tmp.byAttributionLift = byAttributionLift;

        setData(tmp);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        setIsLoading(false);
      }
    }
  };

  const updateGraphData = () => {
    setDisableResetButton(false);//enable reset button once clicked on Apply button
    setDisableApplyButton(true);//disable apply button once clicked on Apply button
    setIsClicked(!isClicked);
  }
  
  const resetGraphData = () => {
    setNumberOfDays(lastSelectedView);//set number of days value to previously selected value ( last selected option)
    setCurrentSelectedView(lastSelectedView); //set current selected value
    setDisableApplyButton(true); //disable apply button once clicked on reset button 
    setDisableResetButton(true); //disable reset button once clicked on reset button 
    setIsClicked(!isClicked)
  }

  const handleNumberOfDaysChange = (option) => {
    if (currentSelectedView.id !== option.id) {
      setNumberOfDays(option);
      setDisableApplyButton(false); //enable apply button once the drop down values change
      setCurrentSelectedView(option); //set current selected value
    }
  };

  useEffect(() => {
    setLastSelectedView(last2UpdatedViews[0]); //update last selected value as current selected value
  }, [last2UpdatedViews])
  

  useEffect(() => {
    if (advertiser.selectedAdvertiser >= 0) {
      const controller = new AbortController();

      setCancelController(controller);
      getCampaignsYTDData(controller, numberOfDays);
      return () => {
        controller.abort();
      };
    } else {
      setData(baseData);
    }
  }, [advertiser, isClicked]);

  return (
    <Row>
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={12}
      >
        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <div className='campaignsHeaderWrapper'>
              <CommonHeadingComponent
                headingLabel='Campaigns'
                url={null}
                isDisabled={false}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
          >
            <div className='analysisSectionInnerSubHeaderWrapper dashboardCampaignYTDFilterWrapper' style={{display:'flex', flexDirection:'row', justifyContent:'center', paddingTop:'2em' }}>
              <SearchableDropdownComponent
                options={timeFrameDropdownOptions}
                label='name'
                id='id'
                dropdownId='viewNumberOfDays'
                dropdownLabel='View'
                labelWidth={{ width: '30px' }}
                dropdownWidth={{
                  width: '320px'
                }}
                placeholderLabel={'Number of Days'}
                selectedValue={numberOfDays}
                handleChange={handleNumberOfDaysChange} //added a function handlenumberofdayschange based on change of dropdown
                isSearchable={false}
                uniqueIdentifier='reportDetailsMediaDeliveryAnalysisDateRange'
              />
              
              <CommonButtonComponent
                label='Apply'
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnPrimary hoverEffect'
                onClickCTA={updateGraphData}
                isDisabled={disableApplyButton} //state for enable / disable apply button 
                buttonUI='dashboardCampaignYTDFilterApply'
              />
              <CommonButtonComponent
                label='Reset'
                appendWrapperClassName='btnPrimaryWrapper buttonLeftPadding'
                appendButtonClassName='btnPrimary hoverEffect'
                onClickCTA={resetGraphData}
                isDisabled={disableResetButton} //state for enable / disable reset button 
                buttonUI='dashboardCampaignYTDFilterReset'
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div className='ytdWrapper'>
            <RenderGraphs
              isLoading={isLoading}
              isError={isError}
              data={data}
              dataFunction={getCampaignsYTDData}
              controller={cancelController}
            />
          </div>
        </Row>
      </Col>
    </Row>
  );
}

const RenderGraphs = ({
  isLoading,
  isError,
  data,
  dataFunction,
  controller,
}) => {
  if (isLoading) {
    return <LoaderComponent headerClass='ytdLoaderHeader' />;
  }

  if (isError) {
    return (
      <ErrorComponent refreshAction={dataFunction} controller={controller} />
    );
  }

  return (
    <Container
      className='widget_container'
      fluid
      style={{ height: 'fit-content' }}
    >
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Col className='widget_content' sm={4}>
              <CommonDonutChart
                data={data.byPartner}
                colors={partnersColors}
                label='Impressions by Partner'
              />
            </Col>
            <Col className='widget_content' sm={4}>
              <CommonDonutChart
                data={data.byMedia}
                colors={mediaColors}
                label='Impressions by Media Type'
              />
            </Col>
            <Col className='widget_content' sm={4}>
              <CommonDonutChart
                data={data.byAttributionLift}
                colors={attributionLiftColors}
                label='Attribution vs Attribution + Lift (Active Campaigns)'
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
