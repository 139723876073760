export const timeFrameDropdown = [
    { id: 9, name: 'Campaign Dates + Lookback Period' },
    { id: 8, name: 'Campaign Dates' },
    { id: 5, name: 'Last 12 Months' },
    { id: 4, name: 'Last 6 Months' },
    { id: 6, name: 'Last Quarter' },
    { id: 3, name: 'Last 90 Days' },
    { id: 2, name: 'Last 60 Days' },
    { id: 1, name: 'Last 30 Days' },
    { id: 7, name: 'Last 7 Days' },
  ],
  timeFrameDropdownCampaignsYTD = [
    { id: 6, name: 'Last 7 Days' },
    { id: 1, name: 'Last Month' },
    { id: 2, name: 'Last Three Months' },
    { id: 5, name: 'Last Quarter' },
    { id: 3, name: 'Last Six Months' },
    { id: 4, name: 'Last Year' },
    { id: 7, name: 'Year To Date' },
  ],
  timeFrameDropdownImpressionsDashboard = [
    { id: 6, name: 'Last 7 Days', dateRange: [null, null] },
    { id: 1, name: 'Last Month', dateRange: [null, null] },
    { id: 2, name: 'Last Three Months', dateRange: [null, null] },
    { id: 5, name: 'Last Quarter', dateRange: [null, null] },
    { id: 3, name: 'Last Six Months', dateRange: [null, null] },
    { id: 4, name: 'Last Year', dateRange: [null, null] },
    { id: 7, name: 'Year To Date', dateRange: [null, null] },
    { id: 8, name: 'Lifetime', dateRange: [null, null] },
    { id: 0, name: 'Custom Date Range', dateRange: [null, null] },
  ],
  dataTypeDropdown = [
    { id: 1, name: 'Placement' },
    { id: 2, name: 'Partner' },
    { id: 3, name: 'Creative Type' },
  ],
  performanceDropdown = [
    { id: 1, name: 'Partner', bottomLabel: 'Media Partner' },
    { id: 2, name: 'Placement', bottomLabel: 'Placement' }
  ],
  performanceOrderByDropdownEngagement = [
    { id: 1, name: 'Impressions' },
    { id: 2, name: 'Engagement' }
  ],
  performanceOrderByDropdownConversion = [
    { id: 1, name: 'Impressions' },
    { id: 2, name: 'Conversion' }
  ],
  performanceSortingDropdown = [
    { id: 1, name: 'Ascending' },
    { id: 2, name: 'Descending' }
  ],
  presentTimeFrameList = [
    {
      id: 19,
      name: 'Campaign Dates',
      isDisabled: false,
      dateRange: [null, null],
    },
    {
      id: 20,
      name: 'Campaign Dates + Lookback Period',
      isDisabled: false,
      dateRange: [null, null],
    },
    {id: 8, name: 'Yesterday', isDisabled: false, dateRange: [null, null]},
    { id: 12, name: 'Last 7 Days', isDisabled: false, dateRange: [null, null] },
    {
      id: 13,
      name: 'Last One Month',
      isDisabled: false,
      dateRange: [null, null],
    },
    {
      id: 18,
      name: 'Last Three Months',
      isDisabled: false,
      dateRange: [null, null],
    },
    {
      id: 14,
      name: 'Last One Quarter',
      isDisabled: false,
      dateRange: [null, null],
    },
    {
      id: 15,
      name: 'Last Six Months',
      isDisabled: false,
      dateRange: [null, null],
    },
    {
      id: 16,
      name: 'Last One Year',
      isDisabled: false,
      dateRange: [null, null],
    },
    {
      id: 17,
      name: 'Last Two Years',
      isDisabled: false,
      dateRange: [null, null],
    },
    {
      id: 0,
      name: 'Custom Date Range',
      isDisabled: false,
      dateRange: [null, null],
    },
  ],
  runTimeFrameList = [
    {
      id: 1,
      name: 'Campaign End Date',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterEndDate: false,
      },
      dateRange: [null, null],
    },
    {
      id: 2,
      name: 'Campaign End Date + Lookback Period',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterEndDate: false,
      },
      dateRange: [null, null],
    },
    {
      id: 3,
      name: 'Campaign End Date + Lookback + Lift Period',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterEndDate: false,
      },
      dateRange: [null, null],
    },
    { 
      id: 4, 
      name: 'First 7 Days', 
      isDisabled: false, 
      showWarning: false, 
      warningType: {
        beforeCurrentDate: false,
        startDateAfterCampaignDates: false,
        endDateAfterCampaignDates: false,
      },
      dateRange: [null, null] 
    },
    {
      id: 5,
      name: 'First Month',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterCampaignDates: false,
        endDateAfterCampaignDates: false,
      },
      dateRange: [null, null],
    },
    {
      id: 6,
      name: 'First Three Months',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterCampaignDates: false,
        endDateAfterCampaignDates: false,
      },
      dateRange: [null, null],
    },
    {
      id: 7,
      name: 'First Quarter',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterCampaignDates: false,
        endDateAfterCampaignDates: false,
      },
      dateRange: [null, null],
    },
    {
      id: 8,
      name: 'First Six Months',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterCampaignDates: false,
        endDateAfterCampaignDates: false,
      },
      dateRange: [null, null],
    },
    {
      id: 9,
      name: 'First Year',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterCampaignDates: false,
        endDateAfterCampaignDates: false,
      },
      dateRange: [null, null],
    },
    {
      id: 10,
      name: 'First Two Years',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterCampaignDates: false,
        endDateAfterCampaignDates: false,
      },
      dateRange: [null, null],
    },
    {
      id: 0,
      name: 'Custom Run Date Range',
      isDisabled: false,
      showWarning: false,
      warningType: {
        beforeCurrentDate: false,
        startDateAfterCampaignDates: false,
        endDateAfterCampaignDates: false,
      },
      dateRange: [null, null],
    },

  ],
   legacyOnlyTimeFrames = [
    {id: 8, name: 'Yesterday', isDisabled: false, dateRange: [null, null]},
    {id: 2, name: 'Last Week (Mon-Sun)', isDisabled: false, dateRange: [null, null]},
    {id: 3, name: 'This Month', isDisabled: false, dateRange: [null, null]},
    {id: 4, name: 'Last Month', isDisabled: false, dateRange: [null, null]},
    {id: 7, name: 'Campaign to Date', isDisabled: false, dateRange: [null, null]},
  ],
  statusOptionList = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Inactive' },
  ],
  reporttypeOptionList = [
    { id: 0, name: 'Attribution', isDisabled: false },
    { id: 1, name: 'Attribution + Lift', isDisabled: false },
    { id: 4, name: 'Attribution Per Day', isDisabled: false },
    { id: 2, name: 'Respondent Data', isDisabled: false },
    { id: 3, name: 'Media Delivery', isDisabled: false },
    { id: 5, name: 'Reach and Frequency', isDisabled: false },
  ],
  frequencyOptionsList = [
    { key: 1, value: 'On Demand', name: 'On Demand'},
    { key: 2, value: 'Daily', name: 'Daily' },
    { key: 6, value: 'Weekly', name: 'Every Business Day' },
    { key: 6, value: 'Weekly', name: 'Weekly' },
    { key: 7, value: 'Bi-Weekly', name: 'Bi-Weekly' },
    { key: 4, value: 'Monthly', name: 'Monthly' },
  ],
  frequencyDayOptionList = [
    {
      key: 'Sunday',
      label: 'S',
      value: 7,
    },
    {
      key: 'Monday',
      label: 'M',
      value: 1,
    },
    {
      key: 'Tuesday',
      label: 'T',
      value: 2,
    },
    {
      key: 'Wednesday',
      label: 'W',
      value: 3,
    },
    {
      key: 'Thursday',
      label: 'T',
      value: 4,
    },
    {
      key: 'Friday',
      label: 'F',
      value: 5,
    },
    {
      key: 'Saturday',
      label: 'S',
      value: 6,
    },
  ],
  frequencyIntervalOptionList = [
    { key: 1, value: 'First' },
    { key: 2, value: 'Second' },
    { key: 3, value: 'Third' },
    { key: 4, value: 'Fourth' },
    { key: 5, value: 'Last' },
  ],
  camapignOptionList = [
    { id: 0, name: 'All Campaigns' },
    { id: 1, name: 'Active Campaigns' },
  ],
  dedupeCustomVarOptions = [
    { id: 1, label: 'custom_var_1', version: [0] },
    { id: 2, label: 'custom_var_2', version: [0] },
    { id: 3, label: 'custom_var_3', version: [0] },
    { id: 4, label: 'custom_var_4', version: [0] },
    { id: 5, label: 'conv_custom_var_1', version: [1] },
    { id: 6, label: 'conv_custom_var_2', version: [1] },
    { id: 7, label: 'conv_custom_var_3', version: [1] },
    { id: 8, label: 'conv_custom_var_4', version: [1] },
    { id: 9, label: 'imp_click_custom_var_1', version: [0, 1] },
    { id: 10, label: 'imp_click_custom_var_2', version: [0, 1] },
    { id: 11, label: 'imp_click_custom_var_3', version: [0, 1] },
    { id: 12, label: 'imp_click_custom_var_4', version: [0, 1] },
  ],
  outputFormatOptions = [
    { value: 0, label: 'Compressed (.gz)' },
    { value: 1, label: 'Uncompressed (.csv)' },
  ],
  piiMaskOptions = [
    { value: true, label: 'No' },
    { value: false, label: 'Yes' },
  ],
  yesNoOptions = [
    { value: 0, label: 'No' },
    { value: 1, label: 'Yes' },
  ],
  TimeFrameOptions = [
    { value: '8', label: 'Yesterday' },
    { value: '5', label: 'Last 7 days' },
    { value: '2', label: 'Last week (Mon-Sun)' },
    { value: '6', label: 'Last 30 days' },
    { value: '3', label: 'This month' },
    { value: '4', label: 'Last month' },
    { value: '7', label: 'Campaign to date' },
    { value: '9', label: 'Campaign Dates without Post Period' },
    { value: '13', label: 'Campaign Dates with Post Period' },
    { value: '14', label: 'Custom Date Range' },
  ],
  DailyTimeFrameOptions = [
    { value: '8', label: 'Yesterday' },
    { value: '5', label: 'Last 7 days' },
    { value: '6', label: 'Last 30 days' },
    { value: '3', label: 'This month' },
    { value: '7', label: 'Campaign to date' },
    { value: '9', label: 'Campaign Dates without Post Period' },
    { value: '13', label: 'Campaign Dates with Post Period' },
  ],
  WeeklyMonToSunTimeFrameOptions = [
    { value: '5', label: 'Last 7 days' },
    { value: '2', label: 'Last week (Mon-Sun)' },
    { value: '6', label: 'Last 30 days' },
    { value: '3', label: 'This month' },
    { value: '7', label: 'Campaign to date' },
    { value: '9', label: 'Campaign Dates without Post Period' },
    { value: '13', label: 'Campaign Dates with Post Period' },
  ],
  MonthlyTimeFrameOptions = [
    { value: '4', label: 'Last month' },
    { value: '7', label: 'Campaign to date' },
    { value: '9', label: 'Campaign Dates without Post Period' },
    { value: '13', label: 'Campaign Dates with Post Period' },
  ],
  RespondentDataReportFrequencyOptions = [
    { key: '1', value: 'On Demand', name: 'On Demand' },
    { key: '2', value: 'Daily', name: 'Daily' },
    { key: '3', value: 'Weekly (Mon-Sun)', name: 'Weekly (Mon-Sun)' },
    { key: '4', value: 'Monthly', name: 'Monthly' },
  ];

