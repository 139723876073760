import React, { useState } from 'react';
import { baseEndpoints } from '../../../../../constants';
import AnalysisComponent from './analysisSection/Analysis.component';
import DistributionSection from './distributionSection/DistributionSection.component';
import ImpressionsReport from './impressionSection/ImpressionsReport.component';
import SummaryHeader from '../../summaryHeader/SummaryHeader.component';

function MediaDeliveryComponent({
  campaign,
  setCampaign,
  hasSetCampaign,
  properStartDateString,
  properLookbackDateString,
  setProperLookbackDateString,
  setProperStartDateString,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  haveSetDates,
  setHaveSetDates,
  placement,
  setPlacement,
  defaultPlacement,
  setDefaultPlacement,
  mediaPartner,
  setMediaPartner,
  defaultMediaPartner,
  setDefaultMediaPartner,
  creativeType,
  setCreativeType,
  defaultCreativeType,
  setDefaultCreativeType,
  isDisabled,
  setIsDisabled,
  getReportsDetails,
  isErrorReportDetails,
  isErrorCampaignList,
  isErrorPlacementList,
  getCampaignList,
  getPlacementList,
  campaignList,
  placementList,
  setPlacementList,
  mediaPartnerList,
  setMediaPartnerList,
  creativeTypeList,
  setCreativeTypeList,
  isLoading,
  dateRange,
  setDateRange,
  summaryData,
  setSummaryData,
  ifSelectedAll,
  updateIsLoading,
  getPartnerList,
  getCreativeTypeList,
  isErrorPartnerList,
  isErrorCreativeTypeList,
  presentTimeFrameOptions,
  timeFrame,
  setTimeFrame,
}) {
  const endpoint = `${baseEndpoints.reports}/media-delivery-tab`,
    labels = ['Gross Impressions', 'IVT', 'Non US Traffic', 'Net Impressions'];
  
  const [noData, setNoData] = useState(false)

  return (
    <>
      <SummaryHeader
        campaign={campaign}
        setCampaign={setCampaign}
        properStartDateString={properStartDateString}
        properLookbackDateString={properLookbackDateString}
        setProperLookbackDateString={setProperLookbackDateString}
        setProperStartDateString={setProperStartDateString}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setHaveSetDates={setHaveSetDates}
        placement={placement}
        setPlacement={setPlacement}
        defaultPlacement={defaultPlacement}
        setDefaultPlacement={setDefaultPlacement}
        mediaPartner={mediaPartner}
        setMediaPartner={setMediaPartner}
        defaultMediaPartner={defaultMediaPartner}
        setDefaultMediaPartner={setDefaultMediaPartner}
        creativeType={creativeType}
        setCreativeType={setCreativeType}
        defaultCreativeType={defaultCreativeType}
        setDefaultCreativeType={setDefaultCreativeType}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        getReportsDetails={getReportsDetails}
        isErrorReportDetails={isErrorReportDetails}
        isErrorCampaignList={isErrorCampaignList}
        isErrorPlacementList={isErrorPlacementList}
        getCampaignList={getCampaignList}
        getPlacementList={getPlacementList}
        campaignList={campaignList}
        placementList={placementList}
        setPlacementList={setPlacementList}
        mediaPartnerList={mediaPartnerList}
        setMediaPartnerList={setMediaPartnerList}
        creativeTypeList={creativeTypeList}
        setCreativeTypeList={setCreativeTypeList}
        isLoading={isLoading}
        dateRange={dateRange}
        setDateRange={setDateRange}
        labels={labels}
        endpoint={endpoint}
        summaryData={summaryData}
        setSummaryData={setSummaryData}
        ifSelectedAll={ifSelectedAll}
        updateIsLoading={updateIsLoading}
        getPartnerList={getPartnerList}
        getCreativeTypeList={getCreativeTypeList}
        isErrorPartnerList={isErrorPartnerList}
        isErrorCreativeTypeList={isErrorCreativeTypeList}
        presentTimeFrameOptions={presentTimeFrameOptions}
        timeFrame={timeFrame}
        setTimeFrame={setTimeFrame}
        isFromMediaDelivery
        setNoData={setNoData}
      />
      <DistributionSection
        campaign={campaign}
        reportComponentIsLoading={isLoading}
        startDate={startDate}
        endDate={endDate}
        haveSetDates={haveSetDates}
        isSummaryError={
          isErrorCampaignList ||
          isErrorReportDetails ||
          isErrorPartnerList ||
          isErrorCreativeTypeList
        }
        placement={placement}
      />
      <ImpressionsReport
        campaign={campaign}
        isLoading={isLoading}
        startDate={startDate}
        endDate={endDate}
        haveSetDates={haveSetDates}
        placement={placement}
        noData={noData}
      />
      <AnalysisComponent
        campaign={campaign}
        reportComponentIsLoading={isLoading}
        startDate={startDate}
        endDate={endDate}
        haveSetDates={haveSetDates}
        placement={placement}
        placementList={placementList}
        creativeType={creativeType}
        mediaPartner={mediaPartner}
        mediaPartnerList={mediaPartnerList}
        isSummaryError={
          isErrorCampaignList ||
          isErrorReportDetails ||
          isErrorPartnerList ||
          isErrorCreativeTypeList
        }
        ifSelectedAll={ifSelectedAll}
        isLoading={isLoading}
        getCampaignList={getCampaignList}
        getPlacementList={getPlacementList}
        getPartnerList={getPartnerList}
        getCreativeTypeList={getCreativeTypeList}
        noData={noData}
      />
    </>
  );
}

export default MediaDeliveryComponent;
