import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  DatePickerComponent,
  ErrorComponent,
  CommonModal,
  SearchableDropdownComponent,
  MultiselectDropdownComponent,
} from '../../../../components';
import { Container, Row, Col } from 'react-bootstrap';
import {
  checkSelectAll,
  dataHandlingForLS,
  getArrayOfData,
  getCurrentESTDate,
  setPlaceHolderLabel,
  getStartAndEndDate,
  checkCondition,
  daysToFormatWRTDates,
  getNotification,
  removeNotification,
  setAdvertiserId
} from '../../../../utils/functions';
import {
  axiosGetRequest,
  axiosPostRequest,
  axiosSendRequest,
} from '../../../../services/http/axiosCalls';
import { baseEndpoints, presentTimeFrameList } from '../../../../constants';
import ScheduledReportModal from '../ScheduledReportModal';
import CreateReportModalBody from './CreateReportModalBody';
import { setBlockingLoaderState } from '../../../../redux/blockingLoader';

import './createReport.css';
import { clearNotification } from '../../../../utils/importErrorNotification';

const presentTimeFrameOptions = [...presentTimeFrameList],
  nullImpFreqWarn =
    'There are 0 impressions during this time for this Campaign.',
  defaultDateFormat = 'YYYY-MM-DD';

const CreateReportComponent = ({ updateLatestReports, campaignId }) => {
  const currentDate = getCurrentESTDate(defaultDateFormat);
  const reportNameMaxLength = 200;

  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser),
    userInfo = useSelector((state) => state.user.uid),
    role = useSelector((state) => state.user.role);
  const dispatch = useDispatch();

  const selectedClientObject = client.clientList.find(c => c.user_id === client.selectedClient);
  const selectedClientOrganization = selectedClientObject ? selectedClientObject.organization : '';

  const placementListRef = useRef(null),
    placementRef = useRef(null),
    mediaPartnerListRef = useRef(null),
    mediaPartnerRef = useRef(null),
    creativeTypeListRef = useRef(null),
    creativeTypeRef = useRef(null),
    originalPlacement = useRef(null),
    originalCreativeType = useRef(null);

  const [campaignList, setCampaignList] = useState(null),
    [campaign, setCampaign] = useState(null);

  const [mediaPartnerList, setMediaPartnerList] = useState(null),
    [mediaPartner, setMediaPartner] = useState(null);

  const [placementList, setPlacementList] = useState(null),
    [placement, setPlacement] = useState(null);

  const [creativeTypeList, setCreativeTypeList] = useState(null),
    [creativeType, setCreativeType] = useState(null);

  const [timeFrame, setTimeFrame] = useState(null),
    [properStartDateString, setProperStartDateString] = useState(null),
    [properLookbackDateString, setProperLookbackDateString] = useState(null),
    [startDate, setStartDate] = useState(null),
    [endDate, setEndDate] = useState(null);

  const [reportName, setReportName] = useState(null),
    [lastSavedReportData, setLastSavedReportData] = useState(null),
    [showScheduledReportDialog, setShowScheduledReportDialog] = useState(false),
    [ifOpenModal, setIfOpenModal] = useState(false),
    [showRunAndSave, setShowRunAndSave] = useState(false);

  const [cancelController, setCancelController] = useState(null),
    [isError, setIsError] = useState(false),
    [isSavingError, setIsSavingError] = useState(false),
    [errorMessage, setErrorMessage] = useState(null),
    [showErrorModal, setShowErrorModal] = useState(false),
    [isDisabled, setIsDisabled] = useState({
      campaign: true,
      mediaPartner: true,
      placement: true,
      creativeType: true,
    }),
    [isLoading, setIsLoading] = useState({
      campaign: true,
      startDate: false,
      endDate: false,
      mediaPartner: false,
      placement: false,
      creativeType: false,
    });

  const [showPartnerDropdown, setShowPartnerDropdown] = useState(false),
    [showCreativeTypeDropdown, setShowCreativeTypeDropdown] = useState(false),
    [showPlacementDropdown, setShowPlacementDropdown] = useState(false);

  const advertiserId = setAdvertiserId(advertiser.selectedAdvertiser, advertiser.selectedClientsAdvertiser, role);
  const getCampaignList = async (controller) => {
    setIsLoading({ ...isLoading, campaign: true });
    setIsError(false);
    setIsDisabled({
      campaign: true,
      mediaPartner: true,
      placement: true,
      creativeType: true,
    });
    setMediaPartner(null);
    setMediaPartnerList(null);
    setCreativeType(null);
    setCreativeTypeList(null);
    setPlacement(null);
    setPlacementList(null);

    try {
      const campaignListPromise = axiosGetRequest(
          `${baseEndpoints.reports}/campaign-list`,
          controller.signal,
          {
            timezone: null,
            user_id:
              client.selectedClient === -1 ? null : client.selectedClient,
            advertiser_id: advertiserId,
          }
        ),
        campaignListResponse = await campaignListPromise;

      if (campaignListResponse.status === 200) {
        setCampaignList(campaignListResponse.data);
        setIsDisabled({
          ...isDisabled,
          campaign: campaignListResponse.data.length <= 0,
          mediaPartner: true,
          placement: true,
          creativeType: true,
        });
      } else {
        setIsError(true);
      }
      applySetIsLoadingToAll(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsError(true);
        applySetIsLoadingToAll(false);
      }
    }
  };

  const getPartnerList = async (controller) => {
    setIsError(false);
    setIsLoading({
      ...isLoading,
      mediaPartner: true,
      creativeType: true,
      placement: true,
    });
    try {
      const partnerListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/partner-list`,
        controller.signal,
        {
          campaign_id: campaignId ? campaignId : campaign.campaign_id,
        }
      );
      if (partnerListResponse.status === 200) {
        const dataListPartner =
          partnerListResponse.data.length > 0 ? partnerListResponse.data : null;
        setMediaPartner(dataListPartner);
        setMediaPartnerList(dataListPartner);
        setIsDisabled({
          ...isDisabled,
          mediaPartner: partnerListResponse.data.length <= 0,
          creativeType: true,
          placement: true,
        });
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsError(true);
        applySetIsLoadingToAll(false);
      }
    } finally {
      setIsLoading({
        ...isLoading,
        mediaPartner: false,
        creativeType: false,
        placement: false,
      });
    }
  };

  const getCreativeTypeList = async (controller, originalCreativeType, originalCreativeTypeList) => {
    setIsError(false);
    setIsLoading({
      ...isLoading,
      creativeType: true,
      placement: true,
    });

    try {
      const creativeTypeListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/creative-type-list`,
        controller.signal,
        {
          campaign_id: campaignId ? campaignId : campaign.campaign_id,
          partner_id: mediaPartner
            ? getArrayOfData(mediaPartner, 'partner_id')
            : null,
        }
      );
      if (creativeTypeListResponse.status === 200) {
        const dataListCreativeType =
          creativeTypeListResponse.data.length > 0
            ? creativeTypeListResponse.data
            : null;

        if (originalCreativeType?.length > 0 && originalCreativeTypeList?.length > 0) {
          const creativeTypesToSet = [];
          const creativeTypeNames = originalCreativeType.map((item) => item.name);
          const originalListNames = originalCreativeTypeList.map((item) => item.name);
          dataListCreativeType.forEach((item) => {
            if (creativeTypeNames.includes(item.name) || !originalListNames.includes(item.name)) {
              creativeTypesToSet.push(item);
            }
          });
          if (creativeTypesToSet.length === 0) {
            setCreativeType(dataListCreativeType);
          } else {
            setCreativeType(creativeTypesToSet);
          }
        } else {
          setCreativeType(dataListCreativeType);
        }

        setCreativeTypeList(dataListCreativeType);

        setIsDisabled({
          ...isDisabled,
          creativeType: creativeTypeListResponse.data.length <= 0,
          placement: true,
        });
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsError(true);
        applySetIsLoadingToAll(false);
      }
    } finally {
      setIsLoading({
        ...isLoading,
        creativeType: false,
        placement: false,
      });
    }
  };

  const getPlacementList = async (controller, previousPlacement, previousPlacementList) => {
    setIsError(false);
    setIsLoading({ ...isLoading, placement: true });
    try {
      const placementListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/placement-list`,
        controller.signal,
        {
          campaign_id: campaignId ? campaignId : campaign.campaign_id,
          partner_id: mediaPartner
            ? getArrayOfData(mediaPartner, 'partner_id')
            : null,
          creative_type: creativeType
            ? getArrayOfData(creativeType, 'name')
            : null,
        }
      );

      if (placementListResponse.status === 200) {
        const dataListPlacement =
          placementListResponse.data.length > 0
            ? placementListResponse.data[0]
            : null;
        setPlacementList(dataListPlacement);
        if (previousPlacement?.length > 0 && previousPlacementList?.length > 0) {
          const placementsToSet = [];
          const placementIds = previousPlacement.map((item) => item.placement_id);
          const originalListIds = previousPlacementList.map((item) => item.placement_id);
          dataListPlacement.forEach((item) => {
            if (placementIds.includes(item.placement_id) || !originalListIds.includes(item.placement_id)) {
              placementsToSet.push(item);
            }
          });
          if (placementsToSet.length === 0) {
            setPlacement(dataListPlacement);
          } else {
            setPlacement(placementsToSet);
          }
        } else {
          setPlacement(dataListPlacement);
        }

        setIsDisabled({
          ...isDisabled,
          placement: placementListResponse.data.length <= 0,
        });
      } else {
        setIsError(true);
      }
      setIsLoading({ ...isLoading, placement: false });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err);
        setIsError(true);
        applySetIsLoadingToAll(false);
      }
    }
  };

  const applySetIsLoadingToAll = (type) => {
    setIsLoading({
      ...isLoading,
      campaign: type,
      startDate: type,
      endDate: type,
      mediaPartner: type,
      placement: type,
      creativeType: type,
    });
  };

  const resetAll = () => {
    setCampaign(null);
    setTimeFrame(null);
    setProperLookbackDateString(null);
    setProperStartDateString(null);
    setPlacement(null);
    setMediaPartner(null);
    setPlacementList(null);
    setMediaPartnerList(null);
    setCreativeType(null);
    setCreativeTypeList(null);
    setStartDate(null);
    setEndDate(null);
    setReportName(null);
    setIsDisabled({
      ...isDisabled,
      mediaPartner: true,
      placement: true,
      creativeType: true,
    });
  };

  const closeCreateReportModal = () => {
    setLastSavedReportData(null);
    ifOpenModal && setIfOpenModal(false);
    isSavingError && setIsSavingError(false);
    showErrorModal && setShowErrorModal(false);
    setReportName(null);
    removeNotification();
  };

  const onModalSave = async (reportParam) => {
    isSavingError && setIsSavingError(false);
    errorMessage && setErrorMessage(null);
    try {
      const saveReport = await axiosPostRequest(
        baseEndpoints.reports,
        reportParam
      );

      if (saveReport.status === 200) {
        window.open(
          `/reports/${saveReport.data.report_id}/?report_name=${encodeURIComponent(reportName)}&report_type=On Demand`,
          '_blank',
          'noreferrer'
        );
        setLastSavedReportData(null);
        updateLatestReports();
      } else {
        setIsSavingError(true);
      }
      resetAll();
      setIfOpenModal(false);
    } catch (err) {
      setErrorMessage(err);
      setIsSavingError(true);
    } finally {
      dispatch(setBlockingLoaderState({ active: false }));
    }
  };

  const onModalRun = () => {
    
    if (reportName?.length > reportNameMaxLength) {
      getNotification(
        'danger',
        `Report Name Should Not Exceed ${reportNameMaxLength} Characters.`
      );
      return false;
    }

    const endDateObject = moment(endDate);
    const startDateObject = moment(startDate);
    if(endDateObject.isBefore(startDateObject)){
      getNotification(
        'danger',
        'End Date cannot be before Start Date.'
      )
      return false;
    }

    const timestamp = new Date().getTime(),
      paramObj = {
        user_id: client.selectedClient,
        advertiser_id: advertiser.selectedAdvertiser,
        campaign_details: campaign,
        placement_details: placement,
        partner_details: mediaPartner,
        creative_type_details: creativeType,
        report_name: reportName,
        time_frame: timeFrame?.id,
        start_date: timeFrame?.id === 0 ? startDate : null,
        end_date: timeFrame?.id === 0 ? endDate : null,
      };

    dataHandlingForLS({
      type: 'allReportsAddOrCreate',
      nameAll: 'AllReports',
      nameReport: `report_${timestamp}`,
      timestamp,
    });

    resetAll();

    dataHandlingForLS({
      type: 'saveAReport',
      nameReport: `report_${timestamp}`,
      reportData: paramObj,
    });

    window.open(
      `/reports/run/?rd=report_${timestamp}${
        reportName ? '&report_name=' + reportName : ''
      }`,
      '_blank',
      'noreferrer'
    );
  };

  const onModalTriggeredRun = () => {
    const result = onModalRun();
    if (result !== false) {
      resetAll();
      setIfOpenModal(false);
    }
  };

  const onModalTriggeredRunAndSave = () => {
    dispatch(setBlockingLoaderState({ active: true }));

    const isAllPlacementSelected =  checkSelectAll(
      placementList,
      placement,
      'placement_id'
    );
    const isAllPartnerSelected =  checkSelectAll(
      mediaPartnerList,
      mediaPartner,
      'partner_id'
    );

    const startDateObject = moment(startDate);
    const endDateObject = moment(endDate);
    if(timeFrame?.id === 0 && endDateObject.isBefore(startDateObject)){
      getNotification(
        'danger',
        'End Date cannot be before Start Date.'
      )
      dispatch(setBlockingLoaderState({ active: false }));
      return false;
    }

    const lastSavedData = {
      user_id: client.selectedClient,
      advertiser_id: campaign?.advertiser_id,
      campaign_id: campaign?.campaign_id ?? null,
      placement_id: isAllPlacementSelected ? null : getArrayOfData(placement, 'placement_id'),
      partner_id: isAllPartnerSelected ? null : getArrayOfData(mediaPartner, 'partner_id'),
      creative_type: getArrayOfData(creativeType, 'name'),
      report_name: reportName,
      time_frame: timeFrame?.id,
      start_date: timeFrame?.id === 0 ? startDate : null,
      end_date: timeFrame?.id === 0 ? endDate : null,
      uid: userInfo,
    };

    onModalSave(lastSavedData);
    setLastSavedReportData(lastSavedData);
    // setIfOpenModal(false);
  };

  const onClickCreateScheduleReport = () => {
    setShowScheduledReportDialog(true);
  };

  useEffect(() => {
    if (campaignId && campaignList) {
      const item = campaignList.filter(
        (item) => item.campaign_id == campaignId
      );
      setCampaign(item[0]);
      setProperStartDateString(
        moment(
          campaign?.start_date.slice(0, campaign.start_date.length - 1)
        ).format('MM/DD/yyyy')
      );
      setProperLookbackDateString(
        moment(
          campaign?.lookback_date.slice(0, campaign.lookback_date.length - 1)
        ).format('MM/DD/yyyy')
      );
      const campaignEndDate = moment(
        campaign?.end_date.slice(0, campaign?.end_date.length - 1) ??
          currentDate
      ).format(defaultDateFormat);
      presentTimeFrameOptions.forEach((option) => {
        const { newStartDate, newEndDate, isDisabled } = getStartAndEndDate(
          option.id,
          currentDate,
          properStartDateString,
          campaignEndDate,
          properLookbackDateString
        );

        option.dateRange = [newStartDate, newEndDate];
        option.isDisabled = isDisabled;
      });
      setTimeFrame({ ...presentTimeFrameOptions[1] });
    }
  }, [campaign, campaignList, properStartDateString]);

  useEffect(() => {
    resetAll();
    setIsDisabled({
      ...isDisabled,
      campaign: true,
      mediaPartner: true,
      placement: true,
      creativeType: true,
    });
    if (advertiser.selectedAdvertiser !== -1) {
      const controller = new AbortController();
      setCancelController(controller);
      getCampaignList(controller);
      return () => {
        controller.abort();
      };
    } else {
      applySetIsLoadingToAll(true);
      setTimeout(() => {
        setCampaignList(null);
        setMediaPartnerList(null);
        setPlacementList(null);
        setCreativeTypeList(null);
        applySetIsLoadingToAll(false);
      }, 100);
    }
  }, [client, advertiser]);

  useEffect(() => {
    setMediaPartnerList(null);
    setMediaPartner(null);
    setCreativeType(null);
    setCreativeTypeList(null);
    setPlacement(null);
    setPlacementList(null);
    setIsDisabled({
      ...isDisabled,
      mediaPartner: true,
      creativeType: true,
      placement: true,
    });
    if (campaign) {
      const controller = new AbortController();

      setCancelController(controller);
      getPartnerList(controller);
      return () => {
        controller.abort();
      };
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }, [campaign?.campaign_id]);

  useEffect(() => {
    if (campaign?.campaign_id && !campaignId) {
      const campaignStartDate = moment(
          properStartDateString ?? currentDate
        ).format(defaultDateFormat),
        campaignEndDate = moment(
          campaign?.end_date.slice(0, campaign?.end_date.length - 1) ??
            currentDate
        ).format(defaultDateFormat),
        campaignLookbackDate = moment(
          properLookbackDateString ?? currentDate
        ).format(defaultDateFormat);

      presentTimeFrameOptions.map((option) => {
        const { newStartDate, newEndDate, isDisabled } = getStartAndEndDate(
          option.id,
          currentDate,
          campaignStartDate,
          campaignEndDate,
          campaignLookbackDate
        );

        option.dateRange = [newStartDate, newEndDate];
        option.isDisabled = isDisabled;
      });

      setTimeFrame({ ...presentTimeFrameOptions[1] });
    }
  }, [campaignId, campaign?.campaign_id, campaign]);

  useEffect(() => {
    if (creativeType && originalCreativeType.current && JSON.stringify(creativeType) === JSON.stringify(originalCreativeType.current)) {
      originalCreativeType.current = null;
      return;
    }
    if (mediaPartner) {
      const controller = new AbortController();
      const previousCreativeType = Array.isArray(creativeType) ? [...creativeType] : null;
      const previousCreativeTypeList = Array.isArray(creativeTypeList) ? [...creativeTypeList] : null;
      getCreativeTypeList(controller, previousCreativeType, previousCreativeTypeList);
      return () => {
        controller.abort();
      };
    } else {
      setIsLoading({ ...isLoading, creativeType: false, placement: false });
      setCreativeType(null);
      setCreativeTypeList(null);
      setIsDisabled({
        ...isDisabled,
        creativeType: true,
      });
    }
  }, [mediaPartner]);

  useEffect(() => {
    if (placement && originalPlacement.current && JSON.stringify(placement) === JSON.stringify(originalPlacement.current)) {
      originalPlacement.current = null;
      return;
    }
    const previousPlacement = Array.isArray(placement) ? [...placement] : null;
    const previousPlacmentList = Array.isArray(placementList) ? [...placementList] : null;
    setPlacement(null);
    setPlacementList(null);
    setIsDisabled({
      ...isDisabled,
      placement: true,
    });

    if (campaign && mediaPartner && creativeType) {
      const controller = new AbortController();
      setCancelController(controller);
      getPlacementList(controller, previousPlacement, previousPlacmentList);
      return () => {
        controller.abort();
      };
    }
  }, [creativeType]);

  useEffect(() => {
    if (!reportName) {
      setShowRunAndSave(false);
    }
  }, [reportName]);

  useEffect(() => {
    placementListRef.current = placementList ? [...placementList] : null;
  }, [placementList]);

  useEffect(() => {
    mediaPartnerListRef.current = mediaPartnerList
      ? [...mediaPartnerList]
      : null;
  }, [mediaPartnerList]);

  useEffect(() => {
    if (mediaPartner) {
      mediaPartnerRef.current = [...mediaPartner];
    }
  }, [mediaPartner]);

  useEffect(() => {
    if (placement) {
      placementRef.current = [...placement];
    }
  }, [placement]);

  useEffect(() => {
    creativeTypeListRef.current = creativeTypeList
      ? [...creativeTypeList]
      : null;
  }, [creativeTypeList]);

  useEffect(() => {
    if (creativeType) {
      creativeTypeRef.current = [...creativeType];
    }
  }, [creativeType]);

  useEffect(() => {
    if (timeFrame) {
      setStartDate(timeFrame?.dateRange[0]);
      setEndDate(timeFrame?.dateRange[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }, [timeFrame]);

  useEffect(() => {
    if (isSavingError) {
      if (
        errorMessage?.response?.data?.error_type === 'ER_DUP_ENTRY' ||
        errorMessage?.response?.data?.error_type === 'Invalid request'
      ) {
        return getNotification('danger', errorMessage.response.data.error);
      }

      resetAll();
      setIfOpenModal(false);
      setShowErrorModal(true);
    }
  }, [isSavingError]);

  return (
    <div className='createReportOuterWrapper'>
      <CommonHeadingComponent
        headingLabel='Create Report'
        uniqueIdentifier='createScheduledReport'
      />
      <ScheduledReportModal
        show={showScheduledReportDialog}
        open={!showScheduledReportDialog}
        setShowScheduledReportDialog={setShowScheduledReportDialog}
        showScheduledReportDialog={showScheduledReportDialog}
        campaign={campaign}
        advertiserId={advertiser.selectedAdvertiser}
        clientId={client.selectedClient}
        organization={selectedClientOrganization}
        onSaveSuccesss={updateLatestReports}
        userId={userInfo}
        mediaPartner={mediaPartner}
        setMediaPartner={setMediaPartner}
        mediaPartnerList={mediaPartnerList}
        setMediaPartnerList={setMediaPartnerList}
        creativeType={creativeType}
        setCreativeType={setCreativeType}
        creativeTypeList={creativeTypeList}
        setCreativeTypeList={setCreativeTypeList}
        originalPlacement={originalPlacement}
        originalCreativeType={originalCreativeType}
        placement={placement}
        setPlacement={setPlacement}
        placementList={placementList}
        setPlacementList={setPlacementList}
        isLoading={isLoading}
      />
      {Boolean(
        campaign && startDate && endDate && placement && mediaPartner
      ) && (
        <CommonModal
          show={ifOpenModal}
          header={'Create Report'}
          body={
            <CreateReportModalBody
              campaign={campaign?.campaign_name}
              placement={formatNames(
                placementList,
                placement,
                'placement_name'
              )}
              partner={formatNames(
                mediaPartnerList,
                mediaPartner,
                'partner_name'
              )}
              creativeType={formatNames(creativeTypeList, creativeType, 'name')}
              timeFrame={timeFrame}
              startDate={startDate}
              endDate={endDate}
              reportName={reportName}
              setReportName={setReportName}
              showRunAndSave={true}
              reportNameMaxLength={reportNameMaxLength}
            />
          }
          hideCloseBtn={true}
          size='otp'
          acceptBtnText={
            <GetModalButtonJSX
              reportName={reportName}
              showRunAndSave={true}
              setShowRunAndSave={setShowRunAndSave}
              onModalTriggeredRun={onModalTriggeredRun}
            />
          }
          disableAcceptBtn={reportName?.length > reportNameMaxLength}
          reject={closeCreateReportModal}
          isBtn2Available={true}
          btn2Accept={onModalTriggeredRunAndSave}
          customAcceptBtnClass='btnNullPadding'
          ifShowingButton2={true}
          disableAcceptBtn2={
            !reportName || reportName?.length > reportNameMaxLength
          }
          buttonUI='reportsCreateReport'
        />
      )}

      <CommonModal
        show={showErrorModal}
        header='Error'
        body={<ErrorBodyJSX />}
        hideCloseBtn={true}
        size='otp'
        acceptBtnText='Yes'
        accept={() => onModalSave(lastSavedReportData)}
        rejectBtnText='No'
        reject={closeCreateReportModal}
      />

      {isError ? (
        <ErrorComponent
          refreshAction={getCampaignList}
          controller={cancelController}
        />
      ) : (
        <div className='createReportWrapper'>
          <Container
            fluid
            className='removePaddingOrMargin createReportInnerWrapper'
          >
            <Row className='removePaddingOrMargin'>
              {!campaignId && (
                <Col
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  className='removePaddingOrMargin createReportElements'
                >
                  <SearchableDropdownComponent
                    options={campaignList ?? []}
                    label='campaign_name'
                    id='campaign_id'
                    dropdownId='campaignsCR'
                    dropdownLabel='Campaign'
                    labelWidth={{ width: '80px' }}
                    dropdownWidth={{
                      width: 'calc(100% - 80px)',
                      minWidth: '193px',
                    }}
                    placeholderLabel={setPlaceHolderLabel(
                      isDisabled.campaign,
                      'Campaign'
                    )}
                    selectedValue={campaign}
                    handleChange={(val) => {
                      if (
                        (!campaign && val) ||
                        (val && campaign.campaign_id !== val.campaign_id)
                      ) {
                        setCampaign(val);
                        setProperStartDateString(
                          moment(
                            val?.start_date.slice(0, val?.start_date.length - 1)
                          ).format('MM/DD/yyyy')
                        );
                        setProperLookbackDateString(
                          moment(
                            val?.lookback_date.slice(
                              0,
                              val?.lookback_date.length - 1
                            )
                          ).format('MM/DD/yyyy')
                        );
                      }
                    }}
                    isLoading={isLoading.campaign}
                    isDisabled={isDisabled.campaign}
                    uniqueIdentifier='reportsCreateReportCampaigns'
                    ifIdVisableOnLabel={true}
                    excludeIdVisableOnLabel='no_id'
                  />
                </Col>
              )}

              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='removePaddingOrMargin createReportElements'
              >
                <MultiselectDropdownComponent
                  dropdownId='mediaPartnerCR'
                  dropdownDataList={mediaPartnerList}
                  dataId='partner_id'
                  dataName='partner_name'
                  label='Media Partner'
                  dropdownData={mediaPartner}
                  setDropdownData={(data) => {
                    if (data) {
                      setMediaPartner(data);
                    } else {
                      setMediaPartnerList(
                        mediaPartnerListRef.current
                          ? [...mediaPartnerListRef.current]
                          : null
                      );
                    }
                  }}
                  ifIdSearchAvailable={true}
                  placeholder={setPlaceHolderLabel(
                    isDisabled.mediaPartner,
                    'Media Partner'
                  )}
                  txtSearch='Search by name of the partner'
                  isAllCheckedInitially={checkSelectAll(
                    mediaPartnerList,
                    mediaPartner,
                    'partner_id'
                  )}
                  labelWidth={{ width: '120px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 120px)',
                    minWidth: '162px',
                  }}
                  isLoading={isLoading.mediaPartner}
                  isDisabled={
                    isDisabled.mediaPartner ||
                    showCreativeTypeDropdown ||
                    showPlacementDropdown
                  }
                  uniqueIdentifier='reportsCreateReportPartners'
                  isOpen={setShowPartnerDropdown}
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='removePaddingOrMargin createReportElements'
              >
                <MultiselectDropdownComponent
                  dropdownId='creativeTypeCR'
                  dropdownDataList={creativeTypeList}
                  dataId='value'
                  dataName='name'
                  label='Creative Type'
                  dropdownData={creativeType}
                  setDropdownData={(data) => {
                    if (data) {
                      setCreativeType(data);
                    } else {
                      setCreativeTypeList(
                        creativeTypeListRef.current
                          ? [...creativeTypeListRef.current]
                          : null
                      );
                    }
                  }}
                  ifIdSearchAvailable={true}
                  placeholder={setPlaceHolderLabel(
                    isDisabled.creativeType,
                    'Creative Type'
                  )}
                  txtSearch='Search by the creative type'
                  isAllCheckedInitially={checkSelectAll(
                    creativeTypeList,
                    creativeType,
                    'name'
                  )}
                  labelWidth={{ width: '120px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 120px)',
                    minWidth: '162px',
                  }}
                  isLoading={isLoading.creativeType}
                  isDisabled={
                    isDisabled.creativeType ||
                    showPartnerDropdown ||
                    showPlacementDropdown
                  }
                  uniqueIdentifier='reportsCreateReportCreativeTypes'
                  isOpen={setShowCreativeTypeDropdown}
                  alignType='right'
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='removePaddingOrMargin createReportElements'
              >
                <MultiselectDropdownComponent
                  dropdownId='placementCR'
                  dropdownDataList={placementList}
                  dataId='placement_id'
                  dataName='placement_name'
                  dataType='type'
                  label='Placement'
                  dropdownData={placement}
                  setDropdownData={(data) => {
                    if (data) {
                      setPlacement(data);
                    } else {
                      setPlacementList(
                        placementListRef.current
                          ? [...placementListRef.current]
                          : null
                      );
                    }
                  }}
                  ifIdSearchAvailable={true}
                  placeholder={setPlaceHolderLabel(
                    isDisabled.placement,
                    'Placement'
                  )}
                  txtSearch='Search by name of the placement'
                  isAllCheckedInitially={checkSelectAll(
                    placementList,
                    placement,
                    'placement_id'
                  )}
                  labelWidth={{ width: '81px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 81px)',
                    minWidth: '190px',
                  }}
                  isLoading={isLoading.placement}
                  isDisabled={
                    isDisabled.placement ||
                    showPartnerDropdown ||
                    showCreativeTypeDropdown
                  }
                  uniqueIdentifier='reportsCreateReportPlacements'
                  isOpen={setShowPlacementDropdown}
                  ifIdVisibleOnLabel={true}
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='removePaddingOrMargin createReportElements'
              >
                <SearchableDropdownComponent
                  options={presentTimeFrameOptions}
                  label='name'
                  id='id'
                  dropdownId='createOnDemandRptTimeFrame'
                  dropdownLabel='Time Frame'
                  labelWidth={{ width: '100px' }}
                  dropdownWidth={{
                    width: 'calc(100% - 100px)',
                    minWidth: '173px',
                  }}
                  placeholderLabel={setPlaceHolderLabel(
                    !campaign,
                    'Time Frame'
                  )}
                  selectedValue={timeFrame}
                  handleChange={setTimeFrame}
                  isSearchable={false}
                  isDisabled={!campaign}
                  uniqueIdentifier='createOnDemandRptTimeFrames'
                  ifShowWarningAtOptions={true}
                  warningOptionId='isDisabled'
                  warningMessage={'Warning! ' + nullImpFreqWarn}
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                className='removePaddingOrMargin createReportElements'
              >
                <DatePickerComponent
                  datePickerId='createOnDemandRptDateRange'
                  label='Date Range'
                  labelWidth={{ width: '90px' }}
                  datePickerBodyWidth={{
                    width: 'calc(100% - 90px)',
                    minWidth: '180px',
                  }}
                  placeholderLabel='Start Date - End Date'
                  minDate={properStartDateString}
                  maxDate={
                    timeFrame?.id === 0 ? currentDate : properLookbackDateString
                  }
                  isDateRangePicker={true}
                  dateRange={[startDate, endDate]}
                  setDateRange={(range) => {
                    setStartDate(range[0]);
                    setEndDate(range[1]);
                  }}
                  isDisabled={timeFrame?.id !== 0}
                  format='MM/DD/YYYY'
                  currentYear={checkCondition(
                    campaign &&
                      daysToFormatWRTDates(properLookbackDateString) <= 0,
                    moment(properLookbackDateString).year(),
                    moment(currentDate).year()
                  )}
                  startYear={checkCondition(
                    campaign,
                    moment(properStartDateString).year() - 1,
                    2010
                  )}
                  uniqueIdentifier='createOnDemandRpt'
                />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className='removePaddingOrMargin createReportButton'
              >
                <div>
                  <CommonButtonComponent
                    label='Create Report'
                    isDisabled={
                      !(
                        campaign &&
                        startDate &&
                        endDate &&
                        placement &&
                        mediaPartner
                      ) ||
                      showPartnerDropdown ||
                      showCreativeTypeDropdown
                    }
                    appendButtonClassName='createReportBtnBody'
                    showLoader={false}
                    onClickCTA={() => {
                      setTimeout(() => {
                        setIfOpenModal(true);
                      }, 50);
                    }}
                    buttonUI='reportsCreateReport'
                  />
                </div>
                <div>
                  <CommonButtonComponent
                    label='Create Scheduled Report'
                    isDisabled={isError || !campaign || !mediaPartner || !creativeType || !placement}
                    appendButtonClassName='createScheduledReportBtnBody'
                    showLoader={false}
                    onClickCTA={onClickCreateScheduleReport}
                    buttonUI='reportsCreateScheduledReport'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default CreateReportComponent;

const GetModalButtonJSX = ({
  reportName,
  showRunAndSave,
  setShowRunAndSave,
  onModalTriggeredRun,
}) => {
  return (
    <div className='modalButtonLabelWrapper'>
      <div className='modalButtonLbl' onClick={onModalTriggeredRun}>
        Run
      </div>
      {/* <div
        className='arrowWrapper'
        onClick={() => setShowRunAndSave(!showRunAndSave)}
      >
        <div className={`arrow ${showRunAndSave ? 'open' : 'close'}`}></div>
      </div> */}
    </div>
  );
};

const ErrorBodyJSX = () => {
  return (
    <div className='savingErrorWrapper'>
      <div>Error occurred while saving the Report.</div>
      <div>Do you want to try to save it again?</div>
    </div>
  );
};

// Common functions

const formatNames = (listData, selectedData, dataName) => {
  if (listData?.length === selectedData?.length) {
    return 'All Selected';
  }

  const totalSelectedDataCount = selectedData?.length,
    firstSelectedData = selectedData ? selectedData[0][dataName] : '';

  if (totalSelectedDataCount === 1) {
    return firstSelectedData;
  }

  return `${firstSelectedData}, ${totalSelectedDataCount - 1} more selected`;
};
