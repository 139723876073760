import React, { useEffect } from 'react';
import {
  AccountCreate,
  AccountList,
  CampaignLookupComponent,
  PartnerList,
} from './components';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetClientMode,
  updateClientMode,
  updateShowUtilityBar,
} from '../../redux/utilitybarSlice';
import { breadCrumbHeader } from '../../constants';
import { Link, useLocation } from 'react-router-dom';

import './admin.css';

const Admin = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const location = useLocation(),
    locationArr = location?.pathname?.split('/'),
    locationMatch = locationArr[locationArr?.length - 1];

  useEffect(() => {
    dispatch(updateShowUtilityBar(false));

    return () => {
      dispatch(updateShowUtilityBar(true));
    };
  }, []);

  useEffect(() => {
    dispatch(updateClientMode(true));

    return () => {
      dispatch(resetClientMode());
    };
  }, []);

  return (
    <div className='adminWrapper'>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <Link to='/'>
            <div className='homeBlue'></div>
          </Link>
          <div className='breadCrumbBody'>
            <span>/</span>
            {breadCrumbHeader.admin} &nbsp;
            <span>/</span>
            {(locationMatch === 'accounts' || locationMatch === 'new') &&
              breadCrumbHeader.clientAccounts}
            {locationMatch === 'new' && (
              <>
                &nbsp;&nbsp;<span>/</span>
                {breadCrumbHeader.createAccount}
              </>
            )}
            {locationMatch === 'campaign-lookup' &&
              breadCrumbHeader.campaignLookup}
            {locationMatch === 'partners' && breadCrumbHeader.partners}
          </div>
        </div>
      </div>
      <RenderElements locationMatch={locationMatch} user={user} />
    </div>
  );
};

export default Admin;

const RenderElements = ({ locationMatch, user }) => {
  if (locationMatch === 'accounts') {
    return <AccountList role={user?.role} />;
  }
  if (locationMatch === 'campaign-lookup') {
    return <CampaignLookupComponent role={user?.role} />;
  }
  if (locationMatch === 'partners') {
    return <PartnerList role={user?.role} />;
  }
  if (locationMatch === 'new') {
    return <AccountCreate />
  }
};
