import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick';
import { getCurrentESTDate } from '../../utils/functions';
import './modals.css';
import {
  RespondentDataReportFrequencyOptions,
  frequencyDayOptionList,
  frequencyIntervalOptionList,
  frequencyOptionsList,
} from '../../constants';

// let frequencyOptions = [...frequencyOptionsList]
const days = [...frequencyDayOptionList],
  intervals = [...frequencyIntervalOptionList];

const SetFrequencyModal = ({
  show,
  close,
  header = 'Alert',
  accept = null,
  reject = null,
  acceptBtnText = 'OK',
  rejectBtnText = 'Cancel',
  onWheel = null,
  disableAcceptBtn = false,
  disableRejectBtn = false,
  hideCloseBtn = false,
  frequencyOpt,
  uniqueIdentifier,
  reportType,
  inEdit = false
}) => {
  const [prevFrequencyOptions, setPrevFrequencyOptions] =
    useState(frequencyOpt);
  const [showModal, setShowModal] = useState(show);
  const [frequencyOption, setFrequencyOption] = useState(
    frequencyOpt.repeatEvery
  );
  const [selectedWeekDays, setSelectedWeekDays] = useState(
    frequencyOpt.selectedDays.map((elem) => {
      if (elem === 7) {
        return 0;
      }
      return elem;
    }) ?? [0]
  );
  const [intervalOption, setIntervalOption] = useState(
    getIntervalOption(frequencyOpt)
  );
  const [weekDayOption, setWeekDayOption] = useState(
    frequencyOpt.selectedDays?.length
      ? days.find((elem) => frequencyOpt.selectedDays.includes(elem.value))
      : days[0]
  );

  const [prevMonthlyOption, setPrevMonthlyOption] = useState(
    getMonthlyOptions(frequencyOpt)
  );
  const [monthlyOption, setMonthlyOption] = useState(
    getMonthlyOptions(frequencyOpt)
  );
  const [monthlyInterval, setMonthlyInterval] = useState(
    getMonthlyInterval(frequencyOpt)
  );

  const frequencyDropdownRef = useRef(null);
  const intervalDropdownRef = useRef(null);
  const weekDayDropdownRef = useRef(null);

  const [isFrequencyDropdownActive, setIsFrequencyDropdownActive] =
    useDetectOutsideClick(frequencyDropdownRef, false);
  const [isIntervalOptionSelected, setIsIntervalOptionSelected] =
    useDetectOutsideClick(intervalDropdownRef, false);
  const [isWeekDayOptionSelected, setIsWeekDayOptionSelected] =
    useDetectOutsideClick(weekDayDropdownRef, false);
 const [frequencyOptions, setFrequencyOptions] = useState([...frequencyOptionsList])

 useEffect(() => {
  if(reportType === 2){
    setFrequencyOptions([...RespondentDataReportFrequencyOptions])
    //This may not be what I need to correctly reset the value in the frequency button
    //when the report type is changed
    if (!inEdit) setFrequencyOption({ key: "2", value: "Daily", name: "Daily" })
  } else {
    setFrequencyOptions([...frequencyOptionsList])
    //This may not be what I need to correctly reset the value in the frequency button
    //when the report type is changed
    if (!inEdit) setFrequencyOption({ key: "2", value: "Daily", name: "Daily" })
  }
 }, [reportType])

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    const modal = document.querySelector('#customModal');
    if (modal && onWheel) {
      modal.addEventListener('wheel', preventScroll, { passive: false });
    }
    return () => {
      if (modal && onWheel) {
        modal.removeEventListener('wheel', preventScroll);
      }
    };
  }, []);

  const reset = (so, mo) => {
    const tempWeekDays = so.selectedDays.map((item) => (item === 7 ? 0 : item));
    setFrequencyOption(so.repeatEvery);
    setSelectedWeekDays(tempWeekDays);
    setIntervalOption(
      so.selectedRecurrence > 0
        ? intervals.find((interval) => interval.key === so.selectedRecurrence)
        : intervals[0]
    );
    setWeekDayOption(
      so.repeatEvery.key === 4 && mo === 'weekDay'
        ? days[tempWeekDays[0]]
        : days[0]
    );
    setMonthlyOption(so.repeatEvery.key === 4 ? mo : 'interval');
    setMonthlyInterval(
      so.selectedDate < 1 ? Number(getCurrentESTDate('DD')) : so.selectedDate
    );
  };

  const preventScroll = (e) => {
    onWheel(e);
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const showListOptions = () => {
    setIsFrequencyDropdownActive(!isFrequencyDropdownActive);
  };

  const showIntervalOptions = (isWeekDay) => {
    if (isWeekDay) {
      setIsWeekDayOptionSelected(!isWeekDayOptionSelected);
    } else {
      setIsIntervalOptionSelected(!isIntervalOptionSelected);
    }
  };

  const saveFrequencyOptions = (accept) => {
    let selectedWeekDayNames;

    switch (frequencyOption.value) {
      case 'Monthly':
        selectedWeekDayNames = [weekDayOption.value];
        break;
      case 'Daily':
        selectedWeekDayNames = [];
        break;
      case 'On Demand':
        selectedWeekDayNames = [];
        break;
      default:
        selectedWeekDayNames = days
          .filter((day, index) => selectedWeekDays?.includes(index))
          .map((day) => day.value);
    }

    const newFrequencyOption = setWeeklyOptions(selectedWeekDayNames),
      selectedOptions = {
        repeatEvery: newFrequencyOption,
        selectedDays: selectedWeekDayNames,
        selectedDate:
          newFrequencyOption.key === 4 && monthlyOption === 'interval'
            ? monthlyInterval
            : -1,
        selectedRecurrence:
          newFrequencyOption.key === 4 && monthlyOption === 'weekDay'
            ? intervalOption.key
            : -1,
      };

    accept(selectedOptions);
    setPrevFrequencyOptions(selectedOptions);
    setPrevMonthlyOption(monthlyOption);
    reset(selectedOptions, monthlyOption);
  };

  const setWeeklyOptions = (selectedWeekDayNames) => {
    if (frequencyOption.key === 6) {
      const daysForEveryBusinessDays = selectedWeekDayNames.filter((item) => {
        return [1, 2, 3, 4, 5].indexOf(item) !== -1;
      });
      if (selectedWeekDayNames.length === 7){
        setFrequencyOption(frequencyOptions[1]);
        return frequencyOptions[1];
      } else if(selectedWeekDayNames.includes(6) || selectedWeekDayNames.includes(0) || daysForEveryBusinessDays.length !== 5) {
        setFrequencyOption(frequencyOptions[3]);
        return frequencyOptions[3];
      } else if (daysForEveryBusinessDays.length === 5 && selectedWeekDayNames.length === 5) {
        setFrequencyOption(frequencyOptions[2]);
        return frequencyOptions[2];
      } 
    }
    return frequencyOption;
  };

  const renderFrequencyDropDown = () => {
    return (
      <div className='frequencyDropdown'>
        <div
          id={uniqueIdentifier + 'FrequenciesCTA'}
          name={uniqueIdentifier + 'FrequenciesCTA'}
          className='frequencyDropbtn'
          ref={frequencyDropdownRef}
          onClick={() => showListOptions()}
        >
          <div className='frequencyDropBtnLabel'>{frequencyOption.name}</div>
          <div
            onClick={() => showListOptions()}
            className='downArrowBlack'
          ></div>
        </div>
        {isFrequencyDropdownActive && (
          <div
            id={uniqueIdentifier + 'Frequencies'}
            name={uniqueIdentifier + 'Frequencies'}
            className='frequencyDropdownContent'
          >
            {frequencyOptions.map((option) => (
              <div
                key={option.name}
                id={
                  uniqueIdentifier +
                  'Frequency' +
                  option.name.replace(/\s/g, '')
                }
                name={
                  uniqueIdentifier +
                  'Frequency' +
                  option.name.replace(/\s/g, '')
                }
                className={
                  frequencyOption.kay === option.kay &&
                  frequencyOption.name === option.name
                    ? 'active'
                    : ''
                }
                onClick={() => {
                  if (frequencyOption.name !== option.name) {
                    if (option.name === 'Every Business Day') {
                      setFrequencyOption(option);
                      setSelectedWeekDays([1, 2, 3, 4, 5]);
                    } else {
                      setSelectedWeekDays([0]);
                      setFrequencyOption(option);
                    }
                  }
                }}
              >
                {option.name}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderWeekDays = () => {
    return (
      <div
        id={uniqueIdentifier + 'AllWeekDays'}
        name={uniqueIdentifier + 'AllWeekDays'}
        className='weekDayCellContainer'
      >
        {days.map((day, index) => (
          <div
            id={uniqueIdentifier + day.key}
            name={uniqueIdentifier + day.key}
            onClick={() => {
              if (selectedWeekDays.includes(index)) {
                let updatedWeekDays = selectedWeekDays.filter(
                  (i) => i !== index
                );
                setSelectedWeekDays([...updatedWeekDays]);
                setWeeklyOptions(updatedWeekDays);
              } else {
                setSelectedWeekDays([...selectedWeekDays, index]);
                setWeeklyOptions([...selectedWeekDays, index]);
              }
            }}
            key={day.key}
            className={`weekDayCell ${
              selectedWeekDays.includes(index) ? 'cellSelected' : ''
            }`}
          >
            {day.label}
          </div>
        ))}
      </div>
    );
  };

  const renderIntervalDropDown = () => {
    return (
      <div className='intervalDropDown'>
        <div
          ref={intervalDropdownRef}
          id={uniqueIdentifier + 'MonthlyOptionOnTheWeeksCTA'}
          name={uniqueIdentifier + 'MonthlyOptionOnTheWeeksCTA'}
          className={`intervalDropbtn ${
            monthlyOption === 'interval' ? 'intervalDropbtnDisabled' : ''
          }`}
          style={{ width: '90px' }}
          onClick={() => showIntervalOptions()}
        >
          {intervalOption.value}
          <div
            onClick={() => showIntervalOptions()}
            className='downArrowBlack'
          ></div>
        </div>
        {isIntervalOptionSelected && (
          <div
            id={uniqueIdentifier + 'MonthlyOptionOnTheWeeks'}
            name={uniqueIdentifier + 'MonthlyOptionOnTheWeeks'}
            className='intervalDropdownContent'
          >
            {intervals.map((option) => (
              <div
                key={option.key}
                id={
                  uniqueIdentifier +
                  'MonthlyOptionOnThe' +
                  option.value +
                  'Week'
                }
                name={
                  uniqueIdentifier +
                  'MonthlyOptionOnThe' +
                  option.value +
                  'Week'
                }
                onClick={() => {
                  setIntervalOption(option);
                }}
              >
                {option.value}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderWeekDayDropDown = () => {
    return (
      <div className='intervalDropDown'>
        <div
          id={uniqueIdentifier + 'MonthlyOptionOnTheDaysCTA'}
          name={uniqueIdentifier + 'MonthlyOptionOnTheDaysCTA'}
          ref={weekDayDropdownRef}
          className={`weeklyDropbtn ${
            monthlyOption === 'interval' ? 'weeklyDropbtnDisabled' : ''
          }`}
          style={{ width: '110px' }}
          onClick={() => showIntervalOptions(true)}
        >
          {weekDayOption.key}
          <div
            onClick={() => showIntervalOptions(true)}
            className='downArrowBlack'
          ></div>
        </div>
        {isWeekDayOptionSelected && (
          <div
            id={uniqueIdentifier + 'MonthlyOptionOnTheDays'}
            name={uniqueIdentifier + 'MonthlyOptionOnTheDays'}
            className='intervalDropdownContent'
          >
            {days.map((option) => (
              <div
                key={option.key}
                id={uniqueIdentifier + 'MonthlyOptionOn' + option.key}
                name={uniqueIdentifier + 'MonthlyOptionOn' + option.key}
                onClick={() => {
                  setWeekDayOption(option);
                }}
              >
                {option.key}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderMonthlyOptions = () => {
    return (
      <div className='monthlyOptionsContainer'>
        <form>
          <div className='onDayWrapper custom-margin-vertical-10'>
            <div className='custom-flex-1' />
            <div className='onDayWrapper custom-margin-top-10 custom-flex-3'>
              <input
                type='radio'
                id={uniqueIdentifier + 'MonthlyOptionOnDay'}
                className='monthlyRadioButton'
                name='onDayRadio'
                autoFocus
                value='interval'
                checked={monthlyOption === 'interval'}
                onChange={(e) => {
                  setMonthlyOption(e.target.value);
                }}
              />
              On day
              <input
                type='number'
                id={uniqueIdentifier + 'MonthlyOptionOnDayValue'}
                name={uniqueIdentifier + 'MonthlyOptionOnDayValue'}
                value={monthlyInterval}
                className='dayInput'
                disabled={monthlyOption === 'weekDay'}
                onChange={(e) => {
                  if (e.target.value < 1) {
                    e.target.value = 1;
                  }

                  if (e.target.value > 31) {
                    e.target.value = 31;
                  }

                  if (e.target.value.includes('.')) {
                    e.target.value = parseInt(Number(e.target.value));
                  }
                  setMonthlyInterval(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='onDayWrapper'>
            <div className='custom-flex-1' />
            <div className='onDayWrapper custom-flex-3'>
              <input
                type='radio'
                id={uniqueIdentifier + 'MonthlyOptionOnThe'}
                name='onDayRadio'
                className='monthlyRadioButton'
                value='weekDay'
                checked={monthlyOption === 'weekDay'}
                onChange={(e) => {
                  setMonthlyOption(e.target.value);
                }}
              />
              On the
              {renderIntervalDropDown()}
              {renderWeekDayDropDown()}
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderFrequencySelector = () => {
    return (
      <div>
        <div className='frequencyOptionsWrapper'>
          <div className='frequencyLabel'>Repeat Every</div>
          {renderFrequencyDropDown()}
        </div>
        {(frequencyOption.value === 'Weekly' ||
          frequencyOption.value === 'Every Business Day' ||
          frequencyOption.value === 'Bi-Weekly') &&
          renderWeekDays()}
        {frequencyOption.value === 'Monthly' && reportType !== 2 &&  renderMonthlyOptions()}
      </div>
    );
  };

  const modalFooter = () => {
    return (
      <Fragment>
        <div className='modalFooter'>
          <div className='separator' />
          {reject ? (
            <button
              id={uniqueIdentifier + 'CancelCTA'}
              name={uniqueIdentifier + 'CancelCTA'}
              className='btnSecondary'
              onClick={() => {
                reject();
                setTimeout(() => {
                  reset(prevFrequencyOptions, prevMonthlyOption);
                }, 50);
              }}
              disabled={disableRejectBtn}
            >
              {rejectBtnText}
            </button>
          ) : null}
          {accept ? (
            <div className='btnPrimaryWrapper'>
              <button
                id={uniqueIdentifier + 'SaveCTA'}
                name={uniqueIdentifier + 'SaveCTA'}
                className='btnPrimary'
                onClick={() => saveFrequencyOptions(accept)}
                disabled={disableAcceptBtn}
              >
                {acceptBtnText}
              </button>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  };

  return (
    <div
      className={
        showModal ? 'customModal modalDiolog show' : 'customModal modalDiolog'
      }
    >
      <div className='modalInnerWrap' id='customModal'>
        <div className='modalHeader'>
          {!hideCloseBtn && (
            <button
              id={uniqueIdentifier + 'CloseCTA'}
              name={uniqueIdentifier + 'CloseCTA'}
              className='close xCircle'
              onClick={(e) => {
                e.preventDefault();
                setShowModal(false);
                close();
                reset(prevFrequencyOptions, prevMonthlyOption);
              }}
            ></button>
          )}
          <div className='diologTitle'>{header}</div>
        </div>
        <div className='modalBody'>
          <div className='diologContent'>{renderFrequencySelector()}</div>
        </div>
        {modalFooter()}
      </div>
    </div>
  );
};

export default SetFrequencyModal;

const getIntervalOption = (frequencyOpt) => {
  return frequencyOpt?.repeatEvery?.key === 4 &&
    frequencyOpt.selectedRecurrence > 0
    ? intervals.find((el) => frequencyOpt.selectedRecurrence === el.key)
    : intervals[0];
};
const getMonthlyOptions = (frequencyOpt) => {
  return frequencyOpt?.repeatEvery?.key === 4 && frequencyOpt?.selectedDate === -1
    ? 'weekDay'
    : 'interval';
};

const getMonthlyInterval = (frequencyOpt) => {
  return frequencyOpt?.repeatEvery?.key === 4 && frequencyOpt.selectedDate > 0
    ? frequencyOpt.selectedDate
    : Number(getCurrentESTDate('DD'));
};
