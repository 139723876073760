/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import {
  ErrorComponent,
  CommonInput,
  SearchableDropdownComponent,
  CommonButtonComponent,
  RedirectModalComponent,
  CommonModal,
  CommonHeadingComponent,
} from '../../../components';
import { commonNotification } from '../../../utils/common-notifications';
import { useGetApiInterceptor } from '../../../hooks/useGetApiInterceptor';
import { baseEndpoints } from '../../../constants';
import {
  axiosPatchRequest,
  axiosPostRequest,
} from '../../../services/http/axiosCalls';
import { setBlockingLoaderState } from '../../../redux/blockingLoader';
import {
  updateAdvertiserList,
  updateSelectedClientsAdvertiser,
  updateShowAdvertiserInitialPopUp,
} from '../../../redux/advertiserSlice';
import { getSortedArrayOfObj } from '../../../utils/functions';
import '../advertisers.css';


// Advertiser Listing Component
const AdvertiserEdit = ({
  advertiserId,
  setHeading,
  getAdName,
  setIsLoading,
}) => {
  const [isMountedAdv, setIsMountedAdv] = useState(false);
  const [isMountedCategory, setIsMountedCategory] = useState(false);
  const [url, setUrl] = useState('');
  const [categoriesUrl, setCategoriesUrl] = useState('');
  const params = null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const client = useSelector((state) => state.client); // listener to client change in global header
  const user = useSelector((state) => state.user); // listener to client change in global header
  const advertiser = useSelector((state) => state.advertiser); // Fetch advertiser's information from redux store
  
  const [advName, setAdvName] = useState(''); // State to handle Advertiser Name in form input

  const statusList = [{ status: 'Inactive' }, { status: 'Active' }]; // Status list to populate status dropdown
  const [advStatus, setAdvStatus] = useState(null); // State to handle Status in form dropdown

  const [advCategory, setAdvCategory] = useState(null); // State to handle Advertiser Category
  const [advSubCategory, setAdvSubCategory] = useState(null); // State to handle Advertiser Sub-Category

  const [inCreateAdv, setInCreateAdv] = useState(false); // State to define, if in Create or Edit Advertiser
  const [editInitialized, setEditInitialized] = useState(false); // State to handle Edit Advertiser initialization

  const [showModal, setShowModal] = useState(false); // State to handle re-directional Modal

  const { data, isLoading, isError } = useGetApiInterceptor(
    isMountedAdv,
    params,
    url
  ); // States for Advertiser Details
  const categoryStates = useGetApiInterceptor(
    isMountedCategory,
    params,
    categoriesUrl
  ); // States for Advertiser Categories

  const [noInProgress, setNoInProgress] = useState(true);

  const [defaultAdvName, setDefaultAdvName] = useState(''),
    [defaultAdvStatus, setDefaultAdvStatus] = useState(null),
    [validName, setValidName] = useState({
      valid: true,
      errorMsg: ''
    }),
    [defaultAdvCategory, setDefaultAdvCategory] = useState(null),
    [defaultAdvSubCategory, setDefaultAdvSubCategory] = useState(null);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setMounts(false);
  }, [isError, categoryStates.isError]);

  const refreshComponent = () => {
    setMounts(true);
  };

  const setMounts = (data) => {
    if (isError) {
      setIsMountedAdv(data);
    }
    if (categoryStates.isError) {
      setIsMountedCategory(data);
    }
  };

  // Advertiser Form for Create/Edit Advertiser
  const advForm = () => {
    if (
      !inCreateAdv &&
      !editInitialized &&
      data.length &&
      categoryStates.data &&
      categoryStates.data.length
    ) {
      setEditInitialized(true);
      setAdvName(data[0].name);
      getAdName(data[0].name);
      const statusIndex = data[0].status;
      setAdvStatus(statusList[statusIndex]);
      setDefaultAdvName(data[0].name);
      setDefaultAdvStatus(statusList[statusIndex]);

      // Set Category and Sub-category if available
      const editCategory =
        categoryStates?.data?.find(
          (item) => item.code === data[0].primaryCategory
        ) ?? null;
      if (editCategory) {
        setAdvCategory(editCategory);
        setDefaultAdvCategory(editCategory);
        const editSubCategory =
          editCategory?.children?.find(
            (item) => item.code === data[0].secondaryCategory
          ) ?? null;
        if (editSubCategory) {
          setAdvSubCategory(editSubCategory);
          setDefaultAdvSubCategory(editSubCategory);
        }
      }
    }

    const popUpBody = (
      <div className='flex mt-3 mb-3'>
        The selected Client Account does not have any Advertisers. Please create a new Advertiser to proceed.
      </div>
    )
    const showAdvertiserPopup = advertiser.showAdvertiserInitialPopUp;


   
    return (
      <>
        <div className='advFormWrapper'>
          <Row style={{padding: 0}}>
            <Col lg={6} className='advertisersBtnMargin advertiserDetailsElemWrapper fieldRelative'>
              <CommonInput
                placeholder='Advertiser Name'
                inputValue={advName}
                setInputValue={(val) => {
                  setAdvName(val)
                  if(val.length>45){
                    setValidName({valid:false, errorMsg: 'Maximum characters allowed for Name is 45'})
                  }else{
                    setValidName({valid:true, errorMsg: ''})
                  }
                }}
                disabled={!inCreateAdv && isLoading}
                className={ validName.valid?'advertiserInput':'advertiserInput errorInput'}
                label='Name '
                inputStyle={{
                  width: '100%',
                  minWidth: '175px',
                }}
                labelClass='requiredInput'
                isLoading={!inCreateAdv && isLoading}
                uniqueIdentifier={getAdvTypeLabel(inCreateAdv) + 'Name'}
                onBlurHandle={(val)=>{
                  if(!val?.target?.value?.length){
                    setValidName({valid:false, errorMsg:'Please enter a Name'})
                  } else if( val?.target?.value?.trim()?.length<3){
                    setValidName({valid: false, errorMsg: 'Name should have three characters'})
                  }
                }}
              />
               {!validName.valid && <span className='errorField'>{validName.errorMsg}</span>}
            </Col>
            <Col lg={6} className='advertisersBtnMargin advertiserDetailsElemWrapper'>
              <SearchableDropdownComponent
                options={statusList}
                label='status'
                id='status'
                dropdownId='statusDD'
                dropdownLabel='Status'
                dropdownWidth={setElementStyles()}
                placeholderLabel='Set Status'
                selectedValue={advStatus}
                handleChange={(val) => setAdvStatus(val)}
                isLoading={!inCreateAdv && isLoading}
                isDisabled={!inCreateAdv && isLoading}
                wrapperClass='requiredDD'
                uniqueIdentifier={getAdvTypeLabel(inCreateAdv) + 'Status'}
              />
            </Col>
            <Col lg={6} className='advertisersBtnMargin advertiserDetailsElemWrapper'>
              <SearchableDropdownComponent
                options={categoryStates.data ?? []}
                label='name'
                id='name'
                dropdownId='categoryDD'
                dropdownLabel='Primary Category'
                dropdownWidth={setElementStyles()}
                placeholderLabel='Select Category'
                selectedValue={advCategory}
                handleChange={(val) => {
                  setAdvCategory(val);
                  setAdvSubCategory(null);
                }}
                isLoading={categoryStates.isLoading}
                isDisabled={categoryStates.isLoading}
                uniqueIdentifier={getAdvTypeLabel(inCreateAdv) + 'Category1'}
                allowClearItem={true}
                onClearSelection = {() => {
                  setAdvCategory(null);
                  setAdvSubCategory(null);
                }}
              />
            </Col>
            <Col lg={6} className='advertisersBtnMargin advertiserDetailsElemWrapper'>
              <SearchableDropdownComponent
                options={advCategory?.children ?? []}
                label='name'
                id='name'
                dropdownId='categoryDD'
                dropdownLabel='Secondary Category'
                dropdownWidth={setElementStyles()}
                placeholderLabel='Select Category'
                selectedValue={advSubCategory}
                handleChange={(val) => setAdvSubCategory(val)}
                isLoading={categoryStates.isLoading}
                isDisabled={!advCategory || categoryStates.isLoading}
                uniqueIdentifier={getAdvTypeLabel(inCreateAdv) + 'Category2'}
                allowClearItem={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className='advertiserDetailsButtonWrapper'>
              <CommonButtonComponent
                label='Save'
                isDisabled={
                  advName?.trim().length < 3 ||
                  !advStatus ||
                  !noInProgress ||
                  !checkChange() ||
                  !validName.valid
                }
                onClickCTA={() => {
                  saveAdvertiser();
                }}
                buttonUI={getAdvTypeLabel(inCreateAdv) + 'Save'}
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnPrimary'
              />
              <CommonButtonComponent
                label='Cancel'
                isDisabled={false}
                showLoader={false}
                onClickCTA={() => {
                  getAdName('');
                  navigate('/advertisers');
                }}
                inverted={true}
                buttonUI={getAdvTypeLabel(inCreateAdv) + 'Cancel'}
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnSecondary'
              />
            </Col>
          </Row>
        </div>
        {/* showAdvertiserPopup: we are maintainng popup state through store 
        because we want to display this on specific conditions */}
         {showAdvertiserPopup && <CommonModal
          show={showAdvertiserPopup}
          header='Caution!'
          body={popUpBody}
          hideCloseBtn={true}
          size='md'
          acceptBtnText='Ok'
          isBtn2Available={true}
          accept={() => {
            // We need to set this false because we don't want to show popup on Crete Advertise button
            dispatch(updateShowAdvertiserInitialPopUp(false));
          }}
        />}
      </>
    );
  };

  // Method to Save Advertiser, for Create and Edit Advertiser
  const saveAdvertiser = () => {
    dispatch(setBlockingLoaderState({ active: true }));

    const statusCode = statusList.findIndex(
      (item) => item.status === advStatus.status
    );
    const payload = {
      uid: user.uid,
      userId: client.selectedClient,
      name: advName,
      status: statusCode,
      primaryCategory: advCategory?.code ?? null,
      secondaryCategory: advSubCategory?.code ?? null,
    };

    let requestPromise;
    if (inCreateAdv) {
      requestPromise = axiosPostRequest(baseEndpoints.advertisers, payload);
    } else {
      requestPromise = axiosPatchRequest(
        `${baseEndpoints.advertisers}/${advertiserId}`,
        null,
        payload
      );
    }
    setNoInProgress(false);

    requestPromise.then(
      (response) => {
        dispatch(setBlockingLoaderState({ active: false }));
        if (response.status === 200) {
          const advertiserList = advertiser.advertiserList, // Fetching previous advertiser list
            selectedClient = client.selectedClient; // Fetching current selected client

          //If in create mode - Modal wii pop up
          if (inCreateAdv) {
            // Update advertiser list of Redux store
            updateAdvertisers(
              dispatch,
              advertiserList,
              inCreateAdv,
              selectedClient,
              statusCode,
              advName,
              response.id
            );

            setAdvName('');
            setAdvCategory(null);
            setAdvSubCategory(null);
            setAdvStatus(statusList[1]);
            setShowModal(true);
            setNoInProgress(true);
            dispatch(setBlockingLoaderState({ active: false })); // Remove loader once request is completed
            return;
          }

          // Update advertiser list of Redux store
          updateAdvertisers(
            dispatch,
            advertiserList,
            inCreateAdv,
            selectedClient,
            statusCode,
            advName,
            Number(advertiserId)
          );

          // If in edit mode - notification will be displayed
          commonNotification({ msg: response.message, type: 'success' });
          dispatch(setBlockingLoaderState({ active: false })); // Remove loader once request is completed
          getAdName('');
          navigate('/advertisers');
        } else {
          commonNotification({
            msg: response.error || 'Something went wrong',
            type: 'danger',
          });
          setNoInProgress(true);
        }
      },
      (err) => {
        if(err?.response?.status === 409){
          commonNotification({
            msg: err?.response?.data?.error,
            type: 'danger',
          });
          setValidName({valid:false, errorMsg:'Name already exists. Please enter another name'})
          const searchElem = document.getElementById(`${getAdvTypeLabel(inCreateAdv)}Name`);
          searchElem?.focus();
          setNoInProgress(true);
          dispatch(setBlockingLoaderState({ active: false }));
          return;
        }
        commonNotification({ msg: err?.response?.data?.error, type: 'danger' });
        setNoInProgress(true);
        dispatch(setBlockingLoaderState({ active: false }));
      }
    );
  };

  //Method to check whether there was a change in the form

  const checkChange = () => {
    return (
      advName !== defaultAdvName ||
      advStatus?.status !== defaultAdvStatus?.status ||
      advCategory?.code !== defaultAdvCategory?.code ||
      advSubCategory?.code !== defaultAdvSubCategory?.code
    );
  };

  /**
   * First time to initialize api calls and set up data
   * @dependencies : client, page-number, sort-data, per-page-data
   */
  useEffect(() => {
    if (advertiserId === 'new') {
      setHeading('Create Advertiser');
      setInCreateAdv(true);
      setAdvStatus(statusList[1]);
      setIsMountedAdv(false);
    } else {
      setHeading('Edit Advertiser');
      setUrl(
        `${baseEndpoints.advertisers}/${client.selectedClient}/${advertiserId}`
      );
      setIsMountedAdv(true);
    }

    setCategoriesUrl(`${baseEndpoints.advertisers}/categories`);
    setIsMountedCategory(true);
  }, [advertiserId]);

  return (
    <div className='advertiserDetailsWrapper'>
      <CommonHeadingComponent headingLabel={advertiserId === 'new'?'Create Advertiser': 'Edit Advertiser'}/>
      <Container>
        <Row>
          <Col lg={12}>
            <div className='advertisersOuterWrapper'>
              <RenderElement
                isError={isError || categoryStates.isError}
                setUrl={refreshComponent}
                advForm={advForm}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {/* Re-directional Modal */}
      {showModal && (
        <RedirectModalComponent
          modalBodyElements={[
            'createAdvertiser2',
            'advertiserList',
            'createCampaign',
            'createProperty',
          ]}
          messageType='Advertiser'
          detailsElements={null}
          onCloseNav='/advertisers'
          samePageRedirect='createAdvertiser2'
          handleSamePageRedirect={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default AdvertiserEdit;

// Error HTML for navigating user to Advertiser List or Create Advertiser
const ErrorHTML = () => {
  return (
    <div>
      <h3>The advertiser does not exist!</h3>
      <div className='errorMessage'>
        Please navigate to <Link to={'/advertisers'}>Advertisers List</Link> or{' '}
        <Link to={'/advertisers/new'}>Create New Advertiser</Link>
      </div>
    </div>
  );
};

const updateAdvertisers = (
  dispatch,
  advList,
  inCreateAdv,
  client,
  status,
  name,
  advId
) => {
  const advertiserList = [...advList];
  if (inCreateAdv) {
    dispatchAdvertisers(
      dispatch,
      client,
      addAndFormatAdvertiserList(
        advertiserList,
        advId,
        name,
        client,
        status,
        true
      )
    );
    return;
  }

  const effectedIndex = advertiserList.findIndex(
    (adv) => adv.id === advId && adv.user_id === client
  );

  advertiserList[effectedIndex] = {
    ...advertiserList[effectedIndex],
    name,
    status,
  };
  dispatchAdvertisers(
    dispatch,
    client,
    addAndFormatAdvertiserList(advertiserList, advId, name, client, status)
  );
};

const addAndFormatAdvertiserList = (
  advList,
  advId,
  name,
  client,
  status,
  isCreated = false
) => {
  if (isCreated) {
    advList.push({
      id: advId,
      name,
      user_id: client,
      status,
    });
  }

  const alls = [],
    notAlls = [];

  advList.forEach((adv) => {
    if (adv.id === 0) {
      alls.push(adv);
    } else {
      notAlls.push(adv);
    }
  });

  return [...alls, ...getSortedArrayOfObj(notAlls, 'name')];
};

const dispatchAdvertisers = (dispatch, client, advertiserList) => {
  dispatch(updateAdvertiserList(advertiserList));
  dispatch(
    updateSelectedClientsAdvertiser({
      userId: client,
      advertiserList,
    })
  );
};

const RenderElement = ({ isError, setUrl, advForm }) => {
  return isError ? <ErrorComponent refreshAction={setUrl} /> : advForm();
};

const setElementStyles = () => {
  return {
    width: '100%',
    minWidth: '175px',
  };
};

const getAdvTypeLabel = (type) => {
  return type ? 'createAdvertiser' : 'editAdvertiser';
};
