import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import NoDataComponent from './NoData.component';
import LoaderComponent from '../loaderComponent/Loader.component';
import './CommonTablesComponent.css';
import MultiselectDropdownComponent from '../searchableMultiselectDropdown/MultiselectDropdown.component';

const CommonTablesComponent = ({
  uniqueId,
  data,
  columns,
  title = null,
  customTableHeader = null,
  isLoading,
  setSortData,
  sortColumnIds = [],
  defaultSortingApplied = null,
  isPaginationApplied = false,
  pageIndex,
  totalDataCount = 0,
  setPageIndex,
  dataPerPage = 10,
  setDataPerPage,
  selectableRows = false,
  handleRowSelect,
  selectableRowSelected,
  selectableRowDisabled,
  paginationOptions = {
    noRowsPerPage: true,
  },
  paginationServer = true,
  customTableStyles = {},
  customNoDataComponentStyle = { backgroundSize: '30%' },
  noDataMessage = 'No Data',
  noDataMessageFontSize = 24,
  noDataCompWidth = '100',
  noDataCompHeight = '100',
  foregnObj = { x: '35', y: '90', width: '150', height: '50' },
  showColumnShowHide = true,
  fromReportsPage = false,
}) => {
  const customStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight: '700',
      },
    },
    cells: {
      style: {
        fontSize: '16px',
        fontWeight: '400',
      },
    },
  };

  const sortOrders = ['asc', 'desc'],
    [lastAppliedSorting, setLastAppliedSorting] = useState(
      defaultSortingApplied
    ),
    [isOpen, setIsOpen] = useState(false),
    [rowSizeDropdownTransform, setRowSizeDropdownTransform] =
      useState('translate(0, 0)'),
    [columnState, setColumnState] = useState(
      columns.map((item) => ({ ...item }))
    ),
    [columnsToOmit, setColumnsToOmit] = useState(null),
    [haveSetColumnsInitially, setHaveSetColumnsInitially] = useState(false);

  const defaultSortedColumn = defaultSortingApplied?.column
    ? defaultSortingApplied.column
    : '';

  const setInitialColumnsToOmit = () => {
    const storedColumnsTotal = localStorage.getItem(`OmitDropdownColumnData`);
    const storedColumns = JSON.parse(storedColumnsTotal) && JSON.parse(storedColumnsTotal)[uniqueId];
    if (storedColumns && storedColumns?.length > 0) {
      setColumnsToOmit(
        storedColumns.map((item, index) => {
          if (!item.omit)
            return {
              id: item.id,
              name: item.omitDropdownName
                ? ''
                : item.name === '[Circular Reference]' ? columns[index].name : item.name,
            };
        })
      );
      setColumnsFromColumnsToOmit(
        storedColumns.map((item, index) => {
          if (!item.omit)
            return {
              id: item.id,
              name: item.omitDropdownName
                ? ''
                : item.name === '[Circular Reference]'
                ? columns[index].name
                : item.name,
            };
        }),
        columns
      );
  
  
    } else {
      setColumnsToOmit(
        columns?.map((item) => ({
          id: item.id,
          name:
            item.name === '[Circular Reference]'
              ? columns[index].name
              : item.name,
        }))
      );
      setColumnsFromColumnsToOmit(
        columns?.map((item) => ({
          id: item.id,
          name:
            item.name === '[Circular Reference]'
              ? columns[index].name
              : item.name,
        })),
        columns
      );
  
  
    }
  };

  const setCustomSortingStyle = (newSortApplied = null) => {
    // sorting
    const usableSortingApplied = newSortApplied ?? lastAppliedSorting;
    sortColumnIds?.forEach((item) => {
      const elementWrapper = document.querySelector(
          `div[data-sort-id="${item}"]`
        ),
        element = document.querySelector(`div[data-sort-id="${item}"] span`);
      if (usableSortingApplied?.column === item) {
        elementWrapper?.classList.add('active');
        element?.classList.add(`${usableSortingApplied?.order}`);
      } else {
        elementWrapper?.classList.add('inactive');
        element?.classList.add('asc');
      }
    });
  };

  const setColumnsFromColumnsToOmit = (columnsToOmits, columnsToUse) => {
    const columnToUpdate = [...columnState]?.length ? [...columnState] : columnsToUse;
    const columnIds = columnsToOmits?.map((item) => item?.id);
    columnToUpdate?.map((item) => {
      if (!columnIds?.includes(item?.id)) {
        item.omit = true;
      } else {
        item.omit = false;
      }
    });
    setColumnState(columnToUpdate);
    setColumnsToOmit(columnsToOmits);
    const storedColumnsTotal = localStorage.getItem(`OmitDropdownColumnData`);
    let setTo = JSON.parse(storedColumnsTotal);
    if (setTo) {
      setTo[uniqueId] = columnToUpdate;
    } else {
      setTo = { [uniqueId]: columnToUpdate };
    }
    localStorage.setItem(
      `OmitDropdownColumnData`,
      JSON.stringify(setTo, (key, value) => {
        // Handle circular references by returning a placeholder
        if (key === 'name' && typeof value === 'object') {
          return '[Circular Reference]';
        }
        return value;
      })
    );
  };

  useEffect(() => {
    // sorting
    if (sortColumnIds?.length > 0 && data?.length > 0) {
      setTimeout(() => {
        setCustomSortingStyle();
      }, 10);
    }
  }, [data]);

  useEffect(() => {
    setColumnState(columns.map((item) => ({ ...item })));
    haveSetColumnsInitially &&
      setColumnsFromColumnsToOmit(
        columnsToOmit,
        columns.map((item) => ({ ...item }))
      );
    if (!haveSetColumnsInitially && columns?.length > 0) {
      setInitialColumnsToOmit();
      setHaveSetColumnsInitially(true);
    }
  }, [columns]);

  const handleSort = (column) => {
    // sorting
    const appliedNewSorting = {
      column: column?.id,
      order:
        lastAppliedSorting?.column === column?.id
          ? sortOrders?.find((elem) => elem !== lastAppliedSorting?.order)
          : 'asc',
    };
    setLastAppliedSorting(appliedNewSorting);
    setCustomSortingStyle(appliedNewSorting);
    setSortData({
      sortField: column?.sortField,
      order: appliedNewSorting?.order,
    });
  };

  const onChangePage = (page) => {
    // pagination
    setPageIndex(page);
  };

  const onChangeDataPerPage = (count) => {
    if (setDataPerPage) {
      setDataPerPage(count);
    }
  };

  return (
    <div
      className={`dataTableWrapper ${
        data?.length === 0 && !isLoading ? 'noData' : ''
      }`}
    >
      <DataTable
        title={title}
        columns={columnState}
        data={data}
        highlightOnHover
        noDataComponent={
          <NoDataComponent
            tableName={customTableHeader ?? title}
            type='table'
            customImageStyle={customNoDataComponentStyle}
            messageText={noDataMessage}
            messageTextFontSize={noDataMessageFontSize}
            width={noDataCompWidth}
            height={noDataCompHeight}
            foregnObj={foregnObj}
          />
        }
        customStyles={customTableStyles ?? customStyles}
        selectableRows={selectableRows}
        selectableRowSelected={selectableRowSelected}
        onSelectedRowsChange={handleRowSelect}
        progressPending={isLoading}
        progressComponent={<LoaderComponent />}
        onSort={paginationServer ? handleSort : undefined}
        pagination={isPaginationApplied}
        onChangePage={onChangePage}
        paginationServer={paginationServer}
        paginationPerPage={dataPerPage}
        onChangeRowsPerPage={onChangeDataPerPage}
        paginationTotalRows={totalDataCount} // total count
        paginationComponentOptions={paginationOptions}
        defaultSortFieldId={defaultSortedColumn}
        selectableRowDisabled={selectableRowDisabled}
        paginationComponent={
          paginationServer
            ? () =>
                CustomPagination({
                  pageNumber: pageIndex,
                  setPageNumber: setPageIndex,
                  pageSize: dataPerPage,
                  setPageSize: setDataPerPage,
                  totalData: totalDataCount,
                  columns: columnState,
                  columnsToOmit,
                  setColumnsFromColumnsToOmit,
                  setColumnsToOmit,
                  enableRowSelector: !paginationOptions.noRowsPerPage,
                  isOpen,
                  setIsOpen,
                  rowSizeDropdownTransform,
                  setRowSizeDropdownTransform,
                  showOmitDropdown: showColumnShowHide,
                  uniqueId,
                  fromReportsPage
                })
            : null
        }
      />
    </div>
  );
};

export default CommonTablesComponent;

const CustomPagination = ({
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  totalData,
  columns,
  columnsToOmit,
  setColumnsToOmit,
  setColumnsFromColumnsToOmit,
  enableRowSelector,
  isOpen,
  setIsOpen,
  rowSizeDropdownTransform,
  setRowSizeDropdownTransform,
  showOmitDropdown,
  uniqueId,
  fromReportsPage
}) => {
  const correctColumns = columns?.map((item) => {
    return { ...item };
  });
  correctColumns.map((item) => {
    if (item.omitDropdownName) {
      item.name = item.omitDropdownName;
    } else if (typeof item.name === 'object') {
      let counter = 0;
      while (typeof item.name !== 'string' && counter < 7) {
        item.name = item.name.props?.children;
        counter++;
      }
      item.hasNameChanged = true;
    }
    return item;
  });
  const correctColumnsToOmit = columnsToOmit?.map((item) => {
    return { ...item };
  });
  correctColumnsToOmit?.map((item) => {
    if (typeof item.name === 'object') {
      let counter = 0;
      while (typeof item.name !== 'string' && counter < 7) {
        item.name = item.name.props?.children;
        counter++;
      }
      item.hasNameChanged = true;
    }
    return item;
  });
  const currentPage = useRef(pageNumber);
  const lastPage =
    totalData % pageSize == 0
      ? totalData / pageSize
      : Math.floor(totalData / pageSize + 1);
  const ref = useRef(null);

  const handleOnPageInput = (value) => {
    if (value == pageNumber) {
      return;
    } else if (value <= 1) {
      setPageNumber(1);
      currentPage.current = 1;
    } else if (value >= lastPage) {
      setPageNumber(Number(lastPage));
      currentPage.current = lastPage;

      currentPage.current = lastPage;
    } else {
      setPageNumber(Number(value));
      currentPage.current = value;
    }
  };
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    if (isOpen) {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && isOpen) {
          setIsOpen(false);
        }
      }
      // Bind
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // dispose
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [ref, isOpen]);

  const displayResultNumber = () => {
    let firstIndex = pageNumber * pageSize - (pageSize - 1);
    let secondIndex = pageNumber * pageSize;
    if (firstIndex === secondIndex || firstIndex === totalData) {
      return `Showing ${firstIndex} of ${totalData}`;
    } else if (secondIndex > totalData) {
      return `Showing ${firstIndex} - ${totalData} of ${totalData}`;
    } else {
      return `Showing ${firstIndex} - ${secondIndex} of ${totalData}`;
    }
  };

  const getTransform = () => {
    if (ref?.current && !isOpen) {
      const refBottom = ref.current.getBoundingClientRect().bottom;
      const windowCheck =
        window.innerHeight - refBottom <
        (totalData >= 20 ? 60 : totalData >= 10 ? 40 : 20);
      const bottomCheck =
        document.querySelector(' .elementWrapper')?.getBoundingClientRect()
          .bottom -
          refBottom <
        60;
      if (windowCheck || bottomCheck) {
        setRowSizeDropdownTransform(
          `translate(0, ${
            totalData >= 20 ? '-80' : totalData >= 10 ? '-60' : '-40'
          }px)`
        );
        return;
      }
      setRowSizeDropdownTransform('translate(0,0)');
    }
  };

  return (
    <div className='paginationBar'>
      {showOmitDropdown && <MultiselectDropdownComponent
        dropdownId={`${uniqueId}OmitDropdown`}
        dropdownDataList={correctColumns}
        dataId={'id'}
        dataName={'name'}
        label={'Show/Hide Columns'}
        dropdownData={correctColumnsToOmit}
        setDropdownData={(data) => {
          setColumnsFromColumnsToOmit(
            data?.map((item, index) => {
              if (columns[index].omitDropdownName) {
                item.name = '';
              } else if (item.name === '[Circular Reference]' || item.hasNameChanged) {
                item.name = columns[index].name;
                item.hasNameChanged = false;
              }
              return item;
            })
          );
        }}
        placeholder='All Hidden'
        wrapperClass={'dropdownWrapper'}
        labelWidth={{ width: '160px' }}
        dropdownWidth={{ width: '250px' }}
        isAllCheckedInitially={correctColumnsToOmit?.length === correctColumns?.length}
      />}
      {(fromReportsPage && enableRowSelector) && (
        <div
          ref={ref}
          className='pageSizeSelector'
          onClick={() => {
            getTransform();
            setIsOpen(!isOpen);
          }}
        >
          <div>Rows per page:</div>
          <div>
            <div className='pageSizeButton'>
              {pageSize}&nbsp;
              <i
                className={`${isOpen ? 'bi-chevron-down' : 'bi-chevron-up'}`}
              />
            </div>
            {isOpen && (
              <div
                className='pageSizeDropdown'
                style={{ transform: rowSizeDropdownTransform }}
              >
                <div
                  className='pageSizeOption'
                  onClick={() => pageSize !== 5 && setPageSize(5)}
                >
                  5
                </div>
                {totalData >= 5 && (
                  <div
                    className='pageSizeOption'
                    onClick={() => pageSize !== 10 && setPageSize(10)}
                  >
                    10
                  </div>
                )}
                {totalData >= 10 && (
                  <div
                    className='pageSizeOption'
                    onClick={() => pageSize !== 25 && setPageSize(25)}
                  >
                    25
                  </div>
                )}
                {totalData >= 25 && (
                  <div
                    className='pageSizeOption'
                    onClick={() => pageSize !== 50 && setPageSize(50)}
                  >
                    50
                  </div>
                )}
                {totalData >= 50 && (
                  <div
                    className='pageSizeOption'
                    onClick={() => pageSize !== 100 && setPageSize(100)}
                  >
                    100
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {(!fromReportsPage && enableRowSelector) && (
        <div
          ref={ref}
          className='pageSizeSelector'
          onClick={() => {
            getTransform();
            setIsOpen(!isOpen);
          }}
        >
          <div>Rows per page:</div>
          <div>
            <div className='pageSizeButton'>
              {pageSize}&nbsp;
              <i
                className={`${isOpen ? 'bi-chevron-down' : 'bi-chevron-up'}`}
              />
            </div>
            {isOpen && (
              <div
                className='pageSizeDropdown'
                style={{ transform: rowSizeDropdownTransform }}
              >
                <div
                  className='pageSizeOption'
                  onClick={() => pageSize !== 10 && setPageSize(10)}
                >
                  10
                </div>
                {totalData >= 10 && (
                  <div
                    className='pageSizeOption'
                    onClick={() => pageSize !== 20 && setPageSize(20)}
                  >
                    20
                  </div>
                )}
                {totalData >= 20 && (
                  <div
                    className='pageSizeOption'
                    onClick={() => pageSize !== 30 && setPageSize(30)}
                  >
                    30
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div className='dataCountBar'>{displayResultNumber()}</div>
      <div className='d-flex align-items-center paginationPagesSelector'>
        <button
          className='paginationButton'
          disabled={pageNumber == 1 ? true : false}
          onClick={() => setPageNumber(1)}
        >
          <i className='bi bi-chevron-bar-left paginationIcon large' />
        </button>
        <button
          className='paginationButton'
          disabled={pageNumber == 1 ? true : false}
          onClick={() => setPageNumber(Number(pageNumber) - 1)}
        >
          <i className='bi bi-chevron-left paginationIcon small' />
        </button>
        <input
          type='number'
          ref={currentPage}
          defaultValue={currentPage.current}
          onChange={(e) => {
            currentPage.current = e.target.value;
          }}
          onBlur={(e) => handleOnPageInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleOnPageInput(e.target.value);
            }
          }}
          className='paginationInput'
          disabled={totalData <= pageSize ? true : false}
        />
        of {lastPage}
        <button
          className='paginationButton'
          disabled={pageNumber == lastPage ? true : false}
          onClick={() => {
            setPageNumber(Number(pageNumber) + 1);
          }}
        >
          <i className='bi bi-chevron-right paginationIcon small' />
        </button>
        <button
          className='paginationButton'
          disabled={pageNumber == lastPage ? true : false}
          onClick={() => setPageNumber(lastPage)}
        >
          <i className='bi bi-chevron-bar-right paginationIcon large' />
        </button>
      </div>
    </div>
  );
};
