import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import moment from 'moment'
import './commonPixelTable.css'

/** Common table component to show pixel raw data with preview and details mode
 * @param {object} props UI Props
 * @param {array} props.pixel An array of pixel raw data
 * @param {number} props.i Index used as key for component
 * @param {array} props.previewRows An array of data item to show in preview mode
 * @param {array} props.allRows An array of data item to show in show details mode
 */
export const CommonPixelTable = (props) => {
  const { pixel, i, previewRows, allRows } = props
  const date = pixel && pixel.event_timestamp ? moment.unix(pixel.event_timestamp).format('LL') : ''
  const time = pixel && pixel.event_timestamp ? moment.unix(pixel.event_timestamp).format('h:mm:ss a') : ''
  const [showHidden, setShowHidden] = useState(false)

  const toggle = () => {
    setShowHidden(!showHidden)
  }

  const renderPreviewDataRows = () => {
    const rowsToShow = showHidden ? allRows : previewRows
    return (
      <Container fluid>
      {rowsToShow.map((pixelData, i) => (
        <Row key={pixelData[1]} className='pixelRow'>
          <Col xl={3} lg={3} md={3} sm={3} className={`pixelCell pixelCellA ${i === 0 ? 'noTopBorder' : ''}`}>{pixelData[1]}</Col>
          <Col xl={9} lg={9} md={9} sm={9} className={`pixelCell ${i === 0 ? 'noTopBorder' : ''}`}>{pixel[pixelData[0]]}</Col>
        </Row>
      ))}
      </Container>
    )
  }

  return (
    <Row key={i} className='pixelDetailCell'>
      <Col
        xl={2}
        lg={2}
        md={2}
        sm={2}
      >
        <div className='pixelTime'>
          {date}
          <br/>
          {time}
          <br/>
          <a onClick={() => toggle()}>
            {
              showHidden ? 'Hide Details' : 'Show Details'
            }
          </a>
        </div>
      </Col>
      <Col
        xl={10}
        lg={10}
        md={10}
        sm={10}
      >
        <div className='pixelItem'>
          {renderPreviewDataRows()}
        </div>
      </Col>
    </Row>
  )
}

CommonPixelTable.propTypes = {
  pixel: PropTypes.object,
  i: PropTypes.number,
  previewRows: PropTypes.array,
  allRows: PropTypes.array
}
