import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { breadCrumbHeader } from '../../constants';
import {
  CreateReportComponent,
  LatestReports,
  ScheduledReportModal,
} from './components';
import './reports.css';

const Reports = () => {
  document.title = 'My Reports';

  const [showScheduledReportDialog, setShowScheduledReportDialog] =
    useState(false);

  const latestReportRef = useRef();

  const updateLatestReports = () => {
    latestReportRef.current.runLatestReport();
  };

  return (
    <div className='myReportsOuterWrapper'>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <Link to='/'>
            <div className='homeBlue'></div>
          </Link>
          <div className='breadCrumbBody'>
            <span>/</span>
            {breadCrumbHeader.myReports}
          </div>
        </div>
        <div>
          <h1>My Reports</h1>
        </div>
      </div>
      {/* <div style={{ width: '650px' }}>
        <button onClick={() => setShowScheduledReportDialog(true)}>
          <div>Create Scheduled Report</div>
        </button>
      </div> */}
      {/* <ScheduledReportModal
        show={showScheduledReportDialog}
        open={!showScheduledReportDialog}
        setShowScheduledReportDialog={setShowScheduledReportDialog}
      /> */}
      <CreateReportComponent updateLatestReports={updateLatestReports} />
      <LatestReports ref={latestReportRef} />
    </div>
  );
};

export default Reports;
