import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  SearchableDropdownComponent,
  NoDataComponent,
} from '../../../../../../components';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
} from 'recharts';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../../../constants';
import './effectivenessChart.css';
import { getArrayOfData } from '../../../../../../utils/functions';

export const EffectivenessChartAttributionTab = ({
  campaign,
  dateRange,
  mediaPartner,
  placement,
  parametersIsLoading,
  ifSelectedAll,
}) => {
  const [goalList, setGoalList] = useState([]),
    [usableGoalListOpts, setUsableGoalListOpts] = useState([]),
    [selectedGoal, setSelectedGoal] = useState(null);

  const [scatterChartData, setScatterChartData] = useState({
      upperRight: [],
      upperLeft: [],
      lowerRight: [],
      lowerLeft: [],
    }),
    [referenceY, setReferenceY] = useState(null),
    [referenceX, setReferenceX] = useState(null);

  const [cancelController, setCancelController] = useState(null),
    [isError, setIsError] = useState(false),
    [goalListIsLoading, setGoalListIsLoading] = useState(true),
    [isLoading, setIsLoading] = useState(true);

  const getGoalsList = async (controller) => {
    setGoalListIsLoading(true);
    setIsError(false);
    try {
      const response = await axiosSendRequest(
        `${baseEndpoints.reports}/attribution-tab/effectiveness-goal-list`,
        controller.signal,
        {
          campaign_id: campaign?.campaign_id,
          start_date: dateRange[0],
          end_date: dateRange[1],
          new_attribution_projection_enabled:
            campaign?.new_attribution_projection_enabled,
          partner_id: getArrayOfData(mediaPartner, 'partner_id'),
          placement_id: getArrayOfData(placement, 'placement_id'),
        }
      );

      if (response.status == 200) {
        if (response.data.goals && response.data.goals.length > 0) {
          let temp = [];
          response.data.goals.forEach((element) => {
            let obj = {
              goal_id: element.goal_id,
              goal_name: element.goal_name,
              goal_type: element.goal_type,
              placement_list: element.placement_list,
            };
            temp.push(obj);
          });
          setGoalList(temp);
        } else {
          setGoalList([]);
          setIsLoading(false);
        }
      } else {
        setIsError(true);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error.message);
        setIsError(true);
      }
    } finally {
      setTimeout(() => {
        setGoalListIsLoading(false);
      }, 100);
    }
  };

  const getScatterChartData = async (controller) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const response = await axiosSendRequest(
        `${baseEndpoints.reports}/attribution-tab/effectiveness`,
        controller.signal,
        {
          campaign_id: campaign?.campaign_id,
          start_date: dateRange[0],
          end_date: dateRange[1],
          new_attribution_projection_enabled:
            campaign?.new_attribution_projection_enabled,
          goal_name: selectedGoal?.goal_name,
          goals_placement: selectedGoal?.placement_list,
        }
      );

      if (response.status == 200) {
        setScatterChartData({
          upperRight: response.data?.upperRight,
          upperLeft: response.data?.upperLeft,
          lowerRight: response.data?.lowerRight,
          lowerLeft: response.data?.lowerLeft,
        });
        setReferenceX(response.data?.avg[0]?.avg_conv_pc);
        setReferenceY(response.data?.avg[0]?.avg_imp_pc);
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err.message);
        setIsError(true);
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  };

  const resetData = () => {
    setGoalList([]);
    setSelectedGoal(null);
    setUsableGoalListOpts([]);
    setScatterChartData({
      upperRight: [],
      upperLeft: [],
      lowerRight: [],
      lowerLeft: [],
    });
    setReferenceY(null);
    setReferenceX(null);
  };

  useEffect(() => {
    resetData();

    const controller = new AbortController();
    setCancelController(controller);
    if (
      campaign &&
      dateRange[0] &&
      dateRange[1] &&
      !parametersIsLoading.campaign &&
      !parametersIsLoading.mediaPartner &&
      !parametersIsLoading.placement
    ) {
      getGoalsList(controller);
    }
    return () => {
      controller.abort();
    };
  }, [placement]);

  useEffect(() => {
    if (
      campaign &&
      dateRange[0] &&
      dateRange[1] &&
      selectedGoal &&
      !parametersIsLoading.campaign &&
      !parametersIsLoading.mediaPartner &&
      !parametersIsLoading.placement
    ) {
      const controller = new AbortController();
      setCancelController(controller);
      getScatterChartData(controller);
      return () => {
        controller.abort();
      };
    }
  }, [selectedGoal]);

  useEffect(() => {
    const generatedGoalList = generateUsableGoalList(goalList);

    setUsableGoalListOpts(generatedGoalList);

    if (generatedGoalList?.length) {
      setSelectedGoal(generatedGoalList[1]);
    }
  }, [goalList]);

  useEffect(() => {
    if (isError) {
      resetData();
    }
  }, [isError]);

  const renderComponent = () => {
    if (
      isLoading ||
      goalListIsLoading ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    ) {
      return <LoaderComponent />;
    }
    if (!scatterChartData || !goalList || goalList.length <= 0) {
      return (
        <NoDataComponent
          type='scatter'
          customLayerStyle={{ backgroundSize: '40%' }}
          customImageStyle={{ backgroundSize: '20%' }}
        />
      );
    } else {
      return (
        <ResponsiveContainer width='100%' height={600}>
          <ScatterChart
            margin={{
              top: 30,
              bottom: 30,
              right: 70,
              left: 30,
            }}
          >
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              type='number'
              dataKey='conv_pc'
              name='conversion'
              tickCount={10}
              domain={[0, 100]}
              hide={true}
              // unit='%'
              // interval={0}
              // axisLine={false}
              // tickLine={false}
            />
            <YAxis
              type='number'
              dataKey='imp_pc'
              name='impression'
              tickCount={10}
              hide={true}
              // unit='%'
              // axisLine={false}
              // tickLine={false}
            />
            <ReferenceLine
              y={referenceY}
              stroke='#a2a1a2'
              label={{
                position: 'right',
                value: `${referenceY}%`,
                fontSize: '12px',
              }}
            />
            <ReferenceLine
              x={referenceX}
              stroke='#a2a1a2'
              label={{
                position: 'bottom',
                value: `${referenceX}%`,
                fontSize: '12px',
              }}
            />
            <Tooltip
              isAnimationActive={false}
              cursor={false}
              contentStyle={{ outline: 'none', border: 'none' }}
              content={<CustomTooltip x={referenceX} y={referenceY} />}
              wrapperStyle={{
                outline: 'none',
              }}
              formatter={(value) => Intl.NumberFormat('en').format(value)}
            />
            <Scatter
              name='Over-performing on Impressions, under-performing on Engagements / Conversions.'
              data={scatterChartData.upperRight}
              stroke='#a2a1a2'
              fill='transparent'
              r={6}
              strokeWidth={2}
            />
            <Scatter
              name='Over-performing on Impressions and Engagements/Conversions'
              data={scatterChartData.upperLeft}
              stroke='#3297fd'
              fill='transparent'
              r={6}
              strokeWidth={2}
            />
            <Scatter
              name='Under-performing on Impressions and Engagements/Conversions'
              data={scatterChartData.lowerRight}
              stroke='#f56600'
              fill='transparent'
              r={6}
              strokeWidth={2}
            />
            <Scatter
              name='Under-performing on Impressions but over-performing on Engagements/Conversions'
              data={scatterChartData.lowerLeft}
              stroke='#646463'
              fill='transparent'
              r={6}
              strokeWidth={2}
            />
            <Legend
              layout='vertical'
              payload={legendPayload}
              wrapperStyle={{ padding: '30px 0 0 ', fontSize: '14px' }}
            />
          </ScatterChart>
        </ResponsiveContainer>
      );
    }
  };

  return (
    <div className='scatterChart'>
      <CommonHeadingComponent
        headingLabel='Effectiveness'
        headingCTALabel={
          <HeadeingSearchField
            goalList={usableGoalListOpts}
            selectedGoal={selectedGoal}
            setSelectedGoal={setSelectedGoal}
            isLoading={goalListIsLoading}
            ifSelectedAll={ifSelectedAll}
            parametersIsLoading={parametersIsLoading}
          />
        }
        ifDropdown={true}
      />
      <div className='scatterChartContent'>
        {isError ? (
          <ErrorComponent
            refreshAction={getGoalsList}
            controller={cancelController}
          />
        ) : (
          renderComponent()
        )}
      </div>
    </div>
  );
};

const CustomTooltip = ({ payload, x, y }) => {
  const findColor = (payload, x, y) => {
    if (
      payload.length > 0 &&
      Number(payload[0]?.value) > Number(x) &&
      Number(payload[1]?.value) > Number(y)
    ) {
      return 'upperRight';
    }
    if (
      payload.length > 0 &&
      Number(payload[0]?.value) > Number(x) &&
      Number(payload[1]?.value) <= Number(y)
    ) {
      return 'lowerRight';
    }
    if (
      payload.length > 0 &&
      Number(payload[0]?.value) <= Number(x) &&
      Number(payload[1]?.value) > Number(y)
    ) {
      return 'upperLeft';
    }
    return 'lowerLeft';
  };
  return (
    <div className={findColor(payload, x, y)}>
      <div className='tooltipHeaderSC'>
        Placement ID: {payload[0]?.payload?.placement_id}
      </div>
      <div className='tooltipHeaderSC'>Name: {payload[0]?.payload?.name}</div>
      <div>
        <div>{`${payload[0]?.name} : ${payload[0]?.value} %`}</div>
        <div>{`${payload[1]?.name} : ${payload[1]?.value} %`}</div>
      </div>
    </div>
  );
};

const legendPayload = [
  {
    value: 'Over-performing on Impressions and Engagements/Conversions',
    color: '#a2a1a2',
    id: 'SC-CL01',
    type: 'square',
  },
  {
    value:
      'Under-performing on Impressions but over-performing on Engagements/Conversions',
    color: '#f56600',
    id: 'SC-CL02',
    type: 'square',
  },
  {
    value:
      'Over-performing on Impressions, under-performing on Engagements / Conversions',
    color: '#3297fd',
    id: 'SC-CL03',
    type: 'square',
  },
  {
    value: 'Under-performing on Impressions and Engagements/Conversions',
    color: '#646463',
    id: 'SC-CL04',
    type: 'square',
  },
];

const generateUsableGoalList = (goalList) => {
  if (!goalList?.length) {
    return [];
  }

  const engagementOptions = [
      {
        goal_id: -1,
        goal_name: 'Engagement Goals',
        goal_type: 'engagement',
        isParent: true,
        isHeading: true,
      },
    ],
    conversionOptions = [
      {
        goal_id: -2,
        goal_name: 'Conversion Goals',
        goal_type: 'conversion',
        isParent: true,
        isHeading: true,
      },
    ];

  goalList.map((goal) => {
    if (goal.goal_type === 'engagement') {
      engagementOptions.push({ ...goal, isParent: false });
    } else {
      conversionOptions.push({ ...goal, isParent: false });
    }
  });

  const newGoalOptions = [].concat(
    engagementOptions.length > 1 ? engagementOptions : [],
    conversionOptions.length > 1 ? conversionOptions : []
  );

  return newGoalOptions;
};

const determineSummaryHeaderLoading = (parametersIsLoading, ifSelectedAll) => {
  return Object.values(parametersIsLoading).includes(true) || !ifSelectedAll;
};

const HeadeingSearchField = ({
  goalList,
  selectedGoal,
  setSelectedGoal,
  isLoading,
  ifSelectedAll,
  parametersIsLoading,
}) => (
  <SearchableDropdownComponent
    options={goalList}
    label='goal_name'
    id='goal_id'
    dataType='goal'
    dropdownId='effectivenessScatterChartGoal'
    dropdownLabel=''
    labelWidth={{ margin: 0 }}
    dropdownWidth={{
      width: '230px',
    }}
    wrapperClass=''
    placeholderLabel={
      goalList?.length &&
      !isLoading &&
      !determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
        ? 'Select Goal'
        : 'Goal unavailable'
    }
    selectedValue={selectedGoal}
    handleChange={setSelectedGoal}
    isSearchable={false}
    isLoading={
      isLoading ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    }
    isLoadingAvailable={2}
    loaderWidth={{ width: '22px' }}
    isDisabled={
      !goalList?.length ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    }
    isParentChildBasedOption={true}
    parentIdentificationKey='isParent'
    uniqueIdentifier='reportDetailsAttributionEffectivenessGoals'
    ifIdVisableOnLabel={true}
  />
);
