import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  LineChart,
  Line,
} from 'recharts';
import { Container, Row, Col } from 'react-bootstrap';
import {
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  NoDataComponent,
  SearchableDropdownComponent,
} from '../../../../../../components';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../../../constants';
import {
  approximateNumberToMils,
  getArrayOfData,
  formatNumberInThousands,
  getGoalListAndType,
  getAllSelectedOrNot,
} from '../../../../../../utils/functions';
import moment from 'moment';

import '../conversionEngagementSection/dailyEngagementsConversionsReport.css';

const CumulativeGoalEvents = ({
  campaign,
  startDate,
  endDate,
  mediaPartnerList,
  mediaPartner,
  placementList,
  placement,
  creativeTypeList,
  creativeType,
  parametersIsLoading,
  ifSelectedAll,
}) => {
  const [goalData, setGoalData] = useState([]),
    [goalList, setGoalList] = useState(null),
    [usableGoalListOpts, setUsableGoalListOpts] = useState([]),
    [selectedGoal, setSelectedGoal] = useState(null),
    [isInitialLoading, setIsInitialLoading] = useState(true);

  const [isLoadingData, setIsLoadingData] = useState(true),
    [isLoadingList, setIsLoadingList] = useState(true),
    [isError, setIsError] = useState(false),
    [cancelController, setCancelController] = useState(null);

  const getImpressions = async (controller, isFetchingAllData = true) => {
    setIsLoadingData(true);
    if (isFetchingAllData) {
      setIsInitialLoading(true);
      setIsLoadingList(true);
    }
    setIsError(false);
    try {
      const { goal_id, goal_type } = getGoalListAndType(selectedGoal);
      const impressionDataPromise = axiosSendRequest(
        `${baseEndpoints.reports}/attribution-tab/impressions-engagements-all`,
        controller,
        {
          campaign_id: campaign.campaign_id,
          if_all_partners: getAllSelectedOrNot(mediaPartnerList, mediaPartner, 'partner_id'),
          if_all_placements: getAllSelectedOrNot(placementList, placement, 'placement_id'),
          if_all_creative_types: getAllSelectedOrNot(creativeTypeList, creativeType, 'value'),
          partner_id: getArrayOfData(mediaPartner, 'partner_id'),
          placement_id: getArrayOfData(placement, 'placement_id'),
          start_date: startDate,
          end_date: endDate,
          goal_id,
          goal_type,
        }
      );

      let goalListResponse = { status: 200 };

      if (isFetchingAllData) {
        try {
          const goalListPromise = axiosSendRequest(
            `${baseEndpoints.reports}/goal-list`,
            controller,
            {
              campaign_id: campaign.campaign_id,
              placement_id: getArrayOfData(placement, 'placement_id'),
              start_date: startDate,
              end_date: endDate,
            }
          );

          goalListResponse = await goalListPromise;
        } catch(e) {
          goalListResponse = { status: 400 };
          if (!axios.isCancel(e)) {
            console.log(e.message);
          }
        }
      }

      const impressionDataResponse = await impressionDataPromise;

      if (
        impressionDataResponse.status === 200 &&
        goalListResponse.status === 200
      ) {
        const impressionData = impressionDataResponse.data?.impressions;

        if (isFetchingAllData) {
          setGoalList(goalListResponse.data);
        }

        setGoalData(calcImpressions(impressionData, startDate, endDate));
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err.message);
      }
    } finally {
      setTimeout(() => {
        setIsLoadingData(false);
        setIsLoadingList(false);
      }, 100);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setCancelController(controller);
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      resetData();
    }

    if (
      !parametersIsLoading.campaign &&
      !parametersIsLoading.mediaPartner &&
      !parametersIsLoading.creativeType &&
      !parametersIsLoading.placement &&
      startDate &&
      endDate
    ) {
      getImpressions(cancelController);
    }
  }, [startDate, endDate, placement]);

  useEffect(() => {
    const generatedGoalList = generateUsableGoalList(goalList);

    setUsableGoalListOpts(generatedGoalList);

    if (generatedGoalList?.length) {
      setSelectedGoal(generatedGoalList[0]);
    }
  }, [goalList]);

  useEffect(() => {
    if (selectedGoal) {
      if (!isInitialLoading) {
        getImpressions(cancelController, false);
      } else {
        setIsInitialLoading(false);
      }
    }
  }, [selectedGoal]);

  useEffect(() => {
    if (isError) {
      resetData();
    }
  }, [isError]);

  const resetData = () => {
    setGoalData([]);
    setGoalList(null);
    setSelectedGoal(null);
    setUsableGoalListOpts([]);
    setIsInitialLoading(null);
  };

  return (
    <div className='cumulativeGoalEventsWrapper'>
      <CommonHeadingComponent
        headingLabel='Cumulative Goal Events'
        headingCTALabel={
          <HeadeingSearchField
            goalList={usableGoalListOpts}
            selectedGoal={selectedGoal}
            setSelectedGoal={setSelectedGoal}
            isLoading={isLoadingList}
            ifSelectedAll={ifSelectedAll}
            parametersIsLoading={parametersIsLoading}
          />
        }
        ifDropdown={true}
      />
      <Container fluid>
        <RenderComponent
          goalData={goalData}
          goalType={selectedGoal?.type}
          isLoading={isLoadingData}
          parametersIsLoading={parametersIsLoading}
          ifSelectedAll={ifSelectedAll}
          isError={isError}
          getImpressions={getImpressions}
          cancelController={cancelController}
        />
      </Container>
    </div>
  );
};

export default CumulativeGoalEvents;

const generateUsableGoalList = (goalList) => {
  if (!goalList?.length) {
    return [];
  }

  const allGoalOption = [
      { id: -1, name: 'All Goals', type: null, isParent: true },
    ],
    engagementOptions = [
      {
        id: -2,
        name: 'Engagement Goals',
        type: 'engagement',
        isParent: true,
      },
    ],
    conversionOptions = [
      {
        id: -3,
        name: 'Conversion Goals',
        type: 'conversion',
        isParent: true,
      },
    ];

  goalList.map((goal) => {
    if (goal.type === 'engagement') {
      engagementOptions.push({ ...goal, isParent: false });
    } else {
      conversionOptions.push({ ...goal, isParent: false });
    }
  });

  const newGoalOptions = allGoalOption.concat(
    engagementOptions.length > 1 ? engagementOptions : [],
    conversionOptions.length > 1 ? conversionOptions : []
  );

  if (newGoalOptions.length > 1) {
    return newGoalOptions;
  }

  return [];
};

const CustomTooltip = ({ payload, goalType }) => {
  let impressionColor;
  let engagementColor;
  let conversionColor;
  payload.forEach((item) => {
    if (item.name === 'Impressions') {
      impressionColor = item.color;
    } else if (item.name === 'Engagements') {
      engagementColor = item.color;
    } else {
      conversionColor = item.color;
    }
  });
  return (
    <div className='customChartTooltipWrapper'>
      <div>{payload[0]?.payload?.name}</div>
      <br />
      <div>
        <div>Total</div>
        <div
          style={{ color: impressionColor }}
        >{`Impressions: ${formatNumberInThousands(
          payload[0]?.payload?.Impressions
        )}`}</div>
        {goalType !== 'conversion' && (
          <div
            style={{ color: engagementColor }}
          >{`Engagements: ${formatNumberInThousands(
            payload[1]?.payload?.Engagements
          )}`}</div>
        )}
        {goalType !== 'engagement' && (
          <div
            style={{ color: conversionColor }}
          >{`Conversions: ${formatNumberInThousands(
            payload[1]?.payload?.Conversions
          )}`}</div>
        )}
        <hr />
        <div>Daily</div>
        <div
          style={{ color: impressionColor }}
        >{`Impressions: + ${formatNumberInThousands(
          payload[0]?.payload?.ImpressionsDaily
        )}`}</div>
        {goalType !== 'conversion' && (
          <div
            style={{ color: engagementColor }}
          >{`Engagements: + ${formatNumberInThousands(
            payload[1]?.payload?.EngagementsDaily
          )}`}</div>
        )}
        {goalType !== 'engagement' && (
          <div
            style={{ color: conversionColor }}
          >{`Conversions: + ${formatNumberInThousands(
            payload[1]?.payload?.ConversionsDaily
          )}`}</div>
        )}
      </div>
    </div>
  );
};

const calcImpressions = (data, startDate, endDate) => {
  let start_date = startDate.substr(0, 10);
  let end_date = endDate.substr(0, 10);
  let tmp = [];
  if (data?.length) {
    tmp = data.map((element) => {
      return {
        date: element.date.substr(0, 10),
        name: moment(element.date.substr(0, 10)).format('MM/DD/YYYY'),
        ImpressionsDaily: parseInt(element.impressions),
        EngagementsDaily: Math.round(parseFloat(element.engagements)),
        ConversionsDaily: Math.round(parseFloat(element.conversions)),
        Impressions: parseInt(element.impressions),
        Engagements: parseFloat(element.engagements),
        Conversions: parseFloat(element.conversions),
      };
    });
    tmp.forEach((element, index, array) => {
      if (index > 0) {
        element['Impressions'] += array[index - 1]['Impressions'];
        element['Engagements'] += array[index - 1]['Engagements'];
        element['Conversions'] += array[index - 1]['Conversions'];
      }
    });
    tmp = tmp.filter((item) => item.date >= start_date && item.date <= end_date);
    tmp = tmp.map((item) => {
      const tempEngagements = Math.round(item['Engagements']),
        tempConversions = Math.round(item['Conversions']);

      return {
        ...item,
        Engagements: tempEngagements,
        Conversions: tempConversions,
      };
    });
  }
  return tmp;
};

const determineSummaryHeaderLoading = (parametersIsLoading, ifSelectedAll) => {
  return Object.values(parametersIsLoading).includes(true) || !ifSelectedAll;
};

const RenderComponent = ({
  goalData,
  goalType,
  isLoading,
  parametersIsLoading,
  ifSelectedAll,
  isError,
  getImpressions,
  cancelController,
}) => {
  if (
    isLoading ||
    determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
  ) {
    return (
      <Row>
        <Col>
          <LoaderComponent headerClass='cumulativeGoalEventsLoaderWrapper' />
        </Col>
      </Row>
    );
  }

  if (isError) {
    return (
      <Row>
        <Col style={{ height: '20.5em' }}>
          <ErrorComponent
            refreshAction={getImpressions}
            controller={cancelController}
          />
        </Col>
      </Row>
    );
  }

  if (!goalData?.length) {
    return (
      <Row>
        <Col>
          <NoDataComponent
            type='line'
            width='180'
            height='180'
            messageTextFontSize={15}
            foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
            messageText='No Data Available'
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col className='attrCumulativeGoalsGraphWrapper'>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            width={550}
            height={700}
            data={goalData}
            margin={{
              top: 30,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray='3 3' />
            <XAxis dataKey='name' minTickGap={10} tickCount={5} />
            <YAxis
              yAxisId='left'
              tickCount={10}
              tickLine={false}
              axisLine={false}
              domain={[0, (dataMax) => {
                if (!dataMax) {
                  return Math.ceil((1 * 1.5) / 100) * 100;
                }
                return Math.ceil((dataMax * 1.5) / 100) * 100;  
              }]}
              tickFormatter={approximateNumberToMils}
              width={80}
            />
            <YAxis
              yAxisId='right'
              orientation='right'
              tickCount={10}
              tickLine={false}
              axisLine={false}
              domain={[0, (dataMax) => {
                if (!dataMax) {
                  return Math.ceil((1 * 1.5) / 100) * 100;
                }
                return Math.ceil((dataMax * 1.5) / 100) * 100;
              }]}
              tickFormatter={approximateNumberToMils}
            />
            <Tooltip content={<CustomTooltip goalType={goalType} />} />
            <Line
              type='linear'
              legendType='square'
              activeDot={true}
              label={false}
              dataKey='Impressions'
              stroke='#271350'
              strokeWidth={2}
              strokeOpacity={1}
              dot={false}
              strokeDasharray='4 2 1'
              yAxisId='left'
            />
            {goalType !== 'conversion' && (
              <Line
                type='linear'
                legendType='square'
                activeDot={true}
                label={false}
                dataKey='Engagements'
                stroke='#03bdc9'
                strokeWidth={1}
                dot={false}
                yAxisId='right'
              />
            )}
            {goalType !== 'engagement' && (
              <Line
                type='linear'
                legendType='square'
                activeDot={true}
                label={false}
                dataKey='Conversions'
                stroke='#ff7c1f'
                strokeWidth={1}
                dot={false}
                yAxisId='right'
              />
            )}

            <Legend height={36} />
          </LineChart>
        </ResponsiveContainer>
      </Col>
    </Row>
  );
};

const HeadeingSearchField = ({
  goalList,
  selectedGoal,
  setSelectedGoal,
  isLoading,
  ifSelectedAll,
  parametersIsLoading,
}) => (
  <SearchableDropdownComponent
    options={goalList}
    label='name'
    id='id'
    dataType='goal'
    dropdownId='cumulativeGoalEvent'
    dropdownLabel=''
    labelWidth={{ margin: 0 }}
    dropdownWidth={{
      width: '200px',
    }}
    wrapperClass=''
    placeholderLabel={goalList?.length ? 'Select Goal' : 'Goal unavailable'}
    selectedValue={selectedGoal}
    handleChange={setSelectedGoal}
    isSearchable={false}
    isLoading={
      isLoading ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    }
    isLoadingAvailable={2}
    loaderWidth={{ width: '22px' }}
    isDisabled={
      !goalList?.length ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    }
    isParentChildBasedOption={true}
    parentIdentificationKey='isParent'
    uniqueIdentifier='cumulativeGoalEvents'
    ifIdVisableOnLabel={true}
  />
);
