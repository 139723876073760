import TreeState from '../model/tree-state';
import Row from '../model/row';


export const createRow = (model, source, onChangeCb) => {
  let rowAPI = {
    toggleChildren: () => {
      const nextState = TreeState.toggleChildren(source, model);
      onChangeCb(nextState);
    },
    updateData: (newData) => {
      const nextState = TreeState.updateData(source, model, newData);
      onChangeCb(nextState);
    }
  };
  return new Row(model, rowAPI);
}
