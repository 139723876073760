import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import DailyImpressionsReport from './DailyImpressionsReport.component';
import { usePostAPIInterceptor } from '../../../../../../hooks/postAPIInterceptor';
import { baseEndpoints } from '../../../../../../constants';
import { CommonHeadingComponent } from '../../../../../../components';
import CumulativeImpressionsReport from './CumulativeImpressionsReport.component';
import { dateFormat } from '../../../../../../utils/functions';

export default function ImpressionsReport({ campaign, isLoading, startDate, endDate, haveSetDates, placement, noData = false }) {

  const [dailyImpressionsData, setDailyImpressionsData] = React.useState([]);
  const [cumulativeImpressionsData, setCumulativeImpressionsData] = React.useState([]);
  const [isDailyImpressionLoading, setIsDailyImpressionLoading] = React.useState(true);
  const [isCumulativeImpressionLoading, setIsCumulativeImpressionLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [status, setStatus] = React.useState(200);
  const [dailyImpressionGrouping, setDailyImpressionGrouping] = React.useState('1');
  const [saveMount, setSaveMount] = React.useState(false);
  const [payload, setPayload] = React.useState({});
  const [impressionsData, setImpressionsData] = React.useState([]);

  React.useEffect(() => {
    const controller = new AbortController();
    if (!isLoading.campaign && haveSetDates) {
      getImpressions();
    }
    return () => {
      controller.abort();
    };
  }, [campaign?.campaign_id, startDate, endDate, isLoading.campaign, haveSetDates, placement]);

  React.useEffect(() => {
    if (impressionsData) {
      let tmp = calcDailyImpressions(impressionsData);
      setDailyImpressionsData(tmp);
    }
  }, [dailyImpressionGrouping]);

  usePostAPIInterceptor(saveMount, payload, `${baseEndpoints.reports}/media-delivery-tab/impressions-all`, (status, data) => handleInterceptorResponse(status, data));

  const getImpressions = () => {
    setIsError(false);
    try {
      if (campaign, startDate, endDate, placement) {
        setIsDailyImpressionLoading(true);
        setIsCumulativeImpressionLoading(true);
        const placementIds = placement?.map(placementObject => placementObject.placement_id);
        setSaveMount(true);
        setPayload({
          campaign_id: campaign.campaign_id,
          start_date: dateFormat(startDate, 'YYYY-MM-DD') ,
          end_date: dateFormat(endDate, 'YYYY-MM-DD'),
          placement_ids: placementIds
        });
      } else if (!isLoading.campaign && haveSetDates) {
        setDailyImpressionsData(null);
        setCumulativeImpressionsData(null);
        setIsDailyImpressionLoading(false);
        setIsCumulativeImpressionLoading(false);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        if (err.response) {
          setStatus(err.response.status);
        } else {
          setStatus(500);
        }
        setIsError(true);
        setIsDailyImpressionLoading(false);
        setIsCumulativeImpressionLoading(false);
      }
    }
  };

  const handleInterceptorResponse = (status, data) => {
    const impressionsDataResponse = data?.data?.impressions;
    setStatus(status);
    if (status === 200 && impressionsDataResponse) {
      setImpressionsData(impressionsDataResponse);
      let tmp = calcDailyImpressions(impressionsDataResponse);
      setDailyImpressionsData(tmp);
      let cumulativeTemp = calcCumulativeImpressions(impressionsDataResponse);
      setCumulativeImpressionsData(cumulativeTemp);
    } else {
      setIsError(true);
    }
    setIsDailyImpressionLoading(false);
    setIsCumulativeImpressionLoading(false);
  };

  const calcDailyImpressions = (data) => {
    let tmp = [];
    if (data?.length) {
      tmp = data.map((element) => {
        const ivt = parseInt(element.ivt),
          nonIvt = parseInt(element.nonIvt);
        return {
          'date': element.date.substr(0, 10),
          'name': moment(element.date.slice(0, element.date.length - 1)).format('MM/DD/YYYY'),
          'nonIVT': nonIvt,
          'ivt': ivt,
          'Net Impressions': nonIvt,
          'IVT': ivt,
        };
      });
      tmp = tmp.filter(
        (item) => item.date >= startDate && item.date <= endDate
      );
      let dataGrouped;
      switch (dailyImpressionGrouping) {
        case '1':
          break;
        case '2':
          dataGrouped = tmp.reduce((acc, item) => {
            // create a composed key: 'year-week'
            const yearWeek = `${moment(item.name)
              .startOf('week')
              .format('MM/DD/YYYY')} - ${moment(item.name)
              .endOf('week')
              .format('MM/DD/YYYY')}`;

            // add this key as a property to the result object
            if (!acc[yearWeek]) {
              acc[yearWeek] = {
                'Net Impressions': 0,
                'IVT': 0,
                'name': yearWeek,
              };
            }

            // push the current date that belongs to the year-week calculated befor
            acc[yearWeek]['Net Impressions'] += item['Net Impressions'];
            acc[yearWeek]['IVT'] += item['IVT'];

            return acc;
          }, {});
          tmp = [];
          for (let key in dataGrouped) {
            tmp.push(dataGrouped[key]);
          }
          break;
        case '3':
          dataGrouped = tmp.reduce((acc, item) => {
            // create a composed key: 'year-week'
            const yearMonth = `${moment(item.name)
              .month('week')
              .format('MM/YYYY')}`;

            // add this key as a property to the result object
            if (!acc[yearMonth]) {
              acc[yearMonth] = {
                'Net Impressions': 0,
                'IVT': 0,
                'name': yearMonth,
              };
            }

            // push the current date that belongs to the year-week calculated befor
            acc[yearMonth]['Net Impressions'] += item['Net Impressions'];
            acc[yearMonth]['IVT'] += item['IVT'];

            return acc;
          }, {});
          tmp = [];
          for (let key in dataGrouped) {
            tmp.push(dataGrouped[key]);
          }
          break;
      }
      if (tmp.length > 10) {
        tmp = tmp.slice(tmp.length - 10, tmp.length);
      }
      return tmp;
    }
  };

  const calcCumulativeImpressions = (data) => {
    let start_date = startDate.substr(0, 10);
    let end_date = endDate.substr(0, 10);
    let tmp = [];
    if (data?.length) {
      tmp = data.map((element) => {
        return {
          date: element.date.substr(0, 10),
          name: moment(element.date.slice(0, element.date.length - 1)).format('MM/DD/YYYY'),
          nonIVT: parseInt(element.nonIvt),
          ivt: parseInt(element.ivt),
          'Net Impressions': parseInt(element.nonIvt),
          IVT: parseInt(element.ivt),
        };
      });
      tmp.forEach((element, index, array) => {
        if (index > 0) {
          element['Net Impressions'] += array[index - 1]['Net Impressions'];
          element['IVT'] += array[index - 1]['IVT'];
        }
      });
      tmp = tmp.filter(
        (item) => item.date >= start_date && item.date <= end_date
      );

      return tmp;
    }
  };

  return (
    <>
      <CommonHeadingComponent headingLabel="Impressions" />
      <Container fluid>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <DailyImpressionsReport
              data={dailyImpressionsData}
              isLoading={isDailyImpressionLoading}
              isError={isError}
              status={status}
              getImpressions={() => getImpressions()}
              handleGroupingChanged={(val) => {
                setDailyImpressionGrouping(val.target.value);
              }}
              noData={noData}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <CumulativeImpressionsReport
              data={cumulativeImpressionsData}
              isLoading={isCumulativeImpressionLoading}
              isError={isError}
              status={status}
              getImpressions={() => getImpressions()}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}></Col>
          <Col lg={6} md={6} sm={12}></Col>
        </Row>
      </Container>
    </>
  );
}
