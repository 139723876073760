import React, { Component } from 'react';


export default class CellWrapper extends Component {

  render() {
    const { row, renderCell } = this.props;

    return (
      <td className={`cp_tree-table_cell`}>
        { renderCell(row) }
      </td>
    );
  }
}


const STYLE_COLUMN = {
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
};
