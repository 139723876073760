import React from "react";

//RSS redirect modal update with the specific details needed
const DupNameBodyJSX = ({dupNameModalData}) => {
  const { suggestedName } = dupNameModalData;
  return (
    <div className="mt-4 mb-4 dup-name-modal">
      <div>Selected Placement Name already exists</div>
      <div className="mt-3 mb-3">
        Click on Create to select the suggested Placement Name:
        <span className="link-text">{suggestedName}</span>
      </div>
      <div>Click on Cancel to manually modify the Placement Name.</div>
    </div>
  );
};

export default DupNameBodyJSX;
