import React, { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { CommonButtonComponent, LoaderComponent } from '../';
import { baseEndpoints } from '../../constants';
import { checkCondition, exportCSV, exportTxt } from '../../utils/functions';
import { useGetApiInterceptor } from '../../hooks/useGetApiInterceptor';

import './exportFiles.css';

const ExportFilesComponent = ({
  baseEndpoint,
  urlExtension,
  fileType,
  fileName,
  ctaLabel,
  messageLabel,
  parentClass,
  typeOfData = null,
  buttonUI,
  headers = null,
  goalsReload = false,
  setGoalsReload = () => {},
}) => {
  const url = `${baseEndpoints[baseEndpoint]}${
    urlExtension ? '/' + urlExtension : ''
  }`;

  const [ifInitiallyErrorOccurred, setIfInitiallyErrorOccurred] =
      useState(false),
    [isMounted, setIsMounted] = useState(true);

  const { data, isLoading, isError } = useGetApiInterceptor(isMounted, null, url);

  useEffect(() => {
    // trigger reload of export image/js tracking tag buttons to refresh goals when goal added/edited
    if (goalsReload) {
      setIsMounted(true);
      setGoalsReload(false);
    }
  }, [goalsReload]);

  useEffect(() => {
    if (!isLoading) {
      setIsMounted(false);
      if (ifInitiallyErrorOccurred) {
        if (isError) {
          getErrorOrWarningNotification('error');
          return;
        }

        setIfInitiallyErrorOccurred(false);

        if (data && data.length > 0) {
          getDataByFileType(fileType, data, fileName, typeOfData);
          return;
        }

        getErrorOrWarningNotification('warning', messageLabel);
      }
    }
  }, [isLoading]);

  const handleExportFile = () => {
    if (isError) {
      setIfInitiallyErrorOccurred(true);
      setIsMounted(true);
      return;
    }

    getDataByFileType(fileType, data, fileName, typeOfData, buttonUI);
  };

  // For status ticks
  /** const getLoaderOrSigns = () => {
    if (isLoading) {
      return <LoaderComponent importedClassNames='downloadFilesButtonLoader' />;
    }

    if (isError) {
      return (
        <div
          className='errorStatusDC'
          onMouseEnter={OnHoverStatus}
          onMouseLeave={onLeaveStatus}
        ></div>
      );
    }

    if (data && data.length > 0) {
      return (
        <div
          className='safeStatusDC'
          onMouseEnter={OnHoverStatus}
          onMouseLeave={onLeaveStatus}
        ></div>
      );
    }

    return (
      <div
        className='warningStatusDC'
        onMouseEnter={OnHoverStatus}
        onMouseLeave={onLeaveStatus}
      ></div>
    );
  };

  const OnHoverStatus = () => {
    const element = document.querySelector(
      `.${parentClass} .downloadTagsWrapper .downloadStatus .statusTooltip`
    );

    if (!element.className.includes('activeStatus')) {
      element.classList.add('activeStatus');
    }
  };

  const onLeaveStatus = () => {
    const element = document.querySelector(
      `.${parentClass} .downloadTagsWrapper .downloadStatus .statusTooltip`
    );

    if (element.className.includes('activeStatus')) {
      element.classList.remove('activeStatus');
    }
  };*/

  return (
    <div className='downloadTagsWrapper'>
      <CommonButtonComponent
        label={checkCondition(
          !isLoading,
          ctaLabel,
          <LoaderComponent importedClassNames='downloadFilesButtonLoader' />
        )}
        appendWrapperClassName='downloadTagsBtnWrapper'
        appendButtonClassName='downloadTagsBtnBody'
        onClickCTA={() => handleExportFile()}
        isDisabled={isLoading || (!isError && !(data && data.length > 0))}
        buttonUI={buttonUI}
      />
      {/* <div className='downloadStatus'> For Status Ticks
        <StatusTooltip
          status={getStatus(data, isError)}
          messageLabel={messageLabel}
        />
        {getLoaderOrSigns()}
      </div> */}
    </div>
  );
};

export default ExportFilesComponent;

const getDataByFileType = (fileType, data, fileName, typeOfData, buttonUI, headers) => {
  switch (fileType) {
    case 'csv':
      exportCSV(data, fileName, buttonUI, headers);
      break;
    case 'txt':
      exportTxt(data, fileName, typeOfData);
      break;
    default:
    // No code needed
  }
};

const getErrorOrWarningNotification = (type, messageLabel = null) => {
  Store.addNotification({
    title: 'Download failed',
    message:
      type === 'error'
        ? 'Unable to download. Please try download again or refresh the page.'
        : `${messageLabel} data is not available.`,
    type: type === 'error' ? 'danger' : 'warning',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: true,
    },
  });
};

// For Status ticks
/**const StatusTooltip = ({ status, messageLabel }) => {
  return (
    <div className={`statusTooltip ${status}`}>
      <div className='statusTooltipBody'>
        {getMessages(status, messageLabel)}
      </div>
    </div>
  );
};

const getStatus = (data, error) => {
  if (error) {
    return 'error';
  }

  if (data && data.length > 0) {
    return 'safe';
  }

  return 'warning';
};*/

const getMessages = (status, messageLabel) => {
  if (status === 'error') {
    return `${messageLabel} data has not ready yet. Downloading may takes longer than usual.`;
  }

  if (status === 'warning') {
    return `${messageLabel} data is not available.`;
  }

  return `${messageLabel} data is ready for download.`;
};
