import React, { useEffect, useRef, useState } from "react";
import { axiosPostRequest } from "../../services/http/axiosCalls";
import { CommonInput, CommonButtonComponent, LoaderComponent } from "../../components";
import "./ResetPassword.css";
import { commonNotification } from "../../utils/common-notifications";
import endpoints from "../../constants/endpoints";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const ResetPassword = () => {
  const [username, setUsername] = useState('');
  const [showGetCode, setShowGetCode] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [notARobot, setNotARobot] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [inProgress, setInProgress] = useState(false)
  const captchaResponse = useRef();
  const navigate = useNavigate();

  const handleRecaptchaChange = (value) => {
    setNotARobot(value !== null);
  };

  const passwordCheck = (password, secondPassword) => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (password !== secondPassword) {
      return 'The Passwords Must Match';
    }
    let pasArr = [];
    if (password.length < 8){
      pasArr.push('8 Characters')
    }
    if (password === password.toLocaleLowerCase()) {
      pasArr.push("an Uppercase Letter");
    }
    if (!password.match(/[0-9]/)){
      pasArr.push('a Number');
    }
    if (!format.test(password.trim())) {
      pasArr.push('a Special Character');
    }
    let strToReturn;
    if (pasArr.length > 0) {
      strToReturn = 'Password Must Contain ';
      if (pasArr.length === 1) {
        return pasArr[0];
      } else if (pasArr.length < 3) {
        strToReturn += pasArr.join(' And ');
      } else {
        strToReturn += pasArr.join(', ');
        const lastComma = strToReturn.lastIndexOf(',');
        strToReturn = strToReturn.substring(0, lastComma + 1) + ' And ' + strToReturn.substring(lastComma + 1);
      }
    }
    
    
    return strToReturn;
  }

  useEffect(() => {
    if (newPassword && confirmPassword) {
      const passwordCheckStr = passwordCheck(newPassword, confirmPassword);
      setPasswordError(passwordCheckStr);
      if (
        newPassword &&
        confirmPassword &&
        verificationCode &&
        !passwordCheckStr
      ) {
        setAllowSubmit(false);
      } else {
        setAllowSubmit(true);
      }
    }
  }, [newPassword, confirmPassword, verificationCode, showGetCode])
  

  const submitForVerCode = async (event) => {
    event.preventDefault();

    try {
      setInProgress(true)
      if (!username) {
        commonNotification({
          msg: "Username Field Must Be Filled",
          type: "danger",
        });
       } else if (!captchaResponse.current.getValue()) {
        commonNotification({
          msg: "You Must Verify You Are Not a Robot",
          type: "danger",
        });
       } else {
        const verCodeRequest = await axiosPostRequest(
          endpoints.authResetPasswordVerificationCode,
          { username, captchaResponseToken: captchaResponse.current.getValue() }
        );

        if (verCodeRequest.status === 200) {
          commonNotification({
            msg: verCodeRequest.message,
            type: "success",
          });
          setTimeout(() => {
            setShowGetCode(false);
          }, 2000);
        } else {
          commonNotification({
            msg: verCodeRequest.error,
            type: "danger",
          });
        }
      }
    } catch (err) {
      if (err.response.data) {
        commonNotification({ msg: err.response.data.error, type: "danger" });
      } else {
        commonNotification({ msg: 'Something Went Wrong', type: 'danger' });
      }
      
    } finally {
      setInProgress(false)
    }
  };

  const submitNewPassword = async (event) => {
    event.preventDefault();

    try {
      if (!verificationCode || !newPassword || !confirmPassword) {
        commonNotification({
          msg: "All Fields Must Be Filled Out",
          type: "danger",
        });
      } else if (newPassword !== confirmPassword) {
        commonNotification({ msg: 'Passwords Must Match', type: 'danger' })
      } else {

        const confirmPasswordData = {
            username,
            verificationCode,
            newPassword,
        }

        const confirmPasswordRequest = await axiosPostRequest(endpoints.authResetPasswordConfirmPassword, confirmPasswordData);
        
        if (confirmPasswordRequest.status === 200) {
            commonNotification({
              msg: confirmPasswordRequest.message,
              type: "success",
            });
            setTimeout(() => {
                navigate('/');
            }, 2000)
        } else {
            commonNotification({ msg: confirmPasswordRequest.error, type: 'danger' });
        }
      }
    } catch (err) {
      commonNotification({ msg: err.response.data.error, type: "danger" });
    }
  };

  const getCode = (
    <React.Fragment>
      <p className="reset-password-p reset-password-form-element">
        We'll Send You a Code To Reset Your Password
      </p>
      <div className="reset-password-form-email reset-password-form-element">
        <CommonInput
          placeholder="Enter Your Email"
          inputValue={username}
          setInputValue={setUsername}
        />
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        ref={captchaResponse}
        className="reset-password-form-recaptcha reset-password-form-element"
      />
      <CommonButtonComponent
        label={!inProgress?"Sign In": <LoaderComponent importedClassNames='buttonLoader' />}
        appendWrapperClassName="reset-password-button-wrapper reset-password-form-element"
        appendButtonClassName="reset-password-button"
        onClickCTA={submitForVerCode}
        isDisabled={inProgress}
      />
    </React.Fragment>
  );

  const setPassword = (
    <React.Fragment>
      {passwordError ? (
        <p className="reset-password-p reset-password-password-error">
          {passwordError}
        </p>
      ) : null}
      <h6 className="reset-password-input-title reset-password-form-element">
        New Password
      </h6>
      <div className="reset-password-form-new-password reset-password-form-input">
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="utilitySearch"
        />
      </div>
      <h6 className="reset-password-input-title reset-password-form-element">
        Confirm Password
      </h6>
      <div className="reset-password-form-confirm-password reset-password-form-input">
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="utilitySearch"
        />
      </div>
      <h6 className="reset-password-input-title reset-password-form-element">
        Verification Code
      </h6>
      <div className="reset-password-form-ver-code reset-password-form-input">
        <input
          type="number"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          className="utilitySearch"
        />
      </div>
      <CommonButtonComponent
        label="Submit"
        appendWrapperClassName="reset-password-button-wrapper reset-password-form-element"
        appendButtonClassName="reset-password-button"
        isDisabled={allowSubmit}
        onClickCTA={submitNewPassword}
      />
    </React.Fragment>
  );

  return (
    <div className="reset-password breadCrumbOuterWrapper">
      <div className="reset-password-wrapper breadCrumbInnerWrapper">
        <div className="reset-password-form">
          <div className="reset-password-logo reset-password-form-element" />
          <h2 className="reset-password-form-header reset-password-form-element">
            Reset Password
          </h2>
          <form className="reset-password-form-inputs">
            {showGetCode ? getCode : setPassword}
          </form>
          <div className="reset-password-links reset-password-form-element">
            {!showGetCode ? (
              <a href="#" onClick={(e) => {
                e.preventDefault(); 
                setShowGetCode(true)
                }}>
                Back
              </a>
            ) : null}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
              className="reset-password-link"
            >
              Login Page
            </a>
          </div>
        </div>
        <div className="reset-password-info reset-password-wrapper-element">
          <p className="reset-password-privacy reset-password-p">Privacy Policy - Terms Of Use</p>
          <div className="reset-password-claritas-logo" />
          <p className="reset-password-copyright reset-password-p">
            Copyright &copy; Claritas, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
