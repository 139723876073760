import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useGetApiInterceptor } from '../../hooks/useGetApiInterceptor';
import { breadCrumbHeader, baseEndpoints } from "../../constants";
import { ExportFilesComponent, CommonHeadingComponent } from "../../components";
import { getEncryptedOrDecryptedData, toggleClasses } from "../../utils/functions";
import { resetAdvertiserMode, updateAdvertiserMode } from "../../redux/utilitybarSlice";
import CreateProperties from '../CreateProperties/CreateProperties';
import { GoalsDataGrid, PixelSummaryData, PixelRawData } from "./components";
import "./propertyDetails.css";

export const PropertyDetails = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [params, setParams] = useState(null);
  const [endpoint, setEndpoint] = useState(null);
  const [goalsReload, setGoalsReload] = useState(false);

  const navigate = useNavigate();

	const client = useSelector((state) => state.client); // listener to client change in global header
  const { id } = useParams();

  const propertyData = useGetApiInterceptor(isMounted, params, endpoint);

  const [expandOrCollapse, setExpandOrCollapse] = useState(false);

  const [searchParams] = useSearchParams(),
    dataParams = searchParams.get('params'),
    { advertiserId, paramClientId, propertyName } = JSON.parse(
      getEncryptedOrDecryptedData(dataParams, 'decrypt')
    );
    
  document.title = "Property";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateAdvertiserMode(true)); //disabling advertiser dropdown selector

    return () => {
      dispatch(resetAdvertiserMode());
    };
  }, []);

  useEffect(() => {
    if (client && client.selectedClient === paramClientId) {
      setParams({
        user_id: client.selectedClient,
        advertiser_id: advertiserId,
      });
      setEndpoint(`${baseEndpoints.properties}/${id}`);
      setIsMounted(true);
      return;
    }
    navigate('/properties');
  }, [client]);

  return (
    <div className="propertyDetailsWrapper">
      <div className="breadCrumbOuterWrapper">
        <div className="breadCrumbInnerWrapper">
          <Link to="/">
            <div className="homeBlue"></div>
          </Link>
          <div className="breadCrumbBody">
            /
            <Link to="/properties">
              <div className="active">{breadCrumbHeader.properties}</div>
            </Link>
          </div>
          <div className='breadCrumbBody'>
            <span>/</span>
            {propertyName}
          </div>
        </div>
        <div className="propertyNameWrapper">
          <h1>{propertyName}</h1>
          <div className="propertyDownloadTagsWrapper">
            <div className="downloadImageTrackingTags">
              <ExportFilesComponent
                baseEndpoint="properties"
                urlExtension={`${id}/image-tracking-tags`}
                fileType="txt"
                fileName={`${propertyName}_image_tracking_tags`}
                ctaLabel="Download Image Tracking Tags"
                messageLabel="Image tracking tags"
                parentClass="downloadImageTrackingTags"
                typeOfData="image"
                buttonUI='downloadImageTrackingTags'
                goalsReload={goalsReload}
                setGoalsReload={setGoalsReload}
              />
            </div>
            <div className="downloadJsTrackingTags">
              <ExportFilesComponent
                baseEndpoint="properties"
                urlExtension={`${id}/js-tracking-tags`}
                fileType="txt"
                fileName={`${propertyName}_js_tracking_tags`}
                ctaLabel="Download JS Tracking Tags"
                messageLabel="JS tracking tags"
                parentClass="downloadJsTrackingTags"
                typeOfData="js"
                buttonUI='downloadJsTrackingTags'
                goalsReload={goalsReload}
                setGoalsReload={setGoalsReload}
              />
            </div>
          </div>
        </div>
      </div>
      <Container fluid className='propertyInnerWrapper'>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div
              className={toggleClasses(
                expandOrCollapse,
                "editPropertyWrapper",
                "editPropertyWrapper expand"
              )}
            >
              <CommonHeadingComponent
                headingLabel="Edit Property"
                headingCTALabel={!expandOrCollapse ? "COLLAPSE" : "EXPAND"}
                isDisabled={false}
                onClickCTA={() => setExpandOrCollapse(!expandOrCollapse)}
                uniqueIdentifier='editPropertyCollapsable'
              />
              {!expandOrCollapse &&
                <CreateProperties propertyId={id} editProperty propertyData={propertyData} />
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <GoalsDataGrid propertyId={id} setGoalsReload={setGoalsReload} />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <PixelSummaryData propertyId={id} advertiserId={advertiserId} />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <PixelRawData propertyId={id} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
