import axios from 'axios';
import Interceptor from './interceptor';

export default () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_APIUrl,
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      // "x-api-key": process.env.NEXT_PUBLIC_api_key,
      // ...headers,
    },
  });
  return Interceptor(instance);
};
