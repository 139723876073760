import React from 'react';
import './footer.css';

const appVersion = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '';


const FooterComponent = () => {
  return <div className='footerWrapper'>
    <div className='footerLogo'>
    </div>
    <div className='footerElements'>
      <div className='elementsSpace elementsCTA'>
        <a href="mailto:AudienceAnywhere@claritas.com">Support</a>
      </div>
      <div className='elementsSpace elementsCTA'>
        <a href="http://claritas.com/about"  target="_blank">About</a>
      </div>
      <div className='elementsSpace elementsCTA'>
        <a href="http://claritas.com/privacy-legal"  target="_blank">Privacy</a>
      </div>
      <div className='elementsSpace elementsCTA'>
        <a href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=C14A72A6FFC621D3A39D1E97CC320A5C" target="_blank">
          Careers
        </a> 
      </div>
      <div className='elementsSpace elementsMsg'>Copyright 2023 Claritas LLC  {appVersion}</div>
    </div>
  </div>;
};

export default FooterComponent;
