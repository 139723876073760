import React from 'react';
import { Link } from 'react-router-dom';
import {
  formatNumberInThousands,
  getEncryptedOrDecryptedData,
} from '../../../../utils/functions';
import moment from 'moment';

export const createColumns = () => {
  return [
    {
      id: 'campaign_id',
      name: 'CAMPAIGN ID',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${
              row.campaign_id
            }?params=${getEncryptedOrDecryptedData(
              { name: row.campaign_name },
              'encrypt'
            )}`}
          >
            <div className='campaignNameStyleAC'>{row.campaign_id}</div>
          </Link>
        </div>
      ),
      sortable: true,
      sortField: 'campaign_id',
      width: '135px',
      right: true,
    },
    {
      id: 'campaign_name',
      name: 'CAMPAIGN NAME',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${
              row.campaign_id
            }?params=${getEncryptedOrDecryptedData(
              { name: row.campaign_name },
              'encrypt'
            )}`}
          >
            <div className='campaignNameStyleCL'>{row.campaign_name}</div>
          </Link>
        </div>
      ),
      sortable: true, // sorting
      sortField: 'campaign_name', // sorting
      minWidth: '150px !important',
    },
    {
      id: 'imp_count',
      name: 'IMPRESSION TO DATE',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          {formatNumberInThousands(row.imp_count || 0)}
        </div>
      ),
      sortable: true, // sorting
      sortField: 'imp_count', // sorting
      style: { justifyContent: 'flex-end' },
      width: `15%`,
      minWidth: '210px !important',
      right: true,
    },
    {
      id: 'engagements',
      name: 'ENGAGEMENTS',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          {formatNumberInThousands(row?.engagements || 0)}
        </div>
      ),
      sortable: true, // sorting
      sortField: 'engagements', // sorting
      style: { justifyContent: 'flex-end' },
      width: '15%',
      minWidth: '210px !important',
      right: true,
    },
    {
      id: 'conversions',
      name: 'CONVERSIONS',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          {formatNumberInThousands(row?.conversions || 0)}
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'conversions', // sorting
      style: { justifyContent: 'flex-end' },
      width: '15%',
      minWidth: '210px !important',
      right: true,
    },
    {
      id: 'end_date',
      name: 'ENDING',
      selector: (row) => {
        return (
          <div className='campaignListEndingWrapper'>
            <div className='tableCellFontStyle '>
              {moment.utc(row.end_date).format('MM/DD/YYYY')}
            </div>
          </div>
        );
      },
      sortable: true, // sorting
      sortField: 'end_date', // sorting
      width: '135px',
    },
    {
      id: 'runReportCL',
      name: 'RUN REPORT',
      selector: (row) => (
        <button
          className='runReportIcon runReportEndable'
          onClick={() =>
            window.open(
              `/reports/run/?rd1=${row.campaign_id}`,
              '_blank',
              'noreferrer'
            )
          }
        />
      ),
      sortable: false,
      style: { justifyContent: 'center' },
      width: '110px',
    },
  ];
};
