import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { baseEndpoints } from '../../../../../constants';
import CumulativeGoalEvents from '../attributionTab/cumulativeGoalEventsSection/CumulativeGoalEvents.component';
import DailyEngagementsConversionsReport from '../attributionTab/conversionEngagementSection/DailyEngagementsConversionsReport.component';
import { EffectivenessChartAttributionTab } from './effectivenessSection/EffectivenessChartAttributionTab.component';
import AnalysisComponent from '../attributionTab/analysisSection/Analysis.component';
import CampaignPerformance from './campaignPerformance/campaignPerformace';
import ComparisionTable from './comparisonTableSection/ComparisionTable.component';
import SummaryHeader from '../../summaryHeader/SummaryHeader.component';

function AttributionComponent({
  campaign,
  setCampaign,
  properStartDateString,
  properLookbackDateString,
  setProperLookbackDateString,
  setProperStartDateString,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setHaveSetDates,
  placement,
  setPlacement,
  defaultPlacement,
  setDefaultPlacement,
  mediaPartner,
  setMediaPartner,
  defaultMediaPartner,
  setDefaultMediaPartner,
  creativeType,
  setCreativeType,
  defaultCreativeType,
  setDefaultCreativeType,
  isDisabled,
  setIsDisabled,
  getReportsDetails,
  isErrorReportDetails,
  getCampaignList,
  getPlacementList,
  campaignList,
  placementList,
  setPlacementList,
  mediaPartnerList,
  setMediaPartnerList,
  creativeTypeList,
  setCreativeTypeList,
  isLoading,
  isErrorCampaignList,
  isErrorPlacementList,
  dateRange,
  setDateRange,
  summaryData,
  setSummaryData,
  ifSelectedAll,
  updateIsLoading,
  getPartnerList,
  getCreativeTypeList,
  isErrorPartnerList,
  isErrorCreativeTypeList,
  presentTimeFrameOptions,
  timeFrame,
  setTimeFrame,
  reportName,
}) {
  const endpoint = `${baseEndpoints.reports}/attribution-tab`,
    labels = ['Impressions', 'Engagements', 'Conversions'];

  const [noData, setNoData] = useState(false)

  return (
    <div className='attributionComponent' style={{ width: '100%' }}>
      <SummaryHeader
        campaign={campaign}
        setCampaign={setCampaign}
        properStartDateString={properStartDateString}
        properLookbackDateString={properLookbackDateString}
        setProperLookbackDateString={setProperLookbackDateString}
        setProperStartDateString={setProperStartDateString}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setHaveSetDates={setHaveSetDates}
        placement={placement}
        setPlacement={setPlacement}
        defaultPlacement={defaultPlacement}
        setDefaultPlacement={setDefaultPlacement}
        mediaPartner={mediaPartner}
        setMediaPartner={setMediaPartner}
        defaultMediaPartner={defaultMediaPartner}
        setDefaultMediaPartner={setDefaultMediaPartner}
        creativeType={creativeType}
        setCreativeType={setCreativeType}
        defaultCreativeType={defaultCreativeType}
        setDefaultCreativeType={setDefaultCreativeType}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        getReportsDetails={getReportsDetails}
        isErrorReportDetails={isErrorReportDetails}
        isErrorCampaignList={isErrorCampaignList}
        isErrorPlacementList={isErrorPlacementList}
        getCampaignList={getCampaignList}
        getPlacementList={getPlacementList}
        campaignList={campaignList}
        placementList={placementList}
        setPlacementList={setPlacementList}
        mediaPartnerList={mediaPartnerList}
        setMediaPartnerList={setMediaPartnerList}
        creativeTypeList={creativeTypeList}
        setCreativeTypeList={setCreativeTypeList}
        isLoading={isLoading}
        dateRange={dateRange}
        setDateRange={setDateRange}
        labels={labels}
        endpoint={endpoint}
        summaryData={summaryData}
        setSummaryData={setSummaryData}
        ifSelectedAll={ifSelectedAll}
        updateIsLoading={updateIsLoading}
        getPartnerList={getPartnerList}
        getCreativeTypeList={getCreativeTypeList}
        isErrorPartnerList={isErrorPartnerList}
        isErrorCreativeTypeList={isErrorCreativeTypeList}
        presentTimeFrameOptions={presentTimeFrameOptions}
        timeFrame={timeFrame}
        setTimeFrame={setTimeFrame}
        setNoData={setNoData}
      />
      <Container fluid>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <DailyEngagementsConversionsReport
              campaign={campaign}
              startDate={startDate}
              endDate={endDate}
              mediaPartnerList={mediaPartnerList}
              mediaPartner={mediaPartner}
              placementList={placementList}
              placement={placement}
              creativeTypeList={creativeTypeList}
              creativeType={creativeType}
              parametersIsLoading={isLoading}
              ifSelectedAll={ifSelectedAll}
            />
          </Col>
          <Col lg={6} md={12} sm={12}>
            <CumulativeGoalEvents
              campaign={campaign}
              startDate={startDate}
              endDate={endDate}
              mediaPartnerList={mediaPartnerList}
              mediaPartner={mediaPartner}
              placementList={placementList}
              placement={placement}
              creativeTypeList={creativeTypeList}
              creativeType={creativeType}
              parametersIsLoading={isLoading}
              ifSelectedAll={ifSelectedAll}
            />
          </Col>
        </Row>
      </Container>

      {/* <EffectivenessChartAttributionTab
        campaign={campaign}
        dateRange={dateRange}
        mediaPartner={mediaPartner}
        placement={placement}
        parametersIsLoading={isLoading}
        ifSelectedAll={ifSelectedAll}
      /> */}

      <CampaignPerformance
        campaign={campaign}
        startDate={startDate}
        endDate={endDate}
        mediaPartnerList={mediaPartnerList}
        mediaPartner={mediaPartner}
        placementList={placementList}
        placement={placement}
        creativeTypeList={creativeTypeList}
        creativeType={creativeType}
        parametersIsLoading={isLoading}
        ifSelectedAll={ifSelectedAll}
      />
      <AnalysisComponent
        campaign={campaign}
        mediaPartner={mediaPartner}
        mediaPartnerList={mediaPartnerList}
        creativeType={creativeType}
        creativeTypeList={creativeTypeList}
        placement={placement}
        placementList={placementList}
        ifSelectedAll={ifSelectedAll}
        startDate={startDate}
        endDate={endDate}
        noData={noData}
      />
      <ComparisionTable
        campaign={campaign}
        mediaPartner={mediaPartner}
        mediaPartnerList={mediaPartnerList}
        startDate={startDate}
        endDate={endDate}
        parametersIsLoading={{
          campaign: isLoading.campaign,
          mediaPartner: isLoading.mediaPartner,
        }}
        reportName={reportName}
      />
    </div>
  );
}

export default AttributionComponent;
