import React, { useState, useEffect  } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NoDataComponent from '../../../components/commonTable/NoData.component';
import axios from 'axios';
import LoaderComponent from '../../../components/loaderComponent/Loader.component';
import { CommonHeadingComponent } from '../../../components';
import { toggleClasses } from '../../../utils/functions';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { axiosGetRequest } from '../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../constants';

import './campaign.css';

const CampaignDetails = (props) => {
  const { id } = props;
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [expandOrCollapse, setExpandOrCollapse] = React.useState(false);

  const getCampaignDetailsData = async (cancelToken) => {
    setIsLoading(true);
    try {
      const response = await axiosGetRequest(
        `${baseEndpoints.campaigns}/${id}`,
        cancelToken.signal,
      );
      const respData = response.data;
      if (response.status === 200) {
        const tmp = respData.details[0];
        tmp.start_date = tmp.start_date
          ? new Date(tmp.start_date).toLocaleDateString()
          : '';
        tmp.end_date = tmp.end_date
          ? new Date(tmp.end_date).toLocaleDateString()
          : '';
        respData.details = tmp;
        setData(respData);
      } 
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        setIsLoading(false);
      }
    }
  };

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getCampaignDetailsData(cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <>
      <div className='campaignListWrapper container-fluid'>
        <CommonHeadingComponent
          headingLabel='Campaign Details'
          headingCTALabel={!expandOrCollapse ? 'COLLAPSE' : 'EXPAND'}
          isDisabled={false}
          onClickCTA={() => setExpandOrCollapse(!expandOrCollapse)}
          uniqueIdentifier='campaignDetailsCollapsable'
        />
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            {!data.details ? (
              <NoDataComponent />
            ) : (
              <>
                {!expandOrCollapse && (
                  <Container fluid style={{ paddingTop: 2 + 'em' }}>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        <Container fluid style={{ padding: 0 }}>
                          <Row>
                            <Col lg={6} md={6} sm={6}>
                              <CampaignDetailsField
                                label='CAMPAIGN ID'
                                value={data.details.campaign_id}
                              />
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                              <CampaignDetailsField
                                label='CAMPAIGN NAME'
                                value={data.details.name}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={3} md={3} sm={3}>
                              <CampaignDetailsField
                                label='START DATE'
                                value={data.details.start_date}
                              />
                            </Col>
                            <Col lg={3} md={3} sm={3}>
                              <CampaignDetailsField
                                label='END DATE'
                                value={data.details.end_date}
                              />
                            </Col>
                            <Col lg={3} md={3} sm={3}>
                              <CampaignDetailsField
                                label='STATUS'
                                value={
                                  data.details.status == 'Y'
                                    ? 'ACTIVE'
                                    : 'INACTIVE'
                                }
                              />
                            </Col>
                            <Col lg={3} md={3} sm={3}>
                              <CampaignDetailsField
                                label='LIFT REPORT'
                                value={
                                  data.details.lift_report_enabled == 'Y'
                                    ? 'INCLUDED'
                                    : 'NO'
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={12} sm={12}>
                              <CampaignDetailsField
                                label='CAMPAIGN DESCRIPTION'
                                value={data.details.description}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={12} sm={12}>
                              <CampaignDetailsField
                                label='ORDER NUMBER'
                                value={data.details.order_number}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col lg={3} md={12} sm={12}>
                        <GenericItemsList
                          label='ATTACHED PROPERTIES'
                          nameField='name'
                          values={data.properties}
                        />
                      </Col>
                      <Col lg={3} md={12} sm={12}>
                        <GenericItemsList
                          label='PIXEL STATUS'
                          nameField='goals'
                          values={data.pixels}
                        />
                      </Col>
                    </Row>
                  </Container>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default CampaignDetails;

export const CampaignDetailsField = ({ type, label, value, disable = false, isMandatory = false,
   name ='', handleChange }) => {
  return (
    <div className='field'>
      
      <div className='field_label'>
        {isMandatory &&
          <span className='madatory-icon'>*</span>
        }
        {label}
      </div>
      <input value={value} disabled={disable} name={name} onChange={(e) => handleChange && handleChange(e)} />
    </div>
  );
};

export const Panel = ({
  label,
  nameField,
  values,
  setTabSelected,
  tabSelected,
  selectedData,
  setSelectedData,
  availableData,
  setAvailableData,
  showModal = true,
  uniqueIdentifier
}) => {
  const [filter, setFilter] = useState(values);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (filterText) {
      return filterSearch(tabSelected, filterText);
    }
    return setFilter(values);
  }, [values])

  useEffect(() => {
    if (!showModal) {
      setFilterText('');
      setFilter(availableData);
    }
  }, [showModal])
  

  const filterSearch = (tabSelected, text) => {
    if (tabSelected) {
      setFilter(selectedData.filter((val) => val.name?.toLowerCase().includes(text?.toLowerCase())));
    } else {
      setFilter(availableData.filter((val) => val.name?.toLowerCase().includes(text?.toLowerCase())));
    }
  };

  return (
    <div className='panelWrapper'>
      <div className='panelHeader'>{label}</div>
      <div className='panelTabWrapper'>
        <div
          id={getUniqueIdentifier(uniqueIdentifier, 'PanelViewSelected')}
          name={getUniqueIdentifier(uniqueIdentifier, 'PanelViewSelected')}
          onClick={() => {
            setTabSelected(true);
            filterSearch(true, filterText);
          }}
          className={toggleClasses(
            tabSelected,
            'panelTabSelected',
            'panelTabNotSelected'
          )}
        >
          Selected
        </div>
        <div
          id={getUniqueIdentifier(uniqueIdentifier, 'PanelViewAll')}
          name={getUniqueIdentifier(uniqueIdentifier, 'PanelViewAll')}
          onClick={() => {
            setTabSelected(false);
            filterSearch(false, filterText);
          }}
          className={toggleClasses(
            !tabSelected,
            'panelTabSelected',
            'panelTabNotSelected'
          )}
        >
          View All
        </div>
      </div>
      <div className='panelSearch'>
          <input
            type='search'
            id={uniqueIdentifier ? uniqueIdentifier + 'PanelSearchField' : undefined}
            name={uniqueIdentifier ? uniqueIdentifier + 'PanelSearchField' : undefined}
            placeholder='Search'
            onChange={(e) => {
              setFilterText(e.target.value);
              filterSearch(tabSelected, e.target.value);
            }}
          />
        </div>
      <div className='panelInnerWrapper'>
        {filter.map((item, index) => {
          return !tabSelected ? (
            <div className='panelList onHover' key={`${item?.name}${item?.id}`}>
              <input
                type='checkbox'
                id={item?.id}
                name={item?.name}
                value={item?.id}
                checked={item?.selected}
                onChange={(e) => {
                  let objIndex = values.findIndex((obj) => {
                    if (obj.id == e.target.value) {
                      return e.target.value;
                    }
                  });
                  values[objIndex] = {
                    id: values[objIndex].id,
                    name: values[objIndex].name,
                    selected: !values[objIndex].selected,
                  };
                  setSelectedData(values.filter((val) => val.selected));
                  filterSearch(tabSelected, filterText);
                }}
              />
              <label htmlFor={item?.id}>{`${item?.name} (#${item?.id})`}</label>
            </div>
          ) : (
            <div className='panelList onHover' key={`${item?.name}${item?.id}`}>
              <input
                type='checkbox'
                id={item?.id}
                name={item?.name}
                value={item?.id}
                checked={item?.selected}
                onChange={(e) => {
                  let objIndex = values.findIndex((obj) => {
                    if (obj.id == e.target.value) {
                      return e.target.value;
                    }
                  });
                  values[objIndex] = {
                    id: values[objIndex].id,
                    name: values[objIndex].name,
                    selected: !values[objIndex].selected,
                  };
                  setAvailableData(
                    availableData.map((val) =>
                      val.id == item.id
                        ? {
                            id: values[objIndex].id,
                            name: values[objIndex].name,
                            selected: false,
                          }
                        : val
                    )
                  );
                  setFilter(values.filter((val) => val.selected));
                  setSelectedData(values.filter((val) => val.selected));
                }}
              />
              <label htmlFor={item?.id}>{`${item?.name} (#${item?.id})`}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const GenericItemsList = (props) => {
  const { label, nameField, values } = props;
  return (
    <div className='field'>
      <div className='field_label'>{label}</div>
      <div className='field_value generic_list'>
        {values?.map(function (item, i) {
          return (
            <OverlayTrigger
              key={`${item[nameField]}`}
              overlay={
                <Tooltip id='tooltip-disabled'>{item[nameField]}</Tooltip>
              }
            >
              <div className='generic_list-item' key={`${item[nameField]}-itemField`}>
                {item[nameField]}
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
};

const getUniqueIdentifier = (ui, prefix) => {
  return ui ? ui + prefix : undefined;
};
