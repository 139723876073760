const mediaTypeColors = ['#863401', '#FFC142', '#FF9933', '#FF7C1F', '#DC4D00']; //orange hue
const deviceColors = ['#405E67', '#AEF1F5', '#80D6DC', '#04BDC9', '#129199']; //teal hue
const partnersColors = ['#64013F', '#FF04A2', '#BA2984', '#FF7ACE', '#FFC2E8']; // pink hue
const placementTypeColors = [
  '#004064',
  '#1E9CE3',
  '#006BA6',
  '#58D6FF',
  '#A6E4F6',
]; // blue hue

const lineChartsColors = [
  '#f56600',
  '#271350',
  '#04878f',
  '#be3a34',
  '#439b0d',
  '#690b89',
  '#b10776',
  '#cdcd0d',
  '#883a3a',
  '#320808',
  '#a35f2e',
  '#27056b',
  '#155559',
  '#7d1713',
  '#2d5414',
  '#39074a',
  '#6b104c',
  '#757508',
  '#521515',
  '#c43333',
];

export {
  mediaTypeColors,
  deviceColors,
  partnersColors,
  placementTypeColors,
  lineChartsColors,
};
