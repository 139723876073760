import React from 'react';
import moment from 'moment'
import '../../placement.css';

export const EpisodeColumn = () => {
  return [
    {
      id: 'episodeName',
      name: 'Episode Name',
      selector: row => row.episodeName,
      cell: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle table-cell-font-size'>{row.episodeName}</div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'episodeName', // sorting
      style: { overflow: 'hidden', width: '650px' },
    },
    {
      id: 'publishDate',
      name: 'Publish Date',
      selector: row => row.publishDate,
      cell: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle table-cell-font-size'>{moment(row.publishDate).utc().format('YYYY-MM-DD')}</div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'publishDate', // sorting
      width: '15%',
      style: { paddingLeft: '25px' }
    }
  ];
};
