import React, { Component } from 'react';



export default class HeaderCellWrapper extends Component {

  render() {
    const { renderHeaderCell } = this.props;

    return (
      <th className={`cp_tree-table_header-cell`}>
        { renderHeaderCell() }
      </th>
    );
  }
}


const STYLE_COLUMN = {
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
};
