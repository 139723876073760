import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  CommonBarChartComponent,
  LoaderComponent,
  ErrorComponent,
  NoDataComponent,
  approximateNumberToMils,
} from './';
import './analysisSectionAT.css';
import { formatNumberInThousands } from '../../../../../../../../utils/functions';

const AnalysisBarChartsComponent = ({
  colWidths = { xl: 6, lg: 6, md: 6, sm: 12 },
  isLoadingBase = false,
  cancelController,
  customTooltip,
  uniqueIndex,
  barDataKeys = ['data_count'],
  chartsAttrArray = [],
}) => {
  return (
    <div className='analysisBarChartsWrapper'>
      <Container fluid className='removePaddingOrMargin chartOuterPadding'>
        <Row className='removePaddingOrMargin'>
          {chartsAttrArray?.map((elem, index) => (
            <Col
              xl={colWidths.xl}
              lg={colWidths.lg}
              md={colWidths.md}
              sm={colWidths.sm}
              className='removePaddingOrMargin chartOuterPadding'
              key={uniqueIndex + elem.uniqueKey}
            >
              <ChartElement
                chartHeaderLabel={elem.chartHeaderLabel}
                isLoadingBase={isLoadingBase}
                isLoadingElement={elem.isLoadingElement}
                isErrorElement={elem.isErrorElement}
                refreshFunction={elem.refreshFunction}
                cancelController={cancelController}
                graphData={elem.graphData}
                barStackIds={elem.barStackIds}
                uniqueKey={elem.uniqueKey}
                ifShowWidget={elem.ifShowWidget}
                customTooltip={customTooltip}
                barDataKeys={barDataKeys}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default AnalysisBarChartsComponent;

const ChartElement = ({
  chartHeaderLabel,
  isLoadingBase,
  isLoadingElement,
  isErrorElement,
  refreshFunction,
  cancelController,
  graphData,
  barDataKeys,
  barStackIds,
  barFills = ['#f56600'],
  uniqueKey,
  ifShowWidget,
  customTooltip,
}) => {
  return (
    <>
      <div className='barCharHeaderLabel'>{chartHeaderLabel}</div>
      {!ifShowWidget ? (
        <div className='comingSoonWidget'>Coming Soon...</div>
      ) : (
        <>
          {isLoadingBase || isLoadingElement ? (
            <LoaderComponent />
          ) : (
            <>
              {isErrorElement ? (
                <ErrorComponent
                  refreshAction={refreshFunction}
                  controller={cancelController}
                />
              ) : (
                <div className={`barChartHeight${!graphData ? ' noData' : ''}`}>
                  {!graphData ? (
                    <NoDataComponent
                      type='bar'
                      width='180'
                      height='180'
                      messageTextFontSize={15}
                      foregnObj={{
                        x: '22',
                        y: '90',
                        width: '80%',
                        height: '50',
                      }}
                      messageText='No Data Available'
                    />
                  ) : (
                    <CommonBarChartComponent
                      data={graphData}
                      chartMargin={{ top: 18, bottom: 15 }}
                      dataKey='goal_name'
                      domainMultiplier={1.2}
                      xAxisTickShow={false}
                      yTickCount={10}
                      yTickFormatter={approximateNumberToMils}
                      ifShowLegends={false}
                      customTooltip={customTooltip}
                      barDataKeys={barDataKeys}
                      barStackIds={barStackIds}
                      barFills={barFills}
                      uniqueKey={uniqueKey}
                      baseHeight='100%'
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
