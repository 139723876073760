import React, { Component } from 'react';




export default class Column extends Component {
  static displayName = 'TreeTable.Column';
  render() {
    return null;
  }
}
