import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  CommonTablesComponent,
  CommonHeadingComponent,
  ErrorComponent,
  CommonTooltipTrendComponent,
} from '../../../../components';
import {
  createEndingSoonColumns,
  // createStartingSoonColumns,
} from './campaignsSnapshot.utils';
import { axiosGetRequest } from '../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../constants';
import { navigateToURL } from '../../../../utils/functions';
import './campaignsSnapshot.css';

const baseTooltipActions = {
  showTooltip: false,
  tooltipPosition: null,
  effectiveCampaignId: null,
  isTooltipOnTop: true,
};

const CampaignsSnapshotComponent = () => {
  const client = useSelector((state) => state.client);
  const advertiser = useSelector((state) => state.advertiser);

  const [isEndingSoonLoading, setIsEndingSoonLoading] = useState(true);
  const [endingSoonCampaigns, setEndingSoonCampaigns] = useState([]);
  const [tableTrendChartData, setTableTrendChartData] = useState(null);
  const [endingSoonCampaignsColumns, setEndingSoonCampaignsColumns] = useState(
    []
  );
  const [endingSoonCampaignsStatus, setEndingSoonCampaignsStatus] =
    useState(null);
  const [endingSoonCampaignsError, setEndingSoonCampaignsError] =
    useState(false);
  const [tooltipActions, setTooltipActions] = useState(baseTooltipActions);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalData, setTotalData] = useState(0);

  // Starting Soon
  /**const [isStartingSoonLoading, setIsStartingSoonLoading] = useState(true);
  const [startingSoonCampaigns, setStartingSoonCampaigns] = useState(null);
  const [startingSoonCampaignsColumns, setStartingSoonCampaignsColumns] =
    useState([]);
  const [startingSoonCampaignsStatus, setStartingSoonCampaignsStatus] =
    useState(null);
  const [startingSoonCampaignsError, setStartingSoonCampaignsError] =
    useState(false);*/

  useEffect(() => {
    if (advertiser.selectedAdvertiser !== -1) {
      const controller = new AbortController();

      getEndingSoonCampaignsData(controller);
      /**getStartingSoonCampaignsData(controller); // Starting Soon*/

      return () => {
        controller.abort();
      };
    } else {
      /**setIsStartingSoonLoading(true); // Starting Soon*/
      setIsEndingSoonLoading(true);
      setTimeout(() => {
        // Starting Soon
        /**setStartingSoonCampaigns([]);
        setIsStartingSoonLoading(false);*/
        setEndingSoonCampaigns([]);
        setIsEndingSoonLoading(false);
      }, 100);
    }
  }, [client, advertiser, pageSize, pageNumber]);

  const getEndingSoonCampaignsData = async (controller) => {
    try {
      setIsEndingSoonLoading(true);
      const endingSoonBaseData = await axiosGetRequest(
          `${baseEndpoints.campaigns}/ending-soon`,
          controller.signal,
          {
            timeZone: null,
            user_id:
              client.selectedClient === -1 ? null : client.selectedClient,
            advertiser_id:
              advertiser.selectedAdvertiser === -1
                ? null
                : advertiser.selectedAdvertiser,
            page_number: pageNumber,
            page_size: pageSize,
          }
        ),
        endingSoonData = endingSoonBaseData.data;
      setEndingSoonCampaignsStatus(endingSoonBaseData.status);
      if (endingSoonBaseData.status === 200) {
        setEndingSoonCampaigns(endingSoonData.table_base_data);
        setTableTrendChartData(endingSoonData.trend_chart_data);
        setTotalData(endingSoonData.data_count);
        setEndingSoonCampaignsColumns(
          createEndingSoonColumns(
            endingSoonData.trend_chart_data,
            tooltipActions,
            setTooltipActions
          )
        );
      } else {
        setEndingSoonCampaignsError(true);
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.log(e);
        if (e.response) {
          setEndingSoonCampaignsStatus(e.response.status);
        } else {
          setEndingSoonCampaignsStatus(500);
        }
        setEndingSoonCampaignsError(true);
      }
    } finally {
      setIsEndingSoonLoading(false);
      setTooltipActions(baseTooltipActions);
    }
  };

  // Starting Soon
  /**const getStartingSoonCampaignsData = async (controller) => {
    try {
      setIsStartingSoonLoading(true);
      const startingSoonData = await axiosGetRequest(
        `${baseEndpoints.campaigns}/starting-soon`,
        controller.signal,
        {
          timeZone: null,
          user_id: client.selectedClient === -1 ? null : client.selectedClient,
          advertiser_id:
            advertiser.selectedAdvertiser === -1
              ? null
              : advertiser.selectedAdvertiser,
        }
      );
      setStartingSoonCampaignsStatus(startingSoonData.status);
      if (startingSoonData.status === 200) {
        setStartingSoonCampaigns(startingSoonData.starting_soon);
        setStartingSoonCampaignsColumns(createStartingSoonColumns());
      } else {
        setStartingSoonCampaignsError(true);
      }
      setIsStartingSoonLoading(false);
    } catch (e) {
      if (!axios.isCancel(e)) {
        if (e.response) {
          setStartingSoonCampaignsStatus(e.response.status);
        } else {
          setStartingSoonCampaignsStatus(500);
        }
        setStartingSoonCampaignsError(true);
        setIsStartingSoonLoading(false);
      }
    }
  };*/

  const renderCampaigns = () => {
    return (
      <div>
        <Container fluid className='campaignsWrapper'>
          <Row id='snapShotRow'>
            <Col lg={7} md={12} id='snapShotCol'>
              {endingSoonCampaignsError ? (
                <ErrorComponent status={endingSoonCampaignsStatus} />
              ) : (
                <div className='campaignSnapshotWrapperES'>
                  {tooltipActions.showTooltip && !isEndingSoonLoading && (
                    <CommonTooltipTrendComponent
                      isTooltipOnTop={tooltipActions.isTooltipOnTop}
                      tooltipPosition={tooltipActions.tooltipPosition}
                      effectiveCampaignId={tooltipActions.effectiveCampaignId}
                      tableTrendChartData={tableTrendChartData}
                      tableBaseData={endingSoonCampaigns}
                      header='Impressions: 7 day snapshot'
                      offsetWidth={16}
                    />
                  )}
                  <CommonTablesComponent
                    uniqueId='endingSoonCampaigns'
                    data={endingSoonCampaigns ?? []}
                    columns={endingSoonCampaignsColumns}
                    isLoading={isEndingSoonLoading}
                    // url={null}
                    isPaginationApplied={true}
                    totalDataCount={totalData}
                    pageIndex={pageNumber}
                    setPageIndex={setPageNumber}
                    dataPerPage={pageSize}
                    noDataMessage='No Campaigns Available'
                    noDataMessageFontSize={15}
                    noDataCompWidth='180'
                    noDataCompHeight='180'
                    foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
                    showColumnShowHide={false}
                  />
                </div>
              )}
            </Col>
            {/* <Col lg={5} md={12}>
              {startingSoonCampaignsError ? (
                <ErrorComponent status={startingSoonCampaignsStatus} />
              ) : (
                <CommonTablesComponent
                  uniqueId='startingSoonCampaigns'
                  data={startingSoonCampaigns ?? []}
                  columns={startingSoonCampaignsColumns}
                  title={'Starting Soon'}
                  url={null}
                  isLoading={isStartingSoonLoading}
                />
              )}
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <div className='campaignSnapshotWrapper'>
      <div className='campaignsHeaderWrapper'>
        <CommonHeadingComponent
          headingLabel={'Campaigns Snapshot'}
          // isDisabled={!Boolean((startingSoonCampaigns && startingSoonCampaigns.length > 0) || (endingSoonCampaigns && endingSoonCampaigns.length > 0))}
          isDisabled={true}
          onClickCTA={() => navigateToURL('')}
        />
      </div>
      {renderCampaigns()}
    </div>
  );
};

export default CampaignsSnapshotComponent;
