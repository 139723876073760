import React from 'react'
import moment from 'moment-timezone'
import { Buffer } from 'buffer'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { Store } from 'react-notifications-component';
import validator from 'validator'
import { get } from 'lodash'

const timeZone = 'America/New_York'
const defaultDateFormat = 'YYYY-MM-DD'
const estDateFormat = 'MM/DD/YYYY'
const reportExpirationTime = 12 * 3600 * 1000
const reportRenewalTime = 30 * 60 * 1000

const USDollar = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});

export const formatUsDollars = (number) => {
  if (number == '') return number;
  return USDollar.format(number);
}

export const daysToFormatWRTDays = (daysTo, date) => {
  if (daysTo > 1) {
    return `${daysTo} days`
  }
  if (daysTo === 1) {
    return 'Tomorrow'
  }
  if (date) {
    return date;
  }
  return 'Today';
};

export const daysToFormatWRTDates = (date) => {
  const daysTo = moment(date, defaultDateFormat).diff(moment(), 'days')
  return daysToFormatWRTDays(daysTo)
}

export const dateFormat = (date, format = estDateFormat) => {
  return moment(date).format(format)
}

export const trendFormat = (trendType) => {
  return trendType > 0
    ? (
    <div className={trendType === 1 ? 'trendDown' : 'trendUp'}></div>
      )
    : (
    <div className='trendEqual'>-</div>
      )
}

export const findCompletedDays = (date) => {
  const days = moment()
    .tz(timeZone)
    .diff(moment(date, defaultDateFormat), 'days')
  return days
}

export const formatNumberInThousands = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const approximateNumberToMils = (number) => {
  if (number >= 100000000) return `${(number / 1000000).toFixed(0)}MM`
  if (number >= 1000000) return `${(number / 1000000).toFixed(1)}MM`
  if (number >= 100000) return `${(number / 1000).toFixed(0)}k`
  if (number >= 1000) return `${(number / 1000).toFixed(1)}k`
  return number
}

export const toggleClasses = (
  Condition,
  classedIfSatisfy,
  classedIfNotSatisfy
) => {
  return Condition ? classedIfSatisfy : classedIfNotSatisfy
}

export const getTrendTooltipParams = (
  isMouseEntered,
  colId,
  campaignId,
  isAlreadyEntered,
  tooltipActions
) => {
  if (!isAlreadyEntered && isMouseEntered && colId) {
    const hoveringElem = document.querySelector(`#${colId} .trendInnerWrapper`)
    const rect = hoveringElem.getBoundingClientRect()

    return {
      isAlreadyEnteredNew: true,
      tooltipActionsNew: {
        ...tooltipActions,
        effectiveCampaignId: campaignId,
        isTooltipOnTop: Boolean(rect.top >= 240),
        tooltipPosition: {
          top: window.scrollY + rect.top + (rect.top >= 240 ? -10 : 10),
          left: rect.left + rect.width / 2
        },
        showTooltip: true
      }
    }
  }
  if (isAlreadyEntered && (!isMouseEntered || !colId)) {
    return {
      isAlreadyEnteredNew: false,
      tooltipActionsNew: {
        ...tooltipActions,
        effectiveCampaignId: null,
        tooltipPosition: null,
        showTooltip: false
      }
    }
  }
  return {
    isAlreadyEnteredNew: isAlreadyEntered
  }
}

export const getSortedArrayOfObj = (data, key) => {
  return data.sort((item1, item2) => {
    if (item1[key].toLowerCase() < item2[key].toLowerCase()) {
      return -1
    }
    if (item2[key].toLowerCase() > item1[key].toLowerCase()) {
      return 1
    }
    return 0
  })
}

export const removeTableClass = (parentClass) => {
  const element = document.querySelector(`${parentClass} .sc-fLcnxK.dApqnJ`)
  if (element) {
    element.classList.remove('dApqnJ')
  }
}

export const getCurrentESTDate = (format = estDateFormat) => {
  return moment().tz(timeZone).format(format)
}

export const navigateToURL = (url) => {
  url && window.location.replace(url)
}

export const createQueryParam = (paramObj) => {
  let param = ''
  const keyArray = Object.keys(paramObj)
  keyArray.forEach((elem, index) => {
    if (paramObj[elem]) {
      param += `${elem}=${paramObj[elem]}`
    }
    if (index < keyArray.length - 1 && paramObj[elem]) {
      param += '&'
    }
  })
  return param
}

export const FormatLabel = ({ label, isMandatory = false, customClass }) => {
  return (
    <div className='commonLabelWrapper'>
      <span className={customClass || ''}>{label}</span>
      {isMandatory && <span className='formatLabelAstStyle'>*</span>}
    </div>
  )
}

export const setDataWhileUpdate = (
  dataList,
  setData,
  defaultData,
  setDefaultData,
  id
) => {
  if (defaultData && dataList) {
    const newDefaultData = defaultData
      .map((elem) =>
        dataList.find((el) => {
          if (el[id] === elem[id]) return el;
        })
      )
      .filter((elem) => elem !== undefined);

    setDefaultData(null);
    if (newDefaultData.length !== dataList.length) {
      setData(newDefaultData.length <= 0 ? [...dataList] : [...newDefaultData]);
      return;
    }
    setData([...dataList]);
    return;
  }

  setDefaultData(null);
  if (!dataList?.length) {
    setData(null);
    return;
  }
  setData([...dataList]);
};

export const setPlaceHolderLabel = (isDisabled, labelParam) => {
  if (isDisabled) {
    return `${labelParam} Unavailable`
  }
  return `Select ${labelParam}`
}

export const getArrayOfData = (selectedData, dataId) => {
  return selectedData?.map((elem) => elem[dataId])
}

export const getEffectiveDropdownHeight = (
  listSize,
  max,
  height,
  buffer = 0
) => {
  if (listSize > 0) {
    return (listSize < max ? listSize : max) * height + buffer
  }
  return 0
}

export const checkCondition = (condition, first, second = null) => {
  return condition ? first : second
}

export const checkSelectAll = (listData, selectedData, id) => {
  if (!listData) {
    return false
  }
  if (!selectedData) {
    return false
  }
  const data = selectedData
    .map((elem) =>
      listData.find((el) => {
        if (el[id] === elem[id]) return el
      })
    )
    .filter((elem) => elem !== undefined)
  return data.length === listData.length
}

export const dataHandlingForLS = (lsParam) => {
  /** lsParam = {
    type,
    nameAll,
    nameReport,
    timestamp,
    reportData,
    removingElements: [],
    allDecryptReport,
    updatingItem,
  }; */

  switch (lsParam.type) {
    case 'allReportsAddOrCreate':
      const ls = localStorage.getItem(lsParam.nameAll)

      if (ls) {
        let decryptedLs = JSON.parse(encryptAndDecryptFunction(ls, 'decrypt'))
        decryptedLs = [
          ...decryptedLs,
          { name: lsParam.nameReport, timestamp: lsParam.timestamp }
        ]
        localStorage.setItem(
          lsParam.nameAll,
          encryptAndDecryptFunction(decryptedLs, 'encrypt')
        )
      } else {
        localStorage.setItem(
          lsParam.nameAll,
          encryptAndDecryptFunction(
            [{ name: lsParam.nameReport, timestamp: lsParam.timestamp }],
            'encrypt'
          )
        )
      }
      break

    case 'allReportsUpdate':
      const newAllReport = [
        ...lsParam.allDecryptReport.filter(
          (item) => item.name !== lsParam.nameReport
        ),
        { ...lsParam.updatingItem, timestamp: lsParam.timestamp }
      ]

      localStorage.setItem(
        lsParam.nameAll,
        encryptAndDecryptFunction(newAllReport, 'encrypt')
      )
      break

    case 'removeElementsFromAllReports':
      const lsAll = localStorage.getItem(lsParam.nameAll)

      if (lsAll) {
        let decryptedLs = JSON.parse(
          encryptAndDecryptFunction(lsAll, 'decrypt')
        )

        if (decryptedLs.length > 0) {
          decryptedLs = decryptedLs.filter(
            (item) => !lsParam.removingElements.includes(item.name)
          )

          localStorage.setItem(
            lsParam.nameAll,
            encryptAndDecryptFunction(decryptedLs, 'encrypt')
          )
        } else {
          localStorage.removeItem(lsParam.nameAll)
        }
      }
      break

    case 'deleteAllReports':
      localStorage.removeItem(lsParam.nameAll)
      break

    case 'saveAReport':
      localStorage.setItem(
        lsParam.nameReport,
        encryptAndDecryptFunction(lsParam.reportData, 'encrypt')
      )
      break

    case 'deleteAReport':
      localStorage.removeItem(lsParam.nameReport)
      break
    default:
    // no code
  }
}

const encryptAndDecryptFunction = (data, type) => {
  let newData

  switch (type) {
    case 'encrypt':
      newData = Buffer.from(JSON.stringify(data)).toString('base64')
      break
    case 'decrypt':
      data = data.replace(/ /g, '+');
      newData = Buffer.from(data, 'base64').toString('ascii')
      break
    default:
    // no code
  }

  return newData
}

export const getEncryptedOrDecryptedData = (data, type) => {
  return encryptAndDecryptFunction(data, type)
}

const getDataArray = (csvData, tagName, headers = null) => {
  if (tagName === 'downloadAllTags') {
    const namingObject = {
      placement_id: 'Placement ID',
      placement_name: 'Placement Name ',
      type: 'Placement Type',
      start_date: 'Start Date',
      end_date: 'End Date',
      partnerId: 'Partner ID',
      partner_name: 'Partner Name',
      podcastProvider: 'Podcast Provider',
      impressionTagUrl: 'Impression Tag URL',
      clickThroughTagUrl: 'Clickthrough Tag URL',
      rssPrefixUrl: 'RSS Prefix URL',
    };
    const reformattedDataArr = [];

    csvData.forEach((tag) => {
      const prettyData = new Object();

      Object.keys(tag).forEach((key) => {
        const newName = namingObject[key];
        if (newName != undefined) {
          prettyData[newName] = tag[key];
        }
      });
      reformattedDataArr.push({
        ...prettyData,
        'Impression Tag URL2': tag?.impressionTagUrl_2 ?? '',
      });
    });

    return reformattedDataArr;
  }

  if (tagName === 'attrPartnerComparison') {
    const namingBaseObject = {
      partner_id: 'Partner Id',
      partner_name: 'Partner Name',
      placement_id: 'Placement Id',
      placement_name: 'Placement Name',
    };
    let goalAndImpressions = {};

    headers.forEach((elem) => {
      goalAndImpressions = {
        ...goalAndImpressions,
        [elem.header_name]: elem.header_label,
      };
    });
    const namingObject = { ...namingBaseObject, ...goalAndImpressions },
      newCsvData = [],
      reformattedDataArr = [];

    csvData.forEach((item) => {
      newCsvData.push({
        partner_id: item.id,
        partner_name: item.name,
        placement_id: null,
        placement_name: null,
        ...item,
      });

      item?.placement_data.forEach((elem) => {
        newCsvData.push({
          partner_id: item.id,
          partner_name: item.name,
          placement_id: elem.id,
          placement_name: elem.name,
          ...elem,
        });
      });
    });

    newCsvData.forEach((tag) => {
      const prettyData = new Object();

      Object.keys(tag).forEach((key) => {
        const newName = namingObject[key];
        if (newName != undefined) {
          prettyData[newName] = tag[key];
        }
      });

      reformattedDataArr.push({
        ...prettyData,
      });
    });

    return reformattedDataArr;
  }

  if (tagName === 'liftGoalsComparison') {
    const namingBaseObject = {
      goal_id: "Goal Id",
      goal_name: "Goal Name",
      partner_id: "Partner Id",
      partner_name: "Partner Name",
      placement_id: "Placement Id",
      placement_name: "Placement Name",
    };
    let goalAndImpressions = {};

    headers.forEach((elem) => {
      goalAndImpressions = {
        ...goalAndImpressions,
        [elem.data_key]: elem.header_label,
      };
    });
    const namingObject = { ...namingBaseObject, ...goalAndImpressions },
      newCsvData = [],
      reformattedDataArr = [];

    csvData.forEach((goal) => {
      newCsvData.push({
        goal_id: goal.id,
        goal_name: goal.name,
        partner_id: null,
        partner_name: null,
        placement_id: null,
        placement_name: null,
        ...goal,
      });

      // and each of these items has items for each partner
      goal?.items.forEach((partner) => {
        newCsvData.push({
          goal_id: goal.id,
          goal_name: goal.name,
          partner_id: partner.id,
          partner_name: partner.name,
          placement_id: null,
          placement_name: null,
          ...partner,
        });

        // and each of those items has items one more time for each placement
        partner?.items.forEach((placement) => {
          newCsvData.push({
            goal_id: goal.id,
            goal_name: goal.name,
            partner_id: partner.id,
            partner_name: partner.name,
            placement_id: placement.id,
            placement_name: placement.name,
            ...placement
          })
        })
      });
    });


    newCsvData.forEach((tag) => {
      const prettyData = new Object();

      Object.keys(tag).forEach((key) => {
        const newName = namingObject[key];
        if (newName != undefined) {
          prettyData[newName] = tag[key];
        }
      });

      reformattedDataArr.push({
        ...prettyData,
      });
    });

    return reformattedDataArr;
  }

  if (tagName === 'campaignLookupAllCampaigns') {
    const reformattedDataArr = [];

    csvData.forEach((tag) => {
      const prettyData = new Object();
      tag.start_date = tag.start_date
        ? moment(tag.start_date).format('MM/DD/YYYY')
        : null;
      tag.end_date = tag.end_date
        ? moment(tag.end_date).format('MM/DD/YYYY')
        : null;
      tag.imp_count = Number(tag.imp_count);

      Object.keys(tag).forEach((key) => {
        const newName = headers[key];
        if (newName != undefined) {
          prettyData[newName] = tag[key];
        }
      });

      reformattedDataArr.push({
        ...prettyData,
      });
    });

    return reformattedDataArr;
  }

  if(tagName === 'propertyPixelSummaryDataExportExcel'){
    const reformattedDataArr = [];

    csvData.forEach((tag) => {
      const prettyData = new Object();
      tag.gross = Number(tag.gross)
      tag.ivt = Number(tag.ivt)
      tag.net = Number(tag.net)

      Object.keys(tag).forEach((key) => {
        const newName = headers[key];
        if (newName != undefined) {
          prettyData[newName] = tag[key];
        }
      });

      reformattedDataArr.push({
        ...prettyData,
      });
    });

    return reformattedDataArr;
  }

  if(tagName === 'propertyPixelRawDataExportExcel' || tagName === 'placementPixelRawDataExportExcel'){
    const reformattedDataArr = [];

    csvData.forEach((tag) => {
      
      const prettyData = new Object();

      Object.keys(tag).forEach((key) => {
        const newName = headers[key]
        if(newName != undefined){
          prettyData[newName] = tag[key]
        }
      });

      reformattedDataArr.push({...prettyData,})
    });

    return reformattedDataArr;
  }

  return [];
};

export const exportCSV = (csvData, fileName, tagName, headers) => {
  if (!csvData) {
    return;
  }
  console.log({csvData, headers});
  try {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(
      getDataArray(csvData, tagName, headers)
    );
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  } catch (error) {
    console.error(error.message);
  }
};

export const exportTxt = (data, fileName, type) => {
  const fileType = 'text/plain;charset=utf-8'
  const fileExtension = '.txt'
  const textBuffer = formatPropertyTagsExport(data, type)
  const exportData = new Blob([textBuffer], { type: fileType })

  FileSaver.saveAs(exportData, fileName + fileExtension)
}

const formatPropertyTagsExport = (data, type) => {
  const { headerText, subHeaderText } = getHeaderAndSubHeader(data, type)

  let tags = ''
  data.forEach((item) => (tags += item.conversion_pixel_data += '\n'))

  return `=============================================================\n
${headerText}\n
-------------------------------------------------------------\n
@created ${moment().format('MM/DD/YYYY')} \n
=============================================================\n
${subHeaderText}\n
-------------------------------------------------------------\n
${tags}`
}

const getHeaderAndSubHeader = (data, type) => {
  if (type === 'image') {
    return {
      headerText: ` ${data[0].property_name} Conversion Tracking / Image`,
      subHeaderText: ' Conversion Image Pixels:'
    }
  }
  return {
    headerText: ` ${data[0].property_name} Tracking / JS`,
    subHeaderText: ' Desktop Conversion JS Pixels:'
  }
}

export { reportExpirationTime, reportRenewalTime }

const isBeteenDates = (date, startDate, cd) => {
  return moment(date).isBetween(startDate, cd, undefined, '[]');
};

const getDateComparison = (csd, clbd, sd, ed) => {
  return (
    isBeteenDates(csd, sd, ed) ||
    isBeteenDates(clbd, sd, ed) ||
    isBeteenDates(sd, csd, clbd) ||
    isBeteenDates(ed, csd, clbd)
  );
};

export const addTimeFrames = (date, offset, constrant) => {
  return moment(date).add(offset, constrant).format('YYYY-MM-DD');
};

export const subtractTimeFrames = (date, offset, constrant) => {
  if (constrant === 'd') {
    return moment(date).subtract(offset, constrant).format('YYYY-MM-DD');
  } 
  return moment(date).subtract(1, 'd').subtract(offset, constrant).format('YYYY-MM-DD');
};

export const calculationForLastLiftperiod = (csd, clbd) => {
  const totalActiveCampaignPeriod = moment(clbd).diff(csd, 'd'),
    extraDaysBeforeFinalLiftPublished = 14 - (totalActiveCampaignPeriod % 14);

  return addTimeFrames(
    csd,
    totalActiveCampaignPeriod + extraDaysBeforeFinalLiftPublished,
    'd'
  );
};

export const getStartAndEndDate = (id, cd, csd, ced, clbd) => {
  const endDate = subtractTimeFrames(cd, 1, 'd');
  const today = moment().format('YYYY-MM-DD');
  // Calculation for Last 7 Days
  if (id === 12) {
    const startDate = subtractTimeFrames(cd, 7, 'd');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
      isDisabled: !getDateComparison(csd, clbd, startDate, cd),
    };
  }

  // Calculation for Last 1 month
  if (id === 13) {
    const startDate = subtractTimeFrames(cd, 1, 'M');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
      isDisabled: !getDateComparison(csd, clbd, startDate, cd),
    };
  }

  // Calculation for Last 1 quarter
  if (id === 14) {
    const lastQuarter = moment(cd).quarter() - 1;

    if (lastQuarter === 0) {
      const lastYear = moment(subtractTimeFrames(cd, 1, 'y')).format('YYYY'),
        sd = moment(lastYear).quarter(4).startOf('Q').format('YYYY-MM-DD'),
        ed = moment(lastYear).quarter(4).endOf('Q').format('YYYY-MM-DD');
      return {
        newStartDate: sd,
        newEndDate: ed,
        isDisabled: !getDateComparison(csd, clbd, sd, ed),
      };
    }

    const sd = moment().quarter(lastQuarter).startOf('Q').format('YYYY-MM-DD'),
      ed = moment().quarter(lastQuarter).endOf('Q').format('YYYY-MM-DD');

    return {
      newStartDate: sd,
      newEndDate: ed,
      isDisabled: !getDateComparison(csd, clbd, sd, ed),
    };
  }

  // Calculation for Last 6 months
  if (id === 15) {
    const startDate = subtractTimeFrames(cd, 6, 'M');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
      isDisabled: !getDateComparison(csd, clbd, startDate, cd),
    };
  }

  // Calculation for Last 1 Year
  if (id === 16) {
    const startDate = subtractTimeFrames(cd, 1, 'y');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
      isDisabled: !getDateComparison(csd, clbd, startDate, cd),
    };
  }

  // Calculation for Last 2 Year
  if (id === 17) {
    const startDate = subtractTimeFrames(cd, 2, 'y');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
      isDisabled: !getDateComparison(csd, clbd, startDate, cd),
    };
  }

  // Calculation for Last 3 months
  if (id === 18) {
    const startDate = subtractTimeFrames(cd, 3, 'M');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
      isDisabled: !getDateComparison(csd, clbd, startDate, cd),
    };
  }

  // Campaign Dates
  if (id === 19) {
    return {
      newStartDate: csd,
      newEndDate: ced,
      isDisabled: false,
    };
  }

  // Campaign Dates + Lookback Period
  if (id === 20) {
    return {
      newStartDate: csd,
      newEndDate: clbd,
      isDisabled: false,
    };
  }

  // Yesterday
  if(id === 8){
    return {
      newStartDate: subtractTimeFrames(today, 1, 'd'),
      newEndDate: subtractTimeFrames(today, 1, 'd'),
      isDisabled: !getDateComparison(csd, clbd, subtractTimeFrames(today, 1, 'd'), today),
    }
  }

  // Last Week (Mon-Sun)
  if(id === 2){
    return {
      newStartDate: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      newEndDate: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
      isDisabled: false,
    }
  }

  // This Month
  if(id === 3){
    return {
      newStartDate: moment().startOf('month').format('YYYY-MM-DD'),
      newEndDate: today,
      isDisabled: false,
    }
  }
  // Last Month
  if(id === 4){
    return {
      newStartDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      newEndDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      isDisabled: false,
    }
  }

  // Campaign to Date
  if (id === 7){
    return {
      newStartDate: csd,
      newEndDate: today,
      isDisabled: false,
    }
  }

  return {
    newStartDate: null,
    newEndDate: null,
    isDisabled: false,
  };
};

export const getGoalListAndType = (selectedGoal) => {
  const data = {
    goal_id: null,
    goal_type: null,
  };

  if (!selectedGoal) {
    return data;
  }

  const { id, type } = selectedGoal;

  if (id < 0) {
    if (!type) {
      return data;
    }
    return { ...data, goal_type: type };
  }

  return { ...data, goal_id: id, goal_type: type };
};

export const getNotification = (
  type,
  message,
  afterRemovalCallback = null,
  id = 'no_id'
) => {
  Store.addNotification({
    id,
    message: message,
    type: type,
    insert: 'center',
    container: 'center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: { showIcon: true, duration: 4200 },
    onRemoval: () => {
      if (afterRemovalCallback) {
        afterRemovalCallback(false);
      }
    },
  });
};

// For removing notifications
export const removeNotification = (id = null) => {
  if (id === null) {
    Store.removeAllNotifications();
  } else {
    Store.removeNotification(id);
  }
};

// Email validation & URL validation
const whiteListDomain = [
  '.com',
  '.net',
  '.edu',
  '.org',
  '.mil',
  '.gov',
  '.info',
  '.biz',
  '.blog',
  '.co',
  '.app',
  '.info',
  '.tv'
]

const validateTopLevelDomain = (url) => {
  for (let str of whiteListDomain) {
    if (url.includes(str)) {
      return true;
    }
  }
  return false;
};

export const isValidateEmail = (email) => {
  return validator.isEmail(email) && validateTopLevelDomain(email);
};

export const isValidateURL = (urlToValidate) => {
  return (
    validator.isURL(urlToValidate) && validateTopLevelDomain(urlToValidate)
  );
};

export const capitalizeRoles = (role = '') => {
  const roleArr = role.split(' ');
  const arrToReturn = roleArr.map(item => {
    return item[0].toUpperCase() + item.substring(1);
  })
  return arrToReturn.join(' ');
}

export const getStartAndEndDateTimeFrameImpressionsDashboard = (id, cd, csd, ced) => {
  const endDate = subtractTimeFrames(cd, 1, 'd');
  // Calculation for Last 7 Days
  if (id === 6) {
    const startDate = subtractTimeFrames(cd, 7, 'd');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
    };
  }

  // Calculation for Last 1 month
  if (id === 1) {
    const startDate = subtractTimeFrames(cd, 1, 'M');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
    };
  }

  // Calculation for Last 3 months
  if (id === 2) {
    const startDate = subtractTimeFrames(cd, 3, 'M');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
    };
  }

  // Calculation for Last 1 quarter
  if (id === 5) {
    const lastQuarter = moment(cd).quarter() - 1;

    if (lastQuarter === 0) {
      const lastYear = moment(subtractTimeFrames(cd, 1, 'y')).format('YYYY'),
        sd = moment(lastYear).quarter(4).startOf('Q').format('YYYY-MM-DD'),
        ed = moment(lastYear).quarter(4).endOf('Q').format('YYYY-MM-DD');

      return {
        newStartDate: sd,
        newEndDate: ed,
      };
    }

    const sd = moment().quarter(lastQuarter).startOf('Q').format('YYYY-MM-DD'),
      ed = moment().quarter(lastQuarter).endOf('Q').format('YYYY-MM-DD');

    return {
      newStartDate: sd,
      newEndDate: ed,
    };
  }

  // Calculation for Last 6 months
  if (id === 3) {
    const startDate = subtractTimeFrames(cd, 6, 'M');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
    };
  }

  // Calculation for Last Year
  if (id === 4) {
    const startDate = subtractTimeFrames(cd, 1, 'y');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
    };
  }

  // Calculation for Year to Date
  if (id === 7) {
    const startDate = moment().startOf('year').format('YYYY-MM-DD');
    return {
      newStartDate: startDate,
      newEndDate: endDate,
    };
  }

  // Calculation for Lifetime
  if (id === 8) {
    const startDate = csd;
    return {
      newStartDate: startDate,
      newEndDate: endDate,
    };
  }

  // Calculation for Custom Date Range
  if (id === 0) {
    return {
      newStartDate: csd,
      newEndDate: ced,
    };
  }

  return {
    newStartDate: null,
    newEndDate: null,
    isDisabled: false,
  };
};

export const getAllSelectedOrNot = (dataList, selectedData, id) => {
  const dataListArr = getArrayOfData(dataList, id).sort((a, b) => a - b),
    selectedDataArr = getArrayOfData(selectedData, id).sort((a, b) => a - b);

  return (
    dataListArr.length === selectedDataArr.length &&
    JSON.stringify(dataListArr) === JSON.stringify(selectedDataArr)
  );
};

export const getAllAdvertiserIds = (selectedClientsAdvertiser) => {
  const advertiserIds = selectedClientsAdvertiser
  .filter((advertiser) => advertiser.id > 0)
  .map((advertiser) => advertiser.id);
  return advertiserIds;
}

export const setAdvertiserId = (selectedAdvertiser, selectedClientsAdvertiser, role) => {
    if(selectedAdvertiser === -1) {
      return null;
    } else if(selectedAdvertiser === 0){
      return 0;
    } else {
      return [selectedAdvertiser]
    }
}

export const isOnlyNumbers = (value) => {
    return /^[0-9]+$/.test(value);
}
