import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid,
  Legend,
} from 'recharts';

const CommonBarChartComponent = ({
  baseWidth = 700,
  baseHeight = 500,
  data,
  chartMargin = { top: 0, bottom: 0, left: 0, right: 0 },
  strokeDasharray = '3 3',
  showVerticalGrids = false,
  showHorizontalGrids = true,
  dataKey,
  xTickCount = 5,
  xAxisTickShow = true,
  xAxisTickLineShow = false,
  xAxisLine = true,
  xAxisHide = false,
  xAxisWidth = 1,
  xAxisInterval = 0,
  yTickCount = 5,
  yAxisTickShow = true,
  yAxisTickLineShow = false,
  yAxisLine = false,
  yAxisHide = false,
  yAxisWidth = 80,
  yAxisInterval = 0,
  isReversed = false,
  domainMultiplier = 1,
  xTickFormatter = null,
  yTickFormatter = null,
  ifShowTooltip = true,
  customTooltip = null,
  ifShowLegends = true,
  legendLayout = 'horizontal', //'horizontal', 'vertical'
  legendHorizontalAlign = 'center', //'left', 'center', 'right'
  legendVerticalAlign = 'bottom', //'top', 'middle', 'bottom'
  modalIconSize = 14,
  modalIconType = 'square',
  barDataKeys,
  barWidth = 24,
  barStackIds,
  barFills,
  barLabel = null,
  uniqueKey,
  wrapperStyle = { outline: 'none' },
}) => {
  return (
    <ResponsiveContainer width='100%' height={baseHeight}>
      <BarChart
        width={baseWidth}
        height={baseHeight}
        data={data}
        margin={chartMargin}
      >
        <CartesianGrid
          vertical={showVerticalGrids}
          horizontal={showHorizontalGrids}
          strokeDasharray={strokeDasharray}
        />
        {isReversed && (
          <>
            <XAxis
              tickCount={xTickCount}
              axisLine={xAxisLine}
              domain={[
                0,
                (dataMax) =>
                  Math.ceil((dataMax * domainMultiplier) / 100) * 100,
              ]}
              tickFormatter={xTickFormatter}
              width={xAxisWidth}
              interval={xAxisInterval}
              hide={xAxisHide}
              tick={xAxisTickShow}
              tickLine={xAxisTickLineShow}
            />
            <YAxis
              tickCount={yTickCount}
              axisLine={yAxisLine}
              dataKey={dataKey}
              width={yAxisWidth}
              interval={yAxisInterval}
              hide={yAxisHide}
              tick={yAxisTickShow}
              tickLine={yAxisTickLineShow}
            />
          </>
        )}
        {!isReversed && (
          <>
            <XAxis
              tickCount={xTickCount}
              axisLine={xAxisLine}
              dataKey={dataKey}
              width={xAxisWidth}
              interval={xAxisInterval}
              hide={xAxisHide}
              tick={xAxisTickShow}
              tickLine={xAxisTickLineShow}
              stroke='#000'
            />
            <YAxis
              tickCount={yTickCount}
              axisLine={yAxisLine}
              domain={domainMultiplier === null ? ['auto', 'auto'] : [
                0,
                (dataMax) => {
                  if (dataMax >= 80) {
                    return Math.ceil((dataMax * domainMultiplier) / 100) * 100;
                  }
                  const max = Math.ceil(dataMax * domainMultiplier) + 5;
                  return max > 100 ? 100 : max;
                },
              ]}
              tickFormatter={yTickFormatter}
              width={yAxisWidth}
              interval={yAxisInterval}
              hide={yAxisHide}
              tick={yAxisTickShow}
              tickLine={yAxisTickLineShow}
            />
          </>
        )}

        {ifShowTooltip && (
          <Tooltip content={customTooltip} wrapperStyle={wrapperStyle} formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
        )}

        {ifShowLegends && (
          <Legend
            layout={legendLayout}
            align={legendHorizontalAlign}
            verticalAlign={legendVerticalAlign}
            iconSize={modalIconSize}
            iconType={modalIconType}
          />
        )}

        {barDataKeys.map((dataKey, index) => (
          <Bar
            key={`${uniqueKey}${dataKey}`}
            dataKey={dataKey}
            width={barWidth}
            stackId={barStackIds[index]}
            fill={barFills[index]}
            label={barLabel}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CommonBarChartComponent;
