import React from 'react';

const NoTableDataSVGComponent = ({message, fontSize = 24, height = "100", width ="100", foregnObj = {x:"35", y:"90", width:"150", height:"50"}}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6_17)">
      <rect x="12.5" y="12.5" width="195" height="195" rx="22.5" stroke="black" strokeWidth="5"/>
      <path d="M35 12H185C197.703 12 208 22.2975 208 35V67H12V35C12 22.2975 22.2975 12 35 12Z" fill="#A2A1A2" stroke="black" strokeWidth="4"/>
      <foreignObject x={foregnObj.x} y={foregnObj.y} width={foregnObj.width} height={foregnObj.height} style={{ textAlign: 'center' }}>
        <div style ={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <span style={{ color: '#4D4D4C', fontSize: fontSize, fontFamily: 'arial', fontWeight: 'bold' }}>{message}</span>
        </div>
      </foreignObject>
      <line x1="69" y1="69" x2="69" y2="91" stroke="black" strokeWidth="2"/>
      <line x1="69" y1="139" x2="69" y2="210" stroke="black" strokeWidth="2"/>
      <line x1="151" y1="69" x2="151" y2="95" stroke="black" strokeWidth="2"/>
      <line x1="151" y1="139" x2="151" y2="210" stroke="black" strokeWidth="2"/>
    </g>
    <defs>
      <filter id="filter0_d_6_17" x="6" y="10" width="208" height="208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6_17"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6_17" result="shape"/>
      </filter>
    </defs>
  </svg>
  );
};

export default NoTableDataSVGComponent;