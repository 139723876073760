import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { CommonInput, ButtonLoaderComponent } from '../../components';
import './modals.css';

const DuplicateRSSChannelModal = ({
  show,
  header = 'Alert',
  accept = null,
  reject = null,
  size = 'md',
  buttonAlignment = 'center',
  buttonUI,
  channelData = null,
  rssValidationLoading = false
}) => {

  const USE = 'Use', UPDATE = 'Update', CREATE = 'Create', CANCEL = 'Cancel';

  const [showModal, setShowModal] = useState(show);
  const [selectedRow, setSelectedRow] = useState(null);
  const [createUpdateMode, setCreateUpdateMode] = useState(null);
  const [newChannelURL, setNewChannelURL] = useState('');
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (show) {
      body.classList.add('removeScrolls');
    } else {
      body.classList.remove('removeScrolls');
    }

    return () => {
      body.classList.remove('removeScrolls');
    };
  }, [show]);

  const modalInnerClass = () => {
    if (size === 'sm') {
      return 'modalInnerWrap sm';
    }
    if (size === 'md') {
      return 'modalInnerWrap md';
    }
    if (size === 'md2') {
      return 'modalInnerWrap md2';
    }
    if (size === 'lg') {
      return 'modalInnerWrap lg';
    }
    if (size === 'otp') {
      return 'modalInnerWrap otp';
    }
    return 'modalInnerWrap';
  };

  const modalFooter = () => {
    return (
      <Fragment>
        <div className='modalFooter duplicateChannelFooter' style={{ textAlign: buttonAlignment }}>
          <div className='btnPrimaryWrapper'>
            <button
              id={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
              className={`btnPrimary`}
              name={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
              onClick={() => accept(USE, selectedRow)}
              disabled={!selectedRow || rssValidationLoading}
            >
              {USE}
            </button>
          </div>
          <div className='btnPrimaryWrapper'>
            <button
              id={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
              className={`btnPrimary`}
              name={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
              onClick={() => {
                if (!rssValidationLoading) {
                  setIsUpdateLoading(true);
                  setNewChannelURL(selectedRow.rss_link);
                  setCreateUpdateMode(UPDATE);
                }
              }}
              disabled={!selectedRow || (rssValidationLoading && !isUpdateLoading)}
              show
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {UPDATE}
                {isUpdateLoading && rssValidationLoading && <ButtonLoaderComponent importedClassNames='loaderImage' />}
              </div>
            </button>
          </div>
          <div className='btnPrimaryWrapper'>
            <button
              id={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
              className={`btnPrimary`}
              name={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
              onClick={() => {
                if (!rssValidationLoading) {
                  setIsCreateLoading(true);
                  setCreateUpdateMode(CREATE);
                }
              }}
              disabled={rssValidationLoading && !isCreateLoading}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {CREATE}
                {isCreateLoading && rssValidationLoading && <ButtonLoaderComponent importedClassNames='loaderImage' />}
              </div>
            </button>
          </div>
          <button
            id={getUniqueIdentifier(buttonUI, 'RejectCTA')}
            className='btnSecondary'
            name={getUniqueIdentifier(buttonUI, 'RejectCTA')}
            onClick={() => {
              reject();
              setIsUpdateLoading(false);
              setIsCreateLoading(false);
            }}
            disabled={rssValidationLoading}
          >
            {CANCEL}
          </button>
        </div>
      </Fragment>
    );
  };

  const renderChannelList = (selectionAvailable) => {
    return (
      <Container fluid className='duplicateChannelFooter'>
        <Row>
          <Col xs={4} sm={4} md={4} lg={4}>Show Name</Col>
          <Col xs={6} sm={6} md={6} lg={6}>RSS URL</Col>
        </Row>
        <Form>
        {
          channelData.map((channel) => (
            <Row key={channel.id}>
              <Col xs={4} sm={4} md={4} lg={4}>
                <div className='onDayWrapper'>
                  {selectionAvailable && (<Form.Check
                    name="channel"
                    type={"radio"}
                    id={channel.id}
                    onChange={() => {
                      setSelectedRow(channel);
                    }}
                  />)}
                  <div style={selectionAvailable ? { flex: 1, marginLeft: '10px' } : {flex:1}}>
                    {channel.title}
                  </div>
                </div>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8}>{channel.rss_link}</Col>
            </Row>
          ))
        }
        </Form>
      </Container>
    );
  };

  const renderMessageBody = () => {
    return (
      <div className='diologContent duplicateRSSBody'>
        <div>
        The RSS feed URL you have entered already exists. Please select the Show Name with the correct RSS URL you would like to use for this Campaign and select the Use button.
        </div>
        {renderChannelList(true)}<br/>
        If you do not see the URL you need, please select the Create button.
      </div>
    );
  };

  const renderCreateUpdateInput = () => {
    return (
      <Container fluid>
      <Col xl={12} lg={12} md={12} sm={12} className='form-field createUpdateChannelWrapper'>
        <div style={{ flex: 1}} >
        <CommonInput
          label={`Enter RSS Feed URL to ${createUpdateMode === CREATE ? 'create' : 'update'}`}
          inputValue={newChannelURL}
          setInputValue={(val) => {
            setNewChannelURL(val);
          }}
          labelClass={'requiredInput'}
          placeholder={'Enter RSS Feed URL'}
        />
        </div>
      </Col>
      </Container>
    );
  };

  const createUpdateModalFooter = () => {
    return (
      <Fragment>
        <div className='modalFooter' style={{ textAlign: buttonAlignment }}>
          <div className='btnPrimaryWrapper'>
            <button
              id={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
              className={`btnPrimary`}
              name={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
              onClick={() => {
                const selectedVal = selectedRow;
                accept(createUpdateMode, selectedVal, newChannelURL);
                setCreateUpdateMode(null);
                setSelectedRow(null);
              }}
              disabled={!newChannelURL}
            >
              {createUpdateMode}
            </button>
          </div>
          <button
            id={getUniqueIdentifier(buttonUI, 'RejectCTA')}
            className='btnSecondary'
            name={getUniqueIdentifier(buttonUI, 'RejectCTA')}
            onClick={() => {
              setCreateUpdateMode(null);
              setSelectedRow(null);
            }}
          >
            {CANCEL}
          </button>
        </div>
      </Fragment>
    );
  };

  return (
    <div
      id={buttonUI + 'Modal'}
      name={buttonUI + 'Modal'}
      className={`customModal modalDiolog${showModal ? ' show' : ''}`}
    >
      <div className={modalInnerClass()} id='customModal'>
        <div className='modalHeader'>
          <div className='diologTitle'>{header}</div>
        </div>
        <div className='modalBody'>
          {createUpdateMode ? renderCreateUpdateInput() : renderMessageBody()}
        </div>
        {createUpdateMode ? createUpdateModalFooter() : modalFooter()}
      </div>
    </div>
  );


};

export default DuplicateRSSChannelModal;

const getUniqueIdentifier = (ui, suffix) => {
  return ui ? ui + suffix : undefined;
};

export const podcastColumn = () => {
  return [
    {
      id: 'channelId',
      name: 'ID',
      selector: row => row.id,
      cell: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle table-cell-font-size'>{row.id}</div>
        </div>
      ),
      width: '10%',
      style: { overflow: 'hidden' },
    },
    {
      id: 'title',
      name: 'Title',
      selector: row => row.title,
      cell: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle table-cell-font-size'>{row.title}</div>
        </div>
      ),
      width: '30%',
    },
    {
      id: 'rss_link',
      name: 'RSS URL',
      selector: row => row.rss_link,
      cell: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle table-cell-font-size'>{row.rss_link}</div>
        </div>
      ),
      width: '50%',
    }
  ];
};

