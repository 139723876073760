/**To configure role based routes */

import roles from './roles';

import {
  Dashboard,
  Campaigns,
  CampaignDetailsPage,
  CreateCampaign,
  Reports,
  ReportDetails,
  Advertisers,
  Properties,
  Users,
  CreateProperties,
  PropertyDetails,
  GoalEdit,
  Admin,
  PageNotFound,
  CreatePlacement,
  EditPlacement,
  ClonePlacement,
  AccountDetailsComponent,
} from '../../pages';
import PartnerDetails from '../../pages/partnerDetails/PartnerDetails';

export const routeConfig = [
  {
    name: 'Campaigns',
    onNavbar: false,
    component: Campaigns,
    path: '/',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'DASHBOARD',
    onNavbar: false,
    component: Dashboard,
    path: '/dashboard',
    permissions: [
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'CAMPAIGNS',
    onNavbar: true,
    component: Campaigns,
    path: '/campaigns',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
    directories: ['campaigns', 'campaign', 'placement'],
  },
  {
    name: 'CREATE CAMPAIGN',
    onNavbar: false,
    component: CreateCampaign,
    path: '/campaign/new',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'CAMPAIGN DETAILS',
    onNavbar: false,
    component: CampaignDetailsPage,
    path: '/campaign/:id',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'REPORTS',
    onNavbar: true,
    component: Reports,
    path: '/reports',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.REPORTING,
      roles.SUPER_ADMIN,
    ],
    directories: ['reports'],
  },
  {
    name: 'REPORT DETAILS',
    onNavbar: false,
    component: ReportDetails,
    path: '/reports/:reportId',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.REPORTING,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'ADVERTISERS',
    onNavbar: true,
    component: Advertisers,
    path: '/advertisers',
    permissions: [
      roles.ADMIN,
      roles.SUPER_ADMIN,
    ],
    directories: ['advertisers'],
  },
  {
    name: 'EDIT ADVERTISER',
    onNavbar: false,
    component: Advertisers,
    path: '/advertisers/:advertiserId',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'PROPERTIES',
    onNavbar: true,
    component: Properties,
    path: '/properties',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
    directories: ['properties'],
  },
  {
    name: 'USERS',
    onNavbar: true,
    component: Users,
    path: '/users',
    permissions: [
      roles.ADMIN,
      roles.SUPER_ADMIN,
    ],
    directories: ['users'],
  },
  {
    name: 'EDIT/VIEW USER DETAILS',
    onNavbar: false,
    component: Users,
    path: '/users/:userId',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'CREATE PROPERTIES',
    onNavbar: false,
    component: CreateProperties,
    path: '/properties/new',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  // {
  //   name: 'PROPERTY DETAILS',
  //   onNavbar: false,
  //   component: PropertyDetails,
  //   path: '/properties/:advertiserId/:id',
  //   permissions: [
  //     roles.ADMIN,
  //     roles.MANAGER,
  //     roles.REPORTING,
  //     roles.SUPER_ADMIN,
  //   ],
  // },
  {
    name: 'PROPERTY DETAILS',
    onNavbar: false,
    component: PropertyDetails,
    path: '/properties/:id',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'EDIT GOAL',
    onNavbar: false,
    component: GoalEdit,
    path: '/properties/:id/goals/new',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'ADMIN',
    onNavbar: true,
    path: '',
    permissions: [roles.SUPER_ADMIN],
    directories: ['admin'],
  },
  {
    name: 'ACCOUNTS',
    onNavbar: false,
    component: Admin,
    path: '/admin/accounts',
    permissions: [roles.SUPER_ADMIN],
  },
  {
    name: 'CREATE ACCOUNT',
    onNavbar: false,
    component: Admin,
    path: '/admin/accounts/new',
    permissions: [roles.SUPER_ADMIN],
  },
  {
    name: 'ACCOUNTS DETAILS',
    onNavbar: false,
    component: AccountDetailsComponent,
    path: '/admin/accounts/:accountId',
    permissions: [roles.SUPER_ADMIN],
  },
  {
    name: 'CAMPAIGN LOOKUP',
    onNavbar: false,
    component: Admin,
    path: '/admin/campaign-lookup',
    permissions: [roles.SUPER_ADMIN],
  },
  {
    name: 'PARTNERS',
    onNavbar: false,
    component: Admin,
    path: '/admin/partners',
    permissions: [roles.SUPER_ADMIN],
  },
  {
    name: 'PARTNER DETAILS',
    onNavbar: false,
    component: PartnerDetails,
    path: '/admin/partners/:id',
    permissions: [roles.SUPER_ADMIN],
  },
  {
    name: 'CREATE PLACEMENT',
    onNavbar: false,
    component: CreatePlacement,
    path: '/campaign/:id/placement/new',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  {
    name: 'Edit PLACEMENT',
    onNavbar: false,
    component: EditPlacement,
    path: '/campaign/:campaignId/placement/:placementId/edit',
    permissions: [
      roles.ADMIN,
      roles.MANAGER,
      roles.SUPER_ADMIN,
    ],
  },
  { onNavbar: false, component: PageNotFound, path: '*' },
];
