/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  CommonButtonComponent,
  CommonHeadingComponent,
  CommonSearch,
  CommonTablesComponent,
  ErrorComponent,
} from '../../../../components';
import columns from './usersColumns';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import { baseEndpoints } from '../../../../constants/endpoints';

import '../../users.css';

const baseInputParams = {
    sortField: 'last_name',
    order: 'asc',
    pageNumber: 1,
    pageSize: 10,
    searchParam: '',
  },
  sortColumns = [
    'lastName',
    'firstName',
    'email',
    'role',
    'lastConnect',
    'statusLabel',
  ];

// User Listing Component
const UsersList = () => {
  const client = useSelector((state) => state.client); // listener to client change in global header
  const userProfile = useSelector(state => state.user?.role) // Fetch current user profile from state
  // Add additional sorting column for Super Admin
  if(userProfile.role === "super admin" && !sortColumns.includes('organization')){
    sortColumns.push('organization')
  }

  const navigate = useNavigate();

  const [isMounted, setIsMounted] = useState(false),
    [params, setParams] = useState(null),
    [searchInput, setSearchInput] = useState(''),
    [inputParams, setInputParams] = useState(null),
    [isClientUpdated, setIsClientUpdated] = useState(true),
    [isCountAtClientLevel, setIsCountAtClientLevel] = useState(false),
    [rowCountAtClientLevel, setRowCountAtClientLevel] = useState(0),
    [showReinviteButton, setShowReinviteButton] = useState(false),
    [showInviteStatus, setShowInviteStatus] = useState(false),
    [selectedTableSize, setSelectedTableSize] = useState(Number(localStorage.getItem('usersPageSize')) || 10);
    
  // States for fetching Advertisers List
  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.users
  );

  /**
   * First time to initialize api calls and set up data
   * @dependencies : client, page-number, sort-data, per-page-data
   */
  useEffect(() => {
    if (inputParams) {
      setParams({
        userId: client.selectedClient,
        ...inputParams,
      });
      setIsMounted(true);
    }
  }, [inputParams]);

  useEffect(() => {
    if (isClientUpdated) {
      return setIsClientUpdated(false);
    }

    const timer = setTimeout(() => {
      setInputParams({
        ...inputParams,
        pageNumber: baseInputParams.pageNumber,
        searchParam: searchInput,
        pageSize: selectedTableSize,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  useEffect(() => {
    setIsClientUpdated(true);
    setIsCountAtClientLevel(true);
    setSearchInput('');
    setInputParams(
      !inputParams
        ? { ...baseInputParams, pageSize: selectedTableSize }
        : {
            ...baseInputParams,
            sortField: inputParams.sortField,
            order: inputParams.order,
            pageSize: selectedTableSize,
          }
    );
  }, [client]);

  useEffect(() => {
    if (!isLoading) {
      setIsMounted(false);
    }
    if (isCountAtClientLevel) {
      setRowCountAtClientLevel(data?.data_count ?? 0);
      setIsCountAtClientLevel(false);
    }
    setShowReinviteButton(false);
    setShowInviteStatus(false);
    data?.table_data?.forEach(item => {
      if ((item.cognitoInviteStatus === 'Invited' || item.cognitoInviteStatus === 'Expired') && !item.sso_managed) setShowInviteStatus(true);
      if (item.cognitoInviteStatus === 'Expired' && !item.sso_managed) setShowReinviteButton(true);
    })
  }, [data]);

const handlePageSizeChange = (pageSize) => {
    localStorage.setItem('usersPageSize', pageSize.toString());
    setSelectedTableSize(pageSize);
  };

  useEffect(() => {
    const previousSelection = Number(localStorage.getItem('usersPageSize') ?? 10);
    setSelectedTableSize(previousSelection);
  }, []);

  return (
    <div className='campaignListWrapper propertyListWrapper activeCampaignsOuterWrapper'>
      <CommonHeadingComponent
        headingLabel='Users'
        url={null}
        isDisabled={true}
      />
      <div className='campaignListSubheader justify-content-between'>
        <div className='d-flex'>
          <CommonSearch
            placeholder='Search Users'
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            disabled={!rowCountAtClientLevel || isLoading || isError}
            uniqueIdentifier='allUsers'
            autofocus={true}
          />
        </div>
        <CommonButtonComponent
          label='Create User'
          appendWrapperClassName='newPropertyBtnWrapper'
          appendButtonClassName='newPropertyBtnBody'
          onClickCTA={() => navigate('/users/new')}
          buttonUI='allUsersCreateUser'
        />
      </div>
      <React.Fragment>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div>
                {isError ? (
                  <ErrorComponent refreshAction={() => setParams({ ...params })} />
                ) : (
                  <CommonTablesComponent
                    uniqueId='userList'
                    data={data?.table_data ?? []}
                    columns={columns(userProfile, showReinviteButton, showInviteStatus, setIsMounted)}
                    isLoading={isLoading}
                    customTableHeader='Advertisers'
                    setSortData={(sortData) =>
                      setInputParams({ ...inputParams, ...sortData })
                    } // sorting
                    sortColumnIds={sortColumns} // sorting
                    defaultSortingApplied={{
                      column: 'lastName',
                      order: baseInputParams.order,
                    }} // sorting
                    isPaginationApplied={true}
                    totalDataCount={data?.data_count ?? 0}
                    dataPerPage={inputParams?.pageSize}
                    paginationOptions={{ noRowsPerPage: false }}
                    setDataPerPage={(pageSize) => {
                      handlePageSizeChange(pageSize);
                      setInputParams({
                        ...inputParams,
                        pageNumber: baseInputParams.pageNumber,
                        pageSize,
                      })
                    }}
                    pageIndex={inputParams?.pageNumber}
                    setPageIndex={(pageNumber) =>
                      setInputParams({ ...inputParams, pageNumber })
                    }
                    noDataMessage='No Users Available'
                    noDataMessageFontSize={15}
                    noDataCompWidth='180'
                    noDataCompHeight='180'
                    foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
};
export default UsersList;
