import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
} from '../../../../../../components';
import CommonDonutChart from '../../../../../../components/charts/CommonDonutChart.component';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import {
  mediaTypeColors,
  // deviceColors,
  partnersColors,
  placementTypeColors,
  baseEndpoints,
} from '../../../../../../constants';
import './distributionSection.css';
import { dateFormat, getArrayOfData } from '../../../../../../utils/functions';
import { Col, Container, Row } from 'react-bootstrap';

const DistributionSection = ({
  campaign,
  reportComponentIsLoading,
  isSummaryError,
  startDate,
  endDate,
  haveSetDates,
  placement,
}) => {
  const advertiser = useSelector((state) => state.advertiser);

  const [isLoading, setIsLoading] = useState(true);
  const [cancelController, setCancelController] = useState(null);
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState(200);
  const [donutData, setDonutData] = useState({
    mediaType: [],
    partners: [],
    placements: [],
    // deviceType: [],
  });

  useEffect(() => {
    if (
      advertiser?.selectedAdvertiser !== -1 &&
      campaign?.campaign_id &&
      startDate &&
      endDate &&
      placement?.length
    ) {
      const controller = new AbortController();
      setCancelController(controller);
      getDistributionData(controller);
      return () => {
        controller.abort();
      };
    } else if (
      Object.values(reportComponentIsLoading).includes(true) ||
      !haveSetDates
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setDonutData({
        mediaType: [],
        partners: [],
        placements: [],
        // deviceType: [],
      });
    }
  }, [
    campaign,
    reportComponentIsLoading,
    startDate,
    endDate,
    haveSetDates,
    placement,
  ]);

  const stringToFloat = (data) => {
    let temp = [];
    data.length > 1 &&
      data.map((val, i) => {
        temp = [...temp, { name: val.name, value: parseFloat(val.value) }];
      });
    return temp;
  };

  const getDistributionData = async (controller) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const distributionDetails = await axiosSendRequest(
        `${baseEndpoints.reports}/media-delivery-tab/distribution`,
        controller.signal,
        {
          campaign_id: campaign?.campaign_id,
          advertiser_id: advertiser.selectedAdvertiser,
          placement_ids: getArrayOfData(placement, 'placement_id'),
          //added date format as API has date validation (YYYY-MM-DD)
          start_date: dateFormat(startDate, 'YYYY-MM-DD'),
          end_date: dateFormat(endDate, 'YYYY-MM-DD'),
        }
      );
      setStatus(distributionDetails.status);

      if (distributionDetails.status === 200) {
        setDonutData({
          mediaType: stringToFloat(distributionDetails?.media),
          partners: stringToFloat(distributionDetails?.partners),
          placements: stringToFloat(distributionDetails?.placements),
          // deviceType: stringToFloat(distributionDetails?.devices),
        });
      }
      setIsLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        if (err.response) {
          console.log({ err });
          setIsError(true);
        } else {
          setStatus(500);
        }
        setIsError(true);
        setIsLoading(false);
      }
    }
  };

  const renderComponent = () => {
    if (isLoading || Object.values(reportComponentIsLoading).includes(true)) {
      return (
        <div className='distributionSecitonRow noBorder'>
          <LoaderComponent />
        </div>
      );
    } else {
      return (
        // <div className='distributionSecitonRow'>
        //   <div className='distributionInnerRow'>
        //     <CommonDonutChart
        //       data={donutData.mediaType}
        //       colors={mediaTypeColors}
        //       label={'Distribution by Media Type'}
        //       tooltip={true}
        //     />
        //     <CommonDonutChart
        //       data={donutData.deviceType}
        //       colors={deviceColors}
        //       label={'Distribution by Device'}
        //       tooltip={true}
        //     />
        //   </div>
        //   <div className='distributionInnerRow'>
        //     <CommonDonutChart
        //       data={donutData.partners}
        //       colors={partnersColors}
        //       label={'Distribution: Top 5 Partners'}
        //       tooltip={true}
        //     />
        //     <CommonDonutChart
        //       data={donutData.placements}
        //       colors={placementTypeColors}
        //       label={'Distribution: Top 5 Placements'}
        //       tooltip={true}
        //     />
        //   </div>
        // </div>
        <Container fluid>
          <Row>
            <Col lg={4} md={3} sm={12}>
              <CommonDonutChart
                data={donutData.mediaType}
                colors={mediaTypeColors}
                label={'Distribution by Media Type'}
                tooltip={true}
              />
            </Col>

            <Col lg={4} md={3} sm={12}>
              <CommonDonutChart
                data={donutData.partners}
                colors={partnersColors}
                label={'Distribution: Top 5 Partners'}
                tooltip={true}
              />
            </Col>

            <Col lg={4} md={3} sm={12}>
              <CommonDonutChart
                data={donutData.placements}
                colors={placementTypeColors}
                label={'Distribution: Top 5 Placements'}
                tooltip={true}
              />
            </Col>
          </Row>
        </Container>
      );
    }
  };
  return (
    <div className='distributionSecitonWrapper'>
      <CommonHeadingComponent headingLabel={'Distribution'} isDisabled={true} />
      {isError || isSummaryError ? (
        <ErrorComponent
          refreshAction={getDistributionData}
          controller={cancelController}
          status={status}
        />
      ) : (
        renderComponent()
      )}
    </div>
  );
};

export default DistributionSection;
