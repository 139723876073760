import React, { useEffect, useRef, useState } from 'react';
import './newMultiSelect.css';
import { LoaderComponent } from '../../../../../../../../components';

const NewMultiSelect = ({
  options,
  selected,
  setSelected,
  dropDownId = 'dropDownId',
  dataId = 'id',
  dataName = 'name',
  isMaxSelect = false,
  selectUpto = 10,
  label = 'label',
  setOnlyOnBlur = false,
  placeholderTxt = 'Select',
  componentWrapperStyle = null,
  labelStyle = null,
  dropDownWrapperStyle = null,
  isLoading = false,
  isDisable = false,
  loaderWidth = { width: '26px' },
  prefix = '',
}) => {
  const [clicked, setClicked] = useState(false),
    [filteredList, setFilteredList] = useState([]),
    [searchField, setSearchField] = useState(''),
    [tempList, setTempList] = useState(selected);

  let maxed =
    isMaxSelect && !setOnlyOnBlur
      ? selected?.length >= selectUpto
      : tempList?.length >= selectUpto;

  const divRef = useRef(null),
    ulRef = useRef(null),
    liRef = useRef(null),
    spanRef = useRef(null),
    inputRef = useRef(null), //
    selectedChildRef = useRef(-1),
    lastSelectedChildRef = useRef(-1),
    ifScrolledRef = useRef(false),
    scrollPosCountUp = useRef(6),
    scrollPosCountDown = useRef(0),
    ifHovered = useRef(false);

  const toggleOption = (data) => {
    if (selected) {
      setSelected((prevSelected) => {
        // if it's in, remove
        const newArray = [...prevSelected];

        if (
          newArray?.find(
            (item) => JSON.stringify(item) === JSON.stringify(data)
          )
        ) {
          return newArray.filter((item) => item[dataId] != data[dataId]);
          // else, add
        } else {
          newArray.push(data);
          return newArray;
        }
      });
    } else {
      setSelected([]);
    }
  };

  const toggleOptionOnBlur = (data) => {
    if (tempList) {
      setTempList((prevSelected) => {
        // if it's in, remove
        const newArray = [...prevSelected];

        if (
          newArray?.find(
            (item) => JSON.stringify(item) === JSON.stringify(data)
          )
        ) {
          return newArray.filter((item) => item[dataId] != data[dataId]);
          // else, add
        } else {
          newArray.push(data);
          return newArray;
        }
      });
    } else {
      setTempList([]);
    }
  };

  const checkOptions = (option) => {
    return !setOnlyOnBlur
      ? selected?.find(
          (item) => JSON.stringify(item) === JSON.stringify(option)
        )
      : tempList?.find(
          (item) => JSON.stringify(item) === JSON.stringify(option)
        );
  };

  const restrictSelection = () => {
    maxed = !setOnlyOnBlur
      ? selected?.length >= selectUpto
      : tempList?.length >= selectUpto;
  };

  const filterOptions = () => {
    setFilteredList(
      options?.filter((val) =>
        val[dataName]?.toLowerCase()?.includes(searchField.toLowerCase())
      )
    );
  };

  const placeholder = () => {
    if (!options) {
      return `${placeholderTxt} Unavailable`;
    }
    if (options && !selected?.length) {
      return `Select ${placeholderTxt}`;
    }
    if (selected.length === 1) {
      return `${selected[0][dataName]}`;
    }
    if (selected.length === options?.length) {
      return `All Selected`;
    }
    return `${selected[0][dataName]}, ${selected.length - 1} more selected`;
  };

  useState(() => {
    if (isMaxSelect) {
      restrictSelection();
    }
  }, [options, selected, isMaxSelect, tempList]);

  useState(() => {
    setFilteredList(options);
  }, [options]);

  useEffect(() => {
    const closeDropdown = (e) => {
      const path = e.path || e?.composedPath();
      if (
        !path.includes(divRef.current) &&
        !path.includes(ulRef.current) &&
        !path.includes(liRef.current) &&
        !path.includes(inputRef.current) &&
        !path.includes(spanRef.current)
      ) {
        setClicked(false);
        resetKeyEvents(true);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.addEventListener('click', closeDropdown);
  }, []);

  useEffect(() => {
    filterOptions();
  }, [options, searchField]);

  useEffect(() => {
    if (!clicked) {
      setSearchField('');
    }
  }, [clicked]);

  useEffect(() => {
    setTempList(selected);
  }, [options, selected]);

  useEffect(() => {
    if (clicked) {
      const outerElem = document.querySelector(
        `#${dropDownId}.newMultiSelectWrapper div.placeholderOrInputWrapper input.searchInputForMultiSelect`
      );
      outerElem.focus();
    }

    if (!clicked && setOnlyOnBlur) {
      setSelected(tempList);
    }
  }, [clicked]);

  const handleKeyPressEvents = (e) => {
    const elem = document.querySelector(
      `#${dropDownId}.newMultiSelectWrapper .multiSelectUlWrapper`
    );

    if (
      e.code === 'ArrowDown' &&
      selectedChildRef.current < filteredList.length - 1
    ) {
      const newSelectedChild = selectedChildRef.current + 1,
        elemNew = elem.children[newSelectedChild],
        elemOld = elem.children[selectedChildRef.current];
      elemOld?.classList?.remove('ddSsOnKeyFocused');
      elemNew?.classList?.add('ddSsOnKeyFocused');

      if (scrollPosCountDown.current < 6) {
        scrollPosCountUp.current = scrollPosCountUp.current - 1;
        scrollPosCountDown.current = scrollPosCountDown.current + 1;
      }

      if (newSelectedChild > 5 && scrollPosCountDown.current === 6) {
        ifScrolledRef.current = true;
        elem.scrollTop = 30 * (newSelectedChild - 5);
      }

      if (ifHovered.current) {
        ifHovered.current = false;
        elem.scrollTop = 0;
      }

      if (lastSelectedChildRef.current > -1) {
        lastSelectedChildRef.current = -1;
        elem.scrollTop = 0;
      }

      selectedChildRef.current = newSelectedChild;
      return;
    }

    if (e.code === 'ArrowUp' && selectedChildRef.current > 0) {
      const newSelectedChild = selectedChildRef.current - 1,
        elemNew = elem.children[newSelectedChild],
        elemOld = elem.children[selectedChildRef.current];
      elemOld?.classList?.remove('ddSsOnKeyFocused');
      elemNew?.classList?.add('ddSsOnKeyFocused');

      if (scrollPosCountUp.current < 6) {
        scrollPosCountDown.current = scrollPosCountDown.current - 1;
        scrollPosCountUp.current = scrollPosCountUp.current + 1;
      }

      if (
        newSelectedChild < filteredList?.length - 6 &&
        scrollPosCountUp.current === 6
      ) {
        ifScrolledRef.current = true;
        elem.scrollTop =
          elem.scrollHeight - 30 * (filteredList?.length - newSelectedChild);
      }

      selectedChildRef.current = newSelectedChild;
      return;
    }

    if (
      e.code === 'Enter' &&
      selectedChildRef.current >= 0 &&
      elem.children[selectedChildRef.current].getAttribute('index')
    ) {
      const checkboxElem = elem.children[selectedChildRef.current].children[0],
        ifChecked = !checkboxElem.checked,
        index = checkboxElem.getAttribute('value'),
        name = checkboxElem.getAttribute('name');
      checkboxElem.checked = ifChecked;

      if (!setOnlyOnBlur) {
        toggleOption({
          [dataId]: !isNaN(index) ? Number(index) : index,
          [dataName]: name,
        });

        return;
      }

      toggleOptionOnBlur({
        [dataId]: !isNaN(index) ? Number(index) : index,
        [dataName]: name,
      });
    }
  };

  const removeFocusedOnHover = (e) => {
    if (ifScrolledRef.current) {
      ifScrolledRef.current = false;
      return;
    }

    resetKeyEvents();
  };

  const resetKeyEvents = (scrollReset = false) => {
    scrollPosCountUp.current = 6;
    scrollPosCountDown.current = 0;
    ifHovered.current = true;

    const elem = document.querySelector(
        `#${dropDownId}.newMultiSelectWrapper .multiSelectUlWrapper`
      ),
      elemOld = elem?.children[selectedChildRef.current];
    elemOld?.classList?.remove('ddSsOnKeyFocused');
    selectedChildRef.current = -1;

    if (scrollReset && elem) elem.scrollTop = 0;
  };

  const renderElem = () => {
    return (
      <div
        className={
          clicked
            ? 'placeholderOrInputWrapper show'
            : 'placeholderOrInputWrapper'
        }
      >
        {clicked ? (
          <>
            <input
              placeholder={`Search ${placeholderTxt}`}
              value={searchField}
              onChange={(e) => {
                resetKeyEvents(true);
                setSearchField(e.target.value);
              }}
              className='searchInputForMultiSelect'
            />
            <ul className='multiSelectUlWrapper ' ref={ulRef}>
              {filteredList?.length ? (
                filteredList.map((option) => {
                  const find = checkOptions(option);
                  const isSelected = !!find;

                  return !isSelected && maxed ? (
                    <li
                      key={option[dataId] + option[dataName]}
                      ref={liRef}
                      className='multiSelectLiWrapper disableMultiSelect'
                      onMouseMove={removeFocusedOnHover}
                    >
                      <input
                        type='checkbox'
                        checked={isSelected}
                        ref={inputRef}
                        className='multiSelectInputWrapper'
                        value={option[dataId]}
                        disabled={true}
                        name={option[dataName]}
                      />
                      <span ref={spanRef} className='newMultiSelectSpanOption'>
                        {option.type
                          ? ` ${option[dataName]} (#${option[dataId]} | ${option.type})`
                          : `${option[dataName]}`}
                      </span>
                    </li>
                  ) : (
                    <li
                      onClick={() => {
                        !setOnlyOnBlur
                          ? toggleOption({
                              [dataId]: option[dataId],
                              [dataName]: option[dataName],
                            })
                          : toggleOptionOnBlur({
                              [dataId]: option[dataId],
                              [dataName]: option[dataName],
                            });
                      }}
                      key={option[dataId] + option[dataName]}
                      ref={liRef}
                      className='multiSelectLiWrapper hover'
                      onMouseMove={removeFocusedOnHover}
                      index={option[dataId]}
                    >
                      <input
                        type='checkbox'
                        checked={isSelected}
                        ref={inputRef}
                        className='multiSelectInputWrapper'
                        value={option[dataId]}
                        onChange={() => {}}
                        name={option[dataName]}
                      />
                      <span ref={spanRef} className='newMultiSelectSpanOption'>
                        {option.type
                          ? ` ${option[dataName]} (#${option[dataId]} | ${option.type})`
                          : `${option[dataName]}`}
                      </span>
                    </li>
                  );
                })
              ) : (
                <li style={{ pointerEvents: 'none' }}>
                  <span className='newMultiSelectSpanOption noRecord'>
                    No Record Found
                  </span>
                </li>
              )}
            </ul>
          </>
        ) : (
          <>
            {/* <div>{selected?.length} selected</div> */}
            <div
              className={
                !isDisable
                  ? `newMultiSelectPlaceholderWrapper enableMultiSelect ${prefix}Wrapper`
                  : `newMultiSelectPlaceholderWrapper disableMultiSelect ${prefix}`
              }
            >
              {!isDisable ? (
                <div
                  className={`newMultiSelectPlaceholder ${prefix}`}
                  title={placeholder()}
                >
                  {placeholder()}
                </div>
              ) : (
                <div className='newMultiSelectPlaceholder disableMultiSelect'>
                  {placeholder()}
                </div>
              )}
              <div className={`arrow`}></div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className='newMultiSelectOuterWrapper' style={componentWrapperStyle}>
      <div className='multiSelectLableWrapper' style={labelStyle}>
        <label>{label}</label>
      </div>

      {!isDisable && (
        <div
          id={dropDownId}
          onClick={() => setClicked(true)}
          ref={divRef}
          className='newMultiSelectWrapper'
          style={dropDownWrapperStyle}
          onKeyDown={handleKeyPressEvents}
        >
          {renderElem()}
        </div>
      )}

      {isDisable && (
        <div
          id={dropDownId}
          ref={divRef}
          className='newMultiSelectWrapper disableMultiSelect'
          style={dropDownWrapperStyle}
          onKeyDown={handleKeyPressEvents}
        >
          {renderElem()}
        </div>
      )}

      {isLoading && (
        <div className='dropdownLoaderWrapper' style={loaderWidth}>
          <LoaderComponent importedClassNames='newMultiSelectLoader' />
        </div>
      )}
    </div>
  );
};

export default NewMultiSelect;
