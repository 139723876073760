import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toggleClasses } from '../../../../utils/functions';
import { routeConfig } from '../../../../routes/routeConfig/configRoute';
import { getAllowedRoutes } from '../../../../routes/routes.utils';
import SearchableDropdownComponent from '../../../searchableDropdown/SearchableDropdown.component';

import './navbarComponent.css';

const adminOptions = [
  {
    id: 0,
    name: 'Client Accounts',
    path: routeConfig.find((item) => item.name === 'ACCOUNTS').path,
  },
  {
    id: 1,
    name: 'Campaign Lookup',
    path: routeConfig.find((item) => item.name === 'CAMPAIGN LOOKUP').path,
  },
  {
    id: 2,
    name: 'Partners',
    path: routeConfig.find((item) => item.name === 'PARTNERS').path,
  },
];

const NavbarComponent = ({ signOut }) => {
  const navigate = useNavigate(),
    location = useLocation();

  const [showDropDown, setShowDropDown] = useState(false);
  const [navs, setNavs] = useState([]);
  const user = useSelector((state) => state.user);

  const usableLocation = location?.pathname,
    usableSearchParam = location?.search,
    locationMatch = usableLocation?.split('/')[1];

  if (locationMatch === 'admin' && usableSearchParam) {
    const adminOptionsId = adminOptions.find(
      (item) => item.path === usableLocation
    )?.id;

    if (adminOptionsId !== undefined) {
      adminOptions[adminOptionsId].path =
        adminOptions[adminOptionsId].path + usableSearchParam;
    }
  }

  const dropDownRef = useRef(null),
    nevbarAdminRef = useRef(null);

  const [selectedAdminOption, setSelectedAdminoption] = useState(null),
    [showAdminOptions, setShowAdminOptions] = useState(false);

  useEffect(() => {
    setNavs(getAllowedRoutes(routeConfig, user.role));
  }, [user]);

  const detect = () => {
    const userAgentString = navigator.userAgent;
    if (userAgentString.indexOf('Chrome') > -1) {
      return 'Chrome';
    }
    if (userAgentString.indexOf('Firefox') > -1) {
      return 'Firefox';
    }
    if (userAgentString.indexOf('Safari') > -1) {
      return 'Safari';
    }
    if (userAgentString.indexOf('OP') > -1) {
      return 'Opera';
    }
    return 'Unknown ';
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      const tags = [dropDownRef.current];
      if (detect() === 'Firefox' || detect() === 'Safari') {
        if (!tags.includes(e.target)) {
          setShowDropDown(false);
        }
      } else if (detect() === 'Chrome') {
        if (!tags.includes(e.composedPath()[0])) {
          setShowDropDown(false);
        }
      } else if (!tags.includes(e.path[0])) {
        setShowDropDown(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.addEventListener('click', closeDropdown);
  }, []);

  useEffect(() => {
    document.addEventListener('click', toggle);
    return () => document.removeEventListener('click', toggle);
  }, []);

  useEffect(() => {
    if (selectedAdminOption) {
      navigate(selectedAdminOption.path);
    }
  }, [selectedAdminOption]);

  useEffect(() => {
    const adminPaths = adminOptions.map((item) => item.path);
    if (selectedAdminOption) {
      if (adminPaths.indexOf(usableLocation) < 0) {
        setSelectedAdminoption(null);
      }
    } else {
      if (adminPaths.indexOf(usableLocation) >= 0) {
        setSelectedAdminoption(
          adminOptions.find((item) => item.path === usableLocation)
        );
      }
    }
  }, [usableLocation]);

  const toggle = (e) => {
    if (e && e.target !== nevbarAdminRef.current) {
      setShowAdminOptions(false);
    }
  };

  const updateShowAdminOptions = () => {
    setShowAdminOptions(!showAdminOptions);
  };

  return (
    <div className='globalNavWrapper'>
      <Link className='navbarLink' to='/'>
        <div className={'logo'}></div>
      </Link>
      <div className='rightNav'>
        <div className='mainMenu'>
          {navs.map((item) => {
            return (
              item.onNavbar && (
                <div
                  key={item.path}
                  className={
                    item?.directories?.includes(locationMatch)
                      ? 'highlightPage navbarLi'
                      : 'navbarLi'
                  }
                >
                  {item.name !== 'ADMIN' ? (
                    <Link to={item.path}>{item.name}</Link>
                  ) : (
                    <div>
                      <div
                        ref={nevbarAdminRef}
                        className='navbarAdminWrapper'
                        onMouseEnter={() => updateShowAdminOptions()}
                        onMouseLeave={() => updateShowAdminOptions()}
                      >
                        {item.name}
                        {showAdminOptions && (
                          <div>
                            <div className='navbarAdminDropdown'>
                              <SearchableDropdownComponent
                                options={adminOptions}
                                label='name'
                                id='id'
                                dropdownId='navbarAdminElement'
                                dropdownLabel=''
                                labelWidth={{ margin: 0 }}
                                dropdownWidth={{
                                  width: '100px',
                                }}
                                placeholderLabel=''
                                selectedValue={selectedAdminOption}
                                handleChange={setSelectedAdminoption}
                                isSearchable={false}
                                isHeaderHidden={true}
                                isShowDropdownElemAlways={true}
                                alignType='right'
                                uniqueIdentifier='navbarAdminElements'
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>
        <div>
          {/** Help Icon */}
          {/* <div className='helpIcon cursorPointer'></div> */}
          <div
            className='myProfile cursorPointer'
            onClick={() => {
              setShowDropDown((prev) => !prev);
            }}
            ref={dropDownRef}
          >
            <ul>
              <li
                className={toggleClasses(
                  showDropDown,
                  'dropdown show',
                  'dropdown dropdownMenu'
                )}
              >
                <div
                  className={toggleClasses(
                    showDropDown,
                    'dropdownMenu dropdownRight show',
                    'dropdownMenu dropdownRight'
                  )}
                >
                  <Link to='/account/profile'>{user?.email}</Link>
                  <hr />
                  <a
                    id='signOutCTA'
                    name='signOutCTA'
                    className='cursorPointer'
                    onClick={signOut}
                  >
                    Sign Out
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarComponent;
