import React from 'react';
import { Link } from 'react-router-dom';
const moment = require('moment-timezone');
import {
  dateFormat,
  daysToFormatWRTDays,
  formatNumberInThousands,
  getEncryptedOrDecryptedData,
  getTrendTooltipParams,
} from '../../../../utils/functions';
import { CommonTrendChartComponent } from '../../../../components';
import './campaignsSnapshot.css';

export const createEndingSoonColumns = (
  trendChartData,
  tooltipActions,
  setTooltipActions
) => {
  let isAlreadyEntered = false;
  const onHoverTrendCol = (isMouseEntered, colId = null, campaignId = null) => {
    const { isAlreadyEnteredNew, tooltipActionsNew } = getTrendTooltipParams(
      isMouseEntered,
      colId,
      campaignId,
      isAlreadyEntered,
      tooltipActions
    );

    if (isAlreadyEnteredNew !== isAlreadyEntered) {
      isAlreadyEntered = isAlreadyEnteredNew;
      setTooltipActions({
        ...tooltipActions,
        ...tooltipActionsNew,
      });
    }
  };

  return [
    {
      id: 'campaignNameES',
      name: 'CAMPAIGN NAME',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${
              row.campaign_id
            }?params=${getEncryptedOrDecryptedData(
              { name: row.name },
              'encrypt'
            )}`}
          >
            <div className='campaignNameStyleCS'>{row.name}</div>
          </Link>
        </div>
      ),
      sortable: false,
      style: { minWidth: '150px' },
    },
    {
      id: 'endingES',
      name: 'ENDING',
      selector: (row) => (
        <div className='upcomingStartingWrapper'>
          <div className='tableCellFontStyle '>
            {daysToFormatWRTDays(
              moment(moment(row.end_date).format('YYYY-MM-DD')).diff(
                moment().tz('America/New_York').format('YYYY-MM-DD'),
                'days'
              )
            )}{' '}
            ({dateFormat(row.end_date, 'MM/DD')})
          </div>
        </div>
      ),
      sortable: false,
      width: '180px',
    },
    {
      id: 'impressionsES',
      name: 'IMPRESSIONS',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          {formatNumberInThousands(row.imp_count || 0)}
        </div>
      ),
      sortable: false,
      style: { justifyContent: 'flex-end' },
      width: '130px',
      right: true,
    },
    {
      id: 'sevenDayTrendES',
      name: '7 DAY TREND',
      selector: (row) => (
        <div
          id={`cs_es_${row.campaign_id}_trend`}
          className='activeCampTrendWrapper'
          onMouseMove={() =>
            onHoverTrendCol(
              true,
              `cs_es_${row.campaign_id}_trend`,
              row.campaign_id
            )
          }
          onMouseLeave={() => onHoverTrendCol(false)}
        >
          <div className='trendInnerWrapper'></div>
          <div className='activeTrendChart'>
            <CommonTrendChartComponent
              chartData={
                trendChartData.find(
                  (lineData) => lineData.campaign_id === row.campaign_id
                )?.chart_data
              }
              dotRadius={1.5}
            />
          </div>
          <div className='activeCampDataTick'>
            {trendFormat(row.trend_type)}
          </div>
        </div>
      ),
      sortable: false,
      wrap: true,
      width: '160px',
    },
  ];
};

export const createStartingSoonColumns = () => {
  return [
    {
      id: 'campaignNameSS',
      name: 'CAMPAIGN NAME',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link to={`/campaign/${row.campaign_id}?name=${row.name}`}>
            {row.name}
          </Link>
        </div>
      ),
      sortable: false,
      wrap: true,
      style: { minWidth: '300px' },
    },
    {
      id: 'startingSS',
      name: 'STARTING',
      selector: (row) => (
        <div className='upcomingStartingWrapper'>
          <div className='tableCellFontStyle '>
            {daysToFormatWRTDays(
              moment(moment(row.start_date).format('YYYY-MM-DD')).diff(
                moment().tz('America/New_York').format('YYYY-MM-DD'),
                'days'
              )
            )}{' '}
            ({dateFormat(row.start_date, 'MM/DD')})
          </div>
        </div>
      ),
      sortable: false,
      wrap: true,
      width: '180px',
    },
  ];
};

export const trendFormat = (trendType) => {
  return trendType > 0 ? (
    <div className={trendType === 1 ? 'trendDown' : 'trendUp'}></div>
  ) : (
    <div className='trendEqual'>-</div>
  );
};
