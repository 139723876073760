const breadCrumbHeader = {
  dashboard: 'my dashboard',
  campaigns: 'campaigns',
  myReports: 'reports',
  advertisers: 'advertisers',
  advertisersNew: 'new advertiser',
  properties: 'properties',
  users: 'users',
  admin: 'administration',
  clientAccounts: 'client accounts',
  partners: 'partners',
  campaignLookup: 'campaign lookup',
  createAccount: 'create account',
  placements: 'placements'
}

export default breadCrumbHeader
