import React from 'react';

const CommonHeadingComponent = ({
  headingLabel,
  headingCTALabel = null,
  isDisabled = true,
  onClickCTA,
  uniqueIdentifier,
  ifDropdown = false,
  rightSideContent,
}) => {
  return (
    <div>
      {headingLabel ? (
        <div className='tableHeaderWrapper'>
          <div className='tableHeaderContent'>{headingLabel}</div>
          <div className='tableHeaderRightSide'>
            {rightSideContent && rightSideContent}
            {headingCTALabel ? (
              <>
                {!ifDropdown ? (
                  <div
                    className={`tableHeaderViewAll ${
                      isDisabled ? 'tableHeaderDisabled' : ''
                    }`}
                  >
                    {!isDisabled ? (
                      <div
                        id={getUniqueIdentifier(uniqueIdentifier, 'CTA')}
                        name={getUniqueIdentifier(uniqueIdentifier, 'CTA')}
                        onClick={onClickCTA}
                      >
                        {headingCTALabel}
                      </div>
                    ) : (
                      <div
                        id={getUniqueIdentifier(uniqueIdentifier, 'CTA')}
                        name={getUniqueIdentifier(uniqueIdentifier, 'CTA')}
                      >
                        {headingCTALabel}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='tableHeaderViewDropdown'>
                    <div
                      id={getUniqueIdentifier(uniqueIdentifier, 'dropDown')}
                      name={getUniqueIdentifier(uniqueIdentifier, 'dropDown')}
                    >
                      {headingCTALabel}
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CommonHeadingComponent;

const getUniqueIdentifier = (ui, suffix) => {
  return ui ? ui + suffix : undefined;
};
