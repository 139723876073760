import React from "react";
import LoaderComponent from "../loaderComponent/Loader.component";
import "./importButton.css";

const ImportButtonComponent = ({
  label,
  appendWrapperClassName = "",
  appendWrapperStyle = {},
  showArrow = true,
  arrowClassName,
  isDisabled = false,
  showLoader = false,
  onClickCTA,
  appendButtonClassName = "",
  inverted = false,
  isNonHeader = true,
  buttonUI,
}) => {
  return (
    <div
      className={`importButtonOuterWrapper importButtonLeftRightPadding ${appendWrapperClassName} ${
        inverted ? "inverted" : ""
      }`}
      style={appendWrapperStyle}
    >
      <div className="importButtonWrapper">
        <button
          id={buttonUI ? buttonUI + "CTA" : undefined}
          className={`importButtonBody${
            isNonHeader ? " nonHeader" : ""
          } ${appendButtonClassName}`}
          name={buttonUI ? buttonUI + "CTA" : undefined}
          disabled={isDisabled}
          onClick={onClickCTA}
        >
          <div>{label}</div>
          {showArrow && <div className={"bi bi-upload"}></div>}
        </button>
      </div>
      {showLoader && (
        <div className="importButtonLoaderWrapper">
          <LoaderComponent importedClassNames="dropdownsAndDates" />
        </div>
      )}
    </div>
  );
};

export default ImportButtonComponent;
