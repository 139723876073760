import React, { Fragment, useEffect, useState } from 'react';
import './modals.css';

const CommonModal = ({
  show,
  close,
  header = 'Alert',
  body = '',
  accept = null,
  reject = null,
  acceptBtnText = 'OK',
  rejectBtnText = 'Cancel',
  size = 'md',
  disableAcceptBtn = false,
  disableRejectBtn = false,
  hideCloseBtn = false,
  // extra button
  isBtn2Available = false,
  acceptBtn2Text = 'Run & Save',
  btn2Accept = null,
  customAcceptBtnClass = '',
  ifShowingButton2 = false,
  disableAcceptBtn2 = false,
  // Prop to pass custom body to modal or use predefined body
  renderCustomBody = false,
  buttonAlignment = 'center',
  buttonUI,
}) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (show) {
      body.classList.add('removeScrolls');
    } else {
      body.classList.remove('removeScrolls');
    }

    return () => {
      body.classList.remove('removeScrolls');
    };
  }, [show]);

  const modalInnerClass = () => {
    if (size === 'sm') {
      return 'modalInnerWrap sm';
    }
    if (size === 'md') {
      return 'modalInnerWrap md';
    }
    if (size === 'md2') {
      return 'modalInnerWrap md2';
    }
    if (size === 'lg') {
      return 'modalInnerWrap lg';
    }
    if (size === 'otp') {
      return 'modalInnerWrap otp';
    }
    return 'modalInnerWrap';
  };

  const modalFooter = () => {
    return (
      <Fragment>
        <div className='modalFooter' style={{ textAlign: buttonAlignment }}>
          {reject ? (
            <button
              id={getUniqueIdentifier(buttonUI, 'RejectCTA')}
              className='btnSecondary'
              name={getUniqueIdentifier(buttonUI, 'RejectCTA')}
              onClick={reject}
              disabled={disableRejectBtn}
            >
              {rejectBtnText}
            </button>
          ) : null}
          {accept || isBtn2Available ? (
            <div className='btnPrimaryWrapper'>
              <button
                id={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
                className={`btnPrimary ${customAcceptBtnClass}`}
                name={getUniqueIdentifier(buttonUI, 'AcceptCTA')}
                onClick={accept}
                disabled={disableAcceptBtn}
              >
                {acceptBtnText}
              </button>
            </div>
          ) : null}
          {accept || isBtn2Available ? (
            <div className='btnPrimaryWrapper'>
              <button
                id={getUniqueIdentifier(buttonUI, 'Accept2CTA')}
                className={`btnPrimary btnPrimary2${
                  ifShowingButton2 ? ' show' : ' hide'
                }`}
                name={getUniqueIdentifier(buttonUI, 'Accept2CTA')}
                onClick={btn2Accept}
                disabled={disableAcceptBtn2}
              >
                {acceptBtn2Text}
              </button>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  };

  return (
    <div
      id={buttonUI + 'Modal'}
      name={buttonUI + 'Modal'}
      className={`customModal modalDiolog${showModal ? ' show' : ''}`}
    >
      <div className={modalInnerClass()} id='customModal'>
        <div className='modalHeader'>
          {!hideCloseBtn && (
            <div
              id={getUniqueIdentifier(buttonUI, 'CloseCTA')}
              className='close xCircle'
              name={getUniqueIdentifier(buttonUI, 'CloseCTA')}
              onClick={close}
            ></div>
          )}
          <div className='diologTitle'>{header}</div>
        </div>
        <div className='modalBody'>
          <div className='diologContent'>{<div>{body}</div>}</div>
        </div>
        {modalFooter()}
      </div>
    </div>
  );
};

export default CommonModal;

const getUniqueIdentifier = (ui, suffix) => {
  return ui ? ui + suffix : undefined;
};
