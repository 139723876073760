import { createSlice } from '@reduxjs/toolkit'

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientList: [],
    selectedClient: -1 // -1 indicates no client is selected
  },
  reducers: {
    updateClientList: (state, action) => {
      state.clientList = action.payload
    },
    updateSelectedClient: (state, action) => {
      state.selectedClient = action.payload
    },
    resetClient: (state) => {
      state.clientList = [];
      state.selectedClient = -1;
    }
  }
})

export const { updateClientList, updateSelectedClient, resetClient } =
  clientSlice.actions
export default clientSlice.reducer
