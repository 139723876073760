import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  CommonHeadingComponent,
  CommonSearch,
  CommonTablesComponent,
  CommonButtonComponent,
  ErrorComponent,
} from '../../../../components';
import { createColumns } from './propertiesList.utils';
import { baseEndpoints } from '../../../../constants';
import { removeTableClass, setAdvertiserId } from '../../../../utils/functions';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import './propertiesList.css';
import { set } from 'lodash';

const baseInputParams = {
    sortField: 'property_id',
    order: 'desc',
    pageNumber: 1,
    pageSize: 10,
    searchParam: '',
  },
  sortColumns = ['property_id', 'name', 'type', 'identifier', 'status'];

const PropertiesListComponent = () => {
  const client = useSelector((state) => state.client);
  const advertiser = useSelector((state) => state.advertiser),
  role = useSelector((state) => state.user.role);

  const navigate = useNavigate();

  const [columns, setColumns] = useState([]),
    [searchInput, setSearchInput] = useState(''),
    [params, setParams] = useState(null),
    [isMounted, setIsMounted] = useState(false),
    [inputParams, setInputParams] = useState(null),
    [isClientUpdated, setIsClientUpdated] = useState(true),
    [isCountAtClientOrAdvLevel, setIsCountAtClientOrAdvLevel] = useState(false),
    [rowCountAtClientOrAdvLevel, setRowCountAtClientOrAdvLevel] = useState(0),
    [selectedTableSize, setSelectedTableSize] = useState(Number(localStorage.getItem('propertiesPageSize')) || 10);

  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.properties + '/all'
  );
  const advertiserId = setAdvertiserId(advertiser.selectedAdvertiser, advertiser.selectedClientsAdvertiser, role);
  useEffect(() => {
    if (inputParams) {
      setParams({
        timeZone: null,
        userId: client.selectedClient === -1 ? null : client.selectedClient,
        advertiser_id: advertiserId,
        ...inputParams,
      });
      setIsMounted(true);
    }
  }, [inputParams]);

  useEffect(() => {
    setIsClientUpdated(true);
    setIsCountAtClientOrAdvLevel(true);
    setSearchInput('');
    setInputParams(
      !inputParams
        ? { ...baseInputParams, pageSize: selectedTableSize }
        : {
            ...baseInputParams,
            sortField: inputParams.sortField,
            order: inputParams.order,
            pageSize: selectedTableSize,
          }
    );
  }, [client, advertiser]);

  useEffect(() => {
    if (isCountAtClientOrAdvLevel) {
      setRowCountAtClientOrAdvLevel(data?.total_properties ?? 0);
      setIsCountAtClientOrAdvLevel(false);
    }

    setColumns(createColumns(client.selectedClient));
    if (data?.total_properties) {
      removeTableClass('.campaignListWrapper');
    }
  }, [data]);

  useEffect(() => {
    if (isClientUpdated) {
      return setIsClientUpdated(false);
    }

    const timer = setTimeout(() => {
      setInputParams({
        ...inputParams,
        pageNumber: baseInputParams.pageNumber,
        searchParam: searchInput,
        pageSize: selectedTableSize,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  const handlePageSizeChange = (pageSize) => {
    localStorage.setItem('propertiesPageSize', pageSize.toString());
    setSelectedTableSize(pageSize);
  };

  useEffect(() => {
    const previousSelection = Number(localStorage.getItem('propertiesPageSize') ?? 10);
    setSelectedTableSize(previousSelection);
  }, []);

  return (
    <div className='campaignListWrapper propertyListWrapper'>
      <CommonHeadingComponent
        headingLabel='Properties'
        url={null}
        isDisabled={true}
      />
      <div className='campaignListSubheader justify-content-between'>
        <div className='d-flex'>
          <CommonSearch
            placeholder={'Search properties'}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            disabled={!rowCountAtClientOrAdvLevel || isLoading || isError}
            uniqueIdentifier='allProperties'
            autofocus={true}
          />
        </div>
        <CommonButtonComponent
          label='Create Property'
          appendWrapperClassName='newPropertyBtnWrapper'
          appendButtonClassName='newPropertyBtnBody'
          onClickCTA={() => navigate('/properties/new')}
          isDisabled={advertiser.selectedClientsAdvertiser?.length <= 1}
          buttonUI='allPropertiesCreateProperty'
        />
      </div>
      {isError ? (
        <ErrorComponent refreshAction={() => setParams({ ...params })} />
      ) : (
        <CommonTablesComponent
          uniqueId='propertiesList'
          data={data?.table_base_data ?? []}
          columns={columns}
          isLoading={isLoading}
          customTableHeader='Properties'
          setSortData={(sortData) =>
            setInputParams({ ...inputParams, ...sortData })
          } // sorting
          sortColumnIds={sortColumns} // sorting
          defaultSortingApplied={{
            column: baseInputParams.sortField,
            order: baseInputParams.order,
          }} // sorting
          isPaginationApplied={true}
          totalDataCount={data?.total_properties ?? 0}
          pageIndex={inputParams?.pageNumber}
          setPageIndex={(pageNumber) =>
            setInputParams({ ...inputParams, pageNumber })
          }
          dataPerPage={inputParams?.pageSize}
          paginationOptions={{
            noRowsPerPage: false,
          }}
          setDataPerPage={(pageSize) => {
            handlePageSizeChange(pageSize);
            setInputParams({
              ...inputParams,
              pageNumber: baseInputParams.pageNumber,
              pageSize,
            })
          }}
          noDataMessage='No Properties Available'
          noDataMessageFontSize={15}
          noDataCompWidth='180'
          noDataCompHeight='180'
          foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
        />
      )}
    </div>
  );
};

export default PropertiesListComponent;
