import React from 'react';
import { Link } from 'react-router-dom';
import {
  CommonTrendChartComponent,
  CommonRatioChartComponent,
} from '../../../../components';
import {
  dateFormat,
  trendFormat,
  getTrendTooltipParams,
  getEncryptedOrDecryptedData,
} from '../../../../utils/functions';
import aaColors from '../../../../constants/colors';

export const createColumns = (
  trendChartData,
  ratioChartData,
  tooltipActions,
  setTooltipActions,
  windowWidth
) => {
  let isAlreadyEntered = false;
  const onHoverTrendCol = (isMouseEntered, colId = null, campaignId = null) => {
    const { isAlreadyEnteredNew, tooltipActionsNew } = getTrendTooltipParams(
      isMouseEntered,
      colId,
      campaignId,
      isAlreadyEntered,
      tooltipActions
    );

    if (isAlreadyEnteredNew !== isAlreadyEntered) {
      isAlreadyEntered = isAlreadyEnteredNew;
      setTooltipActions({
        ...tooltipActions,
        ...tooltipActionsNew,
      });
    }
  };

  return [
    {
      id: 'campaignIdAC',
      name: 'CAMPAIGN ID',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${
              row.campaign_id
            }?params=${getEncryptedOrDecryptedData(
              { name: row.campaign_name },
              'encrypt'
            )}`}
          >
            <div className='campaignNameStyleAC'>{row.campaign_id}</div>
          </Link>
        </div>
      ),
      sortable: false,
      width: '132px',
      right: true,
    },
    {
      id: 'campaignNameAC',
      name: 'CAMPAIGN NAME',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${
              row.campaign_id
            }?params=${getEncryptedOrDecryptedData(
              { name: row.campaign_name },
              'encrypt'
            )}`}
          >
            <div className='campaignNameStyleAC'>{row.campaign_name}</div>
          </Link>
        </div>
      ),
      sortable: false,
      minWidth: '120px !important',
    },
    {
      id: 'trendAC',
      name: '7 DAY TREND',
      selector: (row) => (
        <div
          id={`ac_${row.campaign_id}_trend`}
          className='activeCampTrendWrapper'
          onMouseMove={() =>
            onHoverTrendCol(
              true,
              `ac_${row.campaign_id}_trend`,
              row.campaign_id
            )
          }
          onMouseLeave={() => onHoverTrendCol(false)}
        >
          <div className='trendInnerWrapper'></div>
          <div className='activeTrendChart'>
            <CommonTrendChartComponent
              chartData={
                trendChartData.find(
                  (lineData) => lineData.campaign_id === row.campaign_id
                )?.chart_data
              }
              dotRadius={1.5}
            />
          </div>
          <div className='activeCampDataTick'>
            {trendFormat(row.trend_type)}
          </div>
        </div>
      ),
      sortable: false,
      width: `${windowWidth <= 1440 ? '15%' : '20%'}`,
      minWidth: '175px !important',
    },
    {
      id: 'campaignDatesAC',
      name: 'CAMPAIGN DATES',
      selector: (row) => (
        <div className='activeCampDatesWrapper'>
          <div className='activeCampDataTick tableCellFontStyle activeCampDatesElemPos'>
            {dateFormat(row.start_date)}
          </div>
          <div className='activeTrendChart '>
            <CommonRatioChartComponent
              chartData={
                ratioChartData.find(
                  (pieData) => pieData.campaign_id === row.campaign_id
                )?.chart_data
              }
              baseColors={[aaColors.orange, aaColors.tealChartingMedium]}
            />
          </div>
          <div className='activeCampDataTick tableCellFontStyle activeCampDatesElemPos'>
            {dateFormat(row.end_date)}
          </div>
        </div>
      ),
      sortable: false,
      width: '270px',
    },
    {
      id: 'runReportAC',
      name: 'RUN REPORT',
      selector: (row) => (
        <button
          className='runReportIcon runReportEndable'
          onClick={() =>
            window.open(
              `/reports/run/?rd1=${row.campaign_id}`,
              '_blank',
              'noreferrer'
            )
          }
        />
      ),
      sortable: false,
      style: { justifyContent: 'center' },
      width: '110px',
    },
  ];
};
