import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import endpoints from '../../../../constants/endpoints';
import { CommonInput, CommonTablesComponent } from '../../../../components';
import { EpisodeColumn } from './EpisodeColumn';
import '../../placement.css';

export const EpisodeList = (props) => {
  const {
    episodeList,
    pubStartDate,
    pubEndDate,
    campaignId,
    placementId,
    isAdminOrAbove,
  } = props;
  const labelStyle = { fontSize: '12px' };
  const inputStyle = { fontSize: '12px', width: '85px' };
  const customTableStyles = {
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: '700',
      },
    },
    cells: {
      style: {
        fontSize: '12px',
        fontWeight: '400',
      },
    },
  };
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [excludedEpisodes, setExcludedEpisodes] = useState([]);

  //Getting the edpisode list via API call

  //Updating the data after API response , episodes to table data set and setting the episode column
  useEffect(() => {
    const episodes =
      (episodeList?.data &&
        episodeList.data.episodes &&
        episodeList.data.episodes.length > 0 &&
        episodeList.data.episodes) ||
      [];
    if (episodes.length > 0) {
      episodes.sort((a, b) => {
        return new Date(b.publishDate) - new Date(a.publishDate);
      });
      setTableData(episodes);
      setColumns(EpisodeColumn());
      setTotalData(episodes.length);
      setExcludedEpisodes(episodeList.data.excludedEpisodes || []); // got from the API end which are all excluded
    }
  }, [episodeList]);

  const isRowDisabled = (row) => {
    return excludedEpisodes.some((item) => item.id === row.id);
  }

  //SelectedRow call back function from the react data table - False - unchecked and True - Checked box
  const selectableRowSelected = (row) => {
    const episodeData = sessionStorage.getItem('episodeData');
    const selectedItem =
      (episodeData && JSON.parse(episodeData).selectedEpisodes) || [];
    const excludeItem =
      (episodeData && JSON.parse(episodeData).excludeItem) || [];
    const isExcludeId =
      episodeData?.selectedEpisodes?.length > 0
        ? episodeData?.selectedEpisodes.some((item) => item.id !== row.id)
        : excludedEpisodes.some((item) => item.id === row.id);
    const isSelectedId = selectedItem.includes(row.id);
    const excludeIds = excludeItem.includes(row.id);
    const isEpisodeId = tableData.some((item) => item.id === row.id);
    return isSelectedId || (isEpisodeId && !isExcludeId && !excludeIds);
  };

  //Handle row select
  const handleRowSelect = (row) => {
    const episodeData = sessionStorage.getItem('episodeData');
    const episodeIds = tableData.map((item) => item.id);
    const excludeEpisodeIds = excludedEpisodes.map((item) => item.id);
    const lastSelectedItem =
      (episodeData && JSON.parse(episodeData).selectedEpisodes) || [];
    const sessionExclude =
      (episodeData && JSON.parse(episodeData).excludeItem) || [];
    let selectedItem = [];
    if (
      (!JSON.parse(episodeData)?.episodes ||
        JSON.parse(episodeData)?.episodes?.length <= 0) &&
      (!lastSelectedItem || lastSelectedItem?.length <= 0) &&
      (!sessionExclude || sessionExclude?.length <= 0) &&
      row?.selectedRows?.length <= 0
    ) {
      selectedItem = episodeIds?.filter(
        (id) => !excludeEpisodeIds.includes(id)
      );
    } else if (
      JSON.parse(episodeData)?.episodes?.length > 0 &&
      lastSelectedItem?.length <= 0 &&
      sessionExclude?.length <= 0 &&
      row?.selectedRows?.length <= 0
    ) {
      selectedItem = JSON.parse(episodeData)?.episodes?.filter(
        (id) => !excludeEpisodeIds.includes(id)
      );
    } else if (
      JSON.parse(episodeData)?.episodes?.length > 0 &&
      (lastSelectedItem?.length > 1 ||
        sessionExclude?.length <
          JSON.parse(episodeData)?.episodes?.length - 1) &&
      row?.selectedRows?.length <= 0 &&
      row.allSelected
    ) {
      selectedItem = JSON.parse(episodeData)?.selectedEpisodes?.filter(
        (id) => !sessionExclude.includes(id)
      );
    } else if (
      JSON.parse(episodeData)?.episodes?.length > 0 &&
      (lastSelectedItem?.length == 1 ||
        sessionExclude?.length ==
          JSON.parse(episodeData)?.episodes?.length - 1) &&
      row?.selectedRows?.length <= 0 &&
      row.allSelected
    ) {
      selectedItem = JSON.parse(episodeData)?.selectedEpisodes?.filter(
        (id) => !sessionExclude.includes(id)
      );
    } else if (
      JSON.parse(episodeData)?.episodes?.length > 0 &&
      (lastSelectedItem?.length == 1 ||
        sessionExclude?.length ==
          JSON.parse(episodeData)?.episodes?.length - 1) &&
      row?.selectedRows?.length <= 0 &&
      !row.allSelected
    ) {
      selectedItem = row?.selectedRows?.map((item) => item.id);
    } else {
      selectedItem = row?.selectedRows?.map((item) => item.id);
    }
    const excludedItem = episodeIds.filter((id) => !selectedItem.includes(id));
    sessionStorage.setItem(
      'episodeData',
      JSON.stringify({
        selectedEpisodes: selectedItem,
        episodes: episodeIds,
        excludedEpisodes: excludeEpisodeIds,
        excludeItem: [...excludedItem],
      })
    );
  };

  return (
    <div className='mt-4 mb-4 dup-name-modal'>
      <Row>
        <Col xs={12} className='flex epi-pub-range mb-4'>
          <CommonInput
            label={'Publish Date Range:'}
            inputValue={pubStartDate}
            disabled
            labelStyle={labelStyle}
            inputStyle={inputStyle}
          />
          <CommonInput
            label={'to'}
            inputValue={pubEndDate}
            disabled
            labelStyle={labelStyle}
            inputStyle={inputStyle}
          />
        </Col>
        <Col xs={12}>
          <CommonTablesComponent
            uniqueId='episodeList'
            data={tableData}
            columns={columns}
            isLoading={episodeList?.data ? false : true}
            customTableHeader='Episodes'
            isPaginationApplied={true}
            paginationServer={false}
            totalDataCount={totalData}
            pageIndex={pageNumber}
            setPageIndex={setPageNumber}
            dataPerPage={rowsPerPage}
            paginationOptions={{
              noRowsPerPage: false,
              selectAllRowsItem: true,
              selectAllRowsItemText: 'ALL',
            }}
            setDataPerPage={setRowsPerPage}
            selectableRows={isAdminOrAbove}
            handleRowSelect={handleRowSelect}
            selectableRowSelected={selectableRowSelected}
            selectableRowDisabled={isRowDisabled}
            customTableStyles={customTableStyles}
          />
        </Col>
      </Row>
    </div>
  );
};
