import React, { Fragment, useState, useRef, useEffect } from "react";
import { TreeTable, TreeState } from "../../../../../../components/TreeTable";
import { formatNumberInThousands } from "../../../../../../utils/functions";

// Render tree table
const TreeTableList = (props) => {
    const { tableData, headers } = props;
    const [treeState, setTreeState] = useState(TreeState.create([]));
    const treeTableRef = useRef(null);

    const getTreeData = (data, isPartner) =>{
        let obj = {
          name: data.name,
          isPartner: isPartner
        };
        headers.map((item)=>{
          const keyName = item["header_name"];
          obj[keyName] = data[keyName]
        })
        return obj;
    }

    useEffect(() => {
        const partnerTableData = tableData.map((partner) => {
            const partnerData = {
                data: getTreeData(partner, true),
                children: partner.placement_data && partner.placement_data.map((placement) => {
                    const placementData = {
                        data: getTreeData(placement, false)
                    }
                    return placementData
                }) || []

            }
            return partnerData;
        })
        setTreeState(TreeState.create(partnerTableData))
    }, [tableData]);

    // Render table header
    const renderHeaderCell = (name, alignLeft = true) => {
        return () => {
            return (
                <span className={alignLeft ? "align-left" : "align-right"}>{name}</span>
            );
        }
    }

    // Render partner and placement cell
    const renderIndexCell = (row) => {
        const className1 = row.metadata.hasChildren && (row.$state.isExpanded && "show-down-arrow" || "show-right-arrow") || "no-children";
        return (
            <div
                style={{ paddingLeft: (row.metadata.depth * 15) + "px" }}
                onClick={row.toggleChildren}
                className={"comparision-name " + className1}
            >
                {row.metadata.hasChildren &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                }
                <span title={row.data.name} className={row.data.isWaldo ? "index-cell is-waldo" : "index-cell"}>{row.data.name}</span>
            </div>
        );
    }

    // Render table cell
    const renderTableCell = (row, node) => {
        const data = row.data[node]
        const renderVal = data ? formatNumberInThousands(data) : 0;
        return (
            <span className={row.data.isPartner ? "font-bold" : ""}>{renderVal}</span>
        );
    }

    return (
        <Fragment>
            <TreeTable
                className="comparision-tree-table" 
                headerHeight={32}
                ref={treeTableRef}
                value={treeState}
                onChange={(newVal) => { setTreeState(newVal); }}
            >
                <TreeTable.Column renderCell={renderIndexCell}
                    renderHeaderCell={renderHeaderCell("")} basis="180px" grow={0} />
                {headers.map((item, key)=>{
                    const keyName = item["header_name"]
                    const label = item["header_label"]
                    return(
                        <TreeTable.Column renderCell={ (row) => renderTableCell(row, keyName)}
                    renderHeaderCell={renderHeaderCell(label)}  basis="180px" grow={0} key={keyName} />
                    )
                  })

                }
            </TreeTable>
        </Fragment>
    );
}
export default TreeTableList;
