import { createSlice } from '@reduxjs/toolkit';

export const advertiserSlice = createSlice({
  name: 'advertiser',
  initialState: {
    advertiserList: [],
    selectedClientsAdvertiser: [],
    selectedAdvertiser: -1, // -1 indicate no advertiser is selected
    showAdvertiserInitialPopUp: false
  },
  reducers: {
    updateAdvertiserList: (state, action) => {
      state.advertiserList = action.payload;
    },
    updateSelectedClientsAdvertiser: (state, action) => {
      const tempArray = action.payload.advertiserList.filter(
        (data) => data.user_id === action.payload.userId && data.status
      );
      state.selectedClientsAdvertiser = tempArray;
    },
    updateSelectedAdvertiser: (state, action) => {
      state.selectedAdvertiser = action.payload;
    },
    updateShowAdvertiserInitialPopUp: (state, action) => {
      state.showAdvertiserInitialPopUp = action.payload;
    },
    resetAdvertiser: (state) => {
      state.advertiserList = [];
      state.selectedClientsAdvertiser = [];
      state.selectedAdvertiser = -1;
      state.showAdvertiserInitialPopUp = false;
    },
  },
});

export const {
  updateAdvertiserList,
  updateSelectedAdvertiser,
  updateSelectedClientsAdvertiser,
  updateShowAdvertiserInitialPopUp,
  resetAdvertiser,
} = advertiserSlice.actions;
export default advertiserSlice.reducer;
