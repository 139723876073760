import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseEndpoints } from '../../../../../../../constants';
import { axiosSendRequest } from '../../../../../../../services/http/axiosCalls';
import { dateFormat, getAllSelectedOrNot, getArrayOfData } from '../../../../../../../utils/functions';
import { AnalysisBarChartsComponent } from './commonChatComp';

const BarChartsForGoalsComponent = ({
  campaign,
  mediaPartnerList,
  selectedMediaPartner,
  placementList,
  selectedPlacement,
  creativeTypeList,
  selectedCreativeType,
  typeOfData,
  numberOfDays,
  isLoadingBase,
  ifSelectedAll,
  startDate,
  endDate
}) => {
  const [conversionGraphData, setConversionGraphData] = useState(null),
    [isLoadingCGD, setIsLoadingCGD] = useState(true),
    [isErrorCGD, setIsErrorCGD] = useState(false);

  const [engagementGraphData, setEngagementGraphData] = useState(null),
    [isLoadingEGD, setIsLoadingEGD] = useState(true),
    [isErrorEGD, setIsErrorEGD] = useState(false);

  const [cancelController, setCancelController] = useState(null),
    [prevPlacements, setPrevPlacements] = useState(null),
    [prevTypeOfData, setPrevTypeOfData] = useState(null);

  const ifShowWidget = {
    conversion: true,
    engagement: true,
  };

  const getChartDataEGD = async (controller) => {
    setIsErrorEGD(false);
    setIsLoadingEGD(true);

    try {
      const promiseForEGD = axiosSendRequest(
          `${baseEndpoints.reports}/attribution-tab/analysis/engagement-bar-graph`,
          controller,
          {
            interval_type: numberOfDays?.id,
            campaign_id: campaign?.campaign_id,
            type_of_data: typeOfData,
            if_all_partners: getAllSelectedOrNot(mediaPartnerList, selectedMediaPartner, 'partner_id'),
            if_all_placements: getAllSelectedOrNot(placementList, selectedPlacement, 'placement_id'),
            if_all_creative_types: getAllSelectedOrNot(creativeTypeList, selectedCreativeType, 'value'),
            partner_id: getArrayOfData(selectedMediaPartner, 'partner_id'),
            placement_id: getArrayOfData(selectedPlacement, 'placement_id'),
            creative_type_id: getArrayOfData(selectedCreativeType, 'name'),
            start_date: dateFormat(startDate,'YYYY-MM-DD'),
            end_date: dateFormat(endDate,'YYYY-MM-DD'),
            campaign_start_date: dateFormat(campaign?.start_date,'YYYY-MM-DD'),
            campaign_end_date: dateFormat(campaign?.end_date,'YYYY-MM-DD')
          }
        ),
        responseForEGD = await promiseForEGD;

      if (responseForEGD.status === 200) {
        const data = responseForEGD.data;

        setEngagementGraphData(data && data.length > 0 ? [...data] : null);
        setIsLoadingEGD(false);
        return;
      }
      setIsErrorEGD(true);
      setEngagementGraphData(null);
      setIsLoadingEGD(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setEngagementGraphData(null);
        setIsLoadingEGD(false);
      }
    }
  };

  const getChartDataCGD = async (controller) => {
    setIsErrorCGD(false);
    setIsLoadingCGD(true);

    try {
      const promiseForCGD = axiosSendRequest(
          `${baseEndpoints.reports}/attribution-tab/analysis/conversion-bar-graph`,
          controller,
          {
            interval_type: numberOfDays?.id,
            campaign_id: campaign?.campaign_id,
            type_of_data: typeOfData,
            if_all_partners: getAllSelectedOrNot(mediaPartnerList, selectedMediaPartner, 'partner_id'),
            if_all_placements: getAllSelectedOrNot(placementList, selectedPlacement, 'placement_id'),
            if_all_creative_types: getAllSelectedOrNot(creativeTypeList, selectedCreativeType, 'value'),
            partner_id: getArrayOfData(selectedMediaPartner, 'partner_id'),
            placement_id: getArrayOfData(selectedPlacement, 'placement_id'),
            creative_type_id: getArrayOfData(selectedCreativeType, 'name'),
            start_date: dateFormat(startDate,'YYYY-MM-DD'),
            end_date: dateFormat(endDate,'YYYY-MM-DD'),
            campaign_start_date: dateFormat(campaign?.start_date,'YYYY-MM-DD'),
            campaign_end_date: dateFormat(campaign?.end_date,'YYYY-MM-DD')
          }
        ),
        responseForCGD = await promiseForCGD;

      if (responseForCGD.status === 200) {
        const data = responseForCGD.data;

        setConversionGraphData(data && data.length > 0 ? [...data] : null);
        setIsLoadingCGD(false);
        return;
      }
      setIsErrorCGD(true);
      setConversionGraphData(null);
      setIsLoadingCGD(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setConversionGraphData(null);
        setIsLoadingCGD(false);
      }
    }
  };

  useEffect(() => {
    if (
      campaign &&
      selectedPlacement &&
      (!prevPlacements ||
        JSON.stringify(prevPlacements) !== JSON.stringify(selectedPlacement) ||
        typeOfData !== prevTypeOfData) &&
        startDate &&
        endDate
    ) {
      const controller = new AbortController();
      setCancelController(controller);
      setPrevPlacements(selectedPlacement);
      setPrevTypeOfData(typeOfData);
      ifShowWidget.engagement && getChartDataEGD(controller);
      ifShowWidget.conversion && getChartDataCGD(controller);
      return () => {
        controller.abort();
      };
    } else {
      setEngagementGraphData(null);
      setConversionGraphData(null);
      setPrevPlacements(null);
      setPrevTypeOfData(null);
      setIsLoadingCGD(false);
      setIsLoadingEGD(false);
      setIsErrorCGD(false);
      setIsErrorEGD(false);
    }
    
  }, [selectedPlacement, typeOfData, startDate, endDate]);

  useEffect(() => {
    if (selectedPlacement && startDate && endDate) {
      const controller = new AbortController();
      setCancelController(controller);
      getChartDataEGD(controller);
      getChartDataCGD(controller);
      return () => {
        controller.abort();
      };
    }
    setEngagementGraphData(null);
    setConversionGraphData(null);
  }, [numberOfDays, startDate, endDate]);

  return (
    <AnalysisBarChartsComponent
      isLoadingBase={isLoadingBase || !ifSelectedAll}
      cancelController={cancelController}
      customTooltip={customTooltip}
      uniqueIndex='attrAnalysisBarChart'
      chartsAttrArray={[
        {
          chartHeaderLabel: 'Engagement Goals',
          isLoadingElement: isLoadingEGD,
          isErrorElement: isErrorEGD,
          refreshFunction: getChartDataEGD,
          graphData: engagementGraphData,
          barStackIds: ['e'],
          uniqueKey: 'analysisEngagementsAT',
          ifShowWidget: ifShowWidget.engagement,
        },
        {
          chartHeaderLabel: 'Conversion Goals',
          isLoadingElement: isLoadingCGD,
          isErrorElement: isErrorCGD,
          refreshFunction: getChartDataCGD,
          graphData: conversionGraphData,
          barStackIds: ['c'],
          uniqueKey: 'analysisConversionsAT',
          ifShowWidget: ifShowWidget.conversion,
        },
      ]}
    />
  );
};

export default BarChartsForGoalsComponent;

const customTooltip = ({ payload }) => {
  const data = payload ? payload[0]?.payload : null;

  return (
    !(data === undefined || data === null) && (
      <div className='barChartTooltipWrapper'>
        <div className='barChartTooltipLabel'>Goal Name:</div>
        <div
          className='barChartTooltipContent'
          style={{ color: payload[0]?.fill }}
        >{`${data.goal_name}`}</div>
      </div>
    )
  );
};
