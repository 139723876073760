import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  lineChartsColors,
  baseEndpoints,
} from '../../../../../../../../constants';
import { getArrayOfData } from '../../../../../../../../utils/functions';
import RenderAnalysisLineChart from './RenderAnalysisLineChart';
import { axiosSendRequest } from '../../../../../../../../services/http/axiosCalls';
import {
  ErrorComponent,
  LoaderComponent,
} from '../../../../../../../../components';

import './lineChart.css';

const AnalysisLineChart = ({
  campaign,
  startDate,
  endDate,
  defaultTypeOfData,
  defaultTypeOfImpression,
  defaultNumberOfDays,
  defaultSelectedPlacement,
  defaultSelectedPartner,
  isLoading,
  ifSelectedAll,
  dataFetching,
  setDataFetching,
}) => {
  const advertiser = useSelector((state) => state.advertiser);
  const [data, setData] = useState(null),
    [dataLoading, setDataLoading] = useState(true),
    [isError, setIsError] = useState(false),
    [cancelController, setCancelController] = useState(null),
    [status, setStatus] = useState(200);

  const getData = async (controller) => {
    setDataLoading(true);
    setDataFetching({ ...dataFetching, lineChart: true });
    setIsError(false);
    try {
      const response = await axiosSendRequest(
        `${baseEndpoints.reports}/media-delivery-tab/analysis/line-graph`,
        controller,
        {
          campaign_id: campaign?.campaign_id,
          start_date: startDate,
          end_date: endDate,
          interval_type: defaultNumberOfDays.id,
          type_of_data: defaultTypeOfData.typeDataId,
          type_of_impression: defaultTypeOfImpression.typeImpressionId,
          placement_id:
            getArrayOfData(defaultSelectedPlacement, 'placement_id')?.slice(
              0,
              10
            ) || null,
          partner_id:
            getArrayOfData(defaultSelectedPartner, 'partner_id')?.slice(
              0,
              10
            ) || null,
        }
      );

      setStatus(response.status);

      if (response.status == 200) {
        setData(response.data);
      }
      setDataLoading(false);
      setDataFetching({ ...dataFetching, lineChart: false });
    } catch (error) {
      if (!controller.signal.aborted) {
        console.log(error);
        setIsError(true);
        setDataLoading(false);
        setDataFetching({ ...dataFetching, lineChart: false });
      }
    }
  };

  useEffect(() => {
    setData([]);
    setDataLoading(true);

    if (
      advertiser.selectedAdvertiser !== -1 &&
      campaign &&
      campaign.campaign_id &&
      (defaultSelectedPlacement?.length &&
        defaultSelectedPartner?.length) && 
      startDate &&
      endDate
    ) {
      const controller = new AbortController();
      setCancelController(controller);
      getData(controller);
      return () => {
        controller.abort();
      };
    } 
    setDataLoading(false);
  }, [
    campaign,
    startDate,
    endDate,
    defaultSelectedPlacement,
    defaultSelectedPartner,
    defaultNumberOfDays,
    defaultTypeOfData,
    defaultTypeOfImpression,
    // dataFetching,
    // isLoading
  ]);

  const checkError = () =>
    isError ? (
      <ErrorComponent
        refreshAction={getData}
        controller={cancelController}
        status={status}
      />
    ) : (
      <RenderAnalysisLineChart
        data={data}
        height={600}
        xAxisDataKey={'est_dte'}
        isDate={true}
        colors={lineChartsColors}
        showVertical={false}
        yAxisLine={false}
        lineStrokeWidth={2}
        isCustomLabel={true}
        customLabelOne={'Name'}
        customLabelTwo={defaultTypeOfImpression.typeOfImpression}
        onlyForAnalysisLine={true}
      />
    );

  return !ifSelectedAll ||
    Object.values(isLoading).includes(true) ||
    dataLoading ? (
    <div className='customMultiLineChart'>
      <LoaderComponent />
    </div>
  ) : (
    checkError()
  );
};

export default AnalysisLineChart;
