import { Store } from "react-notifications-component";

// Common method to show toast notification
export const importErrorNotification = ({ msg, type }) => {

  if (!msg) {
    switch (type) {
      case "danger":
        msg = "Something went wrong";
        break;
      case "success":
        msg = "success";
        break;
      default:
        msg = "Default message";
    }
  }

const id = Store.addNotification({
    message: msg,
    type: type,
    insert: "center",
    container: "center",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: { showIcon: true, duration: 30000, onScreen: true, pauseOnHover: true },
  });

  return id;
};

export const clearNotification = () => {
  Store.removeAllNotifications();
}
