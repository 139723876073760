import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CommonDonutChart from '../../../../components/charts/CommonDonutChart.component';
import { Container, Row, Col } from 'react-bootstrap';
import {
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
} from '../../../../components';
import { axiosGetRequest } from '../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../constants';

import '../../dashboard.css';

const colors = ['#05a5ac', '#7ed7dd'],
  label = 'Active vs Inactive';

export default function AdvertisersYTD() {
  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser);

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true),
    [isError, setIsError] = useState(false),
    [cancelController, setCancelController] = useState(null);

  const getAdvertisersYTDData = async (controller) => {
    setIsError(false);
    setIsLoading(true);

    try {
      const response = await axiosGetRequest(
        `${baseEndpoints.advertisers}/ytd`,
        controller.signal,
        {
          user_id: client.selectedClient,
          advertiser_id: advertiser.selectedAdvertiser,
        }
      );

      if (response.status === 200) {
        setData(response.data);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (advertiser.selectedAdvertiser >= 0) {
      const controller = new AbortController();

      setCancelController(controller);
      getAdvertisersYTDData(controller);
      return () => {
        controller.abort();
      };
    } else {
      setData([]);
    }
  }, [advertiser]);

  return (
    <div>
      <div className='campaignsHeaderWrapper'>
        <CommonHeadingComponent
          headingLabel={'Advertisers'}
          url={null}
          isDisabled={false}
        />
      </div>
      <div className='ytdWrapper' id="advertisersYtd">
        <RenderGraphs
          isLoading={isLoading}
          isError={isError}
          data={data}
          dataFunction={getAdvertisersYTDData}
          controller={cancelController}
        />
      </div>
    </div>
  );
}

const RenderGraphs = ({
  isLoading,
  isError,
  data,
  dataFunction,
  controller,
}) => {
  if (isLoading) {
    return <LoaderComponent headerClass='ytdLoaderHeader' />;
  }

  if (isError) {
    return (
      <ErrorComponent refreshAction={dataFunction} controller={controller} />
    );
  }

  return (
    <Container
      className='widget_container'
      fluid
      style={{ height: 'fit-content' }}
    >
      <Row>
        <Col className='widget_content' lg={12} md={12} sm={12}>
          <CommonDonutChart
            data={data}
            colors={colors}
            label={label}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </Container>
  );
};
