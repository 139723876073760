import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import LoaderComponent from '../../../../components/loaderComponent/Loader.component'
import {
  CommonHeadingComponent,
  CommonButtonComponent,
  ErrorComponent,
  DatePickerComponent,
  CommonTablesComponent,
  MultiselectDropdownComponent
} from '../../../../components'
import { toggleClasses, checkSelectAll, setPlaceHolderLabel } from '../../../../utils/functions'
import { pixelSummaryTableColumns, pixelSummaryGoalsTableColumns } from './PixelSummary.utils'
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor'
import { baseEndpoints, patterns } from '../../../../constants'
import './PixelSummary.css'

const PixelSummaryData = ({ propertyId, advertiserId }) => {
  const defaultDateFormat = patterns.TimeframeDateFormat
  const goalTypes = [
    {
      id: 'engagement',
      name: 'Engagement'
    },
    {
      id: 'conversion',
      name: 'Conversion'
    }
  ]

  const goalTypeListRef = useRef(null)
  const client = useSelector((state) => state.client);

  const [expandOrCollapse, setExpandOrCollapse] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [isTimeframeMounted, setIsTimeframeMounted] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10) // records per page in grid
  const [pixelSummaryParams, setPixelSummaryParams] = useState(null)
  const [startDate, setStartDate] = useState('2017-01-01')
  const [endDate, setEndDate] = useState(null)
  const [selectedGoalTypes, setSelectedGoalTypes] = useState(goalTypes) // User selected goals in multiple goal selection component
  const [clickButton, setClickButton] = useState(false) // To handle button clicks and fixing multiple selection issue
  const timeframeParams = null
  // API Interceptor to get all goals for current property
  const { data: summaryData, isLoading: isSummaryLoading, isError: isSummaryError } = useGetApiInterceptor(isMounted, pixelSummaryParams, `${baseEndpoints.properties}/${propertyId}/pixels`) // States for Goal Data
  // API Interceptor to get timeframe for current property
  const { data: timeframeData, isLoading: isTimeframeLoading } = useGetApiInterceptor(isTimeframeMounted, timeframeParams, `${baseEndpoints.properties}/${propertyId}/timeframe`) // States for Timeframe Data

  useEffect(() => {
    setIsTimeframeMounted(true)
  }, [])

  useEffect(() => {
    getPixelSummaryData()
  }, [pageNumber, pageSize])

  // To handle 'Get Reports' button clicks
  useEffect(() => {
    if (clickButton === true) {
      setIsMounted(true)
      getPixelSummaryData()
      setClickButton(false)
    }
  }, [clickButton])

  useEffect(() => {
    if (timeframeData?.startDate && timeframeData?.endDate) {
      setStartDate(timeframeData.startDate)
      setEndDate(timeframeData.endDate)
    } else {
      setStartDate(moment().format(defaultDateFormat))
      setEndDate(moment().format(defaultDateFormat))
    }
  }, [timeframeData])

  const getPixelSummaryData = () => {
    const goalTypeValues = selectedGoalTypes.map(val => val.id)
    if (goalTypeValues.length > 0) {
      setPixelSummaryParams({ userId: client.selectedClient, advertiser_id: advertiserId, pageNumber, pageSize, from: startDate, to: endDate, goalType: goalTypeValues })
    }
  }

  const renderPixelSummary = () => {
    return (
      <Container fluid>
        {summaryData?.summary && <Row className='summaryHeadingWrapper'>
          <Container fluid>
            <Row className='summaryHeading'>Summary Data</Row>
            <Row>
              <CommonTablesComponent
                uniqueId='pixelSummary'
                data={[summaryData?.summary]}
                columns={pixelSummaryTableColumns()}
                isLoading={isSummaryLoading}
                customTableHeader="Summary Data"
              />
            </Row>
          </Container>
        </Row>}
        <Row className='summaryHeadingWrapper'>
          <Container fluid>
            <Row className='summaryHeading'>Count By Goals</Row>
            <Row>
              <CommonTablesComponent
                uniqueId='pixelSummaryGoals'
                data={summaryData?.goals}
                columns={pixelSummaryGoalsTableColumns()}
                isLoading={isSummaryLoading}
                customTableHeader="Count By Goals"
                isPaginationApplied={true}
                totalDataCount={summaryData?.total || 0}
                pageIndex={pageNumber}
                setPageIndex={setPageNumber}
                dataPerPage={pageSize}
                paginationOptions={{
                  noRowsPerPage: false
                }}
                setDataPerPage={setPageSize}
                noDataMessage='No Data Available'
                noDataCompWidth='180'
                noDataCompHeight='180'
                noDataMessageFontSize={15}
                foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
              />
            </Row>
          </Container>
        </Row>
      </Container>
    )
  }

  const renderCollapsibleView = () => {
    return (
      <Container fluid>
        {isTimeframeLoading
          ? <div className='summaryHeadingWrapper'><LoaderComponent/></div>
          : (<Row className='pixelSelector'>
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={3}
          >
            <DatePickerComponent
              datePickerId='cRStartDate'
              label='Start Date'
              maxDate={endDate}
              startOrEndDate={startDate}
              setStartOrEndDate={setStartDate}
              placeholderLabel='MM/DD/YYYY'
              uniqueIdentifier='propertyDetailsPixelSummaryDataStart'
            />
          </Col>
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={3}
          >
            <DatePickerComponent
              datePickerId='cREndDate'
              label='End Date'
              minDate={startDate}
              maxDate={moment().format(defaultDateFormat)}
              startOrEndDate={endDate}
              setStartOrEndDate={setEndDate}
              placeholderLabel='MM/DD/YYYY'
              uniqueIdentifier='propertyDetailsPixelSummaryDataEnd'
            />
          </Col>
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={3}
          >
            <MultiselectDropdownComponent
              dropdownId='goalsDB'
              dropdownDataList={goalTypes}
              dataId='id'
              dataName='name'
              label='Goal Types'
              dropdownData={selectedGoalTypes ?? []}
              setDropdownData={(data) => {
                if (data) {
                  setSelectedGoalTypes(data)
                } else {
                  setSelectedGoalTypes(
                    goalTypeListRef.current
                      ? [...goalTypeListRef.current]
                      : []
                  )
                }
              }}
              ifIdSearchAvailable={true}
              placeholder={setPlaceHolderLabel(
                goalTypes.length <= 0,
                'Goals '
              )}
              txtSearch='Search by Type'
              isAllCheckedInitially={checkSelectAll(
                goalTypes || [],
                selectedGoalTypes,
                'goal_id'
              )}
              labelWidth={{ width: '86px' }}
              dropdownWidth={{
                width: 'calc(100% - 86px)'
              }}
              uniqueIdentifier='propertyDetailsPixelSummaryDataGoalType'
            />
          </Col>
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={3}
          >
            <div className='pixelSummaryDataButtonWrapper' style={{display:'flex'}}>
            <CommonButtonComponent
              label='Generate Report'
              isDisabled={!startDate || !endDate || !(selectedGoalTypes && selectedGoalTypes.length > 0)}
              appendButtonClassName='generateReportButtonBody'
              appendWrapperStyle={{ padding: '0px' }}
              onClickCTA={() => {
                setTimeout(() => {
                  setClickButton(true)
                }, 50)
              }}
              showLoader={false}
              buttonUI='propertyDetailsPixelSummaryDataGenerate'
            />
            </div>
          </Col>
        </Row>)}
        {isSummaryError && <ErrorComponent customWrapperClass='summaryHeadingWrapper' refreshAction={() => getPixelSummaryData()} />}
        {summaryData?.goals && renderPixelSummary()}
      </Container>
    )
  }

  return (
    <div
      className={toggleClasses(
        expandOrCollapse,
        'pixelDataGridWrapper',
        'pixelDataGridWrapper expand'
      )}
    >
      <CommonHeadingComponent
        headingLabel="Pixel Summary Data"
        headingCTALabel={!expandOrCollapse ? 'COLLAPSE' : 'EXPAND'}
        isDisabled={false}
        onClickCTA={() => setExpandOrCollapse(!expandOrCollapse)}
        uniqueIdentifier='propertyDetailsPixelSummaryDataCollapsable'
      />
      {!expandOrCollapse && renderCollapsibleView()}
    </div>
  )
}

PixelSummaryData.propTypes = {
  propertyId: PropTypes.string
}

export default PixelSummaryData
