import React, { useEffect, useRef, useState } from 'react';
import {
  getEffectiveDropdownHeight,
  getSortedArrayOfObj,
} from '../../utils/functions';
import LoaderComponent from '../loaderComponent/Loader.component';
import './multiselectDropdown.css';

/**
 *
 * @param {className} wrapperClass will add class to the wrapper eg. "requiredDD"
 * @param alignType type ['center', 'left', 'right', 'custom']
 * @returns
 */

const alignTypeClasses = {
  center: 'searchableDropdownMSCenterAligned',
  left: 'searchableDropdownMSLeftAligned',
  right: 'searchableDropdownMSRightAligned',
  custom: 'searchableDropdownMSCustomAligned',
};

const MultiselectDropdownComponent = ({
  dropdownId,
  dropdownDataList,
  dataId,
  dataName,
  dataType,
  label,
  dropdownData,
  setDropdownData,
  multiselectSearch = 'true',
  multiselectSelectAll = 'true',
  ifIdSearchAvailable = false,
  placeholder = 'Select',
  txtAll = 'All',
  txtSearch = 'Search',
  searchInputClass = null,
  isAllCheckedInitially = false,
  labelWidth,
  dropdownWidth,
  isLoading = false,
  isDisabled = false,
  ifMaxSelectApplied = false,
  maxSelectElements = 10,
  uniqueIdentifier,
  isOpen = null,
  wrapperClass = null,
  allSelectedPlaceHolder = 'All selected',
  alignType = 'left', // alignType should be any of 'center', 'left', 'right', or 'custom'
  ifIdVisibleOnLabel = false,
  ifCanBeEmptied = false,
  disableAllRows = false,
}) => {
  const prevData = useRef(null),
    filteredData = useRef([]),
    selectedDataItemsRef = useRef(null),
    selectedChildRef = useRef(null),
    lastSelectedChildRef = useRef(null),
    ifScrolledRef = useRef(false),
    scrollPosCountUp = useRef(6),
    scrollPosCountDown = useRef(0),
    ifHovered = useRef(false),
    ifAllRemoved = useRef(false);

  let ifBlurred = false;

  const setTransform = (list) => {
    const effectiveDropdownHeight = getEffectiveDropdownHeight(
      list.length > 0 ? list.length + 1 : 0,
      6,
      30,
      multiselectSearch === 'true' ? 44 : 4
    );
    const elementWrapper = document.querySelector(
      `#${dropdownId} div.multiselect-dropdown`
    );
    const ob =
      window.innerHeight - (elementWrapper.getBoundingClientRect().top + 31);

    if (effectiveDropdownHeight && ob <= effectiveDropdownHeight + 5) {
      return {
        transform: `translate(0px, -${effectiveDropdownHeight - 36}px)`,
      };
    }
    return {
      transform: 'none',
    };
  };

  useEffect(() => {
    filteredData.current = dropdownDataList ?? [];
  }, [dropdownDataList]);

  useEffect(() => {
    prevData.current = dropdownData ? [...dropdownData] : [];

    const prevHtmlElement = document.querySelector(
      `#${dropdownId} div.multiselect-dropdown div.multiselect-dropdown-list-wrapper`
    );
    if (prevHtmlElement) {
      prevHtmlElement.remove();
    }
    const selectElement = document.querySelector(
        `#${dropdownId} select[multiple]`
      ),
      elementWrapper = document.querySelector(
        `#${dropdownId} div.multiselect-dropdown`
      );

    let dropdownTransformValue = { transform: 'none' };

    const listWrap = createNewElement('div', {
        classList: `multiselect-dropdown-list-wrapper ${alignTypeClasses[alignType]}`,
        style: dropdownTransformValue,
      }),
      list = createNewElement('div', {
        class: 'multiselect-dropdown-list',
      }),
      search = createNewElement('input', {
        class: ['multiselect-dropdown-search'].concat([
          searchInputClass ?? 'form-control',
        ]),
        style: {
          display:
            selectElement.attributes['multiselect-search']?.value === 'true'
              ? 'block'
              : 'none',
        },
        placeholder: txtSearch,
      });
    listWrap.appendChild(search);
    elementWrapper.appendChild(listWrap);
    listWrap.appendChild(list);

    selectElement.loadOptions = () => {
      list.innerHTML = '';

      if (
        !ifMaxSelectApplied &&
        selectElement.attributes['multiselect-select-all']?.value === 'true'
      ) {
        const listItemOption = createNewElement('div', {
          classList: isAllCheckedInitially
            ? 'multiselect-dropdown-all-selector multiSelectDDElemWrapper checked'
            : 'multiselect-dropdown-all-selector multiSelectDDElemWrapper',
          id: `${dropdownId}-all`,
        });
        const ic = createNewElement('input', {
          type: 'checkbox',
          checked: isAllCheckedInitially,
          class: 'multiSelectDDCheckbox',
          disabled: disableAllRows,
        });
        listItemOption.setAttribute('index', '0');
        listItemOption.appendChild(ic);
        listItemOption.appendChild(
          createNewElement('div', {
            text: txtAll,
            class: 'multiSelectCheckboxLabel',
          })
        );

        if (!disableAllRows) {
          listItemOption.addEventListener('click', () => {
            listItemOption.classList.toggle('checked');
            listItemOption.querySelector('input').checked =
              !listItemOption.querySelector('input').checked;
  
            const ch = listItemOption.querySelector('input').checked;
            list
              .querySelectorAll(
                `:scope > div:not(.multiselect-dropdown-all-selector)`
              )
              .forEach((i) => {
                if (i.style.display !== 'none') {
                  if (i.classList.value.includes('checked') && ch === false) {
                    i.classList.remove('checked');
                  } else if (
                    !i.classList.value.includes('checked') &&
                    ch === true
                  ) {
                    i.classList.add('checked');
                  }
                  i.querySelector('input').checked = ch;
                  i.optEl.selected = ch;
                }
              });
  
            selectElement.dispatchEvent(new Event('change'));
          });

          ic.addEventListener('click', (ev) => {
            ic.checked = !ic.checked;
          });
        }

        listItemOption.addEventListener('mousemove', () => {
          removeFocusedOnHover();
        });

        selectElement.addEventListener('change', (ev) => {
          const items = Array.from(
            list.querySelectorAll(
              `:scope > div:not(.multiselect-dropdown-all-selector)`
            )
          ).filter((e) => e.style.display !== 'none');
          const existsNotSelected = items.find(
            (i) => !i.querySelector('input').checked
          );
          if (ic.checked && existsNotSelected) ic.checked = false;
          else if (ic.checked === false && existsNotSelected === undefined)
            ic.checked = true;
        });

        list.appendChild(listItemOption);
      }

      Array.from(selectElement.options).forEach((o) => {
        const listItemOption = createNewElement('div', {
          classList: o.selected
            ? 'multiSelectDDElemWrapper checked'
            : 'multiSelectDDElemWrapper',
          optEl: o,
          id: o.getAttribute('unique_id'),
        });
        listItemOption.setAttribute('index', `${o.getAttribute('value')}`);
        const ic = createNewElement('input', {
          type: 'checkbox',
          checked: o.selected,
          value: o.value,
          class: 'multiSelectDDCheckbox',
          disabled: disableAllRows,
        });
        listItemOption.appendChild(ic);
        listItemOption.appendChild(
          createNewElement('div', {
            text: o.text,
            class: 'multiSelectCheckboxLabel',
          })
        );

        if (!disableAllRows) {
          listItemOption.addEventListener('click', () => {
            listItemOption.classList.toggle('checked');
            listItemOption.querySelector('input').checked =
              !listItemOption.querySelector('input').checked;
            listItemOption.optEl.selected = !listItemOption.optEl.selected;
            selectElement.dispatchEvent(new Event('change'));
            applyMaxSelect(
              ifMaxSelectApplied,
              maxSelectElements,
              selectElement,
              list
            );
          });

          ic.addEventListener('click', (ev) => {
            if (selectedDataItemsRef.current) {
              const elem = Number(ic.getAttribute('value'));
              if (selectedDataItemsRef.current.includes(elem)) {
                selectedDataItemsRef.current =
                  selectedDataItemsRef.current.filter(
                    (item) => item[dataId] !== elem
                  );
              } else {
                const selectedElem = filteredData.current.find(
                  (item) => item[dataId] === elem
                );
  
                selectedDataItemsRef.current = getSortedArrayOfObj(
                  [...selectedDataItemsRef.current, selectedElem],
                  dataName
                );
              }
            }
            ic.checked = !ic.checked;
          });
        }

        listItemOption.addEventListener('mousemove', () => {
          removeFocusedOnHover();
        });

        o.listItemElement = listItemOption;
        list.appendChild(listItemOption);
      });
      elementWrapper.listEl = listWrap;

      applyMaxSelect(
        ifMaxSelectApplied,
        maxSelectElements,
        selectElement,
        list
      );

      refreshElement(
        elementWrapper,
        selectElement,
        placeholder,
        dropdownDataList,
        label,
        isDisabled,
        uniqueIdentifier,
        allSelectedPlaceHolder
      );
    };
    selectElement.loadOptions();

    search.addEventListener('input', () => {
      const selectedData = Array.from(selectElement.selectedOptions).map(
          (element) => element.value
        ),
        checkboxElem = document.querySelector(
          `#${dropdownId} .multiselect-dropdown-all-selector input`
        );

      if (!search.value) {
        if (checkboxElem && dropdownDataList.length !== selectedData.length) {
          checkboxElem.checked = false;
        }
      }

      selectedDataItemsRef.current = null;
      scrollPosCountUp.current = 6;
      scrollPosCountDown.current = 0;

      const elem = document.querySelector(
          `#${dropdownId}.multiselectDropdownOuterWrapper .multiselect-dropdown-list-wrapper .multiselect-dropdown-list`
        ),
        elemOld = elem.querySelector(`[index='${selectedChildRef.current}']`);
      elemOld?.classList?.remove('ddSsOnKeyFocused');

      selectedChildRef.current = null;
      elem.scrollTop = 0;

      filteredData.current = dropdownDataList.filter((elem) =>
        elem[dataName]?.toUpperCase().includes(search.value.toUpperCase())
      );

      const searchedElement = [];
      list
        .querySelectorAll(
          `:scope > div:not(.multiselect-dropdown-all-selector)`
        )
        .forEach((d) => {
          const txt = d.querySelector('div').innerText.toUpperCase(),
            value = d.querySelector('input').value;

          if (
            (txt,
            search.value.toUpperCase(),
            txt.includes(search.value.toUpperCase()))
            // (ifIdSearchAvailable && value.includes(search.value))
          ) {
            searchedElement.push(d.querySelector('input').value);
          }

          d.style.display =
            txt.includes(search.value.toUpperCase()) ||
            (value.includes(search.value) && ifIdSearchAvailable)
              ? 'flex'
              : 'none';
        });

      listWrap.style.transform = setTransform(searchedElement).transform;

      if (
        checkboxElem &&
        searchedElement.every((val) => selectedData.includes(val))
      ) {
        checkboxElem.checked = true;
      }

      document.querySelector(
        `#${dropdownId} .multiselect-dropdown-all-selector`
      ).style.display = searchedElement.length > 0 ? 'flex' : 'none';
    });

    elementWrapper.addEventListener('click', () => {
      listWrap.style.transform = setTransform(dropdownDataList ?? []).transform;
      elementWrapper.classList.add('open');
      elementWrapper.listEl.style.display = 'block';
      if (isOpen) {
        isOpen(true);
      }
      search.focus();
      search.select();
    });

    document.addEventListener('click', (event) => {
      if (elementWrapper.contains(event.target) && ifBlurred === false) {
        setIfBlurred();
      } else if (!elementWrapper.contains(event.target) && ifBlurred === true) {
        let selectedData = [];

        if (ifAllRemoved.current) {
          ifAllRemoved.current = false;

          const elem = document.querySelector(
              `#${dropdownId}.multiselectDropdownOuterWrapper .multiselect-dropdown-list-wrapper .multiselect-dropdown-list`
            ),
            ifPrevAllSelected =
              JSON.stringify(filteredData.current) ===
              JSON.stringify(prevData.current),
            prevDataIdArr = prevData.current?.map((item) => item[dataId]);

          elem?.childNodes.forEach((item, index) => {
            if (index !== 0) {
              if (ifPrevAllSelected) {
                item.children[0].checked = true;
              } else {
                const id = item.getAttribute('index');
                if (prevDataIdArr.includes(Number(id))) {
                  item.children[0].checked = true;
                }
              }
            } else {
              item.children[0].checked = ifPrevAllSelected;
            }
          });
        }

        selectedDataItemsRef.current = null;
        scrollPosCountUp.current = 6;
        scrollPosCountDown.current = 0;

        const elem = document.querySelector(
          `#${dropdownId}.multiselectDropdownOuterWrapper .multiselect-dropdown-list-wrapper .multiselect-dropdown-list`
        );
        if (selectedChildRef.current) {
          const elemOld = elem.querySelector(
            `[index='${selectedChildRef.current}']`
          );
          elemOld?.classList?.remove('ddSsOnKeyFocused');
        }

        selectedChildRef.current = null;
        if (elem) {
          elem.scrollTop = 0;
        }

        selectedData = [
          ...Array.from(selectElement.selectedOptions).map((element) => {
            return {
              [dataId]: isNaN(Number(element.value))
                ? element.value
                : Number(element.value),
              [dataName]: element.innerHTML,
            };
          }),
        ];

        if (JSON.stringify(selectedData) !== JSON.stringify(prevData.current)) {
          setDropdownData(selectedData.length <= 0 ? null : selectedData);
          prevData.current = [...selectedData];
        }

        elementWrapper.classList.remove('open');
        elementWrapper.listEl.style.display = 'none';
        if (isOpen) {
          setTimeout(() => {
            isOpen(false);
          }, 50);
        }

        setIfBlurred();
        refreshElement(
          elementWrapper,
          selectElement,
          placeholder,
          dropdownDataList,
          label,
          isDisabled,
          uniqueIdentifier,
          allSelectedPlaceHolder
        );
      }
    });
  }, [dropdownDataList]);

  const setIfBlurred = () => {
    ifBlurred = !ifBlurred;
  };

  const handleKeyPressEvents = (e) => {
    const keys = ['ArrowDown', 'ArrowUp'],
      elem = document.querySelector(
        `#${dropdownId}.multiselectDropdownOuterWrapper .multiselect-dropdown-list-wrapper .multiselect-dropdown-list`
      ),
      allIndexesTemp = filteredData.current.map((item) =>
        !isNaN(item[dataId]) ? Number(item[dataId]) : item[dataId]
      ),
      allIndexes = [0, ...allIndexesTemp];

    if (dropdownDataList?.length && keys.includes(e.code)) {
      const outerElem = document.querySelector(
        `#${dropdownId}.multiselectDropdownOuterWrapper input.multiselect-dropdown-search`
      );

      outerElem.focus();

      if (e.code === 'ArrowDown') {
        let newSelectedChild,
          scrollCount = 0,
          ifLastItem = false;

        if (selectedChildRef.current === null) {
          newSelectedChild = 0;
          ifLastItem = false;
        } else if (
          selectedChildRef.current !== allIndexes[allIndexes?.length - 1]
        ) {
          scrollCount = allIndexes.indexOf(selectedChildRef.current);
          newSelectedChild = allIndexes[scrollCount + 1];
          ifLastItem = false;
        } else {
          ifLastItem = true;
        }

        if (!ifLastItem) {
          const elemNew = elem.querySelector(`[index='${newSelectedChild}']`),
            elemOld = elem.querySelector(
              `[index='${selectedChildRef.current}']`
            );
          elemOld?.classList?.remove('ddSsOnKeyFocused');
          elemNew?.classList?.add('ddSsOnKeyFocused');

          if (scrollPosCountDown.current < 6) {
            scrollPosCountUp.current = scrollPosCountUp.current - 1;
            scrollPosCountDown.current = scrollPosCountDown.current + 1;
          }

          if (scrollCount > 4 && scrollPosCountDown.current === 6) {
            ifScrolledRef.current = true;
            elem.scrollTop = 30 * (scrollCount - 4);
          }

          if (ifHovered.current) {
            ifHovered.current = false;
            elem.scrollTop = 0;
          }

          if (lastSelectedChildRef.current !== null) {
            lastSelectedChildRef.current = null;
            elem.scrollTop = 0;
          }

          selectedChildRef.current = newSelectedChild;
        }

        return;
      }

      if (e.code === 'ArrowUp') {
        let newSelectedChild,
          scrollCount = 0;

        if (selectedChildRef.current === 0) {
          newSelectedChild = 0;
        } else {
          scrollCount = allIndexes.indexOf(selectedChildRef.current);
          newSelectedChild = allIndexes[scrollCount - 1];
        }

        const elemNew = elem.querySelector(`[index='${newSelectedChild}']`),
          elemOld = elem.querySelector(`[index='${selectedChildRef.current}']`);
        elemOld?.classList?.remove('ddSsOnKeyFocused');
        elemNew?.classList?.add('ddSsOnKeyFocused');

        if (scrollPosCountUp.current < 6) {
          scrollPosCountDown.current = scrollPosCountDown.current - 1;
          scrollPosCountUp.current = scrollPosCountUp.current + 1;
        }

        if (
          scrollCount < filteredData.current.length - 4 &&
          scrollPosCountUp.current === 6
        ) {
          ifScrolledRef.current = true;
          elem.scrollTop =
            elem.scrollHeight -
            30 * (filteredData.current.length + 2 - scrollCount);
        }

        selectedChildRef.current = newSelectedChild;
      }

      return;
    }

    const selectedElem = elem.querySelector(
        `[index='${selectedChildRef.current}']`
      ),
      itemIndex = selectedElem?.getAttribute('index');
    let ifChecked = false;

    if (
      e.code === 'Enter' &&
      selectedChildRef.current !== null &&
      !(itemIndex === null || itemIndex === undefined)
    ) {
      const checkboxElem = selectedElem.children[0];
      ifChecked = !checkboxElem.checked;
      checkboxElem.checked = ifChecked;

      if (itemIndex == 0) {
        if (!ifChecked && !ifCanBeEmptied) {
          ifAllRemoved.current = true;
        }
        elem?.childNodes.forEach((item, index) => {
          if (index !== 0) {
            item.children[0].checked = ifChecked;
          }
        });
      } else {
        let checkforAnyUhecked = false;
        elem?.childNodes.forEach((item, index) => {
          if (!checkforAnyUhecked && index !== 0) {
            checkforAnyUhecked = !item.children[0].checked;
          }
        });

        elem.children[0].children[0].checked = !checkforAnyUhecked;
      }
    }

    const selectedItems = [];

    elem?.childNodes.forEach((item, index) => {
      const chkboxElem = item.children[0];
      if (index !== 0 && chkboxElem.checked) {
        const attrVal = chkboxElem.getAttribute('value');
        selectedItems.push(!isNaN(attrVal) ? Number(attrVal) : attrVal);
      }
    });

    const selectedData = filteredData.current.filter((item) =>
      selectedItems.includes(item[dataId])
    );

    selectedDataItemsRef.current =
      selectedData.length <= 0 ? null : selectedData;

    if (
      (!isNaN(itemIndex) && Number(itemIndex) > 0) ||
      (isNaN(itemIndex) && !(itemIndex === null && itemIndex === undefined))
    ) {
      const selectElement = document.querySelector(
        `#${dropdownId} select[multiple]`
      );

      const el = selectElement?.querySelector(`[value='${itemIndex}']`);
      if (el) el.selected = ifChecked;

      return;
    }

    if (!isNaN(itemIndex) && Number(itemIndex) === 0) {
      const selectElement = document.querySelector(
        `#${dropdownId} select[multiple]`
      );

      selectElement.childNodes.forEach((child) => {
        child.selected = ifChecked;
      });
    }
  };

  const removeFocusedOnHover = () => {
    if (ifScrolledRef.current) {
      ifScrolledRef.current = false;
      return;
    }

    scrollPosCountUp.current = 6;
    scrollPosCountDown.current = 0;
    ifHovered.current = true;

    const elem = document.querySelector(
        `#${dropdownId}.multiselectDropdownOuterWrapper .multiselect-dropdown-list-wrapper .multiselect-dropdown-list`
      ),
      elemOld = elem.querySelector(`[index='${selectedChildRef.current}']`);
    elemOld?.classList?.remove('ddSsOnKeyFocused');
    selectedChildRef.current = null;
  };

  return (
    <div
      id={dropdownId}
      className={`container multiselectDropdownOuterWrapper${
        wrapperClass ? ' ' + wrapperClass : ''
      }`}
      onKeyDown={handleKeyPressEvents}
    >
      <div className='row'>
        <div className='col multiselectDropdownWrapper'>
          <div className='multiselectDropdownLabel' style={labelWidth}>
            {label}
          </div>
          <select
            name='field2'
            onChange={() => {}}
            className='multiselectDropdownElement'
            style={dropdownWidth}
            multiple
            multiselect-search={multiselectSearch}
            multiselect-select-all={multiselectSelectAll}
            multiselect-max-items='1'
            value={
              dropdownData && dropdownData.length > 0
                ? dropdownData.map((elem) => elem[dataId])
                : []
            }
          >
            {dropdownDataList?.map((elem) => (
              <option
                key={`${elem[dataId]}-${elem[dataName]}`}
                unique_id={
                  dropdownId +
                  '-' +
                  elem[dataId] +
                  '-' +
                  elem[dataName]?.replace(/\s/g, '')
                }
                value={elem[dataId]}
              >
                {elem[dataName]}
                {ifIdVisibleOnLabel && !elem[dataType] && ` (#${elem[dataId]})`}
                {ifIdVisibleOnLabel &&
                  elem[dataType] &&
                  ` (#${elem[dataId]} | ${elem[dataType]})`}
              </option>
            ))}
          </select>
          <div
            className='multiselect-dropdown'
            style={dropdownWidth}
            disabled={isDisabled}
            tabIndex='0'
          ></div>
          {isLoading && (
            <div className='dropdownLoaderWrapper'>
              <LoaderComponent importedClassNames='dropdownsAndDates' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiselectDropdownComponent;

const createNewElement = (tag, attrs) => {
  const e = document.createElement(tag);
  if (attrs !== undefined) {
    Object.keys(attrs).forEach((elem) => {
      if (elem === 'class') {
        if (Array.isArray(attrs[elem])) {
          attrs[elem].forEach((o) => (o !== '' ? e.classList.add(o) : 0));
          return e;
        }

        if (attrs[elem] !== '') {
          e.classList.add(attrs[elem]);
          return e;
        }
      }

      if (elem === 'style') {
        Object.keys(attrs[elem]).forEach((ks) => {
          e.style[ks] = attrs[elem][ks];
        });
        return e;
      }

      if (elem === 'text') {
        if (attrs[elem] === '') {
          e.innerHTML = '&nbsp;';
          return e;
        }
        e.innerText = attrs[elem];
        return e;
      }

      if (elem === 'name') {
        e.setAttribute('name', attrs[elem]);
        return e;
      }

      e[elem] = attrs[elem];
    });
  }
  return e;
};

const refreshElement = (
  elementWrapper,
  selectElement,
  placeholder,
  dropdownDataList,
  label,
  isDisabled,
  uniqueIdentifier,
  allSelectedPlaceHolder
) => {
  elementWrapper.refresh = () => {
    elementWrapper
      .querySelectorAll('div.textSelected, div.multiSelectPlaceholder')
      .forEach((t) => elementWrapper.removeChild(t));
    const selectedItems = Array.from(selectElement.selectedOptions);
    if (dropdownDataList && selectedItems.length === dropdownDataList.length) {
      elementWrapper.appendChild(
        createNewElement('div', {
          class: ['textSelected'],
          text: allSelectedPlaceHolder,
          id: uniqueIdentifier,
          name: uniqueIdentifier,
        })
      );
    } else if (
      selectedItems.length >
      (selectElement.attributes['multiselect-max-items']?.value ?? 2)
    ) {
      const allElemArray = Array.from(selectElement.selectedOptions)?.map(
        (item) => item.innerHTML
      );
      elementWrapper.appendChild(
        createNewElement('div', {
          class: ['textSelected'],
          text: `${allElemArray[0]}, ${selectedItems.length - 1} more selected`,
          id: uniqueIdentifier,
          name: uniqueIdentifier,
          title: allElemArray.join(', '),
        })
      );
    } else {
      selectedItems.forEach((x) => {
        const c = createNewElement('div', {
          class: 'textSelected',
          text: x.text,
          id: uniqueIdentifier,
          name: uniqueIdentifier,
          title: x.text,
        });

        elementWrapper.appendChild(c);
      });
    }
    if (0 === selectElement.selectedOptions.length) {
      elementWrapper.appendChild(
        createNewElement('div', {
          class: 'multiSelectPlaceholder',
          text:
            selectElement.attributes['multiSelectPlaceholder']?.value ??
            placeholder,
        })
      );

      // CN-3050: Check & remove if additional "Select" placeholder is available
      let additionalPlaceholder =
        elementWrapper.querySelector('div.textSelected')?.nextSibling;
      if (additionalPlaceholder)
        elementWrapper.removeChild(additionalPlaceholder);
    }
  };
  elementWrapper.refresh();
};

const applyMaxSelect = (
  ifMaxSelectApplied,
  maxSelectElements,
  selectElement,
  list
) => {
  if (ifMaxSelectApplied) {
    if (selectElement.selectedOptions.length >= maxSelectElements) {
      list
        .querySelectorAll(
          `:scope > div:not(.multiselect-dropdown-all-selector)`
        )
        .forEach((d) => {
          if (d.classList.value !== 'checked') {
            d.setAttribute('disabled', '');
            d.querySelector('input').disabled = true;
          }
        });
    } else {
      list
        .querySelectorAll(
          `:scope > div:not(.multiselect-dropdown-all-selector)`
        )
        .forEach((d) => {
          if (
            d.classList.value !== 'checked' &&
            d.querySelector('input').disabled === true
          ) {
            d.removeAttribute('disabled');
            d.querySelector('input').disabled = false;
          }
        });
    }
  }
};
