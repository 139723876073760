import React, { useEffect, useState } from 'react';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedClient } from '../../redux/clientSlice';
import { updateSelectedAdvertiser, updateSelectedClientsAdvertiser } from '../../redux/advertiserSlice';
import {
  breadCrumbHeader,
  baseEndpoints,
  presentTimeFrameList,
  legacyOnlyTimeFrames
} from '../../constants';
import {
  axiosGetRequest,
  axiosSendRequest,
} from '../../services/http/axiosCalls';
import {
  getCurrentESTDate,
  toggleClasses,
  setDataWhileUpdate,
  dataHandlingForLS,
  getEncryptedOrDecryptedData,
  reportRenewalTime,
  getArrayOfData,
  getStartAndEndDate,
  setAdvertiserId
} from '../../utils/functions';
import { ErrorComponent } from '../../components';
import MediaDeliveryComponent from './components/reportDetailsTab/mediaDeliveryTab/MediaDelivery.component';
import AttributionComponent from './components/reportDetailsTab/attributionTab/Attribution.component';
import LiftComponent from './components/reportDetailsTab/liftTab/Lift.component';
import { useGetApiInterceptor } from '../../hooks/useGetApiInterceptor';

import './reportDetails.css';

const defaultDateFormat = 'YYYY-MM-DD';

const ReportDetails = () => {
  const currentDate = getCurrentESTDate(defaultDateFormat);
  const [presentTimeFrameOptions, setPresentTimeFrameOptions] = useState([...presentTimeFrameList]);
  const [searchParams] = useSearchParams(),
    reportName = searchParams.get('report_name'),
    { reportId } = useParams();
  document.title = `Report - ${reportName ?? 'Ad-Hoc Report'}`;
  let reportType = searchParams.get('report_type')
  reportType = reportType ? reportType.replace(/(\s\s\s)/g, ' + ') : reportType;
  const [selectedTab, setSelectedTab] = useState({
    mediaDelivery: true,
    attribution: false,
    lift: false,
  });

  const dispatch = useDispatch(),
    client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser),
    role = useSelector((state) => state.user.role);

    const isSuperAdmin = role === 'super admin';

  const [campaignList, setCampaignList] = useState(null),
    [campaign, setCampaign] = useState(null),
    [defaultCampaign, setDefaultCampaign] = useState(null);

  const [mediaPartnerList, setMediaPartnerList] = useState(null),
    [mediaPartner, setMediaPartner] = useState(null),
    [defaultMediaPartner, setDefaultMediaPartner] = useState(null);

  const [placementList, setPlacementList] = useState(null),
    [placement, setPlacement] = useState(null),
    [defaultPlacement, setDefaultPlacement] = useState(null);

  const [creativeTypeList, setCreativeTypeList] = useState(null),
    [creativeType, setCreativeType] = useState(null),
    [defaultCreativeType, setDefaultCreativeType] = useState(null);

  const [timeFrame, setTimeFrame] = useState(null),
    [properStartDateString, setProperStartDateString] = useState(null),
    [properLookbackDateString, setProperLookbackDateString] = useState(null),
    [defaultTimeFrame, setDefaultTimeFrame] = useState(null),
    [startDate, setStartDate] = useState(null),
    [endDate, setEndDate] = useState(null),
    [haveSetDates, setHaveSetDates] = useState(false),
    [defaultDateRange, setDefaultDateRange] = useState([null, null]),
    [dateRange, setDateRange] = useState([null, null]);

  const [isApplyCalls, setIsApplyCalls] = useState(false);

  const [isErrorReportDetails, setIsErrorReportDetails] = useState(false),
    [isErrorCampaignList, setIsErrorCampaignList] = useState(false),
    [isErrorPartnerList, setIsErrorPartnerList] = useState(false),
    [isErrorCreativeTypeList, setIsErrorCreativeTypeList] = useState(false),
    [isErrorPlacementList, setIsErrorPlacementList] = useState(false),
    [isPageExpired, setIsPageExpired] = useState(false);

  const [isDisabled, setIsDisabled] = useState({
    campaign: false,
    mediaPartner: true,
    placement: true,
    creativeType: true,
  });

  const [isLoadingBase, setIsLoadingBase] = useState({
    campaign: true,
    mediaPartner: false,
    placement: false,
    creativeType: false,
  });

  const [summaryData, setSummaryData] = useState({
    dataOne: 0,
    dataTwo: 0,
    dataThree: 0,
    dataFour: 0,
  });

  const [ifSelectedAll, setIfSelectedAll] = useState(false);

  const [isMounted, setIsMounted] = useState(false),
    [params, setParams] = useState(null),
    [isFromCD, setIsFromCD] = useState(false);

  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.reports + '/campaign-info/?campaign_id=' + params
  );

  useEffect(() => {
    if (params) {
      updateIsLoading(true, true, true, true);

      setIsDisabled({
        campaign: true,
        mediaPartner: true,
        placement: true,
        creativeType: true,
      });

      setIsFromCD(true);
      setIsMounted(true);
    }
  }, [params]);

  useEffect(() => {
    if (params) {
      if (!isLoading) {
        if (!isError) {
          setCampaignList(data?.campaign_list ?? []);
          setCampaign(data?.campaign_info);

          if (
            !client.clientList.filter(
              (item) => item.user_id === data?.campaign_info?.user_id
            )?.length
          ) {
            setIsPageExpired(true);
            return;
          }

          if (client.selectedClient !== data?.campaign_info?.user_id) {
            dispatch(updateSelectedClient(data?.campaign_info?.user_id));
            dispatch(
              updateSelectedClientsAdvertiser({
                userId: data.campaign_info.user_id,
                advertiserList: advertiser.advertiserList,
              })
            );
          }

          if (
            advertiser.selectedAdvertiser &&
            advertiser.selectedAdvertiser !== data?.campaign_info?.advertiser_id
          ) {
            dispatch(
              updateSelectedAdvertiser(data?.campaign_info?.advertiser_id)
            );
          }

          setTimeFrameOptions(data?.campaign_info, true);
          setProperStartDateString(
            moment(
              data?.campaign_info?.start_date.slice(
                0,
                data?.campaign_info?.start_date.length - 1
              )
            ).format('MM/DD/yyyy')
          );
          setProperLookbackDateString(
            moment(
              data?.campaign_info?.lookback_date.slice(
                0,
                data?.campaign_info?.lookback_date.length - 1
              )
            ).format('MM/DD/yyyy')
          );
          setMediaPartnerList(commonCondition(data, 'partner_list'));
          setMediaPartner(commonCondition(data, 'partner_list'));
          setCreativeTypeList(commonCondition(data, 'creative_type_list'));
          setCreativeType(commonCondition(data, 'creative_type_list'));
          setPlacementList(commonCondition(data, 'placement_list'));
          setPlacement(commonCondition(data, 'placement_list'));

          setTimeout(() => {
            setIfSelectedAll(true);
            setIsFromCD(false);
          }, 500);
        } else if (isError) {
          setIsMounted(false);
        }
        setIsDisabled({
          campaign: false,
          mediaPartner: false,
          placement: false,
          creativeType: false,
        });

        updateIsLoading(false, false, false, false);
      }
    }
  }, [isLoading]);

  const setTimeFrameOptions = (selectedcampaign, isFromCD = false) => {
    const campaignStartDate = moment(
        selectedcampaign?.start_date.slice(
          0,
          selectedcampaign?.start_date.length - 1
        ) ?? currentDate
      ).format(defaultDateFormat),
      campaignEndDate = moment(
        selectedcampaign?.end_date.slice(
          0,
          selectedcampaign?.start_date.length - 1
        ) ?? currentDate
      ).format(defaultDateFormat),
      campaignLookbackDate = moment(
        selectedcampaign?.lookback_date.slice(
          0,
          selectedcampaign?.start_date.length - 1
        ) ?? currentDate
      ).format(defaultDateFormat);

    presentTimeFrameOptions.forEach((option) => {
      const { newStartDate, newEndDate, isDisabled } = getStartAndEndDate(
        option.id,
        currentDate,
        campaignStartDate,
        campaignEndDate,
        campaignLookbackDate
      );

      option.dateRange = [newStartDate, newEndDate];
      option.isDisabled = isDisabled;
    });

    if (isFromCD) {
      const tf = { ...presentTimeFrameOptions[1] };
      setTimeFrame({ ...tf });
      setDateRange(tf.dateRange);
      setStartDate(tf.dateRange[0]);
      setEndDate(tf.dateRange[1]);
      return;
    }

    const tf = presentTimeFrameOptions.find((tf) => tf.id === defaultTimeFrame);

    if (!defaultDateRange[0] || !defaultDateRange[1]) {
      setDateRange(tf?.dateRange ?? [null, null]);
    }

    if (defaultTimeFrame === null || defaultTimeFrame === undefined) {
      setTimeFrame({ ...presentTimeFrameOptions[1] });
    } else {
      setDefaultTimeFrame(null);
      setTimeFrame({ ...tf });
    }
  };

  const getCampaignList = async (controller) => {
    setIfSelectedAll(false);
    updateIsLoading(true, false, false, false);
    setIsErrorCampaignList(false);
    const advertiserId = setAdvertiserId(advertiser.selectedAdvertiser, advertiser.selectedClientsAdvertiser, role);
    try {
      const campaignListPromise = axiosGetRequest(
          `${baseEndpoints.reports}/campaign-list`,
          controller.signal,
          {
            timezone: null,
            user_id:
              client.selectedClient === -1 ? null : client.selectedClient,
            advertiser_id: advertiserId,
          }
        ),
        campaignListResponse = await campaignListPromise;

      if (campaignListResponse.status === 200) {
        setCampaignList(campaignListResponse.data);

        const c = campaignListResponse.data.find(
          (item) =>
            item?.campaign_id &&
            defaultCampaign?.campaign_id &&
            item.campaign_id === defaultCampaign.campaign_id
        );
        if (c) {
          setCampaign(c);
          setProperStartDateString(
            moment(c?.start_date.slice(0, c?.start_date.length - 1)).format(
              'MM/DD/yyyy'
            )
          );
          setProperLookbackDateString(
            moment(
              c?.lookback_date.slice(0, c?.lookback_date.length - 1)
            ).format('MM/DD/yyyy')
          );
        } else {
          setCampaign(null);
          setProperLookbackDateString(null);
          setProperStartDateString(null);
          setDefaultMediaPartner(null);
          setDefaultPlacement(null);
          setDefaultCreativeType(null);
        }
        setDefaultCampaign(null);
        setIsDisabled({
          ...isDisabled,
          campaign: campaignListResponse.data.length <= 0,
          mediaPartner: true,
          placement: true,
          creativeType: true,
        });

        if (c && campaignListResponse.data.length > 0) {
          updateIsLoading(false, true, true, true);
          return;
        }
      } else {
        setIsErrorCampaignList(true);
      }
      updateIsLoading(false, false, false, false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsErrorCampaignList(true);
        updateIsLoading(false, false, false, false);
      }
    }
  };

  const getPartnerList = async (controller) => {
    setIfSelectedAll(false);
    updateIsLoading(false, true, true, true);
    setIsErrorPartnerList(false);
    try {
      const partnerListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/partner-list`,
        controller.signal,
        {
          campaign_id: campaign.campaign_id,
        }
      );
      if (partnerListResponse.status === 200) {
        const dataListPartner =
          partnerListResponse.data.length > 0 ? partnerListResponse.data : null;
        setDataWhileUpdate(
          dataListPartner,
          setMediaPartner,
          defaultMediaPartner,
          setDefaultMediaPartner,
          'partner_id'
        );
        setMediaPartnerList(dataListPartner);
        setIsDisabled({
          ...isDisabled,
          mediaPartner: partnerListResponse.data.length <= 0,
        });
        if (partnerListResponse.data.length > 0) {
          updateIsLoading(false, false, true, true);
          return;
        }
      } else {
        setIsErrorPartnerList(true);
      }
      updateIsLoading(false, false, false, false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsErrorPartnerList(true);
        updateIsLoading(false, false, false, false);
      }
    }
  };

  const getCreativeTypeList = async (controller) => {
    setIfSelectedAll(false);
    updateIsLoading(false, false, true, true);
    setIsErrorCreativeTypeList(false);

    try {
      const creativeTypeListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/creative-type-list`,
        controller.signal,
        {
          campaign_id: campaign.campaign_id,
          partner_id: mediaPartner
            ? getArrayOfData(mediaPartner, 'partner_id')
            : null,
        }
      );
      if (creativeTypeListResponse.status === 200) {
        const dataListCreativeType =
          creativeTypeListResponse.data.length > 0
            ? creativeTypeListResponse.data
            : null;

        setDataWhileUpdate(
          dataListCreativeType,
          setCreativeType,
          defaultCreativeType,
          setDefaultCreativeType,
          'name'
        );

        setCreativeTypeList(dataListCreativeType);

        setIsDisabled({
          ...isDisabled,
          creativeType: creativeTypeListResponse.data.length <= 0,
        });
        if (creativeTypeListResponse.data.length > 0) {
          updateIsLoading(false, false, false, true);
          return;
        }
      } else {
        setIsErrorCreativeTypeList(true);
      }
      updateIsLoading(false, false, false, false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsErrorCreativeTypeList(true);
        updateIsLoading(false, false, false, false);
      }
    }
  };

  const getPlacementList = async (controller) => {
    setIfSelectedAll(false);
    setIsErrorPlacementList(false);
    updateIsLoading(false, false, false, true);
    try {
      const placementListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/placement-list`,
        controller.signal,
        {
          campaign_id: campaign.campaign_id,
          partner_id: mediaPartner
            ? getArrayOfData(mediaPartner, 'partner_id')
            : null,
          creative_type: creativeType
            ? getArrayOfData(creativeType, 'name')
            : null,
        }
      );
      if (placementListResponse.status === 200) {
        const dataListPlacement =
          placementListResponse.data.length > 0
            ? placementListResponse.data[0]
            : null;

        setDataWhileUpdate(
          dataListPlacement,
          setPlacement,
          defaultPlacement,
          setDefaultPlacement,
          'placement_id'
        );

        setPlacementList(dataListPlacement);

        setIsDisabled({
          ...isDisabled,
          placement: placementListResponse.data.length <= 0,
        });
      } else {
        setIsErrorPlacementList(true);
      }
      updateIsLoading(false, false, false, false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err);
        setIsErrorPlacementList(true);
        updateIsLoading(false, false, false, false);
      }
    }
  };

  const updateIsLoading = (c, pr, cr, pl) => {
    setIsLoadingBase({
      campaign: c,
      mediaPartner: pr,
      creativeType: cr,
      placement: pl,
    });
  };

  const resetAll = () => {
    setCampaign(null);
    setTimeFrame(null);
    setProperLookbackDateString(null);
    setProperStartDateString(null);
    setPlacementList(null);
    setMediaPartnerList(null);
    setMediaPartner(null);
    setCreativeType(null);
    setCreativeTypeList(null);
    setPlacement(null);
    setStartDate(null);
    setEndDate(null);
    setHaveSetDates(false);
    setIsDisabled({
      ...isDisabled,
      mediaPartner: true,
      creativeType: true,
      placement: true,
    });
  };

  useEffect(() => {
    if (isFromCD) {
      return;
    }
    resetAll();
    if (advertiser.selectedAdvertiser !== -1 && isApplyCalls) {
      const controller = new AbortController();
      getCampaignList(controller);
      return () => {
        controller.abort();
      };
    } else {
      setIsDisabled({
        ...isDisabled,
        campaign: true,
        mediaPartner: true,
        placement: true,
        creativeType: true,
      });
      setCampaignList(null);
      setMediaPartnerList(null);
      setCreativeTypeList(null);
      setPlacementList(null);
    }
  }, [client, advertiser, isApplyCalls]);

  const getReportsDetails = async (controller) => {
    setIsErrorReportDetails(false);
    try {
      const reportDetails = await axiosGetRequest(
        `${baseEndpoints.reports}/${reportId}`,
        controller.signal,
        {
          report_type: reportType,
        }
      );
      if (reportDetails.status === 200) {
        const data = reportDetails?.data;

        if (client.selectedClient !== data.user_id) {
          dispatch(updateSelectedClient(data.user_id));
        }

        if (
          advertiser.selectedAdvertiser &&
          advertiser.selectedAdvertiser !== data.advertiser_id
        ) {
          dispatch(updateSelectedAdvertiser(data.advertiser_id));
        }

        setDefaultCampaign({
          campaign_id: data?.campaign_id,
        });
       
        if(data?.time_frame === 6){
          setDefaultTimeFrame(13)
        } else if(data?.time_frame === 5){
          setDefaultTimeFrame(12)
        } else if (data?.time_frame === 9){
          setDefaultTimeFrame(19)
        } else {
          setDefaultTimeFrame(data?.time_frame);
        }

        setDefaultDateRange([data?.start_date, data?.end_date]);

        setDefaultPlacement(
          setDefaultValuesForDropdowns(
            data,
            'placement_id',
            'placement_id',
            reportType
          )
        );
        setDefaultMediaPartner(
          setDefaultValuesForDropdowns(
            data,
            'partner_id',
            'partner_id',
            reportType
          )
        );
        if (data.creative_type) {
          setDefaultCreativeType(
            setDefaultValuesForDropdowns(
              data,
              'creative_type',
              'name',
              reportType,
              false
            )
          );
        } else {
          setDefaultCreativeType(creativeTypeList);
        }

        setIsApplyCalls(true);
      } else {
        setIsErrorReportDetails(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsErrorReportDetails(true);
      }
    }
  };

  const runReportConditions = (rd) => {
    const lsAll = localStorage.getItem('AllReports'),
      ls = localStorage.getItem(rd);

    if (lsAll) {
      const decryptedAllLs = JSON.parse(
          getEncryptedOrDecryptedData(lsAll, 'decrypt')
        ),
        allReportItem = decryptedAllLs.find((item) => item.name === rd);

      if (allReportItem && ls) {
        let decryptedReportDetails = getEncryptedOrDecryptedData(ls, 'decrypt');
        decryptedReportDetails = decryptedReportDetails.replace(
          /[^\x20-\x7F]/g,
          ''
        );
        const decryptedLs = JSON.parse(decryptedReportDetails);

        return { decryptedAllLs, allReportItem, decryptedLs };
      }

      if (!ls) {
        dataHandlingForLS({
          type: 'removeElementsFromAllReports',
          nameAll: 'AllReports',
          removingElements: [rd],
        });
      }

      if (ls && !allReportItem) {
        dataHandlingForLS({
          type: 'deleteAReport',
          nameReport: rd,
        });
      }
    }

    if (!lsAll && ls) {
      dataHandlingForLS({
        type: 'deleteAReport',
        nameReport: rd,
      });
    }

    return { decryptedAllLs: null, allReportItem: null, decryptedLs: null };
  };

  useEffect(() => {
    if (reportId === 'run') {
      const queryParams = new URLSearchParams(window.location.search),
        rd = queryParams.get('rd'),
        rd1 = queryParams.get('rd1');

      if (rd) {
        const timestamp = new Date().getTime(),
          decryptedLsData = runReportConditions(rd);

        if (decryptedLsData.decryptedLs) {
          dataHandlingForLS({
            type: 'allReportsUpdate',
            nameAll: 'AllReports',
            nameReport: rd,
            timestamp,
            allDecryptReport: decryptedLsData.decryptedAllLs,
            updatingItem: decryptedLsData.allReportItem,
          });

          dispatch(updateSelectedClient(decryptedLsData.decryptedLs.user_id));
          dispatch(
            updateSelectedAdvertiser(decryptedLsData.decryptedLs.advertiser_id)
          );

          setDefaultCampaign(decryptedLsData.decryptedLs.campaign_details);

          setDefaultTimeFrame(decryptedLsData.decryptedLs.time_frame);

          setDefaultMediaPartner(decryptedLsData.decryptedLs.partner_details);

          setDefaultPlacement(decryptedLsData.decryptedLs.placement_details);

          setDefaultCreativeType(
            decryptedLsData.decryptedLs.creative_type_details
          );

          setDefaultDateRange([
            decryptedLsData.decryptedLs.start_date,
            decryptedLsData.decryptedLs.end_date,
          ]);

          setIsDisabled({
            ...isDisabled,
            campaign: false,
            mediaPartner: true,
            placement: true,
            creativeType: true,
          });

          setIsApplyCalls(true);
        } else {
          setIsPageExpired(true);
        }
      } else if (rd1 && !isNaN(rd1)) {
        setParams(Number(rd1));
      } else {
        setIsPageExpired(true);
      }

      return;
    }

    const controller = new AbortController();
    getReportsDetails(controller);

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (isFromCD) {
      return;
    }
    if (timeFrame?.id) {
      setDateRange(timeFrame?.dateRange ?? [null, null]);
      return;
    }
    if (defaultDateRange[0] && defaultDateRange[1]) {
      setDefaultDateRange([null, null]);
      setDateRange(defaultDateRange);
    } else {
      setDateRange([null, null]);
    }
  }, [timeFrame]);

  useEffect(() => {
    if (reportId === 'run') {
      setInterval(() => {
        const queryParams = new URLSearchParams(window.location.search),
          rd = queryParams.get('rd');

        if (rd) {
          const timestamp = new Date().getTime(),
            decryptedLsData = runReportConditions(rd);

          if (decryptedLsData.decryptedLs) {
            dataHandlingForLS({
              type: 'allReportsUpdate',
              nameAll: 'AllReports',
              nameReport: rd,
              timestamp,
              allDecryptReport: decryptedLsData.decryptedAllLs,
              updatingItem: decryptedLsData.allReportItem,
            });
          } else {
            setIsPageExpired(true);
          }
        }
      }, reportRenewalTime);
    }
  }, []);

  useEffect(() => {
    if (isFromCD) {
      return;
    }
    if (campaign?.campaign_id) {
      const controller = new AbortController();

      setTimeFrameOptions(campaign);

      setPlacement(placement || null);
      getPartnerList(controller);

      return () => {
        controller.abort();
      };
    }
    setDateRange([null, null]);
    setSummaryData({
      dataOne: 0,
      dataTwo: 0,
      dataThree: 0,
      dataFour: 0,
    });
  }, [campaign?.campaign_id]);

  useEffect(() => {
    if (isFromCD) {
      return;
    }
    setPlacement(null);
    setPlacementList(null);
    setIsDisabled({
      ...isDisabled,
      placement: true,
      mediaPartner: true,
      creativeType: true,
    });

    if (campaign?.campaign_id && mediaPartner?.length && creativeType?.length) {
      const controller = new AbortController();
      getPlacementList(controller);

      return () => {
        controller.abort();
      };
    }
  }, [creativeType]);

  useEffect(() => {
    if (placement || Object.values(isLoadingBase).indexOf(true) === -1) {
      if (isFromCD) {
        return;
      }
      setTimeout(() => {
        setIfSelectedAll(true);
      }, 500);
    }
  }, [placement, isLoadingBase]);

  useEffect(() => {
    if (isFromCD) {
      return;
    }
    setPlacement(null);
    setPlacementList(null);
    setCreativeType(null);
    setCreativeTypeList(null);
    setIsDisabled({
      ...isDisabled,
      placement: true,
      mediaPartner: true,
      creativeType: true,
    });
    if (mediaPartner) {
      const controller = new AbortController();
      getCreativeTypeList(controller);
      return () => {
        controller.abort();
      };
    }
  }, [mediaPartner]);


  useEffect(() => {
    legacyOnlyTimeFrames.forEach((option) => {
      if(option.id === defaultTimeFrame && !presentTimeFrameOptions.includes(option)){
        setPresentTimeFrameOptions([...presentTimeFrameOptions, option])
      }
    })
  }, [defaultTimeFrame]);

  return (
    <div className='reportDetailsOuterWrapper'>
      {isPageExpired ? (
        <ErrorComponent
          ifNotAvailable={true}
          expiredMessage={
            <div className='expiredReport'>
              <h3>This report can not be executed at this time.</h3>
              Please return to the <a href='/reports'> Reports Page </a> and run
              a new report.
            </div>
          }
        />
      ) : (
        <div className='breadCrumbOuterWrapper'>
          <div className='breadCrumbInnerWrapper'>
            <Link to='/'>
              <div className='homeBlue'></div>
            </Link>
            <div className='breadCrumbBody'>
              /
              <Link to='/reports'>
                <div className='active'>{breadCrumbHeader.myReports}</div>
              </Link>
            </div>
            <div className='breadCrumbBody'>
              <span>/</span>
              {reportName ?? 'Ad-Hoc Report'}
            </div>
          </div>
          <div>
            <div className="reportDetailsHeaderWrapper">
              <h1 className="reportDetailsHeaderTitle">{reportName ?? 'Ad-Hoc Report'}</h1>
              {campaign?.incorrect_data_warning === 1 && <h5 
              style={campaign?.notes?.length > 0 ? { backgroundColor: 'var(--aa-lightest-gray2)'} : {}} 
              className="reportDetailsHeaderCampaignNotes">
                {campaign?.notes?.length > 100 ? campaign.notes.slice(0, 100) + '...' : campaign.notes}
              </h5>}
            </div>
            <div className='summaryReportOuterWrapper'>
              <div
                id='reportDetailsSummaryHeaderTabs'
                name='reportDetailsSummaryHeaderTabs'
                className='summaryTabHeaderWrapper'
              >
                <div className='tableTabWrapper'>
                  <div
                    id='reportDetailsSummaryHeaderMediaDeliveryTab'
                    name='reportDetailsSummaryHeaderMediaDeliveryTab'
                    className={toggleClasses(
                      selectedTab.mediaDelivery,
                      'tableTabHeaderContent tableTabSelected',
                      'tableTabHeaderContent'
                    )}
                    onClick={() => {
                      setSelectedTab({
                        mediaDelivery: true,
                        attribution: false,
                        lift: false,
                      });
                    }}
                  >
                    Media Delivery
                  </div>
                  <div
                    id='reportDetailsSummaryHeaderAttributionTab'
                    name='reportDetailsSummaryHeaderAttributionTab'
                    className={toggleClasses(
                      selectedTab.attribution,
                      'tableTabHeaderContent tableTabSelected',
                      'tableTabHeaderContent'
                    )}
                    onClick={() => {
                      setSelectedTab({
                        mediaDelivery: false,
                        attribution: true,
                        lift: false,
                      });
                    }}
                  >
                    Attribution
                  </div>
                  {Boolean(campaign?.lift_report_enabled) && (
                    <div
                      id='reportDetailsSummaryHeaderLiftTab'
                      name='reportDetailsSummaryHeaderLiftTab'
                      className={toggleClasses(
                        selectedTab.lift,
                        'tableTabHeaderContent tableTabSelected',
                        'tableTabHeaderContent'
                      )}
                      onClick={() => {
                        setSelectedTab({
                          mediaDelivery: false,
                          attribution: false,
                          lift: true,
                        });
                      }}
                    >
                      Lift
                    </div>
                  )}
                </div>
              </div>
              <div className='summaryReportInnerWrapper'>
                {selectedTab.mediaDelivery && (
                  <MediaDeliveryComponent
                    campaign={campaign}
                    setCampaign={setCampaign}
                    properStartDateString={properStartDateString}
                    properLookbackDateString={properLookbackDateString}
                    setProperStartDateString={setProperStartDateString}
                    setProperLookbackDateString={setProperLookbackDateString}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    haveSetDates={haveSetDates}
                    setHaveSetDates={setHaveSetDates}
                    placement={placement}
                    setPlacement={setPlacement}
                    defaultPlacement={defaultPlacement}
                    setDefaultPlacement={setDefaultPlacement}
                    mediaPartner={mediaPartner}
                    setMediaPartner={setMediaPartner}
                    defaultMediaPartner={defaultMediaPartner}
                    setDefaultMediaPartner={setDefaultMediaPartner}
                    creativeType={creativeType}
                    setCreativeType={setCreativeType}
                    defaultCreativeType={defaultCreativeType}
                    setDefaultCreativeType={setDefaultCreativeType}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                    getReportsDetails={getReportsDetails}
                    isErrorReportDetails={isErrorReportDetails}
                    isErrorCampaignList={isErrorCampaignList}
                    isErrorPlacementList={isErrorPlacementList}
                    getCampaignList={getCampaignList}
                    getPlacementList={getPlacementList}
                    campaignList={campaignList}
                    placementList={placementList}
                    setPlacementList={setPlacementList}
                    mediaPartnerList={mediaPartnerList}
                    setMediaPartnerList={setMediaPartnerList}
                    creativeTypeList={creativeTypeList}
                    setCreativeTypeList={setCreativeTypeList}
                    isLoading={isLoadingBase}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    summaryData={summaryData}
                    setSummaryData={setSummaryData}
                    ifSelectedAll={ifSelectedAll}
                    updateIsLoading={updateIsLoading}
                    getPartnerList={getPartnerList}
                    getCreativeTypeList={getCreativeTypeList}
                    isErrorPartnerList={isErrorPartnerList}
                    isErrorCreativeTypeList={isErrorCreativeTypeList}
                    presentTimeFrameOptions={presentTimeFrameOptions}
                    timeFrame={timeFrame}
                    setTimeFrame={setTimeFrame}
                  />
                )}
                {selectedTab.attribution && (
                  <AttributionComponent
                    campaign={campaign}
                    setCampaign={setCampaign}
                    properStartDateString={properStartDateString}
                    properLookbackDateString={properLookbackDateString}
                    setProperStartDateString={setProperStartDateString}
                    setProperLookbackDateString={setProperLookbackDateString}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setHaveSetDates={setHaveSetDates}
                    placement={placement}
                    setPlacement={setPlacement}
                    defaultPlacement={defaultPlacement}
                    setDefaultPlacement={setDefaultPlacement}
                    mediaPartner={mediaPartner}
                    setMediaPartner={setMediaPartner}
                    defaultMediaPartner={defaultMediaPartner}
                    setDefaultMediaPartner={setDefaultMediaPartner}
                    creativeType={creativeType}
                    setCreativeType={setCreativeType}
                    defaultCreativeType={defaultCreativeType}
                    setDefaultCreativeType={setDefaultCreativeType}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                    getReportsDetails={getReportsDetails}
                    isErrorReportDetails={isErrorReportDetails}
                    isErrorCampaignList={isErrorCampaignList}
                    isErrorPlacementList={isErrorPlacementList}
                    getCampaignList={getCampaignList}
                    getPlacementList={getPlacementList}
                    campaignList={campaignList}
                    placementList={placementList}
                    setPlacementList={setPlacementList}
                    mediaPartnerList={mediaPartnerList}
                    setMediaPartnerList={setMediaPartnerList}
                    creativeTypeList={creativeTypeList}
                    setCreativeTypeList={setCreativeTypeList}
                    isLoading={isLoadingBase}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    summaryData={summaryData}
                    setSummaryData={setSummaryData}
                    ifSelectedAll={ifSelectedAll}
                    updateIsLoading={updateIsLoading}
                    getPartnerList={getPartnerList}
                    getCreativeTypeList={getCreativeTypeList}
                    isErrorPartnerList={isErrorPartnerList}
                    isErrorCreativeTypeList={isErrorCreativeTypeList}
                    presentTimeFrameOptions={presentTimeFrameOptions}
                    timeFrame={timeFrame}
                    setTimeFrame={setTimeFrame}
                    reportName={reportName ?? 'Ad-Hoc Report'}
                  />
                )}
                {selectedTab.lift && (
                  <LiftComponent
                  campaign={campaign}
                    setCampaign={setCampaign}
                    properStartDateString={properStartDateString}
                    properLookbackDateString={properLookbackDateString}
                    setProperStartDateString={setProperStartDateString}
                    setProperLookbackDateString={setProperLookbackDateString}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    haveSetDates={haveSetDates}
                    setHaveSetDates={setHaveSetDates}
                    placement={placement}
                    setPlacement={setPlacement}
                    defaultPlacement={defaultPlacement}
                    setDefaultPlacement={setDefaultPlacement}
                    mediaPartner={mediaPartner}
                    setMediaPartner={setMediaPartner}
                    defaultMediaPartner={defaultMediaPartner}
                    setDefaultMediaPartner={setDefaultMediaPartner}
                    creativeType={creativeType}
                    setCreativeType={setCreativeType}
                    defaultCreativeType={defaultCreativeType}
                    setDefaultCreativeType={setDefaultCreativeType}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                    getReportsDetails={getReportsDetails}
                    isErrorReportDetails={isErrorReportDetails}
                    isErrorCampaignList={isErrorCampaignList}
                    isErrorPlacementList={isErrorPlacementList}
                    getCampaignList={getCampaignList}
                    getPlacementList={getPlacementList}
                    campaignList={campaignList}
                    placementList={placementList}
                    setPlacementList={setPlacementList}
                    mediaPartnerList={mediaPartnerList}
                    setMediaPartnerList={setMediaPartnerList}
                    creativeTypeList={creativeTypeList}
                    setCreativeTypeList={setCreativeTypeList}
                    isLoading={isLoadingBase}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    summaryData={summaryData}
                    setSummaryData={setSummaryData}
                    ifSelectedAll={ifSelectedAll}
                    updateIsLoading={updateIsLoading}
                    getPartnerList={getPartnerList}
                    getCreativeTypeList={getCreativeTypeList}
                    isErrorPartnerList={isErrorPartnerList}
                    isErrorCreativeTypeList={isErrorCreativeTypeList}
                    presentTimeFrameOptions={presentTimeFrameOptions}
                    timeFrame={timeFrame}
                    setTimeFrame={setTimeFrame}
                    parametersIsLoading={isLoadingBase}
                    reportName={reportName ?? 'Ad-Hoc Report'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportDetails;

const setDefaultValuesForDropdowns = (
  data,
  type,
  param,
  reportType,
  isNumber = true
) => {
  let dataArray = [];
  if (reportType === 'On Demand') {
    dataArray = data?.[type]?.split(',').map((elem) => {
      return { [param]: isNumber ? Number(elem) : elem };
    });
  } else {
    dataArray = data[type]
      ? data[type]?.map((elem) => {
          return { [param]: elem };
        })
      : data[type];
  }

  if (dataArray && dataArray.length > 0) {
    return dataArray;
  }

  return [];
};

const commonCondition = (data, key) => {
  if (data[key] && data[key].length) {
    return data[key];
  }
  return null
}
