import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'react-notifications-component';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import {
  axiosGetRequest,
  axiosPatchRequest,
  axiosPostRequest,
} from '../../../services/http/axiosCalls';
import NoDataComponent from '../../../components/commonTable/NoData.component';
import LoaderComponent from '../../../components/loaderComponent/Loader.component';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  CommonModal,
  ErrorComponent,
} from '../../../components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {
  checkCondition,
  getArrayOfData,
  toggleClasses,
} from '../../../utils/functions';
import { baseEndpoints } from '../../../constants';
import { commonNotification } from '../../../utils/common-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { setBlockingLoaderState } from '../../../redux/blockingLoader';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ExportFilesComponent } from '../../../components';

import './campaign.css';

export const EditCampaign = ({
  id,
  updateBreadCrumb,
  updateReports,
  campaignName,
  setDataWarningText,
  setShowCampaignNotes,
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // navigate between pages

  const [defaultCampaignData, setDefaultCampaignData] = useState({}),
    [campaignData, setCampaignData] = useState({});

  const [defaultProperties, setDefaultProperties] = useState([]),
    [selectedProperties, setSelectedProperties] = useState([]),
    [availableProperties, setAvailableProperties] = useState([]),
    [defaultSelectedProperties, setDefaultSelectedProperties] = useState([]);

  const [currentDate, setCurrentDate] = useState(null);

  const [isUpdating, setIsUpdating] = useState(false);

  const [pixelData, setPixelsData] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [expandOrCollapse, setExpandOrCollapse] = useState(false);
  const [isError, setIsError] = useState(false);

  const [tabSelected, setTabSelected] = useState(false);

  const [filter, setFilter] = useState([]),
    [filterText, setFilterText] = useState('');

  const [status, setStatus] = useState(200);
  const [cancelController, setCancelController] = useState(null);
  const [showTagDeleteModal, setShowTagDeleteModal] = useState(false);
  const [showTagDeleteConfirmationModal, setShowTagDeleteConfirmationModal] =
    useState(false);

  const [updateDetails, setUpdateDetails] = useState(false);
  const [disableAcceptBtnAtLast, setDisableAcceptBtnAtLast] = useState(false);

  const [validName, setValidName] = useState({
      valid: true,
      errorMsg: '',
    }),
    [validOrder, setValidOrder] = useState({
      valid: true,
      errorMsg: false,
    }),
    [validNotes, setValidNotes] = useState({
      valid: true,
      errorMsg: false,
    });

  const [isChanged, setIsChanged] = useState({
    name: false,
    order: false,
    description: false,
    status: false,
    properties: false,
  });

  const { role } = useSelector((state) => state.user);
  const isSuperAdmin = role === 'super admin';

  const getCampaignDetailsData = async (cancelToken) => {
    setIsLoading(true);
    try {
      const response = await axiosGetRequest(
        `${baseEndpoints.campaigns}/${id}`,
        cancelToken.signal
      );
      const respData = response.data;
      setStatus(response.status);
      if (response.status === 200) {
        const tmp = respData.details[0];

        tmp.start_date = returnDate(tmp.start_date);
        tmp.end_date = returnDate(tmp?.end_date);

        // Commented because of the merge conflicis (In dev getting no code here)
        // if (tmp.created) tmp.created = returnDate(tmp.created);
        // if (tmp.modified_date)
        //   tmp.modified_date = returnDate(tmp.modified_date);

        respData.details = tmp;

        setDefaultCampaignData(respData?.details);
        setCampaignData(respData?.details);
        updateBreadCrumb(respData?.details.name);

        const allProperties = respData?.properties.filter((p) => p.name !== '');
        setDefaultProperties(allProperties);
        setAvailableProperties(allProperties);
        const sp = respData?.properties?.filter((val) => val.selected);
        setSelectedProperties(sp);
        setDefaultSelectedProperties(sp);
        setPixelsData(respData?.pixels);
        setCurrentDate(respData?.current_date);
        setDataWarningText(respData?.details?.notes);
        setShowCampaignNotes(respData?.details?.incorrect_data_warning === 1);
        setIsError(false);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        if (err.response) {
          console.log(err);
        }
        setIsLoading(false);
      }
    }
  };

  const updateCampaign = async (controller) => {
    setIsUpdating(true);
    try {
      const response = await axiosPatchRequest(
        `${baseEndpoints.campaigns}/${id}`,
        controller,
        {
          ...campaignData,
          property_id:
            selectedProperties && selectedProperties.length > 0
              ? getArrayOfData(selectedProperties, 'property_id')
              : null,
        }
      );
      if (response?.status == 200) {
        Store.addNotification({
          title: 'Campaign Updated',
          message: 'Campaign updated successfully',
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        setDefaultCampaignData(campaignData);
        setDefaultProperties(availableProperties);
        updateBreadCrumb(campaignData?.name);
        // Update campaign details in UI
        setUpdateDetails(!updateDetails);
        updateReports();
        resetStatus();
      } else {
        Store.addNotification({
          title: 'Unable to Update Campaign',
          message:
            error?.response?.data?.error_message ||
            'Internal server error. Unable to update the campaign. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      setIsUpdating(false);
    } catch (error) {
      if (error?.response?.status === 409) {
        Store.addNotification({
          title: 'Unable to Update Campaign',
          message: error?.response?.data?.error_message,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        setValidName({
          valid: false,
          errorMsg: error?.response?.data?.error_message,
        });
        const searchElem = document.getElementById(`editCampaignName`);
        searchElem?.focus();
        setIsUpdating(false);
        return;
      }
      Store.addNotification({
        title: 'Unable to Update Campaign',
        message:
          error?.response?.data?.error_message ||
          'Internal server error. Unable to update the campaign. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
      setIsUpdating(false);
    }
  };

  const resetAll = () => {
    setCampaignData(defaultCampaignData);
    setValidName({ valid: true, errorMsg: '' });
    setValidOrder({ valid: true, errorMsg: '' });
    setValidNotes({ valid: true, errorMsg: '' });
    setIsChanged({
      name: false,
      order: false,
      description: false,
      status: false,
      properties: false,
    });
    setAvailableProperties(defaultProperties);
    setSelectedProperties(defaultProperties.filter((val) => val.selected));
    if (tabSelected) {
      setFilter(defaultProperties.filter((val) => val.selected));
    } else {
      setFilter(defaultProperties);
    }
    setFilterText('');
  };

  const resetStatus = () => {
    setValidName({
      valid: true,
      errorMsg: '',
    });
    setValidOrder({
      valid: true,
      errorMsg: false,
    });
    setValidNotes({
      valid: true,
      errorMsg: false,
    });
    setIsChanged({
      name: false,
      order: false,
      description: false,
      status: false,
      properties: false,
    });
  };

  const acceptDeleteTagData = () => {
    setShowTagDeleteModal(false);
    setShowTagDeleteConfirmationModal(true);
  };
  const cancelDeleteTagDataDialog = () => {
    setShowTagDeleteModal(false);
    setShowTagDeleteConfirmationModal(false);
  };

  const acceptDeleteTagDataConfirmation = async () => {
    dispatch(setBlockingLoaderState({ active: true }));
    setDisableAcceptBtnAtLast(true);
    try {
      const response = await axiosPostRequest(
        `${baseEndpoints.campaigns}/${id}/delete-tag-data-request`,
        new AbortController()
      );
      const respData = response.data;
      if (response.status === 200) {
        const msg =
          'Successfully Deleted Tag Data for the Campaign: ' +
          campaignData?.name;
        commonNotification({ msg, type: 'success' });
      } else {
        commonNotification({ msg: respData.error, type: 'danger' });
      }
      dispatch(setBlockingLoaderState({ active: false }));
    } catch (err) {
      console.error(err);
      let msg = ';';
      if (!axios.isCancel(err)) {
        if (err.response) {
          msg = err.response.data.error;
        }
        commonNotification({ msg, type: 'danger' });
        dispatch(setBlockingLoaderState({ active: false }));
      }
    }
    setShowTagDeleteModal(false);
    setShowTagDeleteConfirmationModal(false);
    setDisableAcceptBtnAtLast(false);
  };

  const checkValid = (val, key) => {
    switch (key) {
      case 'name':
        if (!val?.length) {
          setValidName({
            valid: false,
            errorMsg: 'Campaign Name is required',
          });
          return;
        }
        if (val?.length > 100) {
          setValidName({
            valid: false,
            errorMsg: 'Maximum characters allowed for Campaign Name is 100',
          });
          return;
        }
        setValidName({
          valid: true,
          errorMsg: '',
        });
        break;
      case 'order':
        if (val?.length > 50) {
          setValidOrder({
            valid: false,
            errorMsg: 'Maximum characters allowed for Order Number is 50',
          });
          return;
        }
        setValidOrder({
          valid: true,
          errorMsg: '',
        });
        break;
      case 'notes':
        if (val?.length > 100) {
          setValidNotes({
            valid: false,
            errorMsg: 'Maximum characters allowed for Notes is 100',
          });
          return;
        }
        setValidNotes({
          valid: true,
          errorMsg: '',
        });
        break;

      default:
        break;
    }
  };

  const checkChange = (val, key) => {
    switch (key) {
      case 'name':
        setIsChanged({ ...isChanged, name: val !== defaultCampaignData?.name });
        break;
      case 'status':
        setIsChanged({
          ...isChanged,
          status: val !== defaultCampaignData?.enabled,
        });
        break;
      case 'order':
        setIsChanged({
          ...isChanged,
          order: val !== defaultCampaignData?.order_number,
        });
        break;
      case 'description':
        setIsChanged({
          ...isChanged,
          description: val !== defaultCampaignData?.description,
        });
        break;
      case 'properties':
        setIsChanged({
          ...isChanged,
          properties:
            JSON.stringify(getArrayOfData(val, 'property_id')) !==
            JSON.stringify(
              getArrayOfData(defaultSelectedProperties, 'property_id')
            ),
        });
        break;
      case 'incorrectDataWarning':
        setIsChanged({
          ...isChanged,
          incorrectDataWarning:
            val !== defaultCampaignData?.incorrect_data_warning,
        });
        break;
      case 'notes':
        setIsChanged({
          ...isChanged,
          notes: val !== defaultCampaignData?.notes,
        });
        break;
      default:
        break;
    }
  };

  const disableButton = () => {
    if (!Object.values(isChanged).includes(true)) {
      return true;
    }
    return !validName.valid || !validOrder.valid;
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCampaignDetailsData(cancelToken);
    setCancelController(cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, [updateDetails]);

  const adminDetails = (campaignData) => {
    if (!isSuperAdmin) {
      return <></>;
    }

    return (
      <Col xl={12} lg={12} md={12} sm={12}>
        <Row className='campaignAdminDetailsRow'>
          <Col xl={3} lg={4} md={12} sm={12}>
            <div className='pl-10 campaignAdminDetails'>
              <div className='field_label'>
                Created By{' '}
                <GetCreatedOrModifiedInfoHtml
                  data={campaignData?.createdby_name}
                />
              </div>
            </div>
          </Col>
          <Col xl={3} lg={2} md={12} sm={12}>
            <div className='pl-10 campaignAdminDetails'>
              <div className='field_label'>
                Created Date{' '}
                <GetCreatedOrModifiedInfoHtml data={campaignData?.created} />
              </div>
            </div>
          </Col>
          <Col xl={3} lg={4} md={12} sm={12}>
            <div className='pl-10 campaignAdminDetails'>
              <div className='field_label'>
                Modified By{' '}
                <GetCreatedOrModifiedInfoHtml
                  data={campaignData?.modifiedby_name}
                />
              </div>
            </div>
          </Col>
          <Col xl={3} lg={2} md={12} sm={12}>
            <div className='pl-10 campaignAdminDetails'>
              <div>
                <div className='field_label'>
                  Modified Date{' '}
                  <GetCreatedOrModifiedInfoHtml
                    data={campaignData?.modified_date}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderErrorOrDetailsComponent = () => {
    return isError ? (
      <ErrorComponent
        refreshAction={getCampaignDetailsData}
        controller={cancelController}
        status={status}
        customWrapperClass='errorWrapper'
      />
    ) : (
      renderNoDataOrDetailsComponent()
    );
  };

  const renderNoDataOrDetailsComponent = () => {
    return (
      <>
        {!campaignData ? (
          <NoDataComponent />
        ) : (
          <>
            {!expandOrCollapse && (
              <Container fluid className='campaignListInnerWrapper'>
                <Row>
                  <Col xl={6} md={12} sm={12}>
                    <Row>
                      <Col sm={12} className='pd-btm'>
                        <div className='field relative'>
                          <div>
                            <div className='field_label'>
                              <span>Campaign Name</span>
                              <span className='important_field'>*</span>
                            </div>
                            <input
                              type='text'
                              id='editCampaignName'
                              name='editCampaignName'
                              value={campaignData?.name}
                              onChange={(e) => {
                                setCampaignData({
                                  ...campaignData,
                                  name: e.target.value,
                                });
                                checkValid(e.target.value, 'name');
                                checkChange(e.target.value, 'name');
                              }}
                              placeholder='Enter Campaign Name'
                              className={
                                validName.valid
                                  ? 'editCampaignInput'
                                  : 'editCampaignInput inputError'
                              }
                            />
                          </div>
                          {!validName.valid && (
                            <span className='error'>{validName.errorMsg}</span>
                          )}
                        </div>
                      </Col>
                      <Col sm={4} className='pd-btm'>
                        <CampaignDetailsField
                          label='Campaign ID'
                          value={campaignData?.campaign_id}
                          disable={true}
                        />
                      </Col>
                      <Col sm={4} className='pd-btm'>
                        <CampaignDetailsField
                          label='Advertiser'
                          value={campaignData?.advertiser_name}
                          disable={true}
                        />
                      </Col>
                      <Col sm={4} className='pd-btm'>
                        <CampaignDetailsField
                          label='Lift Report'
                          value={checkCondition(
                            campaignData?.lift_report_enabled,
                            'Yes',
                            'No'
                          )}
                          disable={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4} className='pd-btm'>
                        <CampaignDetailsField
                          label='Start Date'
                          value={campaignData?.start_date}
                          disable={true}
                        />
                      </Col>
                      <Col sm={4} className='pd-btm'>
                        <CampaignDetailsField
                          label='End Date'
                          value={campaignData?.end_date}
                          disable={true}
                        />
                      </Col>
                      <Col sm={4} className='pd-btm'>
                        <div className='field'>
                          <div className='field_label'>Status</div>
                          <select
                            name='liftReport'
                            disabled={checkCondition(
                              new Date(currentDate) <
                                new Date(campaignData?.end_date),
                              false,
                              true
                            )}
                            value={checkCondition(
                              campaignData?.enabled === 'Y',
                              'Y',
                              'N'
                            )}
                            onChange={(e) => {
                              setCampaignData({
                                ...campaignData,
                                enabled: e.target.value,
                              });
                              checkChange(e.target.value, 'status');
                            }}
                            style={{ width: '100%', height: '26px' }}
                          >
                            <option value={'Y'}>Active</option>
                            <option value={'N'}>Inactive</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      style={{ position: 'relative' }}
                      className='pd-btm'
                    >
                      <div className='editCampaignOrderNumberIncorrectDataWrapper'>
                        <div className='editCampaignOrderNumberWrapper'>
                          <CampaignDetailsField
                            label='Order Number'
                            value={campaignData?.order_number}
                            campaignData={campaignData}
                            setCampaignData={setCampaignData}
                            onChangeCallback={(val) => {
                              checkValid(val, 'order');
                              checkChange(val, 'order');
                            }}
                            field='order_number'
                            uniqueIdentifier='editCampaignOrderNumber'
                            placeholder='Enter Order Number'
                            className={
                              validOrder.valid
                                ? 'editCampaignInput'
                                : 'editCampaignInput inputError'
                            }
                          />
                          {!validOrder.valid && (
                            <span
                              className='error'
                              style={{ bottom: '2px', left: '10px' }}
                            >
                              {validOrder.errorMsg}
                            </span>
                          )}
                        </div>
                        {isSuperAdmin && (
                          <div>
                            <input
                              type='checkbox'
                              checked={
                                campaignData?.incorrect_data_warning === 1
                              }
                              id='editCampaignIncorrectData'
                              name='editCampaignIncorrectData'
                              disabled={!isSuperAdmin}
                              onChange={(e) => {
                                const value = e.target.checked ? 1 : 0;
                                setCampaignData({
                                  ...campaignData,
                                  incorrect_data_warning: value,
                                });
                                checkChange(value, 'incorrectDataWarning');
                              }}
                              className={'editCampaignIncorrectData'}
                            />
                            <label
                              htmlFor='editCampaignIncorrectData'
                              className='editCampaignIncorrectDataLabel'
                            >
                              Incorrect Data Warning
                            </label>
                          </div>
                        )}
                      </div>
                    </Col>
                    {campaignData?.incorrect_data_warning === 1 &&
                      isSuperAdmin && (
                        <Col lg={12} md={12} sm={12}>
                          <div className='campaignDetailsNotesWrapper'>
                            <div className='campaignDetailsNotesInnerWrapper field'>
                              <div className='field_label'>Campaign Notes</div>
                              <textarea
                                id='editCampaignNotes'
                                disabled={!isSuperAdmin}
                                name='editCampaignNotes'
                                maxLength={100}
                                value={campaignData?.notes}
                                onChange={(e) => {
                                  const value =
                                    e.target.value.length > 0
                                      ? e.target.value
                                      : null;
                                  setCampaignData({
                                    ...campaignData,
                                    notes: value,
                                  });
                                  checkChange(value, 'notes');
                                  checkValid(value, 'notes');
                                }}
                                placeholder='Enter Campaign Data Warning Notes'
                              />
                            </div>
                            {!validNotes.valid && (
                              <span className='error campaignDetailsNotesError'>
                                {validNotes.errorMsg}
                              </span>
                            )}
                          </div>
                        </Col>
                      )}
                    <Col lg={12} md={12} sm={12}>
                      <div className='field'>
                        <div className='field_label'>Campaign Description</div>
                        <textarea
                          id='editCampaignDescription'
                          name='editCampaignDescription'
                          value={campaignData?.description}
                          onChange={(e) => {
                            setCampaignData({
                              ...campaignData,
                              description: e.target.value,
                            });
                            checkChange(e.target.value, 'description');
                          }}
                          placeholder='Enter Campaign Description'
                        />
                      </div>
                    </Col>
                  </Col>
                  <Col xl={6} md={12} sm={12}>
                    <Row>
                      <Col lg={12} md={12} sm={12}>
                        <Panel
                          label='Attached Properties'
                          setTabSelected={setTabSelected}
                          tabSelected={tabSelected}
                          selectedData={selectedProperties}
                          setSelectedData={setSelectedProperties}
                          availableData={availableProperties}
                          setAvailableData={setAvailableProperties}
                          filter={filter}
                          setFilter={setFilter}
                          filterText={filterText}
                          setFilterText={setFilterText}
                          uniqueIdentifier='editCampaign'
                          onUpdateCallback={(val) => {
                            checkChange(val, 'properties');
                          }}
                          panelInnerWrapperStyling={
                            campaignData?.incorrect_data_warning === 1 && isSuperAdmin
                              ? { height: '538px' }
                              : {}
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {adminDetails(campaignData)}
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    className='campaignListButtonWrapper'
                  >
                    <CommonButtonComponent
                      label={checkCondition(
                        !isUpdating,
                        'Save',
                        <LoaderComponent importedClassNames='buttonLoader' />
                      )}
                      appendWrapperClassName='btnPrimaryWrapper'
                      appendButtonClassName='btnPrimary'
                      onClickCTA={() => {
                        const controller = new AbortController();
                        updateCampaign(controller);
                        return () => {
                          controller.abort();
                        };
                      }}
                      isDisabled={isUpdating || disableButton()}
                      buttonUI='editCampaignSave'
                    />
                    <CommonButtonComponent
                      label='Clone'
                      appendWrapperClassName='btnPrimaryWrapper'
                      appendButtonClassName='btnPrimary hoverEffect'
                      buttonUI='editCampaignClone'
                      onClickCTA={() => {
                        navigate(`/campaign/new`, {
                          state: { campaignData, selectedProperties },
                        });
                      }}
                    />
                    <CommonButtonComponent
                      label='Reset'
                      appendWrapperClassName='btnPrimaryWrapper'
                      appendButtonClassName='btnSecondary'
                      onClickCTA={resetAll}
                      buttonUI='editCampaignReset'
                      isDisabled={
                        isUpdating || !Object.values(isChanged).includes(true)
                      }
                    />
                    {user.role === 'super admin' ? (
                      <CommonButtonComponent
                        label='Delete Tag Data'
                        appendWrapperClassName='btnPrimaryWrapper deleteTagDataButton'
                        appendButtonClassName='btnSecondary deleteTagDataButton'
                        onClickCTA={() => setShowTagDeleteModal(true)}
                        buttonUI='editCampaignDeleteTagData'
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Container>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <div
        className={toggleClasses(
          expandOrCollapse,
          'editCampaignListWrapper container-fluid',
          'editCampaignListWrapper expand container-fluid'
        )}
      >
        <CommonHeadingComponent
          headingLabel='Campaign Details'
          headingCTALabel={!expandOrCollapse ? 'COLLAPSE' : 'EXPAND'}
          isDisabled={isError}
          onClickCTA={() => setExpandOrCollapse(!expandOrCollapse)}
          uniqueIdentifier='campaignDetailsEditCampaignCollapsable'
          rightSideContent={
            <div className='editCampaignHeaderExportFilesWrapper'>
              <ExportFilesComponent
                baseEndpoint='campaigns'
                urlExtension={`${id}/tags`}
                fileType='csv'
                fileName={`${campaignName}_tags`}
                ctaLabel='Download All Tags (.xlsx)'
                messageLabel='All tags'
                parentClass='campaignNameWrapper'
                buttonUI='downloadAllTags'
              />
            </div>
          }
        />
        {isLoading ? <LoaderComponent /> : renderErrorOrDetailsComponent()}
        <CommonModal
          show={showTagDeleteModal}
          header=''
          body={
            <div>
              <br />
              Are you sure you want to DELETE previous data collected for this
              Campaign?
              <br />
              <br />
            </div>
          }
          close={cancelDeleteTagDataDialog}
          reject={cancelDeleteTagDataDialog}
          rejectBtnText='Cancel'
          acceptBtnText='Delete'
          accept={acceptDeleteTagData}
          size='md2'
        />
        <CommonModal
          show={showTagDeleteConfirmationModal}
          header=''
          body={
            <div>
              <br />
              This information will be DELETED and you will NOT have access to
              this data. Are you sure you want to continue?
              <br />
              <br />
            </div>
          }
          close={cancelDeleteTagDataDialog}
          reject={cancelDeleteTagDataDialog}
          rejectBtnText='Cancel'
          acceptBtnText='Delete'
          accept={acceptDeleteTagDataConfirmation}
          size='md2'
          disableAcceptBtn={disableAcceptBtnAtLast}
        />
      </div>
    </div>
  );
};

EditCampaign.propTypes = {
  id: PropTypes.any.isRequired,
  updateBreadCrumb: PropTypes.any.isRequired,
};

const CampaignDetailsField = ({
  type,
  label,
  value,
  disable = false,
  campaignData,
  setCampaignData,
  field,
  uniqueIdentifier,
  onChangeCallback = () => {},
  placeholder = '',
  className = '',
}) => {
  return (
    <div className='field'>
      <div className='field_label'>{label}</div>
      <input
        id={uniqueIdentifier}
        name={uniqueIdentifier}
        value={value ?? ''}
        disabled={disable}
        onChange={(e) => {
          setCampaignData({ ...campaignData, [field]: e.target.value });
          onChangeCallback(e.target.value);
        }}
        placeholder={placeholder}
        className={className}
      />
    </div>
  );
};

const Panel = ({
  label,
  setTabSelected,
  tabSelected,
  selectedData,
  setSelectedData,
  availableData,
  setAvailableData,
  filter,
  setFilter,
  filterText,
  setFilterText,
  uniqueIdentifier,
  incorrectDataWarning,
  panelInnerWrapperStyling = {},
  onUpdateCallback = () => {},
}) => {
  const filterSearch = (tabSelected, text) => {
    if (tabSelected) {
      setFilter(
        selectedData.filter((val) =>
          val?.name.toLowerCase().includes(text.toLowerCase())
        )
      );
    } else {
      setFilter(
        availableData.filter((val) =>
          val?.name.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    filterSearch(tabSelected, filterText);
  }, [availableData]);

  return (
    <div className='panelWrapper'>
      <div className='panelHeader'>{label}</div>
      <div className='panelTabWrapper'>
        <div
          id={getUniqueIdentifier(uniqueIdentifier, 'PanelViewSelected')}
          name={getUniqueIdentifier(uniqueIdentifier, 'PanelViewSelected')}
          onClick={() => {
            setTabSelected(true);
            filterSearch(true, filterText);
          }}
          className={toggleClasses(
            tabSelected,
            'panelTabSelected',
            'panelTabNotSelected'
          )}
        >
          Selected
        </div>
        <div
          id={getUniqueIdentifier(uniqueIdentifier, 'PanelViewAll')}
          name={getUniqueIdentifier(uniqueIdentifier, 'PanelViewAll')}
          onClick={() => {
            setTabSelected(false);
            filterSearch(false, filterText);
          }}
          className={toggleClasses(
            !tabSelected,
            'panelTabSelected',
            'panelTabNotSelected'
          )}
        >
          View All
        </div>
      </div>
      <div className='panelSearch'>
        <input
          type='search'
          id={
            uniqueIdentifier ? uniqueIdentifier + 'PanelSearchField' : undefined
          }
          name={
            uniqueIdentifier ? uniqueIdentifier + 'PanelSearchField' : undefined
          }
          placeholder='Search'
          onChange={(e) => {
            setFilterText(e.target.value);
            filterSearch(tabSelected, e.target.value);
          }}
        />
      </div>
      <div style={panelInnerWrapperStyling} className='panelInnerWrapper'>
        {filter.map((item, index) => {
          return !tabSelected ? (
            <div
              className='panelList onHover'
              key={`${item?.name}${item?.property_id}`}
            >
              <input
                type='checkbox'
                id={item?.property_id}
                name={item?.name}
                value={item?.property_id}
                checked={item?.selected}
                onChange={(e) => {
                  let temp = availableData.map((obj, index) => {
                    return obj?.property_id == e.target.value
                      ? {
                          ...obj,
                          selected: !obj.selected,
                        }
                      : obj;
                  });
                  setSelectedData(temp.filter((val) => val.selected));
                  setAvailableData(temp);
                  onUpdateCallback(temp.filter((val) => val.selected));
                }}
              />
              <label
                htmlFor={item?.property_id}
              >{`${item?.name} (#${item?.property_id})`}</label>
            </div>
          ) : (
            <div
              className='panelList onHover'
              key={`${item?.name}${item?.property_id}`}
            >
              <input
                type='checkbox'
                id={item?.property_id}
                name={item?.name}
                value={item?.property_id}
                checked={item?.selected}
                onChange={(e) => {
                  let temp = availableData.map((obj, index) => {
                    return obj?.property_id == e.target.value
                      ? {
                          ...obj,
                          selected: !obj.selected,
                        }
                      : obj;
                  });
                  setAvailableData(temp);
                  setFilter(temp.filter((val) => val.selected));
                  setSelectedData(temp.filter((val) => val.selected));
                  onUpdateCallback(temp.filter((val) => val.selected));
                }}
              />
              <label
                htmlFor={item?.property_id}
              >{`${item?.name} (#${item?.property_id})`}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const GenericItemsList = (props) => {
  const { label, nameField, values } = props;
  return (
    <div className='field'>
      <div className='field_label'>{label}</div>
      <div className='field_value generic_list'>
        {values?.map(function (item, i) {
          return (
            <OverlayTrigger
              key={`${label}_${item[nameField]}`}
              overlay={
                <Tooltip id='tooltip-disabled'>{item[nameField]}</Tooltip>
              }
            >
              <div className='generic_list-item' key={'itm' + item[nameField]}>
                {item[nameField]}
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
};

const returnDate = (date) => {
  return date ? moment.utc(date).format('MM/DD/YYYY') : '';
};

const getUniqueIdentifier = (ui, prefix) => {
  return ui ? ui + prefix : undefined;
};

const GetCreatedOrModifiedInfoHtml = ({ data }) => {
  if (data) {
    return <div className='notEmptyCreatedOrModifiedField'>{data}</div>;
  }

  return <div className='emptyCreatedOrModifiedField'>-</div>;
};

GetCreatedOrModifiedInfoHtml.propTypes = {
  data: PropTypes.string.isRequired,
};

const validateData = (oldData, newData, oldProp, newProp) => {
  return (
    oldData?.name === newData?.name &&
    oldData?.enabled === newData?.enabled &&
    oldData?.order_number === newData?.order_number &&
    oldData?.description === newData?.description &&
    oldProp?.length === newProp?.length &&
    JSON.stringify(oldProp?.map((item) => item.id)) ===
      JSON.stringify(newProp?.map((item) => item.id))
  );
};
