import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  CommonHeadingComponent,
  CommonTablesComponent,
  ErrorComponent,
  CommonSearch,
  CommonButtonComponent,
} from '../../../../components';
import columns from './advertisersColumns';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import { baseEndpoints } from '../../../../constants';
import '../../advertisers.css';

const baseInputParams = {
    sortField: 'id',
    order: 'desc',
    pageNumber: 1,
    pageSize: 10,
    searchParam: '',
  },
  sortColumns = ['id', 'name', 'createdBy', 'createdOn', 'status'];

// Advertiser Listing Component
const AdvertisersList = () => {
  const client = useSelector((state) => state.client); // listener to client change in global header

  const navigate = useNavigate();

  const [isMounted, setIsMounted] = useState(false),
    [params, setParams] = useState(null),
    [searchInput, setSearchInput] = useState(''),
    [inputParams, setInputParams] = useState(null),
    [isClientUpdated, setIsClientUpdated] = useState(true),
    [isCountAtClientLevel, setIsCountAtClientLevel] = useState(false),
    [rowCountAtClientLevel, setRowCountAtClientLevel] = useState(0),
    [selectedTableSize, setSelectedTableSize] = useState(Number(localStorage.getItem('advertisersPageSize')) || 10);

  // States for fetching Advertisers List
  const { data, isLoading, isError, status } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.advertisers
  );
  /**
   * First time to initialize api calls and set up data
   * @dependencies : client, page-number, sort-data, per-page-data
   */
  useEffect(() => {
    if (inputParams) {
      setParams({ userId: client.selectedClient, ...inputParams });
      setIsMounted(true);
    }
  }, [inputParams]);

  useEffect(() => {
    if (isClientUpdated) {
      return setIsClientUpdated(false);
    }

    const timer = setTimeout(() => {
      setInputParams({
        ...inputParams,
        pageNumber: baseInputParams.pageNumber,
        searchParam: searchInput,
        pageSize: selectedTableSize,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  useEffect(() => {
    setIsClientUpdated(true);
    setIsCountAtClientLevel(true);
    setSearchInput('');
    setInputParams(
      !inputParams
        ? { ...baseInputParams, pageSize: selectedTableSize}
        : {
            ...baseInputParams,
            sortField: inputParams.sortField,
            order: inputParams.order,
            pageSize: selectedTableSize,
          }
    );
  }, [client]);

  useEffect(() => {
    if (isCountAtClientLevel) {
      setRowCountAtClientLevel(data?.data_count ?? 0);
      setIsCountAtClientLevel(false);
    }
  }, [data]);

const handlePageSizeChange = (pageSize) => {
    localStorage.setItem('advertisersPageSize', pageSize.toString());
    setSelectedTableSize(pageSize);
  };

  useEffect(() => {
    const previousSelection = Number(localStorage.getItem('advertisersPageSize') ?? 10);
    setSelectedTableSize(previousSelection);
  }, []);

  return (
    <div className='campaignListWrapper propertyListWrapper activeCampaignsOuterWrapper'>
      <CommonHeadingComponent
        headingLabel='Advertisers'
        url={null}
        isDisabled={true}
      />
      <div className='campaignListSubheader justify-content-between'>
        <div className='d-flex'>
          <CommonSearch
            placeholder='Search Advertisers'
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            disabled={!rowCountAtClientLevel || isLoading || isError}
            uniqueIdentifier='allAdvertisers'
            autofocus={true}
          />
        </div>
        <CommonButtonComponent
          label='Create Advertiser'
          appendWrapperClassName='newPropertyBtnWrapper'
          appendButtonClassName='newPropertyBtnBody'
          onClickCTA={() => navigate('/advertisers/new')}
          buttonUI='allAdvertisersCreateAdvertiser'
        />
      </div>
      <React.Fragment>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div>
                {isError ? (
                  <ErrorComponent
                    refreshAction={() => setParams({ ...params })}
                    status={status}
                  />
                ) : (
                  <CommonTablesComponent
                    uniqueId='advertiserList'
                    data={data['table_data'] ?? []}
                    columns={columns}
                    isLoading={isLoading}
                    customTableHeader='Advertisers'
                    setSortData={(sortData) =>
                      setInputParams({ ...inputParams, ...sortData })
                    }
                    sortColumnIds={sortColumns} // sorting
                    defaultSortingApplied={{
                      column: baseInputParams.sortField,
                      order: baseInputParams.order,
                    }} // sorting
                    isPaginationApplied={true}
                    totalDataCount={data['data_count']}
                    pageIndex={inputParams?.pageNumber}
                    setPageIndex={(pageNumber) =>
                      setInputParams({ ...inputParams, pageNumber })
                    }
                    dataPerPage={inputParams?.pageSize}
                    paginationOptions={{
                      noRowsPerPage: false,
                    }}
                    setDataPerPage={(pageSize) => {
                      handlePageSizeChange(pageSize)
                      setInputParams({
                        ...inputParams,
                        pageNumber: baseInputParams.pageNumber,
                        pageSize,
                      })
                    }}
                    noDataMessage='No Advertisers Available'
                    noDataMessageFontSize={15}
                    noDataCompWidth='180'
                    noDataCompHeight='180'
                    foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
};
export default AdvertisersList;
