import React, { useEffect, useState } from 'react';
import {
  CommonHeadingComponent,
  CommonSearch,
  CommonTablesComponent,
  ErrorComponent,
  CommonButtonComponent,
} from '../../../../components';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { baseEndpoints } from '../../../../constants';
import accountsTableColumn from './accountList.util';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientList, updateSelectedClient } from '../../../../redux/clientSlice';
import { getSortedArrayOfObj } from '../../../../utils/functions';
import { setBlockingLoaderState } from '../../../../redux/blockingLoader';
import { updateAdvertiserList } from '../../../../redux/advertiserSlice';

import './accountList.css';

const baseInputParams = {
    sort_column: 'organization',
    sort_order: 'asc',
    page_number: 1,
    page_size: 10,
    search_param: '',
  },
  sortColumns = [
    'organization',
    'email',
    'created',
    'last_connect',
    'status',
    'modified',
  ];

const AccountList = ({ role }) => {
  const navigate = useNavigate();
  document.title = 'Admin | Accounts';

  const dispatch = useDispatch()
  const client = useSelector((state) => state.client);

  const username = localStorage.getItem("username")

  const [searchParams] = useSearchParams(),
    updatedDefaultSorting = searchParams.get('param');

  const [isMounted, setIsMounted] = useState(false),
    [params, setParams] = useState(null),
    [searchInput, setSearchInput] = useState(''),
    [inputParams, setInputParams] = useState(null),
    [isClientUpdated, setIsClientUpdated] = useState(true),
    [newDefaultSorting, setNewDefaultSorting] = useState(null),
    [showTable, setShowTable] = useState(false),
    [userInfoMounted, setUserInfoMounted] = useState(false),
    [userInfoParams, setUserInfoParams] = useState(null),
    [selectedTableSize, setSelectedTableSize] = useState(Number(localStorage.getItem('accountsPageSize')) || 10);

  // States for fetching Account List
  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.admin + '/accounts'
  );

  // States for updating Client List and Selected Client
  const {
    data: userInfo,
    isLoading: userInfoLoading,
    isError: userInfoError,
  } = useGetApiInterceptor(
    userInfoMounted,
    userInfoParams,
    `${baseEndpoints.users}/info`
  );

  /**
   * First time to initialize api calls and set up data
   * @dependencies : page-number, sort-data, per-page-data
   */

  useEffect(() => {
    setUserInfoParams({ email: username });
    setUserInfoMounted(true);
  }, []);

  useEffect(() => {
    if (inputParams) {
      setParams({ role, ...inputParams });
      setIsMounted(true);
    }
  }, [inputParams]);

  // for search filter
  useEffect(() => {
    if (isClientUpdated) {
      return setIsClientUpdated(false);
    }

    const timer = setTimeout(() => {
      setInputParams({
        ...inputParams,
        page_number: baseInputParams.page_number,
        search_param: searchInput,
        page_size: selectedTableSize,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  useEffect(() => {
    if (updatedDefaultSorting == 1) {
      setNewDefaultSorting({
        sortColumn: 'modified',
        sortOrder: 'desc',
      });
      setInputParams({
        ...baseInputParams,
        sort_column: 'modified',
        sort_order: 'desc',
        page_size: selectedTableSize,
      });
    } else {
      setNewDefaultSorting({
        sortColumn: baseInputParams.sort_column,
        sortOrder: baseInputParams.sort_order,
      });
      setInputParams({ ...baseInputParams, page_size: selectedTableSize });
    }
    setShowTable(true);
  }, [updatedDefaultSorting]);

  useEffect(() => {
    if (userInfo?.client_list?.length && !userInfoError) {
      dispatch(
        updateClientList(
          getSortedArrayOfObj(userInfo?.client_list, 'organization')
        )
      );
      dispatch(updateAdvertiserList(userInfo?.advertiser_list));
      const found = userInfo?.client_list?.find(
        (item) => item?.user_id == client?.selectedClient
      );
      if (found === undefined) {
        dispatch(updateSelectedClient(userInfo?.client_list[0].user_id));
      }
    }
  }, [userInfo]);


  useEffect(() => {
    if (userInfoLoading) {
      dispatch(setBlockingLoaderState({ active: true }));
    } else {
      dispatch(setBlockingLoaderState({ active: false }));
    }
    return () => {
      // cleanup mechanism runs before next render, prevents infinite loading bug
      dispatch(setBlockingLoaderState({ active: false }));
    }
  }, [userInfoLoading]);

const handlePageSizeChange = (pageSize) => {
    localStorage.setItem('accountsPageSize', pageSize.toString());
    setSelectedTableSize(pageSize);
  };

  useEffect(() => {
    const previousSelection = Number(localStorage.getItem('accountsPageSize') ?? 10);
    setSelectedTableSize(previousSelection);
  }, []);

  /** render component on the basis of the API request, if error then error component else table   */
  const renderComponent = () => {
    return isError ? (
      <ErrorComponent refreshAction={() => setParams({ ...params })} />
    ) : (
      <>
        <div className=' accountListSubheaderWrapper'>
          <div className='d-flex'>
            <CommonSearch
              placeholder={'Search Account'}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              customStyle={
                searchInput.length > 0 ? { fontStyle: 'normal' } : {}
              }
              disabled={isLoading || isError}
              uniqueIdentifier='allAccounts'
              autofocus={true}
            />
          </div>
          <CommonButtonComponent
            label='Create Account'
            appendWrapperClassName='newAccountPropertyBtnWrapper'
            appendButtonClassName='newAccountBtnBody'
            onClickCTA={() => navigate('/admin/accounts/new')}
            buttonUI='allUsersCreateAccount'
          />
        </div>
        {showTable&&!userInfoLoading && (
          <CommonTablesComponent
            uniqueId='accountList'
            data={data?.table_data ?? []}
            columns={accountsTableColumn}
            isLoading={isLoading}
            customTableHeader='Accounts'
            setSortData={(sortData) =>
              setInputParams({
                ...inputParams,
                sort_column: sortData?.sortField,
                sort_order: sortData?.order,
              })
            } // sorting
            sortColumnIds={sortColumns} // sorting
            defaultSortingApplied={{
              column: newDefaultSorting?.sortColumn,
              order: newDefaultSorting?.sortOrder,
            }} // sorting
            isPaginationApplied={true}
            totalDataCount={data?.total_data ?? 0}
            pageIndex={inputParams?.page_number}
            setPageIndex={(pageNumber) =>
              setInputParams({ ...inputParams, page_number: pageNumber })
            }
            dataPerPage={inputParams?.page_size}
            paginationOptions={{
              noRowsPerPage: false,
            }}
            setDataPerPage={(pageSize) => {
              handlePageSizeChange(pageSize);
              setInputParams({
                ...inputParams,
                page_number: baseInputParams.page_number,
                page_size: pageSize,
              })
            }}
            noDataMessage='No Accounts Available'
            noDataMessageFontSize={15}
            noDataCompWidth='180'
            noDataCompHeight='180'
            foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
          />
        )}
      </>
    );
  };

  return (
    <div className='accountListWrapper'>
      <div className='accountListHeaderWrapper'>
        <CommonHeadingComponent
          headingLabel='Client Accounts'
          url={null}
          isDisabled={true}
        />
      </div>
      {renderComponent()}
    </div>
  );
};

export default AccountList;
