import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  ErrorComponent,
  MultiselectDropdownComponent,
  SearchableDropdownComponent,
} from '../../../../../../components';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import {
  baseEndpoints,
  timeFrameDropdown,
  dataTypeDropdown,
} from '../../../../../../constants';
import {
  checkSelectAll,
  getArrayOfData,
  setPlaceHolderLabel,
} from '../../../../../../utils/functions';
import { BarChartsForGoalsComponent } from './barchartForGoals';
import PartnersEngagementsConversionsByGoal from '../analysisSection/PartnersEngagementsConversionsByGoal.component';
import { Col, Container, Row } from 'react-bootstrap';
import EngagementsConversionsTop5GoalsDevices from '../analysisSection/EngagementsConversionsTop5GoalsDevices.component';
import './analysis.css';

const AnalysisComponent = ({
  campaign,
  mediaPartner,
  mediaPartnerList,
  creativeType,
  creativeTypeList,
  placement,
  placementList,
  ifSelectedAll,
  startDate,
  endDate,
  noData = false
}) => {
  const [usableSelectedPlacement, setUsableSelectedPlacement] = useState(null),
    [isLoadingUsable, setIsLoadingUsable] = useState(true);

  const [newPlacementList, setNewPlacementList] = useState(null),
    [selectedPlacement, setSelectedPlacement] = useState(null),
    [defaultSelectedPlacement, setDefaultSelectedPlacement] = useState(null),
    [isLoadingPlacement, setIsLoadingPlacement] = useState(true);

  const [newMediaPartnerList, setNewMediaPartnerList] = useState(null),
    [selectedMediaPartner, setSelectedMediaPartner] = useState(null),
    [defaultSelectedMediaPartner, setDefaultSelectedMediaPartner] =
      useState(null),
    [isLoadingMediaPartner, setIsLoadingMediaPartner] = useState(true);

  const [newCreativeTypeList, setNewCreativeTypeList] = useState(null),
    [selectedCreativeType, setSelectedCreativeType] = useState(null),
    [defaultSelectedCreativeType, setDefaultSelectedCreativeType] =
      useState(null),
    [isLoadingCreativeType, setIsLoadingCreativeType] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const [typeOfData, setTypeOfData] = useState({
      id: 2,
      name: 'Partner',
    }),
    [defaultTypeOfData, setDefaultTypeOfData] = useState({
      id: 2,
      name: 'Partner',
    }),
    [usableTypeOfData, setUsableTypeOfData] = useState({
      id: 2,
      name: 'Partner',
    }),
    [numberOfDays, setNumberOfDays] = useState({
      id: 9,
      name: 'Campaign Dates + Lookback Period',
    }),
    [defaultNumberOfDays, setDefaultNumberOfDays] = useState({
      id: 9,
      name: 'Campaign Dates + Lookback Period',
    });

  const [isSelectorsChanged, setSelectorsChanged] = useState({
    numberOfDays: false,
    typeOfData: false,
    selectedPlacement: false,
    selectedMediaPartner: false,
    selectedCreativeType: false,
  });

  const getAllPlacementsAsPerData = async (
    controller,
    partners,
    creativeTypes
  ) => {
    try {
      if (partners && creativeType) {
        setIsLoadingUsable(true);
        const placementListResponse = await axiosSendRequest(
          `${baseEndpoints.reports}/placement-list`,
          controller,
          {
            campaign_id: campaign?.campaign_id,
            creative_type: getArrayOfData(creativeTypes, 'name'),
            partner_id: getArrayOfData(partners, 'partner_id'),
          }
        );

        if (
          placementListResponse.status === 200 &&
          !checkDisable(placementListResponse.data, usableSelectedPlacement)
        ) {
          const data =
            placementListResponse.data.length > 0
              ? placementListResponse.data
              : null;

          setUsableSelectedPlacement(data);
      }
      } else {
        setUsableSelectedPlacement(null);
      }
      
      isSelectorsChanged.numberOfDays && setDefaultNumberOfDays(numberOfDays);
      isSelectorsChanged.selectedMediaPartner &&
        setDefaultSelectedMediaPartner(selectedMediaPartner);
      isSelectorsChanged.selectedCreativeType &&
        setDefaultSelectedCreativeType(selectedCreativeType);
      setIsLoadingUsable(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        isSelectorsChanged.numberOfDays && setDefaultNumberOfDays(numberOfDays);
        isSelectorsChanged.selectedMediaPartner &&
          setDefaultSelectedMediaPartner(selectedMediaPartner);
        isSelectorsChanged.selectedCreativeType &&
          setDefaultSelectedCreativeType(selectedCreativeType);
        setUsableSelectedPlacement(null);
        setIsLoadingUsable(false);
      }
    }
  };

  const getSelectedPlacements = () => {
    setSelectedPlacement(
      placement && placement.length > 0 ? [...placement] : null
    );
    setNewPlacementList(
      placement && placement.length > 0 ? [...placement] : null
    );
    setDefaultSelectedPlacement(
      placement && placement.length > 0 ? [...placement] : null
    );
    setIsLoadingPlacement(false);
  };

  const getSelectedMediaPartners = () => {
    setSelectedMediaPartner(
      mediaPartner && mediaPartner.length > 0 ? [...mediaPartner] : null
    );
    setNewMediaPartnerList(
      mediaPartner && mediaPartner.length > 0
        ? [...mediaPartner]
        : null
    );
    setDefaultSelectedMediaPartner(
      mediaPartner && mediaPartner.length > 0 ? [...mediaPartner] : null
    );
    setIsLoadingMediaPartner(false);
  };

  const getSelectedCreativeType = () => {
    setSelectedCreativeType(
      creativeType && creativeType.length > 0 ? [...creativeType] : null
    );
    setNewCreativeTypeList(
      creativeType && creativeType.length > 0
        ? [...creativeType]
        : null
    );
    setDefaultSelectedCreativeType(
      creativeType && creativeType.length > 0 ? [...creativeType] : null
    );
    setIsLoadingCreativeType(false);
  };

  const saveChanges = () => {
    const controller = new AbortController();
    setUsableTypeOfData({...typeOfData});
    if (isSelectorsChanged.typeOfData) {
      if (
        (defaultTypeOfData.id === 1 &&
          !checkDisable(placement, selectedPlacement)) ||
        (defaultTypeOfData.id != 1 &&
          typeOfData.id === 1 &&
          !isSelectorsChanged.selectedPlacement &&
          !checkDisable(defaultSelectedPlacement, usableSelectedPlacement))
      ) {
        getSelectedPlacements();
      }

      isSelectorsChanged.typeOfData && setDefaultTypeOfData(typeOfData);
      defaultTypeOfData.id === 2 && getSelectedMediaPartners();
      defaultTypeOfData.id === 3 && getSelectedCreativeType();
    }

    if ((isSelectorsChanged.selectedCreativeType && typeOfData.id === 3) ||
      (defaultTypeOfData.id != 3 && typeOfData.id === 3 &&
        !checkDisable(defaultSelectedPlacement, usableSelectedPlacement))) {
      getAllPlacementsAsPerData(
        controller,
        mediaPartner,
        selectedCreativeType
      );
      return;
    }
    
    if (
      (isSelectorsChanged.selectedMediaPartner && typeOfData.id === 2) ||
      (defaultTypeOfData.id != 2 && typeOfData.id === 2 &&
        !checkDisable(defaultSelectedPlacement, usableSelectedPlacement))
    ) {
      getAllPlacementsAsPerData(
        controller,
        selectedMediaPartner,
        creativeType
      );
      return;
    }

    isSelectorsChanged.numberOfDays && setDefaultNumberOfDays(numberOfDays);
    isSelectorsChanged.selectedPlacement &&
      setDefaultSelectedPlacement(selectedPlacement);
    isSelectorsChanged.selectedPlacement &&
       (selectedPlacement);
    isSelectorsChanged.selectedMediaPartner &&
      setDefaultSelectedMediaPartner(selectedMediaPartner);
    isSelectorsChanged.selectedCreativeType &&
      setDefaultSelectedCreativeType(selectedCreativeType);
    setSelectorsChanged({
      numberOfDays: false,
      typeOfData: false,
      selectedPlacement: false,
      selectedMediaPartner: false,
      selectedCreativeType: false,
    });
  };

  const resetChanges = () => {
    setNumberOfDays(defaultNumberOfDays);
    setTypeOfData(defaultTypeOfData);
    setSelectedPlacement(defaultSelectedPlacement);
    setSelectedMediaPartner(defaultSelectedMediaPartner);
    setSelectedCreativeType(defaultSelectedCreativeType);
    setSelectorsChanged({
      numberOfDays: false,
      typeOfData: false,
      selectedPlacement: false,
      selectedMediaPartner: false,
      selectedCreativeType: false,
    });
    setNewPlacementList([...newPlacementList]);
    setNewMediaPartnerList([...newMediaPartnerList]);
    setNewCreativeTypeList([...newCreativeTypeList]);
  };

  const checkDisable = (selectedData, defaultData) => {
    return JSON.stringify(selectedData) === JSON.stringify(defaultData);
  };

  const setTypeOfDataFunc = (type) => {
    if (typeOfData.id !== defaultTypeOfData.id) {
      typeOfData.id === 1 &&
        setSelectedPlacement(newPlacementList) &&
        getSelectedPlacements();
      typeOfData.id === 2 &&
        !checkDisable(newMediaPartnerList, selectedMediaPartner) &&
        getSelectedMediaPartners();
      typeOfData.id === 3 &&
        !checkDisable(newCreativeTypeList, selectedCreativeType) &&
        getSelectedCreativeType();
    }
    setTypeOfData(type);
  };

  useEffect(() => {
    setIsLoadingPlacement(true);

    if (placement) {
      getSelectedPlacements();
      return;
    }

    setSelectedPlacement(null);
    setDefaultSelectedPlacement(null);
    setNewPlacementList(null);

    setTimeout(() => {
      setIsLoadingPlacement(false);
      setUsableSelectedPlacement(null);
      setIsLoadingUsable(false);
    }, 50);
  }, [placement]);

  useEffect(() => {
    setIsLoadingMediaPartner(true);

    if (mediaPartner) {
      getSelectedMediaPartners();
      return;
    }

    setSelectedMediaPartner(null);
    setDefaultSelectedMediaPartner(null);
    setNewMediaPartnerList(null);
  }, [mediaPartner]);

  useEffect(() => {
    setIsLoadingCreativeType(true);

    if (creativeType) {
      getSelectedCreativeType();
      return;
    }

    setSelectedCreativeType(null);
    setDefaultSelectedCreativeType(null);
    setNewCreativeTypeList(null);
  }, [creativeType]);

  useEffect(() => {
    if (defaultSelectedPlacement) {
      setUsableSelectedPlacement(defaultSelectedPlacement);
    } else {
      setUsableSelectedPlacement(null);
    }
    setIsLoadingUsable(isLoadingPlacement);
    return;
  }, [defaultSelectedPlacement]);

  useEffect(() => {
    setSelectorsChanged({
      typeOfData: !checkDisable(typeOfData, defaultTypeOfData),
      numberOfDays: !checkDisable(numberOfDays, defaultNumberOfDays),
      selectedPlacement: !checkDisable(
        selectedPlacement,
        defaultSelectedPlacement
      ),
      selectedMediaPartner: !checkDisable(
        selectedMediaPartner,
        defaultSelectedMediaPartner
      ),
      selectedCreativeType: !checkDisable(
        selectedCreativeType,
        defaultSelectedCreativeType
      ),
    });
  }, [
    numberOfDays,
    typeOfData,
    selectedPlacement,
    selectedMediaPartner,
    selectedCreativeType,
  ]);

  return (
    <div className='analysisWrapperAT'>
      <div className='analysisSectionHeaderAT'>
        <CommonHeadingComponent headingLabel={'Analysis Section'} />
      </div>
      <Container fluid className='analysisSectionInnerSubHeaderWrapper'>
        <Row>
          <Col xl={10} lg={12} md={12} sm={12}>
            <div className='analysisSectionInnerSubHeaderWrapper'>
              {/* <SearchableDropdownComponent
                options={timeFrameDropdown}
                label='name'
                id='id'
                dropdownId='viewNumberOfDaysAT'
                dropdownLabel='View'
                labelWidth={{ width: '40px' }}
                dropdownWidth={{
                  width: '155px',
                }}
                placeholderLabel={'Number of Days'}
                selectedValue={numberOfDays}
                handleChange={setNumberOfDays}
                isSearchable={false}
                isDisabled={!campaign}
                uniqueIdentifier='reportDetailsAttributionAnalysisDateRange'
                dropdownLabelClass='dropdownMarginRight'
              /> */}
              <SearchableDropdownComponent
                options={dataTypeDropdown}
                label='name'
                id='id'
                dropdownId='viewTypeOfDataAT'
                dropdownLabel='View Engagements & Conversions by'
                labelWidth={{ width: '295px', marginRight: '5px' }}
                dropdownWidth={{
                  width: '150px',
                }}
                placeholderLabel={'Type of Data'}
                selectedValue={typeOfData}
                handleChange={setTypeOfDataFunc}
                isSearchable={false}
                isDisabled={!campaign || noData}
                uniqueIdentifier='reportDetailsAttributionAnalysisTypesOfData'
              />
              <div className='multiDropdownWrapperAT'>
                {typeOfData.id === 1 && (
                  <SetCommonDropdown
                    dropdownId='placementOfCampOfAnalysisAT'
                    dropdownDataList={newPlacementList}
                    dataId='placement_id'
                    dataName='placement_name'
                    dataType='type'
                    dropdownData={selectedPlacement}
                    setDropdownData={setSelectedPlacement}
                    placeholderLabel='Placement'
                    isAllCheckedInitially={checkSelectAll(
                      newPlacementList,
                      selectedPlacement,
                      'placement_id'
                    )}
                    isDisabled={!(newPlacementList || !campaign)||noData}
                    isOpen={setIsOpen}
                    ifIdVisibleOnLabel={true}
                  />
                )}
                {typeOfData.id === 2 && (
                  <SetCommonDropdown
                    dropdownId='mediaPartnerOfCampOfAnalysisAT'
                    dropdownDataList={newMediaPartnerList}
                    dataId='partner_id'
                    dataName='partner_name'
                    dropdownData={selectedMediaPartner}
                    setDropdownData={setSelectedMediaPartner}
                    placeholderLabel='Partner'
                    isAllCheckedInitially={checkSelectAll(
                      newMediaPartnerList,
                      selectedMediaPartner,
                      'placement_id'
                    )}
                    isDisabled={!(newMediaPartnerList || !campaign)||noData}
                    isOpen={setIsOpen}
                    ifIdVisibleOnLabel={false}
                  />
                )}
                {typeOfData.id === 3 && (
                  <SetCommonDropdown
                    dropdownId='creativeTypeOfCampOfAnalysisAT'
                    dropdownDataList={newCreativeTypeList}
                    dataId='value'
                    dataName='name'
                    dropdownData={selectedCreativeType}
                    setDropdownData={setSelectedCreativeType}
                    placeholderLabel='Type'
                    isAllCheckedInitially={checkSelectAll(
                      newCreativeTypeList,
                      selectedCreativeType,
                      'value'
                    )}
                    isDisabled={!(newCreativeTypeList || !campaign)|| noData}
                    isOpen={setIsOpen}
                    ifIdVisibleOnLabel={false}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col xl={2} lg={12} md={12} sm={12}>
            <div className='analysisSectionInnerSubHeaderWrapper'>
              <CommonButtonComponent
                label='Apply'
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnPrimary hoverEffect'
                onClickCTA={saveChanges}
                isDisabled={
                  !Object.values(isSelectorsChanged).includes(true) || isOpen || noData
                }
                buttonUI='editCampaignSave'
              />
              <CommonButtonComponent
                label='Reset'
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnPrimary hoverEffect'
                onClickCTA={resetChanges}
                isDisabled={
                  !Object.values(isSelectorsChanged).includes(true) || isOpen || noData
                }
                buttonUI='editCampaignSave'
              />
            </div>
          </Col>
        </Row>
      </Container>
      <BarChartsForGoalsComponent
        campaign={campaign}
        mediaPartnerList={mediaPartnerList}
        selectedMediaPartner={selectedMediaPartner}
        placementList={placementList}
        selectedPlacement={usableSelectedPlacement}
        creativeTypeList={creativeTypeList}
        selectedCreativeType={selectedCreativeType}
        typeOfData={usableTypeOfData?.id}
        numberOfDays={defaultNumberOfDays}
        isLoadingBase={isLoadingUsable}
        ifSelectedAll={ifSelectedAll}
        startDate={startDate}
        endDate={endDate}
      />
      <Container fluid>
        <Row>
          <Col lg={3} md={6} sm={6} xs={12} style={{ padding: 0 }}>
            <EngagementsConversionsTop5GoalsDevices
              campaign={campaign}
              mediaPartnerList={mediaPartnerList}
              selectedMediaPartner={selectedMediaPartner}
              placementList={placementList}
              selectedPlacement={usableSelectedPlacement}
              creativeTypeList={creativeTypeList}
              selectedCreativeType={selectedCreativeType}
              typeOfData={usableTypeOfData?.id}
              isLoadingBase={isLoadingUsable}
              ifSelectedAll={ifSelectedAll}
              numberOfDays={defaultNumberOfDays}
              chartType='goals'
              goalType='engagement'
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
          <Col lg={3} md={6} sm={6} xs={12} style={{ padding: 0 }}>
            <EngagementsConversionsTop5GoalsDevices
              campaign={campaign}
              mediaPartnerList={mediaPartnerList}
              selectedMediaPartner={selectedMediaPartner}
              placementList={placementList}
              selectedPlacement={usableSelectedPlacement}
              creativeTypeList={creativeTypeList}
              selectedCreativeType={selectedCreativeType}
              typeOfData={usableTypeOfData?.id}
              isLoadingBase={isLoadingUsable}
              ifSelectedAll={ifSelectedAll}
              numberOfDays={defaultNumberOfDays}
              chartType='devices'
              goalType='engagement'
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
          <Col lg={3} md={6} sm={6} xs={12} style={{ padding: 0 }}>
            <EngagementsConversionsTop5GoalsDevices
              campaign={campaign}
              mediaPartnerList={mediaPartnerList}
              selectedMediaPartner={selectedMediaPartner}
              placementList={placementList}
              selectedPlacement={usableSelectedPlacement}
              creativeTypeList={creativeTypeList}
              selectedCreativeType={selectedCreativeType}
              typeOfData={usableTypeOfData?.id}
              isLoadingBase={isLoadingUsable}
              ifSelectedAll={ifSelectedAll}
              numberOfDays={defaultNumberOfDays}
              chartType='goals'
              goalType='conversion'
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
          <Col lg={3} md={6} sm={6} xs={12} style={{ padding: 0 }}>
            <EngagementsConversionsTop5GoalsDevices
              campaign={campaign}
              mediaPartnerList={mediaPartnerList}
              selectedMediaPartner={selectedMediaPartner}
              placementList={placementList}
              selectedPlacement={usableSelectedPlacement}
              creativeTypeList={creativeTypeList}
              selectedCreativeType={selectedCreativeType}
              typeOfData={usableTypeOfData?.id}
              isLoadingBase={isLoadingUsable}
              ifSelectedAll={ifSelectedAll}
              numberOfDays={defaultNumberOfDays}
              chartType='devices'
              goalType='conversion'
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} style={{ padding: 0 }}>
            <PartnersEngagementsConversionsByGoal
              campaign={campaign}
              type='engagement'
              label='Engagements'
              uniqueIdentifier='reportDetailsAttributionAnalysis'
              mediaPartnerList={mediaPartnerList}
              selectedMediaPartner={selectedMediaPartner}
              placementList={placementList}
              selectedPlacement={usableSelectedPlacement}
              creativeTypeList={creativeTypeList}
              selectedCreativeType={selectedCreativeType}
              typeOfData={usableTypeOfData?.id}
              startDate={startDate}
              endDate={endDate}
              numberOfDays={defaultNumberOfDays}
              goalType='engagement'
              isLoadingBase={isLoadingUsable}
              ifSelectedAll={ifSelectedAll}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <PartnersEngagementsConversionsByGoal
              campaign={campaign}
              type='conversion'
              label='Conversions'
              uniqueIdentifier='reportDetailsAttributionAnalysis'
              mediaPartnerList={mediaPartnerList}
              selectedMediaPartner={selectedMediaPartner}
              placementList={placementList}
              selectedPlacement={usableSelectedPlacement}
              creativeTypeList={creativeTypeList}
              selectedCreativeType={selectedCreativeType}
              typeOfData={usableTypeOfData?.id}
              startDate={startDate}
              endDate={endDate}
              numberOfDays={defaultNumberOfDays}
              goalType='conversion'
              isLoadingBase={isLoadingUsable}
              ifSelectedAll={ifSelectedAll}
              align='right'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AnalysisComponent;

const SetCommonDropdown = ({
  dropdownId,
  dropdownDataList = null,
  dataId,
  dataName,
  dataType,
  dropdownData,
  setDropdownData,
  placeholderLabel,
  isAllCheckedInitially,
  isDisabled,
  isOpen,
  ifIdVisibleOnLabel
}) => {
  return (
    <MultiselectDropdownComponent
      dropdownId={dropdownId}
      dropdownDataList={dropdownDataList}
      dataId={dataId}
      dataName={dataName}
      dataType={dataType}
      label=':'
      dropdownData={dropdownData}
      setDropdownData={setDropdownData}
      placeholder={setPlaceHolderLabel(isDisabled, placeholderLabel)}
      txtSearch={setSearchPlaceHolderLabel(placeholderLabel)}
      isAllCheckedInitially={isAllCheckedInitially}
      labelWidth={{ width: '5px', marginRight: '7px' }}
      dropdownWidth={{
        width: '250px',
      }}
      isDisabled={isDisabled}
      uniqueIdentifier='reportDetailsAttributionAnalysisPlacements'
      isOpen={isOpen}
      ifIdVisibleOnLabel={ifIdVisibleOnLabel}
      alignType='right'

    />
  );
};

const setSearchPlaceHolderLabel = (placeholderLabel) => {
  return `Search by ${placeholderLabel}`;
};
