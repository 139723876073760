import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import {
  axiosGetRequest,
  axiosPostRequest,
  axiosPatchRequest,
} from '../../services/http/axiosCalls';
import {
  CommonButtonComponent,
  SearchableDropdownComponent,
  CommonInput,
  LoaderComponent,
  RedirectModalComponent,
  CommonHeadingComponent,
} from '../../components';
import { commonNotification } from '../../utils/common-notifications';
import { breadCrumbHeader, baseEndpoints } from '../../constants';
import { daysOption, typeOptions, statusOptions, getIndex } from './helper';
import { setBlockingLoaderState } from '../../redux/blockingLoader';
import {
  getEncryptedOrDecryptedData,
  isValidateURL,
} from '../../utils/functions';
import './CreateProperties.css';

//createProperties used to create the property in the application with required fields

const CreateProperties = ({ editProperty = false, propertyData = null }) => {
  const client = useSelector((state) => state.client); // listener to client change in global header
  const user = useSelector((state) => state.user); // listener to client change in global header
  const advertiser = useSelector((state) => state.advertiser); // listener to advertiser change in global header
  const navigate = useNavigate(); //navigate between pages
  const dispatch = useDispatch();

  const { id } = useParams(); //getting the advertiser id and id value from URl using useParam redux

  const getAdvertiserFromListById = (id) => {
    let itemToReturn = null;
    advertiser.selectedClientsAdvertiser.forEach((item) => {
      if (item.id !== 0 && item.id === id) {
        itemToReturn = item;
        return;
      }
    });
    return itemToReturn;
  };

  //Getting all the field data for the form with default values and updating the state for create property
  const [formData, setFormData] = useState({
    name: '',
    webDomain: '',
    impressionDays: daysOption[5],
    clickDays: daysOption[5],
    type: typeOptions[0],
    propStatus: statusOptions[0],
    googlePlayId: '',
    appStoreId: '',
    appStoreCountry: '',
    advertiser: getAdvertiserFromListById(advertiser.selectedAdvertiser),
  });

  const [defaultFormData, setDefaultFormData] = useState({
    name: '',
    webDomain: '',
    impressionDays: daysOption[5],
    clickDays: daysOption[5],
    type: typeOptions[0],
    propStatus: statusOptions[0],
    googlePlayId: '',
    appStoreId: '',
    appStoreCountry: '',
    advertiser: getAdvertiserFromListById(advertiser.selectedAdvertiser),
  });

  const [isChanged, setIsChanged] = useState({
    name: false,
    webDomain: false,
    impressionDays: false,
    clickDays: false,
    type: false,
    propStatus: false,
    googlePlayId: false,
    appStoreId: false,
    appStoreCountry: false,
    advertiser: false,
  });

  const [validName, setValidName] = useState({
      valid: true,
      errorMsg: '',
    }),
    [validDomain, setValidDomain] = useState({
      valid: true,
      errorMsg: '',
    }),
    [validIdentifier, setValidIdentifier] = useState({
      valid: true,
      errorMsg: '',
    });

  const [allowEdit, setAllowEdit] = useState(false); //setting the state with allow edit
  const [countryList, setCountryList] = useState([]); //Fetch the country list and update the state value

  const {
    name,
    webDomain,
    impressionDays,
    clickDays,
    type,
    propStatus,
    googlePlayId,
    appStoreId,
    appStoreCountry,
    advertiser: formAdvertiser,
  } = formData;
  //Setting the identifier based on the type code and mandate values needed
  const identifier =
    (type.label === 'iOS App' &&
      appStoreCountry &&
      appStoreCountry.code &&
      appStoreId) ||
    (type.label === 'Android App' && googlePlayId) ||
    (type.label === 'Website' && webDomain);
  // For rendering Modal
  const [showModal, setShowModal] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [propertyName, setPropertyName] = useState(null);
  const [newPropertyData, setNewPropertyData] = useState(propertyData);

  let saveEnableCheck = true;

  if (type.label === 'Website') {
    saveEnableCheck = !name || !formAdvertiser || !webDomain;
  } else if (type.label === 'iOS App') {
    saveEnableCheck =
      !name || !formAdvertiser || !appStoreId || !appStoreCountry;
  } else {
    saveEnableCheck = !name || !formAdvertiser || !googlePlayId;
  }

  // const [propStatus, setPropStatus] = useState(statusOptions[0])

  //To save property  & edit property and  set the payload
  useEffect(() => {
    if (JSON.stringify(newPropertyData) !== JSON.stringify(propertyData)) {
      setNewPropertyData(propertyData);
    }
  }, [propertyData]);

  const saveProperties = () => {
    dispatch(setBlockingLoaderState({ active: true }));

    const url = editProperty
      ? `${baseEndpoints.properties}/${id}`
      : baseEndpoints.properties;
    const adId =
      formAdvertiser.id === -1 || formAdvertiser.id === 0
        ? null
        : formAdvertiser.id; //checking if the selected advertiser is particular one rather than ALL advertiser option
    let payload = {
      userId: client.selectedClient === -1 ? null : client.selectedClient,
      advertiserId: editProperty ? newPropertyData?.data[0].advertiserId : adId,
      uid: user.uid,
      status: propStatus.code,
      pcLookback: clickDays.code,
      pvLookback: impressionDays.code,
    };

    if (!editProperty) {
      payload = {
        ...payload,
        uid: user.uid,
        clientId: client.selectedClient === -1 ? null : client.selectedClient,
        name: name.trim(),
        type: type.label,
        identifier: identifier.trim(),
        iosCountry: appStoreCountry.code,
      };
    }

    //Axios call for API and if status 200 display message and then redirect to properties page
    const requestPromise = editProperty
      ? axiosPatchRequest(url, null, payload)
      : axiosPostRequest(url, payload);
    requestPromise.then(
      (response) => {
        if (response.status === 200) {
          if (!editProperty) {
            setPropertyId(response.id);
            setPropertyName(name);
            setShowModal(true);
            return dispatch(setBlockingLoaderState({ active: false }));
          }

          const msg = `Property ${name} with ID ${id} has been successfully updated. Redirecting to view all Properties...`;
          commonNotification({ msg: msg, type: 'success' });
          setTimeout(() => {
            dispatch(setBlockingLoaderState({ active: false }));
            navigate('/properties');
          }, 2000);
          return;
        }
        commonNotification({
          msg: response.error || 'Something went wrong',
          type: 'danger',
        });
        dispatch(setBlockingLoaderState({ active: false }));
      },
      (err) => {
        if (err?.response?.status === 409) {
          commonNotification({
            msg: err?.response?.data?.error,
            type: 'danger',
          });
          setValidName({
            valid: false,
            errorMsg: 'Name already exists. Please enter another name',
          });
          const searchElem = document.getElementById(`createPropertiesName`);
          searchElem?.focus();
          dispatch(setBlockingLoaderState({ active: false }));
          return;
        }
        commonNotification({
          msg: err.error || err?.response?.data?.error,
          type: 'danger',
        });
        dispatch(setBlockingLoaderState({ active: false }));
      }
    );
  };

  //Axios call for the country list API and then setting the value
  const getCountryList = async () => {
    try {
      const response = await axiosGetRequest(
        `${baseEndpoints.properties}/countrycode`
      );
      if (response.data) {
        setCountryList(response.data);
        if (!editProperty) {
          const defaultCode =
            (response.data.length > 0 && response.data[0]) || {};
          setFormData({ ...formData, appStoreCountry: defaultCode });
        }
      }
    } catch (err) {
      setCountryList([]);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    if (advertiser.selectedAdvertiser >= 1 && !editProperty) {
      advertiser?.selectedClientsAdvertiser?.forEach((item) => {
        if (item.id === advertiser?.selectedAdvertiser) {
          changeHandler(item, 'advertiser');
          checkChange('advertiser', item);
        }
      });
    }
  }, [advertiser.selectedAdvertiser]);

  useEffect(() => {
    if (propertyData?.data?.length) {
      const {
        name,
        pcLookback,
        identifier,
        type,
        status,
        iosCountry,
        pvLookback,
        advertiserId,
      } = propertyData.data[0];

      const domain = (type === 'Website' && identifier) || '';
      const googleId = (type === 'Android App' && identifier) || '';
      const storeId = (type === 'iOS App' && identifier) || '';

      setDefaultFormData({
        name: name,
        webDomain: domain,
        impressionDays: daysOption[getIndex(daysOption, pvLookback)],
        clickDays: daysOption[getIndex(daysOption, pcLookback)],
        type: typeOptions[getIndex(typeOptions, type, 'label')],
        propStatus: statusOptions[getIndex(statusOptions, status)],
        googlePlayId: googleId,
        appStoreId: storeId,
        appStoreCountry: countryList[getIndex(countryList, iosCountry)],
        advertiser: getAdvertiserFromListById(advertiserId),
      });
    }
  }, [propertyData]);

  //for edit property we are updating the values based on user selection - click days, impression days & status
  useEffect(() => {
    if (newPropertyData?.data?.length > 0) {
      const {
        name,
        pcLookback,
        identifier,
        type,
        status,
        iosCountry,
        pvLookback,
        advertiserId,
      } = newPropertyData.data[0];
      const domain = (type === 'Website' && identifier) || '';
      const googleId = (type === 'Android App' && identifier) || '';
      const storeId = (type === 'iOS App' && identifier) || '';
      setFormData({
        name: name,
        webDomain: domain,
        impressionDays: daysOption[getIndex(daysOption, pvLookback)],
        clickDays: daysOption[getIndex(daysOption, pcLookback)],
        type: typeOptions[getIndex(typeOptions, type, 'label')],
        propStatus: statusOptions[getIndex(statusOptions, status)],
        googlePlayId: googleId,
        appStoreId: storeId,
        appStoreCountry: countryList[getIndex(countryList, iosCountry)],
        advertiser: getAdvertiserFromListById(advertiserId),
      });
    }
  }, [newPropertyData]);

  //Change handler for handling each form value change and updating each form data
  const changeHandler = (val, name) => {
    setFormData({ ...formData, [name]: val });
  };

  //Handler for cancel - navigates to the properties page
  const handleCancel = () => {
    navigate('/properties');
  };

  //Handler for allow edit option - navigates to the edit page with field editable
  const handleAllowEdit = () => {
    setAllowEdit(true);
  };

  //enable save with a minimum character length > 2.

  const labelType = propertyData?.data[0]?.type;

  //enable show edit option and allow edit option based on property type
  const showEdit = editProperty && allowEdit;
  const disableDropdowns =
    labelType === 'Android App' || labelType === 'iOS App';
  const disableField = showEdit;

  const closeModal = () => {
    setFormData({
      name: '',
      webDomain: '',
      impressionDays: daysOption[5],
      clickDays: daysOption[5],
      type: typeOptions[0],
      propStatus: statusOptions[0],
      googlePlayId: '',
      appStoreId: '',
      appStoreCountry: '',
      advertiser: getAdvertiserFromListById(advertiser.selectedAdvertiser),
    });
    setDefaultFormData({
      name: '',
      webDomain: '',
      impressionDays: daysOption[5],
      clickDays: daysOption[5],
      type: typeOptions[0],
      propStatus: statusOptions[0],
      googlePlayId: '',
      appStoreId: '',
      appStoreCountry: '',
      advertiser: getAdvertiserFromListById(advertiser.selectedAdvertiser),
    });
    setShowModal(false);
  };

  const checkChange = (key, value) => {
    setIsChanged({ ...isChanged, [key]: value !== defaultFormData[key] });
  };

  return (
    <div
      className={
        editProperty ? 'create-properties edit-screen' : 'create-properties'
      }
    >
      {!editProperty && (
        <div className='breadCrumbOuterWrapper'>
          <div className='breadCrumbInnerWrapper'>
            <Link to='/'>
              <div className='homeBlue'></div>
            </Link>
            <div className='breadCrumbBody'>
              /
              <Link to='/properties'>
                <div className='active'>{breadCrumbHeader.properties}</div>
              </Link>
            </div>
            <div className='breadCrumbBody'>
              <span>/</span>
              Create Property
            </div>
          </div>
        </div>
      )}
      {!editProperty ? (
        <CommonHeadingComponent headingLabel={'Create Property'} />
      ) : (
        <></>
      )}
      <Container>
        {editProperty && newPropertyData.isLoading ? (
          <LoaderComponent headerClass='editPropertiesLoaderWrapper' />
        ) : (
          <>
            <Row>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={12}
                className='form-field relative'
              >
                <CommonInput
                  label='Name'
                  inputValue={name}
                  setInputValue={(val) => {
                    changeHandler(val, 'name');
                    checkChange('name', val);
                    if (val.length > 45) {
                      setValidName({
                        valid: false,
                        errorMsg: 'Maximum allowed characters for Name is 45',
                      });
                    } else {
                      setValidName({ valid: true, errorMsg: '' });
                    }
                  }}
                  disabled={editProperty}
                  labelClass={'requiredInput'}
                  // maxLength='45'
                  placeholder='Enter Property name'
                  uniqueIdentifier='createPropertiesName'
                  onBlurHandle={(val) => {
                    if (!val?.target?.value) {
                      setValidName({
                        valid: false,
                        errorMsg: 'Please enter a Name',
                      });
                    } else if (val?.target?.value?.length > 45) {
                      setValidName({
                        valid: false,
                        errorMsg: 'Maximum allowed characters for Name is 45',
                      });
                    } else {
                      setValidName({ valid: true, errorMsg: '' });
                    }
                  }}
                  className={
                    validName.valid
                      ? 'propertyDetailInput'
                      : 'propertyDetailInput error'
                  }
                />
                {!validName.valid && (
                  <span className='error'>{validName.errorMsg}</span>
                )}
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                <div className='field'>
                  <SearchableDropdownComponent
                    options={
                      advertiser.selectedClientsAdvertiser?.filter(
                        (item) => item.id !== 0
                      ) ?? []
                    }
                    label='name'
                    id='id'
                    dropdownId='propertiesAdvertiserSelect'
                    dropdownLabel='Advertiser'
                    selectedValue={formAdvertiser}
                    wrapperClass={'requiredDD'}
                    handleChange={(newAdvertiser) => {
                      changeHandler(newAdvertiser, 'advertiser');
                      checkChange('advertiser', newAdvertiser);
                    }}
                    isDisabled={editProperty}
                    placeholderLabel={'Select An Advertiser'}
                  />
                </div>
              </Col>
              {type.label === 'Website' && (
                <Col
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  className='form-field relative'
                >
                  <CommonInput
                    label='Domain'
                    inputValue={webDomain}
                    setInputValue={(val) => {
                      changeHandler(val, 'webDomain');
                      checkChange('webDomain', val);
                      if (val.length > 70) {
                        setValidDomain({
                          valid: false,
                          errorMsg:
                            'Maximum allowed characters for Domain is 70',
                        });
                      } else {
                        setValidDomain({ valid: true, errorMsg: '' });
                      }
                    }}
                    disabled={editProperty}
                    labelClass={'requiredInput'}
                    placeholder='Enter Domain'
                    uniqueIdentifier='createPropertiesDomain'
                    onBlurHandle={(val) => {
                      if (!val?.target?.value) {
                        setValidDomain({
                          valid: false,
                          errorMsg: 'Please enter a Domain',
                        });
                      } else if (val?.target?.value?.length > 70) {
                        setValidDomain({
                          valid: false,
                          errorMsg:
                            'Maximum allowed characters for Domain is 70',
                        });
                      } else {
                        setValidDomain({
                          valid: isValidateURL(val.target.value),
                          errorMsg: !isValidateURL(val.target.value)
                            ? 'Please enter a valid Domain'
                            : '',
                        });
                      }
                    }}
                    className={
                      validDomain.valid
                        ? 'propertyDetailInput'
                        : 'propertyDetailInput error'
                    }
                  />
                  {!validDomain.valid && (
                    <span className='error'>{validDomain.errorMsg}</span>
                  )}
                </Col>
              )}
              {type.label === 'iOS App' && (
                <>
                  <Col
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    className='form-field relative'
                  >
                    <CommonInput
                      label={'Apple Store ID'}
                      inputValue={appStoreId}
                      setInputValue={(val) => {
                        changeHandler(val, 'appStoreId');
                        checkChange('appStoreId', val);
                        if (val.length > 200) {
                          setValidIdentifier({
                            valid: false,
                            errorMsg:
                              'Maximum allowed characters for Apple Store ID is 200',
                          });
                        } else {
                          setValidIdentifier({ valid: true, errorMsg: '' });
                        }
                      }}
                      disabled={editProperty}
                      labelClass='requiredInput'
                      uniqueIdentifier='createPropertiesReqInput'
                      onBlurHandle={(val) => {
                        if (!val?.target?.value) {
                          setValidIdentifier({
                            valid: false,
                            errorMsg: 'Please enter Apple Store ID',
                          });
                        } else if (val?.target?.value?.length > 200) {
                          setValidIdentifier({
                            valid: false,
                            errorMsg:
                              'Maximum allowed characters for Apple Store ID is 200',
                          });
                        } else {
                          setValidIdentifier({ valid: true, errorMsg: '' });
                        }
                      }}
                      className={
                        validIdentifier.valid
                          ? 'propertyDetailInput'
                          : 'propertyDetailInput error'
                      }
                    />
                    {!validIdentifier.valid && (
                      <span className='error'>{validIdentifier.errorMsg}</span>
                    )}
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                    <div className='field'>
                      <SearchableDropdownComponent
                        options={countryList}
                        label='name'
                        id='name'
                        dropdownLabel='App Store Country'
                        selectedValue={appStoreCountry}
                        handleChange={(val) => {
                          if (
                            (!appStoreCountry && val) ||
                            (val && appStoreCountry.code !== val.code)
                          ) {
                            changeHandler(val, 'appStoreCountry');
                            checkChange('appStoreCountry', val);
                          }
                        }}
                        wrapperClass={'requiredDD'}
                        dropdownId='countryList'
                        isDisabled={editProperty}
                        uniqueIdentifier='createPropertiesAppStoreCountry'
                      />
                    </div>
                  </Col>
                </>
              )}
              {type.label === 'Android App' && (
                <Col
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  className='form-field relative'
                >
                  <CommonInput
                    label='Google Play ID'
                    inputValue={googlePlayId}
                    setInputValue={(val) => {
                      changeHandler(val, 'googlePlayId');
                      checkChange('appStoreCountry', val);
                      if (val.length > 200) {
                        setValidIdentifier({
                          valid: false,
                          errorMsg:
                            'Maximum allowed characters for Google Play ID is 200',
                        });
                      } else {
                        setValidIdentifier({ valid: true, errorMsg: '' });
                      }
                    }}
                    disabled={editProperty}
                    labelClass={'requiredInput'}
                    uniqueIdentifier='createPropertiesGooglePlayID'
                    onBlurHandle={(val) => {
                      if (!val?.target?.value) {
                        setValidIdentifier({
                          valid: false,
                          errorMsg: 'Please enter Google Play ID',
                        });
                      } else if (val?.target?.value?.length > 200) {
                        setValidIdentifier({
                          valid: false,
                          errorMsg:
                            'Maximum allowed characters for Google Play ID is 200',
                        });
                      } else {
                        setValidIdentifier({ valid: true, errorMsg: '' });
                      }
                    }}
                    className={
                      validIdentifier.valid
                        ? 'propertyDetailInput'
                        : 'propertyDetailInput error'
                    }
                  />
                  {!validIdentifier.valid && (
                    <span className='error'>{validIdentifier.errorMsg}</span>
                  )}
                </Col>
              )}
              <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                <div className='field'>
                  <SearchableDropdownComponent
                    options={typeOptions}
                    label='label'
                    id='code'
                    dropdownLabel='Type'
                    isSearchable={false}
                    selectedValue={type}
                    handleChange={(val) => {
                      changeHandler(val, 'type');
                      checkChange('type', val);
                    }}
                    wrapperClass={'requiredDD'}
                    dropdownId='type'
                    isDisabled={editProperty}
                    uniqueIdentifier='createPropertiesType'
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                <div className='field'>
                  <SearchableDropdownComponent
                    options={statusOptions}
                    label='label'
                    id='label'
                    dropdownLabel='Status'
                    isSearchable={false}
                    selectedValue={propStatus}
                    handleChange={(val) => {
                      changeHandler(val, 'propStatus');
                      checkChange('propStatus', val);
                    }}
                    wrapperClass={'requiredDD'}
                    dropdownId='propStatus'
                    isDisabled={disableField}
                    uniqueIdentifier='createPropertiesStatus'
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                <div className='field'>
                  <SearchableDropdownComponent
                    options={daysOption}
                    label='label'
                    id='label'
                    dropdownLabel='Post Impression Lookback Window'
                    isSearchable={false}
                    selectedValue={impressionDays}
                    handleChange={(val) => {
                      changeHandler(val, 'impressionDays');
                      checkChange('impressionDays', val);
                    }}
                    wrapperClass={'requiredDD'}
                    dropdownId='plWindow'
                    isDisabled={disableDropdowns}
                    uniqueIdentifier='createPropertiesLookbackWindow'
                  />
                  {allowEdit && (
                    <div className='flex'>
                      <div className='dropdown text-danger'>
                        Note: Editing the lookback window will impact the active
                        campaigns that are using this property.
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                <div className='field'>
                  <SearchableDropdownComponent
                    options={daysOption}
                    label='label'
                    id='label'
                    dropdownLabel='Post Click Lookback Window'
                    isSearchable={false}
                    selectedValue={clickDays}
                    handleChange={(val) => {
                      changeHandler(val, 'clickDays');
                      checkChange('clickDays', val);
                    }}
                    wrapperClass={'requiredDD'}
                    dropdownId='pcWindow'
                    isDisabled={disableDropdowns}
                    uniqueIdentifier='createPropertiesLookbackWindow2'
                  />
                  <div className='flex'>
                    <div className='dropdown'>
                      <div>
                        Note: This is applicable only for search, social and
                        display with click URL placements.
                      </div>
                      {allowEdit && (
                        <div className='text-danger'>
                          Note: Editing the lookback window will impact the
                          active campaigns that are using this property.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                className='campaignListButtonWrapper text-center mt-3 mb-4'
              >
                <CommonButtonComponent
                  label={getShowEditProps(showEdit, 'label')}
                  appendWrapperClassName='btnPrimaryWrapper'
                  appendButtonClassName='btnPrimary'
                  isDisabled={
                    saveEnableCheck ||
                    !Object.values(isChanged).includes(true) ||
                    !validName.valid ||
                    !validDomain.valid ||
                    !validIdentifier.valid
                  }
                  onClickCTA={getShowEditProps(
                    showEdit,
                    'onClickCTA',
                    handleAllowEdit,
                    saveProperties
                  )}
                  buttonUI={`createProperties${getShowEditProps(
                    showEdit,
                    'label'
                  )}`}
                />

                <CommonButtonComponent
                  label={'Cancel'}
                  appendWrapperClassName='btnPrimaryWrapper'
                  appendButtonClassName='btnSecondary'
                  onClickCTA={handleCancel}
                  buttonUI='createPropertiesCancel'
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
      {/* Component for redirect modal */}
      {showModal && (
        <RedirectModalComponent
          modalBodyElements={[
            'createGoal',
            'createProperty2',
            'propertyList',
            'createCampaign',
            'campaignList',
          ]}
          messageType='Property'
          detailsElements={{
            createGoal: {
              routeParam: [
                { param: propertyId, index: 0 },
                { param: 'goals', index: 1 },
                { param: 'new', index: 2 },
              ],
              queryParam: [
                {
                  params: getEncryptedOrDecryptedData(
                    {
                      advertiserId: formAdvertiser.id,
                      paramClientId: client.selectedClient,
                      propertyName,
                    },
                    'encrypt'
                  ),
                },
              ],
            },
          }}
          onCloseNav='/properties'
          samePageRedirect='createProperty2'
          handleSamePageRedirect={() => closeModal()}
        />
      )}
    </div>
  );
};

export default CreateProperties;

const getShowEditProps = (
  showEdit,
  type,
  handleAllowEdit = null,
  handleSave = null
) => {
  switch (type) {
    case 'label':
      return showEdit ? 'Edit' : 'Save';
    case 'onClickCTA':
      return showEdit ? handleAllowEdit : handleSave;
    default:
      return null;
  }
};
