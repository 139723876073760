import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { Container, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import {
  CommonButtonComponent,
  CommonInput,
  CommonModal,
  DatePickerComponent,
  SearchableDropdownComponent,
  SetFrequencyModal,
  MultiselectDropdownComponent,
  LoaderComponent
} from '../../../components';
import MultiRecipients from './MultiRecipients/MultiRecipients';
import {
  FormatLabel,
  checkCondition,
  daysToFormatWRTDates,
  getStartAndEndDate,
  addTimeFrames,
  subtractTimeFrames,
  getCurrentESTDate,
  calculationForLastLiftperiod,
  setPlaceHolderLabel,
  getNotification,
  removeNotification,
  checkSelectAll,
} from '../../../utils/functions';
import { usePostAPIInterceptor } from '../../../hooks/postAPIInterceptor';
import {
  baseEndpoints,
  presentTimeFrameList,
  runTimeFrameList,
  statusOptionList,
  scheduledReportErrorMessageList,
  reporttypeOptionList,
  dedupeCustomVarOptions,
  outputFormatOptions,
  piiMaskOptions,
  yesNoOptions,
  DailyTimeFrameOptions,
  MonthlyTimeFrameOptions,
  WeeklyMonToSunTimeFrameOptions,
  TimeFrameOptions,
} from '../../../constants';
import NewMultiSelect from '../../ReportDetails/components/reportDetailsTab/mediaDeliveryTab/analysisSection/components/dropDownAnalysisSection/NewMultiSelect';
import '../../../styles/custom.css';
import '../../../components/modals/modals.css';
import { set } from 'lodash';
import axios from 'axios';
import { axiosSendRequest } from '../../../services/http/axiosCalls';

let presentTimeFrameOptions = [...presentTimeFrameList];

const runTimeFrameOptions = [...runTimeFrameList],
  reportTypeOptions = [...reporttypeOptionList],
  statusOptions = [...statusOptionList],
  baseFrequencyOptions = {
    repeatEvery: { key: 2, value: 'Daily', name: 'Daily' },
    selectedDays: [],
    selectedDate: -1,
    selectedRecurrence: -1,
  },
  errAndWarnMsg = { ...scheduledReportErrorMessageList };

const piiMaskList = [...piiMaskOptions],
  headerChoiceList = [...yesNoOptions],
  outputFormatList = [...outputFormatOptions];

let dedupeVariableList = [...dedupeCustomVarOptions];

const reportNameMaxLength = 200;

const ScheduledReportModal = ({
  setShowScheduledReportDialog,
  showScheduledReportDialog,
  show,
  campaign,
  advertiserId,
  clientId,
  organization,
  onSaveSuccesss,
  userId,
  mediaPartner,
  setMediaPartner,
  mediaPartnerList,
  setMediaPartnerList,
  creativeType,
  setCreativeType,
  creativeTypeList,
  setCreativeTypeList,
  originalPlacement,
  originalCreativeType,
  placement,
  setPlacement,
  placementList,
  setPlacementList,
  isLoading
  }) => {
  const user = useSelector(
    (state) => state.user
  ),
  role = useSelector((state) => state.user.role);
  const currentDate = getCurrentESTDate('YYYY-MM-DD');
  const [reportName, setReportName] = useState(''),
    [reportData, setReportData] = useState(null),
    showWasPreviouslyOpen = useRef(false);

  const [emails, setEmails] = useState([user.email]);
  const mediaPartnerListRef = useRef(null),
    originalMediaPartner = useRef(null);

  const originalCreativeTypeList = useRef(null),
    originalCreativeTypeToSet = useRef(null);

  const [reportType, setReportType] = useState(reportTypeOptions[0]);
  const [reportStatus, setReportStatus] = useState(statusOptions[0]);
  const placementListRef = useRef(null),
    originalPlacementToSet = useRef(null),
    originalPlacementList = useRef(null);
  const [showFrequencyModal, setShowFrequencyModal] = useState(false),
    [frequency, setFrequency] = useState('Daily'.toUpperCase()),
    [frequencyOptions, setFrequencyOptions] = useState(baseFrequencyOptions);

  const [runTimeFrame, setRunTimeFrame] = useState(null),
    [runDateRange, setRunDateRange] = useState([null, null]),
    [tempRunEndDate, setTempRunEndDate] = useState(null),
    [finalLiftDate, setFinalLiftDate] = useState(null);

  const [presentTimeFrame, setPresentTimeFrame] = useState(null),
    [presentDateRange, setPresentDateRange] = useState([null, null]);

  const [showFrequencyWarnModal, setShowFrequencyWarnModal] = useState(false);

  const [isMounted, setIsMounted] = useState(false),
    [saving, setSaving] = useState(false);

  const [reportTypeValue, setReportTypeValue] = useState(null);
  const [piiMaskChoice, setPiiMaskChoice] = useState(null);
  const [headerIntValue, setHeaderIntValue] = useState(null);
  const [selectedDedupeVariables, setSelectedDedupeVariables] = useState([]);
  const [selectedOutputFormat, setSelectedOutputFormat] = useState(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  let respondentDataTimeframeOptions = [...DailyTimeFrameOptions];
  const location = window.location.pathname;
  const [showReachAndFrequencyWarning, setShowReachAndFrequencyWarning] = useState(false);
  const today = moment().startOf('day').format('YYYY-MM-DD');
  const campaignStart = campaign?.start_date.substring(0, 10);
  const sevenDaysAgo = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD');
  const dateRangeDiff = moment(presentDateRange[1]).diff(moment(presentDateRange[0]), 'days');

  if (reportType.id === 2 && frequencyOptions.repeatEvery.key === '1') {
    respondentDataTimeframeOptions = [...TimeFrameOptions];
  } else if (reportType.id === 2 && frequencyOptions.repeatEvery.key === '3') {
    respondentDataTimeframeOptions = [...WeeklyMonToSunTimeFrameOptions];
  } else if (reportType.id === 2 && frequencyOptions.repeatEvery.key === '4') {
    respondentDataTimeframeOptions = [...MonthlyTimeFrameOptions];
  }

  useEffect(() => {
    if (showScheduledReportDialog && !showWasPreviouslyOpen.current) {
      originalMediaPartner.current = mediaPartner;
      originalPlacementToSet.current = placement;
      originalPlacementList.current = placementList;
      originalCreativeTypeToSet.current = creativeType;
      originalCreativeTypeList.current = creativeTypeList;
    }
    
    if (showScheduledReportDialog) {
      showWasPreviouslyOpen.current = true;
    } else {
      showWasPreviouslyOpen.current = false;
    }
  }, [showScheduledReportDialog]);

  useEffect(() => {
    if (reportType.id !== 2) {
      if (
        reportName &&
        reportName.length <= reportNameMaxLength &&
        !checkReportNameForSpecialCharacters(reportName) &&
        emails.length > 0 &&
        mediaPartner?.length > 0 &&
        !(isLoading.mediaPartner) &&
        !(isLoading.placement) &&
        (!!(
          presentTimeFrame?.id === 0 &&
          presentDateRange[0] &&
          presentDateRange[1]
        ) ||
          (presentTimeFrame && presentTimeFrame?.id !== 0)) &&
        (frequencyOptions?.repeatEvery?.key === 1 ||
          !!(runTimeFrame?.id === 0 && runDateRange[0] && runDateRange[1]) ||
          (runTimeFrame && runTimeFrame?.id !== 0))
      ) {
        setIsSaveButtonDisabled(false);
      } else {
        setIsSaveButtonDisabled(true);
      }
    } else {
      if (
        reportName &&
        reportName?.length <= reportNameMaxLength &&
        !checkReportNameForSpecialCharacters(reportName) &&
        emails.length > 0 &&
        !(isLoading.mediaPartner) &&
        !(isLoading.placement) &&
        piiMaskChoice &&
        headerIntValue &&
        selectedOutputFormat &&
        ((presentTimeFrame?.id === 0 &&
          presentDateRange[0] &&
          presentDateRange[1]) ||
          (presentTimeFrame && presentTimeFrame?.id !== 0))
      ) {
        setIsSaveButtonDisabled(false);
      } else {
        setIsSaveButtonDisabled(true);
      }
    }
  }, [
    reportName,
    emails,
    mediaPartner,
    presentTimeFrame,
    presentDateRange,
    runTimeFrame,
    runDateRange,
    reportType,
    piiMaskChoice,
    headerIntValue,
    selectedOutputFormat,
    isLoading
  ]);

  useEffect(() => {
    setPresentTimeFrame(null);
    setPresentDateRange([null, null]);
    setIsSaveButtonDisabled(true);
  }, [frequency, reportType]);

  useEffect(() => {
    if (runTimeFrame?.id === 3) {
      getUpdatedRunDateRangeBasedOnFrequency(
        frequencyOptions,
        runDateRange,
        setTempRunEndDate
      );
    }
  }, [frequencyOptions]);

  useEffect(() => {
    if (campaign?.campaign_id && showScheduledReportDialog) {
      const campaignStartDate = moment(
          campaign?.start_date ?? currentDate
        ).format('YYYY-MM-DD'),
        campaignEndDate = moment(campaign?.end_date ?? currentDate).format(
          'YYYY-MM-DD'
        ),
        campaignLookbackDate = moment(
          campaign?.lookback_date ?? currentDate
        ).format('YYYY-MM-DD'),
        campaignFinalLiftDate = calculationForLastLiftperiod(
          campaignStartDate,
          campaignLookbackDate
        );

      setFinalLiftDate(campaignFinalLiftDate);
      const controller = new AbortController();
      presentTimeFrameOptions.map((option) => {
        const { isDisabled } = getStartAndEndDate(
          option.id,
          currentDate,
          campaignStartDate,
          campaignEndDate,
          campaignLookbackDate
        );
        option.isDisabled = isDisabled;
      });

      runTimeFrameOptions.map((option) => {
        option.isDisabled = disableRunOpts(
          option.id,
          currentDate,
          campaignFinalLiftDate,
          campaignEndDate,
          campaignLookbackDate
        );

        option.dateRange = getStartAndEndDatesForRunTimeFrames(
          option.id,
          currentDate,
          campaignEndDate,
          campaignLookbackDate,
          campaignFinalLiftDate
        );
      });
    }
  }, [campaign?.campaign_id, showScheduledReportDialog]);

  useEffect(() => {
    if (showScheduledReportDialog) {
      if (reportType.id === 0 && runTimeFrame?.id === 3) {
        setRunTimeFrame(null);
        setRunDateRange([null, null]);
        setTempRunEndDate(null);
      }

      runTimeFrameOptions.map((option) => {
        if (option.id === 3) {
          option.isDisabled =
            !reportType.id || disableRunOpts(3, currentDate, finalLiftDate);
        }
      });
    }
    
  }, [reportType, campaign?.campaign_id, showScheduledReportDialog]);

  useEffect(() => {
    if (presentTimeFrame?.isDisabled) {
      setShowFrequencyWarnModal(true);
    }

    if (presentTimeFrame?.id !== 0) {
      setPresentDateRange([null, null]);
    }
  }, [presentTimeFrame]);

  useEffect(() => {
    if (runTimeFrame) {
      setRunDateRange(runTimeFrame.dateRange);
    }

    if (runTimeFrame?.id === 3) {
      getUpdatedRunDateRangeBasedOnFrequency(
        frequencyOptions,
        runTimeFrame.dateRange,
        setTempRunEndDate
      );
    }
  }, [runTimeFrame]);

  useEffect(() => {
    placementListRef.current = placementList ? [...placementList] : null;
  }, [placementList]);

  const createScheduledReportCallback = (status, data, error) => {
    if (status !== 200) {
      return getNotification('danger', data?.error ? data?.error : error);
    }

    getNotification('success', data?.message || '');
    onSaveSuccesss();
    closeDialog();
    setShowSuccessModal(true);
  };

  // Post API Interceptor to save scheduled reports
  usePostAPIInterceptor(
    isMounted,
    reportData,
    `${baseEndpoints.reports}/scheduled`,
    createScheduledReportCallback
  );

  const closeDialog = () => {
    removeNotification();
    setShowScheduledReportDialog(false);
    reset();
  };

  const reset = () => {
    setReportName('');
    setReportStatus(statusOptions[0]);
    setReportType(reportTypeOptions[0]);
    setMediaPartner(originalMediaPartner.current);
    setEmails([user.email]);
    setPresentTimeFrame(null);
    setPresentDateRange([null, null]);
    setTempRunEndDate(null);
    setRunTimeFrame(null);
    setRunDateRange([null, null]);
    setFrequencyOptions(baseFrequencyOptions);
    setFrequency('Daily'.toUpperCase());
    setPiiMaskChoice(null);
    setHeaderIntValue(null);
    setSelectedDedupeVariables(null);
    setSelectedOutputFormat(null);
    setIsSaveButtonDisabled(true);
    setShowSuccessModal(false);
    setCreativeType(originalCreativeTypeToSet.current);
    setCreativeTypeList(originalCreativeTypeList.current);
    setPlacement(originalPlacementToSet.current);
    setPlacementList(originalPlacementList.current);
    if (JSON.stringify(placement) !== JSON.stringify(originalPlacementToSet.current)) {
      originalPlacement.current = originalPlacementToSet.current;
    }
    if (JSON.stringify(creativeType) !== JSON.stringify(originalCreativeTypeToSet.current)) {
      originalCreativeType.current = originalCreativeTypeToSet.current;
    }
  };
  useEffect(() => {
    if (campaign) {
      const setDedupeVariables = (campaign) => {
        const attributionVersion = campaign.v2_attribution_enabled;

        const variableList = dedupeCustomVarOptions
          .filter((element) => element.version.includes(attributionVersion))
          .map(({ version, ...rest }) => {
            return rest;
          });

        dedupeVariableList = [...variableList];
      };

      setDedupeVariables(campaign);
    }
  }, [campaign]);

  useEffect(() => {
    if(reportType?.id === 5 && campaignStart > sevenDaysAgo){
      setShowReachAndFrequencyWarning(true);
    }
  }, [reportType, campaign])

  const save = () => {
    setSaving(true);
    setTimeout(() => {
      setSaving(false);
    }, 5000);

    if (!reportName) {
      return getNotification('danger', errAndWarnMsg.noRptNameErr);
    }

    if (emails.length === 0) {
      return getNotification('danger', errAndWarnMsg.noRecipientErr);
    }

    if(!placement || !creativeType || !mediaPartner){
      return getNotification('danger', errAndWarnMsg.atLeastOnePlacementCreativeTypePartnerError);
    }

    const legacyTimeframeIds = [
      '8',
      '5',
      '2',
      '6',
      '3',
      '4',
      '7',
      '9',
      '13',
      '14',
    ];

    if (
      frequencyOptions?.repeatEvery?.key !== 1 &&
      ((!runTimeFrame && reportType.id !== 2) ||
        (reportType.id === 2 &&
          !legacyTimeframeIds.includes(presentTimeFrame?.value)))
    ) {
      return getNotification('danger', errAndWarnMsg.noRunTimeFrameErr);
    }

    if (
      (!runDateRange[0] || !runDateRange[1]) &&
      frequencyOptions?.repeatEvery?.key !== 1 &&
      reportType.id !== 2
    ) {
      return getNotification('danger', errAndWarnMsg.noRunDateErr);
    }

    if (!presentTimeFrame) {
      return getNotification('danger', errAndWarnMsg.noPresetTimeFrameErr);
    }

    if (
      presentTimeFrame?.id === 0 &&
      (!presentDateRange[0] || !presentDateRange[1])
    ) {
      return getNotification('danger', errAndWarnMsg.customDateRngErr);
    }

    if (reportType.id == 2 && !piiMaskChoice) {
      return getNotification('danger', errAndWarnMsg.piiMaskError);
    }

    if (reportType.id == 2 && !headerIntValue) {
      return getNotification('danger', errAndWarnMsg.headerSelectionError);
    }

    if (reportType.id == 2 && !selectedOutputFormat) {
      return getNotification('danger', errAndWarnMsg.outputFormatError);
    }

    if (reportType?.id === 5 && presentTimeFrame?.id === 0 && dateRangeDiff < 6){
      return getNotification('danger', errAndWarnMsg.reachAndFrequencyDateRangeError);
    }

    let reportParams = {};

    const piiMaskChoiceValue =
      reportType.id === 2 && piiMaskChoice.value ? 1 : 0;
    const headerIntValueChoice =
      reportType.id === 2 && headerIntValue.value ? headerIntValue.value : 0;
    const selectedDedupeVariablesChoice = [];
    if (reportType.id === 2 && selectedDedupeVariables?.length > 0) {
      for (let dedupeChoice of selectedDedupeVariables) {
        if (dedupeChoice) {
          selectedDedupeVariablesChoice.push(dedupeChoice.label);
        }
      }
    }
    const selectedOutputFormatChoice =
      reportType.id === 2 ? selectedOutputFormat.value : 0;
    //frequencyIdChoice is converting a 6 (Weekly) to a 2, becuase that is number that corresponds to the weekly
    //option in legacy
    const frequencyIdChoice =
      frequencyOptions.repeatEvery.key === 6
        ? 3
        : frequencyOptions.repeatEvery.key;

    let placementIds = '';
    for(let i = 0; i < placement.length; i++){
      if(i < placement.length - 1){
        placementIds += placement[i].placement_id + ',';
      } else {
        placementIds += placement[i].placement_id;
      }
    }

    let reportOptionId;
    switch(reportType.id){
      case 5:
        reportOptionId = 21;
        break;
      case 4:
        reportOptionId = 20;
        break;
      case 3:
        reportOptionId = 19;
        break;
      default:
        reportOptionId = 17;
    }

    const isAllPlacementSelected =  checkSelectAll(
      placementList,
      placement,
      'placement_id'
    );
    const isAllPartnerSelected =  checkSelectAll(
      mediaPartnerList,
      mediaPartner,
      'partner_id'
    );


    if(reportType.id === 2) {
      reportParams = {
        statusId: 1,
        client_id: clientId,
        advertiser_id: campaign?.advertiser_id,
        name: reportName,
        campaignid: campaign?.campaign_id,
        placementIds: '0',
        typeId: 5,
        timeframeId: presentTimeFrame.value,
        frequencyId: frequencyIdChoice,
        customStartDate: presentDateRange[0]
          ? moment(presentDateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : null,
        customEndDate: presentDateRange[1]
          ? moment(presentDateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : null,
        custom_var_dedupe: selectedDedupeVariablesChoice,
        reportFormatId: selectedOutputFormatChoice,
        recipients: emails,
        mediaPartners: mediaPartner,
        piiMask: piiMaskChoiceValue,
        header: headerIntValueChoice,
      };
    } else {
      reportParams = {
        name: reportName,
        campaign_id: campaign?.campaign_id,
        client_id: clientId,
        advertiser_id: campaign?.advertiser_id,
        type: reportOptionId,
        report_type: reportType.id,
        status: reportStatus.id,
        recipients: emails,
        // CN-4865: Disabling media partner filter in attribution + lift report for now
        mediaPartners: (isAllPartnerSelected || reportType.id === 1) ? null : mediaPartner,
        time_frame: presentTimeFrame?.id,
        start_date: presentDateRange[0]
          ? moment(presentDateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : null,
        end_date: presentDateRange[1]
          ? moment(presentDateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : null,
        frequency_options: frequencyOptions,
        frequencyId: frequencyIdChoice,
        run_time_frame: runTimeFrame?.id,
        run_date_start: moment(runDateRange[0]).format('YYYY-MM-DD HH:mm:ss'),
        run_date_end: moment(
          runTimeFrame?.id === 3 ? tempRunEndDate : runDateRange[1]
        ).format('YYYY-MM-DD HH:mm:ss'),
        placement_ids: ((reportType.id === 4 || reportType.id === 3) && !isAllPlacementSelected) ? placementIds : "0",
        organization: organization,
        lookback: campaign?.lookback,
        campaign_start_date: campaign?.start_date,
        campaign_end_date: campaign?.end_date,
      };
    }

    setReportData(reportParams);
    setIsMounted(true);
  };

  const saveFrequencyOptions = (selectedOptions) => {
    setShowFrequencyModal(false);
    setFrequencyOptions(selectedOptions);
    setFrequency(selectedOptions.repeatEvery.name.toUpperCase());
  };

  useEffect(() => {
    mediaPartnerListRef.current = mediaPartnerList
      ? [...mediaPartnerList]
      : null;
  }, [mediaPartnerList]);

  return (
    <div className='createScheduledReportWrapper'>
      <CommonModal
        show={show}
        header='Create Scheduled Report'
        body={
          saving ? <LoaderComponent headerClass='scheduledReportModalLoader' /> :
          <RenderModalBody
            campaign={campaign}
            reportName={reportName}
            setReportName={setReportName}
            reportType={reportType}
            setReportType={setReportType}
            frequency={frequency}
            setShowFrequencyModal={setShowFrequencyModal}
            showFrequencyModal={showFrequencyModal}
            saveFrequencyOptions={saveFrequencyOptions}
            frequencyOptions={frequencyOptions}
            show={show}
            reportStatus={reportStatus}
            setReportStatus={setReportStatus}
            presentTimeFrame={presentTimeFrame}
            setPresentTimeFrame={setPresentTimeFrame}
            showScheduledReportDialog={showScheduledReportDialog}
            setEmails={(emails) => {
              setEmails([...emails]);
            }}
            presentDateRange={presentDateRange}
            setPresentDateRange={setPresentDateRange}
            currentDate={currentDate}
            runTimeFrame={runTimeFrame}
            setRunTimeFrame={setRunTimeFrame}
            runDateRange={runDateRange}
            setRunDateRange={setRunDateRange}
            mediaPartnerList={mediaPartnerList}
            mediaPartnerListRef={mediaPartnerListRef}
            mediaPartner={mediaPartner}
            setMediaPartner={setMediaPartner}
            setMediaPartnerList={setMediaPartnerList}
            emails={emails}
            piiMaskChoice={piiMaskChoice}
            setPiiMaskChoice={setPiiMaskChoice}
            headerIntValue={headerIntValue}
            setHeaderIntValue={setHeaderIntValue}
            selectedDedupeVariables={selectedDedupeVariables}
            setSelectedDedupeVariables={setSelectedDedupeVariables}
            selectedOutputFormat={selectedOutputFormat}
            setSelectedOutputFormat={setSelectedOutputFormat}
            respondentDataTimeframeOptions={respondentDataTimeframeOptions}
            placement={placement}
            setPlacement={setPlacement}
            placementList={placementList}
            setPlacementList={setPlacementList}
            placementListRef={placementListRef}
          />
        }
        hideCloseBtn={true}
        size='otp'
        accept={save}
        acceptBtnText='Save'
        disableAcceptBtn={isSaveButtonDisabled || saving}
        reject={closeDialog}
        buttonUI='scheduledReport'
      />
      <CommonModal
        size='md'
        header='Warning'
        show={showFrequencyWarnModal}
        hideCloseBtn={true}
        body={<RenderFreqErrModalBody />}
        acceptBtnText='Continue'
        accept={() => setShowFrequencyWarnModal(false)}
        buttonUI='scheduledReportError'
      />

      <CommonModal
        show={showSuccessModal}
        header='Success'
        body={<SuccessBodyJSX />}
        hideCloseBtn={true}
        size='otp'
        acceptBtnText='Ok'
        accept={() => setShowSuccessModal(false)}
      />
      <CommonModal
        show={showReachAndFrequencyWarning}
        header='Warning'
        body={<RenderReachAndFrequencyWarning />}
        hideCloseBtn={true}
        size='otp'
        acceptBtnText='Ok'
        accept={() => {
          setReportType(reportTypeOptions[0])
          setShowReachAndFrequencyWarning(false)
        }}
      />
    </div>
  );
};

export default ScheduledReportModal;

const RenderModalBody = ({
  campaign,
  reportName,
  setReportName,
  reportType,
  setReportType,
  frequency,
  setShowFrequencyModal,
  showFrequencyModal,
  saveFrequencyOptions,
  frequencyOptions,
  show,
  reportStatus,
  setReportStatus,
  presentTimeFrame,
  setPresentTimeFrame,
  showScheduledReportDialog,
  setEmails,
  presentDateRange,
  setPresentDateRange,
  currentDate,
  runTimeFrame,
  setRunTimeFrame,
  runDateRange,
  setRunDateRange,
  mediaPartnerList,
  mediaPartnerListRef,
  mediaPartner,
  setMediaPartner,
  setMediaPartnerList,
  emails,
  piiMaskChoice,
  setPiiMaskChoice,
  headerIntValue,
  setHeaderIntValue,
  selectedDedupeVariables,
  setSelectedDedupeVariables,
  selectedOutputFormat,
  setSelectedOutputFormat,
  respondentDataTimeframeOptions,
  placement,
  setPlacement,
  placementList,
  setPlacementList,
  placementListRef,
}) => {
  return (
    <div className='scheduledReportBodyWrapper'>
      <Container fluid className='noMarginPadding'>
        <Row className='noMarginPadding elementAlignLeft '>
          <div className='col-4 scheduledReportElementWrapper'>
            <FormatLabel
              label='Report Name'
              isMandatory
              customClass='field-name'
            />
            <CommonInput
              label=''
              placeholder='Enter the name of the Report'
              inputValue={reportName}
              setInputValue={setReportName}
              className='scheduledReportField'
              uniqueIdentifier='scheduledReportName'
              maxLength={reportNameMaxLength}
            />
            {checkReportNameForSpecialCharacters(reportName) ? (
              <div className='createReportModalNameError'>
                Report Name Cannot Include the Following Characters: {'" / * : < > ? | + \' , . ; = [ ]'}
              </div>
            ) : reportName?.length >= reportNameMaxLength && (
              <div className='createReportModalNameError'>
                Report Name Is Limited To {reportNameMaxLength} Characters - Current: {reportName.length}
              </div>
            )}
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            <Row>
              <div className='col-7'>
                <FormatLabel label='Report Type' customClass='field-name' />
                <SearchableDropdownComponent
                  options={
                    campaign?.lift_report_enabled
                      ? reportTypeOptions
                      : [
                          reportTypeOptions[0],
                          { ...reportTypeOptions[1], isDisabled: true },
                          reportTypeOptions[2],
                          reportTypeOptions[3],
                          reportTypeOptions[4],
                          reportTypeOptions[5]
                        ]
                  }
                  label='name'
                  id='id'
                  dropdownId='createScheduledReportType'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Report Type'
                  selectedValue={reportType}
                  handleChange={setReportType}
                  isSearchable={false}
                  uniqueIdentifier='scheduledReportTypes'
                  ifShowDisabledOptions={true}
                  disabledOptionId='isDisabled'
                />
              </div>
              <div className='col-5'>
                <FormatLabel
                  value={reportStatus}
                  label='Status'
                  customClass='field-name'
                />
                <SearchableDropdownComponent
                  options={statusOptions}
                  label='name'
                  id='id'
                  dropdownId='createScheduledReportStatus'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Report Status'
                  selectedValue={reportStatus}
                  handleChange={setReportStatus}
                  isSearchable={false}
                  uniqueIdentifier='scheduledReportStatuses'
                />
              </div>
            </Row>
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            <FormatLabel
              label='Frequency'
              isMandatory
              customClass='field-name'
            />
            <CommonButtonComponent
              label={frequency}
              appendWrapperClassName='frequencyBtnWrapper'
              appendButtonClassName='frequencyButton'
              showLoader={false}
              onClickCTA={() => setShowFrequencyModal(true)}
              buttonUI='scheduledReportFrequencyModal'
            />
          </div>
        </Row>
        <Row className='noMarginPadding elementAlignLeft'>
          <div className='col-4 scheduledReportElementWrapper'>
            <FormatLabel label='Campaign' customClass='field-name' />
            <CommonInput
              label=''
              inputValue={campaign?.campaign_name ?? ''}
              className='scheduledReportField'
              disabled={true}
            />
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            {reportType.id !== 2 && frequencyOptions?.repeatEvery.key !== 1 && (
              <div>
                <FormatLabel
                  label='How Long Report Will Run'
                  isMandatory
                  customClass='field-name'
                />
                <SearchableDropdownComponent
                  options={runTimeFrameOptions}
                  label='name'
                  id='id'
                  dropdownId='scheduledReportRunTimeFrame'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Time Frame'
                  selectedValue={runTimeFrame}
                  handleChange={setRunTimeFrame}
                  isSearchable={false}
                  uniqueIdentifier='scheduledReportRunTimeFrames'
                  ifShowDisabledOptions={true}
                  disabledOptionId='isDisabled'
                />
              </div>
            )}
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            {reportType.id !== 2 && (
              <div>
                <FormatLabel
                  label='Days Worth of Data'
                  isMandatory
                  customClass='field-name'
                />
                <SearchableDropdownComponent
                  options={reportType?.id !== 5 ? presentTimeFrameOptions :
                   [presentTimeFrameOptions[0],
                    presentTimeFrameOptions[1],
                    presentTimeFrameOptions[3],
                    presentTimeFrameOptions[4],
                    presentTimeFrameOptions[5],
                    presentTimeFrameOptions[6],
                    presentTimeFrameOptions[7],
                    presentTimeFrameOptions[8],
                    presentTimeFrameOptions[9],
                    presentTimeFrameOptions[10],
                  ]}
                  label='name'
                  id='id'
                  dropdownId='scheduledReportTimeFrame'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Time Frame'
                  selectedValue={presentTimeFrame}
                  handleChange={setPresentTimeFrame}
                  isSearchable={false}
                  uniqueIdentifier='scheduledReportTimeFrames'
                  ifShowWarningAtOptions={true}
                  warningOptionId='isDisabled'
                  warningMessage={'Warning! ' + errAndWarnMsg.nullImpFreqWarn}
                />
              </div>
            )}
            {reportType.id === 2 && (
              <div>
                <FormatLabel
                  label='Preset Time Frame'
                  isMandatory
                  customClass='field-name'
                />
                <SearchableDropdownComponent
                  options={respondentDataTimeframeOptions}
                  label='label'
                  id='value'
                  dropdownId='scheduledReportTimeFrameForRespondentData'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Time Frame'
                  selectedValue={presentTimeFrame}
                  handleChange={setPresentTimeFrame}
                  isSearchable={false}
                  uniqueIdentifier='scheduledReportTimeFrames'
                  ifShowWarningAtOptions={true}
                  warningOptionId='isDisabled'
                  warningMessage={'Warning! ' + errAndWarnMsg.nullImpFreqWarn}
                />
              </div>
            )}
          </div>
        </Row>
        <Row className='noMarginPadding elementAlignLeft'>
          <div className='col-4 scheduledReportElementWrapper' id="scheduledReportPlacements">
            {(reportType.id === 3 || reportType.id === 4) && (
              <div className="scheduledReportPlacementsWrapper">
                <div>
                  <FormatLabel
                      label='Placements'
                      isMandatory
                      customClass='field-name'
                  />
                </div>
                <div>
                  <MultiselectDropdownComponent
                dropdownId='placementSRM'
                dropdownDataList={placementList}
                dataId="placement_id"
                dataName="placement_name"
                dataType="type"
                dropdownData={placement}
                setDropdownData={(data) => {
                  if(data){
                    setPlacement(data)
                  } else {
                    setPlacementList(placementListRef.current 
                      ? [...placementListRef.current] : null)
                  }
                }}
                ifIdSearchAvailable={true}
                txtSearch="Search by placement name"
                isAllCheckedInitially={checkSelectAll(
                  placementList,
                  placement,
                  'placement_id'
                )}
                dropdownWidth={{
                  width: '312px',
                  minWidth: '190px'
                }}
                isLoading={false}
                isDisabled={false}
                uniqueIdentifier='reportsScheduledReportModalPlacements'
                ifIdVisibleOnLabel={true}
              />
                </div>
              </div>
              
            )}
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            {runTimeFrame?.id === 0 && reportType.id !== 2 && frequencyOptions?.repeatEvery.key !== 1 && (
              <div>
                <FormatLabel
                  label='Custom Run Date Range'
                  customClass='field-name'
                  isMandatory={true}
                />
                <DatePickerComponent
                  datePickerId='scheduledReportDateRange'
                  label=''
                  labelWidth={{ padding: 0, margin: 0 }}
                  datePickerBodyWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Start Date - End Date'
                  minDate={currentDate}
                  maxDate={null}
                  isDateRangePicker={true}
                  dateRange={runDateRange}
                  setDateRange={setRunDateRange}
                  isDisabled={false}
                  format='MM/DD/YYYY'
                  currentYear={moment(currentDate).year()}
                  startYear={moment(currentDate).year() - 1}
                  futureYear={moment(currentDate).year() + 5}
                  futureYearNeeded={true}
                  uniqueIdentifier='scheduledReportRun'
                />
              </div>
            )}
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            {(presentTimeFrame?.id === 0 ||
              presentTimeFrame?.value === '14') && (
              <div>
                <FormatLabel
                  label='Custom Date Range'
                  customClass='field-name'
                  isMandatory={presentTimeFrame?.id === 0}
                />
                <DatePickerComponent
                  datePickerId='scheduledReportDateRange'
                  label=''
                  labelWidth={{ padding: 0, margin: 0 }}
                  datePickerBodyWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Start Date - End Date'
                  minDate={campaign?.start_date}
                  maxDate={campaign?.lookback_date}
                  isDateRangePicker={true}
                  dateRange={presentDateRange}
                  setDateRange={setPresentDateRange}
                  isDisabled={
                    presentTimeFrame?.id !== 0 &&
                    presentTimeFrame?.value !== '14'
                  }
                  format='MM/DD/YYYY'
                  currentYear={checkCondition(
                    campaign &&
                      daysToFormatWRTDates(campaign?.lookback_date) <= 0,
                    moment(campaign?.lookback_date).year(),
                    moment(currentDate).year()
                  )}
                  startYear={checkCondition(
                    campaign,
                    moment(campaign?.start_date).year() - 1,
                    2010
                  )}
                  uniqueIdentifier='scheduledReport'
                />
              </div>
            )}
          </div>
        </Row>
        <Row>
          <div className='col-12 scheduledReportElementWrapper container'>
            <Row>
              {showScheduledReportDialog && (
                <>
                  <div className='col-6'>
                    {(reportType.id !=1 && reportType.id !== 2 && reportType.id !== 5) && (
                      <>
                      <FormatLabel
                        label='Media Partner'
                        isMandatory
                        customClass='field-name'
                      />
                      <MultiselectDropdownComponent
                        dropdownId='mediaPartnerSRM'
                        dropdownDataList={mediaPartnerList}
                        dataId='partner_id'
                        isMandatory
                        isDisabled={reportType?.id === 1}
                        dataName='partner_name'
                        dropdownData={mediaPartner}
                        setDropdownData={(data) => {
                          if (data) {
                            setMediaPartner(data ?? []);
                          } else {
                            setMediaPartnerList(
                              mediaPartnerListRef.current
                                ? [...mediaPartnerListRef.current]
                                : null
                            );
                            getNotification(
                              'warning',
                              'Media Partners list can not be empty!'
                            );
                          }
                        }}
                        ifIdSearchAvailable={true}
                        placeholder={setPlaceHolderLabel(
                          false,
                          'Media Partner'
                        )}
                        txtSearch='Search by name of the partner'
                        isAllCheckedInitially={
                          JSON.stringify(mediaPartner) ==
                          JSON.stringify(mediaPartnerList)
                        }
                        labelWidth={{ width: 0 }}
                        dropdownWidth={{
                          width: 'calc(100%)',
                          minWidth: '162px',
                        }}
                        uniqueIdentifier='reportsCreateReportPartners'
                      />  
                    </>)}
                  </div>
                </>
              )}
              <div className={'col-6'}>
                <FormatLabel
                  label='Recipient'
                  isMandatory
                  customClass='field-name'
                />
                <MultiRecipients emails={emails} setEmails={setEmails} />
              </div>
            </Row>
          </div>
        </Row>

        {reportType.id === 2 && (
          <div
            className='noMarginPadding elementAlignLeft row'
            id='respondentDataFields'
          >
            <div className='col-2'>
              <FormatLabel
                label='Include PII?'
                isMandatory
                customClass='field-name'
              />
              <SearchableDropdownComponent
                options={piiMaskList}
                label='label'
                id='value'
                dropdownId='createPiiMask'
                dropdownLabel=''
                labelWidth={{ margin: 0 }}
                dropdownWidth={{
                  width: '100%',
                }}
                placeholderLabel='PII Masking'
                selectedValue={piiMaskChoice}
                handleChange={setPiiMaskChoice}
                isSearchable={false}
                uniqueIdentifier='piiMaskingOptions'
                ifShowDisabledOptions={true}
                disabledOptionId='isDisabled'
              />
            </div>
            <div className='col-3'>
              <FormatLabel
                // value={reportStatus}
                label='Include Column Headers?'
                isMandatory
                customClass='field-name'
              />
              <SearchableDropdownComponent
                options={headerChoiceList}
                label='label'
                id='value'
                dropdownId='createHeaderChoice'
                dropdownLabel=''
                labelWidth={{ margin: 0 }}
                dropdownWidth={{
                  width: '100%',
                }}
                placeholderLabel='Include Column Headers?'
                selectedValue={headerIntValue}
                handleChange={setHeaderIntValue}
                isSearchable={false}
                uniqueIdentifier='headerChoiceList'
              />
            </div>
            <div className='col-3'>
              <FormatLabel
                label='Output Format'
                isMandatory
                customClass='field-name'
              />
              <SearchableDropdownComponent
                options={outputFormatList}
                label='label'
                id='value'
                dropdownId='createOutputFormat'
                dropdownLabel=''
                labelWidth={{ margin: 0 }}
                dropdownWidth={{
                  width: '100%',
                }}
                placeholderLabel='Select Output Format'
                selectedValue={selectedOutputFormat}
                handleChange={setSelectedOutputFormat}
                isSearchable={false}
                uniqueIdentifier='outputFormatList'
                ifShowDisabledOptions={true}
                disabledOptionId='isDisabled'
              />
            </div>
            <div className='col-4' id='multiSelectSchedReports'>
              <FormatLabel
                // value={reportStatus}
                label='Dedupe By (select up to 3)'
                customClass='field-name'
              />
              <NewMultiSelect
                prefix='dedupeList'
                options={dedupeVariableList}
                dataId='id'
                dataName='label'
                selected={selectedDedupeVariables}
                setSelected={setSelectedDedupeVariables}
                isMaxSelect={true}
                selectUpto={3}
                placeholderTxt='Dedupe Variables'
                label=''
                dropDownId='reportsDedupeVariableList'
                componentWrapperStyle={{
                  width: '260px',
                }}
                isLoading={false}
                isDisable={false}
              />
            </div>
          </div>
        )}
      </Container>
      {show && (
        <SetFrequencyModal
          show={showFrequencyModal}
          header='Set Frequency'
          bodyType='set-frequency'
          accept={(selectedOptions) => saveFrequencyOptions(selectedOptions)}
          reject={() => setShowFrequencyModal(false)}
          acceptBtnText='Save'
          size='sm'
          hideCloseBtn
          frequencyOpt={frequencyOptions}
          uniqueIdentifier='scheduledReportFrequencyModal'
          reportType={reportType.id}
        />
      )}
    </div>
  );
};

const RenderFreqErrModalBody = () => {
  return (
    <div className='freqErrModalBodyWrapper'>
      {errAndWarnMsg.nullImpFreqWarn}
    </div>
  );
};

export const checkReportNameForSpecialCharacters = (reportName) => {
  const regex = /[/*:<>?|+'.,;=[\]"]/;
  return regex.test(reportName);
}

const SuccessBodyJSX = () => {
  return (
    <div className='savingErrorWrapper'>
      <div>Your report has been saved successfully.</div>
    </div>
  );
};

const RenderReachAndFrequencyWarning = () => {
  return (
    <div className='freqErrModalBodyWrapper'>
      {errAndWarnMsg.reachAndFrequencyWarning}
    </div>
  );
};

export const getStartAndEndDatesForRunTimeFrames = (id, cd, ced, clbd, cld) => {
  // Calculation for Campaign Dates
  if (id === 1) {
    return [cd, addTimeFrames(ced, 1, 'd')];
  }

  // Calculation for Campaign Dates + Lookback Period
  if (id === 2) {
    return [cd, addTimeFrames(clbd, 1, 'd')];
  }

  // Calculation for Campaign Dates + Lookback + Lift Period
  if (id === 3) {
    return [cd, addTimeFrames(cld, 1, 'd')];
  }

  // Calculation for Next 7 Days
  if (id === 4) {
    return [cd, addTimeFrames(cd, 7, 'd')];
  }

  // Calculation for Next 1 month
  if (id === 5) {
    return [cd, addTimeFrames(cd, 1, 'M')];
  }

  // Calculation for Next 3 months
  if (id === 6) {
    return [cd, addTimeFrames(cd, 3, 'M')];
  }

  // Calculation for Next 1 quarter from current date
  if (id === 7) {
    const nextQuarter = moment(cd).quarter() + 1;

    if (nextQuarter === 5) {
      const nextYear = addTimeFrames(cd, 1, 'y');

      return [cd, moment(nextYear).quarter(1).endOf('Q').format('YYYY-MM-DD')];
    }

    return [cd, moment().quarter(nextQuarter).endOf('Q').format('YYYY-MM-DD')];
  }

  // Calculation for Next 6 months
  if (id === 8) {
    return [cd, addTimeFrames(cd, 6, 'M')];
  }

  // Calculation for Next 1 Year
  if (id === 9) {
    return [cd, addTimeFrames(cd, 1, 'y')];
  }

  // Calculation for Next 2 Year
  if (id === 10) {
    return [cd, addTimeFrames(cd, 2, 'y')];
  }

  return [null, null];
};

const disableRunOpts = (id, cd, cld, ced = null, clbd = null) => {
  if (id === 1) {
    return moment(ced).diff(cd, 'd') <= 0;
  }

  if (id === 2) {
    return moment(clbd).diff(cd, 'd') <= 0;
  }

  if (id === 3) {
    return moment(cld).diff(cd, 'd') <= 0;
  }
};

export const getUpdatedRunDateRangeBasedOnFrequency = (fo, rdr, setRdr) => {
  const { repeatEvery, selectedDate, selectedDays, selectedRecurrence } = fo,
    selectedDaysArray = selectedDays.sort((a, b) => a - b),
    firstDay = moment(rdr[0]).day() + 1 > 7 ? 1 : moment(rdr[0]).day() + 1,
    endDay = moment(rdr[1]).day();

  // For selected frequency is Weekly or Every Business Days
  if (repeatEvery.key === 6) {
    const dayDiff = selectedDaysArray.find((item) => item >= endDay),
      daysNeedToBeAdded = dayDiff
        ? dayDiff - endDay
        : 7 - endDay + selectedDaysArray[0];

    setRdr(addTimeFrames(rdr[1], daysNeedToBeAdded, 'd'));
    return;
  }

  // For selected frequency is Bi-Weekly
  if (repeatEvery.key === 7) {
    const firstWeek = 7 - firstDay > 0 ? 1 : 0,
      totalDays = moment(rdr[1]).diff(moment(rdr[0]), 'd'),
      totalDaysExcept1stWeek = totalDays - 8 + firstDay,
      lastWeek = totalDaysExcept1stWeek % 7 > 0 ? 1 : 0,
      totalWeeks = firstWeek + parseInt(totalDaysExcept1stWeek / 7) + lastWeek;

    if (totalWeeks % 2 === 0) {
      setRdr(addTimeFrames(rdr[1], 7 - endDay + selectedDaysArray[0], 'd'));
      return;
    }

    const dayDiff = selectedDaysArray.find((item) => item >= endDay),
      daysNeedToBeAdded = dayDiff
        ? dayDiff - endDay
        : 14 - endDay + selectedDaysArray[0];

    setRdr(addTimeFrames(rdr[1], daysNeedToBeAdded, 'd'));
    return;
  }

  // For selected frequency is Monthely
  if (repeatEvery.key === 4) {
    if (selectedDate > 0) {
      const { newSelectedDay, newSelectedDate } = lastDayCalculationForMonths(
        selectedDate,
        rdr[1]
      );

      if (newSelectedDay < +moment(rdr[1]).format('DD')) {
        const updatedDate = lastDayCalculationForMonths(
          selectedDate,
          addTimeFrames(rdr[1], 1, 'M')
        );

        setRdr(updatedDate.newSelectedDate);
        return;
      }

      setRdr(newSelectedDate);
      return;
    }

    const selectedDateOftheMonth = calculationForRecurrence(
      selectedRecurrence,
      selectedDays,
      rdr[1]
    );

    if (moment(selectedDateOftheMonth).diff(rdr[1], 'd') <= 0) {
      setRdr(
        calculationForRecurrence(
          selectedRecurrence,
          selectedDays,
          addTimeFrames(rdr[1], 1, 'M')
        )
      );
      return;
    }

    setRdr(selectedDateOftheMonth);
    return;
  }

  setRdr(rdr[1]);
};

const lastDayCalculationForMonths = (selectedDate, endDate) => {
  const monthAndYear = moment(endDate).format('YYYY-MM'),
    endDaysOfEndDateMonth = moment(`${monthAndYear}`, 'YYYY-MM').daysInMonth(),
    newDateObj = {
      newSelectedDay: selectedDate,
      newSelectedDate: `${monthAndYear}-${selectedDate}`,
    };

  if (endDaysOfEndDateMonth < selectedDate) {
    return {
      ...newDateObj,
      newSelectedDay: endDaysOfEndDateMonth,
      newSelectedDate: `${monthAndYear}-${endDaysOfEndDateMonth}`,
    };
  }

  return newDateObj;
};

const calculationForRecurrence = (
  selectedRecurrence,
  selectedDays,
  endDate
) => {
  let firstSelectedDay = moment(endDate)
    .startOf('month')
    .day(selectedDays[0])
    .format('YYYY-MM-DD');

  if (moment(firstSelectedDay).month() < moment(endDate).month()) {
    firstSelectedDay = addTimeFrames(firstSelectedDay, 7, 'd');
  }

  if (selectedRecurrence === 1) {
    return firstSelectedDay;
  }

  if (selectedRecurrence === 5) {
    const lastSelectedDay = moment(endDate)
      .endOf('month')
      .day(selectedDays[0])
      .format('YYYY-MM-DD');

    if (moment(lastSelectedDay).month() > moment(endDate).month()) {
      return subtractTimeFrames(lastSelectedDay, 7, 'd');
    }
    return lastSelectedDay;
  }

  return addTimeFrames(firstSelectedDay, 7 * (selectedRecurrence - 1), 'd');
};
