import React, { useEffect, useState } from "react";
import {
  CommonHeadingComponent,
  CommonTablesComponent,
  ErrorComponent,
  NoDataComponent,
  CommonSearch,
  CommonButtonComponent,
} from "../../../../components";
import axios from "axios";
import { toggleClasses } from "../../../../utils/functions";
import columnsWithCTA from "./goalsDataGridColumn";
import { baseEndpoints } from "../../../../constants";
import { axiosGetRequest } from "../../../../services/http/axiosCalls";
import GoalEdit from "../addEditGoal/GoalEdit.component";
import "./goalsDataGrid.css";

const GoalsDataGrid = ({ propertyId, setGoalsReload = () => {} }) => {
  const [expandOrCollapse, setExpandOrCollapse] = useState(true);

  const [isLoading, setIsLoading] = useState(false),
    [isError, setIsError] = useState(false),
    [goalsEditDialogActive, setGoalsEditDialogActive] = useState(false),
    [reloadTrigger, setReloadTrigger] = useState(false),
    [selectedGoalId, setSelectedGoalId] = useState(0),
    [isNoDataLoad, setIsNoDataLoad] = useState(false);

  const [cancelController, setCancelController] = useState(null);

  const [goalList, setGoalList] = useState([]),
    [totalData, setTotalData] = useState(0),
    [status, setStatus] = useState(200);

  const [sortData, setSortData] = useState({
    sortField: "goal_name",
    order: "asc",
  });

  const [pageSize, setPageSize] = useState(10),
    [pageNumber, setPageNumber] = useState(1);

  const [searchInput, setSearchInput] = useState(""),
    [searchInputCall, setSearchInputCall] = useState("");

  const getData = async (controller) => {
    if (isNoDataLoad) {
      setIsNoDataLoad(false);
      return;
    }
    setIsLoading(true);
    try {
      const data = await axiosGetRequest(
        `${baseEndpoints.properties}/${propertyId}/goals`,
        controller.signal,
        {
          page_number: pageNumber,
          page_size: pageSize,
          column: sortData?.sortField,
          order: sortData?.order,
          search_text: searchInputCall,
        }
      );

      setStatus(data.status);
      if (data.status === 200) {
        let { table_data, total_data } = data.data;
        setGoalList(table_data);
        setTotalData(total_data);
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        if (err.response) {
          setStatus(err.response.status);
        } else {
          setStatus(500);
        }
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderComponent = () => {
    return isError ? (
      <ErrorComponent
        refreshAction={getData}
        controller={cancelController}
        status={status}
      />
    ) : (
      <div className='goalListInnerWrapper'>
        {renderTable()}
      </div>
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    setCancelController(controller);
    getData(controller);
    return () => {
      controller.abort();
    };
  }, [pageSize, pageNumber, sortData, searchInputCall, reloadTrigger]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchInputCall(searchInput);
      setPageNumber(1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  const renderTable = () => {
    return !goalList && !expandOrCollapse ? (
      <NoDataComponent
        type='table'
        width='180'
        height='180'
        messageTextFontSize={15}
        foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
        messageText='No Data Available'
      />
    ) : (
      !expandOrCollapse && (
        <CommonTablesComponent
          uniqueId='goalsList'
          data={goalList ?? []}
          columns={columnsWithCTA((goalId)=>{
            setSelectedGoalId(goalId);
            setGoalsEditDialogActive(true);
          })}
          isLoading={isLoading}
          customTableHeader="Goal List"
          setSortData={setSortData} // sorting
          sortColumnIds={["goal_id", "goal_name"]} // sorting
          defaultSortingApplied={{ column: "goal_name", order: "asc" }} // sorting
          isPaginationApplied={true}
          totalDataCount={totalData}
          pageIndex={pageNumber}
          setPageIndex={setPageNumber}
          dataPerPage={pageSize}
          paginationOptions={{
            noRowsPerPage: false,
          }}
          setDataPerPage={setPageSize}
          noDataMessage='No Data Available'
          noDataCompWidth='180'
          noDataCompHeight='180'
          noDataMessageFontSize={15}
          foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
        />
      )
    );
  };
    /**console.log("pageSize");
    console.log(pageSize);
    console.log("totalData");
    console.log(totalData);
    console.log("goalList");
    console.log(goalList);*/
  return (
    <div
      className={toggleClasses(
        expandOrCollapse,
        "goalsDataGridWrapper",
        "goalsDataGridWrapper expand"
      )}
    >
      <CommonHeadingComponent
        headingLabel="Goals"
        headingCTALabel={!expandOrCollapse ? "COLLAPSE" : "EXPAND"}
        isDisabled={false}
        onClickCTA={() => setExpandOrCollapse(!expandOrCollapse)}
        uniqueIdentifier='propertyDetailsAllGoalsCollapsable'
      />
      {!expandOrCollapse && (
        <>
          {goalsEditDialogActive ? (
            <GoalEdit
              propertyId={propertyId}
              goalId={selectedGoalId}
              setSearchInput={setSearchInput}
              navigateBackCallback={(reload, sortNewGoalOnTop) => {
                setGoalsEditDialogActive(false);
                if (sortNewGoalOnTop){
                  setSortData({
                    sortField: "goal_id",
                    order: "desc",
                  });
                  setPageNumber(1);
                }
                if (reload === true){
                  setReloadTrigger(!reloadTrigger);
                }

              }}
              setGoalsReload={setGoalsReload}
            />
          ) : (
            <>
              <div className=" goalGridSubheaderWrapper">
                <div className="d-flex">
                  <CommonSearch
                    placeholder={"Find a goal"}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    customStyle={
                      searchInput.length > 0 ? { fontStyle: "normal" } : {}
                    }
                    uniqueIdentifier='propertyDetailsAllGoals'
                  />
                </div>
                <CommonButtonComponent
                  label="Create New Goal"
                  appendWrapperClassName="newGoalBtnWrapper"
                  appendButtonClassName="newGoalBtnBody"
                  onClickCTA={() => {
                    setSearchInput('');
                    setSelectedGoalId(null);
                    setGoalsEditDialogActive(true);
                  }}
                  buttonUI='propertyDetailsCreateGoal'
                />
              </div>
              {renderComponent()}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GoalsDataGrid;
