import React from 'react';
import { formatNumberInThousands } from '../../../../../../utils/functions';

export default (cta) =>{
  return [
  {
    id: 'name',
    name: 'IVT Category',
    selector: (row) => (
      <div className='tableCellFontStyle capitalizeCell'>{row.name}</div>
    ),
    sortable: false, // sorting
    minWidth: '400px !important',
  },
  {
    id: 'Count',
    name: 'Count',
    selector: (row) => (
      <div>{formatNumberInThousands(row.Count)}</div>
    ),
    sortable: true,
    sortField: 'Count',
    minWidth: '215px !important',
    style: { justifyContent: 'flex-end !important' },
    right: true,
  }
]}
