import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid,
  Legend,
} from 'recharts';
import moment from 'moment';
import { Container, Row, Col } from 'react-bootstrap';
import {
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  NoDataComponent,
  SearchableDropdownComponent,
} from '../../../../../../components';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../../../constants';
import {
  approximateNumberToMils,
  getAllSelectedOrNot,
  getArrayOfData,
  getGoalListAndType,
} from '../../../../../../utils/functions';

import './dailyEngagementsConversionsReport.css';

const groupingOptions = [
  { id: 1, name: 'Daily' },
  { id: 2, name: 'Weekly' },
  { id: 3, name: 'Monthly' },
];

const DailyEngagementsConversionsReport = ({
  campaign,
  startDate,
  endDate,
  mediaPartnerList,
  mediaPartner,
  placementList,
  placement,
  creativeTypeList,
  creativeType,
  parametersIsLoading,
  ifSelectedAll,
}) => {
  const [goalData, setGoalData] = useState([]),
    [usableGoalData, setUsableGoalData] = useState([]),
    [goalList, setGoalList] = useState(null),
    [usableGoalListOpts, setUsableGoalListOpts] = useState([]),
    [selectedGoal, setSelectedGoal] = useState(null),
    [isInitialLoading, setIsInitialLoading] = useState(true),
    [grouping, setGrouping] = useState(groupingOptions[0]);

  const [isLoadingData, setIsLoadingData] = useState(true),
    [isLoadingList, setIsLoadingList] = useState(true),
    [isError, setIsError] = useState(false),
    [cancelController, setCancelController] = useState(null);

  const getImpressions = async (controller, isFetchingAllData = true) => {
    setIsLoadingData(true);
    if (isFetchingAllData) {
      setIsInitialLoading(true);
      setIsLoadingList(true);
    }
    setIsError(false);
    try {
      const { goal_id, goal_type } = getGoalListAndType(selectedGoal),
        impressionDataPromise = axiosSendRequest(
          `${baseEndpoints.reports}/attribution-tab/engagements-conversions-daily`,
          controller,
          {
            campaign_id: campaign.campaign_id,
            if_all_partners: getAllSelectedOrNot(mediaPartnerList, mediaPartner, 'partner_id'),
            if_all_placements: getAllSelectedOrNot(placementList, placement, 'placement_id'),
            if_all_creative_types: getAllSelectedOrNot(creativeTypeList, creativeType, 'value'),
            partner_id: getArrayOfData(mediaPartner, 'partner_id'),
            placement_id: getArrayOfData(placement, 'placement_id'),
            start_date: startDate,
            end_date: endDate,
            goal_type,
            goal_id,
          }
        );
      let goalListResponse = { status: 200 };

      if (isFetchingAllData) {
        try {
          const goalListPromise = axiosSendRequest(
            `${baseEndpoints.reports}/goal-list`,
            controller,
            {
              campaign_id: campaign.campaign_id,
              placement_id: getArrayOfData(placement, 'placement_id'),
              start_date: startDate,
              end_date: endDate,
            }
          );

          goalListResponse = await goalListPromise;
        } catch(e) {
          goalListResponse = { status: 400 };
          if (!axios.isCancel(e)) {
            console.log(e.message);
          }
        }
      }

      const impressionDataResponse = await impressionDataPromise;

      if (
        impressionDataResponse.status === 200 &&
        goalListResponse.status === 200
      ) {
        const impressionData = impressionDataResponse.data?.impressions;

        if (isFetchingAllData) {
          setGoalList(goalListResponse.data);
        }

        setGoalData(impressionData);
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err.message);
      }
    } finally {
      setTimeout(() => {
        setIsLoadingData(false);
        setIsLoadingList(false);
      }, 100);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setCancelController(controller);
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      resetData();
    }

    if (
      !parametersIsLoading.campaign &&
      !parametersIsLoading.mediaPartner &&
      !parametersIsLoading.creativeType &&
      !parametersIsLoading.placement &&
      startDate &&
      endDate
    ) {
      getImpressions(cancelController);
    }
  }, [startDate, endDate, placement]);

  useEffect(() => {
    const generatedGoalList = generateUsableGoalList(goalList);

    setUsableGoalListOpts(generatedGoalList);

    if (generatedGoalList?.length) {
      setSelectedGoal(generatedGoalList[0]);
    }
  }, [goalList]);

  useEffect(() => {
    if (selectedGoal) {
      if (!isInitialLoading) {
        getImpressions(cancelController, false);
      } else {
        setIsInitialLoading(false);
      }
    }
  }, [selectedGoal]);

  useEffect(() => {
    if (isError) {
      resetData();
    }
  }, [isError]);

  useEffect(() => {
    if (goalData?.length) {
      setUsableGoalData(
        calcImpressions(goalData, startDate, endDate, grouping)
      );
    } else {
      calcImpressions([]);
    }
  }, [grouping, goalData]);

  const resetData = () => {
    setGoalData([]);
    setGoalList(null);
    setSelectedGoal(null);
    setUsableGoalListOpts([]);
    setIsInitialLoading(null);
    setGrouping(groupingOptions[0]);
    setUsableGoalData([]);
  };

  return (
    <div className='engagementAndConversionWrapper'>
      <CommonHeadingComponent
        headingLabel='Engagements & Conversions'
        headingCTALabel={
          <HeadeingSearchField
            goalList={usableGoalListOpts}
            selectedGoal={selectedGoal}
            setSelectedGoal={setSelectedGoal}
            isLoading={isLoadingList}
            ifSelectedAll={ifSelectedAll}
            parametersIsLoading={parametersIsLoading}
          />
        }
        ifDropdown={true}
      />
      <Container fluid>
        <Row>
          <Col>
            <SearchableDropdownComponent
              options={groupingOptions}
              label='name'
              id='id'
              dropdownId='attrEngagementAndConversionGrp'
              dropdownLabel='View'
              labelWidth={{ width: '40px', margin: 0 }}
              dropdownWidth={{
                width: '100px',
                margin: '0 25px',
              }}
              wrapperClass='engagementAndConversionGrpDropdown'
              placeholderLabel=''
              selectedValue={grouping}
              handleChange={setGrouping}
              isSearchable={false}
              ifShowTitle={false}
              isDisabled={!usableGoalData?.length}
              uniqueIdentifier='attrEngagementAndConversionGroups'
            />
          </Col>
        </Row>
        <RenderComponent
          isError={isError}
          isLoading={isLoadingData}
          getImpressions={getImpressions}
          cancelController={cancelController}
          goalData={usableGoalData}
          selectedGoal={selectedGoal}
          ifSelectedAll={ifSelectedAll}
          parametersIsLoading={parametersIsLoading}
        />
      </Container>
    </div>
  );
};

export default DailyEngagementsConversionsReport;

const calcImpressions = (data, startDate, endDate, grouping) => {
  let tmp = [];
  if (data && data.length > 0) {
    tmp = data.map((element) => {
      const Engagements = parseFloat(element.engagements);
      const Conversions = parseFloat(element.conversions);
      return {
        date: element.date.substr(0, 10),
        name: moment(element.date).format('MM/DD/YYYY'),
        Engagements,
        Conversions,
      };
    });
    tmp = tmp.filter((item) => item.date >= startDate && item.date <= endDate);

    let dataGrouped;
    switch (grouping.id) {
      case 1:
        break;
      case 2:
        dataGrouped = tmp.reduce((acc, item) => {
          // create a composed key: 'year-week'
          const yearWeek = `${moment(item.name)
            .startOf('week')
            .format('MMM DD')} - ${moment(item.name)
            .endOf('week')
            .format('MMM DD')} ${moment(item.name).year()}`;

          // add this key as a property to the result object
          if (!acc[yearWeek]) {
            acc[yearWeek] = {
              Engagements: 0,
              Conversions: 0,
              name: yearWeek,
            };
          }

          // push the current date that belongs to the year-week calculated befor
          acc[yearWeek]['Engagements'] += item['Engagements'];
          acc[yearWeek]['Conversions'] += item['Conversions'];

          return acc;
        }, {});
        tmp = [];
        for (let key in dataGrouped) {
          tmp.push(dataGrouped[key]);
        }
        break;
      case 3:
        dataGrouped = tmp.reduce((acc, item) => {
          // create a composed key: 'year-week'
          const yearMonth = `${moment(item.name)
            .month('week')
            .format('MMM')} ${moment(item.name).year()}`;

          // add this key as a property to the result object
          if (!acc[yearMonth]) {
            acc[yearMonth] = {
              Engagements: 0,
              Conversions: 0,
              name: yearMonth,
            };
          }

          // push the current date that belongs to the year-week calculated befor
          acc[yearMonth]['Engagements'] += item['Engagements'];
          acc[yearMonth]['Conversions'] += item['Conversions'];

          return acc;
        }, {});
        tmp = [];
        for (let key in dataGrouped) {
          tmp.push(dataGrouped[key]);
        }
        break;
    }

    if (tmp.length > 10) {
      tmp = tmp.slice(tmp.length - 10, tmp.length);
    }

    tmp = tmp.map((item) => {
      const tempEngagements = Math.round(item['Engagements']),
        tempConversions = Math.round(item['Conversions']);

      return {
        ...item,
        Engagements: tempEngagements,
        Conversions: tempConversions,
      };
    });
    
    return tmp;
  }
};

const generateUsableGoalList = (goalList) => {
  if (!goalList?.length) {
    return [];
  }

  const allGoalOption = [
      { id: -1, name: 'All Goals', type: null, isParent: true },
    ],
    engagementOptions = [
      {
        id: -2,
        name: 'Engagement Goals',
        type: 'engagement',
        isParent: true,
      },
    ],
    conversionOptions = [
      {
        id: -3,
        name: 'Conversion Goals',
        type: 'conversion',
        isParent: true,
      },
    ];

  goalList.map((goal) => {
    if (goal.type === 'engagement') {
      engagementOptions.push({ ...goal, isParent: false });
    } else {
      conversionOptions.push({ ...goal, isParent: false });
    }
  });

  const newGoalOptions = allGoalOption.concat(
    engagementOptions.length > 1 ? engagementOptions : [],
    conversionOptions.length > 1 ? conversionOptions : []
  );

  if (newGoalOptions.length > 1) {
    return newGoalOptions;
  }

  return [];
};

const determineSummaryHeaderLoading = (parametersIsLoading, ifSelectedAll) => {
  return Object.values(parametersIsLoading).includes(true) || !ifSelectedAll;
};

const RenderComponent = ({
  isError,
  isLoading,
  getImpressions,
  cancelController,
  goalData,
  selectedGoal,
  ifSelectedAll,
  parametersIsLoading,
}) => {
  if (
    isLoading ||
    determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
  ) {
    return (
      <Row>
        <Col>
          <LoaderComponent headerClass='engagementsAndConversionsLoaderWrapper' />
        </Col>
      </Row>
    );
  }

  if (isError) {
    return (
      <Row>
        <Col style={{ height: '20.5em' }}>
          <ErrorComponent
            refreshAction={getImpressions}
            controller={cancelController}
          />
        </Col>
      </Row>
    );
  }

  if (!goalData?.length) {
    return (
      <Row>
        <Col>
          <NoDataComponent
            type='bar'
            width='180'
            height='180'
            messageTextFontSize={15}
            foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
            messageText='No Data Available'
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col style={{ height: '20em' }}>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart width={500} height={700} data={goalData}>
            <CartesianGrid vertical={false} strokeDasharray='3 3' />
            <XAxis dataKey='name' minTickGap={10} tickCount={5} />
            <YAxis
              tickCount={10}
              axisLine={false}
              domain={[0, (dataMax) => Math.ceil((dataMax * 1.5) / 100) * 100]}
              tickFormatter={approximateNumberToMils}
              width={80}
            />
            <Tooltip
              formatter={(value) => Intl.NumberFormat('en').format(value)}
            />
            <Legend iconType='square' />
            {(!selectedGoal?.type || selectedGoal?.type === 'engagement') && (
              <Bar
                dataKey='Engagements'
                width={24}
                stackId='e'
                fill='#03bdc9'
              />
            )}
            {(!selectedGoal?.type || selectedGoal?.type === 'conversion') && (
              <Bar dataKey='Conversions' stackId='c' fill='#ff7c1f' />
            )}
          </BarChart>
        </ResponsiveContainer>
      </Col>
    </Row>
  );
};

const HeadeingSearchField = ({
  goalList,
  selectedGoal,
  setSelectedGoal,
  isLoading,
  ifSelectedAll,
  parametersIsLoading,
}) => (
  <SearchableDropdownComponent
    options={goalList}
    label='name'
    id='id'
    dataType='goal'
    dropdownId='attrEngagementAndConversionGoal'
    dropdownLabel=''
    labelWidth={{ margin: 0 }}
    dropdownWidth={{
      width: '200px',
    }}
    wrapperClass=''
    placeholderLabel={goalList?.length ? 'Select Goal' : 'Goal unavailable'}
    selectedValue={selectedGoal}
    handleChange={setSelectedGoal}
    isSearchable={false}
    isLoading={
      isLoading ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    }
    isLoadingAvailable={2}
    loaderWidth={{ width: '22px' }}
    isDisabled={
      !goalList?.length ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    }
    isParentChildBasedOption={true}
    parentIdentificationKey='isParent'
    uniqueIdentifier='attrEngagementAndConversionGoals'
    ifIdVisableOnLabel={true}
  />
);
