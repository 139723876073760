import React, { useState, useEffect } from "react";
import { axiosPostRequest } from "../../services/http/axiosCalls";
import {
  CommonInput,
  CommonButtonComponent,
  LoaderComponent,
} from "../../components";
import { commonNotification } from "../../utils/common-notifications";
import endpoints from "../../constants/endpoints";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { isValidateEmail } from "../../utils/functions";

const Login = ({ setShowLogin, setHaveSetShowLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showEmail, setShowEmail] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");

  const getAuthFunc = (event) => {
    event?.preventDefault();
    if (showEmail) return getSSOURL(event);
    return authenticateUser();
  };

  const getSSOURL = () => {
    if (!isValidateEmail(username)) return commonNotification({ msg: "Valid Email Required", type: "danger" });
    const userDetails = {
      username,
    };

    setInProgress(true);
    const urlRequest = axiosPostRequest(endpoints.authSSOURL, userDetails)
    .then((res) => {
      if (res.status === 200) {
        window.location.href = res.data.ssoUrl;
      } else {
        setShowEmail(false);
      }
    })
    .catch((err) => {
      const errMsg = err.response?.status >= 500 ? 'Something Went Wrong' : err.response?.data?.error ? err.response.data.error :  'Something Went Wrong';
      commonNotification({ msg: errMsg, type: "danger" });
      setUsername("");
    })
    .finally(() => {
      setInProgress(false);
    });
  };

  const authenticateUser = (code) => {
    const userDetails = {
      username,
      password,
      code,
    };
    if (!code && (!username || !password)) {
      commonNotification({
        msg: "All Fields Must Be Filled Out",
        type: "danger",
      });
      return false;
    }

    setInProgress(true);

    const loginRequest = axiosPostRequest(endpoints.authLogin, userDetails);
    loginRequest
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            localStorage.setItem(
              "username",
              username ? username : res.data.email
            );
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            setShowLogin(false);
            setHaveSetShowLogin(true);
          } else {
            setShowLoginForm(false);
            commonNotification({ msg: res.message, type: "success" });
          }
        }
      })
      .catch((err) => {
        const errMsg = err.response?.status >= 500 ? 'Something Went Wrong' : err.response?.data?.error ? err.response.data.error :  'Something Went Wrong';
        commonNotification({ msg: errMsg, type: "danger" });

        setShowEmail(true);
        setPassword("");
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  if (code) {
    history.replaceState(null, "", "/");
    authenticateUser(code);
  }

  const passwordCheck = (password, secondPassword) => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (password !== secondPassword) {
      return "The Passwords Must Match";
    }
    let pasArr = [];
    if (password.length < 8) {
      pasArr.push("8 Characters");
    }
    if (password === password.toLocaleLowerCase()) {
      pasArr.push("an Uppercase Letter");
    }
    if (!password.match(/[0-9]/)) {
      pasArr.push("a Number");
    }
    if (!format.test(password.trim())) {
      pasArr.push("a Special Character");
    }
    let strToReturn;
    if (pasArr.length > 0) {
      strToReturn = "Password Must Contain ";
      if (pasArr.length === 1) {
        return pasArr[0];
      } else if (pasArr.length < 3) {
        strToReturn += pasArr.join(" And ");
      } else {
        strToReturn += pasArr.join(", ");
        const lastComma = strToReturn.lastIndexOf(",");
        strToReturn =
          strToReturn.substring(0, lastComma + 1) +
          " And " +
          strToReturn.substring(lastComma + 1);
      }
    }

    return strToReturn;
  };

  const changePassword = async (event) => {
    event.preventDefault();
    try {
      setInProgress(true);
      if (!username || !newPassword || !confirmPassword) {
        commonNotification({
          msg: "All Fields Must Be Filled Out",
          type: "danger",
        });
      } else if (newPassword !== confirmPassword) {
        commonNotification({ msg: "Passwords Must Match", type: "danger" });
      } else {
        const userDetails = {
          username,
          password: newPassword,
        };
        const changePasswordRequest = await axiosPostRequest(
          endpoints.authChangePassword,
          userDetails
        );

        if (changePasswordRequest.status === 200) {
          localStorage.setItem("username", username);
          setShowLoginForm(true);
          commonNotification({
            msg: changePasswordRequest.message,
            type: "success",
          });
        } else {
          commonNotification({
            msg: changePasswordRequest.error,
            type: "danger",
          });
        }
      }
    } catch (err) {
      const errMsg = err.response?.status >= 500 ? 'Something Went Wrong' : err.response?.data?.error ? err.response.data.error :  'Something Went Wrong';
      commonNotification({ msg: errMsg, type: "danger" });

      setNewPassword("");
      setConfirmPassword("");
    } finally {
      setInProgress(false);
    }
  };

  useEffect(() => {
    if (newPassword && confirmPassword) {
      const passwordCheckStr = passwordCheck(newPassword, confirmPassword);
      setPasswordError(passwordCheckStr);
      if (newPassword && confirmPassword && !passwordCheckStr) {
        setAllowSubmit(false);
      } else {
        setAllowSubmit(true);
      }
    }
  }, [newPassword, confirmPassword, showLoginForm]);

  const getLabel = () => {
    if (inProgress) {
      return <LoaderComponent/>
    } else if (showEmail) {
      return "Next";
    } else {
      return "Sign In";
    }
  }

  const loginForm = (
    <>
      {
        showEmail && 
        <div className="login-form-element">
          <h6 className="login-p login-email-header">Email</h6>
          <div className="login-email">
            <CommonInput
              placeholder="Example@Domain.com"
              inputValue={username}
              setInputValue={setUsername}
              uniqueIdentifier="loginEmailId"
            />
          </div>
        </div> 
      }
      {
        !showEmail &&
        <div className="login-form-element">
          <h6 className="login-p">Password</h6>
          <div className="login-password">
            <input
              type="password"
              id="loginPassword"
              name="loginPassword"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="utilitySearch login-password-input"
            />
          </div>
        </div>
      }
      <div className="login-button-wrapper login-form-element">
        <CommonButtonComponent
          label={getLabel()}
          appendWrapperClassName="login-button"
          onClickCTA={getAuthFunc}
          buttonUI="loginSignIn"
          isDisabled={inProgress || (showEmail && !isValidateEmail(username)) || (!showEmail && !password)}
        />
      </div>
      {
        !showEmail &&
        <div className="login-button-wrapper login-form-element">
          <CommonButtonComponent
            label='Back'
            appendWrapperClassName="login-button"
            appendButtonClassName='btnSecondary'
            onClickCTA={() => {
              setShowEmail(true);
              setPassword("");
            }}
            buttonUI="loginSignIn"
            isDisabled={inProgress}
          />
        </div>
      }
    </>
  );

  const changePasswordForm = (
    <>
      {passwordError && (
        <p className="reset-password-p reset-password-password-error">
          {passwordError}
        </p>
      )}
      <div className="login-form-element">
        <h6 className="login-p">New Password</h6>
        <div className="login-password">
          <input
            type="password"
            name="loginPassword"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="utilitySearch login-password-input"
          />
        </div>
      </div>
      <div className="login-form-element">
        <h6 className="login-p">Confirm Password</h6>
        <div className="login-password">
          <input
            type="password"
            name="loginPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="utilitySearch login-password-input"
          />
        </div>
      </div>
      <div className="login-button-wrapper login-form-element">
        <CommonButtonComponent
          label="Submit"
          isDisabled={allowSubmit}
          appendWrapperClassName="login-button"
          onClickCTA={changePassword}
          buttonUI="loginSignIn"
        />
      </div>
    </>
  );

  return (
    <div className="login breadCrumbOuterWrapper">
      <div className="login-color-div-1 login-color-div" />
      <div className="login-color-div-2 login-color-div" />
      <div className="login-color-div-3 login-color-div" />
      <div className="login-color-div-4 login-color-div" />
      <div className="login-wrapper breadCrumbInnerWrapper">
        <div className="login-form login-wrapper-element">
          <div className="login-logo login-form-element" />
          <h2 className="login-header login-form-element">Sign In</h2>
          <form className="login-element-form">
            {showLoginForm ? loginForm : changePasswordForm}
          </form>

          <a
            href="#"
            className="login-form-element login-forgot-button"
            onClick={(e) => {
              e.preventDefault();
              navigate("/reset-password");
            }}
          >
            Forgot Password
          </a>
        </div>
        <div className="login-info login-wrapper-element">
          <p className="login-privacy">Privacy Policy - Terms Of Use</p>
          <div className="login-claritas-logo" />
          <p className="login-copyright">
            Copyright &copy; Claritas, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
