import React from 'react';
import { Link } from 'react-router-dom';

export default [
  {
    id: 'partner_id',
    name: 'PARTNER ID',
    selector: (row) => (
      <div className='partnerListCellWrapper'>
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/admin/partners/${row?.partner_id}?name=${row?.partner_name}`}
          >
            <div className='linkStyleLR'>{row?.partner_id}</div>
          </Link>
        </div>
      </div>
    ),
    sortable: true,
    sortField: 'partner_id',
    width: '150px',
    right: true,
  },
  {
    id: 'partner_name',
    name: 'PARTNER NAME',
    selector: (row) => (
      <div className='tableCellFontStyle tableCampNameFontStyle'>
        <Link
          to={`/admin/partners/${row?.partner_id}?name=${row?.partner_name}`}
        >
          <div className='linkStyleLR'>{row?.partner_name}</div>
        </Link>
      </div>
    ),
    sortable: true,
    sortField: 'partner_name',
    // style: { minWidth: '120px', maxWidth: '200px' },
  },
  {
    id: 'type',
    name: 'TYPE',
    selector: (row) => <div className='tableCellFontStyle '>{row?.type}</div>,
    sortable: true,
    sortField: 'type',
    width: '250px',
  },
  {
    id: 'category',
    name: 'CATEGORY',
    selector: (row) => (
      <div className='tableCellFontStyle '>{row?.category}</div>
    ),
    sortable: true,
    sortField: 'category',
    width: '250px',
  },
  {
    id: 'status',
    name: 'STATUS',
    selector: (row) => (
      <div className={`tableCellFontStyle status ${row.status}`}>
        {row?.status}
      </div>
    ),
    sortable: true,
    sortField: 'status',
    width: '140px',
    // grow: 1,
    // right: true
  },
];
