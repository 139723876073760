import React, { useEffect, useState } from 'react';
import {
  CommonHeadingComponent,
  CommonSearch,
  CommonTablesComponent,
  ErrorComponent,
} from '../../../../components';
import { baseEndpoints } from '../../../../constants';
import partnerTableColumn from './partnerList.util';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';

import './partnerList.css';

const baseInputParams = {
    sort_column: 'partner_name',
    sort_order: 'asc',
    page_number: 1,
    page_size: 10,
    search_param: '',
  },
  sortColumns = [
    'partner_id',
    'partner_name',
    'type',
    'category',
    'certification_level',
    'status',
  ];

const PartnerList = ({ role }) => {
  document.title = 'Admin | Partners';

  const [isMounted, setIsMounted] = useState(false),
    [params, setParams] = useState(null),
    [searchInput, setSearchInput] = useState(''),
    [selectedTableSize, setSelectedTableSize] = useState(Number(localStorage.getItem('partnersPageSize')) || 10),
    [inputParams, setInputParams] = useState({...baseInputParams, page_size: selectedTableSize}),
    [isClientUpdated, setIsClientUpdated] = useState(true),
    [isSearched, setIsSearched] = useState(true);

  // States for fetching Partner List
  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.admin + '/partners'
  );

  /**
   * First time to initialize api calls and set up data
   * @dependencies : page-number, sort-data, per-page-data
   */
  useEffect(() => {
    setParams({ ...inputParams });
    setIsMounted(true);
  }, [inputParams]);

  // for search filter
  useEffect(() => {
    if (isClientUpdated) {
      return setIsClientUpdated(false);
    }

    const timer = setTimeout(() => {
      setInputParams({
        ...inputParams,
        page_number: baseInputParams.page_number,
        search_param: searchInput,
        page_size: selectedTableSize,
      });
    }, 1000);
    setIsSearched(true);
    return () => clearTimeout(timer);
  }, [searchInput]);

  useEffect(() => {
    if (!isLoading && isSearched) {
      setIsSearched(false);
      setTimeout(() => {
        const searchElem = document.querySelector(
          '.partnerListWrapper .partnerListSubheaderWrapper input'
        );
        searchElem?.focus();
      }, 100);
    }
  }, [isLoading]);

  const handlePageSizeChange = (pageSize) => {
    localStorage.setItem('partnersPageSize', pageSize.toString());
    setSelectedTableSize(pageSize);
  };

  useEffect(() => {
    const previousSelection = Number(localStorage.getItem('partnersPageSize') ?? 10);
    setSelectedTableSize(previousSelection);
  }, []);

  /** render component on the basis of the API request, if error then error component else table   */
  const renderComponent = () => {
    return isError ? (
      <ErrorComponent refreshAction={() => setParams({ ...params })} />
    ) : (
      <>
        <div className=' partnerListSubheaderWrapper'>
          <div className='d-flex'>
            <CommonSearch
              placeholder={'Search Partner by ID or Name'}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              customStyle={
                searchInput.length > 0 ? { fontStyle: 'normal' } : {}
              }
              disabled={isLoading || isError}
              uniqueIdentifier='allPartners'
            />
          </div>
        </div>
        <CommonTablesComponent
          uniqueId='partnerList'
          data={data?.table_data ?? []}
          columns={partnerTableColumn}
          isLoading={isLoading}
          customTableHeader='Partners'
          setSortData={(sortData) =>
            setInputParams({
              ...inputParams,
              sort_column: sortData?.sortField,
              sort_order: sortData?.order,
            })
          } // sorting
          sortColumnIds={sortColumns} // sorting
          defaultSortingApplied={{
            column: baseInputParams.sort_column,
            order: baseInputParams.sort_order,
          }} // sorting
          isPaginationApplied={true}
          totalDataCount={data?.total_data ?? 0}
          pageIndex={inputParams?.page_number}
          setPageIndex={(pageNumber) =>
            setInputParams({ ...inputParams, page_number: pageNumber })
          }
          dataPerPage={inputParams?.page_size}
          paginationOptions={{
            noRowsPerPage: false,
          }}
          setDataPerPage={(pageSize) => {
            handlePageSizeChange(pageSize);
            setInputParams({
              ...inputParams,
              page_number: baseInputParams.page_number,
              page_size: pageSize,
            })
          }}
          noDataMessage='No Partners Available'
          noDataMessageFontSize={15}
          noDataCompWidth='180'
          noDataCompHeight='180'
          foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
        />
      </>
    );
  };

  return (
    <div className='partnerListWrapper'>
      <div className='partnerListHeaderWrapper'>
        <CommonHeadingComponent
          headingLabel='Partners'
          url={null}
          isDisabled={true}
        />
      </div>
      {renderComponent()}
    </div>
  );
};

export default PartnerList;
