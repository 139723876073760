import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../../../constants';
import { Container, Row, Col } from 'react-bootstrap';
import CommonDonutChart from '../../../../../../components/charts/CommonDonutChart.component';
import {
  ErrorComponent,
  LoaderComponent,
} from './barchartForGoals/commonChatComp';
import { dateFormat, getAllSelectedOrNot, getArrayOfData } from '../../../../../../utils/functions';

import './analysis.css';

const constants = {
  goals: {
    engagement: {
      endpoint: 'engagement-top-5-goals',
      label: 'Total Engagements',
    },
    conversion: {
      endpoint: 'conversion-top-5-goals',
      label: 'Total Conversions',
    },
    keyName: 'goal_name',
    colors: [
      '#dc4d00',
      '#fec241',
      '#ff9730',
      '#f56600',
      '#FF8935',
      '#FFA260',
      '#C15000',
      '#973F00',
    ],
  },
  devices: {
    engagement: {
      endpoint: 'engagement-top-5-devices',
      label: 'Engagements by Device',
    },
    conversion: {
      endpoint: 'conversion-top-5-devices',
      label: 'Conversions by Device',
    },
    keyName: 'device_type_name',
    colors: [
      '#016ba7',
      '#96fdfe',
      '#59d6ff',
      '#219ae1',
      '#69FCFC',
      '#CEFFFF',
      '#96FEFE',
      '#3FF3F3',
    ],
  },
};

export default function EngagementsConversionsTop5GoalsDevices({
  campaign,
  mediaPartnerList,
  selectedMediaPartner,
  placementList,
  selectedPlacement,
  creativeTypeList,
  selectedCreativeType,
  typeOfData,
  isLoadingBase,
  ifSelectedAll,
  numberOfDays,
  chartType,
  goalType,
  startDate,
  endDate,
}) {
  const [data, setData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [isError, setIsError] = useState(false);

  const [cancelController, setCancelController] = useState(null);

  const getData = async (controller) => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await axiosSendRequest(
          `${baseEndpoints.reports}/attribution-tab/analysis/${constants[chartType][goalType].endpoint}`,
          controller,
          {
            campaign_id: campaign?.campaign_id,
            type_of_data: typeOfData,
            if_all_partners: getAllSelectedOrNot(mediaPartnerList, selectedMediaPartner, 'partner_id'),
            if_all_placements: getAllSelectedOrNot(placementList, selectedPlacement, 'placement_id'),
            if_all_creative_types: getAllSelectedOrNot(creativeTypeList, selectedCreativeType, 'value'),
            partner_id: getArrayOfData(selectedMediaPartner, 'partner_id'),
            placement_id: getArrayOfData(selectedPlacement, 'placement_id'),
            creative_type_id: getArrayOfData(selectedCreativeType, 'name'),
            interval_type: numberOfDays?.id,
            start_date: dateFormat(startDate, 'YYYY-MM-DD'),
            end_date: dateFormat(endDate, 'YYYY-MM-DD'),
            campaign_start_date: dateFormat(campaign?.start_date, 'YYYY-MM-DD'),
            campaign_end_date: dateFormat(campaign?.end_date, 'YYYY-MM-DD'),
          }
        );

      if (response.status === 200) {
        setData(calcData(response.data, constants[chartType].keyName));
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err.message);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (campaign?.campaign_id && selectedPlacement && startDate && endDate) {
      const controller = new AbortController();
      setCancelController(controller);
      getData(controller);
      return () => {
        controller.abort();
      };
    } else {
      setData([]);
      setIsError(false);
      setIsLoading(false)
    }
  }, [campaign?.campaign_id, selectedPlacement, numberOfDays, startDate, endDate, typeOfData]);

  return (
    <div className='attrAnalysisDonutChartWrapper'>
      <Container fluid>
        <Row>
          <Col className='widget_content' sm={12}>
            <div className='chart_caption analysis_chart_caption'>
              {constants[chartType][goalType].label}
            </div>
            <RenderCharts
              isLoading={isLoading || isLoadingBase || !ifSelectedAll}
              isError={isError}
              data={data}
              colors={constants[chartType].colors}
              dataFunction={getData}
              controller={cancelController}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const calcData = (data, nameProperty) => {
  let tmp = [];
  if (data?.length) {
    tmp = data.map((element) => {
      return {
        name: element[nameProperty],
        value: parseInt(element.count),
      };
    });

    return tmp;
  }
};

const RenderCharts = ({
  isLoading,
  isError,
  data,
  colors,
  dataFunction,
  controller,
}) => {
  if (isLoading) {
    return <LoaderComponent headerClass='attrAnalysisChartsLoader' />;
  }

  if (isError) {
    return (
      <ErrorComponent refreshAction={dataFunction} controller={controller} />
    );
  }

  return <CommonDonutChart data={data} colors={colors} tooltip={true} />;
};
