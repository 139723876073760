import React from 'react';
import './CommonInput.css';
import LoaderComponent from '../loaderComponent/Loader.component';

const CommonInput = ({
  placeholder,
  inputValue,
  setInputValue,
  disabled,
  className,
  label,
  labelStyle,
  labelClass,
  maxLength,
  onKeyDown,
  onBlurHandle,
  isLoading = false,
  inputStyle = null,
  loaderStyle = null,
  uniqueIdentifier,
}) => {
  return (
    <div className='flex commonInputWrapper' style={{position: 'relative'}}>
      {label ? (
        <div className={`inputLabel ${labelClass}`} style={labelStyle}>
          {label}
        </div>
      ) : (
        ''
      )}
      <input
        type='text'
        id={uniqueIdentifier}
        name={uniqueIdentifier}
        value={inputValue}
        placeholder={placeholder}
        onChange={(e) => setInputValue(e.target.value)}
        className={`utilitySearch ${className}`}
        style={inputStyle}
        disabled={disabled}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onBlur={onBlurHandle}
        autoComplete='off'
      />
      {isLoading && (
        <div className='dropdownLoaderWrapper' style={{position: 'absolute', right: '5px', bottom: '20px'}}>
          <LoaderComponent importedClassNames='dropdownsAndDates' />
        </div>
      )}
    </div>
  );
};

export default CommonInput;
