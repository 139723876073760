import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import moment from 'moment';
import { CommonModal } from '../../../components/modals';
import { useGetApiInterceptor } from '../../../hooks/useGetApiInterceptor';
import { usePatchAPIInterceptor } from '../../../hooks/patchAPIInterceptor';
import {
  resetAdvertiserMode,
  updateAdvertiserMode,
  updateClientMode,
  resetClientMode,
} from '../../../redux/utilitybarSlice';
import {
  CommonButtonComponent,
  SearchableDropdownComponent,
  CommonInput,
  LoaderComponent,
  DatePickerComponent,
  CommonHeadingComponent,
} from '../../../components';
import { commonNotification } from '../../../utils/common-notifications';
import { breadCrumbHeader } from '../../../constants';
import endpoints, {baseEndpoints} from '../../../constants/endpoints';
import { statusOptions } from '../../CreateProperties/helper';
import { EpisodeList } from './EpisodeList/EpisodeList';
import {
  getEncryptedOrDecryptedData,
  isValidateURL, isOnlyNumbers
} from '../../../utils/functions';
import { setBlockingLoaderState } from '../../../redux/blockingLoader';

import '../placement.css';
import {
  axiosGetRequest,
  axiosPatchRequest,
  axiosPostRequest,
} from '../../../services/http/axiosCalls';
import { bool } from 'prop-types';
import { set } from 'lodash';

const today = new Date();

// createProperties used to create the property in the application with required fields
const EditPlacement = () => {
  document.title = 'Placement | Details';

  const dispatch = useDispatch();
  const navigate = useNavigate(); // navigate between pages
  const { pathname, state } = useLocation();
  const pathnameArr = pathname.split('/');
  const { role } = useSelector((state) => state.user);
  const isSuperAdmin = role === 'super admin';
  const isAdminOrAbove = role === 'super admin' || role === 'admin';
  const campaignId = Number(pathnameArr[2]);
  const placementId = Number(pathnameArr[4]);
  const modalHeaderStyle = { marginBottom: '10px', marginTop: '10px' };
  const placementData = state?.placementData;

  // Getting all the field data for the form with default values and updating the state for create placement
  const [formData, setFormData] = useState({
    name: '',
    cpm: '0.00',
    mediaPartner: {},
    creativeType: null,
    podcastProvider: null,
    referenceId: '',
    clickURL: '',
    showName: '',
    channelId: '',
    RSSFeedURL: '',
    status: statusOptions[0],
    isDataFile: false,
    itemId: '',
    orderId: ''
  });
  const [preName, setPreName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null); //setting the state with start date
  const [endDate, setEndDate] = useState(null); //setting the state with the end date
  const [pubStartDate, setPubStartDate] = useState(null); //setting the state with the publish start date
  const [prePubDate, setPrePubDate] = useState(null);
  const [pubEndDate, setPubEndDate] = useState(null); //setting the state with the publish end date
  const [saveMount, setSaveMount] = useState(false);
  const [savePayload, setSavePayload] = useState(null);
  const [saveEpisodeMount, setSaveEpisodeMount] = useState(false);
  const [isDupModalOpen, setIsDupModalOpen] = useState(false);
  const [dupNameModalData, setDupNameModalData] = useState(null);
  const [isEpiModalOpen, setIsEpiModalOpen] = useState(false);
  const [ispubEndDateModal, setIspubEndDateModal] = useState(false);
  const [isNameChangeModal, setIsNameChangeModal] = useState(false);
  const [isEpisodeConfirm, setIsEpisodeConfirm] = useState(false);
  const [excludeIds, setExcludeIds] = useState([]);
  const [includeIds, setIncludeIds] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [usablePlacementData, setUsablePlacementData] = useState(null);
  const [episodeList, setEpisodeList] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  const [validName, setValidName] = useState({
      valid: true,
      errorMsg: '',
    }),
    [validClickThroughURL, setValidClickThroughURL] = useState({
      valid: true,
      errorMsg: '',
    }),
    [validRefID, setValidRefID] = useState({
      valid: true,
      errorMsg: '',
    }),
    [validOrderId, setValidOrderId] = useState({
      valid: true,
      errorMsg: ''
    }),
    [validItemId, setValidItemId] = useState({
      valid: true,
      errorMsg: ''
    }),
    [restatementDetails, setRestatementDetails] = useState(null),
    [restatementText, setRestatementText] = useState(''),
    [restatementStatus, setRestatementStatus] = useState({});

  const defaultName = usablePlacementData?.placementName;
  const defaultStartDate = usablePlacementData
    ? moment.utc(usablePlacementData?.startDate).format('YYYY-MM-DD')
    : null;
  const defaultEndDate = usablePlacementData
    ? moment.utc(usablePlacementData?.endDate).format('YYYY-MM-DD')
    : null;
  const defaultPubStartDate = usablePlacementData
    ? moment.utc(usablePlacementData?.publishStartDate).format('YYYY-MM-DD')
    : null;
  const defaultPubEndDate = usablePlacementData
    ? moment.utc(usablePlacementData?.publishEndDate).format('YYYY-MM-DD')
    : null;
  const defaultClickthroughUrl = usablePlacementData?.clickThroughUrl;
  const defaultReferenceID = usablePlacementData?.tpRefId;
  const defaultCPM = usablePlacementData?.cost;
  const defaultStatus = usablePlacementData?.status;
  const defaultOrderId = usablePlacementData?.order_id;
  const defaultItemId = usablePlacementData?.item_id;

  const placementList = useGetApiInterceptor(true, null, `${baseEndpoints.campaigns}/${campaignId}/placements`);
  let placementArray = placementList?.data?.table_data || [];
  let usedItemIds = placementArray
    .filter((placement) => placement.item_id && placement.item_id.trim() !== '' && placement.item_id !== defaultItemId)
    .map((placement) => placement.item_id);
  let usedOrderIds = placementArray
    .filter((placement) => placement.order_id && placement.order_id.trim() !== '' && placement.order_id !== defaultOrderId)
    .map((placement) => placement.order_id);

  const checkChange = () => {
    if (usablePlacementData?.publishStartDate) {
      return (
        defaultName !== formData.name ||
        defaultStartDate !== startDate ||
        defaultEndDate !== endDate ||
        defaultPubStartDate !== pubStartDate ||
        defaultPubEndDate !== pubEndDate ||
        defaultClickthroughUrl !== formData.clickURL ||
        defaultReferenceID !== formData.referenceId ||
        (defaultCPM !== formData.cpm && formData.cpm >= 0) ||
        defaultStatus !== formData.status.code ||
        defaultItemId !== formData.itemId ||
        defaultOrderId !== formData.orderId
      );
    } else {
      return (
        defaultName !== formData.name ||
        defaultStartDate !== startDate ||
        defaultEndDate !== endDate ||
        defaultClickthroughUrl !== formData.clickURL ||
        defaultReferenceID !== formData.referenceId ||
        (defaultCPM !== formData.cpm && formData.cpm >= 0) ||
        defaultStatus !== formData.status.code || 
        defaultItemId !== formData.itemId ||
        defaultOrderId !== formData.orderId
      );
    }
  };

  const checkValid = (val, key) => {
    switch (key) {
      case 'name':
        if (!val?.length) {
          setValidName({
            valid: false,
            errorMsg: 'Name is required',
          });
          return;
        }
        if (val?.length > 255) {
          setValidName({
            valid: false,
            errorMsg: 'Maximum allowed characters for Name is 255',
          });
          return;
        }
        setValidName({
          valid: true,
          errorMsg: '',
        });
        break;
      case 'referenceID':
        if (val.length > 45) {
          setValidRefID({
            valid: false,
            errorMsg: 'Maximum characters allowed for Reference ID is 45.',
          });
        } else {
          setValidRefID({
            valid: true,
            errorMsg: '',
          });
        }
        break;
      case 'clickURL':
        if (!val.length) {
          setValidClickThroughURL({
            valid: false,
            errorMsg: 'URL is required',
          });
          return;
        }
        if (val.length > 1000) {
          setValidClickThroughURL({
            valid: false,
            errorMsg: 'Maximum allowed characters for URL is 1000',
          });
          return;
        }
        setValidClickThroughURL({
          valid: isValidateURL(val),
          errorMsg: isValidateURL(val) ? '' : 'Please enter a valid URL',
        });
        break;
      default:
        break;
    }
  };

  const disableBtn = () => {
    return !validName.valid || (!isDataFile && !validClickThroughURL.valid) || !validRefID.valid ||
    (isDataFile && (!validOrderId.valid || !validItemId.valid));
  };

  useEffect(() => {
    dispatch(updateAdvertiserMode(true)); //disabling advertiser dropdown selector
    dispatch(updateClientMode(true)); //disabling the client dropdown selector
    sessionStorage.removeItem('episodeData');
    return () => {
      dispatch(resetAdvertiserMode());
      dispatch(resetClientMode());
      sessionStorage.removeItem('episodeData');
    };
  }, []);

  useEffect(() => {
    if (placementData) {
      setUsablePlacementData({ ...placementData });
    } else {
      setIsMounted(true);
    }
  }, [placementData]);

  useEffect(() => {
    if (!restatementDetails && !restatementDetails?.length) {
      getRestatementDetails();
    } else {
      restatementDetailsCheck();
    }
  }, [restatementDetails]);

  const getRestatementDetails = async () => {
    try {
      const res = await axiosPostRequest(
        endpoints.placementRestatementDetails,
        {
          campaignId: campaignId,
          placementIds: [placementId],
        }
      );
      if (res?.data?.table_data) {
        setRestatementDetails(res.data.table_data);
      }
    } catch (e) {
      console.error(e);
      if (e?.response?.status !== 403) {
        setRestatementText('**Error Retrieving Restatement Details**');
      }
    }
  };

  const getEpisodeList = async () => {
    const url = `${endpoints.episodeList(
      campaignId,
      placementId
    )}?publishStartDate=${pubStartDate}&publishEndDate=${pubEndDate}`;
    const res = await axiosGetRequest(url);
    if (res.status === 200) {
      setEpisodeList(res);
      const excludeIds = res?.data?.excludedEpisodes?.map((item) => item.id);
      setExcludeIds(excludeIds);
      setIncludeIds(
        res?.data?.episodes.filter((item) => {
          if (!excludeIds?.includes(item.id)) return item.id;
        })
      );
    }
  };

  useEffect(() => {
    if (isEpiModalOpen && !episodeList?.data) {
      getEpisodeList();
    }
  }, [isEpiModalOpen]);

  useEffect(() => {
    if (isLoading && usablePlacementData) {
      setStartDate(
        moment.utc(usablePlacementData?.startDate).format('YYYY-MM-DD')
      );
      setEndDate(moment.utc(usablePlacementData?.endDate).format('YYYY-MM-DD'));

      setFormData({
        ...formData,
        name: usablePlacementData?.placementName,
        cpm: usablePlacementData?.cost,
        mediaPartner: {
          name: usablePlacementData?.partnerName,
          partner_id: usablePlacementData?.partnerId,
        },
        creativeType: {
          name: usablePlacementData?.typeLabel,
          value: usablePlacementData?.type,
        },
        podcastProvider: {
          name: usablePlacementData?.podcastProvider,
          value: usablePlacementData?.podcastProvider,
        },
        referenceId: usablePlacementData?.tpRefId,
        clickURL: usablePlacementData?.clickThroughUrl,
        showName: usablePlacementData?.showName,
        RSSFeedURL: usablePlacementData?.rssLink,
        channelId: usablePlacementData?.channelId,
        status: usablePlacementData?.status
          ? statusOptions[0]
          : statusOptions[1],
        isDataFile: usablePlacementData?.item_id?.length > 0 || usablePlacementData?.order_id?.length > 0,
        itemId: usablePlacementData?.item_id,
        orderId: usablePlacementData?.order_id
      });

      if (
        usablePlacementData?.publishStartDate &&
        usablePlacementData?.publishEndDate
      ) {
        setPubStartDate(
          moment.utc(usablePlacementData?.publishStartDate).format('YYYY-MM-DD')
        );
        setPubEndDate(
          moment.utc(usablePlacementData?.publishEndDate).format('YYYY-MM-DD')
        );
      }

      setIsLoading(false);
    }
  }, [usablePlacementData]);

  const placementDetails = useGetApiInterceptor(
    isMounted,
    null,
    endpoints.placementDetails(campaignId, placementId)
  );

  useEffect(() => {
    if (
      isLoading &&
      placementDetails.data.table_data &&
      placementDetails.data.table_data.length > 0 &&
      placementDetails.data.table_data[0]
    ) {
      setIsMounted(false);
      setUsablePlacementData({ ...placementDetails.data.table_data[0] });
    }
  }, [placementDetails]);

  const pubEndDateOnChange = (selectedDate) => {
    setDisableSave(false);
    if (!isLoading && endDate && new Date(endDate) < new Date(selectedDate)) {
      setPrePubDate(pubEndDate);
      setIspubEndDateModal(true);
    }
  };

  const getIncludesAndExcludes = () => {
    const episodeData = sessionStorage.getItem('episodeData');
    const includes = [];
    const excludes = [];
    if (episodeData) {
      const { episodes, selectedEpisodes, excludedEpisodes, excludeItem } =
        JSON.parse(episodeData);
      if (episodes?.length > 0) {
        episodes?.forEach((id) => {
          if (selectedEpisodes?.includes(id)) {
            includes.push(id);
          } else if (excludeItem?.includes(id)) {
            excludes.push(id);
          }
        });
      }
    }
    return { includes, excludes };
  };

  //updating the formdata with the various field values
  const {
    name,
    cpm,
    mediaPartner,
    creativeType,
    showName,
    podcastProvider,
    referenceId,
    clickURL,
    RSSFeedURL,
    status,
    isDataFile,
    itemId,
    orderId
  } = formData;

  useEffect(() => {
    if(isDataFile && !orderId && !itemId){
      setValidOrderId({valid: false, errorMsg: 'Please enter either an Order ID or Item ID.'})
      setValidItemId({valid: false, errorMsg: 'Please enter either an Order ID or Item ID.'})
    } else {
        if((orderId && !itemId) && !usedOrderIds.includes(orderId) && orderId.length <= 40 && isOnlyNumbers(orderId)){
          setValidOrderId({valid: true, errorMsg: ''})
          setValidItemId({valid: true, errorMsg: ''})
        } else  if (orderId && !isOnlyNumbers(orderId)){
          setValidOrderId({ valid: false, errorMsg: 'Order IDs may only contain numbers.', });
        } else if (itemId && orderId){
          setValidOrderId({valid: false, errorMsg: 'Please enter either an Order ID or Item ID, but not both.'})
        } else if (orderId?.length > 40) {
          setValidOrderId({ valid: false, errorMsg: 'Order IDs may not be longer than 40 characters.',});
        } else if (usedOrderIds.includes(orderId)){
          setValidOrderId({ valid: false, errorMsg: 'That Order ID is already in use by this campaign.', });
        } 

        if((itemId && !orderId) && !usedItemIds.includes(itemId)  && itemId.length <= 40 && isOnlyNumbers(itemId)){
          setValidItemId({valid: true, errorMsg: ''})
          setValidOrderId({valid: true, errorMsg: ''})
        } else if (itemId && !isOnlyNumbers(itemId)){
          setValidItemId({ valid: false, errorMsg: 'Item IDs may only contain numbers.', });
        } else if (itemId && orderId){
          setValidItemId({valid: false, errorMsg: 'Please enter either an Order ID or Item ID, but not both.'})
        } else if (itemId?.length > 40) {
          setValidItemId({ valid: false, errorMsg: 'Item IDs may not be longer than 40 characters.',});
        } else if (usedItemIds.includes(itemId)){
          setValidItemId({ valid: false, errorMsg: 'That Item ID is already in use by this campaign.', });
        }
    }
  }, [orderId, itemId]);

  //To save property  & edit property and  set the payload

  const handleSave = (sname = '') => {
    dispatch(setBlockingLoaderState({ active: true }));

    let payload = {
      name: sname || name.trim(),
      startDate: startDate,
      endDate: endDate,
      tpRefId: referenceId,
      type: creativeType.value,
      cpm: cpm,
      status: status.code,
      isDataFile: isDataFile,
      itemId: isDataFile ? itemId : '',
      orderId: isDataFile ? orderId : '',
      defaultItemId: isDataFile ? defaultItemId : '',
      defaultOrderId: isDataFile ? defaultOrderId: ''
    };
    if (creativeType.value !== 'Podcast-RSS') {
      payload.clickThroughUrl = !isDataFile ? clickURL : '';
      if (creativeType.value === 'Podcast') {
        payload.podcastProvider = podcastProvider.value;
      }
    } else {
      payload.endPublishDate = pubEndDate;
    }
    setSavePayload(payload);
    setSaveMount(true);
  };

  usePatchAPIInterceptor(
    saveMount,
    savePayload,
    endpoints.savePlacement(campaignId, placementId),
    (status, data, error) => {
      if (data) {
        if (status === 200) {
          let msg = `Placement ${name.trim()} has been successfully updated. Redirecting to view all placement...`;
          if (data.message.includes('Restatement')) {
            msg = data.message;
          }
          commonNotification({ msg, type: 'success' });
          sessionStorage.removeItem('episodeData');
          setTimeout(() => {
            navigate(
              `/campaign/${campaignId}?params=${getEncryptedOrDecryptedData(
                { name: usablePlacementData?.campaignName },
                'encrypt'
              )}`
            );
            dispatch(setBlockingLoaderState({ active: false }));
          }, 2000);
        } else {
          if (data.errorType === 'duplicate_placment_name') {
            setDupNameModalData(data);
            setIsDupModalOpen(true);
          } else {
            commonNotification({
              msg: data.message || data.error || 'Something went wrong',
              type: 'danger',
            });
          }
          dispatch(setBlockingLoaderState({ active: false }));
        }
      } else {
        commonNotification({
          msg: error.error || 'Something went wrong',
          type: 'danger',
        });
        dispatch(setBlockingLoaderState({ active: false }));
      }
    }
  );

  const saveEpisodeRestatement = async (controller) => {
    dispatch(setBlockingLoaderState({ active: true }));
    try {
      const { status, message } = await axiosPatchRequest(
        endpoints.placementSaveEpisodeRestatement(campaignId, placementId),
        controller,
        {
          includedEpisodes: includeIds,
          excludedEpisodes: excludeIds,
        }
      );
      if (status === 200) {
        sessionStorage.removeItem('episodeData');
        commonNotification({
          msg: message || 'Successfully Restated Placement',
          type: 'success',
        });
        getRestatementDetails();
        getEpisodeList();
      }
    } catch (e) {
      console.log(e);
      commonNotification({
        msg: e?.response?.data?.message || 'Something went wrong',
        type: 'danger',
      });
    }
    setSaveEpisodeMount(false);
    dispatch(setBlockingLoaderState({ active: false }));
  };

  useEffect(() => {
    if (saveEpisodeMount) {
      const controller = new AbortController();
      saveEpisodeRestatement(controller);
      return () => {
        controller.abort();
      };
    }
  }, [saveEpisodeMount]);

  // Change handler for handling each form value change and updating each form data
  const changeHandler = (val, name) => {
    setDisableSave(false);
    setFormData({ ...formData, [name]: val });
  };

  // Handler for cancel - navigates to the campaigns page
  const handleCancel = () => {
    navigate(
      `/campaign/${campaignId}?params=${getEncryptedOrDecryptedData(
        { name: usablePlacementData?.campaignName },
        'encrypt'
      )}`
    );
  };

  const restatementDetailsCheck = () => {
    if (restatementDetails && restatementDetails?.length > 0) {
      let text = '';
      let restatementStatusUpdate = {};
      let restatementDate = '';
      if (restatementDetails.some((item) => item.status_id === 0)) {
        text =
          '**An Error Occured While Processing This Restatement Request. ' +
          'Please Reach Out To Your Account Manager For the Next Recommended Steps.**';
        restatementStatusUpdate.failed = true;
        if (!restatementStatus.failed) {
          setRestatementStatus(restatementStatusUpdate);
          setRestatementText(text);
        }
      } else if (restatementDetails.some((item) => item.status_id != 4)) {
        text = '**This Placement Has Been Queued For Restatement**';
        restatementStatusUpdate.queued = true;
        if (!restatementStatus.queued) {
          setRestatementStatus(restatementStatusUpdate);
          setRestatementText(text);
        }
      } else {
        restatementDetails.forEach((item, index) => {
          const dateString = item?.last_updated_dttm?.toString();
          const dateStringWithoutZ = dateString?.substring(
            0,
            dateString.length - 1
          );
          if ((dateStringWithoutZ && moment(dateStringWithoutZ).isAfter(restatementDate)) || !restatementDate) {
            restatementDate = moment(dateStringWithoutZ).format('MM/DD/YYYY');
          }
          if (
            index === restatementDetails.length - 1
            && !dateStringWithoutZ
          ) {
            restatementDate = '';
          }
        });
        text = `**Restatement Complete${restatementDate?.length > 0 ? ` As Of ${restatementDate}` : ''}**`;
        restatementStatusUpdate.passed = true;
        if (!restatementStatus.passed) {
          setRestatementStatus(restatementStatusUpdate);
          setRestatementText(text);
        }
      }
      return restatementStatusUpdate;
    }
  };

  //getting the field values of reference id and cpm and storing in on common function for  multiple usage
  const getCPMReferenceFields = () => {
    return (
      <>
        <Col xs={12} className='form-field'>
          <CommonInput
            label={'Reference ID'}
            inputValue={referenceId == 0 ? '' : referenceId}
            setInputValue={(val) => changeHandler(val, 'referenceId')}
          />
        </Col>
        <Col xs={12} className='form-field'>
          <div className='field'>
            <CommonInput
              label={'CPM'}
              inputValue={`$${cpm}`}
              setInputValue={(val) =>
                changeHandler(val.replace(/\$/g, ''), 'cpm')
              }
            />
            {showCPMWarning()}
          </div>
        </Col>
      </>
    );
  };
  const handleEpisode = () => {
    setIsEpiModalOpen(true);
  };

  const showCPMWarning = () => {
    if (Number(cpm) >= 999.99) {
      return (
        <div className='flex mt-2'>
          <div className='dropdown text-danger'>
            Note: The maximum value for CPM is set to $999.99.
          </div>
        </div>
      );
    } else if (Number(cpm) < 0) {
      return (
        <div className='flex mt-2'>
          <div className='dropdown text-danger'>
            Note: CPM must be greater than or equal to $0.00
          </div>
        </div>
      );
    } else return <div />;
  };

  const ConfirmationJSX = ({ isNameChange }) => {
    return (
      <div className='mt-4 mb-4 dup-name-modal'>
        {!isNameChange ? (
          <div>
            The selected Publish End Date is greater than the End Date. Do you
            want to proceed?
          </div>
        ) : (
          <div>
            Changing the Name of the Placement can cause issues with
            categorizing future attribution logic. Are you sure you want to
            change this Placement Name?
          </div>
        )}
      </div>
    );
  };
  const DupNameBodyJSX = () => {
    const { suggestedName } = dupNameModalData;
    return (
      <div className='mt-4 mb-4 dup-name-modal'>
        <div>Selected Placement Name already exists</div>
        <div className='mt-3 mb-3'>
          Click on Save to select the suggested Placement Name:
          <span className='link-text'>{suggestedName}</span>
        </div>
        <div>Click on Cancel to manually modify the Placement Name.</div>
      </div>
    );
  };
  const EpiConfirmationJSX = () => {
    return (
      <div className='mt-4 mb-4 epi-confirmation-modal'>
        <div>
          Adding or Removing Episodes from this list will result in any
          Scheduled or On-Demand reporting to be outdated until the data can be
          restated to exclude or include the selected episode(s). Do you want to
          continue?
        </div>
      </div>
    );
  };
  //checking the creative type is Podcast RSS and then updating the form fields accordingly
  const cType = creativeType?.value || '';
  const isRSSType = creativeType && creativeType.value === 'Podcast-RSS';

  return (
    <div className='create-placement edit-placement'>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <Link to='/'>
            <div className='homeBlue'></div>
          </Link>
          <div className='breadCrumbBody'>
            /
            <Link to='/campaigns'>
              <div className='active'>{breadCrumbHeader.campaigns}</div>
            </Link>
          </div>
          <div className='breadCrumbBody'>
            /
            {!isLoading ? (
              <Link
                to={`/campaign/${campaignId}?params=${getEncryptedOrDecryptedData(
                  { name: usablePlacementData?.campaignName },
                  'encrypt'
                )}`}
              >
                <div className='active'>
                  {usablePlacementData?.campaignName}
                </div>
              </Link>
            ) : (
              <LoaderComponent importedClassNames='dropdownsAndDates midElem' />
            )}
          </div>

          <div className='breadCrumbBody'>
            <span>/</span>
            {!isLoading ? (
              usablePlacementData?.placementName
            ) : (
              <LoaderComponent importedClassNames='dropdownsAndDates' />
            )}
          </div>
        </div>
        <div className='placementDetailsHeaderWrapper'>
          {!isLoading && (
            <>
              <div className='placementDetailsHeaderWrapperTitleRow'>
                <h1 className='font-bold'>
                  {usablePlacementData?.placementName}
                </h1>
              </div>
              <div className='placementDetailsHeaderElem'>
                <CommonHeadingComponent headingLabel='Placement Details' />
              </div>
            </>
          )}
        </div>
      </div>
      <div className='create-edit-placement-container'>
        <Container>
          {isLoading ? (
            <div className='pageLoaderWrapper'>
              {' '}
              <LoaderComponent headerClass='placementDetailsAndCloneLoader' />{' '}
            </div>
          ) : (
            <>
              {usablePlacementData && (
                <>
                  <Row>
                    <Col sm={12} className='form-field'>
                      <CommonInput
                        label={'Name'}
                        inputValue={name}
                        setInputValue={(val) => {
                          if (!preName) {
                            setPreName(name);
                            setIsNameChangeModal(true);
                          }
                          changeHandler(val, 'name');
                          if (val.length > 255) {
                            setValidName({
                              valid: false,
                              errorMsg:
                                'Maximum allowed characters for Name is 255 ',
                            });
                          } else {
                            setValidName({ valid: true, errorMsg: '' });
                          }
                        }}
                        labelClass={'requiredInput'}
                        onBlurHandle={(val) => {
                          checkValid(val.target.value, 'name');
                        }}
                        className={
                          validName.valid ? 'userInput' : 'userInput inputError'
                        }
                      />
                      {!validName.valid && (
                        <span className='error'>{validName.errorMsg}</span>
                      )}
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                      <SearchableDropdownComponent
                        options={statusOptions}
                        label='label'
                        id='label'
                        dropdownLabel='Status'
                        selectedValue={status}
                        handleChange={(val) => changeHandler(val, 'status')}
                        wrapperClass={'requiredDD'}
                        dropdownId='status'
                        isDisabled={!isSuperAdmin}
                      />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                      <SearchableDropdownComponent
                        options={[
                          {
                            name: usablePlacementData?.partnerName,
                            partner_id: usablePlacementData?.partnerId,
                          },
                        ]}
                        label='name'
                        id='name'
                        dropdownLabel='Media Partner'
                        selectedValue={mediaPartner}
                        handleChange={(val) =>
                          changeHandler(val, 'mediaPartner')
                        }
                        isDisabled
                        wrapperClass={'requiredDD'}
                        dropdownId='mediaPartner'
                        placeholderLabel='Select a Media Partner'
                      />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                      <DatePickerComponent
                        datePickerId='cCStartDate'
                        label='Start Date'
                        placeholderLabel='Start Date'
                        minDate={startDate}
                        maxDate={endDate}
                        format='MM/DD/YYYY'
                        startOrEndDate={startDate}
                        setStartOrEndDate={setStartDate}
                        isMandatory
                        isDisabled
                      />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                      <DatePickerComponent
                        datePickerId='cCEndDate'
                        label='End Date'
                        placeholderLabel='End Date'
                        minDate={moment(today).format('YYYY-MM-DD')}
                        format='MM/DD/YYYY'
                        startOrEndDate={endDate}
                        setStartOrEndDate={setEndDate}
                        onChangeCallback={() => setDisableSave(false)}
                        isMandatory
                      />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                      <div className={`dataFileCPMWrapper ${!isRSSType ? 'dataFileVisible' : ''}`}>
                      <div>
                        <CommonInput
                          label={'CPM'}
                          inputValue={`$${cpm}`}
                          setInputValue={(val) =>
                            changeHandler(val.replace(/\$/g, ''), 'cpm')
                          }
                          placeholder={'Enter CPM'}
                        />
                        {showCPMWarning()}
                      </div>
                      {!isRSSType && <div className='dataFileWrapper'>
                        <input
                          type='checkbox'
                          id={'dataFile'}
                          className={'dataFile'}
                          checked={isDataFile}
                          disabled={true}
                          onChange={(e) => {
                            changeHandler(e.target.checked, 'isDataFile');
                          }}
                        />
                        <label className="dataFileLabel" htmlFor={'dataFile'}>Data File</label>
                      </div>}
                    </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                      <CommonInput
                        label={'Reference ID'}
                        inputValue={referenceId == 0 ? '' : referenceId}
                        setInputValue={(val) => {
                          changeHandler(val, 'referenceId');
                          checkValid(val, 'referenceID');
                        }}
                        placeholder={'Enter Reference ID'}
                        className={
                          validRefID.valid
                            ? 'userInput'
                            : 'userInput inputError'
                        }
                      />
                      {!validRefID.valid && (
                        <span className='error'>{validRefID.errorMsg}</span>
                      )}
                    </Col>

                    <Col xl={6} lg={6} md={12} sm={12}>
                      <SearchableDropdownComponent
                        options={[
                          {
                            name: usablePlacementData?.typeLabel,
                            value: usablePlacementData?.type,
                          },
                        ]}
                        label='name'
                        id='name'
                        dropdownLabel='Creative Type'
                        handleChange={(val) =>
                          changeHandler(val, 'creativeType')
                        }
                        selectedValue={creativeType}
                        wrapperClass={'requiredDD'}
                        dropdownId='creativeType'
                        isDisabled
                      />
                    </Col>
                    {creativeType && creativeType.value === 'Podcast' && !isDataFile ? (
                      <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                        <SearchableDropdownComponent
                          options={[
                            {
                              name: usablePlacementData?.podcastProvider,
                              value: usablePlacementData?.podcastProvider,
                            },
                          ]}
                          label='name'
                          id='name'
                          dropdownLabel='Podcast Provider'
                          handleChange={(val) =>
                            changeHandler(val, 'podcastProvider')
                          }
                          selectedValue={podcastProvider}
                          wrapperClass={'requiredDD'}
                          dropdownId='podcastProvider'
                          isDisabled
                        />
                      </Col>
                    ) : (
                      <></>
                    )}
                    {isDataFile && !isRSSType && usedItemIds?.length === 0 ? (
                  <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                    <CommonInput
                      label={'Order ID'}
                      inputValue={orderId}
                      setInputValue={(val) => {
                        changeHandler(val, 'orderId');
                      }}
                      placeholder={'Enter Order ID'}
                      onBlurHandle={(val) => {
                        checkValid(val.target.value, 'orderId');
                      }}
                      className={
                        validOrderId.valid
                          ? 'userInput'
                          : 'userInput inputError'
                      }
                    />
                    {!validOrderId.valid && (
                      <span className='error'>
                        {validOrderId.errorMsg}
                      </span>
                    )}
                  </Col>
                ) : (
                <></>
                )}
                {isDataFile && !isRSSType && usedOrderIds?.length === 0 ? (
                  <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                    <CommonInput
                      label={'Line Item ID'}
                      inputValue={itemId}
                      setInputValue={(val) => {
                        changeHandler(val, 'itemId');
                      }}
                      placeholder={'Enter Line Item ID'}
                      onBlurHandle={(val) => {
                        checkValid(val.target.value, 'itemId');
                      }}
                      className={
                        validItemId.valid
                          ? 'userInput'
                          : 'userInput inputError'
                      }
                    />
                    {!validItemId.valid && (
                      <span className='error'>
                        {validItemId.errorMsg}
                      </span>
                    )}
                  </Col>
                ) : (
                <></>
                )}
                    {!isRSSType && !isDataFile ? (
                      <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                        <CommonInput
                          label={'Clickthrough URL'}
                          inputValue={clickURL}
                          setInputValue={(val) => {
                            changeHandler(val, 'clickURL');
                            if (val.length > 1000) {
                              setValidClickThroughURL({
                                valid: false,
                                errorMsg:
                                  'Maximum characters allowed for Clickthrough URL is 1000',
                              });
                            } else {
                              setValidClickThroughURL({
                                valid: true,
                                errorMsg: '',
                              });
                            }
                          }}
                          labelClass={'requiredInput'}
                          onBlurHandle={(val) => {
                            checkValid(val.target.value, 'clickURL');
                          }}
                          className={
                            validClickThroughURL.valid
                              ? 'userInput'
                              : 'userInput inputError'
                          }
                        />
                        {!validClickThroughURL.valid && (
                          <span className='error'>
                            {validClickThroughURL.errorMsg}
                          </span>
                        )}
                      </Col>
                    ) : (
                      <></>
                    )}
                    {isRSSType ? (
                      <>
                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          className='form-field'
                        >
                          {/* <div className='infoColumn'>
                            <div className='field-label'>Show Name</div>
                            <div className='field-val'>
                              {showManualOption ? rssData.showName : showName}
                            </div>
                          </div> */}
                          <CommonInput
                            label={'Show Name'}
                            inputValue={showName}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          className='form-field'
                        >
                          {/* <div className='infoColumn'>
                              <div className='field-label'>RSS feed URL</div>
                              <div className='field-val'>{RSSFeedURL}</div>
                            </div> */}
                          <CommonInput
                            label={'RSS feed URL'}
                            inputValue={RSSFeedURL}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          className='form-field'
                        >
                          <DatePickerComponent
                            datePickerId='pubStartDate'
                            label='Publish Start Date'
                            placeholderLabel='Publish Start Date'
                            minDate={pubStartDate}
                            maxDate={pubEndDate}
                            format='MM/DD/YYYY'
                            startOrEndDate={pubStartDate}
                            setStartOrEndDate={setPubStartDate}
                            isMandatory
                            isDisabled
                          />
                        </Col>
                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          className='form-field'
                        >
                          <DatePickerComponent
                            datePickerId='pubEndDate'
                            label='Publish End Date'
                            placeholderLabel='Publish End Date'
                            minDate={moment(today).format('YYYY-MM-DD')}
                            format='MM/DD/YYYY'
                            startOrEndDate={pubEndDate}
                            setStartOrEndDate={setPubEndDate}
                            onChangeCallback={pubEndDateOnChange}
                            isMandatory
                          />
                        </Col>
                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          className='campaignListButtonWrapper update-episode-list-button-wrapper'
                          style={
                            restatementText?.length > 0
                              ? {}
                              : { marginTop: '20px' }
                          }
                        >
                          <h6
                            className='placementDetailsHeaderWrapperTitleRowRestatementText'
                            style={
                              restatementStatus?.passed
                                ? {}
                                : { color: 'red' }
                            }
                          >
                            {restatementText}
                          </h6>
                          <CommonButtonComponent
                            label={
                              isAdminOrAbove
                                ? 'Update Episodes List'
                                : 'View Episodes List'
                            }
                            onClickCTA={handleEpisode}
                            appendWrapperClassName='btnPrimaryWrapper update-episode-btn'
                            appendButtonClassName='btnSecondary'
                            appendWrapperStyle={{ padding: '0px' }}
                          />
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <Row>
                    <Col
                      sm={12}
                      className='campaignListButtonWrapper text-center mt-3 mb-4'
                    >
                      <CommonButtonComponent
                        label={'Save'}
                        appendWrapperClassName='btnPrimaryWrapper'
                        appendButtonClassName='btnPrimary'
                        onClickCTA={() => {
                          handleSave('');
                        }}
                        isDisabled={ !checkChange() || disableBtn() }
                      />
                      <CommonButtonComponent
                        label={'Clone'}
                        appendWrapperClassName='btnPrimaryWrapper'
                        appendButtonClassName='btnPrimary'
                        onClickCTA={() => {
                          navigate(`/campaign/${campaignId}/placement/new`, {
                            state: {
                              placementData: usablePlacementData,
                              isClonePlacement: true,
                            },
                          });
                        }}
                      />
                      <CommonButtonComponent
                        label={'Cancel'}
                        appendWrapperClassName='btnPrimaryWrapper'
                        appendButtonClassName='btnSecondary'
                        onClickCTA={handleCancel}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
        {isDupModalOpen && (
          <CommonModal
            show={isDupModalOpen}
            header={'Duplicate Placement Name'}
            body={<DupNameBodyJSX />}
            hideCloseBtn={true}
            size='md'
            acceptBtnText='Save'
            rejectBtnText='Cancel'
            accept={() => {
              setFormData({
                ...formData,
                name: dupNameModalData.suggestedName,
              });
              setIsDupModalOpen(false);
              handleSave(dupNameModalData.suggestedName);
            }}
            reject={() => {
              setIsDupModalOpen(false);
            }}
          />
        )}
        {isEpisodeConfirm && (
          <CommonModal
            show={isEpisodeConfirm}
            header={'Confirmation'}
            body={<EpiConfirmationJSX />}
            hideCloseBtn={true}
            size='lg'
            acceptBtnText='Save'
            rejectBtnText='Cancel'
            accept={() => {
              const { includes, excludes } = getIncludesAndExcludes();
              setIncludeIds(includes);
              setExcludeIds(excludes);
              setDisableSave(false);
              setIsEpisodeConfirm(false);
              setSaveEpisodeMount(true);
            }}
            reject={() => {
              if (isAdminOrAbove) {
                sessionStorage.removeItem('episodeData');
                setIsEpiModalOpen(false);
              }
              setIsEpisodeConfirm(false);
            }}
          />
        )}
        {isEpiModalOpen && (
          <CommonModal
            show={isEpiModalOpen}
            header={`${showName} Show: Episodes`}
            modalHeaderStyle={modalHeaderStyle}
            body={
              <EpisodeList
                episodeList={episodeList}
                pubStartDate={pubStartDate}
                pubEndDate={pubEndDate}
                campaignId={campaignId}
                placementId={placementId}
                isAdminOrAbove={isAdminOrAbove}
              />
            }
            hideCloseBtn={true}
            size='lg'
            acceptBtnText='Save'
            rejectBtnText='Cancel'
            accept={() => {
              const { includes, excludes } = getIncludesAndExcludes();
              const includeIdsArr = includeIds?.map((item) => item.id);
              if (
                (isAdminOrAbove &&
                  includeIdsArr?.some((item) => {
                    return !includes?.includes(item);
                  })) ||
                includes.some((item) => {
                  return !includeIdsArr?.includes(item);
                })
              ) {
                setIsEpisodeConfirm(true);
              }
              setIsEpiModalOpen(false);
            }}
            reject={
              isAdminOrAbove
                ? () => {
                    sessionStorage.removeItem('episodeData');
                    setIsEpiModalOpen(false);
                  }
                : null
            }
          />
        )}
        {ispubEndDateModal && (
          <CommonModal
            show={ispubEndDateModal}
            header={'Confirmation'}
            body={<ConfirmationJSX />}
            hideCloseBtn={true}
            size='md'
            acceptBtnText='Ok'
            rejectBtnText='Cancel'
            accept={() => {
              setIspubEndDateModal(false);
            }}
            reject={() => {
              setPubEndDate(prePubDate);
              setIspubEndDateModal(false);
            }}
          />
        )}
        {isNameChangeModal && (
          <CommonModal
            show={isNameChangeModal}
            header={'Confirmation'}
            body={<ConfirmationJSX isNameChange />}
            hideCloseBtn={true}
            size='sm'
            acceptBtnText='Ok'
            rejectBtnText='Cancel'
            accept={() => {
              setIsNameChangeModal(false);
            }}
            reject={() => {
              setIsNameChangeModal(false);
              setFormData({ ...formData, name: preName });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EditPlacement;
