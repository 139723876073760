import React from 'react';

export default (cta) =>{
  return [
  {
    id: 'goal_id',
    name: 'ID',
    selector: (row) => (
      <div className='tableCellFontStyle tableCampNameFontStyle'>
        <div onClick={()=>{cta(row.goal_id)}}>
          <div className='goalIdStylePDGL pointerCursor'>{row.goal_id}</div>
        </div>
      </div>
    ),
    sortable: true, // sorting
    sortField: 'goal_id', // sorting
    minWidth: '150px !important',
  },
  {
    id: 'goal_name',
    name: 'Goal Name',
    selector: (row) => (
      <div className='tableCellFontStyle tableCampNameFontStyle'>
        <div onClick={()=>{cta(row.goal_id)}}>
          <div className='goalIdStylePDGL pointerCursor'>{row.goal_name}</div>
        </div>
      </div>
    ),
    sortable: true,
    sortField: 'goal_name',
    minWidth: '215px !important',
  },
  {
    id: 'goal_type',
    name: 'Goal Type',
    selector: (row) => (
      <div className='tableCellFontStyle capitalizeCell'>{row.goal_type}</div>
    ),
    sortable: false, // sorting
    minWidth: '200px !important',
  },
  {
    id: 'description',
    name: 'Description',
    selector: (row) => (
      <div className='tableCellFontStyle capitalizeCell'>{row.description}</div>
    ),
    sortable: false,
    minWidth: '245px !important',
  },
  {
    id: 'postback',
    name: 'Postback',
    selector: (row) => <div className='tableCellFontStyle capitalizeCell'>{row.postback}</div>,
    sortable: false,
    // width: '18%',
    minWidth: '240px !important',
  },
]}
