import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { breadCrumbHeader } from '../../constants'
import './users.css'

import { UsersList, UserEdit } from './components'
import { CommonButtonComponent } from '../../components'

import { updateAdvertiserMode, resetAdvertiserMode } from '../../redux/utilitybarSlice'
import { updateSelectedAdvertiser } from '../../redux/advertiserSlice'

// Main User Component, this will handle Listing and Edit/Create User Page
const Users = () => {
  document.title = 'Users' // Page title
  const { userId } = useParams() // GET params from URL
  const [heading, setHeading] = useState('Users') // Page heanding, will be shared as props in child components
  const [componentToLoad, setComponentToLoad] = useState(<UsersList />) // state to hold which component to load, on the basis of URL path
  const [showForm, setShowForm] = useState(false) // boolean to show/hide 'create user' button
  const [userName, setUserName] = useState('');
  const dispatch = useDispatch() // dispatch to enable/disable advertiser drop-down

  /**
   *   * Single useEffect to update/toggle child components on the basis of props{userId} change
   */
  useEffect(() => {
    // Disable advertiser dropdown from app state
    dispatch(updateAdvertiserMode(true)) // Disable advertiser dropdown
    dispatch(updateSelectedAdvertiser(0)) // Select selected advertiser to All

    // condition to load Listing or Edi
    if (userId) {
      setComponentToLoad(
        <UserEdit
          userId={userId}
          setHeading={setHeading}
          getUserName={setUserName}
        />
      )
      setShowForm(true)
    } else {
      setHeading('Users')
      setComponentToLoad(<UsersList />)
      setShowForm(false)
    }

    return () => {
      dispatch(resetAdvertiserMode()) // Enable advertiser dropdown
    }
  }, [userId])

  return (
    <React.Fragment>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <Link to='/'>
            <div className='homeBlue'></div>
          </Link>
          {showForm ?
					  <>
						<div className='breadCrumbBody'>
						/
						<Link to='/users'>
						<div className='active'>{breadCrumbHeader.users}</div>
						</Link>
						</div>
						<div className='breadCrumbBody'>
							<span>/</span>
							{heading}
						</div>
					  </>
					:
					<div className="breadCrumbBody">
						<span>/</span>
						{breadCrumbHeader.users}
					</div>
					}
        </div>
         {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>{heading}</h1>
          {!showForm
            ? (
                <Link
                  to='/users/new'
                  className='createAdvertiserBtn decorationNone'
                >
                  <CommonButtonComponent
                    label='Create User'
                    isDisabled={false}
                    showLoader={false}
                    onClickCTA={() => {}}
                  />
                </Link>
              )
            : (
                ''
              )}
        </div> */}
      </div>

      {componentToLoad}
    </React.Fragment>
  )
}

export default Users
