import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedClient } from '../../../redux/clientSlice';
import {
  updateSelectedAdvertiser,
  updateSelectedClientsAdvertiser
} from '../../../redux/advertiserSlice';
import { axiosPatchRequest } from '../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../constants';
import './headerComponent.css';
import SearchableDropdownComponent from '../../searchableDropdown/SearchableDropdown.component';

const HeaderComponent = () => {
  const user = useSelector((state) => state.user);
  const client = useSelector((state) => state.client);
  const advertiser = useSelector((state) => state.advertiser);
  const clientList = client.clientList;
  const selectedClientId = client.selectedClient; //Get the selected client ID
  const disableClient = useSelector(
    (state) => state.utilitybarMode.clientReadOnly
  );
  const dispatch = useDispatch();
  
  const [currentClient, setCurrentClient] = useState(null); //updating the state with the selected client or else defaulting to zeroth client entry 

  useEffect(() => {
    const selectedItem = clientList.filter(f => f.user_id === selectedClientId); // Filter Client List based on the Selected Client ID and update with user ID

    setCurrentClient(selectedItem.length > 0 ? selectedItem[0] : client.clientList[0])
  }, [selectedClientId]);
  

  const saveClient = async (value ) => {
              const newSelectedClient = Number(value);
              dispatch(updateSelectedClient(newSelectedClient));
              dispatch(updateSelectedAdvertiser(0)); //Reset selected adertiser
              dispatch(
                updateSelectedClientsAdvertiser({
                  userId: newSelectedClient,
                  advertiserList: advertiser.advertiserList,
                })
              );
              // resetting the advertiser to first advertiser in client list when user changes the client
              const selectedClientAdvertisers =
                advertiser.advertiserList.filter(
                  (adv) => adv.user_id === newSelectedClient
                );
              dispatch(
                updateSelectedAdvertiser(selectedClientAdvertisers[0].id)
              );
              if (selectedClientAdvertisers[0].id === 0) {
                await axiosPatchRequest(
                  `${baseEndpoints.users}/${user?.uid}/default-client-advertiser`,
                  null,
                  {
                    user_id: newSelectedClient,
                    advertiser_id: 0 - newSelectedClient,
                  }
                );
              } else {
                await axiosPatchRequest(
                  `${baseEndpoints.users}/${user?.uid}/default-client-advertiser`,
                  null,
                  {
                    user_id: newSelectedClient,
                    advertiser_id: selectedClientAdvertisers[0].id,
                  }
                );
              }
            }

  return (
    <div className="headerBar">
      <div className="innerHeaderBar">
        <div className="clientSelector" id="headerClientSelect">
          <label className="clientAccount">Client Account</label>
          <SearchableDropdownComponent
            options={client.clientList ?? []}
            label="organization"
            id="user_id"
            dropdownId="clientSelect"
            labelWidth={{ width: "0px" }}
            dropdownWidth={{
              width: "calc(100% - 80px)",
              minWidth: "250px",
            }}
            selectedValue={currentClient}
            handleChange={(val) => {
              if (
                (!currentClient && val) ||
                (val && currentClient.user_id !== val.user_id)
              ) {
                setCurrentClient(val);
                saveClient(val.user_id);
              }
            }}
            wrapperClass="clientDropdown"
            isDisabled={disableClient}
          />
        </div>
        <div className="userInfo">
          <label>Your Account</label>
          <span className="accountInfo leftMargin">{user?.email}</span>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
