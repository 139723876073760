import React, { Component } from 'react';
import HeaderCellWrapper from './HeaderCellWrapper';


export default class TreeTableHeader extends Component {

  render() {
    const { columns } = this.props;
    let keyIndex = 0;
    return (
      <thead>
        <tr className="cp_tree-table_header">
          {columns.map((column) => {
            const currentIndex = keyIndex;
            keyIndex++;
            return (
              <HeaderCellWrapper key={currentIndex}
                renderHeaderCell={column.renderHeaderCell} 

                grow={column.grow}
                basis={column.basis}/>
            );
          })}
        </tr>
      </thead>
    );
  }
}


const STYLE_ROW = {
  display: 'flex',

  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
};
