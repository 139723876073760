import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import clientReducer from './clientSlice';
import advertiserReducer from './advertiserSlice';
import utilitybarSlice from './utilitybarSlice';
import blockingLoaderSlice from './blockingLoader';

export default configureStore({
  reducer: {
    user: userReducer,
    client: clientReducer,
    advertiser: advertiserReducer,
    utilitybarMode: utilitybarSlice,
    blockingLoader: blockingLoaderSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
