import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import { Container, Row, Col } from 'react-bootstrap';
import { baseEndpoints, lineChartsColors } from '../../../../../../constants';
import { dateFormat, getAllSelectedOrNot, getArrayOfData } from '../../../../../../utils/functions';
import {
  ErrorComponent,
  LoaderComponent,
  MultiselectDropdownComponent,
  SearchableDropdownComponent,
} from '../../../../../../components';
import RenderAnalysisLineChart from '../../mediaDeliveryTab/analysisSection/components/analysisLineChart/RenderAnalysisLineChart';

export default function PartnersEngagementsByGoal({
  campaign,
  type,
  label,
  uniqueIdentifier,
  mediaPartnerList,
  selectedMediaPartner,
  placementList,
  selectedPlacement,
  creativeTypeList,
  selectedCreativeType,
  typeOfData,
  startDate,
  endDate,
  numberOfDays,
  goalType,
  isLoadingBase,
  ifSelectedAll,
  align = 'left'
}) {
  const [goalsList, setGoalsList] = useState([]),
    [isGoalListLoading, setGoalListLoading] = useState(true),
    [selectedGoal, setSelectedGoal] = useState(null);

  const [partnersList, setPartnersList] = useState([]),
    [isPartnerLoading, setPartnerLoading] = useState(true),
    [selectedPartners, setSelectedPartners] = useState(null);

  const [data, setData] = useState([]),
    [isDataLoading, setDataLoading] = useState(true);

  const [isError, setIsError] = useState(false),
    [status, setStatus] = useState(200),
    [cancelController, setCancelController] = useState(null);

  const getGoalList = async (controller) => {
    setGoalListLoading(true);
    setIsError(false);
    try {
      if (campaign?.campaign_id) {
        const response = await axiosSendRequest(
          `${baseEndpoints.reports}/attribution-tab/analysis/goals-by-placement`,
          controller.signal,
          {
            campaign_id: campaign.campaign_id,
            goal_type: goalType,
            type_of_data: typeOfData,
            if_all_partners: getAllSelectedOrNot(mediaPartnerList, selectedMediaPartner, 'partner_id'),
            if_all_placements: getAllSelectedOrNot(placementList, selectedPlacement, 'placement_id'),
            if_all_creative_types: getAllSelectedOrNot(creativeTypeList, selectedCreativeType, 'value'),
            partner_id: getArrayOfData(selectedMediaPartner, 'partner_id'),
            placement_id: getArrayOfData(selectedPlacement, 'placement_id'),
            creative_type_id: getArrayOfData(selectedCreativeType, 'name'),
            start_date: dateFormat(startDate,'YYYY-MM-DD'),
            end_date: dateFormat(endDate,'YYYY-MM-DD'),
          }
        );
        setStatus(response.status);
        if (response.status === 200 && data) {
          setGoalsList(response?.data);
          setSelectedGoal(response?.data[0]);
        } else {
          setSelectedGoal(null);
          setPartnerLoading(false);
          setDataLoading(false)
          setIsError(true);
        }
      }
      setGoalListLoading(false);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        if (err.response) {
          setStatus(err.response.status);
        } else {
          setStatus(500);
        }
        setGoalListLoading(false);
      }
    }
  };

  const getPartnerList = async (controller) => {
    setPartnerLoading(true);
    setIsError(false);
    try {
      if (campaign?.campaign_id) {
        const response = await axiosSendRequest(
          `${baseEndpoints.reports}/attribution-tab/analysis/partners-by-goal`,
          controller.signal,
          {
            campaign_id: campaign.campaign_id,
            goal_id: selectedGoal?.goal_id,
            type_of_data: typeOfData,
            if_all_partners: getAllSelectedOrNot(mediaPartnerList, selectedMediaPartner, 'partner_id'),
            if_all_placements: getAllSelectedOrNot(placementList, selectedPlacement, 'placement_id'),
            if_all_creative_types: getAllSelectedOrNot(creativeTypeList, selectedCreativeType, 'value'),
            partner_id: getArrayOfData(selectedMediaPartner, 'partner_id'),
            placement_id: getArrayOfData(selectedPlacement, 'placement_id'),
            creative_type_id: getArrayOfData(selectedCreativeType, 'name'),
            start_date: dateFormat(startDate,'YYYY-MM-DD'),
            end_date: dateFormat(endDate,'YYYY-MM-DD'),
          }
        );
        const data = response?.data;
        setStatus(response.status);
        if (response.status === 200 && data) {
          setPartnersList(data);
          setSelectedPartners(data);
        } else {
          setPartnersList([]);
          setSelectedPartners(null);
          setData([])
          setDataLoading(false)
        }
      }
      setPartnerLoading(false);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        if (err.response) {
          setStatus(err.response.status);
        } else {
          setStatus(500);
        }
        setPartnerLoading(false);
      }
    }
  };

  const getEngagementOrConversionData = async (controller) => {
    setDataLoading(true);
    setIsError(false);
    try {
      const response = await axiosSendRequest(
        `${baseEndpoints.reports}/attribution-tab/analysis/engagement-converison-count-by-partners`,
        controller.signal,
        {
          campaign_id: campaign.campaign_id,
          goal_id: selectedGoal?.goal_id,
          placement_id: selectedPlacement
            ? getArrayOfData(selectedPlacement, 'placement_id')
            : [],
          partner_id: selectedPartners
            ? getArrayOfData(selectedPartners, 'partner_id')
            : [],
          interval_type: numberOfDays?.id,
          start_date: dateFormat(startDate, 'YYYY-MM-DD'),
          end_date: dateFormat(endDate, 'YYYY-MM-DD'),
          campaign_start_date: dateFormat(campaign?.start_date, 'YYYY-MM-DD'),
          campaign_end_date: dateFormat(campaign?.end_date, 'YYYY-MM-DD'),
        }
      );
      const data = response?.data;
      setStatus(response.status);
      if (response.status === 200 && data) {
        setData(data);
      } else {
        setData([]);
      }
      setDataLoading(false);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        if (err.response) {
          setStatus(err.response.status);
        } else {
          setStatus(500);
        }
        setDataLoading(false);
      }
    }
  };

  const checkError = () => {
    return isError ? (
      <ErrorComponent
        controller={cancelController}
        status={status}
        refreshAction={[
          getGoalList,
          getPartnerList,
          getEngagementOrConversionData,
        ]}
      />
    ) : (
      renderComponent()
    );
  };

  const renderComponent = () => (
    <RenderAnalysisLineChart
      data={data}
      height={300}
      xAxisDataKey={'est_dte'}
      isDate={true}
      colors={lineChartsColors}
      showVertical={false}
      yAxisLine={false}
      lineStrokeWidth={2}
      isCustomLabel={true}
      customLabelOne={'Partner'}
      customLabelTwo={`${label}`}
      onlyForAnalysisLine={true}
      customLayerStyle={{ backgroundSize: '50%' }}
      customImageStyle={{ backgroundSize: '25%' }}
    />
  );

  useEffect(() => {
    setGoalsList([]);
    setPartnersList([]);
    setData([]);
    if (campaign && selectedPlacement?.length > 0 && startDate && endDate) {
      const controller = new AbortController();
      setCancelController(controller);
      getGoalList(controller);
      return () => {
        controller.abort();
      };
    }else{
      setGoalListLoading(false);
      setPartnerLoading(false);
      setDataLoading(false)
    }
  }, [campaign, selectedPlacement, startDate, endDate, typeOfData]);

  useEffect(() => {
    setPartnersList([]);
    setSelectedPartners(null);
    setData([]);
    if (selectedGoal && startDate && endDate) {
      const controller = new AbortController();
      setCancelController(controller);
      getPartnerList(controller);
      return () => {
        controller.abort();
      };
    }
  }, [selectedGoal, startDate, endDate]);

  useEffect(() => {
    setData([]);
    if (campaign && selectedPartners) {
      const controller = new AbortController();
      setCancelController(controller);
      getEngagementOrConversionData(controller);
      return () => {
        controller.abort();
      };
    }
  }, [selectedPartners, numberOfDays]);

  return (
    <div className='attrAnalysisLineChartWrapper'>
      <Container fluid>
        <Row>
          <Col style={{ textAlign: 'center', marginTop: 2 + 'em' }}>
            <Container fluid style={{ margin: 0 }}>
              <Row>
                <Col>
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      marginBottom: '10px',
                    }}
                  >{`${label} for Goal`}</div>
                </Col>
              </Row>
              <Row style={{textAlign: 'left'}}>
                <Col lg={6} md={6} sm={6}>
                  <div style={{ float: 'right' }}>
                    <SearchableDropdownComponent
                      options={goalsList}
                      label='goal_name'
                      id='goal_id'
                      dropdownId={'goalsDropdown' + type}
                      dropdownLabel={'Goal'}
                      labelWidth={{ width: '40px', marginRight: '5px' }}
                      dropdownWidth={{
                        width: '155px',
                      }}
                      placeholderLabel={
                        !goalsList?.length ? 'Goal Unavailable' : 'Select Goal'
                      }
                      selectedValue={selectedGoal}
                      handleChange={setSelectedGoal}
                      isSearchable={goalsList?.length > 3}
                      isLoading={isGoalListLoading}
                      isDisabled={!goalsList?.length}
                      uniqueIdentifier={uniqueIdentifier + label + 'Goals'}
                      ifIdVisableOnLabel={true}
                      alignType={align}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <div style={{width: 'fit-content'}}>
                    <MultiselectDropdownComponent
                      dropdownId={'partnersDropdown' + type}
                      dropdownDataList={partnersList}
                      dataId='partner_id'
                      dataName='partner_name'
                      label='By Partner'
                      dropdownData={selectedPartners}
                      setDropdownData={(e) => {
                        if (e?.length) {
                          setSelectedPartners(e);
                          return;
                        }
                        setPartnersList([...partnersList]);
                      }}
                      ifIdSearchAvailable={true}
                      placeholder={'Partner'}
                      txtSearch='Search by Partner'
                      isAllCheckedInitially={true}
                      labelWidth={{ width: '86px' }}
                      dropdownWidth={{
                        width: '155px',
                      }}
                      isLoading={isPartnerLoading}
                      isDisabled={!partnersList?.length}
                      uniqueIdentifier={uniqueIdentifier + label + 'Partners'}
                      alignType={align}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} style={{ minHeight: '20.5em' }}>
            {isGoalListLoading ||
            isPartnerLoading ||
            isDataLoading ||
            isLoadingBase ||
            !ifSelectedAll ? (
              <LoaderComponent headerClass='attributionAnalysisSectionPartnersLC' />
            ) : (
              checkError()
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
