import React from 'react';
import { Link } from 'react-router-dom';
import {
  dateFormat,
  formatNumberInThousands,
  getEncryptedOrDecryptedData,
} from '../../../../utils/functions';

export default [
  {
    id: 'campaignId',
    name: 'CAMPAIGN ID',
    selector: (row) => (
      <div className='accountListCellWrapper'>
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={
              '/campaign/' +
              row?.campaign_id +
              '?params=' +
              getEncryptedOrDecryptedData(
                {
                  name: row?.campaign_name,
                  isFromAdmin: 1,
                  adminAdvId: row?.advertiser_id,
                  adminClientId: row?.client_id,
                },
                'encrypt'
              )
            }
          >
            <div className='linkStyleLR'>{row?.campaign_id ?? 0}</div>
          </Link>
        </div>
      </div>
    ),
    sortable: true,
    sortField: 'campaignId',
  },
  {
    id: 'campaignName',
    name: 'CAMPAIGN NAME',
    selector: (row) => (
      <div className='accountListCellWrapper'>
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={
              '/campaign/' +
              row?.campaign_id +
              '?params=' +
              getEncryptedOrDecryptedData(
                {
                  name: row?.campaign_name,
                  isFromAdmin: 1,
                  adminAdvId: row?.advertiser_id,
                  adminClientId: row?.client_id,
                },
                'encrypt'
              )
            }
          >
            <div className='linkStyleLR'>{row?.campaign_name}</div>
          </Link>
        </div>
      </div>
    ),
    sortable: true,
    sortField: 'campaignName',
  },
  {
    id: 'account',
    name: 'ACCOUNT',
    selector: (row) => (
      <div className='tableCellFontStyle campaignLookupAccANdAdv campaignLookupNonCTAColls'>
        {row.account}
      </div>
    ),
    sortable: true,
    sortField: 'account',
  },
  {
    id: 'advertiser',
    name: 'ADVERTISER',
    selector: (row) => (
      <div className='tableCellFontStyle campaignLookupAccANdAdv campaignLookupNonCTAColls'>
        {row.advertiser}
      </div>
    ),
    sortable: true,
    sortField: 'advertiser',
  },
  {
    id: 'startDate',
    name: 'START DATE',
    selector: (row) => {
      if (row?.start_date) {
        return (
          <div className='tableCellFontStyle campaignLookupNonCTAColls'>
            {dateFormat(row?.start_date.substring(0, 10), 'MM/DD/YYYY')}
          </div>
        );
      }
      return (
        <div className='tableCellFontStyle campaignLookupInvalidDateElemSD campaignLookupNonCTAColls'>
          -
        </div>
      );
    },
    sortable: true,
    sortField: 'startDate',
  },
  {
    id: 'endDate',
    name: 'END DATE',
    selector: (row) => {
      if (row?.end_date) {
        return (
          <div className='tableCellFontStyle campaignLookupNonCTAColls'>
            {dateFormat(row?.end_date.substring(0, 10), 'MM/DD/YYYY')}
          </div>
        );
      }

      return (
        <div className='tableCellFontStyle campaignLookupInvalidDateElemED campaignLookupNonCTAColls'>
          -
        </div>
      );
    },
    sortable: true,
    sortField: 'endDate',
  },
  {
    id: 'impressionCount',
    name: 'IMPRESSION COUNT',
    selector: (row) => (
      <div className='tableCellFontStyle campaignLookupNonCTAColls'>
        {formatNumberInThousands(row?.imp_count ?? 0)}
      </div>
    ),
    sortable: true,
    sortField: 'impressionCount',
  },
  {
    id: 'status',
    name: 'STATUS',
    selector: (row) => (
      <div
        className={`tableCellFontStyle status${row.status} campaignLookupNonCTAColls`}
      >
        {row?.status}
      </div>
    ),
    sortable: false,
  },
];
