import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import {
  axiosGetRequest,
  axiosSendRequest,
} from '../../../../services/http/axiosCalls';
import { baseEndpoints, presentTimeFrameList } from './../../../../constants';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  DatePickerComponent,
  ErrorComponent,
  MultiselectDropdownComponent,
  SearchableDropdownComponent,
} from '../../../../components';
import {
  checkCondition,
  checkSelectAll,
  dataHandlingForLS,
  daysToFormatWRTDates,
  getArrayOfData,
  getCurrentESTDate,
  getStartAndEndDate,
  setPlaceHolderLabel,
} from '../../../../utils/functions';
import './runReport.css';

const presentTimeFrameOptions = [...presentTimeFrameList],
  nullImpFreqWarn =
    'There are 0 impressions during this time for this Campaign.',
  defaultDateFormat = 'YYYY-MM-DD';

const RunReportComponent = () => {
  const currentDate = getCurrentESTDate(defaultDateFormat);

  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser);

  const placementListRef = useRef(null),
    placementRef = useRef(null),
    mediaPartnerListRef = useRef(null),
    mediaPartnerRef = useRef(null),
    creativeTypeListRef = useRef(null),
    creativeTypeRef = useRef(null);

  const [campaignList, setCampaignList] = useState(null),
    [campaign, setCampaign] = useState(null),
    [campaignListLoading, setCampaignListLoading] = useState(false);

  const [placementList, setPlacementList] = useState(null),
    [placement, setPlacement] = useState(null),
    [placementListLoading, setPlacementListLoading] = useState(false);

  const [mediaPartnerList, setMediaPartnerList] = useState(null),
    [mediaPartner, setMediaPartner] = useState(null),
    [mediaPartnerListLoading, setMediaPartnerListLoading] = useState(false);

  const [creativeTypeList, setCreativeTypeList] = useState(null),
    [creativeType, setCreativeType] = useState(null),
    [creativeTypeListLoading, setCreativeTypeListLoading] = useState(false);

  const [timeFrame, setTimeFrame] = useState(null),
    [dateRange, setDateRange] = useState([null, null]);

  const [cancelController, setCancelController] = useState(null),
    [isError, setIsError] = useState(false),
    [isDisabled, setIsDisabled] = useState({
      campaign: true,
      mediaPartner: true,
      placement: true,
      creativeType: true,
    }),
    [isButtonDisabled, setIsButtonDisabled] = useState(true),
    [isFiredRun, setIsFiredRun] = useState(false);

  const [showPartnerDropdown, setShowPartnerDropdown] = useState(false),
    [showCreativeTypeDropdown, setShowCreativeTypeDropdown] = useState(false),
    [showPlacementDropdown, setShowPlacementDropdown] = useState(false);

  const getCampaignList = async (controller) => {
    setIsError(false);
    setCampaignListLoading(true);

    try {
      const campaignListPromise = axiosGetRequest(
          `${baseEndpoints.reports}/campaign-list`,
          controller.signal,
          {
            timezone: null,
            user_id: client.selectedClient,
            advertiser_id: advertiser.selectedAdvertiser,
          }
        ),
        campaignListResponse = await campaignListPromise;

      if (campaignListResponse.status === 200) {
        setCampaignList(campaignListResponse.data);

        setIsDisabled({
          ...isDisabled,
          campaign: campaignListResponse.data.length <= 0,
          mediaPartner: true,
          placement: true,
          creativeType: true,
        });
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsError(true);
      }
    } finally {
      setCampaignListLoading(false);
    }
  };

  const getPartnerList = async (controller) => {
    setIsError(false);
    setMediaPartnerListLoading(true);
    setCreativeTypeListLoading(true);
    setPlacementListLoading(true);
    try {
      const partnerListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/partner-list`,
        controller.signal,
        {
          campaign_id: campaign.campaign_id,
        }
      );
      if (partnerListResponse.status === 200) {
        const dataListPartner =
          partnerListResponse.data.length > 0 ? partnerListResponse.data : null;
        setMediaPartner(dataListPartner);
        setMediaPartnerList(dataListPartner);
        setIsDisabled({
          ...isDisabled,
          mediaPartner: partnerListResponse.data.length <= 0,
        });
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsError(true);
      }
    } finally {
      setMediaPartnerListLoading(false);
      setCreativeTypeListLoading(false);
      setPlacementListLoading(false);
    }
  };

  const getCreativeTypeList = async (controller) => {
    setIsError(false);
    setCreativeTypeListLoading(true);
    setPlacementListLoading(true);

    try {
      const creativeTypeListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/creative-type-list`,
        controller.signal,
        {
          campaign_id: campaign.campaign_id,
          partner_id: mediaPartner
            ? getArrayOfData(mediaPartner, 'partner_id')
            : null,
        }
      );
      if (creativeTypeListResponse.status === 200) {
        const dataListCreativeType =
          creativeTypeListResponse.data.length > 0
            ? creativeTypeListResponse.data
            : null;

        setCreativeType(dataListCreativeType);

        setCreativeTypeList(dataListCreativeType);

        setIsDisabled({
          ...isDisabled,
          creativeType: creativeTypeListResponse.data.length <= 0,
        });
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsError(true);
      }
    } finally {
      setCreativeTypeListLoading(false);
      setPlacementListLoading(false);
    }
  };

  const getPlacementList = async (controller) => {
    setIsError(false);
    setPlacementListLoading(true);
    try {
      const placementListResponse = await axiosSendRequest(
        `${baseEndpoints.reports}/placement-list`,
        controller.signal,
        {
          campaign_id: campaign.campaign_id,
          partner_id: mediaPartner
            ? getArrayOfData(mediaPartner, 'partner_id')
            : null,
          creative_type: creativeType
            ? getArrayOfData(creativeType, 'name')
            : null,
        }
      );

      if (placementListResponse.status === 200) {
        const dataListPlacement =
          placementListResponse.data.length > 0
            ? placementListResponse.data
            : null;
        setPlacementList(dataListPlacement);
        setPlacement(dataListPlacement);

        setIsDisabled({
          ...isDisabled,
          placement: placementListResponse.data.length <= 0,
        });
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err);
        setIsError(true);
      }
    } finally {
      setPlacementListLoading(false);
    }
  };

  const resetAll = () => {
    setCampaign(null);
    setTimeFrame(null);
    setMediaPartner(null);
    setMediaPartnerList(null);
    setPlacement(null);
    setPlacementList(null);
    setCreativeType(null);
    setCreativeTypeList(null);
    setDateRange([null, null]);
    setIsDisabled({
      ...isDisabled,
      mediaPartner: true,
      placement: true,
      creativeType: true,
    });
  };

  const onClickRun = () => {
    const timestamp = new Date().getTime(),
      paramObj = {
        user_id: client.selectedClient,
        advertiser_id: advertiser.selectedAdvertiser,
        campaign_details: campaign,
        placement_details: placement,
        partner_details: mediaPartner,
        creative_type_details: creativeType,
        time_frame: timeFrame?.id,
        start_date: timeFrame?.id === 0 ? dateRange[0] : null,
        end_date: timeFrame?.id === 0 ? dateRange[1] : null,
      };

    dataHandlingForLS({
      type: 'allReportsAddOrCreate',
      nameAll: 'AllReports',
      nameReport: `report_${timestamp}`,
      timestamp,
    });

    resetAll();

    dataHandlingForLS({
      type: 'saveAReport',
      nameReport: `report_${timestamp}`,
      reportData: paramObj,
    });

    window.open(`/reports/run/?rd=report_${timestamp}`, '_blank', 'noreferrer');
  };

  useEffect(() => {
    resetAll();
    setIsDisabled({
      ...isDisabled,
      campaign: true,
      mediaPartner: true,
      placement: true,
      creativeType: true,
    });
    if (advertiser.selectedAdvertiser !== -1) {
      const controller = new AbortController();
      setCancelController(controller);
      getCampaignList(controller);
      return () => {
        controller.abort();
      };
    } else {
      setTimeout(() => {
        setCampaignList(null);
        setMediaPartnerList(null);
        setPlacementList(null);
        setCreativeTypeList(null);
      }, 100);
    }
  }, [client, advertiser]);

  useEffect(() => {
    setPlacement(null);
    setPlacementList(null);
    setIsDisabled({
      ...isDisabled,
      placement: true,
      mediaPartner: true,
      creativeType: true,
    });

    if (campaign) {
      const controller = new AbortController();

      setCancelController(controller);
      getPartnerList(controller);
      return () => {
        controller.abort();
      };
    } else {
      setDateRange([null, null]);
      setMediaPartnerListLoading(false);
    }
  }, [campaign]);

  useEffect(() => {
    if (campaign?.campaign_id) {
      const campaignStartDate = moment(
          campaign?.start_date ?? currentDate
        ).format(defaultDateFormat),
        campaignEndDate = moment(campaign?.end_date ?? currentDate).format(
          defaultDateFormat
        ),
        campaignLookbackDate = moment(
          campaign?.lookback_date ?? currentDate
        ).format(defaultDateFormat);

      presentTimeFrameOptions.map((option) => {
        const { newStartDate, newEndDate, isDisabled } = getStartAndEndDate(
          option.id,
          currentDate,
          campaignStartDate,
          campaignEndDate,
          campaignLookbackDate
        );

        option.dateRange = [newStartDate, newEndDate];
        option.isDisabled = isDisabled;
      });

      setTimeFrame({ ...presentTimeFrameOptions[1] });
    }
  }, [campaign?.campaign_id]);

  useEffect(() => {
    if (timeFrame) {
      setDateRange(timeFrame?.dateRange ?? [null, null]);
    } else {
      setDateRange([null, null]);
    }
  }, [timeFrame]);

  useEffect(() => {
    setPlacement(null);
    setPlacementList(null);
    setIsDisabled({
      ...isDisabled,
      placement: true,
      mediaPartner: true,
      creativeType: true,
    });

    if (campaign && mediaPartner && creativeType) {
      const controller = new AbortController();
      setCancelController(controller);
      getPlacementList(controller);
      return () => {
        controller.abort();
      };
    } else {
      setPlacementListLoading(false);
    }
  }, [mediaPartner, creativeType]);

  useEffect(() => {
    if (campaign && placement && mediaPartner && dateRange[0] && dateRange[1]) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [campaign, placement, mediaPartner, dateRange]);

  useEffect(() => {
    placementListRef.current = placementList ? [...placementList] : null;
  }, [placementList]);

  useEffect(() => {
    mediaPartnerListRef.current = mediaPartnerList
      ? [...mediaPartnerList]
      : null;
  }, [mediaPartnerList]);

  useEffect(() => {
    if (mediaPartner) {
      mediaPartnerRef.current = [...mediaPartner];
    }
  }, [mediaPartner]);

  useEffect(() => {
    if (placement) {
      placementRef.current = [...placement];
    }
  }, [placement]);

  useEffect(() => {
    creativeTypeListRef.current = creativeTypeList
      ? [...creativeTypeList]
      : null;
  }, [creativeTypeList]);

  useEffect(() => {
    if (creativeType) {
      creativeTypeRef.current = [...creativeType];
    }
  }, [creativeType]);

  useEffect(() => {
    if (isFiredRun) {
      setIsFiredRun(false);
      onClickRun();
    }
  }, [isFiredRun]);

  useEffect(() => {
    if (isError) {
      resetAll();
    }
  }, [isError]);

  useEffect(() => {
    if (mediaPartner) {
      const controller = new AbortController();
      getCreativeTypeList(controller);
      return () => {
        controller.abort();
      };
    } else {
      setCreativeTypeListLoading(false);
      setCreativeType(null);
      setCreativeTypeList(null);
      setIsDisabled({
        ...isDisabled,
        creativeType: true,
      });
    }
  }, [mediaPartner]);

  return (
    <div className='dashboardRunReportWrapper'>
      <CommonHeadingComponent headingLabel='Run a Report' />
      {isError ? (
        <ErrorComponent
          refreshAction={getCampaignList}
          controller={cancelController}
        />
      ) : (
        <Container
          fluid
          className='removePaddingOrMargin dashboardRunReportContainer'
        >
          <Row className='removePaddingOrMargin' id='runReportElementRow'>
            <Col
              xl={12}
              lg={12}
              md={6}
              sm={12}
              className='removePaddingOrMargin dashboardRunReportElement'
            >
              <SearchableDropdownComponent
                options={campaignList ?? []}
                label='campaign_name'
                id='campaign_id'
                dropdownId='campaignNameDB'
                dropdownLabel='Campaign'
                labelWidth={{ width: '85px', paddingRight: '10px', margin: 0 }}
                dropdownWidth={{
                  width: 'calc(100% - 85px)',
                }}
                placeholderLabel={setPlaceHolderLabel(
                  isDisabled.campaign,
                  'Campaign'
                )}
                selectedValue={campaign}
                handleChange={(val) => {
                  if (
                    (!campaign && val) ||
                    (val && campaign.campaign_id !== val.campaign_id)
                  ) {
                    setCampaign(val);
                  }
                }}
                isDisabled={isDisabled.campaign}
                uniqueIdentifier='dashboardRunReportCampaigns'
                isLoading={campaignListLoading}
                alignType='right'
                ifIdVisableOnLabel={true}
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={6}
              sm={12}
              className='removePaddingOrMargin dashboardRunReportElement'
            >
              <MultiselectDropdownComponent
                dropdownId='mediaPartnerDB'
                dropdownDataList={mediaPartnerList}
                dataId='partner_id'
                dataName='partner_name'
                label='Media Partner'
                dropdownData={mediaPartner}
                setDropdownData={(data) => {
                  if (data) {
                    setMediaPartner(data);
                  } else {
                    setMediaPartnerList(
                      mediaPartnerListRef.current
                        ? [...mediaPartnerListRef.current]
                        : null
                    );
                  }
                }}
                ifIdSearchAvailable={true}
                placeholder={setPlaceHolderLabel(
                  isDisabled.mediaPartner,
                  'Media Partner'
                )}
                txtSearch='Search by Partner'
                isAllCheckedInitially={checkSelectAll(
                  mediaPartnerList,
                  mediaPartner,
                  'partner_id'
                )}
                labelWidth={{ width: '115px', paddingRight: '10px', margin: 0 }}
                dropdownWidth={{
                  width: 'calc(100% - 115px)',
                }}
                isDisabled={
                  isDisabled.mediaPartner ||
                  showCreativeTypeDropdown ||
                  showPlacementDropdown
                }
                uniqueIdentifier='dashboardRunReportPartner'
                isOpen={setShowPartnerDropdown}
                isLoading={mediaPartnerListLoading}
                alignType='right'
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={6}
              sm={12}
              className='removePaddingOrMargin dashboardRunReportElement'
            >
              <MultiselectDropdownComponent
                dropdownId='creativeTypeDB'
                dropdownDataList={creativeTypeList}
                dataId='value'
                dataName='name'
                label='Creative Type'
                dropdownData={creativeType}
                setDropdownData={(data) => {
                  if (data) {
                    setCreativeType(data);
                  } else {
                    setCreativeTypeList(
                      creativeTypeListRef.current
                        ? [...creativeTypeListRef.current]
                        : null
                    );
                  }
                }}
                ifIdSearchAvailable={false}
                placeholder={setPlaceHolderLabel(
                  isDisabled.creativeType,
                  'Creative Type'
                )}
                txtSearch='Search by Creative Type'
                isAllCheckedInitially={checkSelectAll(
                  creativeTypeList,
                  creativeType,
                  'name'
                )}
                labelWidth={{ width: '115px', paddingRight: '10px', margin: 0 }}
                dropdownWidth={{
                  width: 'calc(100% - 115px)',
                }}
                isDisabled={
                  isDisabled.creativeType ||
                  showPartnerDropdown ||
                  showPlacementDropdown
                }
                uniqueIdentifier='dashboardRunReportCreativeTypes'
                isOpen={setShowCreativeTypeDropdown}
                isLoading={creativeTypeListLoading}
                alignType='right'
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={6}
              sm={12}
              className='removePaddingOrMargin dashboardRunReportElement'
            >
              <MultiselectDropdownComponent
                dropdownId='placementDB'
                dropdownDataList={placementList}
                dataId='placement_id'
                dataName='placement_name'
                dataType='type'
                label='Placement'
                dropdownData={placement}
                setDropdownData={(data) => {
                  if (data) {
                    setPlacement(data);
                  } else {
                    setPlacementList(
                      placementListRef.current
                        ? [...placementListRef.current]
                        : null
                    );
                  }
                }}
                ifIdSearchAvailable={true}
                placeholder={setPlaceHolderLabel(
                  isDisabled.placement,
                  'Placement'
                )}
                txtSearch='Search by Placement'
                isAllCheckedInitially={checkSelectAll(
                  placementList,
                  placement,
                  'placement_id'
                )}
                labelWidth={{ width: '90px', paddingRight: '10px', margin: 0 }}
                dropdownWidth={{
                  width: 'calc(100% - 90px)',
                }}
                isDisabled={
                  isDisabled.placement ||
                  showPartnerDropdown ||
                  showCreativeTypeDropdown
                }
                uniqueIdentifier='dashboardRunReportPlacements'
                isLoading={placementListLoading}
                isOpen={setShowPlacementDropdown}
                alignType='right'
                ifIdVisibleOnLabel={true}
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={6}
              sm={12}
              className='removePaddingOrMargin dashboardRunReportElement'
            >
              <SearchableDropdownComponent
                options={presentTimeFrameOptions}
                label='name'
                id='id'
                dropdownId='runAReportTimeFrame'
                dropdownLabel='Time Frame'
                labelWidth={{
                  width: '102px',
                  paddingRight: '10px',
                  margin: 0,
                }}
                dropdownWidth={{
                  width: 'calc(100% - 102px)',
                  minWidth: '173px',
                }}
                placeholderLabel={setPlaceHolderLabel(!campaign, 'Time Frame')}
                selectedValue={timeFrame}
                handleChange={setTimeFrame}
                isSearchable={false}
                isDisabled={!campaign}
                uniqueIdentifier='runAReportTimeFrames'
                ifShowWarningAtOptions={true}
                warningOptionId='isDisabled'
                warningMessage={'Warning! ' + nullImpFreqWarn}
                alignType='right'
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={6}
              sm={12}
              className='removePaddingOrMargin dashboardRunReportElement'
            >
              {timeFrame?.id === 0 && (
                <DatePickerComponent
                  datePickerId='runAReportDateRange'
                  label='Date Range'
                  labelWidth={{
                    width: '100px',
                    paddingRight: '10px',
                    margin: 0,
                  }}
                  datePickerBodyWidth={{
                    width: 'calc(100% - 100px)',
                    minWidth: '160px',
                  }}
                  placeholderLabel='Start Date - End Date'
                  minDate={campaign?.start_date}
                  maxDate={campaign?.lookback_date}
                  isDateRangePicker={true}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isDisabled={timeFrame?.id !== 0}
                  format='MM/DD/YYYY'
                  currentYear={checkCondition(
                    campaign &&
                      daysToFormatWRTDates(campaign?.lookback_date) <= 0,
                    moment(campaign?.lookback_date).year(),
                    moment(currentDate).year()
                  )}
                  startYear={checkCondition(
                    campaign,
                    moment(campaign?.start_date).year() - 1,
                    2010
                  )}
                  uniqueIdentifier='runAReport'
                />
              )}
            </Col>
            <Col className='removePaddingOrMargin dashboardRunReportElement dashboardRunReportButton'>
              <CommonButtonComponent
                label='Run Report'
                isDisabled={
                  isButtonDisabled ||
                  showPartnerDropdown ||
                  showCreativeTypeDropdown
                }
                onClickCTA={() =>
                  setTimeout(() => {
                    setIsFiredRun(true);
                  }, 50)
                }
                showLoader={false}
                buttonUI='dashboardRunReport'
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default RunReportComponent;
