import { useState, useEffect } from 'react';
import axios from '../services/axios/axios';

/**
 * @param {boolean} isMounted - whether component is mounted or not
 * @param {object} body - request body
 * @param {string} APIEndpoint - API URL to hit
 * @returns {Promise}
 */
const usePostAPIInterceptor = (isMounted, body, APIEndpoint, callback) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState(200); // response status

  useEffect(() => {
    if (isMounted) {
      const controller = new AbortController();
      const signal = controller.signal;

      const postData = () => {
        setIsLoading(true);
        try {
          axios()
            .post(APIEndpoint, { ...body }, signal)
            .then(
              (res) => {
                if (res.status === 200) {
                  console.info('Request Completed !! ', APIEndpoint);
                  const data = res;
                  setStatus(res.status);
                  setData(data);
                  setIsError(false);
                } else {
                  console.error('Request is not 200');
                  setIsError(true);
                  setStatus(res.status);
                }
                if (callback) callback(res.status, res, null);
              },
              (err) => {
                if (signal.aborted) {
                  console.info('Request was cancelled.');
                } else {
                  console.error(err);
                  setIsError(true);
                  setStatus(err.response.status);
                  if (callback)
                    callback(err.response.status, err.response.data, err);
                }

                setIsLoading(false);
              }
            );
          console.info('Request Initiated !! ', APIEndpoint);
        } catch (error) {
          console.error(error);
          setIsError(true);
          setStatus(500);
          setIsLoading(false);
          if (callback) callback(500, { data: error.message }, error.message);
        } finally {
          setIsLoading(false);
        }
      };

      postData();

      return () => {
        controller.abort();
      };
    }
  }, [body, APIEndpoint, isMounted]);

  return { data, isLoading, isError, status };
};

export { usePostAPIInterceptor };
