import React, { Component, Children } from 'react';
import Column from './Column';
import TreeTableHeader from './TreeTableHeader';
import VirtualList from './VirtualList';


const TABLE_DEFAULT_HEIGHT = 260;
export default class TreeTable extends Component{
  static Column = Column;
  render() {
    const { value, children, onScroll,
      height, headerHeight, className } = this.props;
    const columnsDef = [];
    Children
      .toArray(children)
      .forEach((node) => {
        if (isColumnElement(node)) {
          columnsDef.push(node.props);
        }
      });

    return (
      <table className={`cp_tree-table ${className != null && className}`}>
        <TreeTableHeader columns={columnsDef} height={headerHeight} />
        {value.hasData && (
          <VirtualList
            data={value}
            columns={columnsDef}
            height={Number(height) || TABLE_DEFAULT_HEIGHT}
            onChange={(value) => {
              const { onChange } = this.props;
              onChange(value);
            }}
            onScroll={onScroll}
          />
        )}
      </table>
    );
  }

}


function isColumnElement(elem) {
  return checkElementType(elem, Column);
}

function checkElementType(elem, cmpType) {
  return elem?.type?.displayName === cmpType.displayName;
}
