import React, { useEffect, useRef, useState } from 'react';
import { getEffectiveDropdownHeight } from '../../utils/functions';
import LoaderComponent from '../loaderComponent/Loader.component';
import './searchableDropdown.css';

/**
 *
 * @param {className} wrapperClass will add class to the wrapper eg. "requiredDD"
 * @param alignType type ['center', 'left', 'right', 'custom']
 * @returns
 */

const alignTypeClasses = {
  center: 'searchableDropdownSSCenterAligned',
  left: 'searchableDropdownSSLeftAligned',
  right: 'searchableDropdownSSRightAligned',
  custom: 'searchableDropdownMSCustomAligned',
};

const SearchableDropdownComponent = ({
  options,
  label,
  id,
  dataType = '',
  dropdownId,
  dropdownLabel,
  dropdownLabelClass,
  labelWidth,
  dropdownWidth,
  placeholderLabel,
  selectedValue,
  handleChange,
  isLoading = false,
  isDisabled = false,
  isSearchable = true,
  isDifferentBackground = false,
  differentBackgroundKey = null,
  wrapperClass,
  singleSelectDropdownFieldClass = '',
  loaderWidth = null,
  uniqueIdentifier,
  ifShowDisabledOptions = false,
  disabledOptionId = null,
  ifShowWarningAtOptions = false,
  warningOptionId = null,
  warningMessage = null,
  searchPlaceHolder = null,
  ifShowTitle = true,
  isLoadingAvailable = 1,
  isParentChildBasedOption = false,
  parentIdentificationKey = null,
  alignType = 'left', // alignType should be any of 'center', 'left', 'right', or 'custom'
  isHeaderHidden = false,
  isShowDropdownElemAlways = false,
  ifIdVisableOnLabel = false,
  excludeIdVisableOnLabel = 'no_id',
  allowClearItem = false,
  onClearSelection = null,
}) => {
  const [selectedOption, setSelectedOption] = useState(''),
    [selectedOptionId, setSelectedOptionId] = useState(''),
    [filteredData, setFilteredData] = useState([]),
    [isOpen, setIsOpen] = useState(false),
    [dropdownTransformValue, setDropdownTransformValue] = useState({
      transform: 'none',
    }),
    [offsetBottom, setOffsetBottom] = useState(0),
    [disabled, setDisabled] = useState(true),
    [searchedData, setSearchedData] = useState('');

  const inputRef = useRef(null),
    selectedChildRef = useRef(-1),
    lastSelectedChildRef = useRef(-1),
    ifScrolledRef = useRef(false),
    scrollPosCountUp = useRef(6),
    scrollPosCountDown = useRef(0),
    ifHovered = useRef(false);

  const selectOption = (option) => {
    setSelectedOption(option[label]);
    setSelectedOptionId(
      dataType === 'goal' && option.id
        ? option.id
        : option.goal_id
        ? option.goal_id
        : option.campaign_id
    );
    setFilteredData(options);
    setTransform(options, offsetBottom);
    handleChange(option);
    selectedChildRef.current = -1;
    if (!isShowDropdownElemAlways) {
      setIsOpen((isOpen) => !isOpen);
    }
  };

  const toggle = (e) => {
    if (
      e &&
      e.target !== inputRef.current &&
      e.target.className !== 'searchFieldSS'
    ) {
      const elem = document.querySelector(
        `#${dropdownId}.searchableDropdownWrapper .optionsWrapperSS`
      ).children[selectedChildRef.current];
      elem?.classList?.remove('ddSsOnKeyFocused');
      lastSelectedChildRef.current = selectedChildRef.current;
      selectedChildRef.current = -1;
      scrollPosCountUp.current = 6;
      scrollPosCountDown.current = 0;
      setSearchedData('');
      setIsOpen(false);
    }
  };

  const toggleUsingInput = () => {
    if (isOpen) {
      const elem = document.querySelector(
        `#${dropdownId}.searchableDropdownWrapper .optionsWrapperSS`
      ).children[selectedChildRef.current];
      elem?.classList?.remove('ddSsOnKeyFocused');
      lastSelectedChildRef.current = selectedChildRef.current;
      selectedChildRef.current = -1;
      scrollPosCountUp.current = 6;
      scrollPosCountDown.current = 0;
      setSearchedData('');
    }
    setIsOpen(!isOpen);
  };

  const setTransform = (list, ob) => {
    const effectiveDropdownHeight = getEffectiveDropdownHeight(
      list.length,
      6,
      30,
      isSearchable ? 43 : 5
    );

    if (effectiveDropdownHeight && ob <= effectiveDropdownHeight + 5) {
      setDropdownTransformValue({
        transform: `translate(0px, -${
          isSearchable
            ? effectiveDropdownHeight - 36
            : effectiveDropdownHeight + 31
        }px)`,
      });
    } else {
      setDropdownTransformValue({
        transform: 'none',
      });
    }
  };

  useEffect(() => {
    if (!isShowDropdownElemAlways) {
      document.addEventListener('click', toggle);
      return () => document.removeEventListener('click', toggle);
    } else {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    const filteredList = options.filter(
      (option) =>
        option[label]?.toLowerCase().indexOf(searchedData?.toLowerCase()) >
          -1 || String(option[id]).indexOf(searchedData) > -1
    );

    setTransform(filteredList, offsetBottom);
    setFilteredData(filteredList);
  }, [searchedData]);

  useEffect(() => {
    if (options) {
      const dropdownElement = document.querySelector(
          `#${dropdownId}.searchableDropdownWrapper`
        ),
        ob = window.document.body.scrollHeight - dropdownElement.offsetTop - 31;

      setOffsetBottom(ob);
      setTransform(options, ob);
      setFilteredData(options);
    }
  }, [options]);

  useEffect(() => {
    setSelectedOption(selectedValue ? selectedValue[label] : '');
    setSelectedOptionId(
      dataType === 'goal' && selectedValue?.id > 0
        ? selectedValue?.id
        : selectedValue?.goal_id
        ? selectedValue.goal_id
        : selectedValue?.campaign_id
    );
  }, [selectedValue]);

  useEffect(() => {
    setDisabled(isDisabled);
    if (isDisabled) {
      setIsOpen(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (isOpen && isSearchable) {
      const searchElem = document.querySelector(
        `#${uniqueIdentifier + 'SearchFieldSS'}.searchFieldSS`
      );
      searchElem.focus();
      searchElem.select();
    }
  }, [isOpen]);

  const handleClearSelectedValue = () => {
    setSelectedOption('');
    setSelectedOptionId('');
    if (onClearSelection) onClearSelection();
  };

  useEffect(() => {
    resetKeyEvents(true);
  }, [searchedData]);

  const handleKeyPressEvents = (e) => {
    const keys = ['ArrowDown', 'ArrowUp'],
      elem = document.querySelector(
        `#${dropdownId}.searchableDropdownWrapper .optionsWrapperSS`
      );

    if (options?.length && keys.includes(e.code)) {
      const outerElem = document.querySelector(
        `#${dropdownId}.searchableDropdownWrapper input.searchFieldSS`
      );
      outerElem.focus();

      if (
        e.code === 'ArrowDown' &&
        selectedChildRef.current < filteredData.length - 1
      ) {
        const newSelectedChild = selectedChildRef.current + 1,
          elemNew = elem.children[newSelectedChild],
          elemOld = elem.children[selectedChildRef.current];
        elemOld?.classList?.remove('ddSsOnKeyFocused');
        elemNew?.classList?.add('ddSsOnKeyFocused');

        if (scrollPosCountDown.current < 6) {
          scrollPosCountUp.current = scrollPosCountUp.current - 1;
          scrollPosCountDown.current = scrollPosCountDown.current + 1;
        }

        if (newSelectedChild > 5 && scrollPosCountDown.current === 6) {
          ifScrolledRef.current = true;
          elem.scrollTop = 30 * (newSelectedChild - 5);
        }

        if (ifHovered.current) {
          ifHovered.current = false;
          elem.scrollTop = 0;
        }

        if (lastSelectedChildRef.current > -1) {
          lastSelectedChildRef.current = -1;
          elem.scrollTop = 0;
        }

        selectedChildRef.current = newSelectedChild;
        return;
      }

      if (e.code === 'ArrowUp' && selectedChildRef.current > 0) {
        const newSelectedChild = selectedChildRef.current - 1,
          elemNew = elem.children[newSelectedChild],
          elemOld = elem.children[selectedChildRef.current];
        elemOld?.classList?.remove('ddSsOnKeyFocused');
        elemNew?.classList?.add('ddSsOnKeyFocused');

        if (scrollPosCountUp.current < 6) {
          scrollPosCountDown.current = scrollPosCountDown.current - 1;
          scrollPosCountUp.current = scrollPosCountUp.current + 1;
        }

        if (
          newSelectedChild < filteredData.length - 6 &&
          scrollPosCountUp.current === 6
        ) {
          ifScrolledRef.current = true;
          elem.scrollTop =
            elem.scrollHeight - 30 * (filteredData.length - newSelectedChild);
        }
        selectedChildRef.current = newSelectedChild;
      }

      return;
    }

    if (
      e.code === 'Enter' &&
      selectedChildRef.current >= 0 &&
      elem.children[selectedChildRef.current].getAttribute('index')
    ) {
      selectOption(filteredData[selectedChildRef.current]);
    }
  };

  const removeFocusedOnHover = (e) => {
    if (ifScrolledRef.current) {
      ifScrolledRef.current = false;
      return;
    }

    resetKeyEvents();
  };

  const resetKeyEvents = (scrollReset = false) => {
    scrollPosCountUp.current = 6;
    scrollPosCountDown.current = 0;
    ifHovered.current = true;

    const elem = document.querySelector(
        `#${dropdownId}.searchableDropdownWrapper .optionsWrapperSS`
      ),
      elemOld = elem?.children[selectedChildRef.current];
    elemOld?.classList?.remove('ddSsOnKeyFocused');
    selectedChildRef.current = -1;

    if (scrollReset && elem) elem.scrollTop = 0;
  };

  return (
    <div
      id={dropdownId}
      className={`searchableDropdownWrapper ${wrapperClass ?? ''}`}
      style={{ position: 'relative' }}
      onKeyDown={handleKeyPressEvents}
    >
      <div
        className={`dropdownLabel ${dropdownLabelClass ?? ''}`}
        style={labelWidth}
      >
        {dropdownLabel}
      </div>
      <div className='dropdown' style={dropdownWidth}>
        {!isHeaderHidden && (
          <div className='control'>
            <div className='selected-value'>
              {!disabled ? (
                <>
                  <div
                    ref={inputRef}
                    id={uniqueIdentifier + 'DropdownFieldSS'}
                    name={uniqueIdentifier + 'DropdownFieldSS'}
                    className={`singleSelectDropdownField ${singleSelectDropdownFieldClass} ${
                      !selectedOption ? ' selectDropdownElemSS' : ''
                    }`}
                    onClick={toggleUsingInput}
                    title={ifShowTitle && selectedOption ? selectedOption : ''}
                    tabIndex='0'
                  >
                    {selectedOption || placeholderLabel}
                    {selectedOptionId && ` (#${selectedOptionId})`}
                    {selectedOption && allowClearItem && (
                      <span
                        onClick={() => handleClearSelectedValue()}
                        className='cross'
                      >
                        x
                      </span>
                    )}
                  </div>
                  <div></div>
                </>
              ) : (
                <div
                  ref={inputRef}
                  id={uniqueIdentifier + 'DropdownFieldSS'}
                  name={uniqueIdentifier + 'DropdownFieldSS'}
                  className='singleSelectDropdownField disabledDropdownSS'
                >
                  {selectedOption || placeholderLabel}
                  {selectedOptionId && ` (#${selectedOptionId})`}
                </div>
              )}
            </div>
            <div className={`arrow ${isOpen ? 'open' : 'close'}`}></div>
          </div>
        )}
        <div
          className={`searchableDropdownList options${isOpen ? ' open' : ''}${
            !isSearchable ? ' notSearchableSS' : ''
          } ${alignTypeClasses[alignType]}`}
          style={dropdownTransformValue}
          tabIndex='0'
        >
          {isSearchable ? (
            <div>
              <input
                type='search'
                id={uniqueIdentifier + 'SearchFieldSS'}
                name={uniqueIdentifier + 'SearchFieldSS'}
                className='searchFieldSS'
                value={searchedData}
                onChange={(e) => setSearchedData(e.target.value)}
                placeholder={searchPlaceHolder ?? 'Search through Name or Id'}
                autoComplete='off'
              />
            </div>
          ) : (
            <div className='hiddenElem'>
              <input
                type='search'
                id={uniqueIdentifier + 'SearchFieldSS'}
                name={uniqueIdentifier + 'SearchFieldSS'}
                className='searchFieldSS hiddenElem'
                autoComplete='off'
              />
            </div>
          )}

          <div className='optionsWrapperSS'>
            {filteredData.map((option, index) => {
              if (ifShowDisabledOptions && option[disabledOptionId]) {
                return (
                  <div
                    id={
                      dropdownId +
                      '-' +
                      option[id] +
                      '-' +
                      option[label]?.replace(/\s/g, '')
                    }
                    className={`option disabledOption`}
                    key={`${dropdownId}-${option[id]}`}
                  >
                    {option[label]}
                    {ifIdVisableOnLabel &&
                      !option[excludeIdVisableOnLabel] &&
                      ` (#${option[id]})`}
                  </div>
                );
              }

              return (
                <RenderOptions
                  key={`${dropdownId}-${option[id]}`}
                  option={option}
                  selectOption={selectOption}
                  dropdownId={dropdownId}
                  id={id}
                  label={label}
                  selectedValue={selectedValue}
                  isDifferentBackground={isDifferentBackground}
                  differentBackgroundKey={differentBackgroundKey}
                  ifShowWarningAtOptions={ifShowWarningAtOptions}
                  warningOptionId={warningOptionId}
                  warningMessage={warningMessage}
                  isParentChildBasedOption={isParentChildBasedOption}
                  parentIdentificationKey={parentIdentificationKey}
                  ifIdVisableOnLabel={ifIdVisableOnLabel}
                  excludeIdVisableOnLabel={excludeIdVisableOnLabel}
                  removeFocusedOnHover={removeFocusedOnHover}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      </div>
      {isLoadingAvailable > 0 && (
        <div className='searchableDropdownLoaderPosition'>
          {isLoading && (
            <div className='dropdownLoaderWrapper'>
              <LoaderComponent importedClassNames='dropdownsAndDates' />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdownComponent;

const RenderOptions = ({
  option,
  selectOption,
  dropdownId,
  id,
  label,
  selectedValue,
  isDifferentBackground,
  differentBackgroundKey,
  ifShowWarningAtOptions,
  warningOptionId,
  warningMessage,
  isParentChildBasedOption,
  parentIdentificationKey,
  ifIdVisableOnLabel,
  excludeIdVisableOnLabel,
  removeFocusedOnHover,
  index,
}) => {
  const newId =
    dropdownId + '-' + option[id] + '-' + option[label]?.replace(/\s/g, '');

  if (option?.isHeading) {
    return (
      <div
        id={newId}
        className='option dropdownOptionHeading'
        onMouseMove={removeFocusedOnHover}
        index={index}
      >
        {option[label]}
        {ifIdVisableOnLabel &&
          !option[excludeIdVisableOnLabel] &&
          !option[parentIdentificationKey] && (
            <div className='idOnDropdownlabel'>{` (#${option[id]})`}</div>
          )}
      </div>
    );
  }

  return (
    <div
      onClick={() => selectOption(option)}
      id={newId}
      className={`option${
        selectedValue && option[id] === selectedValue[id] ? ' selected' : ''
      }${isDifferentBackground ? option[differentBackgroundKey] : ''}`}
      onMouseMove={removeFocusedOnHover}
      index={index}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!isParentChildBasedOption ? (
          <div className='showIdInElem'>
            {option[label]}
            {ifIdVisableOnLabel && !option[excludeIdVisableOnLabel] && (
              <div className='idOnDropdownlabel'>{` (#${option[id]})`}</div>
            )}
          </div>
        ) : (
          <>
            {option[parentIdentificationKey] ? (
              <div className='showIdInElem'>
                {option[label]}
                {ifIdVisableOnLabel &&
                  !option[excludeIdVisableOnLabel] &&
                  !option[parentIdentificationKey] && (
                    <div className='idOnDropdownlabel'>{` (#${option[id]})`}</div>
                  )}
              </div>
            ) : (
              <div className='showIdInElem' style={{ paddingLeft: '20px' }}>
                {option[label]}
                {ifIdVisableOnLabel && !option[excludeIdVisableOnLabel] && (
                  <div className='idOnDropdownlabel'>{` (#${option[id]})`}</div>
                )}
              </div>
            )}
          </>
        )}

        {ifShowWarningAtOptions && option[warningOptionId] && (
          <div
            title={
              warningMessage ??
              'No Data available for ' + option[label] + ' option'
            }
            className='dropdownWaringOpt'
          ></div>
        )}
      </div>
    </div>
  );
};
