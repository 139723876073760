import axios from '../axios/axios';

export const axiosGetRequest = (endpoint, signal, params) => {
  return axios().get(endpoint, {
    signal,
    params,
  });
};

export const axiosPostRequest = (endpoint, body, headers) => {
  return axios().post(
    endpoint,
    {
      ...body,
    },
    headers
  );
};

export const axiosPatchRequest = (endpoint, controller, body) => {
  return axios().patch(endpoint, { ...body }, { signal: controller?.signal });
};

export const axiosSendRequest = (endpoint, controller, body) => {
  return axios().post(
    endpoint,
    {
      ...body,
    },
    { signal: controller?.signal }
  );
};

export const axiosGetFileRequest = (endpoint, responseType) => {
  return axios().get(endpoint, responseType);
}

export const axiosSendFile = (endpoint, formData) => {
  return axios().post(endpoint, formData);
};