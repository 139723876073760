import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarComponent from './navbar/Navbar.component';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedAdvertiser, updateShowAdvertiserInitialPopUp } from '../../../redux/advertiserSlice';
import { axiosPatchRequest } from '../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../constants';
import './globalUtilityBar.css';
import SearchableDropdownComponent from '../../searchableDropdown/SearchableDropdown.component';

const GlobalUtilityBar = ({ signOut }) => {
  const [checkClient, setCheckClient] = useState(true);

  const client = useSelector((state) => state.client);
  const advertiser = useSelector((state) => state.advertiser);
  const user = useSelector((state) => state.user);
  const showUtilityBar = useSelector(
    (state) => state.utilitybarMode.showUtilityBar
  );

  const selectedAdvertiserId = advertiser.selectedAdvertiser; // Get the selected advertiser ID 
  const disableAdvertiser = useSelector(
    (state) => state.utilitybarMode.advertiserReadOnly
  );

  const [currentAdvertiser, setCurrentAdvertiser] = useState(null); //Updating the state with the selected item or else defaulting to the zeroth advertiser entry

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const selectedItem = advertiser.selectedClientsAdvertiser.filter(f => f.id === selectedAdvertiserId); // Filter Client Advertiser based on the Selected Item and then update f.id as selected advertiser ID
    
    setCurrentAdvertiser(selectedItem.length > 0 ? selectedItem[0] : advertiser.selectedClientsAdvertiser[0]);
  }, [selectedAdvertiserId])
  

  useEffect(() => {
    if (client.selectedClient !== -1) {
      setCheckClient(false);
    } else {
      setCheckClient(true);
    }
  }, [client.selectedClient]);
  useEffect(() => {
    if (advertiser.selectedClientsAdvertiser.length === 1) {
      navigate('/advertisers/new');
    }
  }, [advertiser]);

  useEffect(() => {
    if(selectedAdvertiserId === 0) {
      setCurrentAdvertiser(advertiser.selectedClientsAdvertiser[0]);
    }
  },[selectedAdvertiserId]);

  const saveAdvertiser = async (value) => {
    const newSelectedAdvertiser = Number(value);
    dispatch(updateSelectedAdvertiser(newSelectedAdvertiser));

    if (newSelectedAdvertiser === 0) {
      await axiosPatchRequest(
        `${baseEndpoints.users}/${user.uid}/default-client-advertiser`,
        null,
        {
          user_id: client.selectedClient,
          advertiser_id: -client.selectedClient,
        }
      );
    } else {
      await axiosPatchRequest(
        `${baseEndpoints.users}/${user.uid}/default-client-advertiser`,
        null,
        {
          user_id: client.selectedClient,
          advertiser_id: newSelectedAdvertiser,
        }
      );
    }
  }

  useEffect(() => {
    if (advertiser.selectedClientsAdvertiser.length <= 1) {
      dispatch(updateShowAdvertiserInitialPopUp(true));
    } else {
      dispatch(updateShowAdvertiserInitialPopUp(false));
    }
  }, [advertiser.selectedClientsAdvertiser]);

  return (
    <div className="utilityBar">
      <NavbarComponent signOut={signOut} />
      {showUtilityBar && (
        <div className="utilitySearchBar">
          <div className="currentAdv leftMargin">
            <SearchableDropdownComponent
              options={advertiser.selectedClientsAdvertiser ?? []}
              label="name"
              id="id"
              dropdownId="advertiserSelect"
              dropdownLabel="Current Advertiser"
              labelWidth={{ width: "200px" }}
              dropdownWidth={{
                width: "calc(100% - 80px)",
                minWidth: "250px",
              }}
              selectedValue={currentAdvertiser}
              handleChange={(val) => {
                if (
                  (!currentAdvertiser && val) ||
                  (val && currentAdvertiser.id !== val.id)
                ) {
                  setCurrentAdvertiser(val);
                  saveAdvertiser(val.id);
                }
              }}
              isDisabled={checkClient || disableAdvertiser}
              placeholderLabel={!disableAdvertiser ? 'Select An Advertiser' : 'All'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalUtilityBar;
