import React, { useEffect, useRef, useState } from 'react';
import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { EditCampaign, PixelRawData } from './components';
import { breadCrumbHeader } from '../../constants';
import PlacementsListComponent from './components/placementsList/PlacementsList.component';
import { useDispatch, useSelector } from 'react-redux';
import { getEncryptedOrDecryptedData } from '../../utils/functions';
import { updateSelectedClient } from '../../redux/clientSlice';
import {
  updateSelectedAdvertiser,
  updateSelectedClientsAdvertiser,
} from '../../redux/advertiserSlice';
import CreateReportComponent from '../Reports/components/createReport/CreateReport.component';
import { axiosPostRequest } from '../../services/http/axiosCalls';
import endpoints from '../../constants/endpoints';

const CampaignDetailsPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams(),
    encryptedParams = searchParams.get('params');

  document.title = 'Campaign';

  const dispatch = useDispatch();
  const advertiser = useSelector((state) => state.advertiser), // Fetch advertiser's information from redux store
    client = useSelector((state) => state.client); // Fetch clients's information from redux store
  const isInitialMount = useRef(true);
  const navigate = useNavigate();
  const [isFromAdminAvailable, setIsFromAdminAvailable] = useState(false),
    [campaignName, setCampaignName] = useState(''),
    [adminParams, setAdminParams] = useState(null),
    [isParamsUpdated, setIsParamsUpdated] = useState(false),
    [refreshKey, setRefreshKey] = useState(0),
    [restatementDetails, setRestatementDetails] = useState(null),
    [restatementText, setRestatementText] = useState(''),
    [restatementStatus, setRestatementStatus] = useState({}),
    [dataWarningText, setDataWarningText] = useState(''),
    [showCampaignNotes, setShowCampaignNotes] = useState(false);

  //This is being used to trigger a refresh of the CreateReport component when the user makes a change in the EditCampaign component
  const handleUserChange = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const getRestatementDetails = async () => {
    try {
      const res = await axiosPostRequest(
        endpoints.placementRestatementDetails,
        {
          campaignId: id,
        }
      );
      if (res?.data?.table_data) {
        setRestatementDetails(res.data.table_data);
      }
    } catch (e) {
      console.error(e);
      if (e?.response?.status !== 403) {
        setRestatementText('**Error Retrieving Restatement Details**');
      }
    }
  };

  const restatementDetailsCheck = () => {
    if (restatementDetails && restatementDetails?.length > 0) {
      let text = '';
      let restatementStatusUpdate = {};
      if (restatementDetails.some((item) => item.status_id === 0)) {
        text =
          '**An Error Occured While Processing This Restatement Request. ' +
          'Please Reach Out To Your Account Manager For the Next Recommended Steps.**';
        restatementStatusUpdate.failed = true;
        if (!restatementStatus.failed) {
          setRestatementText(text);
          setRestatementStatus(restatementStatusUpdate);
        }
      } else if (restatementDetails.some((item) => item.status_id != 4)) {
        text =
          '**This Campaign Is Queued For Restatement; ' +
          ' Associated Reports Will Not Reflect the Latest Changes.**';
        restatementStatusUpdate.queued = true;
        if (!restatementStatus.queued) {
          setRestatementText(text);
          setRestatementStatus(restatementStatusUpdate);
        }
      } else {
        text =
          '**All Restatement Requests For This Campaign Have Been Processed. Reporting Is Up To Date.**';
        restatementStatusUpdate.passed = true;
        if (!restatementStatus.passed) {
          setRestatementText(text);
          setRestatementStatus(restatementStatusUpdate);
        }
      }
      return restatementStatusUpdate;
    }
  };

  /**
   * Single useEffect to redirect a user to My Campaigns who is viewing a campaign and then changes the client or the advertiser.
   */
  useEffect(() => {
    if ((id || advertiser) && isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (isFromAdminAvailable) {
        return setIsFromAdminAvailable(false);
      }
      navigate('/campaigns');
    }
  }, [id, advertiser]);

  useEffect(() => {
    if (encryptedParams) {
      const dycryptedParams = JSON.parse(
          getEncryptedOrDecryptedData(encryptedParams, 'decrypt')
        ),
        { name, isFromAdmin, adminAdvId, adminClientId } = dycryptedParams;

      // setCampaignName(name);
      setIsFromAdminAvailable(Boolean(isFromAdmin));
      if (isFromAdmin && adminClientId && adminAdvId) {
        setAdminParams({ isFromAdmin, adminAdvId, adminClientId });
      }
      setIsParamsUpdated(true);
    } else {
      navigate('/campaigns');
    }
  }, [encryptedParams]);

  useEffect(() => {
    if (
      isParamsUpdated &&
      adminParams?.isFromAdmin &&
      adminParams?.adminClientId &&
      adminParams?.adminAdvId
    ) {
      const { adminAdvId, adminClientId } = adminParams;
      if (
        client.clientList.find((item) => item.user_id === adminClientId) &&
        advertiser.advertiserList.find((item) => item.id === adminAdvId)
      ) {
        dispatch(updateSelectedClient(adminClientId));
        dispatch(updateSelectedAdvertiser(adminAdvId));
        dispatch(
          updateSelectedClientsAdvertiser({
            userId: adminClientId,
            advertiserList: advertiser.advertiserList,
          })
        );
      } else {
        navigate('/campaigns');
      }
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (!restatementDetails && !restatementDetails?.length) {
      getRestatementDetails();
    } else {
      restatementDetailsCheck();
    }
  }, [restatementDetails]);

  const onCampaignUpdate = (campaignName) => {
    setCampaignName(campaignName);
  };

  //This is here because the CreateReportComponent needs a value passed into updateLatestReports, without something passed it will throw an error
  const onSaveReport = () => {
    return true;
  };

  const getRestatementStyle = () => {
    const objectToReturn = {};

    if (!restatementStatus?.passed) objectToReturn.color = 'red';
    if (restatementText) objectToReturn.backgroundColor = 'var(--aa-lightest-gray2)';
    return objectToReturn;
  }

  return (
    <div className='campaignDetailsWrapper'>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <Link to='/'>
            <div className='homeBlue'></div>
          </Link>
          <div className='breadCrumbBody'>
            /
            <Link to='/campaigns'>
              <div className='active'>{breadCrumbHeader.campaigns}</div>
            </Link>
          </div>
          <div className='breadCrumbBody'>
            <span>/</span>
            {campaignName}
          </div>
        </div>
        <div className='campaignNameWrapper'>
          <div className='campaignNameInnerWrapper'>
            <h1>{campaignName}</h1>
          </div>
          <div className='campaignHeaderRestatementDataWarningWrapper'>
            {showCampaignNotes && (
              <h5
                style={
                  dataWarningText?.length > 0
                    ? { backgroundColor: 'var(--aa-lightest-gray2)' }
                    : {}
                }
                className='campaignNamWrapperDataWarningText'
              >
                {dataWarningText?.length > 100
                  ? dataWarningText.slice(0, 100) + '...'
                  : dataWarningText}
              </h5>
            )}
            {restatementText?.length > 0 && (
              <h5
                style={getRestatementStyle()}
                className='campaignNameWrapperRestatementText'
              >
                {restatementText}
              </h5>
            )}
          </div>
        </div>
      </div>

      <Container fluid>
        <Row>
          <Col className='editCampaignRowOrColumns8Px' lg={12} md={12} sm={12}>
            <EditCampaign
              id={id}
              campaignName={campaignName}
              updateBreadCrumb={(campaignName) =>
                onCampaignUpdate(campaignName)
              }
              updateReports={handleUserChange}
              setDataWarningText={setDataWarningText}
              setShowCampaignNotes={setShowCampaignNotes}
            />
          </Col>
        </Row>
        <Row>
          <Col className='editCampaignRowOrColumns8Px' lg={12} md={12} sm={12}>
            <CreateReportComponent
              key={refreshKey}
              updateLatestReports={onSaveReport}
              campaignId={id}
            />
          </Col>
        </Row>
        <Col lg={12} md={12} sm={12}>
          <PlacementsListComponent
            campaignId={id}
            campaignName={campaignName}
            restatementDetails={restatementDetails}
            getRestatementDetails={getRestatementDetails}
          />
        </Col>
        <Row>
          <Col className='editCampaignRowOrColumns8Px' lg={12} md={12} sm={12}>
            <PixelRawData />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CampaignDetailsPage;
