import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getEncryptedOrDecryptedData } from '../../../../utils/functions';
import moment from 'moment';
import { CommonButtonComponent } from '../../../../components';
import { axiosPostRequest } from '../../../../services/http/axiosCalls';
import endpoints from '../../../../constants/endpoints';
import { commonNotification } from '../../../../utils/common-notifications';
import { setBlockingLoaderState } from '../../../../redux/blockingLoader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const latestReportsTableColumns = (
  setShowEditModal,
  setSlectedReportDetails,
  setRowNumber,
  dispatch =()=>{},
  role
) => {

  const downloadReport = (row) => {
    setRowNumber({ ...row, downloadLoading: true });
    const isRespondentData = row.report_type === 'Respondent Data';
    window.open(
      `https://${
        !isRespondentData ? row.destination_bucket + '.' : ''
      }s3.amazonaws.com/${
        !isRespondentData ? '' : row.destination_bucket + '/'
      }${row.last_filename}`
    );
    setRowNumber({ ...row, downloadLoading: false });
  };

  const queueRespondentDataReport = (row) => {
    if(row.report_type === "Respondent Data" || row.report_type === "Reach and Frequency"){
    dispatch(setBlockingLoaderState({ active: true }));
    setRowNumber({...row, loading: true})
      axiosPostRequest(endpoints.queueRespondentDataReport, {row})
      .then((res) => {
        if(res.status === 200){
          commonNotification({
            msg: res.message,
            type: "success",
          });
          setRowNumber({...row, loading: false, queue_date: moment().format('MM/DD/YYYY')})
        } else {
          commonNotification({
            msg: res.message,
            type: "danger",
          })
        setRowNumber({...row, loading: false})
        }
      })
      .catch((res)=>{
        commonNotification({
          msg: res.message ?? 'Something went wrong',
          type: danger
        })
      })
      .finally((res)=>{
        dispatch(setBlockingLoaderState({ active: false }));
      }
      )
    } 
  };

  const runScheduledReport = (row) => {
    dispatch(setBlockingLoaderState({ active: true }));
    setRowNumber({...row, loading: true})

    axiosPostRequest(endpoints.runScheduledReport, {...row})
    .then((res) => {
      if(res.status === 200){
        commonNotification({
          msg: res.message,
          type: "success",
        });
      setRowNumber({...row, loading: false, run_date: moment().format('MM/DD/YYYY')})
      } else {
        commonNotification({
          msg: res.message,
          type: "danger",
        }) 
      setRowNumber({...row, loading: false})
       }
    })
    .catch((res)=>{
      commonNotification({
        msg: res?.response?.data?.message ?? 'Something went wrong',
        type: "danger",
      })
      setRowNumber({...row, loading: false})
    })
    .finally((_res)=>{
      dispatch(setBlockingLoaderState({ active: false }));
    }
    )
  }

  const checkRunDate = (run_date, queue_date) => {
    const currentDate = moment();
    const runMoment = moment(new Date(run_date));
    const queueMoment = moment(new Date(queue_date));

    return (currentDate.diff(
        runMoment,
        'seconds'
      ) <= 30 ||
      currentDate.diff(
        queueMoment,
        'seconds'
      ) <= 30);
  };

  const checkDownloadRunDate = (runDate) => {
    const currentDate = moment().utc();
    const targetDate = moment(runDate).utc().add(14, 'days').hour(19).minute(0).second(0);
    
    return currentDate.isAfter(targetDate);
  };

  const renderRunButton = (row) => {
    const [runPressed, setRunPressed] = useState(false);
    if (row.report_type === 'Respondent Data' || row.report_type === 'Reach and Frequency') {
      return (
        <div className='respondantDataOuterButtonWrapper'>
          <CommonButtonComponent
            label={!row.loading ? 'Run' : ''}
            appendWrapperClassName='respondentDataButtonWrapper'
            appendButtonClassName='respondentDataRunButton'
            showLoader={row?.loading}
            isDisabled={
              row?.loading ||
              row?.queue_date ||
              checkRunDate(row?.run_date, row?.queue_date)
            }
            onClickCTA={() => queueRespondentDataReport(row)}
            buttonUI='runButtonForRespondentDataReports'
          />

          {(!row?.downloadLoading && !row.last_filename) ||
          checkDownloadRunDate(row?.run_date) ? (
            <OverlayTrigger
              key={`downloadButtonOverlay`}
              delay={350}
              overlay={
                <Tooltip id='downloadButtonOverlayTooltip'>
                  {!row.last_filename ? (
                    <div>
                      This Download Button Is Disabled Because the Report Has
                      Not Been Sent, Has No Data, or the File Failed To Be
                      Created.
                    </div>
                  ) : (
                    checkDownloadRunDate(row?.run_date) === true && (
                      <div>
                        This Download Button Is Disabled Because the Report Has
                        Not Been Run In the Last 14 Days.
                      </div>
                    )
                  )}
                </Tooltip>
              }
            >
              <div>
                <CommonButtonComponent
                  label={!row.downloadLoading ? 'Download' : ''}
                  appendWrapperClassName='respondentDataButtonWrapper btnPrimaryWrapper'
                  appendButtonClassName='respondentDataDownloadButton btnSecondary'
                  showLoader={row?.downloadLoading}
                  isDisabled={
                    row?.downloadLoading ||
                    !row.last_filename ||
                    !row.destination_bucket ||
                    checkDownloadRunDate(row?.run_date)
                  }
                  onClickCTA={() => downloadReport(row)}
                  buttonUI=''
                />
              </div>
            </OverlayTrigger>
          ) : (
            <CommonButtonComponent
              label={!row.downloadLoading ? 'Download' : ''}
              appendWrapperClassName='respondentDataButtonWrapper btnPrimaryWrapper'
              appendButtonClassName='respondentDataDownloadButton btnSecondary'
              showLoader={row?.downloadLoading}
              isDisabled={
                row?.downloadLoading ||
                !row.last_filename ||
                !row.destination_bucket ||
                checkDownloadRunDate(row?.run_date)
              }
              onClickCTA={() => downloadReport(row)}
              buttonUI=''
            />
          )}
        </div>
      );
    }
    if (row.report_type === 'On Demand') {
      return (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/reports/${row.report_id}/?report_name=${row.report_name}&report_type=${row.report_type}`}
          >
              <CommonButtonComponent
                label={"Open"}
                appendWrapperClassName="respondentDataButtonWrapper"
                appendButtonClassName="respondentDataRunButton"
                showLoader={false}
                buttonUI="runButtonForRespondentDataReports"/>
          </Link>
        </div>
      )
    }

    return (
      <div className='respondantDataOuterButtonWrapper'>
        <CommonButtonComponent
          label={!row.loading ? 'Run' : ''}
          appendWrapperClassName='respondentDataButtonWrapper'
          appendButtonClassName='respondentDataRunButton'
          showLoader={row?.loading}
          isDisabled={
            row?.loading ||
            row?.queued_date ||
            runPressed ||
            checkRunDate(row?.run_date, row?.queue_date)
          }
          onClickCTA={() => {
            setRunPressed(true);
            runScheduledReport(row);
          }}
          buttonUI='runButtonForRespondentDataReports'
        />

        {(!row?.downloadLoading && !row.last_filename) ||
        !row.destination_bucket ||
        checkDownloadRunDate(row?.run_date) ? (
          <OverlayTrigger
            key={`downloadButtonOverlay`}
            delay={350}
            overlay={
              <Tooltip id='downloadButtonOverlayTooltip'>
                {!row.last_filename ? (
                  <div>
                    This Download Button Is Disabled Because the Report Has Not
                    Been Sent, Has No Data, or the File Failed To Be Created.
                  </div>
                ) : (
                  checkDownloadRunDate(row?.run_date) === true && (
                    <div>
                      This Download Button Is Disabled Because the Report Has
                      Not Been Run In the Last 14 Days.
                    </div>
                  )
                )}
              </Tooltip>
            }
          >
            <div>
              <CommonButtonComponent
                label={!row.downloadLoading ? 'Download' : ''}
                appendWrapperClassName='respondentDataButtonWrapper btnPrimaryWrapper'
                appendButtonClassName='respondentDataDownloadButton btnSecondary'
                showLoader={row?.downloadLoading}
                isDisabled={
                  row?.downloadLoading ||
                  !row.last_filename ||
                  !row.destination_bucket ||
                  checkDownloadRunDate(row?.run_date)
                }
                onClickCTA={() => downloadReport(row)}
                buttonUI=''
              />
            </div>
          </OverlayTrigger>
        ) : (
          <CommonButtonComponent
            label={!row.downloadLoading ? 'Download' : ''}
            appendWrapperClassName='respondentDataButtonWrapper btnPrimaryWrapper'
            appendButtonClassName='respondentDataDownloadButton btnSecondary'
            showLoader={row?.downloadLoading}
            isDisabled={
              row?.downloadLoading ||
              !row.last_filename ||
              !row.destination_bucket ||
              checkDownloadRunDate(row?.run_date)
            }
            onClickCTA={() => downloadReport(row)}
            buttonUI=''
          />
        )}
      </div>
    );
  };

  return [
    {
      id: 'editReport',
      omitDropdownName: 'Edit Report',
      name: '',
      selector: (row) => {
        if (row.report_type.toLowerCase() === 'attribution' || row.report_type.toLowerCase() === 'attribution + lift' 
            || row.report_type.toLowerCase() === 'respondent data'
            || row.report_type.toLowerCase() === 'media delivery' 
            || row.report_type.toLowerCase() === 'attribution per day' || row.report_type.toLowerCase() === 'reach and frequency'){
          return (
            <div
              className='editReportIcon editReportEndable'
              onClick={() => {
                setSlectedReportDetails(row);
                setShowEditModal(true);
              }}
            ></div>
          );
        }
        return <div className='editReportIcon editReportDisable'></div>;
      },
      width: '52px',
      style: { justifyContent: 'center', paddingRight: 0 },
    },
    {
      id: 'report_id',
      name: 'REPORT ID',
      selector: (row) => {
        if (row.report_type === 'Respondent Data' || row.report_type === 'Media Delivery' || row.report_type === 'Attribution Per Day' || row.report_type === 'Reach and Frequency') {
          return (
            <div className='tableCellFontStyle tableCampNameFontStyle'>
              <div className='linkStyleLR noLink'>{row.report_id}</div>
            </div>
          );
        }
        return (
          <div className='tableCellFontStyle tableCampNameFontStyle'>
            <Link
              to={`/reports/${row.report_id}/?report_name=${row.report_name}&report_type=${row.report_type}`}
            >
              <div className='linkStyleLR'>{row.report_id}</div>
            </Link>
          </div>
        );
      },
      right: true,
      width: '120px',
      sortable: true,
      sortField: 'report_id',
    },
    {
      id: 'report_name',
      name: 'REPORT NAME',
      selector: (row) => {
        if (row.report_type === 'Respondent Data' || row.report_type === 'Media Delivery' || row.report_type === 'Attribution Per Day' || row.report_type === 'Reach and Frequency') {
          return (
            <div className='tableCellFontStyle tableCampNameFontStyle'>
              <div className='linkStyleLR noLink'>{row.report_name}</div>
            </div>
          );
        }
        return (
          <div className='tableCellFontStyle tableCampNameFontStyle'>
            <Link
              to={`/reports/${row.report_id}/?report_name=${encodeURIComponent(row.report_name)}&report_type=${row.report_type}`}
            >
              <div className='linkStyleLR'>{row.report_name}</div>
            </Link>
          </div>
        );
      },
      style: { width: 'max-content' },
      sortable: true,
      sortField: 'report_name',
    },
    {
      id: 'campaign_name',
      name: 'CAMPAIGN NAME',
      selector: (row) => {
        if(role === 'reporting'){
          return (<div className='tableCellFontStyle'>{row.campaign_name}</div>) 
        } else {
          return (
            <div className='tableCellFontStyle tableCampNameFontStyle'>
              <Link
                to={`/campaign/${
                  row.campaign_id
                }?params=${getEncryptedOrDecryptedData(
                  { name: row.campaign_name },
                  'encrypt'
                )}`}
              >
                <div className='linkStyleLR'>{row.campaign_name}</div>
              </Link>
            </div>
          )
        }        
      },
      style: { width: 'max-content' },
      sortable: true,
      sortField: 'campaign_name',
    },
    {
      id: 'run_date',
      name: 'RUN DATE',
      selector: (row) => (
        <div className='tableCellFontStyle '>
          {row.run_date ? moment.utc(row.run_date).format('MM/DD/YYYY') : ''}
        </div>
      ),
      width: '135px',
      sortable: true,
      sortField: 'run_date',
    },
    {
      id: 'report_type',
      name: 'REPORT TYPE',
      selector: (row) => (
        <div className='tableCellFontStyle '>{row.report_type}</div>
      ),
      minWidth: 'fit-content',
      maxWidth: '200px',
      sortable: true,
      sortField: 'report_type',
    },
    {
      id: 'advertiser_name',
      name: 'ADVERTISER',
      selector: (row) => (
        <div className='tableCellFontStyle '>{row.advertiser_name}</div>
      ),
      width: '160px',
      sortable: true,
      sortField: 'advertiser_name',
    },
    {
      omitDropdownName: 'Run Button',
      id: 'actions',
      name: '',
      selector: (row) => renderRunButton(row),
      width: '242px',
      sortable: false,
    },
  ];
};
