import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { breadCrumbHeader, camapignOptionList } from '../../constants';
import {
  ActiveCampaignsComponent,
  UpcomingCampaignsComponent,
  CampaignListComponent,
  Performers,
} from './components';
import { SearchableDropdownComponent, CommonButtonComponent } from '../../components';
import './campaigns.css';

const campaignDropdownOptions = [...camapignOptionList];

const Campaigns = () => {
  const navigate = useNavigate();
  if (window.location.pathname === '/') {
    navigate('/campaigns');
  }
  document.title = 'Campaigns';

  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser);

  const [selectedCampaignOption, setSelectedCampaignOption] = useState(null),
    [isLoadingForUpdate, setIsLoadingForUpdate] = useState(false);

  useEffect(() => {
    setIsLoadingForUpdate(true);
    setSelectedCampaignOption({ ...campaignDropdownOptions[1] });
  }, [client.selectedClient, advertiser.selectedAdvertiser]);

  useEffect(() => {
    if (isLoadingForUpdate) {
      setTimeout(() => {
        setIsLoadingForUpdate(false);
      }, 100);
    }
  }, [isLoadingForUpdate]);

  return (
    <React.Fragment>
      <div className='campaignDashboardHeaderWrapper'>
        <div className='breadCrumbOuterWrapper'>
          <div className='breadCrumbInnerWrapper'>
            <Link to='/'>
              <div className='homeBlue'></div>
            </Link>
            <div className='breadCrumbBody'>
              <span>/</span>
              {breadCrumbHeader.campaigns}
            </div>
          </div>
        </div>
        <div className='campaignDashboardHeaderWrapperRightContainer'>
          <CommonButtonComponent
            label='Create Campaign'
            appendWrapperClassName='newCampaignBtnWrapper'
            appendButtonClassName='newCampaignBtnBody'
            onClickCTA={() => navigate('/campaign/new')}
            isDisabled={advertiser.selectedClientsAdvertiser?.length <= 1}
            buttonUI='allCampaignsCreateCampaign'
          />
          <SearchableDropdownComponent
            options={campaignDropdownOptions}
            label='name'
            id='id'
            dropdownId='campaignTypeSelection'
            dropdownLabel=''
            labelWidth={{ margin: 0 }}
            dropdownWidth={{
              width: '165px',
            }}
            placeholderLabel='Select Campaign Type'
            selectedValue={selectedCampaignOption}
            handleChange={setSelectedCampaignOption}
            isSearchable={false}
            uniqueIdentifier='campaignTypeSelections'
          />
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col
            xl={7}
            lg={7}
            md={12}
            sm={12}
            className='activeCampaignsOuterWrapper'
          >
            <ActiveCampaignsComponent />
          </Col>
          <Col
            xl={5}
            lg={5}
            md={12}
            sm={12}
            className='upcomingCampaignsOuterWrapper'
          >
            <UpcomingCampaignsComponent />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className='activeCampaignsOuterWrapper'>
            <Performers
              endpoint='top-performance'
              headingLabel='Highest Volume'
              campaignOption={selectedCampaignOption}
              isLoadingForUpdate={isLoadingForUpdate}
            />
          </Col>
          <Col lg={6} className='activeCampaignsOuterWrapper'>
            <Performers
              endpoint='bottom-performance'
              headingLabel='Lowest Volume'
              campaignOption={selectedCampaignOption}
              isLoadingForUpdate={isLoadingForUpdate}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            className='activeCampaignsOuterWrapper'
          >
            <CampaignListComponent
              campaignOption={selectedCampaignOption}
              isLoadingForUpdate={isLoadingForUpdate}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Campaigns;
