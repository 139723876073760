import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseEndpoints } from '../../../../../../constants';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import { AnalysisBarChartsComponent } from '../../attributionTab/analysisSection/barchartForGoals/commonChatComp';
import { dateFormat } from '../../../../../../utils/functions';
import './analysis.css';

const EngagementConversionGoalsComponent = ({ campaign, startDate, endDate }) => {
  // setting base graph data
  const baseGraphData = {
    engagement_data: null,
    conversion_data: null,
  };

  // base state initializing for graph data, loader, error
  const [graphData, setGraphData] = useState(baseGraphData),
    [isLoading, setIsLoading] = useState(true),
    [isError, setIsError] = useState(false);

  // base state for axios cancel controller
  const [cancelController, setCancelController] = useState(null);

  // Enable (true) or disable (false) chart content/s
  const ifShowWidget = {
    conversion: true,
    engagement: true,
  };

  // fetching graph data from backend
  const getChartData = async (controller) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const responseGraphData = await axiosSendRequest(
        `${baseEndpoints.reports}/lift-tab/analysis/engagement-conversion-goals`,
        controller,
        {
          campaign_id: campaign?.campaign_id,
          from: dateFormat(startDate, 'YYYY-MM-DD'),
          to: dateFormat(endDate, 'YYYY-MM-DD'),
          mock_data: false,
        }
      );

      // if status is 200 then data fetching is successful, hence updating the state
      if (responseGraphData.status === 200) {
        const data = responseGraphData.data;

        setGraphData(
          data && Object.keys(data).length > 0 ? data : baseGraphData
        );
        setIsLoading(false);
        return;
      }

      setIsError(true);
      setGraphData(baseGraphData);
      setIsLoading(false);
    } catch (err) {
      // if request has not ben cancelled, then treated as error
      if (!axios.isCancel(err)) {
        setGraphData(baseGraphData);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    // if campaign is available, then initializing the request
    if (campaign) {
      const controller = new AbortController();
      setCancelController(controller);
      getChartData(controller);

      return () => {
        // if request has been cancelled, then aborting it
        controller.abort();
      };
    } else {
      setGraphData(baseGraphData);
      setIsLoading(false);
    }
  }, [campaign, startDate, endDate]);

  return (
    <div className='liftAnalysisBody'>
      <AnalysisBarChartsComponent
        cancelController={cancelController}
        customTooltip={customTooltip}
        uniqueIndex='liftAnalysisBarChart'
        barDataKeys={['incremental_events']}
        chartsAttrArray={[
          {
            chartHeaderLabel: 'Engagement Goals',
            isLoadingElement: isLoading,
            isErrorElement: isError,
            refreshFunction: getChartData,
            graphData: graphData.engagement_data,
            barStackIds: ['e'],
            uniqueKey: 'analysisEngagementsLT',
            ifShowWidget: ifShowWidget.engagement,
          },
          {
            chartHeaderLabel: 'Conversion Goals',
            isLoadingElement: isLoading,
            isErrorElement: isError,
            refreshFunction: getChartData,
            graphData: graphData.conversion_data,
            barStackIds: ['c'],
            uniqueKey: 'analysisConversionsLT',
            ifShowWidget: ifShowWidget.conversion,
          },
        ]}
      />
    </div>
  );
};

export default EngagementConversionGoalsComponent;
const customTooltip = ({ payload }) => {
  const data = payload ? payload[0]?.payload : null;

  return (
    !(data === undefined || data === null) && (
      <div className="attrVsIncTooltipWrapper">
        <div className="attrVsIncTooltipLabel">{data.goal_name}</div>
        <div className="attrVsIncTooltipBody">
          <div>Attributable: </div>
          <div className="colorTeal">
            {Intl.NumberFormat("en").format(data.attributable_events)}
          </div>
        </div>
        <div className="attrVsIncTooltipBody">
          <div>Incremental: </div>
          <div style={{ color: payload[0]?.color }}>
            {Intl.NumberFormat("en").format(data.incremental_events)}
          </div>
        </div>
        <div className="attrVsIncTooltipBody">
          <div>Lift %: </div>
          <div>{(data.percent_of_lift * 100).toFixed(2)}%</div>
        </div>
        <div className="attrVsIncTooltipBody">
          <div>Property: </div>
          <div>{data.property_name}</div>
        </div>
      </div>
    )
  );
};
