import React from "react";

//RSS redirect modal update with the specific details needed
const RSSURLBodyJSX = ({ rssData }) => {
  const { message, lastRedirectUrl, enteredRssFeedUrl } = rssData;
  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <>
      <div className="flex mt-3 rss-url-change-modal">
        <div>{message}</div>
        <a
          className="ps-2 more-details"
          onClick={() => setShowDetails(!showDetails)}
        >
          {!showDetails ? "More" : "Less"} Details
        </a>
      </div>
      {showDetails && (
        <div>
          <div className="flex">
            <div className="font-bold">Entered RSS Feed URL: </div>
            <div className="ps-2">{enteredRssFeedUrl}</div>
          </div>
          <div className="flex mb-3">
            <div className="font-bold">Last Redirect URL: </div>
            <div className="ps-2">{lastRedirectUrl}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RSSURLBodyJSX;