const endpoints = {
  // global utility bar
  userInfo: '/global-utility-bar/user-details',
  setDefaultAdvertiser: '/global-utility-bar/default-advertiser',
  // dashboard
  dashboardActiveCampaigns: '/dashboard/active-campaigns',
  dashboardUpcomingCampaigns: '/dashboard/upcoming-campaigns',
  dashboardStartingSoonCampaigns: '/dashboard/starting-soon-campaigns',
  dashboardEndingSoonCampaigns: '/dashboard/ending-soon-campaigns',
  dashboardAdvertisersYTD: '/dashboard/advertisers/ytd',
  dashboardCampaignsYTD: '/dashboard/campaigns/ytd',
  dashboardImpressionsWSpendDailyByAdvertizer: (advertiserId) =>
    `/dashboard/impressions/w-spend/daily?advertiserId=${advertiserId}`,
  dashboardImpressionsWSpendDailyByClient: (clientId) =>
    `/dashboard/impressions/w-spend/daily?clientId=${clientId}`,
  // campaigns
  campaignAllCampaign: '/campaigns/all-campaigns',
  topPerformingCampaigns: '/campaigns/top-performers',
  bottomPerformingCampaigns: '/campaigns/bottom-performers',
  // campaign
  campaignPlacements: (campaignId) => `/campaigns/${campaignId}/placements`,
  campaignGoals: (campaignId, type) =>
    `/campaign/${campaignId}/goals?type=${type}`,
  campaignPartnersByGoal: (campaignId, goalId) =>
    `/campaign/${campaignId}/partners-by-goal/${goalId}`,
  editCampaign: '/campaign/edit-campaign',
  // reports
  latestReports: 'reports/latest-reports',
  reportsCampaignList: '/reports/campaign-list',
  reportsMediaPartnerList: '/reports/media-partner-list',
  reportsPlacementList: '/reports/placement-list',
  saveNewReport: '/reports/save-new-report',
  createScheduledReport: 'scheduled',
  placementsAsPerPartners: '/reports/placement-list/partners',
  placementsAsPerCreativeTypes: '/reports/placement-list/creative-types',
  // report
  reportDetails: 'reports/details',
  mediaDelivery: 'reports/media-delivery',
  distribution: 'reports/distribution',
  reportAllImpressions: (campaignId) =>
    `/report/impressions/all?campaignId=${campaignId}`,
  reportIvtImpressions: (campaignId, startDate, endDate) =>
    `/report/impressions/ivt?campaignId=${campaignId}&startDate=${startDate}&endDate=${endDate}`,
  reportEngagementsConversions: (campaignId, startDate, endDate) =>
    `/report/engagements-conversions/daily?campaignId=${campaignId}&startDate=${startDate}&endDate=${endDate}`,
  reportAllImpressionsEngagements: (campaignId) =>
    `/report/impressions-engagements/all?campaignId=${campaignId}`,
  attribution: 'reports/attribution',
  analysisLineChart: 'reports/analysis-section/line-graph',
  attributionAnalysisBarChart:
    '/report/attribution/analysis/goal-count-bar-chart',
  liftGoalsComparison: (campaignId) =>
    `report/comparison/lift?campaignId=${campaignId}`,
  effectivenessScatterChart: 'reports/attribution/effectiveness',
  attributionAnalysisPartnersByGoal: (campaignId, goalId, type) =>
    `/report/attribution/analysis/partners-by-goals/${type}?campaignId=${campaignId}&goalId=${goalId}`,
  attributionAnalysisTop5Goals: (campaignId, type) =>
    `/report/attribution/analysis/top-5-goals/${type}?campaignId=${campaignId}`,
  attributionAnalysisTop5Devices: (campaignId, type) =>
    `/report/attribution/analysis/top-5-devices/${type}?campaignId=${campaignId}`,
  creativeTypeList: 'reports/creative-type-list',
  creativeTypes: '/placements/creativeType',
  mediaPartners: '/placements/mediaPartner',
  podcasts: '/placements/podcastRss',
  redirectedCheck: '/placements/rss',
  createPlacement: '/placements/create',
  podcastProviders: '/placements/podcastProvider',
  placementDetails: (campaignId, placementId) =>
    `/placements/detail/${campaignId}/${placementId}`,
  savePlacement: (campaignId, placementId) =>
    `/placements/${campaignId}/${placementId}`,
  placementSaveEpisodeRestatement: (cid, pid) =>
    `/placements/saveEpisodeRestatement/${cid}/${pid}`,
  episodeList: (campaignId, placementId) =>
    `/placements/episodes/${campaignId}/${placementId}`,
  importPlacements: '/placements/import',
  authLogin: '/auth/login',
  authChangePassword: '/auth/change-password',
  authResetPasswordVerificationCode: '/auth/reset-password/verification-code',
  authResetPasswordConfirmPassword: '/auth/reset-password/confirm-password',
  authRefresh: '/auth/refresh',
  authLogout: '/auth/logout',
  authSSOURL: '/auth/sso-url',
  importCampaigns: '/campaigns/import',
  downloadPlacementTemplate: '/download/placements',
  downloadCampaignTemplate: '/download/campaigns',
  queueRespondentDataReport: '/reports/queue',
  status: '/reports/status',
  runScheduledReport: '/reports/run-report',
  placementRestatementDetails: '/placements/restatement',
};

export default endpoints;

export const baseEndpoints = {
  auth: '/auth',
  users: '/users',
  campaigns: '/campaigns',
  advertisers: '/advertisers',
  reports: '/reports',
  properties: '/properties',
  admin: '/admin',
  pixels: '/pixels',
};

