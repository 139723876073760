import React, { Component, createRef } from 'react';
import VirtualListRow from './VirtualListRow';
import TreeState from '../model/tree-state';
export default class VirtualList extends Component {
  
  state = {
    topOffset: 0,
    overscanHeight: 100,
  };

  containerRef = createRef();

  render() {
    const { data, columns, onChange } = this.props;

    let visibleRowsData = [];
    TreeState.sliceRows(data, 0, data.data.length).forEach((rowModel) => {
      if (rowModel.$state.isVisible) {
        visibleRowsData.push(rowModel);
      }
    });

    let keyIndex = 0;

    const visibleVLRows = visibleRowsData.map((rowModel, relIndex) => {
      const currentIndex = keyIndex;
      keyIndex++;
      return (
        <VirtualListRow key={currentIndex}
          data={data}
          model={rowModel}
          columns={columns}
          onChange={onChange}

          index={rowModel.metadata.index}
          relIndex={relIndex} />
      );
    });

    return (
      <tbody className="cp_tree-table_viewport"
        ref={this.containerRef}
        onScroll={() => {
          if (this.containerRef.current != null) {
            const { scrollTop } = this.containerRef.current;
            const { onScroll } = this.props;
      
            onScroll(scrollTop);
            this.setState({
              topOffset: scrollTop,
            });
          }
        }}>

{visibleVLRows}

      </tbody>
    );
  }
  
}


const STYLE_LIST = {
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
};

const STYLE_WRAPPER = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  minHeight: '100%',
};

const STYLE_CONTENT = {
  position: 'absolute',
  overflow: 'visible',
  height: '100%',
  width: '100%',
  top: '0px',
  left: '0px',
};
