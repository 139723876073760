import React from 'react';
import TooltipTrendChartComponent from './TooltipTrendChart.component';

const CommonTooltipTrendComponent = ({
  isTooltipOnTop,
  tooltipPosition,
  effectiveCampaignId,
  tableTrendChartData,
  tableBaseData,
  header,
  offsetWidth
}) => {
  return (
    <div
      className={`tooltipWrapper ${
        isTooltipOnTop ? 'tickOnBottom' : 'tickOnTop'
      }`}
      style={{
        top: isTooltipOnTop
          ? `calc(${tooltipPosition.top}px - var(--aa-trend-tooltip-height))`
          : `calc(${tooltipPosition.top}px + var(--aa-table-column-height))`,
        left: `calc(${tooltipPosition.left}px - var(--aa-trend-tooltip-width) / 2 - ${offsetWidth}px)`,
      }}
    >
      <div className='tableTooltipHeader'>{header}</div>
      <div>
        <TooltipTrendChartComponent
          chartData={
            tableTrendChartData.find(
              (lineData) => lineData.campaign_id === effectiveCampaignId
            )?.chart_data
          }
          startDate={
            tableBaseData.find(
              (tDatum) => tDatum.campaign_id === effectiveCampaignId
            )?.start_date
          }
          endDate={
            tableBaseData.find(
              (tDatum) => tDatum.campaign_id === effectiveCampaignId
            )?.end_date
          }
        />
      </div>
      <div className='chartTooltipLegendWrapper'>
        <div className='chartTooltipLegendInnerWrapper active'>
          <div className='legendIcon'></div>
          <div className='legendText'>Active</div>
        </div>
        <div className='chartTooltipLegendInnerWrapper inactive'>
          <div className='legendIcon'></div>
          <div className='legendText'>Inactive</div>
        </div>
      </div>
    </div>
  );
};

export default CommonTooltipTrendComponent;
