import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { CommonModal } from '../modals';
import redirectModalInfo from './redirectModalInfo';
import { CommonButtonComponent } from '../';
import './redirectModal.css';

const RedirectModalComponent = ({
  modalBodyElements,
  messageType,
  detailsElements,
  onCloseNav,
  samePageRedirect,
  handleSamePageRedirect = null,
}) => {
  const navigate = useNavigate();

  return (
    <div className='redirectModalWrapper'>
      <CommonModal
        show={true}
        header='Congratulations!'
        body={
          <RedirectModalBody
            modalBodyElements={modalBodyElements}
            messageType={messageType}
            detailsElements={detailsElements}
            samePageRedirect={samePageRedirect}
            handleSamePageRedirect={handleSamePageRedirect}
          />
        }
        close={() => navigate(onCloseNav)}
        buttonUI={'create' + messageType + 'RedirectModal'}
      />
    </div>
  );
};

export default RedirectModalComponent;

const RedirectModalBody = ({
  modalBodyElements,
  messageType,
  detailsElements,
  samePageRedirect,
  handleSamePageRedirect,
}) => {
  const navigate = useNavigate();

  return (
    <div className='redirectModalBodyWrapper'>
      <div className='redirectModalMessageBar'>
        You have just created a new {messageType}. What you like to do next?
      </div>
      <div className='redirectModalIconBar'>
        <Container fluid className='removePaddingOrMargin'>
          <Row className='removePaddingOrMargin'>
            {formatBody(
              modalBodyElements,
              detailsElements,
              navigate,
              samePageRedirect,
              handleSamePageRedirect,
              messageType
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

const formatBody = (
  modalBodyElements,
  detailsElements,
  navigate,
  samePageRedirect,
  handleSamePageRedirect,
  messageType
) => {
  return modalBodyElements?.map((elem, index) => {
    const val =
      index === modalBodyElements.length - 1 && index % 2 === 0 ? 12 : 6,
      elemIndex = Object.keys(redirectModalInfo).indexOf(elem);

    return (
      <Col
        key={elem + elemIndex}
        xl={val}
        lg={val}
        md={val}
        sm={12}
        className='removePaddingOrMargin redirectModalBtnOuterWrapper'
      >
        <CommonButtonComponent
          label={redirectModalInfo[elem].name}
          appendWrapperClassName='redirectModalBtnWrapper'
          appendButtonClassName='redirectModalBtnBody'
          onClickCTA={() => {
            if (samePageRedirect === elem) {
              handleSamePageRedirect();
              return;
            }
            navigate(
              createUrl(
                redirectModalInfo[elem].baseRedirectUrl,
                detailsElements
                  ? detailsElements[elem]?.routeParam ?? null
                  : null,
                detailsElements
                  ? detailsElements[elem]?.queryParam ?? null
                  : null
              )
            );
          }}
          isDisabled={redirectModalInfo[elem].disabled}
          buttonUI={'create' + messageType + 'RedirectModal' + elem.charAt(0).toUpperCase() + elem.slice(1)}
        />
      </Col>
    );
  });
};

// Function to Create a url for redirection ** rp = for route params, qp = for query params

const createUrl = (baseUrl, rp, qp) => {
  let url = baseUrl;

  if (rp) {
    const baseArray = Array.from({ length: rp.length });

    rp.forEach((item) => {
      baseArray[item.index] = '/' + item.param;
    });

    baseArray.forEach((item) => {
      url += item;
    });
  }

  if (qp) {
    url += '/?';

    qp.forEach((item, index) => {
      const key = Object.keys(item)[0];

      if (index >= qp.length - 1) {
        url += key + '=' + item[key];
      } else {
        url += key + '=' + item[key] + '&';
      }
    });
  }

  return url;
};
