/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import './styles.css'

export function CommonSearchFilter ({
  placeholder, inputValue, disabled, className, setInputValue,
  label, labelStyle, labelClass, maxLength, filterData, filterKey, handleSelect
}) {
  const [filteredList, setFilteredList] = useState([])
  const [showList, setShowList] = useState(false)

  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value
    setShowList(true)
    setInputValue(query)
    // Create copy of item list
    let updatedList = [...filterData]
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item[filterKey].toLowerCase().indexOf(query.toLowerCase()) !== -1
    })
    // Trigger render with updated values
    setFilteredList(updatedList)
  }
  function clickHandler (item) {
    handleSelect(item)
    setShowList(false)
  }
  function handleKeyDown (e) {
    if (e.key === 'Enter') {
      setShowList(false)
      handleSelect(filteredList.length > 0 ? filteredList[0] : inputValue)
    }
  }

  return (
    <>
        <div className="flex commonInputWrapper searchFilter">
        <div className={`inputLabel ${labelClass}`} style={labelStyle}>{label}</div>
        <div className='searchFilterBody'>
            <input type="text" value={inputValue} placeholder={placeholder}
                onChange={filterBySearch}
                className={`utilitySearch ${className}`}
                disabled={disabled}
                maxLength={maxLength}
                onKeyDown={handleKeyDown}
            />
            {showList &&
            <div id="item-list">
        <ul>
          {filteredList.length > 0
            ? filteredList.map((item, index) => (
            <li key={item[filterKey]} onClick={() => clickHandler(item)}>{item[filterKey]}</li>
            ))
            : <li onClick={() => clickHandler(inputValue)}>No Data</li>
          }
        </ul>
      </div>
      }
            </div>
        </div>
    </>
  )
}
