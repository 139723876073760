import React from 'react';
import { Link } from 'react-router-dom';
import { getEncryptedOrDecryptedData } from '../../../../utils/functions';

export const createColumns = (clientId) => {
  return [
    {
      id: 'property_id',
      name: 'PROPERTY ID',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/properties/${
              row.property_id
            }/?params=${getEncryptedOrDecryptedData(
              {
                advertiserId: row.advertiser_id,
                paramClientId: clientId,
                propertyName: row.name,
              },
              'encrypt'
            )}`}
          >
            <div className='campaignNameStyleCL'>{row.property_id}</div>
          </Link>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'property_id', // sorting
      width: '8%',
      minWidth: '145px !important',
      right: true,
    },
    {
      id: 'name',
      name: 'PROPERTY NAME',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/properties/${
              row.property_id
            }/?params=${getEncryptedOrDecryptedData(
              {
                advertiserId: row.advertiser_id,
                paramClientId: clientId,
                propertyName: row.name,
              },
              'encrypt'
            )}`}
          >
            <div className='campaignNameStyleCL'>{row.name}</div>
          </Link>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'name', // sorting
      width: '36%',
      minWidth: '215px !important',
    },
    {
      id: 'type',
      name: 'TYPE',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle'>{row.type}</div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'type', // sorting
      width: '10%',
      minWidth: '170px !important',
    },
    {
      id: 'identifier',
      name: 'IDENTIFIER',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle'>{row.identifier}</div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'identifier', // sorting
      width: '36%',
      minWidth: '215px !important',
    },
    {
      id: 'status',
      name: 'STATUS',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <div className='tableCellFontStyle'>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </div>
      ),
      // sortable: false,
      sortable: true, // sorting
      sortField: 'status', // sorting
      width: '10%',
      minWidth: '120px !important',
    },
  ];
};
