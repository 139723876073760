import React from 'react';
import './loaderComponent.css';

const LoaderComponent = ({ importedClassNames = '', headerClass = '' }) => {
  return (
    <div className={`loaderOuterWrapper ${headerClass}`}>
      <div
        className={`spinner-border loaderWrapper ${importedClassNames}`}
        role='status'
      >
        <span className='sr-only'></span>
      </div>
    </div>
  );
};

export default LoaderComponent;
