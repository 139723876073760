import React from 'react';
import { NoDataComponent, ErrorComponent, LoaderComponent } from '../../../../../../components';
import { approximateNumberToMils } from '../../../../../../utils/functions';

import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid,
  Legend,
} from 'recharts';
import { Container, Row, Col } from 'react-bootstrap';

import './impressionSection.css'

export default function DailyImpressionsReport({
  data,
  isLoading,
  isError,
  status,
  getImpressions,
  handleGroupingChanged,
  noData
}) {
  const [grouping, setGrouping] = React.useState('1');

  const groupingChanged = (val) => {
    handleGroupingChanged(val);
    setGrouping(val.target.value);
  };

  const checkLoadingComponent = () => {
    return isLoading ? <LoaderComponent /> : checkErrorComponent();
  };

  const checkErrorComponent = () => {
    const controller = new AbortController();
    return isError ? (
      <ErrorComponent status={status} refreshAction={() => getImpressions()} controller={controller} />
    ) : (
      renderGraph()
    );
  };

  const renderGraph = () => {
    return !data || data.length <= 0 ? (
      <NoDataComponent type='stack'/>
    ) : (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={500} height={700} data={data}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            tickCount={10}
            axisLine={false}
            domain={[0, (dataMax) => Math.ceil((dataMax * 1.5) / 100) * 100]}
            tickFormatter={approximateNumberToMils}
            width={100}
          />
          <Tooltip
            formatter={(value) => Intl.NumberFormat("en").format(value)}
          />
          <Legend />
          <Bar dataKey="Net Impressions" width={24} stackId="a" fill="#03bdc9" />
          <Bar dataKey="IVT" stackId="a" fill="#ff7c1f" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className='dailyImpressionWrapper'>
      <Container fluid>
        <Row>
          <Col style={{ textAlign: 'center', marginTop: 2 + 'em' }}>
            <select
              id='reportDetailsMediaDeliveryImpressionsTimeFrames'
              name='reportDetailsMediaDeliveryImpressionsTimeFrames'
              onChange={groupingChanged}
              value={grouping}
              disabled={noData}
            >
              <option value='1'>Daily</option>
              <option value='2'>Weekly</option>
              <option value='3'>Monthly</option>
            </select>
            <span> Impressions</span>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} style={{ height: '20em' }}>
            {checkLoadingComponent()}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
