import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  formatNumberInThousands,
  getEncryptedOrDecryptedData,
} from '../../../../utils/functions';

export const createPerformersColumns = (width) => {
  const getGrow = () => {
    switch (true) {
      case width >= 1600:
        return 7;
      case width >= 1440:
        return 6;
      case width >= 1368:
        return 6;
      case width >= 1200:
        return 4;
      case width >= 1100:
        return 4;
      case width >= 991:
        return 6;
      case width >= 768:
        return 6;
      default:
        return 6;
    }
  };

  return [
    {
      id: 'campaignIdPerformer',
      name: 'CAMPAIGN ID',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${
              row.campaign_id
            }?params=${getEncryptedOrDecryptedData(
              { name: row.name },
              'encrypt'
            )}`}
          >
            <div className='campaignNameStyleAC'>{row.campaign_id}</div>
          </Link>
        </div>
      ),
      sortable: false,
      width: '132px',
      right: true,
    },
    {
      id: 'topPerformerCampaignName',
      name: 'CAMPAIGN NAME',
      selector: (row) => (
        <div className='tableCellFontStyle tableCampNameFontStyle'>
          <Link
            to={`/campaign/${
              row.campaign_id
            }?params=${getEncryptedOrDecryptedData(
              { name: row.name },
              'encrypt'
            )}`}
          >
            <div className='performerCampaignsNameStyle'>{row.name}</div>
          </Link>
        </div>
      ),
      sortable: false,
      grow: getGrow(),
    },
    {
      id: 'topPerformerEnding',
      name: 'ENDING',
      selector: (row) => {
        return (
          <div>
            <div className='tableCellFontStyle '>
              {moment.utc(row.end_date).format('MM/DD/YYYY')}
            </div>
          </div>
        );
      },
      sortable: false,
      grow: 3,
    },
    {
      id: 'topPerformerImpressions',
      name: 'IMPRESSIONS TO DATE',
      selector: (row) => (
        <div className='tableCellFontStyle'>
          {formatNumberInThousands(row.total_imp || 0)}
        </div>
      ),
      sortable: false,
      style: { justifyContent: 'flex-end' },
      grow: 4,
      right: true,
    },
    {
      id: 'runReportCP',
      name: 'RUN REPORT',
      selector: (row) => (
        <button
          className='runReportIcon runReportEndable'
          onClick={() =>
            window.open(
              `/reports/run/?rd1=${row.campaign_id}`,
              '_blank',
              'noreferrer'
            )
          }
        />
      ),
      sortable: false,
      style: { justifyContent: 'center' },
      width: '110px',
    },
  ];
};
