import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  CommonHeadingComponent,
  CommonSearch,
  CommonTablesComponent,
  ErrorComponent,
  CommonButtonComponent,
  CommonModal,
  DatePickerComponent,
} from '../../../../components';
import { createColumns } from './campaignList.utils';
import { baseEndpoints } from '../../../../constants';
import {
  removeTableClass,
  formatNumberInThousands,
  setAdvertiserId
} from '../../../../utils/functions';
import moment from 'moment';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import { Col, Container, Row } from 'react-bootstrap';
import ImportButtonComponent from '../../../../components/importButton/ImportButton.component';
import ImportFileModal from '../../../../components/importButton/ImportFileModal';

import './campaignList.css';

//GLOBAL VARIABLE FOR DEFAULT RANGE
const MIN = 0,
  MAX = 9999999999,
  baseInputParams = {
    sort_field: 'campaign_name',
    order: 'asc',
    search_param: '',
    page_number: 1,
    impression_range: false,
    impression_min: MIN,
    impression_max: MAX,
    conversion_range: false,
    conversion_min: MIN,
    conversion_max: MAX,
    engagement_range: false,
    engagement_min: MIN,
    engagement_max: MAX,
    end_date_range: false,
    end_date_min: null,
    end_date_max: null,
  },
  baseFilterParams = {
    min: MIN,
    max: MAX,
  },
  sortColumns = [
    'campaign_id',
    'campaign_name',
    'imp_count',
    'engagements',
    'conversions',
    'end_date',
  ];

const CampaignListComponent = ({ campaignOption, isLoadingForUpdate }) => {
  const navigate = useNavigate();

  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser), 
    role = useSelector((state) => state.user.role);

  const [params, setParams] = useState(null),
    [isMounted, setIsMounted] = useState(false),
    [inputParams, setInputParams] = useState(null),
    [columns, setColumns] = useState([]),
    [isClientUpdated, setIsClientUpdated] = useState(true),
    [searchInput, setSearchInput] = useState(''),
    [showModal, setShowModal] = useState(false),
    [isReset, setIsReset] = useState(false),
    [isCountAtClientOrAdvLevel, setIsCountAtClientOrAdvLevel] = useState(false),
    [rowCountAtClientOrAdvLevel, setRowCountAtClientOrAdvLevel] = useState(0);

  const [defaultImpressionRange, setDefaultImpressionRange] =
      useState(baseFilterParams),
    [defaultConversionRange, setDefaultConversionRange] =
      useState(baseFilterParams),
    [defaultEngagementRange, setDefaultEngagementRange] =
      useState(baseFilterParams),
    [minEndDate, setMinEndDate] = useState(null),
    [maxEndDate, setMaxEndDate] = useState(null);
  const [showImportFileModal, setShowImportFileModal] = useState(false);
  const handleApply = () => {
    setInputParams({
      ...inputParams,
      impression_range:
        defaultImpressionRange.min !== MIN ||
        defaultImpressionRange.max !== MAX,
      impression_min: defaultImpressionRange.min,
      impression_max: defaultImpressionRange.max,
      conversion_range:
        defaultConversionRange.min !== MIN ||
        defaultConversionRange.max !== MAX,
      conversion_min: defaultConversionRange.min,
      conversion_max: defaultConversionRange.max,
      engagement_range:
        defaultEngagementRange.min !== MIN ||
        defaultEngagementRange.max !== MAX,
      engagement_min: defaultEngagementRange.min,
      engagement_max: defaultEngagementRange.max,
      end_date_range: Boolean(minEndDate && maxEndDate),
      end_date_min: minEndDate ? moment(minEndDate).format('YYYY-MM-DD') : null,
      end_date_max: maxEndDate ? moment(maxEndDate).format('YYYY-MM-DD') : null,
    });

    setShowModal(false);
  };

  const resetImpression = () => {
    setDefaultImpressionRange(baseFilterParams);

    setInputParams({
      ...inputParams,
      impression_range: false,
      impression_min: MIN,
      impression_max: MAX,
    });
  };

  const resetConversion = () => {
    setDefaultConversionRange(baseFilterParams);

    setInputParams({
      ...inputParams,
      conversion_range: false,
      conversion_min: MIN,
      conversion_max: MAX,
    });
  };

  const resetEngagement = () => {
    setDefaultEngagementRange(baseFilterParams);

    setInputParams({
      ...inputParams,
      engagement_range: false,
      engagement_min: MIN,
      engagement_max: MAX,
    });
  };

  const resetEndDate = () => {
    setInputParams({
      ...inputParams,
      end_date_range: false,
      end_date_min: null,
      end_date_max: null,
    });

    setMinEndDate(null);
    setMaxEndDate(null);
  };

  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.campaigns
  );

  useEffect(() => {
    if (inputParams) {
      const advertiserId = setAdvertiserId(advertiser.selectedAdvertiser, advertiser.selectedClientsAdvertiser, role);
      setParams({
        timezone: null,
        user_id: client.selectedClient === -1 ? null : client.selectedClient,
        advertiser_id: advertiserId,
        ...inputParams,
      });
      setIsMounted(true);
    }
  }, [inputParams]);

  useEffect(() => {
    if (campaignOption) {
      setIsClientUpdated(true);
      setIsCountAtClientOrAdvLevel(true);
      setDefaultImpressionRange(baseFilterParams);
      setDefaultEngagementRange(baseFilterParams);
      setDefaultConversionRange(baseFilterParams);
      setMinEndDate(null);
      setMaxEndDate(null);
      setSearchInput('');
      setInputParams(
        !inputParams
          ? { ...baseInputParams, campaign_option: campaignOption?.id }
          : {
              ...baseInputParams,
              sort_field: inputParams.sort_field,
              order: inputParams.order,
              campaign_option: campaignOption?.id,
            }
      );
    }
  }, [campaignOption]);

  useEffect(() => {
    if (isCountAtClientOrAdvLevel) {
      setRowCountAtClientOrAdvLevel(data?.total_campaigns ?? 0);
      setIsCountAtClientOrAdvLevel(false);
    }

    if (data?.total_campaigns) {
      setColumns(createColumns());
      removeTableClass('.campaignListWrapper');
    }
  }, [data]);

  useEffect(() => {
    if (isClientUpdated) {
      return setIsClientUpdated(false);
    }

    const timer = setTimeout(() => {
      setInputParams({
        ...inputParams,
        page_number: baseInputParams.page_number,
        search_param: searchInput,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  useEffect(() => {
    if (isReset) {
      setDefaultImpressionRange({
        min: MIN,
        max: MAX,
      });
      setDefaultEngagementRange({
        min: MIN,
        max: MAX,
      });
      setDefaultConversionRange({
        min: MIN,
        max: MAX,
      });
      setMinEndDate(null);
      setMaxEndDate(null);
      setIsReset(false);
    }
  }, [isReset]);

  const onClickImportFile = () => {
    setShowImportFileModal(true);
  };

  return (
    <div className='campaignListWrapper'>
      <CommonHeadingComponent
        headingLabel='Campaigns'
        url={null}
        isDisabled={true}
      />
      <div className='campaignListSubheader justify-content-between'>
        <div className='d-flex align-items-center'>
          <CommonSearch
            placeholder={'Search campaigns'}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            disabled={
              !rowCountAtClientOrAdvLevel ||
              isLoading ||
              isLoadingForUpdate ||
              isError
            }
            uniqueIdentifier='allCampaigns'
          />
          <div className='d-flex justify-content-between'>
            <CommonButtonComponent
              label='Filter'
              onClickCTA={() => setShowModal(true)}
              isDisabled={
                !rowCountAtClientOrAdvLevel ||
                isLoading ||
                isLoadingForUpdate ||
                isError
              }
              buttonUI='allCampaignsFilter'
            />
            <FilterBox
              inputParams={inputParams}
              resetImpression={resetImpression}
              resetConversion={resetConversion}
              resetEngagement={resetEngagement}
              resetEndDate={resetEndDate}
            />
          </div>
        </div>
        <div className='new-campaign-btn'>
          <ImportButtonComponent
            label='Import Campaigns'
            appendWrapperClassName='importBtnWrapper'
            appendButtonClassName='importBtnBody'
            onClickCTA={onClickImportFile}
            buttonUi='allCampaignsImportCampaigns'
          />
          <ImportFileModal
            show={showImportFileModal}
            open={!showImportFileModal}
            setShowImportFileModal={setShowImportFileModal}
            showImportFileModal={showImportFileModal}
            advertiserId={advertiser.selectedAdvertiser}
            clientId={client.selectedClient}
            header={'Import Campaigns'}
          />
        </div>
      </div>
      {isError ? (
        <ErrorComponent refreshAction={() => setParams({ ...params })} />
      ) : (
        <CommonTablesComponent
          uniqueId='campaignList'
          data={data?.table_base_data ?? []}
          columns={columns}
          isLoading={isLoading || isLoadingForUpdate}
          customTableHeader='Campaigns'
          setSortData={(sortData) =>
            setInputParams({
              ...inputParams,
              sort_field: sortData.sortField,
              order: sortData.order,
            })
          } // sorting
          sortColumnIds={sortColumns} // sorting
          defaultSortingApplied={{
            column: baseInputParams.sort_field,
            order: baseInputParams.order,
          }} // sorting
          isPaginationApplied={true}
          totalDataCount={data?.total_campaigns ?? 0}
          pageIndex={inputParams?.page_number}
          setPageIndex={(page_number) =>
            setInputParams({ ...inputParams, page_number })
          }
          dataPerPage={10}
          noDataMessage='No Campaigns Available'
          noDataMessageFontSize={15}
          noDataCompWidth='180'
          noDataCompHeight='180'
          foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
        />
      )}
      {!isError && showModal && (
        <CommonModal
          show={showModal}
          header='Filter by'
          body={
            <FilterModalBody
              defaultImpressionRange={defaultImpressionRange}
              setDefaultImpressionRange={setDefaultImpressionRange}
              defaultConversionRange={defaultConversionRange}
              setDefaultConversionRange={setDefaultConversionRange}
              defaultEngagementRange={defaultEngagementRange}
              setDefaultEngagementRange={setDefaultEngagementRange}
              minEndDate={minEndDate}
              setMinEndDate={setMinEndDate}
              maxEndDate={maxEndDate}
              setMaxEndDate={setMaxEndDate}
              isReset={isReset}
            />
          }
          close={() => {
            setIsReset(true);
            setShowModal(false);
          }}
          reject={() => setIsReset(true)}
          rejectBtnText='Clear'
          acceptBtnText='Apply'
          accept={handleApply}
          size='md2'
          buttonUI='allCampaignsFilter'
          disableRejectBtn={
            defaultImpressionRange.min === MIN &&
            defaultImpressionRange.max === MAX &&
            defaultEngagementRange.min === MIN &&
            defaultEngagementRange.max === MAX &&
            defaultConversionRange.min === MIN &&
            defaultConversionRange.max === MAX &&
            !minEndDate &&
            !maxEndDate
          }
          disableAcceptBtn={
            (minEndDate && !maxEndDate) || (!minEndDate && maxEndDate)
          }
        />
      )}
    </div>
  );
};

export default CampaignListComponent;

const FilterModalBody = ({
  defaultImpressionRange,
  setDefaultImpressionRange,
  defaultConversionRange,
  setDefaultConversionRange,
  defaultEngagementRange,
  setDefaultEngagementRange,
  minEndDate,
  setMinEndDate,
  maxEndDate,
  setMaxEndDate,
  isReset,
}) => {
  return (
    <div className='campaignListFilter'>
      <Container>
        <Row>
          <Col xs={3} sm={3} lg={3}></Col>
          <Col>Min</Col>
          <Col>Max</Col>
        </Row>
        <Row className='campaignListFilterRow'>
          <Col xs={3} sm={3} lg={3}>
            <div className='filterWrapperLabel'>Impressions</div>
          </Col>
          <Col className='minMaxWrapper'>
            <InputMin
              defaultRange={defaultImpressionRange}
              setDefaultRange={setDefaultImpressionRange}
              uniqueIdentifier='allCampaignsFilterImpressions'
              isReset={isReset}
            />
          </Col>
          <Col className='minMaxWrapper'>
            <InputMax
              defaultRange={defaultImpressionRange}
              setDefaultRange={setDefaultImpressionRange}
              uniqueIdentifier='allCampaignsFilterImpressions'
              isReset={isReset}
            />
          </Col>
        </Row>
        <Row className='campaignListFilterRow'>
          <Col xs={3} sm={3} lg={3}>
            <div className='filterWrapperLabel'>Engagements</div>
          </Col>
          <Col className='minMaxWrapper'>
            <InputMin
              defaultRange={defaultEngagementRange}
              setDefaultRange={setDefaultEngagementRange}
              uniqueIdentifier='allCampaignsFilterEngagements'
              isReset={isReset}
            />
          </Col>
          <Col className='minMaxWrapper'>
            <InputMax
              defaultRange={defaultEngagementRange}
              setDefaultRange={setDefaultEngagementRange}
              uniqueIdentifier='allCampaignsFilterEngagements'
              isReset={isReset}
            />
          </Col>
        </Row>
        <Row className='campaignListFilterRow'>
          <Col xs={3} sm={3} lg={3}>
            <div className='filterWrapperLabel'>Conversions</div>
          </Col>
          <Col className='minMaxWrapper'>
            <InputMin
              defaultRange={defaultConversionRange}
              setDefaultRange={setDefaultConversionRange}
              uniqueIdentifier='allCampaignsFilterConversions'
              isReset={isReset}
            />
          </Col>
          <Col className='minMaxWrapper'>
            <InputMax
              defaultRange={defaultConversionRange}
              setDefaultRange={setDefaultConversionRange}
              uniqueIdentifier='allCampaignsFilterConversions'
              isReset={isReset}
            />
          </Col>
        </Row>
        <Row className='campaignListFilterRow campaignListFilterDateSelector'>
          <Col>
            <DatePickerComponent
              datePickerId='allCampaignFilterDateRange'
              label={'End Date'}
              labelWidth={{
                minWidth: '90px',
                fontWeight: '700',
                display: 'flex',
              }}
              datePickerBodyWidth={{
                width: 'calc(100% - 120px)',
                minWidth: '180px',
              }}
              placeholderLabel={`Campaign End Date`}
              isDateRangePicker={true}
              dateRange={[minEndDate, maxEndDate]}
              setDateRange={(range) => {
                setMinEndDate(range[0]);
                setMaxEndDate(range[1]);
              }}
              format='MM/DD/YYYY'
              uniqueIdentifier='allCampaignFilterDateRangeCampaigns'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

/** FILTER BUBBLES THAT APPEAR IN SUBHEADER */
const FilterBox = ({
  inputParams,
  resetImpression,
  resetConversion,
  resetEngagement,
  resetEndDate,
}) => {
  return (
    <>
      {inputParams?.impression_range && (
        <div className='filterBubble' onClick={() => resetImpression()}>
          <div className='filterBubbleLabel'>
            Impression <span className='filterBubbleReset'>X</span>
          </div>
          <div className='filterBubbleValue'>
            {`${formatNumberInThousands(
              inputParams?.impression_min
            )} - ${formatNumberInThousands(inputParams?.impression_max)}`}
          </div>
        </div>
      )}

      {inputParams?.engagement_range && (
        <div className='filterBubble' onClick={() => resetEngagement()}>
          <div className='filterBubbleLabel'>
            Engagement <span className='filterBubbleReset'>X</span>
          </div>
          <div className='filterBubbleValue'>
            {`${formatNumberInThousands(
              inputParams?.engagement_min
            )} - ${formatNumberInThousands(inputParams?.engagement_max)}`}
          </div>
        </div>
      )}

      {inputParams?.conversion_range && (
        <div className='filterBubble' onClick={() => resetConversion()}>
          <div className='filterBubbleLabel'>
            Conversion <span className='filterBubbleReset'>X</span>
          </div>
          <div className='filterBubbleValue'>
            {`${formatNumberInThousands(
              inputParams?.conversion_min
            )} - ${formatNumberInThousands(inputParams?.conversion_max)}`}
          </div>
        </div>
      )}

      {inputParams?.end_date_range && (
        <div className='filterBubble' onClick={() => resetEndDate()}>
          <div className='filterBubbleLabel'>
            End Date <span className='filterBubbleReset'>X</span>
          </div>
          <div className='filterBubbleValue'>
            {`${moment(inputParams?.end_date_min).format(
              'MM/DD/YY'
            )} - ${moment(inputParams?.end_date_max).format('MM/DD/YY')}`}
          </div>
        </div>
      )}
    </>
  );
};

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const removeNonNumeric = (num) => num.toString().replace(/\D/g, '');

/**Input for min range */
const InputMin = ({
  defaultRange,
  setDefaultRange,
  uniqueIdentifier,
  isReset,
}) => {
  const [minVal, setMinVal] = useState(defaultRange?.min);
  useEffect(() => {
    setMinVal(defaultRange?.min);
  }, [isReset]);

  return (
    <input
      type='text'
      id={uniqueIdentifier + 'MinInputField'}
      name={uniqueIdentifier + 'MinInputField'}
      value={formatNumberInThousands(minVal)}
      onChange={(e) => {
        const valueWithComma = addCommas(removeNonNumeric(e.target.value));
        const valueWithoutComma = valueWithComma.replace(/,/g, '');
        setMinVal(valueWithoutComma);
      }}
      onBlur={(e) => {
        const valueWithComma = addCommas(removeNonNumeric(e.target.value));
        const valueWithoutComma = valueWithComma.replace(/,/g, '');
        if (!e.target.value || valueWithoutComma < 0) {
          setDefaultRange({
            ...defaultRange,
            min: 0,
          });
          setMinVal(0);
          return;
        }
        if (
          valueWithoutComma &&
          valueWithoutComma <= defaultRange?.max - 1 &&
          valueWithoutComma >= 0
        ) {
          setDefaultRange({
            ...defaultRange,
            min: Number(valueWithoutComma),
          });
          setMinVal(Number(valueWithoutComma));
          return;
        }
        if (valueWithoutComma >= defaultRange?.max) {
          setDefaultRange({
            ...defaultRange,
            min: Number(defaultRange?.max - 1),
          });
          setMinVal(Number(defaultRange?.max - 1));
        }
      }}
    />
  );
};

/**Input for max range */

const InputMax = ({
  defaultRange,
  setDefaultRange,
  uniqueIdentifier,
  isReset,
}) => {
  const [maxVal, setMaxVal] = useState(defaultRange?.max);
  useEffect(() => {
    setMaxVal(defaultRange?.max);
  }, [isReset]);

  return (
    <input
      type='text'
      id={uniqueIdentifier + 'MaxInputField'}
      name={uniqueIdentifier + 'MaxInputField'}
      value={formatNumberInThousands(maxVal)}
      onChange={(e) => {
        const valueWithComma = addCommas(removeNonNumeric(e.target.value));
        const valueWithoutComma = valueWithComma.replace(/,/g, '');
        setMaxVal(valueWithoutComma);
      }}
      onBlur={(e) => {
        const valueWithComma = addCommas(removeNonNumeric(e.target.value));
        const valueWithoutComma = valueWithComma.replace(/,/g, '');
        if (!e.target.value) {
          setDefaultRange({
            ...defaultRange,
            max: MAX,
          });
          setMaxVal(MAX);
          return;
        }
        if (valueWithoutComma <= defaultRange?.min) {
          setDefaultRange({
            ...defaultRange,
            max: Number(defaultRange?.min + 1),
          });
          setMaxVal(Number(defaultRange?.min + 1));
          return;
        }
        if (valueWithoutComma > MAX) {
          setDefaultRange({
            ...defaultRange,
            max: MAX,
          });
          setMaxVal(MAX);
          return;
        }
        if (valueWithoutComma <= MAX) {
          if (defaultRange?.min < valueWithoutComma) {
            setDefaultRange({
              ...defaultRange,
              max: Number(valueWithoutComma),
            });
            setMaxVal(Number(valueWithoutComma));
            return;
          }
        }
      }}
    />
  );
};
