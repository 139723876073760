import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CommonHeadingComponent,
  CommonTablesComponent,
  ErrorComponent,
} from '../../../../components';
import { createPerformersColumns } from './performers.utils';
import { baseEndpoints } from '../../../../constants';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import { baseInputParams } from '../';
import { setAdvertiserId } from '../../../../utils/functions';
import './performers.css';

const Performers = ({
  endpoint,
  headingLabel,
  campaignOption,
  isLoadingForUpdate,
}) => {
  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser),
    role = useSelector((state) => state.user.role);

  const { width } = useWindowDimensions();

  const [params, setParams] = useState(null),
    [isMounted, setIsMounted] = useState(false),
    [inputParams, setInputParams] = useState(null),
    [columns, setColumns] = useState([]),
    [totalData, setTotalData] = useState(0);

  const { data, dataCount, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    `${baseEndpoints.campaigns}/${endpoint}`
  );

  useEffect(() => {
    if (inputParams) {
      const advertiserId = setAdvertiserId(advertiser.selectedAdvertiser, advertiser.selectedClientsAdvertiser, role);
      setParams({
        timezone: null,
        user_id: client.selectedClient === -1 ? null : client.selectedClient,
        advertiser_id: advertiserId,
        ...inputParams,
      });
      setIsMounted(true);
    }
  }, [inputParams]);

  useEffect(() => {
    if (campaignOption) {
      setInputParams({
        ...baseInputParams,
        campaign_option: campaignOption?.id,
      });
    }
  }, [campaignOption]);

  useEffect(() => {
    setTotalData(dataCount >= 20 ? 20 : dataCount);
    setColumns(createPerformersColumns(width));
  }, [data]);

  return (
    <div className='performanceWrapper'>
      <CommonHeadingComponent headingLabel={headingLabel} />
      {isError ? (
        <ErrorComponent refreshAction={() => setParams({ ...params })} />
      ) : (
        <CommonTablesComponent
          uniqueId='performers'
          data={data ?? []}
          columns={columns}
          isLoading={isLoading || isLoadingForUpdate}
          isPaginationApplied={true}
          totalDataCount={totalData}
          pageIndex={inputParams?.page_number}
          setPageIndex={(page_number) =>
            setInputParams({ ...inputParams, page_number })
          }
          dataPerPage={inputParams?.page_size}
          noDataMessage='No Campaigns Available'
          noDataMessageFontSize={15}
          noDataCompWidth='180'
          noDataCompHeight='180'
          foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
          showColumnShowHide={false}
        />
      )}
    </div>
  );
};

export default Performers;
