import React, { useEffect, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {
  CommonButtonComponent,
  CommonInput,
  CommonModal,
  DatePickerComponent,
  MultiselectDropdownComponent,
  SearchableDropdownComponent,
  SetFrequencyModal,
} from '../../../../components';
import {
  baseEndpoints,
  presentTimeFrameList,
  reporttypeOptionList,
  legacyOnlyTimeFrames,
  runTimeFrameList,
  DailyTimeFrameOptions,
  MonthlyTimeFrameOptions,
  piiMaskOptions,
  yesNoOptions,
  outputFormatOptions,
  TimeFrameOptions,
  statusOptionList,
  dedupeCustomVarOptions,
  scheduledReportErrorMessageList,
  frequencyOptionsList,
  RespondentDataReportFrequencyOptions,
  WeeklyMonToSunTimeFrameOptions,
} from '../../../../constants';
import MultiRecipients from '../MultiRecipients/MultiRecipients';
import { setBlockingLoaderState } from '../../../../redux/blockingLoader';
import { usePostAPIInterceptor } from '../../../../hooks/postAPIInterceptor';
import NewMultiSelect from '../../../ReportDetails/components/reportDetailsTab/mediaDeliveryTab/analysisSection/components/dropDownAnalysisSection/NewMultiSelect';
import {
  FormatLabel,
  checkCondition,
  dateFormat,
  daysToFormatWRTDates,
  getCurrentESTDate,
  getNotification,
  calculationForLastLiftperiod,
  getStartAndEndDate,
  setPlaceHolderLabel,
  checkSelectAll,
  removeNotification,
} from '../../../../utils/functions';
import '../../reports.css';
import './scheduledReportEdit.css';

import { checkReportNameForSpecialCharacters, getStartAndEndDatesForRunTimeFrames, getUpdatedRunDateRangeBasedOnFrequency } from '../ScheduledReportModal';

const presentTimeFrameOptions = [...presentTimeFrameList],
  runTimeFrameOptions = [...runTimeFrameList],
  reportTypeOptions = [...reporttypeOptionList],
  statusOptions = [...statusOptionList],
  errAndWarnMsg = { ...scheduledReportErrorMessageList },
  frequencyOptions = [...frequencyOptionsList],
  respondantFrequencyOptions = [...RespondentDataReportFrequencyOptions],
  timeFrameList = [...TimeFrameOptions];

const piiMaskList = [...piiMaskOptions],
  headerChoiceList = [...yesNoOptions],
  outputFormatList = [...outputFormatOptions];

let dedupeVariableList = [...dedupeCustomVarOptions];

const reportNameMaxLength = 200;

const setDefaultReportType = (reportTypeString) => {
  switch (reportTypeString) {
    case 'Attribution':
      return { id: 0 };
    case 'Attribution Plus Lift':
      return { id: 1 };
    case 'Respondent Data':
      return { id: 2 };
    case 'Media Delivery':
      return { id: 3 };
    case 'Attribution Per Day':
      return { id: 4 };
    case 'Reach and Frequency':
      return { id: 5 };
  } 
}

const ScheduledReportEditComponent = ({
  isModalOpen,
  setIsModalOpen,
  selectedReportDetails,
  onSaveSuccesss,
}) => {
  const dispatch = useDispatch();
  const currentDate = getCurrentESTDate('YYYY-MM-DD');

  const [reportName, setReportName] = useState('');
  const [oldSelections, setOldSelections] = useState(null);
  const [mediaPartner, setMediaPartner] = useState(null),
    [mediaPartnerList, setMediaPartnerList] = useState(null),
    [showMediaPartnerDropdownWarning, setShowMediaPartnerDropdownWarning] = useState(false);

  const [reportStatus, setReportStatus] = useState(null),
    [reportType, setReportType] = useState(selectedReportDetails?.report_type ? setDefaultReportType(selectedReportDetails.report_type) : { id: 0 }),
    [recipients, setRecipients] = useState([]),
    [respondentDataTimeframeOptions, setRespondentDataTimeframeOptions] =
      useState([]),
    [presentTimeFrame, setPresentTimeFrame] = useState(null),
    [presentDateRange, setPresentDateRange] = useState([null, null]);

  const [showFrequencyModal, setShowFrequencyModal] = useState(false),
    [frequency, setFrequency] = useState(''),
    [frequencyOption, setFrequencyOption] = useState(null),
    [previousFrequencyOptionWasOnDemand, setPreviousFrequencyOptionWasOnDemand] = useState(false);

  const [showFrequencyWarnModal, setShowFrequencyWarnModal] = useState(false),
    [showRunTimeFrameWarnModal, setShowRunTimeFrameWarnModal] = useState(false),
    [isFirstInitiation, setIsFirstInitiation] = useState(true),
    [isSaveDisable, setIsSaveDisable] = useState(true);

  const [runTimeFrame, setRunTimeFrame] = useState(null),
    [runDateRange, setRunDateRange] = useState([null, null]),
    [tempRunEndDate, setTempRunEndDate] = useState(null);

  const [params, setParams] = useState(null),
    [isMounted, setIsMounted] = useState(false);
  const [piiMaskChoice, setPiiMaskChoice] = useState(null);
  const [headerIntValue, setHeaderIntValue] = useState(null);
  const [selectedDedupeVariables, setSelectedDedupeVariables] = useState([]);
  const [selectedOutputFormat, setSelectedOutputFormat] = useState(null);

  const [placementList, setPlacementList] = useState(null),
    [placement, setPlacement] = useState(null),
    [showPlacementDropdownWarning, setShowPlacementDropdownWarning] = useState(false);

  const getMediaPartnersCallback = (status, data, error) => {
    if (!error) {
      setMediaPartnerList(data.data);
    }
  };
  usePostAPIInterceptor(
    selectedReportDetails && !mediaPartnerList,
    {
      campaign_id: selectedReportDetails?.campaign_id,
    },
    `${baseEndpoints.reports}/partner-list`,
    getMediaPartnersCallback
  );

   const getPlacementsCallback = (status, data, error) => {
      if (!error) {
        setPlacementList(data.data[0]);
      }
  };

  usePostAPIInterceptor(
    selectedReportDetails && !placementList,
    {campaign_id: selectedReportDetails?.campaign_id},
    `${baseEndpoints.reports}/placement-list`,
    getPlacementsCallback
  );

  useEffect(() => {
    if (reportType && ![1,2].includes(reportType?.id)) {
      try {
        const mediaPartners = JSON.parse(
          selectedReportDetails?.media_partners_filter
        );
        if (mediaPartners.length > 0) {
          setMediaPartner(mediaPartners);
        } else {
          setMediaPartner(mediaPartnerList);
          getNotification('warning', "Media Partners filter reset to 'All'");
        }
      } catch {
        setMediaPartner(mediaPartnerList);
        getNotification('warning', "Media Partners filter reset to 'All'");
      }
    }
  }, [mediaPartnerList]);

  useEffect(() => {
    if (frequencyOption?.repeatEvery?.key === 1) {
      setPreviousFrequencyOptionWasOnDemand(true);
    } else {
      setPreviousFrequencyOptionWasOnDemand(false);
    }

    if (previousFrequencyOptionWasOnDemand && frequencyOption?.repeatEvery?.key !== 1 && runTimeFrame) {
      setShowRunTimeFrameWarnModal(
        runTimeFrameOptions.find((el) => el.id === runTimeFrame.id).showWarning
      )
    }
    
    let ptfo = [...DailyTimeFrameOptions];
    if (reportType?.id === 2 && frequencyOption?.repeatEvery.key === '1') {
      ptfo = [...TimeFrameOptions];
    } else if (
      reportType?.id === 2 &&
      frequencyOption?.repeatEvery.key === '3'
    ) {
      ptfo = [...WeeklyMonToSunTimeFrameOptions];
    } else if (
      reportType?.id === 2 &&
      frequencyOption?.repeatEvery.key === '4'
    ) {
      ptfo = [...MonthlyTimeFrameOptions];
    }

    ptfo = ptfo.map((o) => {
      return { id: parseInt(o.value), name: o.label };
    });
    setRespondentDataTimeframeOptions(ptfo);
    if (reportType?.id != 2) {
      setPresentTimeFrame(
        presentTimeFrameOptions.find(
          (el) => el.id === selectedReportDetails.timeframe
        )
      );
    } else {
      setPresentTimeFrame(
        ptfo.find(
          (el) => el.id === selectedReportDetails.timeframe
        )
      );
    }
  }, [reportType, frequencyOption]);

  useEffect(() => {
    if (!isFirstInitiation) {
      setIsSaveDisable(
        validateEnableSave(
          reportName,
          reportStatus,
          presentTimeFrame,
          presentDateRange,
          recipients,
          selectedReportDetails,
          frequencyOption,
          piiMaskChoice,
          headerIntValue,
          selectedDedupeVariables,
          selectedOutputFormat,
          reportType?.id,
          runTimeFrame,
          runDateRange,
          mediaPartner,
          mediaPartnerList,
          placement,
          placementList,
        )
      );
    }
  }, [
    reportName,
    reportStatus,
    recipients,
    presentTimeFrame,
    presentDateRange,
    frequencyOption,
    piiMaskChoice,
    headerIntValue,
    selectedDedupeVariables,
    selectedOutputFormat,
    runTimeFrame,
    runDateRange,
    mediaPartner,
    mediaPartnerList,
    placement,
    placementList,
  ]);

  useEffect(() => {
    if (selectedReportDetails) {
      updateFrequency(selectedReportDetails, setFrequency, setFrequencyOption);

      const campaignStartDate = dateFormat(
          selectedReportDetails.start_date ?? currentDate,
          'YYYY-MM-DD'
        ),
        campaignEndDate = dateFormat(
          selectedReportDetails.end_date ?? currentDate,
          'YYYY-MM-DD'
        ),
        campaignLookbackDate = dateFormat(
          selectedReportDetails.lookback_date ?? currentDate,
          'YYYY-MM-DD'
        ),
        campaignFinalLiftDate = calculationForLastLiftperiod(
          campaignStartDate,
          campaignLookbackDate
        );

      presentTimeFrameOptions.map((option) => {
        const { isDisabled } = getStartAndEndDate(
          option.id,
          currentDate,
          campaignStartDate,
          campaignEndDate,
          campaignLookbackDate
        );
        option.isDisabled = isDisabled;
      });

      runTimeFrameOptions.map((option) => {

        option.isDisabled = false;
        option.showWarning = false;
        option.warningType = {
          beforeCurrentDate: false,
          startDateAfterCampaignDates: false,
          endDateAfterCampaignDates: false,
          startDateAfterEndDate: false,
        };
        option.dateRange = getStartAndEndDatesForRunTimeFrames(
          option.id,
          selectedReportDetails.date_created,
          campaignEndDate,
          campaignLookbackDate,
          campaignFinalLiftDate
        );

        const warningInfo = runTimeFrameWarningCheck(
          option.id,
          option.dateRange[0],
          option.dateRange[1],
          currentDate,
          campaignStartDate,
          campaignEndDate,
          campaignLookbackDate,
          campaignFinalLiftDate,
        );
        option.showWarning = warningInfo?.showWarning;
        option.warningType = warningInfo?.warningType;
      });

      let newSelectedReportDetails = { ...selectedReportDetails };
      if (
        selectedReportDetails.run_time_frame === 0 &&
        (!selectedReportDetails.run_date_start ||
          selectedReportDetails.run_date_end)
      ) {
        newSelectedReportDetails = {
          ...selectedReportDetails,
          run_date_start: campaignStartDate,
          run_date_end: currentDate,
        };
      }

      if (selectedReportDetails?.report_name) {
        setReportName(selectedReportDetails.report_name);
      }

      if (selectedReportDetails?.run_time_frame || selectedReportDetails?.run_time_frame === 0) {
        setRunTimeFrame(
          runTimeFrameOptions.find(
            (el) => el.id === selectedReportDetails.run_time_frame
          )
        );
      }

      if (selectedReportDetails?.run_date_start && selectedReportDetails?.run_date_end) {
        setRunDateRange([
          dateFormat(selectedReportDetails.run_date_start.slice(0, selectedReportDetails?.run_date_start.length - 1), 'MM/DD/YYYY'),
          dateFormat(selectedReportDetails.run_date_end.slice(0, selectedReportDetails?.run_date_end.length - 1), 'MM/DD/YYYY'),
        ]);
      }

      setOldSelections(newSelectedReportDetails);
      setReportStatus(
        statusOptions.find((el) => el.id === selectedReportDetails.status)
      );
      if (selectedReportDetails?.recipients.length) {
        setRecipients(changeRecipientsToProper(selectedReportDetails.recipients));
        const reportType = reportTypeOptions.find(
          (o) => o.name === selectedReportDetails.report_type
        );
        setReportType(reportType);
      }

      if ((selectedReportDetails.report_type !== 'Respondent Data' && selectedReportDetails.timeframe === 0) || 
      (selectedReportDetails.report_type === 'Respondent Data' && selectedReportDetails.timeframe === 14)) {
        setPresentDateRange([
          dateFormat(selectedReportDetails.custom_start_date.slice(0, selectedReportDetails.custom_start_date.length - 1), 'MM/DD/YYYY'),
          dateFormat(selectedReportDetails.custom_end_date.slice(0, selectedReportDetails.custom_end_date.length - 1), 'MM/DD/YYYY'),
        ]);
      }

      if (selectedReportDetails.report_type === 'Respondent Data') {
        setPiiMaskChoice(piiMaskList.find((el) => el.value == selectedReportDetails.pii_mask));
        setHeaderIntValue(headerChoiceList.find((el) => el.value == selectedReportDetails.header));
        setSelectedOutputFormat(outputFormatList.find((el) => el.value == selectedReportDetails.report_format));
        if (selectedReportDetails.custom_var_dedupe) {
          const filteredDedupeList = dedupeVariableList.filter((el) => selectedReportDetails.custom_var_dedupe.split(',').includes(el.label));
          setSelectedDedupeVariables(filteredDedupeList.map((el) => ({id: el.id, label: el.label})));
        }
      }
      setIsFirstInitiation(false);
    }
  }, [selectedReportDetails]);

  useEffect(() => {
    if (presentTimeFrame) {
      if (presentTimeFrame.isDisabled) {
        setShowFrequencyWarnModal(true);
      }

      if ((reportType?.id !== 2 && presentTimeFrame.id !== 0) || (reportType?.id === 2 && (presentTimeFrame.id !== 14 && presentTimeFrame.value !== '14'))) {
        setPresentDateRange([null, null]);
      }
    }
  }, [presentTimeFrame]);

  useEffect(() => {
    if (runTimeFrame?.id === 3) {
      getUpdatedRunDateRangeBasedOnFrequency(
        frequencyOption,
        runDateRange,
        setTempRunEndDate
      );
    }
  }, [frequencyOptions]);

   useEffect(() => {
    let placementIds;
    let updatedPlacement;
     if (placementList && !placement && [3, 4, 5].includes(reportType?.id)) {
      if(!selectedReportDetails?.placement_ids || selectedReportDetails?.placement_ids === "0" && (reportType?.id === 3 || reportType?.id === 4 || reportType?.id === 5)){
        placementIds = placementList.map(placement => placement.placement_id);
        updatedPlacement = placementIds.map(id => {
        return placementList.find(placement => placement.placement_id === id);
        });
      } else if((reportType?.id === 3 || reportType?.id === 4 || reportType?.id === 5)) {
        placementIds = selectedReportDetails?.placement_ids?.split(',');
        updatedPlacement = placementIds.map(id => {
        return placementList.find(placement => placement.placement_id === Number(id));
        });
      }
    setPlacement(updatedPlacement);
   }
  }, [placementList]);
  
  useEffect(() => {
    if (runTimeFrame) {
      setRunDateRange(runTimeFrame.dateRange);
    }

    if (runTimeFrame?.showWarning && reportType?.id !== 2 && frequencyOption?.repeatEvery.key !== 1) {
      setShowRunTimeFrameWarnModal(true);
    }


    
    if (runTimeFrame?.id === 3) {
      getUpdatedRunDateRangeBasedOnFrequency(
        frequencyOption,
        runTimeFrame.dateRange,
        setTempRunEndDate
      );
    }
  }, [runTimeFrame, runTimeFrame?.dateRange]);

  useEffect(() => {
    if (runTimeFrame?.id === 0 && runDateRange && runDateRange[0] && runDateRange[1]) {
      const campaignStartDate = dateFormat(
        selectedReportDetails.start_date ?? currentDate,
        'YYYY-MM-DD'
      ),
      campaignEndDate = dateFormat(
        selectedReportDetails.end_date ?? currentDate,
        'YYYY-MM-DD'
      ),
      campaignLookbackDate = dateFormat(
        selectedReportDetails.lookback_date ?? currentDate,
        'YYYY-MM-DD'
      ),
      campaignFinalLiftDate = calculationForLastLiftperiod(
        campaignStartDate,
        campaignLookbackDate
      );
      runTimeFrameOptions.map((option) => {
        if (option.id === 0) {
          option.dateRange = [...runDateRange];
          const warningInfo = runTimeFrameWarningCheck(
            option.id,
            option.dateRange[0],
            option.dateRange[1],
            currentDate,
            campaignStartDate,
            campaignEndDate,
            campaignLookbackDate,
            campaignFinalLiftDate,
          );
          option.showWarning = warningInfo?.showWarning;
          option.warningType = warningInfo?.warningType;
          if (option.showWarning && reportType?.id !== 2 && frequencyOption?.repeatEvery.key !== 1) {
            setShowRunTimeFrameWarnModal(true);
          }
        }
      });
      setRunTimeFrame(runTimeFrameOptions.find((el) => el.id === 0));
    }
  }, [runDateRange])

  useEffect(() => {
    if (reportType?.id === 0 && runTimeFrame?.id === 3) {
      setRunTimeFrame(null);
      setRunDateRange([null, null]);
      setTempRunEndDate(null);
    }

    runTimeFrameOptions.map((option) => {
      if (option.id === 3) {
        option.isDisabled =
          !reportType?.id
      }
    });
  }, [reportType]);

  // Saving initiated if no errors have been found
  const initiateSave = () => {
    if (
      !recipients || recipients.length === 0 ||
      (((reportType.id !== 2 && presentTimeFrame?.id === 0) || (reportType.id === 2 && presentTimeFrame?.id === 14)) &&
        (!presentDateRange[0] || !presentDateRange[1]))
    ) {
      deactiveSaveCTAForError();
      return getNotification('danger', getErrorMessage(), setIsSaveDisable);
    }

    getSaved();
  };

  const getSaved = () => {
    dispatch(setBlockingLoaderState({ active: true }));
    const piiMaskChoiceValue = reportType.id === 2 && piiMaskChoice?.value ? 1 : 0;
    const headerIntValueChoice = reportType.id === 2 && headerIntValue?.value ? headerIntValue.value : 0;
    const selectedDedupeVariablesChoice = [];
    if(reportType.id === 2 && selectedDedupeVariables?.length > 0){
      for(let dedupeChoice of selectedDedupeVariables){
        if(dedupeChoice){
          selectedDedupeVariablesChoice.push(dedupeChoice.label)        
        }
      }
    }
    const selectedOutputFormatChoice = reportType.id === 2 ? selectedOutputFormat?.value : 0;
    const isAllPartnerSelected =  checkSelectAll(
      mediaPartnerList,
      mediaPartner,
      'partner_id'
    );
    const isAllPlacementSelected =  checkSelectAll(
      placementList,
      placement,
      'placement_id'
    );
    let placementIds = '';
    if ((reportType?.id === 3 || reportType?.id === 4 || reportType?.id === 5)) {
      for(let i = 0; i < placement.length; i++){
        if(i < placement.length - 1){
          placementIds += placement[i].placement_id + ',';
        } else {
          placementIds += placement[i].placement_id;
        }
      }
    }
    
    const requestParams = {
      report_type: reportType.id,
      report_id: selectedReportDetails.report_id,
      report_name: reportName,
      status: reportStatus.id,
      recipients,
      mediaPartners: isAllPartnerSelected ? null : mediaPartner,
      time_frame: presentTimeFrame.id,
      start_date: presentDateRange[0]
        ? moment(presentDateRange[0]).format('YYYY-MM-DD HH:mm:ss')
        : null,
      end_date: presentDateRange[1]
        ? moment(presentDateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        : null,
      frequency_options: frequencyOption,
      run_time_frame: runTimeFrame?.id,
      run_date_start: runDateRange[0] ? moment(runDateRange[0]).format('YYYY-MM-DD HH:mm:ss') : null,
      run_date_end: getRunEndDateParam(runTimeFrame?.id, runDateRange, tempRunEndDate),
      placement_ids: (reportType?.id <= 2 || isAllPlacementSelected) ? '0' : placementIds,
      campaign_id: selectedReportDetails?.campaign_id,
      custom_var_dedupe: selectedDedupeVariablesChoice,
      reportFormatId: selectedOutputFormatChoice,
      piiMask: piiMaskChoiceValue,
      header: headerIntValueChoice,
    };

    setParams(requestParams);
    setIsMounted(true);
  };

  const editScheduledReportCallback = (status, data, error) => {
    if (status !== 200) {
      dispatch(setBlockingLoaderState({ active: false }));
      return getNotification(
        'danger',
        (data?.error && Object.keys(data.error).length > 0) ? data?.error.message : error?.message ? error.message : 'Something Went Wrong',
        setIsSaveDisable
      );
    }

    closeEditScheduledReportModal();
    dispatch(setBlockingLoaderState({ active: false }));
    onSaveSuccesss();
    getNotification('success', data?.message || '');
  };

  // Post API Interceptor to save updated scheduled report
  usePostAPIInterceptor(
    isMounted,
    params,
    `${baseEndpoints.reports}/update-scheduled-report`,
    editScheduledReportCallback
  );

  const closeEditScheduledReportModal = () => {
    removeNotification();
    setIsModalOpen(false);
  };

  const deactiveSaveCTAForError = () => {
    setIsSaveDisable(true);
    setTimeout(() => {
      setIsSaveDisable(false);
    }, 5000);
  };

  const getErrorMessage = () => {
    let errorMessage = '';

    if (recipients || recipients.length === 0) {
      errorMessage = <div>{errAndWarnMsg.noRecipientErr}</div>;
    }

    if (
      ((reportType.id !== 2 && presentTimeFrame?.id === 0) || (reportType.id === 2 && presentTimeFrame?.id === 14)) &&
      (!presentDateRange[0] || !presentDateRange[1])
    ) {
      errorMessage = errorMessage ? (
        <div>
          {errorMessage} <div>{errAndWarnMsg.customDateRngErr}</div>
        </div>
      ) : (
        <div>{errAndWarnMsg.customDateRngErr}</div>
      );
    }

    return errorMessage;
  };

  const saveFrequencyOptions = (selectedOptions) => {
    setShowFrequencyModal(false);
    setFrequencyOption(selectedOptions);
    setFrequency(selectedOptions.repeatEvery.name.toUpperCase());
  };

  return (
    <div className='createScheduledReportWrapper editScheduledReportWrapper'>
      <CommonModal
        show={isModalOpen}
        header='Edit Scheduled Report'
        body={
          <RenderModalBody
            oldSelections={oldSelections}
            reportName={reportName}
            setReportName={setReportName}
            reportStatus={reportStatus}
            setReportStatus={setReportStatus}
            recipients={recipients}
            setRecipients={setRecipients}
            presentTimeFrame={presentTimeFrame}
            setPresentTimeFrame={setPresentTimeFrame}
            presentDateRange={presentDateRange}
            setPresentDateRange={setPresentDateRange}
            currentDate={currentDate}
            runTimeFrame={runTimeFrame}
            setRunTimeFrame={setRunTimeFrame}
            runDateRange={runDateRange}
            setRunDateRange={setRunDateRange}
            saveFrequencyOptions={saveFrequencyOptions}
            showFrequencyModal={showFrequencyModal}
            setShowFrequencyModal={setShowFrequencyModal}
            frequencyOption={frequencyOption}
            frequency={frequency}
            mediaPartnerList={mediaPartnerList}
            mediaPartner={mediaPartner}
            showMediaPartnerDropdownWarning={showMediaPartnerDropdownWarning}
            setMediaPartner={setMediaPartner}
            setMediaPartnerList={setMediaPartnerList}
            setShowMediaPartnerDropdownWarning={setShowMediaPartnerDropdownWarning}
            reportType={reportType}
            presentTimeFrameOptions={presentTimeFrameOptions}
            respondentDataTimeframeOptions={respondentDataTimeframeOptions}
            placement={placement}
            setPlacement={setPlacement}
            placementList={placementList}
            setPlacementList={setPlacementList}
            showPlacementDropdownWarning={showPlacementDropdownWarning}
            setShowPlacementDropdownWarning={setShowPlacementDropdownWarning}
            piiMaskChoice={piiMaskChoice}
            setPiiMaskChoice={setPiiMaskChoice}
            headerIntValue={headerIntValue}
            setHeaderIntValue={setHeaderIntValue}
            selectedDedupeVariables={selectedDedupeVariables}
            setSelectedDedupeVariables={setSelectedDedupeVariables}
            selectedOutputFormat={selectedOutputFormat}
            setSelectedOutputFormat={setSelectedOutputFormat}
          />
        }
        hideCloseBtn={true}
        size='otp'
        acceptBtnText='Save'
        accept={() => {
          initiateSave();
        }}
        reject={closeEditScheduledReportModal}
        disableAcceptBtn={isSaveDisable}
        buttonUI='editScheduledReport'
      />
      {(showFrequencyWarnModal || showRunTimeFrameWarnModal) && (
        <CommonModal
          size='md'
          header='Warning'
          show={showFrequencyWarnModal || showRunTimeFrameWarnModal}
          hideCloseBtn={true}
          body={<RenderFreqRunTimeErrModalBody 
                  showFrequencyWarnModal={showFrequencyWarnModal}
                  showRunTimeFrameWarnModal={showRunTimeFrameWarnModal}
                  runTimeFrame={runTimeFrame}
                />}
          acceptBtnText='Continue'
          accept={() => {
            setShowFrequencyWarnModal(false);
            setShowRunTimeFrameWarnModal(false)
          }}
          buttonUI='editScheduledReportError'
        />
      )}
    </div>
  );
};

export default ScheduledReportEditComponent;

// Checks when the save button should be enabled
// Change in any of Status, Frequency, Preset Time Frame, Recipient, enables the save button
const validateEnableSave = (
  reportName,
  reportStatus,
  presentTimeFrame,
  presentDateRange,
  recipients,
  selectedReportDetails,
  frequencyOption,
  piiMaskChoice,
  headerIntValue,
  selectedDedupeVariables,
  selectedOutputFormat,
  reportType,
  runTimeFrame,
  runDateRange,
  mediaPartner,
  mediaPartnerList,
  placement,
  placementList,
) => {

  if (
    !reportName ||
    (frequencyOption?.repeatEvery?.key !== 1 && runTimeFrame?.showWarning && runTimeFrame?.warningType.startDateAfterEndDate) ||
    ((!runDateRange[0] || !runDateRange[1]) && frequencyOption?.repeatEvery?.key !== 1 && reportType !== 2) ||
    (!presentTimeFrame?.id && presentTimeFrame?.id !== 0) ||
    (((reportType !== 2 && presentTimeFrame?.id === 0) || (reportType === 2 && presentTimeFrame?.id === 14)) && (!presentDateRange[0] || !presentDateRange[1])) ||
    reportName.length > reportNameMaxLength ||
    checkReportNameForSpecialCharacters(reportName) ||
    !recipients || recipients.length === 0 ||
    (![1, 2].includes(reportType) && !mediaPartner || mediaPartner?.length === 0) ||
    ([3, 4, 5].includes(reportType) && !placement || placement?.length === 0)
    ) {
    return true;
  }

  return (
    reportName === selectedReportDetails.report_name &&
    reportStatus?.id === selectedReportDetails?.status &&
    presentTimeFrame?.id === selectedReportDetails.timeframe &&
     JSON.stringify(recipients?.sort()) === JSON.stringify(changeRecipientsToProper(selectedReportDetails.recipients).sort()) &&
      (presentDateRange[0] ? dateFormat(presentDateRange[0], 'MM/DD/YYYY') : null) ===
      (selectedReportDetails.custom_start_date
        ? dateFormat(selectedReportDetails.custom_start_date.slice(0, selectedReportDetails.custom_start_date.length - 1), 'MM/DD/YYYY')
        : null) &&
        (presentDateRange[1] ? dateFormat(presentDateRange[1], 'MM/DD/YYYY') : null) ===
      (selectedReportDetails.custom_end_date
        ? dateFormat(selectedReportDetails.custom_end_date.slice(0, selectedReportDetails.custom_end_date.length - 1), 'MM/DD/YYYY')
        : null) &&
    validateFrequency(selectedReportDetails, frequencyOption, reportType) &&
    runTimeFrame?.id == selectedReportDetails.run_time_frame &&
    (runDateRange[0] ? dateFormat(runDateRange[0], 'MM/DD/YYYY') : null) ===
    (selectedReportDetails.run_date_start
      ? dateFormat(selectedReportDetails.run_date_start.slice(0, selectedReportDetails.run_date_start.length - 1), 'MM/DD/YYYY')
      : null) &&
    (runDateRange[1] ? dateFormat(runDateRange[1], 'MM/DD/YYYY') : null) ===
    (selectedReportDetails.run_date_end
      ? dateFormat(selectedReportDetails.run_date_end.slice(0, selectedReportDetails.run_date_end.length - 1), 'MM/DD/YYYY')
      : null) &&
    checkMediaPartnerChanged(reportType, selectedReportDetails, mediaPartner, mediaPartnerList) &&
    checkPlacementChanged(reportType, selectedReportDetails, placement, placementList) &&
    (reportType === 2 ?
      piiMaskChoice?.value == selectedReportDetails.pii_mask &&
      headerIntValue?.value === selectedReportDetails.header &&
      selectedOutputFormat?.value === selectedReportDetails.report_format &&
      selectedDedupeVariables?.map(item => item.id)?.sort().join(', ') ===
        dedupeVariableList?.filter((el) =>
          selectedReportDetails.custom_var_dedupe?.split(',').includes(el.label)
        ).map(item => item.id).sort().join(', ')
        : true
    )
  );
};

const checkPlacementChanged = (reportType, selectedReportDetails, placement, placementList) => {
  if (![3, 4, 5].includes(reportType)) {
    return true;
  }
  if ((!selectedReportDetails?.placement_ids || selectedReportDetails?.placement_ids === '0') && placement && placementList) {
    const placementIds = placement.map(placement => placement.placement_id);
    const placementListIds = placementList.map(placement => placement.placement_id);
    return JSON.stringify(placementIds.sort((a, b) => a - b)) === JSON.stringify(placementListIds.sort((a, b) => a - b));
  } else {
    const parsedRetrivedPlacements = selectedReportDetails?.placement_ids?.split(',').map(id => Number(id));
    const placementIds = placement.map(placement => placement.placement_id);
    return JSON.stringify(placementIds?.sort((a, b) => a - b)) === JSON.stringify(parsedRetrivedPlacements?.sort((a, b) => a - b));
  }
}

const checkMediaPartnerChanged = (reportType, selectedReportDetails, mediaPartner, mediaPartnerList) => {
  if ([1, 2].includes(reportType)) {
    return true;
  }
  if ((!selectedReportDetails?.media_partners_filter || JSON.parse(selectedReportDetails?.media_partners_filter)?.length === 0) && mediaPartner && mediaPartnerList) {
    return JSON.stringify(mediaPartner.sort((a, b) => a.partner_id - b.partner_id)) === JSON.stringify(mediaPartnerList.sort((a, b) => a.partner_id - b.partner_id));
  } else {
    const parsedRetrivedMediaPartners = JSON.parse(selectedReportDetails?.media_partners_filter)
    return JSON.stringify(mediaPartner?.sort((a, b) => a.partner_id - b.partner_id)) === JSON.stringify(parsedRetrivedMediaPartners?.sort((a, b) => a.partner_id - b.partner_id));
  }
}

const changeRecipientsToProper = (recipients) => {
  let recToSet = [];
  if (recipients[0] === '[') {
    recToSet = JSON.parse(recipients)[0].emails ?? [];
  } else {
    const splitRecipients = recipients.split(';').map((item) => item.trim()).filter((item) => item !== '');
    recToSet = splitRecipients ?? [];
  }
  return recToSet;
}

// Additional validation for Frequency
const validateFrequency = (selectedReportDetails, frequencyOption, reportType) => {
  const weekDaysKeyArray = selectedReportDetails.week_days
    ?.split(',')
    ?.map((elem) => Number(elem));

  if (frequencyOption?.repeatEvery?.key == selectedReportDetails?.frequency) {
    if (frequencyOption?.repeatEvery?.key === 2 || reportType === 2) {
      return true;
    }

    if (frequencyOption?.repeatEvery?.key === 4) {
      if (frequencyOption?.selectedDate > 0) {
        return (
          frequencyOption?.selectedDate == selectedReportDetails?.frequency_date
        );
      }

      return (
        frequencyOption?.selectedRecurrence ==
          selectedReportDetails?.frequency_recurrence ||
        JSON.stringify(frequencyOption?.selectedDays) ===
          JSON.stringify(weekDaysKeyArray)
      );
    }

    return (
      JSON.stringify(frequencyOption?.selectedDays) ===
      JSON.stringify(weekDaysKeyArray || [])
    );
  }
  return false;
};

// Formats the initial Frequency data before saving to the state
const updateFrequency = (
  selectedReportDetails,
  setFrequency,
  setFrequencyOption
) => {
  const isRespondantData = selectedReportDetails.report_type === 'Respondent Data';
  const frequencyOptionsToUse = isRespondantData ? respondantFrequencyOptions : frequencyOptions,
    weekDaysKeyArray = selectedReportDetails.week_days
      ?.split(',')
      ?.map((elem) => Number(elem)),
    lastFrequencyOption = frequencyOptionsToUse.filter(
      (item) => Number(item.key) === selectedReportDetails.frequency
    ),
    usableFrequencyOption =
      lastFrequencyOption[
        selectedReportDetails.frequency === 6 &&
        weekDaysKeyArray && JSON.stringify([...weekDaysKeyArray].sort()) !==
          JSON.stringify([1, 2, 3, 4, 5])
          ? 1
          : 0
      ];
      
  setFrequency(usableFrequencyOption?.name?.toUpperCase());

  setFrequencyOption({
    repeatEvery: { ...usableFrequencyOption },
    selectedDays: weekDaysKeyArray?.length ? [...weekDaysKeyArray] : [],
    selectedDate: selectedReportDetails.frequency_date ?? -1,
    selectedRecurrence: selectedReportDetails.frequency_recurrence ?? -1,
  });
};

// Body of Edit/Update modal
const RenderModalBody = ({
  oldSelections,
  reportName,
  setReportName,
  reportStatus,
  setReportStatus,
  recipients,
  setRecipients,
  presentTimeFrame,
  setPresentTimeFrame,
  presentDateRange,
  setPresentDateRange,
  currentDate,
  runTimeFrame,
  setRunTimeFrame,
  runDateRange,
  setRunDateRange,
  saveFrequencyOptions,
  showFrequencyModal,
  setShowFrequencyModal,
  frequencyOption,
  frequency,
  mediaPartnerList,
  mediaPartner,
  showMediaPartnerDropdownWarning,
  setMediaPartner,
  setMediaPartnerList,
  setShowMediaPartnerDropdownWarning,
  reportType,
  presentTimeFrameOptions,
  respondentDataTimeframeOptions,
  placement,
  setPlacement,
  placementList,
  setPlacementList,
  showPlacementDropdownWarning,
  setShowPlacementDropdownWarning,
  piiMaskChoice,
  setPiiMaskChoice,
  headerIntValue,
  setHeaderIntValue,
  selectedDedupeVariables,
  setSelectedDedupeVariables,
  selectedOutputFormat,
  setSelectedOutputFormat,
}) => {

  const [dedupesToShow, setDedupesToShow] = useState([]);

  useEffect(() => {
    if (reportType?.id === 2) {
      setDedupesToShow(dedupeVariableList.filter(el => selectedDedupeVariables.map(item => item.id).includes(el.id)));
    }
  }, [selectedDedupeVariables]);
  
  return (
    <div className='scheduledReportBodyWrapper'>
      <Container fluid className='noMarginPadding'>
        <Row className='noMarginPadding elementAlignLeft '>
          <div className='col-4 scheduledReportElementWrapper'>
            <FormatLabel
              label='Report Name'
              isMandatory
              customClass='field-name'
            />
            <CommonInput
              label=''
              inputValue={reportName}
              setInputValue={setReportName}
              className='scheduledReportField'
              maxLength={reportNameMaxLength}
            />
            {checkReportNameForSpecialCharacters(reportName) ? (
              <div className='editScheduledReportFieldError'>
                Report Name Cannot Include the Following Characters: {'" / * : < > ? | + \' , . ; = [ ]'}
              </div>
            ) : reportName?.length >= reportNameMaxLength && (
              <div className='editScheduledReportFieldError'>
                Report Name Is Limited To {reportNameMaxLength} Characters - Current: {reportName.length}
              </div>
            )}
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            <Row>
              <div className='col-7'>
                <FormatLabel label='Report Type' isMandatory customClass='field-name' />
                <SearchableDropdownComponent
                  options={reportTypeOptions}
                  label='name'
                  id='id'
                  dropdownId='editScheduledReportType'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Report Type'
                  selectedValue={reportTypeOptions.find(
                    (el) => el.id === reportType?.id
                  )}
                  isSearchable={false}
                  isDisabled={true}
                  uniqueIdentifier='editScheduledReportTypes'
                />
              </div>
              <div className='col-5'>
                <FormatLabel label='Status' isMandatory customClass='field-name' />
                <SearchableDropdownComponent
                  options={statusOptions}
                  label='name'
                  id='id'
                  dropdownId='editScheduledReportStatus'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Report Status'
                  selectedValue={reportStatus}
                  handleChange={setReportStatus}
                  isSearchable={false}
                  uniqueIdentifier='editScheduledReportStatuses'
                />
              </div>
            </Row>
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            <FormatLabel
              label='Frequency'
              isMandatory
              customClass='field-name'
            />
            <CommonButtonComponent
              label={frequency}
              appendWrapperClassName='frequencyBtnWrapper'
              appendButtonClassName='frequencyButton'
              showLoader={false}
              onClickCTA={() => setShowFrequencyModal(true)}
              isDisabled={!frequencyOption}
              buttonUI='editScheduledReportFrequencyModal'
            />
          </div>
        </Row>
        <Row className={`noMarginPadding ${(reportType?.id !== 2 && frequencyOption?.repeatEvery?.key != 1) ? 'elementAlignLeft' : 'elementAlignLeft spaceBetween'}`}>
          <div className='col-4 scheduledReportElementWrapper'>
            <FormatLabel label='Campaign' customClass='field-name' />
            <CommonInput
              label=''
              inputValue={oldSelections?.campaign_name}
              className='scheduledReportField'
              disabled={true}
            />
          </div>
          {(reportType?.id !== 2 && frequencyOption?.repeatEvery?.key != 1) && <div className='col-4 scheduledReportElementWrapper'>
            <FormatLabel label='How Long Report Will Run' isMandatory customClass='field-name' />
            <SearchableDropdownComponent
              options={runTimeFrameOptions}
              label='name'
              id='id'
              dropdownId='editScheduledReportRunTimeFrame'
              dropdownLabel=''
              labelWidth={{ margin: 0 }}
              dropdownWidth={{
                width: '100%',
              }}
              placeholderLabel='Select Time Frame'
              selectedValue={runTimeFrameOptions.find(
                (el) => el.id === runTimeFrame?.id
              )}
              handleChange={setRunTimeFrame}
              isSearchable={false}
              uniqueIdentifier='editScheduledReportRunTimeFrames'
              ifShowDisabledOptions={true}
              ifShowWarningAtOptions={true}
              disabledOptionId='isDisabled'
              warningOptionId={'showWarning'}
              isDisabled={!runTimeFrame}
            />
            {runTimeFrame?.showWarning && runTimeFrame.warningType.startDateAfterEndDate && (
              <div className='editScheduledReportFieldError'>
                Invalid Time Frame: Start Date Cannot Be After End Date
              </div>
            )}
          </div>}
          <div className='col-4 scheduledReportElementWrapper'>
            {reportType?.id !== 2 && (
              <div>
                <FormatLabel
                  label='Days Worth of Data'
                  isMandatory
                  customClass='field-name'
                />
                <SearchableDropdownComponent
                  options={presentTimeFrameOptions}
                  label='name'
                  id='id'
                  dropdownId='scheduledReportTimeFrame'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Time Frame'
                  selectedValue={presentTimeFrame}
                  handleChange={setPresentTimeFrame}
                  isSearchable={false}
                  uniqueIdentifier='scheduledReportTimeFrames'
                  ifShowWarningAtOptions={true}
                  warningOptionId='isDisabled'
                  warningMessage={'Warning! ' + errAndWarnMsg.nullImpFreqWarn}
                />
              </div>
            )}
            {reportType?.id === 2 && (
              <div>
                <FormatLabel
                  label='Preset Time Frame'
                  isMandatory
                  customClass='field-name'
                />
                <SearchableDropdownComponent
                  options={respondentDataTimeframeOptions}
                  label='name'
                  id='id'
                  dropdownId='scheduledReportTimeFrameForRespondentData'
                  dropdownLabel=''
                  labelWidth={{ margin: 0 }}
                  dropdownWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Select Time Frame'
                  selectedValue={presentTimeFrame}
                  handleChange={setPresentTimeFrame}
                  isSearchable={false}
                  uniqueIdentifier='scheduledReportTimeFrames'
                  ifShowWarningAtOptions={true}
                  warningOptionId='isDisabled'
                  warningMessage={'Warning! ' + errAndWarnMsg.nullImpFreqWarn}
                />
              </div>
            )}
          </div>
        </Row>
        <Row className='noMarginPadding elementAlignLeft'>
          <div className='col-4 scheduledReportElementWrapper'>
            {reportType && (reportType.id === 3 || reportType.id === 4) ? (
                  <div className="scheduledReportPlacementsWrapper">
                    <div className="scheduledReportPlacementsWrapper">
                <div>
                  <FormatLabel
                      label='Placements'
                      isMandatory
                      customClass='field-name'
                  />
                </div>
                <div>
                  <MultiselectDropdownComponent
                dropdownId='placementEditModal'
                dropdownDataList={placementList}
                dataId="placement_id"
                dataName="placement_name"
                dataType="type"
                dropdownData={placement}
                setDropdownData={(data) => {
                  setPlacement(data ?? [])
                  if(!data){
                    setShowPlacementDropdownWarning(true);
                    getNotification(
                      'warning',
                      'Placements list can not be empty!'
                    );
                  } else {
                    setShowPlacementDropdownWarning(false);
                  }
                }}
                ifIdSearchAvailable={true}
                txtSearch="Search by placement name"
                isAllCheckedInitially={checkSelectAll(
                  placementList,
                  placement,
                  'placement_id'
                )}
                dropdownWidth={{
                  width: '312px',
                  minWidth: '190px'
                }}
                isLoading={false}
                isDisabled={false}
                uniqueIdentifier='reportsEditModalPlacements'
                ifIdVisibleOnLabel={true}
              /> 
              {showPlacementDropdownWarning && (
                <div className='editScheduledReportFieldError'>
                  Placement List Cannot Be Empty
                </div>
              )}
                </div>
              </div>
                  </div>
                ) : <></>}
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            {runTimeFrame?.id === 0 && frequencyOption?.repeatEvery?.key != 1 && (
              <div>
                <FormatLabel
                  label='Custom Run Date Range'
                  customClass='field-name'
                  isMandatory={true}
                />
                <DatePickerComponent
                  datePickerId='editScheduledReportDateRange'
                  label=''
                  labelWidth={{ padding: 0, margin: 0 }}
                  datePickerBodyWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Start Date - End Date'
                  minDate={currentDate}
                  maxDate={null}
                  isDateRangePicker={true}
                  dateRange={runDateRange}
                  setDateRange={setRunDateRange}
                  isDisabled={false}
                  format='MM/DD/YYYY'
                  currentYear={moment(currentDate).year()}
                  startYear={moment(currentDate).year() - 1}
                  futureYear={moment(currentDate).year() + 5}
                  futureYearNeeded={true}
                  uniqueIdentifier='editScheduledReportRun'
                />
              </div>
            )}
          </div>
          <div className='col-4 scheduledReportElementWrapper'>
            {(presentTimeFrame?.id === 0   || (reportType?.id === 2 && presentTimeFrame?.id === 14) )&& (
              <div>
                <FormatLabel
                  label='Custom Date Range'
                  customClass='field-name'
                  isMandatory={((reportType?.id !== 2 && presentTimeFrame?.id === 0) || (reportType?.id === 2 && presentTimeFrame?.id === 14))}
                />
                <DatePickerComponent
                  datePickerId='editScheduledReportDateRange'
                  label=''
                  labelWidth={{ padding: 0, margin: 0 }}
                  datePickerBodyWidth={{
                    width: '100%',
                  }}
                  placeholderLabel='Start Date - End Date'
                  minDate={oldSelections?.start_date}
                  maxDate={oldSelections?.lookback_date}
                  isDateRangePicker={true}
                  dateRange={presentDateRange}
                  setDateRange={setPresentDateRange}
                  isDisabled={(reportType?.id !== 2 && presentTimeFrame?.id !== 0)  || (reportType?.id === 2 && presentTimeFrame?.id !== 14)}
                  format='MM/DD/YYYY'
                  currentYear={checkCondition(
                    oldSelections &&
                      daysToFormatWRTDates(oldSelections?.lookback_date) <= 0,
                    moment(oldSelections?.lookback_date).year(),
                    moment(currentDate).year()
                  )}
                  startYear={checkCondition(
                    oldSelections,
                    moment(oldSelections?.start_date).year() - 1,
                    2010
                  )}
                  uniqueIdentifier='editScheduledReport'
                />
              </div>
            )}
          </div>
        </Row>
        <Row>
          <div className='col-12 scheduledReportElementWrapper'>
            <Row>
              {![1, 2, 5].includes(reportType?.id) && (
                <div className='col-6'>
                  <FormatLabel
                    label='Media Partner'
                    isMandatory
                    customClass='field-name'
                  />
                  <MultiselectDropdownComponent
                    dropdownId='mediaPartnerEdit'
                    dropdownDataList={mediaPartnerList}
                    dataId='partner_id'
                    isMandatory
                    dataName='partner_name'
                    dropdownData={mediaPartner}
                    setDropdownData={(data) => {
                      setMediaPartner(data ?? []);
                      if (!data) {
                        setShowMediaPartnerDropdownWarning(true);
                        getNotification(
                          'warning',
                          'Media Partners list can not be empty!'
                        );
                      } else {
                        setShowMediaPartnerDropdownWarning(false);
                      }
                    }}
                    ifIdSearchAvailable={true}
                    placeholder={setPlaceHolderLabel(false, 'Media Partner')}
                    txtSearch='Search by name of the partner'
                    isAllCheckedInitially={
                      JSON.stringify(mediaPartner) ==
                      JSON.stringify(mediaPartnerList)
                    }
                    labelWidth={{ width: 0 }}
                    dropdownWidth={{
                      width: 'calc(100%)',
                      minWidth: '162px',
                    }}
                    uniqueIdentifier='reportsCreateReportPartners'
                  />
                  {showMediaPartnerDropdownWarning && (
                    <div className='editScheduledReportFieldError'>
                      Media Partner List Cannot Be Empty
                    </div>
                  )}
                </div>
              )}

              <div className={![1, 2, 5].includes(reportType?.id) ? 'col-6' : 'col-12'}>
                <FormatLabel
                  label='Recipient'
                  isMandatory
                  customClass='field-name'
                />
                <MultiRecipients
                  emails={recipients}
                  setEmails={setRecipients}
                />
              </div>
            </Row>
          </div>
        </Row>
        {reportType?.id === 2 && (
          <div className="noMarginPadding elementAlignLeft row" id="respondentDataFields">
            <div className="col-2">
              <FormatLabel label="Include PII?" isMandatory customClass="field-name" />
              <SearchableDropdownComponent
                options={piiMaskList}
                label="label"
                id="value"
                dropdownId="createPiiMask"
                dropdownLabel=""
                labelWidth={{ margin: 0 }}
                dropdownWidth={{
                  width: '100%',
                }}
                placeholderLabel="PII Masking"
                selectedValue={piiMaskChoice}
                handleChange={setPiiMaskChoice}
                isSearchable={false}
                uniqueIdentifier="piiMaskingOptions"
                ifShowDisabledOptions={true}
                disabledOptionId="isDisabled"
              />
            </div>
            <div className="col-3">
              <FormatLabel
                // value={reportStatus}
                label="Include Column Headers?"
                isMandatory
                customClass="field-name"
              />
              <SearchableDropdownComponent
                options={headerChoiceList}
                label="label"
                id="value"
                dropdownId="createHeaderChoice"
                dropdownLabel=""
                labelWidth={{ margin: 0 }}
                dropdownWidth={{
                  width: '100%',
                }}
                placeholderLabel="Include Column Headers?"
                selectedValue={headerIntValue}
                handleChange={setHeaderIntValue}
                isSearchable={false}
                uniqueIdentifier="headerChoiceList"
              />
            </div>
            <div className="col-3">
              <FormatLabel label="Output Format" isMandatory customClass="field-name" />
              <SearchableDropdownComponent
                options={outputFormatList}
                label="label"
                id="value"
                dropdownId="createOutputFormat"
                dropdownLabel=""
                labelWidth={{ margin: 0 }}
                dropdownWidth={{
                  width: '100%',
                }}
                placeholderLabel="Select Output Format"
                selectedValue={selectedOutputFormat}
                handleChange={setSelectedOutputFormat}
                isSearchable={false}
                uniqueIdentifier="outputFormatList"
                ifShowDisabledOptions={true}
                disabledOptionId="isDisabled"
              />
            </div>
            <div className="col-4" id="multiSelectSchedReports">
              <FormatLabel
                // value={reportStatus}
                label="Dedupe By (select up to 3)"
                customClass="field-name"
              />
              <NewMultiSelect
                prefix="dedupeList"
                options={dedupeVariableList}
                dataId='id'
                dataName='label'
                selected={dedupesToShow}
                setSelected={setSelectedDedupeVariables}
                isMaxSelect={true}
                selectUpto={3}
                placeholderTxt='Dedupe Variables'
                label=''
                dropDownId='reportsDedupeVariableList'
                componentWrapperStyle={{
                  width: '260px',
                }}
                isLoading={false}
                isDisable={false} 
              />
            </div>
          </div>
        )}
      </Container>
      {frequencyOption && (
        <SetFrequencyModal
          show={showFrequencyModal}
          header='Set Frequency'
          bodyType='set-frequency'
          accept={(selectedOptions) => saveFrequencyOptions(selectedOptions)}
          reject={() => setShowFrequencyModal(false)}
          acceptBtnText='Save'
          size='sm'
          hideCloseBtn
          frequencyOpt={frequencyOption}
          uniqueIdentifier='scheduledReportFrequencyModal'
          reportType={reportType?.id}
          inEdit={true}
        />
      )}
    </div>
  );
};

// Modal body for showing warning to select a time-frame where supposably no impressions are available
const RenderFreqRunTimeErrModalBody = ({
  showFrequencyWarnModal,
  showRunTimeFrameWarnModal,
  runTimeFrame,
}) => {
  return (
    <div className='freqErrModalBodyWrapper'>
      {showFrequencyWarnModal && errAndWarnMsg.nullImpFreqWarn}
      {
        showRunTimeFrameWarnModal && 
        runTimeFrame?.warningType.beforeCurrentDate &&
        !runTimeFrame?.warningType.startDateAfterEndDate &&
        (<div>{errAndWarnMsg.runTimeFrameBeforeCurrentDateWarn}</div>)
      }
      {
        showRunTimeFrameWarnModal &&
        runTimeFrame?.warningType.startDateAfterCampaignDates &&
        !runTimeFrame?.warningType.startDateAfterEndDate &&
        (<div>{errAndWarnMsg.runTimeFrameStartDateAfterCampaignDates}</div>)
      }
      {
        showRunTimeFrameWarnModal &&
        runTimeFrame?.warningType.endDateAfterCampaignDates &&
        !runTimeFrame?.warningType.startDateAfterEndDate &&
        (<div>{errAndWarnMsg.runTimeFrameEndDateAfterCampaignDates}</div>)
      }
      {
        showRunTimeFrameWarnModal &&
        runTimeFrame?.warningType.startDateAfterEndDate &&
        (<div>{errAndWarnMsg.runTimeFrameStartDateAfterEndDate}</div>)
      }
    </div>
  );
};

const runTimeFrameWarningCheck = (id, startDate, endDate, currentDate, campaignStartDate, campaignEndDate, campaignLookbackEndDate, campaignLiftEndDate) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const currentDateMoment = moment(currentDate);
  const campaignStartDateMoment = moment(campaignStartDate);
  const campaignEndDateMoment = moment(campaignEndDate);
  const campaignLookbackEndDateMoment = moment(campaignLookbackEndDate);
  const campaignLiftEndDateMoment = moment(campaignLiftEndDate);
  let beforeCurrentDate = false,
    startDateAfterCampaignDates = false,
    endDateAfterCampaignDates = false,
    startDateAfterEndDate = false,
    showWarning = false;
  if (id > 3 || (id === 0 && startDate && endDate)) {
    beforeCurrentDate = endDateMoment.isBefore(currentDateMoment);
    startDateAfterCampaignDates = startDateMoment.isAfter(campaignEndDateMoment) && startDateMoment.isAfter(campaignLookbackEndDateMoment) && startDateMoment.isAfter(campaignLiftEndDateMoment);
    endDateAfterCampaignDates = endDateMoment.isAfter(campaignEndDateMoment) && endDateMoment.isAfter(campaignLookbackEndDateMoment) && endDateMoment.isAfter(campaignLiftEndDateMoment);
    showWarning = (beforeCurrentDate || startDateAfterCampaignDates || endDateAfterCampaignDates);
  } else if (id === 1) {
    beforeCurrentDate = currentDateMoment.isSameOrAfter(campaignEndDateMoment);
    startDateAfterEndDate = startDateMoment.isAfter(endDateMoment);
    showWarning = (beforeCurrentDate || startDateAfterEndDate);
  } else if (id === 2) {
    beforeCurrentDate = currentDateMoment.isSameOrAfter(campaignLookbackEndDateMoment);
    startDateAfterEndDate = startDateMoment.isAfter(endDateMoment);
    showWarning = (beforeCurrentDate || startDateAfterEndDate);
  } else if (id === 3) {
    beforeCurrentDate = currentDateMoment.isSameOrAfter(campaignLiftEndDateMoment);
    startDateAfterEndDate = startDateMoment.isAfter(endDateMoment);
    showWarning = (beforeCurrentDate || startDateAfterEndDate);
  }
  
  return {
    showWarning: showWarning, 
    warningType: {beforeCurrentDate, startDateAfterCampaignDates, endDateAfterCampaignDates, startDateAfterEndDate}
  };
}

const getRunEndDateParam = (runTimeFrameId, runDateRange, tempEndDate) => {
  let runEndDate = null;
  if (runTimeFrameId === 3) {
    runEndDate = tempEndDate;
  } else {
    runEndDate = runDateRange ? runDateRange[1] : null;
  }
  return runEndDate ? moment(
    runEndDate
  ).format('YYYY-MM-DD HH:mm:ss') : null;
}