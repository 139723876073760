import { ROW_DEFAULT_HEIGHT } from './constants';







export class RowModel {
  static DEFAULT_HEIGHT = ROW_DEFAULT_HEIGHT;

  data;
  metadata;
  $state;

  constructor(data, metadata, state) {
    this.data = data;
    this.$state = state;
    this.metadata = metadata;
  }
}

export default class Row extends RowModel {
  // RowAPI
  toggleChildren;
  updateData;

  constructor(model, api) {
    // RowModel
    super(model.data, model.metadata, model.$state)

    // RowAPI
    this.toggleChildren = api.toggleChildren;
    this.updateData = api.updateData;
  }
}