import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../../utils/functions';

export default [
  {
    id: 'id',
    name: 'ADVERTISER ID',
    selector: (row) => (
      <div className='tableCellFontStyle tableCampNameFontStyle'>
        <Link to={`/advertisers/${row.id}`}>
          <div className='campaignNameStyleCL'>{row.id}</div>
        </Link>
      </div>
    ),
    sortable: true,
    sortField: 'id', // sorting
    width: '145px',
    right: true,
  },
  {
    id: 'name',
    name: 'ADVERTISER NAME',
    selector: (row) => (
      <div className='tableCellFontStyle tableCampNameFontStyle'>
        <Link to={`/advertisers/${row.id}`}>
          <div className='campaignNameStyleCL'>{row.name}</div>
        </Link>
      </div>
    ),
    sortable: true, // sorting
    sortField: 'name', // sorting
  },
  {
    id: 'createdBy',
    name: 'CREATOR',
    selector: (row) => (
      <div className='tableCellFontStyle'>
        <div className='campaignNameStyleCL'>{row.createdBy}</div>
      </div>
    ),
    sortable: true, // sorting
    sortField: 'createdBy', // sorting
  },
  {
    id: 'createdOn',
    name: 'CREATED DATE',
    selector: (row) => (
      <div className='tableCellFontStyle'>
        <div className='campaignNameStyleCL'>
          {dateFormat(row.createdOn, 'MM/DD/YYYY')}
        </div>
      </div>
    ),
    sortable: true, // sorting
    sortField: 'createdOn', // sorting
  },
  {
    id: 'status',
    name: 'STATUS',
    selector: (row) => (
      <div className='tableCellFontStyle'>
        <div className='campaignNameStyleCL'>{row.status}</div>
      </div>
    ),
    sortable: true, // sorting
    sortField: 'status', // sorting
  },
];
