import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  ErrorComponent,
  SearchableDropdownComponent,
} from '../../../../../../components';
import IVTImpressionsReport from '../analysisSection/IVTImpressionsReport.component';
import AnalysisLineChart from './components/analysisLineChart/AnalysisLineChart.component';
import './analysis.css';
import NewMultiSelect from './components/dropDownAnalysisSection/NewMultiSelect';

function AnalysisComponent({
  campaign,
  reportComponentIsLoading,
  placement,
  placementList,
  creativeType,
  mediaPartner,
  mediaPartnerList,
  startDate,
  endDate,
  haveSetDates,
  isSummaryError = false,
  ifSelectedAll,
  isLoading,
  getCampaignList,
  getPlacementList,
  getPartnerList,
  getCreativeTypeList,
  noData = false
}) {
  const [selectedPlacement, setSelectedPlacement] = useState(
      placement ? placement.slice(0, 10) : null
    ),
    [defaultSelectedPlacement, setDefaultSelectedPlacement] = useState(
      placement ? placement.slice(0, 10) : null
    );

  const [selectedPartner, setSelectedPartner] = useState(
      mediaPartner ? mediaPartner.slice(0, 10) : null
    ),
    [defaultSelectedPartner, setDefaultSelectedPartner] = useState(
      mediaPartner ? mediaPartner.slice(0, 10) : null
    );

  const [isSelectorsChanged, setSelectorsChanged] = useState({
    numberOfDays: false,
    typeOfImpression: false,
    typeOfData: false,
    selectedPartner: false,
    selectedPlacement: false,
  });

  const [numberOfDays, setNumberOfDays] = useState({
      id: 9,
      name: 'Campaign Dates + Lookback Period',
    }),
    [defaultNumberOfDays, setDefaultNumberOfDays] = useState({
      id: 9,
      name: 'Campaign Dates + Lookback Period',
    });

  const [typeOfImpression, setTypeOfImpression] = useState({
      typeOfImpression: 'Gross Impression',
      typeImpressionId: 1,
    }),
    [defaultTypeOfImpression, setDefaultTypeOfImpression] = useState({
      typeOfImpression: 'Gross Impression',
      typeImpressionId: 1,
    });

  const [typeOfData, setTypeOfData] = useState({
      typeOfData: 'Placement',
      typeDataId: 1,
    }),
    [defaultTypeOfData, setDefaultTypeOfData] = useState({
      typeOfData: 'Placement',
      typeDataId: 1,
    });

  const [dataFetching, setDataFetching] = useState({
    lineChart: true,
    ivtImpression: false,
  });

  const [cancelController, setCancelController] = useState(null);

  const saveChanges = () => {
    setDefaultNumberOfDays(numberOfDays);
    setDefaultTypeOfImpression(typeOfImpression);
    setDefaultTypeOfData(typeOfData);
    setDefaultSelectedPlacement(selectedPlacement);
    setDefaultSelectedPartner(selectedPartner);
    setSelectorsChanged({
      numberOfDays: false,
      typeOfImpression: false,
      typeOfData: false,
      selectedPlacement: false,
      selectedPartner: false,
    });
  };

  const resetChanges = () => {
    setNumberOfDays(defaultNumberOfDays);
    setTypeOfImpression(defaultTypeOfImpression);
    setTypeOfData(defaultTypeOfData);
    setSelectedPlacement(defaultSelectedPlacement);
    setSelectedPartner(defaultSelectedPartner);
    setSelectorsChanged({
      numberOfDays: false,
      typeOfImpression: false,
      typeOfData: false,
      selectedPlacement: false,
      selectedPartner: false,
    });
  };

  const checkDisable = (selectedData, defaultData) => {
    return JSON.stringify(selectedData) === JSON.stringify(defaultData);
  };

  useEffect(() => {
    if (placement) {
      setSelectedPlacement(placement?.slice(0, 10));
      setDefaultSelectedPlacement(placement?.slice(0, 10));
    } else if (!reportComponentIsLoading.campaign && !campaign) {
      setSelectedPlacement(null);
    } else {
      setSelectedPlacement(null);
      setDefaultSelectedPartner(null);
    }
  }, [placement, reportComponentIsLoading.campaign]);

  useEffect(() => {
    if (mediaPartner) {
      setSelectedPartner(mediaPartner?.slice(0, 10));
      setDefaultSelectedPartner(mediaPartner?.slice(0, 10));
    } else if (!reportComponentIsLoading.campaign && !campaign) {
      setSelectedPartner(null);
    } else {
      setSelectedPartner(null);
      setDefaultSelectedPartner(null);
    }
  }, [mediaPartner, reportComponentIsLoading.campaign]);

  useEffect(() => {
    setSelectorsChanged({
      ...isSelectorsChanged,
      numberOfDays: !checkDisable(numberOfDays, defaultNumberOfDays),
    });
  }, [numberOfDays]);

  useEffect(() => {
    setSelectorsChanged({
      ...isSelectorsChanged,
      typeOfImpression: !checkDisable(
        typeOfImpression,
        defaultTypeOfImpression
      ),
    });
  }, [typeOfImpression]);

  useEffect(() => {
    setSelectorsChanged({
      ...isSelectorsChanged,
      typeOfData: !checkDisable(typeOfData, defaultTypeOfData),
    });
  }, [typeOfData]);

  useEffect(() => {
    setSelectorsChanged({
      ...isSelectorsChanged,
      selectedPlacement: !checkDisable(
        selectedPlacement,
        defaultSelectedPlacement
      ),
    });
  }, [selectedPlacement]);

  useEffect(() => {
    setSelectorsChanged({
      ...isSelectorsChanged,
      selectedPartner: !checkDisable(selectedPartner, defaultSelectedPartner),
    });
  }, [selectedPartner]);

  useEffect(() => {
    if (isSummaryError) {
      const controller = new AbortController();
      setCancelController(controller);
      return () => {
        controller.abort();
      };
    }
  }, [isSummaryError]);

  useEffect(() => {
    setDefaultSelectedPartner(mediaPartner?.slice(0, 10));
    setDefaultSelectedPlacement(placement?.slice(0, 10));
    setNumberOfDays(defaultNumberOfDays);
    setTypeOfImpression(defaultTypeOfImpression);
    setTypeOfData(defaultTypeOfData);
    setSelectorsChanged({
      numberOfDays: false,
      typeOfImpression: false,
      typeOfData: false,
      selectedPlacement: false,
      selectedPartner: false,
    });
  }, [campaign, placement, creativeType, mediaPartner, startDate, endDate]);

  const renderComponent = () => {
    return (
        <div className='analysisSectionInnerWrapper'>
          <Container fluid className='analysisSectionInnerSubHeaderWrapper'>
            <Row>
              <Col xl={10} lg={12} md={12} sm={12}>
                <div className='analysisSectionInnerSubHeaderWrapper'>
                  {/* <SearchableDropdownComponent
                    options={timeFrameDropdown}
                    label='name'
                    id='id'
                    dropdownId='viewNumberOfDays'
                    dropdownLabel='View'
                    labelWidth={{ width: '30px' }}
                    dropdownWidth={{
                      width: '155px',
                    }}
                    placeholderLabel={'Number of Days'}
                    selectedValue={numberOfDays}
                    handleChange={setNumberOfDays}
                    isSearchable={false}
                    uniqueIdentifier='reportDetailsMediaDeliveryAnalysisDateRange'
                    isDisabled={
                      Object.values(dataFetching).includes(true) ||
                      Object.values(isLoading).includes(true)
                    }
                    dropdownLabelClass='dropdownMarginRight'
                  /> */}
                  <SearchableDropdownComponent
                    options={[
                      {
                        typeOfImpression: 'Gross Impression',
                        typeImpressionId: 1,
                      },
                      {
                        typeOfImpression: 'Net Impression',
                        typeImpressionId: 2,
                      },
                      { typeOfImpression: 'IVT', typeImpressionId: 3 },
                    ]}
                    label='typeOfImpression'
                    id='typeImpressionId'
                    dropdownId='typeImpressionId'
                    dropdownLabel='View'
                    labelWidth={{ width: '40px' }}
                    dropdownWidth={{
                      width: '175px',
                    }}
                    placeholderLabel={'Type of Impression'}
                    selectedValue={typeOfImpression}
                    handleChange={(val) => {
                      setTypeOfImpression(val);
                    }}
                    isSearchable={false}
                    uniqueIdentifier='reportDetailsMediaDeliveryAnalysisImpressionTypes'
                    isDisabled={
                      Object.values(dataFetching).includes(true) ||
                      Object.values(isLoading).includes(true) || noData
                    }
                  />
                  <SearchableDropdownComponent
                    options={[
                      { typeOfData: 'Placement', typeDataId: 1 },
                      { typeOfData: 'Partner', typeDataId: 2 },
                    ]}
                    label='typeOfData'
                    id='typeDataId'
                    dropdownId='viewTypeOfData'
                    dropdownLabel='By'
                    labelWidth={{ width: '20px' }}
                    dropdownWidth={{
                      width: '150px',
                    }}
                    placeholderLabel={'Type of Data'}
                    selectedValue={typeOfData}
                    handleChange={(val) => {
                      setTypeOfData(val);
                    }}
                    // dataLoading={dataLoading.campaign}
                    // isDisabled={isDisabled.campaign}
                    isSearchable={false}
                    uniqueIdentifier='reportDetailsMediaDeliveryAnalysisTypeOfData'
                    isDisabled={
                      Object.values(dataFetching).includes(true) ||
                      Object.values(isLoading).includes(true) || noData
                    }
                  />
                  <div className='reportsDetailMediaDeliveryMutliSelectWrapper'>
                    {typeOfData.typeDataId === 1 ? (
                      <NewMultiSelect
                        options={placement}
                        dataId='placement_id'
                        dataName='placement_name'
                        selected={selectedPlacement}
                        setSelected={setSelectedPlacement}
                        isMaxSelect={true}
                        placeholderTxt='Placement'
                        label=':'
                        dropDownId='reportsMTASLCMultiSelectPlacement'
                        componentWrapperStyle={{
                          width: '260px',
                        }}
                        isLoading={isLoading?.placement}
                        isDisable={
                          !placement ||
                          Object.values(dataFetching).includes(true) ||
                          Object.values(isLoading).includes(true) || noData
                        }
                      />
                    ) : (
                      <NewMultiSelect
                        options={mediaPartner}
                        dataId='partner_id'
                        dataName='partner_name'
                        selected={selectedPartner}
                        setSelected={setSelectedPartner}
                        isMaxSelect={true}
                        placeholderTxt='Partner'
                        label=':'
                        dropDownId='reportsMTASLCMultiSelectPartner'
                        componentWrapperStyle={{
                          width: '260px',
                        }}
                        isLoading={isLoading?.mediaPartner}
                        isDisable={
                          !mediaPartner ||
                          Object.values(dataFetching).includes(true) ||
                          Object.values(isLoading).includes(true) || noData
                        }
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={12} md={12} sm={12}>
                <div className='analysisSectionInnerSubHeaderWrapper'>
                  <CommonButtonComponent
                    label={'Apply'}
                    appendWrapperClassName='btnPrimaryWrapper'
                    appendButtonClassName={'btnPrimary hoverEffect'}
                    onClickCTA={saveChanges}
                    isDisabled={
                      !Object.values(isSelectorsChanged).includes(true) ||
                      (typeOfData?.typeDataId == 1 &&
                        !selectedPlacement?.length) ||
                      (typeOfData?.typeDataId === 2 && !selectedPartner?.length) || noData
                    }
                    buttonUI='editCampaignSave'
                  />
                  <CommonButtonComponent
                    label={'Reset'}
                    appendWrapperClassName='btnPrimaryWrapper'
                    appendButtonClassName={'btnPrimary hoverEffect'}
                    onClickCTA={resetChanges}
                    isDisabled={
                      !Object.values(isSelectorsChanged).includes(true) ||
                      (typeOfData?.typeDataId == 1 &&
                        !selectedPlacement?.length) ||
                      (typeOfData?.typeDataId === 2 && !selectedPartner?.length) || noData
                    }
                    buttonUI='editCampaignSave'
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <div className='analysisSectionInnerContentWrapper'>
            <Container fluid>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <AnalysisLineChart
                    campaign={campaign}
                    startDate={startDate}
                    endDate={endDate}
                    defaultTypeOfData={defaultTypeOfData}
                    defaultTypeOfImpression={defaultTypeOfImpression}
                    defaultNumberOfDays={defaultNumberOfDays}
                    defaultSelectedPlacement={defaultSelectedPlacement}
                    defaultSelectedPartner={defaultSelectedPartner}
                    isLoading={isLoading}
                    ifSelectedAll={ifSelectedAll}
                    dataFetching={dataFetching}
                    setDataFetching={setDataFetching}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <IVTImpressionsReport
                    campaign={campaign}
                    startDate={startDate}
                    endDate={endDate}
                    reportComponentIsLoading={reportComponentIsLoading}
                    haveSetDates={haveSetDates}
                    placement={placement}
                    creativeType={creativeType}
                    mediaPartner={mediaPartner}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
    );
  };

  return (
    <div className='analysisSectionWrapper'>
      <div className='analysisSectionHeader'>
        <CommonHeadingComponent headingLabel={'Analysis Section'} />
      </div>
      {isSummaryError ? (
        <ErrorComponent
          refreshAction={
            getCampaignList ||
            getPlacementList ||
            getPartnerList ||
            getCreativeTypeList
          }
          controller={cancelController}
        />
      ) : (
        <div className='analysisSectionInnerWrapper'>{renderComponent()}</div>
      )}
    </div>
  );
}

export default AnalysisComponent;
