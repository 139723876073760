import React, { Component } from 'react';
import CellWrapper from './CellWrapper';
import { createRow } from '../util/row-creator';
export default class VirtualListRow extends Component {
    
  handleChange = (value) => {
    const { onChange } = this.props;
    onChange(value);
  }
  render() {
    const { model, columns, data, index, relIndex } = this.props;
    const row = createRow(model, data, this.handleChange);
    
    return (
      <tr className={`cp_tree-table_row`}
        data-index={index}
        data-relindex={relIndex} key={relIndex}>
        
        {columns.map((column, indexKey) => {
          return (
            <CellWrapper key={indexKey}
              row={row}
              renderCell={column.renderCell} 

              grow={column.grow}
              basis={column.basis}/>
          );
        })}

      </tr>
    );
  }

  
}


const STYLE_ROW = {
  display: 'flex',

  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
};
