import React from 'react';
import { customMessages } from '../../assets/messages';
import NoTableDataSVGComponent from '../commonSVGs/TableNoDataSVG.js';

const NoDataComponent = ({
  tableName,
  type = 'message',
  customLayerStyle = {},
  customImageStyle = {},
  messageText = '',
  messageTextFontSize,
  width = '100',
  height = '100',
  foregnObj = { x: '35', y: '90', width: '150', height: '50' },
}) => {
  const message = customMessages.emptyTables[tableName] ?? 'No data available!';
  let text = false,
    imgClassName = '',
    isTable = false,
    isDonut = false;
  switch (type) {
    case 'message':
      text = true;
      break;
    case 'table':
      isTable = true;
      break;
    case 'donut':
      imgClassName = 'donutChartImage';
      isDonut = true;
      break;
    case 'line':
      imgClassName = 'lineChartImage';
      break;
    case 'bar':
      imgClassName = 'barChartImage';
      break;
    case 'stack':
      imgClassName = 'stackChartImage';
      break;
    case 'area':
      imgClassName = 'areaChartImage';
      break;
    case 'scatter':
      imgClassName = 'scatterChartImage';
      break;
    default:
      text = true;
      break;
  }

  const renderNoTableComponent = () => {
    return (
      <div className='noTableDataSVGWrapper' style={{ display: 'flex' }}>
        <NoTableDataSVGComponent
          message={messageText}
          fontSize={messageTextFontSize}
          width={width}
          height={height}
          foregnObj={foregnObj}
        />
      </div>
    );
  };

  return text ? (
    <div className='noTableDataCompWrapper'>{message}</div>
  ) : (
    <div
      className={`noTableDataCompWrapper ${isDonut ? imgClassName : 'layer'}`}
    >
      {isTable && renderNoTableComponent()}
      {isDonut || isTable ? null : (
        <>
          <div
            style={customLayerStyle ?? {}}
            className={`lowerLayer ${imgClassName}`}
          />
          <div style={customImageStyle ?? {}} className='noDataImage' />
        </>
      )}
    </div>
  );
};

export default NoDataComponent;
