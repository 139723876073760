import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { CommonModal } from "../modals";
import "../modals/modals.css";
import CommonButtonComponent from '../commonButton/CommonButton.component';
import "./importFileModal.css"
import axios from 'axios';
import { commonNotification } from '../../utils/common-notifications';
import { importErrorNotification, clearNotification } from '../../utils/importErrorNotification';
import { axiosGetFileRequest, axiosSendFile } from '../../services/http/axiosCalls';
import * as FileSaver from "file-saver";
import endpoints from '../../constants/endpoints';

function ImportFileModal({
  setShowImportFileModal,
  showImportFileModal,
  show,
  campaign,
  advertiserId,
  clientId,
  header}) {
  const [file, setFile] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isMounted, setIsMounted] = useState(false)
  const [errorId, setErrorId] = useState(null)
  const path = window.location.pathname;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const getExtension = (fileName) => {
    return fileName.split(".").pop();
  };

  const showResults = (res) => {
    if (res.status === 200) {
      clearNotification();
      commonNotification({
        msg: res.message,
        type: "success",
      });
      setTimeout(() => {
        navigate(0);
      }, 2000);
    } else {
      let errors = [];
      if (typeof res.message === "string") {
        clearNotification();
        setErrorId(importErrorNotification({
          msg: res.message,
          type: "danger",
        }));
      } else {
        for (let entry of res.message) {
          if (errors.length == 0) {
            errors.push(entry);
          } else {
            errors.push(<br />);
            errors.push(<br />);
            errors.push(entry);
          }
        }
        clearNotification();
        setErrorId(importErrorNotification({
          msg: errors,
          type: "danger",
        }));
      }
    }
  }

useEffect(() => {
  let errorElement = document.querySelector('.rnc__notification-message')

  const eventHandler = (e) => {
    clearNotification();
    document.removeEventListener('click', eventHandler);
  }
  
  if (errorElement) {
    document.addEventListener('click', eventHandler);
  }

}, [errorId]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setIsSuccess(false);

    const allowedTypes = ["csv", "xls", "xlsx"];

    if (!allowedTypes.includes(getExtension(selectedFile.name.toLowerCase()))) {
      setIsError(true);
      setErrorMsg("Only .csv, .xls, or .xlsx files may be uploaded");
      return;
    }
    setIsError(false);
    setFile(selectedFile);
  };
  
  const handleSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const formData = new FormData();
    if(file && path == "/campaigns"){
        formData.append("file", file)
        formData.append("clientId", clientId);
        formData.append("uid", user.uid);
        formData.append("advertiserId", advertiserId)
        formData.append("pathname", window.location.pathname)
        setErrorId(
          importErrorNotification({ msg: "The uploaded document is being processed...", type: "info", })
        );
        axiosSendFile(endpoints.importCampaigns, formData)
          .then((res) => {
            showResults(res);
          })
          .catch((res, err) => {
            showResults(res.response.data)
          });

        setShowImportFileModal(false);
        setFile(null)  

    } else if (file) {
         formData.append("file", file);
         formData.append("campaignId", campaign);
         formData.append("clientId", clientId);
         formData.append("uid", user.uid);
         formData.append("pathname", window.location.pathname)
         setErrorId(
          importErrorNotification({ msg: "The uploaded document is being processed...", type: "info", })
        );
         axiosSendFile(endpoints.importPlacements, formData)         
           .then((res) => {
             showResults(res)
           })
           .catch((res, err) => {
            showResults(res.response.data)
          });

            setShowImportFileModal(false);  
            setFile(null)
      } else if(!file) {
          commonNotification({ msg: 'Please select a file before uploading', type: 'danger'})
      }

  } 

  const handleCancel = (event) => {
    setShowImportFileModal(false)
    setFile(null)
  }

  const handleDownload = async (event) =>{ 
    if(path == "/campaigns"){
        axiosGetFileRequest(endpoints.downloadCampaignTemplate, {responseType:'blob'})
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
          });

          FileSaver.saveAs(blob, "Campaign Import Template.xlsx")
        })
    } else {
        axiosGetFileRequest(endpoints.downloadPlacementTemplate, {responseType:'blob'})
        .then(
          (response) => {  
            const blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });

            FileSaver.saveAs(blob, "Placement Import Template.xlsx")
          }
        );
      }

    
  }

  return (
    showImportFileModal && (
      <div className='importFileModalWrapper'>
        <CommonModal
          show={show}
          header={header}
          body={
            <div className="formWrapper">
              <br />
              <div className="downloadButtonWrapper">
                  <CommonButtonComponent
                    label={"Download A Template"}
                    className="download"
                    buttonUI="downloadButton"
                    onClickCTA={handleDownload}
                  />
                </div>
              <form>
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv, .xls, .xlsx"
                  className="fileSelect"
                />
                {isError && <div className="error-text">{errorMsg}</div>}
              </form>
              <div className="uploadAndCancelButtons">
                <CommonButtonComponent
                  label={"Upload"}
                  onClickCTA={handleSubmit}
                  className="upload"
                  buttonUI="uploadButton"
                />

                <CommonButtonComponent
                  label={"Cancel"}
                  onClickCTA={handleCancel}
                  className="cancel"
                  buttonUI="cancelButton"
                />
              </div>
            </div>
          }
          hideCloseBtn={true}
          size="otp"
          buttonUI="ImportFile"
        />
      </div>
    )
  );
}

export default ImportFileModal;