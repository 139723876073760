import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import moment from 'moment';
import { usePostAPIInterceptor } from '../../hooks/postAPIInterceptor';
import { useGetApiInterceptor } from '../../hooks/useGetApiInterceptor';
import {
  resetAdvertiserMode,
  updateAdvertiserMode,
  updateClientMode,
  resetClientMode,
} from '../../redux/utilitybarSlice';
import {
  CommonButtonComponent,
  SearchableDropdownComponent,
  CommonInput,
  LoaderComponent,
  DatePickerComponent,
  CommonSearchFilter,
  CommonHeadingComponent,
} from '../../components';
import { CommonModal, DuplicateRSSChannelModal } from '../../components/modals';
import { commonNotification } from '../../utils/common-notifications';
import { breadCrumbHeader } from '../../constants';
import endpoints, { baseEndpoints } from '../../constants/endpoints'
import { getEncryptedOrDecryptedData, isValidateURL, isOnlyNumbers } from '../../utils/functions';
import DupNameBodyJSX from './components/DupNameBodyJSX';
import RSSURLBodyJSX from './components/RSSURLBodyJSX';
import { setBlockingLoaderState } from '../../redux/blockingLoader';
import './placement.css';



// current date as today for various date usage
const today = new Date()
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

// createProperties used to create the property in the application with required fields
const CreatePlacement = () => {
  const USE = 'Use', UPDATE = 'Update';
  const dispatch = useDispatch();
  const navigate = useNavigate() // navigate between pages
  const { pathname, state } = useLocation();
  const pathnameArr = pathname.split('/');
  const campaignId = Number(pathnameArr[2]);
  const campaignData = state?.campaignName; // Read values passed on state
  const { isClonePlacement, placementData } = state ?? {};
  const client = useSelector((state) => state.client);
  const user = useSelector((state) => state.user);
  // Getting all the field data for the form with default values and updating the state for create placement

  const [formData, setFormData] = useState({
    name: '',
    cpm: '0.00',
    mediaPartner: {},
    creativeType: null,
    podcastProvider: null,
    referenceId: '',
    clickURL: '',
    appStoreCountry: '',
    searchPodcast: '',
    showName: '',
    RSSFeedURL: '',
    manualRssURL: '',
    channelId: '',
    isDataFile: false,
    itemId: '',
    orderId: '',
  })
  const [isLoading, setIsLoading] = useState(false) 
  const [showManualOption, setShowManualOption] = useState(false)
  const [showNonEditable, setShowNonEditable] = useState(false)
  const [startDate, setStartDate] = useState(null) //setting the state with start date
  const [endDate, setEndDate] = useState(null) //setting the state with the end date
  const [pubStartDate, setPubStartDate] = useState(null) //setting the state with the publish start date
  const [pubEndDate, setPubEndDate] = useState(null) //setting the state with the publish end date
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rssChannelExists, setRssChannelExists] = useState(null)
  const [rssData, setRssData] = useState(null) //setting the state with the RSS data
  const [isDupModalOpen, setIsDupModalOpen] = useState(false)
  const [dupNameModalData, setDupNameModalData] = useState(null)
  const [saveMount, setSaveMount] = useState(false)
  const [savePayload, setSavePayload] = useState(null)
  const [redirectedAPIMount, setRedirectedAPIMount] = useState(false)
  const [redirectedAPIPayload, setRedirectedAPIPayload] = useState(null);
  const [isBaseLoading, setIsBaseLoading] = useState(true);
  const [rssValidationLoading, setRSSValidationLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [campaignName, setCampaignName] = useState(null);
  const today = moment.utc().format('YYYY-MM-DD');
  const tomorrow = moment.utc().add(1, 'days').format('YYYY-MM-DD');

  const [validName, setValidName] = useState({
    valid: true,
    errorMsg: ''
  }),
  [validStartDate, setValidStartDate] =  useState({
    valid: true,
    errorMsg: ''
  }),
  [validEndDate, setValidEndDate] =  useState({
    valid: true,
    errorMsg: ''
  }),
  [validClickThroughURL, setValidClickThroughURL] = useState({
    valid: true,
    errorMsg: ''
  }),
  [validRefID, setValidRefID] = useState({
    valid: true,
    errorMsg: ''
  }),
  [validPublishStartDate, setValidPublishStartDate] = useState({
    valid: true,
    errorMsg: ''
  }),
  [validPublishEndDate, setValidPublishEndDate] = useState({
    valid: true,
    errorMsg: ''
  }),
  [validOrderId, setValidOrderId] = useState({
    valid: true,
    errorMsg: ''
  }),
  [validItemId, setValidItemId] = useState({
    valid: true,
    errorMsg: ''
  });

  const mediaPartners = useGetApiInterceptor(
    true,
    null,
    endpoints.mediaPartners
  );
  let creativeTypes = useGetApiInterceptor(
    true,
    null,
    endpoints.creativeTypes
  );
  const podcasts = useGetApiInterceptor(true, null, endpoints.podcasts);
  const podcastProviders = useGetApiInterceptor(
    true,
    null,
    endpoints.podcastProviders
  );

  const placementList = useGetApiInterceptor(true, null, `${baseEndpoints.campaigns}/${campaignId}/placements`);
  let placementArray = placementList?.data?.table_data || [];
  let usedItemIds = placementArray
    .filter((placement) => placement.item_id && placement.item_id.trim() !== '')
    .map((placement) => placement.item_id);
  let usedOrderIds = placementArray
    .filter((placement) => placement.order_id && placement.order_id.trim() !== '')
    .map((placement) => placement.order_id);

  //updating the formdata with the various field values
  const {
    name,
    cpm,
    searchPodcast,
    mediaPartner,
    creativeType,
    showName,
    podcastProvider,
    referenceId,
    clickURL,
    RSSFeedURL,
    manualRssURL,
    channelId,
    isDataFile,
    itemId,
    orderId
    } = formData;

  useEffect(() => {
    dispatch(updateAdvertiserMode(true)); //disabling advertiser dropdown selector
    dispatch(updateClientMode(true)); //disabling the client dropdown selector

    return () => {
      dispatch(resetAdvertiserMode());
      dispatch(resetClientMode());
    };
  }, []);

  useEffect(() => {
    if (campaignData) {
      setCampaignName(campaignData);
      setIsBaseLoading(false);
    } else {
      setIsMounted(true);
    }
  }, [campaignData]);

  useEffect(() => {
    if (isClonePlacement && placementData) {
      setFormData({
        name: getSuggestedCloneName(placementData?.placementName),
        cpm: placementData?.cost || 0,
        mediaPartner: { name: placementData?.partnerName, 'partner_id': placementData?.partnerId, data_provider: placementData?.dataProvider },
        creativeType: { name: placementData?.typeLabel, value: placementData?.type },
        podcastProvider: { name: placementData?.podcastProvider, value: placementData?.podcastProvider },
        referenceId: placementData?.tpRefId || 0,
        clickURL: placementData?.clickThroughUrl || '',
        showName: placementData?.showName || '',
        RSSFeedURL: placementData?.rssLink || '',
        searchPodcast: placementData?.showName || '',
        manualRssURL: placementData?.rssLink || '',
        channelId: placementData.channelId || '',
        isDataFile: false,
        itemId: '',
        orderId: '',
      });
      setStartDate(moment(placementData?.startDate, 'YYYY-MM-DD').isBefore(moment(today, 'YYYY-MM-DD')) ? tomorrow : moment.utc(placementData.startDate).format('YYYY-MM-DD'));
      setEndDate(moment(placementData?.endDate, 'YYYY-MM-DD').isBefore(moment(today, 'YYYY-MM-DD')) ? null : moment.utc(placementData.endDate).format('YYYY-MM-DD'));
      setShowManualOption(true);
      if (placementData?.publishStartDate) {
        setPubStartDate(
          moment(placementData.publishStartDate, 'YYYY-MM-DD').isBefore(moment(today, 'YYYY-MM-DD')) 
            ? tomorrow 
            : moment(placementData.publishStartDate).format('YYYY-MM-DD')
        );
      } else {
        setPubStartDate(null);
      }

      if (placementData?.publishEndDate) {
        setPubEndDate(
          moment(placementData.publishEndDate, 'YYYY-MM-DD').isBefore(moment(today, 'YYYY-MM-DD')) 
            ? null 
            : moment(placementData.publishEndDate).format('YYYY-MM-DD')
        );
      } else {
        setPubEndDate(null);
      }
      setRssData({
        showName: placementData?.showName,
        channelId: placementData?.channelId
      });
    }
  }, [placementData]);

  const campaignDetails = useGetApiInterceptor(
    isMounted,
    null,
    `${baseEndpoints.campaigns}/${campaignId}`
  );

  useEffect(() => {
    if (!campaignDetails.isLoading && campaignDetails?.data?.details?.length) {
      setCampaignName(campaignDetails?.data.details[0].name);
    }

    if (!campaignDetails.isLoading) {
      setIsBaseLoading(false);
    }
  }, [campaignDetails]);
  

  //setting the default values for creative type and the media partner
  useEffect(() => {
    if (
      creativeTypes.data &&
      creativeTypes.data.table_data &&
      creativeTypes.data.table_data.length > 0 &&
      !creativeTypes
    ) {
      setFormData({
        ...formData,
        creativeTypes: creativeTypes.data.table_data[0],
      });
      setIsLoading(false);
    }
  }, [creativeTypes]);

  useEffect(() => {
    if (
      podcastProviders.data &&
      podcastProviders.data.table_data &&
      podcastProviders.data.table_data.length > 0 &&
      !podcastProvider
    ) {
      setFormData({
        ...formData,
        podcastProvider: podcastProviders.data.table_data[0],
      });
    }
  }, [podcastProviders]);

  //updating the form with the RSS data that we get from API - update show name , is redirect true then redirected RSS URL & Manual RSS entry if not found in list
  useEffect(() => {
    if (rssData) {
      setFormData({
        ...formData,
        showName: rssData.showName,
        RSSFeedURL: rssData.isRedirectDetected
          ? rssData.lastRedirectUrl
          : RSSFeedURL,
        manualRssURL:
          rssData.isRedirectDetected && showManualOption
            ? rssData.lastRedirectUrl
            : manualRssURL,
        channelId: rssData.channelId
      });
    } else if (showManualOption) {
      setFormData({
        ...formData,
        showName: '',
        RSSFeedURL: '',
        channelId: ''
      });
    }
  }, [rssData]);

  useEffect(()=>{
    if(startDate && moment().format('YYYY-MM-DD')>moment(startDate).format('YYYY-MM-DD')){
     setValidStartDate({valid: false, errorMsg: 'Start Date can not be in the past'})
    } else if (endDate && startDate > endDate){
      setValidStartDate({valid: false, errorMsg: 'Start Date cannot be after End Date'})
      
    } else {
     setValidStartDate({valid: true, errorMsg: ''})
    }
   },[startDate, endDate])
 
   useEffect(()=>{
     if(endDate && moment().format('YYYY-MM-DD')>moment(endDate).format('YYYY-MM-DD')){
      setValidEndDate({valid: false, errorMsg: 'End Date can not be in the past'})
     } else {
      setValidEndDate({valid: true, errorMsg: ''})
     }
    },[endDate])

    useEffect(()=>{
      if(pubStartDate && moment().format('YYYY-MM-DD')>moment(pubStartDate).format('YYYY-MM-DD')){
       setValidPublishStartDate({valid: false, errorMsg: 'Publish Start Date can not be in the past'})
      } else if(pubEndDate && pubStartDate > pubEndDate) {
        setValidPublishStartDate({valid: false, errorMsg: 'Publish Start Date cannot be after Publish End Date'})
      } else {
        setValidPublishStartDate({valid: true, errorMsg: ''})
      }
     },[pubStartDate, pubEndDate])
   
     useEffect(()=>{
       if(pubEndDate && moment().format('YYYY-MM-DD')>moment(pubEndDate).format('YYYY-MM-DD')){
        setValidPublishEndDate({valid: false, errorMsg: 'Publish End Date can not be in the past'})
       } else {
        setValidPublishEndDate({valid: true, errorMsg: ''})
       }
      },[pubEndDate])

    // Checking if the media partner supports data file and setting the data provider to null if not supported
    useEffect(() => {
      if (isDataFile && !mediaPartner?.data_provider) {
        changeHandler({}, 'mediaPartner');
      }
    }, [isDataFile]);

    // Verifying the creative type exists in the list of creative types. Setting null if not found
    useEffect(() => {
      if (creativeTypes?.data?.table_data?.length > 0 && creativeType !== null && (!creativeTypes?.data?.table_data?.find(type => type.value === creativeType?.value) || isDataFile && creativeType?.value === 'Podcast-RSS')) {
        setFormData({...formData, creativeType: null})
      }
    }, [creativeType, creativeTypes]);

    useEffect(() => {
      if(!orderId){
        setValidOrderId({valid: true, errorMsg: ''})
      } else if (!isOnlyNumbers(orderId)){
        setValidOrderId({ valid: false, errorMsg: 'Order IDs may only contain numbers.', });
      } else if((orderId && !itemId) && !usedOrderIds.includes(orderId) && orderId.length <= 40){
        setValidOrderId({valid: true, errorMsg: ''})
        setValidItemId({valid: true, errorMsg: ''})
      } else if (itemId && orderId){
        setValidOrderId({valid: false, errorMsg: 'Please enter either an Order ID or Item ID, but not both.'})
      } else if (orderId.length > 40) {
        setValidOrderId({ valid: false, errorMsg: 'Order IDs may not be longer than 40 characters.',});
      } else if (usedOrderIds.includes(orderId)){
        setValidOrderId({ valid: false, errorMsg: 'That Order ID is already in use by this campaign.', });
      } 

      if(!itemId){
        setValidItemId({valid: true, errorMsg: ''})
      } else if (!isOnlyNumbers(itemId)){
        setValidItemId({ valid: false, errorMsg: 'Item IDs may only contain numbers.', });
      } else if((itemId && !orderId) && !usedItemIds.includes(itemId)  && itemId.length <= 40){
        setValidItemId({valid: true, errorMsg: ''})
        setValidOrderId({valid: true, errorMsg: ''})
      } else if (itemId && orderId){
        setValidItemId({valid: false, errorMsg: 'Please enter either an Order ID or Item ID, but not both.'})
      } else if (itemId.length > 40) {
        setValidItemId({ valid: false, errorMsg: 'Item IDs may not be longer than 40 characters.',});
      } else if (usedItemIds.includes(itemId)){
        setValidItemId({ valid: false, errorMsg: 'That Item ID is already in use by this campaign.', });
      } 
    }, [orderId, itemId]);
    
  //To save property  & edit property and  set the payload

  const handleSave = (sname = '') => {
    dispatch(setBlockingLoaderState({ active: true }));

    if (isDataFile && !mediaPartner.data_provider) {
      commonNotification({
        msg: 'Please Select a Media Partner That Supports a Creative Type Of Data File',
        type: 'danger',
      });
      dispatch(setBlockingLoaderState({ active: false }));
      return;
    }

    if (!saveMount) {
      let payload = {
        clientId: client.selectedClient,
        uid: user.uid,
        campaignId: Number(campaignId),
        name: sname || name.trim(),
        cpi: 0,
        partner: mediaPartner.partner_id,
        startDate: startDate,
        endDate: endDate,
        tpRefId: referenceId,
        adserving: 0,
        type: creativeType.value,
        cpm: cpm,
        isDataFile: isDataFile,
        itemId: isDataFile ? itemId : '',
        orderId: isDataFile ? orderId : '',
      };
      
      if (creativeType.value !== 'Podcast-RSS') {
        payload.clickThroughUrl = !isDataFile ? clickURL : '';
        if (creativeType.value === 'Podcast') {
          payload.podcastProvider = podcastProvider.value;
        }
      } else {
        payload.startPublishDate = pubStartDate;
        payload.endPublishDate = pubEndDate;
        payload.rssFeedUrl = showManualOption ? manualRssURL : RSSFeedURL;
        payload.showName = showName;
        payload.channelId = channelId;
      }
      setSavePayload(payload);
      setSaveMount(true);
    }
  };
  usePostAPIInterceptor(
    saveMount,
    savePayload,
    endpoints.createPlacement,
    (status, data, error) => {
      if (data) {
        if (status === 200) {
          const msg = `Placement ${name.trim()} has been successfully created. Redirecting to view all placement...`;
          commonNotification({ msg, type: 'success' });
          setTimeout(() => {
            dispatch(setBlockingLoaderState({ active: false }));
            navigate(
              `/campaign/${campaignId}?params=${getEncryptedOrDecryptedData(
                { name: campaignName },
                'encrypt'
              )}`
            );
            setSaveMount(false);
            dispatch(setBlockingLoaderState({ active: false }));
          }, 2000);
        } else {
          if (data.errorType === 'duplicate_placment_name') {
            setDupNameModalData(data);
            setIsDupModalOpen(true);
            setSaveMount(false);
            dispatch(setBlockingLoaderState({ active: false }));
          } else {
            commonNotification({
              msg: data.message || data.error || 'Something went wrong',
              type: 'danger',
            });
            setSaveMount(false);
            dispatch(setBlockingLoaderState({ active: false }));
          }
          dispatch(setBlockingLoaderState({ active: false }));
        }
      } else {
        commonNotification({
          msg: error.error || 'Something went wrong',
          type: 'danger',
        });
        setSaveMount(false);
        dispatch(setBlockingLoaderState({ active: false }));
      }
    }
  );

  // Method to generate a new name for cloned placement satisfying all the criteria
  const getSuggestedCloneName = (placementName) =>{
    let placementMaxLength=249;
    let cloneLength = 5;
    let nameLength = placementName.length;
    let suggestedPlacementName;

    if (nameLength >= placementMaxLength) {
      suggestedPlacementName = placementName.substring(0, 249) + '_clone';
    } else {
      let lastIndexOfCloneWord = placementName.lastIndexOf('clone');

      if (lastIndexOfCloneWord === -1) {
        suggestedPlacementName = placementName + '_clone';
      } else {
        let lengthOfNameShouldBe = (lastIndexOfCloneWord + cloneLength)
        let numberOfExtraChar = (nameLength - lengthOfNameShouldBe)
        let extraChar = placementName.substr(lengthOfNameShouldBe, numberOfExtraChar)
        
        if ((lengthOfNameShouldBe === nameLength)) {
          suggestedPlacementName = placementName + 1;          
        } else if (isNaN(extraChar)) {
          suggestedPlacementName = placementName + '_clone';          
        }
        else {
          let nextNumber = Number(extraChar) + 1;
          suggestedPlacementName = placementName.substring(0, lengthOfNameShouldBe) + nextNumber;          
        }
      }
    }
    return suggestedPlacementName;
  }

  // Change handler for handling each form value change and updating each form data
  const changeHandler = (val, name) => {
    setFormData({ ...formData, [name]: val });
  };

  // Handler for cancel - navigates to the campaigns page
  const handleCancel = () => {
    navigate(`/campaign/${campaignId}?params=${getEncryptedOrDecryptedData({ name: campaignName }, 'encrypt' )}`)
  }

  //RSS redirect APi call for checking whether its redirecting URL or not
  const checkRedirectedURL = (rssURL, option, selectedChannel) => {
    setRSSValidationLoading(true);
    const payLoad = {
      rssFeedUrl: rssURL,
      option,
      selectedChannel,
    };
    setRedirectedAPIPayload(payLoad);
    setRedirectedAPIMount(true);
  };

  usePostAPIInterceptor(
    redirectedAPIMount,
    redirectedAPIPayload,
    endpoints.redirectedCheck,
    (status, data, error) => {
      if (data) {
        if (data.isRedirectDetected) {
          setIsModalOpen(true);
        } else if (data.status === 202) {
          setRssChannelExists(data?.channelData);
        } else if (data.error) {
          commonNotification({
            msg: data.error || 'Something went wrong',
            type: 'danger',
          });
        }
        if (data.status !== 202 && !data.error) {
          setRssData(data);
          setRssChannelExists(null);
        }
        if (data.updatedRSSURL) {
          changeHandler(data.updatedRSSURL, 'manualRssURL');
        }
      } else {
        commonNotification({
          msg: error.error || 'Something went wrong',
          type: 'danger',
        });
      }
      setRSSValidationLoading(false);
    }
  );

  const checkValid = (val, key) =>{
    switch (key) {
      case 'name':
        if (!val?.length) {
          setValidName({
            valid: false,
            errorMsg: 'Name is required'
          });
          return;
        }
        if (val?.length > 255) {
          setValidName({
            valid: false,
            errorMsg: 'Maximum allowed characters for Name is 255'
          });
          return;
        }
        setValidName({
          valid: true,
          errorMsg: ''
        });
        break;
      case 'referenceID':
        if(val.length>45){
          setValidRefID({
            valid: false,
            errorMsg: 'Maximum characters allowed for Reference ID is 45.'
          })
        }else{
          setValidRefID({
            valid: true,
            errorMsg: ''
          })
        }
        break;
      case 'clickURL':
        if (!val.length) {
          setValidClickThroughURL({ valid: false, errorMsg: 'URL is required'})
          return;
        }
        if (val.length > 1000) {
          setValidClickThroughURL({ valid: false, errorMsg: 'Maximum allowed characters for URL is 1000'})
          return;
        }
        setValidClickThroughURL({
          valid: isValidateURL(val),
          errorMsg: isValidateURL(val) ? '' : 'Please enter a valid URL',
        });
        break;
      default:
        break;
    }
  }

  const disableBtn = () => {
    return (
      !validName.valid ||
      (!isDataFile && !validClickThroughURL.valid) ||
      !validRefID.valid ||
      !validStartDate.valid ||
      !validEndDate.valid
    );
  }

  //Handling the particular podcast select from the search for podcast list under Podcast RSS URL
  const handlePodCastSelect = (selectedPodcast) => {
    if (selectedPodcast.title) {
      setShowManualOption(false);
      setShowNonEditable(true);
      setFormData({
        ...formData,
        showName: selectedPodcast.title,
        RSSFeedURL: selectedPodcast.rss,
        searchPodcast: selectedPodcast.title,
        channelId: selectedPodcast.id
      });
      checkRedirectedURL(selectedPodcast.rss);
    } else {
      commonNotification({ msg: 'Show Name not found', type: 'danger' });
      setShowManualOption(true);
      setShowNonEditable(false);
    }
  };

  //Handling the manual RSS feed URL entry based on the enter option
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      checkRedirectedURL(formData.manualRssURL);
    }
  }
  //getting the field values of reference id and cpm and storing in on common function for  multiple usage
  const getCPMReferenceFields = () => {
    return (
      <>
        <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
          <CommonInput
            label={'Reference ID'}
            inputValue={referenceId}
            setInputValue={(val) => {
              changeHandler(val, 'referenceId');
              checkValid(val, 'referenceId');
            }}
            placeholder={'Enter Reference ID'}
            className={validRefID.valid ? 'userInput' : 'userInput inputError'}
          />
          {!validRefID.valid && (
            <span className='error'>{validRefID.errorMsg}</span>
          )}
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
          <CommonInput
            label={'CPM'}
            inputValue={`$${cpm}`}
            setInputValue={(val) =>
              changeHandler(val.replace(/\$/g, ''), 'cpm')
            }
            placeholder={'Enter CPM'}
          />
          {showCPMWarning()}
        </Col>
      </>
    );
  };

  const showCPMWarning = () => {
    if (Number(cpm) >= 999.99) {
      return (
        <div className='infoForUser textDanger'>
          Note: The maximum value for CPM is set to $999.99.
        </div>
      );
    } else if (Number(cpm) < 0) {
      return (
        <div className='infoForUser textDanger'>
          Note: CPM must be greater than or equal to $0.00
        </div>
      );
    } else return <div />;
  };

  // Handle duplicate RSS based on user's selected option
  const handleDuplicateRSSAction = (val, selectedRow, newChannelURL) => {
    if (val === USE) {
      setRssData({
        showName: selectedRow?.title,
        channelId: selectedRow?.id
      });
      changeHandler(selectedRow?.rss_link, 'manualRssURL');
      setRssChannelExists(null);
    } else if ( val === UPDATE) {
      checkRedirectedURL(newChannelURL, 'update', selectedRow);
    }else {
      checkRedirectedURL(newChannelURL, 'create', selectedRow);
    }
  };

  //checking the creative type is Podcast RSS and then updating the form fields accordingly
  const cType = (creativeType && creativeType.value) || '';
  const isRSSType = creativeType && creativeType.value === 'Podcast-RSS';
  const enableSave =
    name &&
    mediaPartner &&
    mediaPartner.partner_id &&
    startDate &&
    endDate &&
    startDate <= endDate &&
    cType &&
    Number(cpm) >= 0 &&
    ((cType !== 'Podcast-RSS' && cType !== 'Podcast' &&  !isDataFile && clickURL) ||
      (cType === 'Podcast' && podcastProvider && clickURL) ||
      (cType === 'Podcast-RSS' && !isDataFile &&
        (showManualOption ? manualRssURL : RSSFeedURL) &&
        showName &&
        pubStartDate &&
        pubEndDate &&
        pubStartDate <= pubEndDate) ||
          (isDataFile && cType !== 'Podcast-RSS' && (orderId && !usedOrderIds.includes(orderId) && orderId.length <= 40 && !itemId && isOnlyNumbers(orderId)) || (itemId && !usedItemIds.includes(itemId) && itemId.length <= 40 && !orderId && isOnlyNumbers(itemId)))
        ) 
  return (
    <div className='create-placement'>
      <div className='breadCrumbOuterWrapper'>
        <div className='breadCrumbInnerWrapper'>
          <Link to='/'>
            <div className='homeBlue'></div>
          </Link>
          <div className='breadCrumbBody'>
            /
            <Link to='/campaigns'>
              <div className='active'>{breadCrumbHeader.campaigns}</div>
            </Link>
          </div>
          <div className='breadCrumbBody'>
            /
            {!isBaseLoading ? (
              <Link
                to={`/campaign/${campaignId}?params=${getEncryptedOrDecryptedData(
                  { name: campaignName },
                  'encrypt'
                )}`}
              >
                <div className='active'>{campaignName}</div>
              </Link>
            ) : (
              <LoaderComponent importedClassNames='dropdownsAndDates midElem' />
            )}
          </div>
          <div className='breadCrumbBody'>
            <span>/</span>
            {isClonePlacement ? 'Clone Placement' : 'Create Placement'}
          </div>
        </div>
      </div>
      <div className='create-edit-placement-container'>
        <CommonHeadingComponent
          headingLabel={
            isClonePlacement ? 'Clone Placement' : 'Create Placement'
          }
        />
        <Container>
          {isLoading || isBaseLoading ? (
            <div className='pageLoaderWrapper'>
              <LoaderComponent />
            </div>
          ) : (
            <>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} className='form-field'>
                  <CommonInput
                    label={'Name'}
                    inputValue={name}
                    setInputValue={(val) => {
                      changeHandler(val, 'name');
                      if (val.length > 255) {
                        setValidName({
                          valid: false,
                          errorMsg:
                            'Maximum allowed characters for Name is 255 ',
                        });
                      } else {
                        setValidName({ valid: true, errorMsg: '' });
                      }
                    }}
                    labelClass={'requiredInput'}
                    placeholder={'Enter Placement Name'}
                    onBlurHandle={(val) => {
                      checkValid(val.target.value, 'name');
                    }}
                    className={
                      validName.valid ? 'userInput' : 'userInput inputError'
                    }
                  />
                  {!validName.valid && (
                    <span className='error'>{validName.errorMsg}</span>
                  )}
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                  <DatePickerComponent
                    datePickerId='cCStartDate'
                    label='Start Date'
                    placeholderLabel='Start Date'
                    // minDate={moment(today).format('YYYY-MM-DD')}
                    format='MM/DD/YYYY'
                    startOrEndDate={startDate}
                    setStartOrEndDate={setStartDate}
                    isMandatory
                    className={
                      validStartDate.valid
                        ? 'inputField inputCalendar cursorPointer'
                        : 'inputField inputCalendar cursorPointer invalidInput'
                    }
                  />
                  {!validStartDate.valid && (
                    <span className='error'>{validStartDate.errorMsg}</span>
                  )}
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                  <DatePickerComponent
                    datePickerId='cCEndDate'
                    label='End Date'
                    placeholderLabel='End Date'
                    // minDate={startDate || moment(today).format('YYYY-MM-DD')}
                    format='MM/DD/YYYY'
                    startOrEndDate={endDate}
                    setStartOrEndDate={setEndDate}
                    isMandatory
                    className={
                      validEndDate.valid
                        ? 'inputField inputCalendar cursorPointer'
                        : 'inputField inputCalendar cursorPointer invalidInput'
                    }
                  />
                  {!validEndDate.valid && (
                    <span className='error'>{validEndDate.errorMsg}</span>
                  )}
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                  <SearchableDropdownComponent
                    options={((isDataFile && !isRSSType) ? mediaPartners.data.table_data?.filter(item => item.data_provider) : mediaPartners.data.table_data) ?? []}
                    label='name'
                    id='name'
                    dropdownLabel='Media Partner'
                    dropdownLabelClass='requiredInput'
                    selectedValue={mediaPartner}
                    handleChange={(val) => changeHandler(val, 'mediaPartner')}
                    wrapperClass={'requiredDD'}
                    dropdownId='mediaPartner'
                    placeholderLabel='Select a Media Partner'
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                  <CommonInput
                    label={'Reference ID'}
                    inputValue={referenceId}
                    setInputValue={(val) => {
                      changeHandler(val, 'referenceId');
                      checkValid(val, 'referenceID');
                    }}
                    placeholder={'Enter Reference ID'}
                    className={
                      validRefID.valid ? 'userInput' : 'userInput inputError'
                    }
                  />
                  {!validRefID.valid && (
                    <span className='error'>{validRefID.errorMsg}</span>
                  )}
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                  <div className={`dataFileCPMWrapper ${!isRSSType ? 'dataFileVisible' : ''}`}>
                    <div>
                      <CommonInput
                        label={'CPM'}
                        inputValue={`$${cpm}`}
                        setInputValue={(val) =>
                          changeHandler(val.replace(/\$/g, ''), 'cpm')
                        }
                        placeholder={'Enter CPM'}
                      />
                      {showCPMWarning()}
                    </div>
                    {!isRSSType && <div className='dataFileWrapper'>
                      <input
                        type='checkbox'
                        id={'dataFile'}
                        className={'dataFile'}
                        checked={isDataFile}
                        disabled={false}
                        onChange={(e) => {
                          changeHandler(e.target.checked, 'isDataFile');
                        }}
                      />
                      <label className="dataFileLabel" htmlFor={'dataFile'}>Data File</label>
                    </div>}
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <SearchableDropdownComponent
                    options={creativeTypes?.data?.table_data?.filter(item => {
                        if (isDataFile) {
                          return item.value !== 'Podcast-RSS'
                        } else {
                          return true;
                        }
                      }) ?? []}
                    label='name'
                    id='name'
                    dropdownLabel='Creative Type'
                    dropdownLabelClass='requiredInput'
                    selectedValue={creativeType}
                    handleChange={(val) => changeHandler(val, 'creativeType')}
                    wrapperClass={'requiredDD'}
                    dropdownId='creativeType'
                    isSearchable={false}
                    placeholderLabel={'Enter Creative Type'}
                  />
                </Col>
                {creativeType && creativeType.value === 'Podcast' && !isDataFile ? (
                  <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                    <SearchableDropdownComponent
                      options={podcastProviders.data.table_data ?? []}
                      label='name'
                      id='name'
                      dropdownLabel='Podcast Provider'
                      selectedValue={podcastProvider}
                      handleChange={(val) =>
                        changeHandler(val, 'podcastProvider')
                      }
                      wrapperClass={'requiredDD'}
                      dropdownId='podcastProvider'
                      isSearchable={false}
                      placeholderLabel={'Enter Podcast Provider'}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                {(isDataFile && !isRSSType && usedItemIds?.length === 0) ? (
                  <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                    <CommonInput
                      label={'Order ID'}
                      inputValue={orderId}
                      setInputValue={(val) => {
                        changeHandler(val, 'orderId');
                      }}
                      placeholder={'Enter Order ID'}
                      onBlurHandle={(val) => {
                        checkValid(val.target.value, 'orderId');
                      }}
                      className={
                        validOrderId.valid
                          ? 'userInput'
                          : 'userInput inputError'
                      }
                    />
                    {!validOrderId.valid && (
                      <span className='error'>
                        {validOrderId.errorMsg}
                      </span>
                    )}
                  </Col>
                ) : (
                <></>
                )}
                {(isDataFile && !isRSSType && usedOrderIds?.length === 0) ? (
                  <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                    <CommonInput
                      label={'Line Item ID'}
                      inputValue={itemId}
                      setInputValue={(val) => {
                        changeHandler(val, 'itemId');
                      }}
                      placeholder={'Enter Line Item ID'}
                      onBlurHandle={(val) => {
                        checkValid(val.target.value, 'itemId');
                      }}
                      className={
                        validItemId.valid
                          ? 'userInput'
                          : 'userInput inputError'
                      }
                    />
                    {!validItemId.valid && (
                      <span className='error'>
                        {validItemId.errorMsg}
                      </span>
                    )}
                  </Col>
                ) : (
                <></>
                )}
                {!isRSSType && !isDataFile ? (
                  <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                    <CommonInput
                      label={'Clickthrough URL'}
                      inputValue={clickURL}
                      setInputValue={(val) => {
                        changeHandler(val, 'clickURL');
                        if (val.length > 1000) {
                          setValidClickThroughURL({
                            valid: false,
                            errorMsg:
                              'Maximum characters allowed for Clickthrough URL is 1000',
                          });
                        } else {
                          setValidClickThroughURL({
                            valid: true,
                            errorMsg: '',
                          });
                        }
                      }}
                      labelClass={'requiredInput'}
                      placeholder={'Enter Clickthrough URL'}
                      onBlurHandle={(val) => {
                        checkValid(val.target.value, 'clickURL');
                      }}
                      className={
                        validClickThroughURL.valid
                          ? 'userInput'
                          : 'userInput inputError'
                      }
                    />
                    {!validClickThroughURL.valid && (
                      <span className='error'>
                        {validClickThroughURL.errorMsg}
                      </span>
                    )}
                  </Col>
                ) : (
                  <></>
                )}
                {isRSSType ? (
                  <>
                    <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                      <CommonSearchFilter
                        label={'Search for Podcast'}
                        placeholder='Search Podcast'
                        filterKey={'label'}
                        filterData={podcasts.data.table_data.map(podcast => ({...podcast, label: `${podcast.title} (#${podcast.id})`}))}
                        handleSelect={handlePodCastSelect}
                        inputValue={searchPodcast}
                        setInputValue={(val) =>
                          changeHandler(val, 'searchPodcast')
                        }
                        labelClass={'requiredInput'}
                      />
                    </Col>
                    {showManualOption ? (
                      <Col xl={6} lg={6} md={12} sm={12} className='form-field'>
                        <CommonInput
                          label={'RSS Feed URL'}
                          inputValue={manualRssURL}
                          setInputValue={(val) => {
                            setRssData(null);
                            changeHandler(val, 'manualRssURL');
                          }}
                          labelClass={'requiredInput'}
                          onKeyDown={handleKeyDown}
                          placeholder={'Enter RSS Feed URL'}
                          isLoading={rssValidationLoading}
                        />
                        <div className='flex mt-2'>
                          <div className='infoForUser'>
                            Note: After entering in the URL, please select the Enter key to populate the show name.
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {showNonEditable ||
                    (showManualOption && rssData && rssData.showName) ? (
                      <>
                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          className='form-field'
                        >
                          <CommonInput
                            label={'Show Name'}
                            inputValue={
                              showManualOption ? rssData.showName : showName
                            }
                            disabled={true}
                          />
                        </Col>
                        {showNonEditable ? (
                          <Col
                            xl={6}
                            lg={6}
                            md={12}
                            sm={12}
                            className='form-field'
                          >
                            <CommonInput
                              label={'RSS feed URL'}
                              inputValue={RSSFeedURL}
                              disabled={true}
                            />
                          </Col>
                        ) : (
                          <></>
                        )}
                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          className='form-field'
                        >
                          <DatePickerComponent
                            datePickerId='pubStartDate'
                            label='Publish Start Date'
                            placeholderLabel='Publish Start Date'
                            // minDate={moment(today).format('YYYY-MM-DD')}
                            format='MM/DD/YYYY'
                            startOrEndDate={pubStartDate}
                            setStartOrEndDate={setPubStartDate}
                            isMandatory
                            className={
                              validPublishStartDate.valid
                                ? 'inputField inputCalendar cursorPointer'
                                : 'inputField inputCalendar cursorPointer invalidInput'
                            }
                          />
                          {!validPublishStartDate.valid && (
                            <span className='error'>
                              {validPublishStartDate.errorMsg}
                            </span>
                          )}
                        </Col>
                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          className='form-field'
                        >
                          <DatePickerComponent
                            datePickerId='pubEndDate'
                            label='Publish End Date'
                            placeholderLabel='Publish End Date'
                            // minDate={
                            //   pubStartDate || moment(today).format('YYYY-MM-DD')
                            // }
                            format='MM/DD/YYYY'
                            startOrEndDate={pubEndDate}
                            setStartOrEndDate={setPubEndDate}
                            isMandatory
                            className={
                              validPublishEndDate.valid
                                ? 'inputField inputCalendar cursorPointer'
                                : 'inputField inputCalendar cursorPointer invalidInput'
                            }
                          />
                          {!validPublishEndDate.valid && (
                            <span className='error'>
                              {validPublishEndDate.errorMsg}
                            </span>
                          )}
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row>
                <Col
                  sm={12}
                  className='campaignListButtonWrapper text-center mt-3 mb-4'
                >
                  <CommonButtonComponent
                    label={'Save'}
                    appendWrapperClassName='btnPrimaryWrapper'
                    appendButtonClassName='btnPrimary'
                    onClickCTA={() => handleSave('')}
                    isDisabled={!enableSave || disableBtn()}
                  />
                  <CommonButtonComponent
                    label={'Cancel'}
                    appendWrapperClassName='btnPrimaryWrapper'
                    appendButtonClassName='btnSecondary'
                    onClickCTA={handleCancel}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>

      {isModalOpen && (
        <CommonModal
          show={isModalOpen}
          header={'RSS Redirect Confirmation'}
          body={<RSSURLBodyJSX rssData={rssData} />}
          hideCloseBtn={true}
          size='md'
          acceptBtnText='Ok'
          accept={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      {isDupModalOpen && (
        <CommonModal
          show={isDupModalOpen}
          header={'Duplicate Placement Name'}
          body={<DupNameBodyJSX dupNameModalData={dupNameModalData} />}
          hideCloseBtn={true}
          size='lg'
          acceptBtnText='Create'
          rejectBtnText='Cancel'
          accept={() => {
            setFormData({ ...formData, name: dupNameModalData.suggestedName });
            setIsDupModalOpen(false);
            handleSave(dupNameModalData.suggestedName);
          }}
          reject={() => {
            setIsDupModalOpen(false);
          }}
        />
      )}
      {rssChannelExists && (
        <DuplicateRSSChannelModal
          show={rssChannelExists}
          hideCloseBtn={true}
          size='lg'
          accept={(val, selectedRow, newChannelURL) => handleDuplicateRSSAction(val, selectedRow, newChannelURL)}
          reject={() => {
            setRssChannelExists(null);
            setRssData(null);
            changeHandler('', 'manualRssURL');
          }}
          channelData={rssChannelExists}
          rssValidationLoading={rssValidationLoading}
        />
      )}
    </div>
  );
};

export default CreatePlacement;
