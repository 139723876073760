const redirectModalInfo = {
  // For Advertiser
  advertiserList: {
    name: 'View Advertisers',
    baseRedirectUrl: '/advertisers',
  },
  createAdvertiser: {
    name: 'Create an Advertiser',
    baseRedirectUrl: '/advertisers/new',
  },
  createAdvertiser2: {
    name: 'Create Another Advertiser',
    baseRedirectUrl: '/advertisers/new',
  },
  // For Campaigns
  campaignDetails: {
    name: 'View Campaign Details',
    baseRedirectUrl: '/campaign',
    disabled: false,
  },
  campaignList: {
    name: 'View Campaigns',
    baseRedirectUrl: '/campaigns',
    disabled: false,
  },
  createCampaign: {
    name: 'Create Campaign',
    baseRedirectUrl: '/campaign/new',
    disabled: false,
  },
  createCampaign2: {
    name: 'Create Another Campaign',
    baseRedirectUrl: '/campaign/new',
    disabled: false,
  },
  // For Properties
  propertyDetails: {
    name: 'View Property Details',
    baseRedirectUrl: '/properties',
    disabled: false,
  },
  propertyList: {
    name: 'View Properties',
    baseRedirectUrl: '/properties',
    disabled: false,
  },
  createProperty: {
    name: 'Create a Property',
    baseRedirectUrl: '/properties/new',
    disabled: false,
  },
  createProperty2: {
    name: 'Create Another Property',
    baseRedirectUrl: '/properties/new',
    disabled: false,
  },
  // For Goals
  createGoal: {
    name: 'Create Goal',
    baseRedirectUrl: '/properties',
    disabled: false,
  },
  // For Placements
  createPlacement: {
    name: 'Create a Placement',
    baseRedirectUrl: '/campaign',
    disabled: false,
  },
  // For Reports
  reportingDashboard: {
    name: 'View Reporting Dashboard',
    baseRedirectUrl: '/reports',
    disabled: false,
  },
};

export default redirectModalInfo;
