import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateAdvertiserMode, resetAdvertiserMode } from '../../redux/utilitybarSlice'
import { updateSelectedAdvertiser } from '../../redux/advertiserSlice'
import { breadCrumbHeader } from '../../constants'
import { AdvertisersList, AdvertiserEdit } from './components'
import { LoaderComponent } from '../../components'
import './advertisers.css'

// Main Advertiser Component, this will handle Listing and Edit/Create Advertiser Page
const Advertisers = () => {
  document.title = 'Advertisers' // Page title

  const { advertiserId } = useParams() // GET params from URL
  const [heading, setHeading] = useState('Advertisers') // Page heading, will be shared as props in child components
  const [componentToLoad, setComponentToLoad] = useState(<AdvertisersList />) // state to hold which component to load, on the basis of URL path
  const [showForm, setShowForm] = useState(false)
  const [advName, setAdvName] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch() // dispatch to enable/disable advertiser drop-down
	const client = useSelector((state) => state.client) // listener to client change in global header
	const advertiser = useSelector((state) => state.advertiser) // Fetch advertiser's information from redux store

	const navigate = useNavigate()

  /**
   * Single useEffect to update/toggle child components on the basis of props{advertiserId} change
   */
  useEffect(() => {
    // Disable advertiser dropdown from app state
    dispatch(updateAdvertiserMode(true)) // Disable advertiser dropdown
    dispatch(updateSelectedAdvertiser(0)) // Select selected advertiser to All
    // condition to load Listing or Edit
    if (advertiserId) {
			const advInfo = advertiser.advertiserList.find(
				(adv) =>
					adv.id === Number(advertiserId) && adv.user_id === client.selectedClient
			)
			if (advertiserId === 'new' || advInfo) {
				setComponentToLoad(
					<AdvertiserEdit
						advertiserId={advertiserId}
						setHeading={setHeading}
						getAdName={setAdvName}
						setIsLoading={setIsLoading}
					/>
				)
				setShowForm(true)
			} else {
				navigate('/advertisers')
			}
    } else {
      setHeading('Advertisers')
      setComponentToLoad(<AdvertisersList />)
      setShowForm(false)
    }

		return () => {
			dispatch(resetAdvertiserMode()); // Enable advertiser dropdown
		};
	}, [advertiserId, client.selectedClient]);

	return (
		<React.Fragment>
			<div className="breadCrumbOuterWrapper">
				<div className="breadCrumbInnerWrapper">
					<Link to="/">
						<div className="homeBlue"></div>
					</Link>
					
					{showForm ?
					  <>
						<div className='breadCrumbBody'>
						/
						<Link to='/advertisers'>
						<div className='active' onClick={() => setAdvName('')}>{breadCrumbHeader.advertisers}</div>
						</Link>
						</div>
						<div className='breadCrumbBody'>
							<span>/</span>
							{setHeadingText(showForm, heading, advName, isLoading)}
						</div>
					  </>
					:
					<div className="breadCrumbBody">
						<span>/</span>
						{breadCrumbHeader.advertisers}
					</div>
					}
					
				</div>
				{/* <div style={{ display: "flex", justifyContent: "space-between" }}>
					<h1>{headingText}</h1>
					{!showForm ? (
						<Link to="/advertisers/new" className="createAdvertiserBtn decorationNone">
							<CommonButtonComponent
								label="Create Advertiser"
								isDisabled={false}
								showLoader={false}
								onClickCTA={() => {}}
							/>
						</Link>
					) : (
						""
					)}
				</div> */}
			</div>
      {componentToLoad}
    </React.Fragment>
  )
}

export default Advertisers

const setHeadingText = (showForm, heading, advName, isLoading) => {
  if (showForm && heading !== 'Create Advertiser') {
    if (!isLoading) {
      return advName;
    }
    return <LoaderComponent importedClassNames='dropdownsAndDates' />;
  }
  return heading;
};
