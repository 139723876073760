import { createSlice } from '@reduxjs/toolkit';

export const utilityBarSlice = createSlice({
  name: 'utilityBar',
  initialState: {
    advertiserReadOnly: false,
    clientReadOnly: false,
    showUtilityBar: true,
  },
  reducers: {
    updateAdvertiserMode: (state, action) => {
      state.advertiserReadOnly = action.payload;
    },
    resetAdvertiserMode: (state) => {
      state.advertiserReadOnly = false;
    },
    updateClientMode: (state, action) => {
      state.clientReadOnly = action.payload;
    },
    updateShowUtilityBar: (state, action) => {
      state.showUtilityBar = action.payload;
    },
    resetClientMode: (state) => {
      state.clientReadOnly = false;
    },
  },
});

export const {
  updateAdvertiserMode,
  resetAdvertiserMode,
  updateClientMode,
  resetClientMode,
  updateShowUtilityBar,
} = utilityBarSlice.actions;
export default utilityBarSlice.reducer;
