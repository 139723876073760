import ActiveCampaignsComponent from '../../Campaigns/components/activeCampaigns/ActiveCampaigns.component';
import UpcomingCampaignsComponent from './upcomingCampaigns/UpcomingCampaigns.component';
import CampaignListComponent from './campaignList/CampaignList.component';
import Performers from './performers/Performers.component';

export const baseInputParams = {
  page_size: 5,
  page_number: 1,
};

export {
  ActiveCampaignsComponent,
  UpcomingCampaignsComponent,
  CampaignListComponent,
  Performers,
};
