import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import {
  approximateNumberToMils,
  dateFormat,
  getArrayOfData,
} from './../../../../../../utils/functions';
import {
  CommonBarChartComponent,
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  SearchableDropdownComponent,
  NoDataComponent,
} from '../../../../../../components';
import './topIncrementalGoals.css';
import { useSelector } from 'react-redux';
import { axiosSendRequest } from '../../../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../../../constants';

const TopIncrementalGoals = ({
  campaign,
  placement,
  mediaPartner,
  creativeType,
  ifSelectedAll,
  parametersIsLoading,
  startDate,
  endDate
}) => {
  const [goal, setGoal] = useState(null),
    [goalList, setGoalList] = useState(null),
    [defaultGoalList, setDefaultGoalList] = useState(null);

  const [isGoalListLoading, setGoalListLoading] = useState(false),
    [isDataLoading, setDataLoading] = useState(false),
    [isError, setIsError] = useState(false),
    [status, setStatus] = useState(200);
  const [usableGoalListOpts, setUsableGoalListOpts] = useState([]);


  const [cancelController, setCancelController] = useState(null);

  const [incrementalData, setIncrementalData] = useState([]),
    [incrementalBars, setIncrementalBars] = useState([]);

  const advertiser = useSelector((state) => state.advertiser);

  const goalListRef = useRef(null);

  const getGoalList = async (controller) => {
    setGoalListLoading(true);
    setDataLoading(true);
    setIsError(false);
    try {
      const listOfGoals = await axiosSendRequest(
        `${baseEndpoints.reports}/lift-tab/goal-list`,
        controller,
        {
          campaign_id: campaign?.campaign_id,
          partner_id: getArrayOfData(mediaPartner, 'partner_id'),
          creative_type: getArrayOfData(creativeType, 'name'),
          placement_id: getArrayOfData(placement, 'placement_id'),
          from: dateFormat(startDate, 'YYYY-MM-DD'),
          to: dateFormat(endDate, 'YYYY-MM-DD'),
        }
      );
      setStatus(listOfGoals.status);
      if (listOfGoals.status === 200 && listOfGoals?.data?.length) {
        setDefaultGoalList(listOfGoals?.data);
        setGoalList(listOfGoals?.data);
        setGoal(listOfGoals?.data[0]);
      } else {
        setDataLoading(false);
        setDefaultGoalList(null);
        setGoalList(null);
        setGoal(null);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        if (err.response) {
          console.log({ err });
          setIsError(true);
        } else {
          setStatus(500);
        }
        setDataLoading(false);
      }
    } finally {
      setGoalListLoading(false);
    }
  };

  const getIncrementalList = async (controller) => {
    setDataLoading(true);
    setIsError(false);
    try {
      const incrementalGoalsData = await axiosSendRequest(
        `${baseEndpoints.reports}/lift-tab/incremental-goals`,
        controller,
        {
          campaign_id: campaign?.campaign_id,
          from: dateFormat(startDate, 'YYYY-MM-DD'),
          to: dateFormat(endDate, 'YYYY-MM-DD'),
          mock_data: false,
          goal_id: defaultGoalList?.map(item => item.goal_id),
        }
      );

      if (incrementalGoalsData?.incrementalLift?.success) {
        let temp = [];
        let data = incrementalGoalsData?.incrementalLift?.data;
        data.forEach((item) => {
          let foundGoal = goalList.find(g => g.goal_id === item.goal_id);
          item.goal_name = foundGoal.goal_name;
        });
        temp = data.filter(item => item.goal_id === goal.goal_id);
        setIncrementalData(data);
        setIncrementalBars(temp);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    setGoalList(null);
    setDefaultGoalList(null);
    setIncrementalData([]);
    setIncrementalBars([]);
    if (
      advertiser.selectedAdvertiser !== -1 &&
      campaign &&
      campaign.campaign_id
    ) {
      const controller = new AbortController();
      setCancelController(controller);
      getGoalList(controller);
      return () => {
        controller.abort();
      };
    } else {
      setGoalListLoading(false);
    }
  }, [campaign, ifSelectedAll, parametersIsLoading, startDate, endDate]);

  useEffect(() => {
    setIncrementalData([]);
    if (defaultGoalList?.length) {
      const controller = new AbortController();
      setCancelController(controller);
      getIncrementalList(controller);
      calData();
      return () => {
        controller.abort();
      };
    }
  }, [defaultGoalList]);

  useEffect(() => {
    calData();
  }, [goal]);

  useEffect(() => {
    goalListRef.current = goalList ? [...goalList] : null;
    const generatedGoalList = generateUsableGoalList(goalList);
    setUsableGoalListOpts(generatedGoalList);
    if (generatedGoalList?.length) {
      setGoal(generatedGoalList[1]);
    }
  }, [goalList]);

  const calData = () => {
    let temp = [];
    if (goal) {
      temp = incrementalData.filter(item => item.goal_id === goal.goal_id);
    }
    setIncrementalBars(temp);
  };

  const generateUsableGoalList = (goalList) => {
    if (!goalList?.length) {
      return [];
    }
  
    const engagementOptions = [
        {
          goal_id: -1,
          goal_name: 'Engagement Goals',
          goal_type: 'engagement',
          isParent: true,
          isHeading: true,
        },
      ],
      conversionOptions = [
        {
          goal_id: -2,
          goal_name: 'Conversion Goals',
          goal_type: 'conversion',
          isParent: true,
          isHeading: true,
        },
      ];
  
    goalList.map((goal) => {
      if (goal.goal_type === 'engagement') {
        engagementOptions.push({ ...goal, isParent: false });
      } else {
        conversionOptions.push({ ...goal, isParent: false });
      }
    });
  
    const newGoalOptions = [].concat(
      engagementOptions.length > 1 ? engagementOptions : [],
      conversionOptions.length > 1 ? conversionOptions : []
    );
  
    return newGoalOptions;
  };

  const checkError = () => {
    return isError ? (
      <ErrorComponent
        refreshAction={getGoalList}
        controller={cancelController}
        status={status}
      />
    ) : (
      renderComponent()
    );
  };

  const renderComponent = () => {
    return incrementalBars?.length ? (
      <Container>
        <Row>
          <CommonBarChartComponent
            baseHeight={300}
            data={incrementalBars}
            chartMargin={{ top: 30, bottom: 0, left: 20, right: 25 }}
            dataKey='goal_name'
            domainMultiplier={null}
            xAxisTickShow={false}
            xTickFormatter={number => `${number.toFixed(4)}`}
            yTickFormatter={approximateNumberToMils}
            ifShowLegends={false}
            customTooltip={customTooltip}
            barDataKeys={['expectedEvents', 'exposedEvents']}
            barStackIds={['expectedEvents', 'exposedEvents']}
            barFills={['#04bdc9', '#f56600']}
            uniqueKey={['TIG']}
            // barLabel={<CustomLabel />}
            wrapperStyle={{ outline: 'none' }}
          />
        </Row>
        <Row className='topIncRemovePaddingMargin topIncEventsDataWrapper'>
          <Col
            xl={6}
            lg={6}
            md={12}
            sm={12}
            className='topIncRemovePaddingMargin topIncDataElements'
          >
            <div className='topIncDataValue'>
              {(incrementalBars?.[0].percentLift * 100).toFixed(2)} %
            </div>
            <div className='topIncDataLabel'>
              <span style={{ textTransform: 'capitalize' }}>
                {goal?.goal_type}
              </span>{' '}
              Goal Lift %
            </div>
          </Col>
        </Row>
       </Container>
    ) : (
      <NoDataComponent
        type='bar'
        width='180'
        height='180'
        messageTextFontSize={15}
        foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
        messageText='No Data Available'
      />
    );
  };

  return (
    <div className='topIncrementalGoalsWrapper'>
      <div className='topIncrementalGoalsHeaderWrapper'>
        <CommonHeadingComponent
          headingLabel={'Top Incremental Goals'}
          ifDropdown={true}
          headingCTALabel={
            <HeadeingSearchField
              goalList={usableGoalListOpts}
              selectedGoal={goal}
              setSelectedGoal={setGoal}
              isLoading={isGoalListLoading}
              ifSelectedAll={ifSelectedAll}
              parametersIsLoading={isGoalListLoading || isDataLoading}
            />
          }
        />
      </div>
      <div className='topIncrementalGoalsGraphWrapper'>
        {isDataLoading ? <LoaderComponent /> : checkError()}
      </div>
    </div>
  );
};

export default TopIncrementalGoals;

const CustomLabel = (payload) => {
  return (
    <text
      x={payload?.x + 18}
      y={payload?.y - 5}
      width={payload?.width}
      height={payload?.height}
      stroke='#000'
      strokeOpacity='0.5'
      offset={payload?.offset}
      fill='#808080'
      className='recharts-text recharts-label'
      textAnchor='middle'
    >
      <tspan x={payload?.x + 18} dy='0em'>
        {approximateNumberToMils(payload?.value).toFixed(1)}
      </tspan>
    </text>
  );
};

const customTooltip = (payload) => {
  const data =
    payload.payload && payload?.payload.length > 0
      ? payload.payload
      : [null, null];
  const exposedEventCount = data.find((elem) => elem?.dataKey === "exposedEvents")?.value?.toFixed(4);
  const expectedEventCount = data.find((elem) => elem?.dataKey === "expectedEvents")?.value?.toFixed(4);
  return (
    <div className='customTooltipWrapper-TIG'>
      <div className='customTooltipLabel-TIG'>{payload?.label}</div>
      <div className='customTooltipInnerWrapper-TIG'>
        <div>Control Group:</div>
        <div className='customTooltipValue-TIG blueTIG'>{expectedEventCount || ''}</div>
      </div>
      <div className='customTooltipInnerWrapper-TIG'>
        <div>Exposed Group:</div>
        <div className='customTooltipValue-TIG orangeTIG'>{exposedEventCount || ''}</div>
      </div>
    </div>
  );
};

const determineSummaryHeaderLoading = (parametersIsLoading, ifSelectedAll) => {
  return Object.values(parametersIsLoading).includes(true) || !ifSelectedAll;
};

const HeadeingSearchField = ({
  goalList,
  selectedGoal,
  setSelectedGoal,
  isLoading,
  ifSelectedAll,
  parametersIsLoading,
}) => (
  <SearchableDropdownComponent
    options={goalList}
    label='goal_name'
    id='goal_id'
    dropdownId='attrVsIncGoalList'
    dropdownLabel=''
    labelWidth={{ margin: 0 }}
    dropdownWidth={{
      width: '180px',
    }}
    wrapperClass=''
    placeholderLabel={goalList?.length ? 'Select Goal' : 'Goal unavailable'}
    selectedValue={selectedGoal}
    handleChange={setSelectedGoal}
    isSearchable={false}
    isDisabled={
      !goalList?.length ||
      determineSummaryHeaderLoading(parametersIsLoading, ifSelectedAll)
    }
    isLoading={isLoading}
    isParentChildBasedOption={true}
    parentIdentificationKey='isParent'
    uniqueIdentifier='reportDetailsLiftAttributableVsIncrementalGoals'
    ifIdVisableOnLabel={true}
  />
);
