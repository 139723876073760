export const messages = {
    pixelRawDataInfo:
      'Displays up to 10 most recent actions. This report is meant for validation only.',
  },
  scheduledReportErrorMessageList = {
    nullImpFreqWarn:
      'There are 0 impressions during this time for this Campaign.',
    runTimeFrameBeforeCurrentDateWarn: 'This Time Frame Ends Before the Current Date.',
    runTimeFrameStartDateAfterCampaignDates: 'This Time Frame Starts After the Campaign Dates.',
    runTimeFrameEndDateAfterCampaignDates: 'This Time Frame Ends After the Campaign Dates.',
    runTimeFrameStartDateAfterEndDate: 'Invalid Time Frame: Start Date Is After End Date.',
    noRptNameErr: 'Report Name must be entered.',
    noRecipientErr: 'At least one email address must be entered.',
    noRunTimeFrameErr: 'A Run Time Frame must be entered.',
    noRunDateErr: 'Run Date Range must be entered.',
    noPresetTimeFrameErr: 'A Preset Time Frame must be entered.',
    customDateRngErr: 'Custom Date Range must be entered.',
    piiMaskError: 'Please select a PII Mask option.',
    headerSelectionError: 'Please select an option under Include Column Headers.',
    outputFormatError: 'Please select an Output Format option.',
    reachAndFrequencyWarning: 'Reach and Frequency reports can only be run on campaigns with 7 days worth of data or more.',
    reachAndFrequencyDateRangeError: 'Reach and frequency reports require a date range that is at least 7 days long.',
    atLeastOnePlacementCreativeTypePartnerError: 'At least one placement, creative type, and partner must be selected before creating a scheduled report.',
  };
