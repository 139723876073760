export const customMessages = {
  emptyTables: {
    'Active Campaigns': 'No active campaigns available!',
    'Upcoming Campaigns': 'No upcoming campaigns!',
    'Ending Soon': 'No campaigns ending soon!',
    'Starting Soon': 'No campaigns starting soon!',
    'Comparision Table': 'Please select partners for comparison',
    'No partner': 'No data available!'
  },
  errors: {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    408: 'Request Timeout',
    426: 'Network Error',
    429: 'Too Many Requests',
    500: 'Internal Server Error'
  }
};
