import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { CommonTablesComponent, ErrorComponent, NoDataComponent } from '../../../../../../components';
import { axiosPostRequest } from '../../../../../../services/http/axiosCalls';
import { baseEndpoints } from '../../../../../../constants';
import columnsWithCTA from './ivtImpressionsGridColumn'

const ivtMap = [
  { code: '1', name: 'IAB Spider/Bot List Match (Neutral)' },
  { code: '2', name: 'Test Flag (Including internal office IP)' },
  { code: '3', name: 'Activity Based Filtration (Speed)' },
  { code: '4', name: 'Prefetch' },
  { code: '5', name: 'Click w/o Impression Match' },
  { code: '6', name: 'Prerender' },
  { code: '7', name: 'Deferred Impression' },
  { code: '8', name: 'Double Click' },
  { code: '10', name: 'Data Center IP' },
  { code: '11', name: 'Missing UA or IP' },
  { code: '12', name: 'Activity Based Filtration (Interval)' },
  { code: '13', name: 'Activity Based Filtration (Repeat)' },
  { code: '14', name: 'Bogon (Bogus IP Address)' },
  { code: '15', name: 'Invalid User Agent' },
  { code: '16', name: 'Bot (Good)' },
  { code: '17', name: 'Bot (Bad)' },
];

export default function IVTImpressionsReport({ campaign, reportComponentIsLoading, startDate, endDate, haveSetDates, placement, creativeType, mediaPartner }) {
  const [data, setData] = useState([]);
  const [firstHalfOfData, setFirstHalfOfData] = useState([]);
  const [secondHalfOfData, setSecondHalfOfData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState(200);
  const [sortData, setSortData] = useState({
    column: "Count",
    order: "desc",
  });

  /**const groupingChanged = (val) => {
    setGrouping(val.target.value);
  };*/

  const getImpressions = async (controller) => {
    setIsLoading(true);
    setIsError(false);
    setData([]);
    setFirstHalfOfData([]);
    setSecondHalfOfData([]);
    try {
      if (campaign?.campaign_id && startDate && endDate && placement?.length && creativeType?.length && mediaPartner?.length && !isError) {
        const placementIds = changeObjectToArray(placement, 'placement_id');
        const creativeIds = changeObjectToArray(creativeType, 'value');
        const partnerIds = changeObjectToArray(mediaPartner, 'partner_id');
        const response = await axiosPostRequest(
          `${baseEndpoints.reports}/media-delivery-tab/analysis/ivt`,
          {
            campaign_id: campaign.campaign_id,
            start_date: startDate,
            end_date: endDate,
            placement_id_array: placementIds,
            creative_id_array: creativeIds,
            partner_id_array: partnerIds,
          }
        );

        const data = response.data?.impressions;
        setStatus(response.status);
        if (response.status === 200 && data) {
          if (data?.length) {
            const tmp = ivtMap.map((ivtType) => {
              const countEl = data.find((ivtImp) => {
                return ivtImp.ivt == ivtType.code;
              });

              return {
                code: ivtType.code,
                name: `${ivtType.name} (${ivtType.code})`,
                Count: parseInt(countEl?.count ?? 0),
              };
            });
            tmp.sort((a, b) => {
              if (a.Count < b.Count) return 1;
              else return -1;
            });
            setData(tmp);
            const midpoint = Math.ceil(tmp.length / 2);
            setFirstHalfOfData(tmp.slice(0, midpoint));
            setSecondHalfOfData(tmp.slice(midpoint));
          }
        } else {
          setIsError(true);
        }
      } else {
        setData([]);
        setFirstHalfOfData([]);
        setSecondHalfOfData([]);
      }
      setIsLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err.message);
        if (err.response) {
          setStatus(err.response.status);
        } else {
          setStatus(500);
        }
        setIsError(true);
        setIsLoading(false);
      }
    }
  };

  const changeObjectToArray = (iterable, value) => {
    const array = [];
    iterable.forEach((element) => {
      array.push(element[value]);
    });
    return array;
  }

  const checkReportComponentIsLoading = () => {
    return !(
      !reportComponentIsLoading.campaign &&
      !reportComponentIsLoading.placement &&
      !reportComponentIsLoading.mediaPartner &&
      !reportComponentIsLoading.creativeType
    );
  };
  
  useEffect(() => {
    if ((sortData.order === 'desc' && data[0]?.Count <= data[data.length - 1]?.Count) ||
      (sortData.order === 'asc' && data[0]?.Count >= data[data.length - 1]?.Count)
    ) {
      setData(data?.toReverse());
      setFirstHalfOfData(secondHalfOfData?.toReverse());
      setSecondHalfOfData(firstHalfOfData?.toReverse());
    }
  }, [sortData]);

  useEffect(() => {
    const controller = new AbortController();
    if (!checkReportComponentIsLoading() && haveSetDates) {
      getImpressions(controller);
    }

    return () => {
      controller.abort();
    };
  }, [
    campaign?.campaign_id,
    reportComponentIsLoading,
    haveSetDates,
    startDate,
    endDate,
    placement,
    creativeType,
    mediaPartner
  ]);

  if (!data || (!data.length && !isLoading)) {
    return (
      <NoDataComponent
        type='bar'
        customLayerStyle={{ backgroundSize: '30%' }}
        customImageStyle={{ backgroundSize: '10%' }}
      />
    );
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col style={{ textAlign: 'center', marginTop: 2 + 'em' }}>
            <span>IVT Impressions</span>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            {isError ? (
              <ErrorComponent status={status} />
            ) : (
              <Row>
                <Col lg={6} md={6} sm={6}>
                  <CommonTablesComponent
                    uniqueId='ivtImpressions'
                    data={firstHalfOfData ?? []}
                    setSortData={setSortData}
                    columns={columnsWithCTA(() => {})}
                    isLoading={isLoading}
                    customTableHeader='IVT Impressions'
                    sortColumnIds={['Count']} // sorting
                    defaultSortingApplied={{ column: 'Count', order: 'desc' }} // sorting
                    isPaginationApplied={false}
                  />
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <CommonTablesComponent
                    uniqueId='ivtImpressions'
                    data={secondHalfOfData ?? []}
                    setSortData={setSortData}
                    columns={columnsWithCTA(() => {})}
                    isLoading={isLoading}
                    customTableHeader='IVT Impressions'
                    sortColumnIds={['Count']} // sorting
                    defaultSortingApplied={{ column: 'Count', order: 'desc' }} // sorting
                    isPaginationApplied={false}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
