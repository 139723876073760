import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllowedRoutes } from './routes.utils';
import { routeConfig } from './routeConfig/configRoute';

const CreateRoute = () => {
  const loggedInUser = useSelector((state) => state.user);
  const [route, setRoute] = useState([]);

  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    // if user role is reporting, restrict user for any other route
    if(loggedInUser.role === 'reporting' && !location.pathname.includes('/reports')){
      navigate('/reports')
    }

    if((loggedInUser.role === 'admin' || loggedInUser.role === 'manager'  || loggedInUser === 'reporting') && location.pathname === '/dashboard'){
      navigate('/campaigns')
    }

  }, [location]);

  useEffect(() => {
    //function to get routes accessible by the logged in user
    setRoute(getAllowedRoutes(routeConfig, loggedInUser.role));
  }, [loggedInUser]);

  return (
    <Routes>
      {route.map((item) => {
        return (
          <Route
            path={item.path}
            key={item.path}
            element={item.component ? <item.component /> : null}
          />
        );
      })}
    </Routes>
  );
};

export default CreateRoute;
