const aaColors = {
  black: '#000000',
  white: '#ffffff',
  blue: '#04878f',
  lightBlue: '#ddf0ed',
  yellow: '#fac70f',
  orange: '#f56600',
  red: '#be3a34',
  darkOrange: '#863401',
  darkRed: '#681d1d',
  darkGray: '#252f39',
  mediumGray: '#646463',
  lightGray: '#a2a1a2',
  lightestGray: '#f4f2f1',
  lightestGray2: '#e5e4e5',
  tealText: '#88c3c3',
  teal: '#a1d9d6',
  darkTeal: '#405e67',
  tealChartingMedium: '#04bdc9'
};

export default aaColors;
