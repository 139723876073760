import React, { useEffect, useState } from 'react';
import {
  CommonButtonComponent,
  CommonHeadingComponent,
  CommonSearch,
  CommonTablesComponent,
  ErrorComponent,
  LoaderComponent,
  SearchableDropdownComponent,
} from '../../../../components';
import { baseEndpoints, camapignOptionList } from '../../../../constants';
import campaignLookupColumn from './campaignLookup.util';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';
import {
  checkCondition,
  exportCSV,
  getCurrentESTDate,
} from '../../../../utils/functions';

import './campaignLookup.css';

const baseInputParams = {
    sort_column: 'campaignName',
    sort_order: 'asc',
    page_number: 1,
    page_size: 10,
    search_param: '',
    campaign_type: 0,
  },
  sortColumns = [
    'campaignId',
    'campaignName',
    'account',
    'advertiser',
    'startDate',
    'endDate',
    'impressionCount',
  ],
  campaignDropdownOptions = [
    ...camapignOptionList,
    { id: 2, name: 'Inactive Campaigns' },
  ],
  excelHeaders = {
    campaign_id: 'Campaign Id',
    campaign_name: 'Camapign Name',
    account: 'Account',
    advertiser: 'Advertiser',
    start_date: 'Start Date',
    end_date: 'End Date',
    imp_count: 'Impressions',
    status: 'Status',
  },
  sortColumnObj = {
    campaignId: 'campaign_id',
    campaignName: 'campaign_name',
    account: 'account',
    advertiser: 'advertiser',
    startDate: 'start_date',
    endDate: 'end_date',
    impressionCount: 'imp_count',
  };

const CampaignLookupComponent = ({ role }) => {
  document.title = 'Admin | Campaign Lookup';

  const [isMounted, setIsMounted] = useState(false),
    [params, setParams] = useState(null);

  const [usableDataWithPagination, setUsableDataWithPagination] = useState([]), // For populating table
    [usableDataWitouthPagination, setUsableDataWithoutPagination] = useState(
      []
    ), // For populating Excel file
    [usableDataCount, setUsableDataCount] = useState(0),
    [isLoadingUsable, setIsLoadingUsable] = useState(true);

  const [inputParams, setInputParams] = useState(null),
    [searchParam, setSearchParam] = useState(null),
    [searchInput, setSearchInput] = useState(null),
    [selectedCampaignOption, setSelectedCampaignOption] = useState(
      campaignDropdownOptions[0]
    ),
    [selectedTableSize, setSelectedTableSize] = useState(Number(localStorage.getItem('campaignLookupPageSize')) || 10);

  // States for fetching Campaign Lookup
  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.admin + '/campaign-lookup'
  );

  /**
   * First time to initialize api calls and set up data
   * @dependencies : page-number, sort-data, per-page-data
   */
  useEffect(() => {
    setParams({ role });
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (data?.length) {
      // calculation for find usable data and data count
      getFormattedData(
        data,
        setUsableDataCount,
        setUsableDataWithPagination,
        setUsableDataWithoutPagination,
        inputParams
      );
    } else {
      setUsableDataCount(0);
      setUsableDataWithoutPagination([]);
      setUsableDataWithPagination([]);
    }
  }, [data, inputParams]);

  // for search filter
  useEffect(() => {
    if (searchParam !== null) {
      setInputParams({
        ...inputParams,
        page_number: baseInputParams.page_number,
        search_param: searchParam,
        page_size: selectedTableSize
      });
      // setIsSearched(true);
    }
  }, [searchParam]);

  useEffect(() => {
    const trimmedSearchParam = searchInput?.trim();
    if (
      !(
        searchInput === null ||
        searchInput === undefined ||
        trimmedSearchParam === searchParam ||
        (!searchParam && !trimmedSearchParam)
      )
    ) {
      setSearchParam(trimmedSearchParam);
    }
  }, [searchInput]);

  useEffect(() => {
    if (!inputParams) {
      setInputParams({
        ...baseInputParams, page_size: selectedTableSize
      });
    } else {
      setInputParams({
        ...inputParams,
        page_number: baseInputParams.page_number,
        search_param: '',
        campaign_type: selectedCampaignOption.id,
        page_size: selectedTableSize
      });
      setSearchParam(null);
      setSearchInput(null);
    }
  }, [selectedCampaignOption]);

  useEffect(() => {
    if (isLoading) {
      return setIsLoadingUsable(true);
    }

    setTimeout(() => {
      setIsLoadingUsable(false);
    }, 150);

    if (data?.length) {
      setTimeout(() => {
        const searchElem = document.querySelector(
          '.campaignLookupWrapper .campaignLookupSubheaderWrapper input'
        );
        searchElem.focus();
      }, 200);
    }
  }, [isLoading]);

const handlePageSizeChange = (pageSize) => {
    localStorage.setItem('campaignLookupPageSize', pageSize.toString());
    setSelectedTableSize(pageSize);
  };

  useEffect(() => {
    const previousSelection = Number(localStorage.getItem('campaignLookupPageSize') ?? 10);
    setSelectedTableSize(previousSelection);
  }, []);

  /** render component on the basis of the API request, if error then error component else table   */
  const renderElementHTML = () => {
    return isError ? (
      <ErrorComponent refreshAction={() => setParams({ ...params })} />
    ) : (
      <div>
        <div className=' campaignLookupSubheaderWrapper'>
          <div className='d-flex'>
            <CommonSearch
              placeholder='Search through Campaign ID, Name, Account, or Advertiser'
              searchInput={searchInput ?? ''}
              setSearchInput={setSearchInput}
              customStyle={
                searchInput?.length > 0 ? { fontStyle: 'normal' } : {}
              }
              disabled={
                (searchParam === null && usableDataCount <= 0) ||
                isLoadingUsable ||
                isError
              }
              uniqueIdentifier='allAccounts'
            />
          </div>
        </div>
        <CommonTablesComponent
          uniqueId='campaignLookup'
          data={usableDataWithPagination ?? []}
          columns={campaignLookupColumn}
          isLoading={isLoadingUsable}
          customTableHeader='Accounts'
          setSortData={(sortData) =>
            setInputParams({
              ...inputParams,
              sort_column: sortData?.sortField,
              sort_order: sortData?.order,
            })
          }
          sortColumnIds={sortColumns}
          defaultSortingApplied={{
            column: baseInputParams.sort_column,
            order: baseInputParams.sort_order,
          }}
          isPaginationApplied={true}
          totalDataCount={usableDataCount ?? 0}
          pageIndex={inputParams?.page_number}
          setPageIndex={(pageNumber) =>
            setInputParams({ ...inputParams, page_number: pageNumber })
          }
          dataPerPage={inputParams?.page_size}
          paginationOptions={{
            noRowsPerPage: false,
          }}
          setDataPerPage={(pageSize) =>{
            handlePageSizeChange(pageSize);
            setInputParams({
              ...inputParams,
              page_number: baseInputParams.page_number,
              page_size: pageSize,
            })
          }}
          noDataMessage='No Campaign Information Available'
          noDataCompWidth='200'
          noDataCompHeight='200'
          noDataMessageFontSize={15}
          foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
        />
      </div>
    );
  };

  return (
    <div className='campaignLookupWrapper'>
      <div className='campaignLookupHeaderWrapper'>
        <CommonHeadingComponent
          headingLabel='Campaign Lookup'
          headingCTALabel={
            <RenderHeaderRightElement
              usableDataCount={usableDataCount}
              usableDataWitouthPagination={usableDataWitouthPagination}
              isError={isError}
              isLoading={isLoadingUsable}
              searchParam={searchParam}
              excelHeaders={excelHeaders}
              campaignDropdownOptions={campaignDropdownOptions}
              selectedCampaignOption={selectedCampaignOption}
              setSelectedCampaignOption={setSelectedCampaignOption}
            />
          }
          ifDropdown={true}
        />
      </div>
      {renderElementHTML()}
    </div>
  );
};

export default CampaignLookupComponent;

const RenderHeaderRightElement = ({
  usableDataCount,
  usableDataWitouthPagination,
  isError,
  isLoading,
  searchParam,
  excelHeaders,
  selectedCampaignOption,
  campaignDropdownOptions,
  setSelectedCampaignOption,
}) => (
  <div className='campaignLookupHeaderRightContentWrapper'>
    <SearchableDropdownComponent
      options={campaignDropdownOptions}
      label='name'
      id='id'
      dropdownId='campaignLookupTypeSelection'
      dropdownLabel=''
      labelWidth={{ margin: 0 }}
      dropdownWidth={{
        width: '180px',
      }}
      wrapperClass=''
      placeholderLabel='Select Campaign Type'
      selectedValue={selectedCampaignOption}
      handleChange={setSelectedCampaignOption}
      isSearchable={false}
      isDisabled={
        (searchParam === null &&
          usableDataCount <= 0 &&
          !selectedCampaignOption?.id) ||
        isLoading ||
        isError
      }
      uniqueIdentifier='campaignLookupTypeSelections'
    />
    <div
      className={`downloadTagsWrapper${
        isLoading ? ' downloadTagsWrapperLoading' : ''
      }`}
    >
      <CommonButtonComponent
        label={checkCondition(
          isLoading,
          <LoaderComponent importedClassNames='downloadFilesButtonLoader' />,
          'Export to Excel'
        )}
        appendWrapperClassName='downloadTagsBtnWrapper'
        appendButtonClassName='downloadTagsBtnBody'
        onClickCTA={() =>
          exportCSV(
            usableDataWitouthPagination,
            'Campaign Lookup_' + getCurrentESTDate('MM-DD-YYYY'),
            'campaignLookupAllCampaigns',
            excelHeaders
          )
        }
        isDisabled={usableDataCount <= 0 || isLoading || isError}
        buttonUI='campaignLookupAllCampaigns'
      />
    </div>
  </div>
);

const getFormattedData = (
  data,
  setUsableDataCount,
  setUsableDataWithPagination,
  setUsableDataWithoutPagination,
  inputParams
) => {
  const {
    sort_column,
    sort_order,
    page_number,
    page_size,
    search_param,
    campaign_type,
  } = inputParams;

  // data formation after selecting campaign type (All, Active, ot Inactive)
  const formattedDataAfterSelectingCampaignType =
    getDataFormationForSelectedCampaignType(data, campaign_type);

  // data formation after Searching
  const formattedDataAfterSearching = getSearchedResult(
    formattedDataAfterSelectingCampaignType,
    search_param
  );

  // data formation after Sorting
  const formattedDataAfterSorting = getSortedResult(
    formattedDataAfterSearching,
    sort_order,
    sort_column
  );

  // data formation after Pagination
  const maxLimit = page_number * page_size - 1,
    minLimit = maxLimit + 1 - page_size,
    formattedDataAfterPagination = getPaginatedResult(
      formattedDataAfterSorting,
      maxLimit,
      minLimit
    );

  setUsableDataCount(formattedDataAfterSearching?.length);
  setUsableDataWithPagination(formattedDataAfterPagination);
  setUsableDataWithoutPagination(formattedDataAfterSearching);
};

// Logics for implementing Campaign type selection
const getDataFormationForSelectedCampaignType = (data, campaignType) => {
  if (campaignType === 1) {
    return data.filter((elem) => elem.status === 'Active');
  }

  if (campaignType === 2) {
    return data.filter((elem) => elem.status === 'Inactive');
  }

  return data;
};

// Logics For implementing Searching
const getSearchedResult = (data, sortParam) => {
  return data.filter(
    (elem) =>
      elem.campaign_id.toString().includes(sortParam) ||
      elem.campaign_name.toLowerCase().includes(sortParam.toLowerCase()) ||
      elem.account.toLowerCase().includes(sortParam.toLowerCase()) ||
      elem.advertiser.toLowerCase().includes(sortParam.toLowerCase())
  );
};

// Logics For implementing Sorting
const getSortedResult = (data, sortOrder, sortColumn) => {
  let overallSortedCampaignInfo = [];
  // For strings
  if (['campaignName', 'account', 'advertiser'].includes(sortColumn)) {
    overallSortedCampaignInfo = data.sort((a, b) => {
      if (
        a[sortColumnObj[sortColumn]].toUpperCase() <
        b[sortColumnObj[sortColumn]].toUpperCase()
      ) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (
        a[sortColumnObj[sortColumn]].toUpperCase() >
        b[sortColumnObj[sortColumn]].toUpperCase()
      ) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  // For numbers
  if (['campaignId', 'impressionCount'].includes(sortColumn)) {
    overallSortedCampaignInfo = data.sort((a, b) => {
      return sortOrder === 'asc'
        ? a[sortColumnObj[sortColumn]] - b[sortColumnObj[sortColumn]]
        : b[sortColumnObj[sortColumn]] - a[sortColumnObj[sortColumn]];
    });
  }

  // For dates
  if (['startDate', 'endDate'].includes(sortColumn)) {
    overallSortedCampaignInfo = data.sort((a, b) => {
      const sortA = a[sortColumnObj[sortColumn]]
          ? new Date(a[sortColumnObj[sortColumn]])
          : 0,
        sortB = b[sortColumnObj[sortColumn]]
          ? new Date(b[sortColumnObj[sortColumn]])
          : 0;
      return sortOrder === 'asc' ? sortA - sortB : sortB - sortA;
    });
  }

  return overallSortedCampaignInfo;
};

// Logics for implementing Pagination
const getPaginatedResult = (data, maxLimit, minLimit) => {
  const overallSortedLACampaignInfo = [];

  for (let i = minLimit; i <= maxLimit; i++) {
    if (data.length <= i) {
      break;
    }
    overallSortedLACampaignInfo.push(data[i]);
  }

  return overallSortedLACampaignInfo;
};
