import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommonHeadingComponent,
  CommonSearch,
  CommonTablesComponent,
  ErrorComponent,
} from '../../../../components';
import { baseEndpoints } from '../../../../constants';
import { removeTableClass, setAdvertiserId } from '../../../../utils/functions';
import { latestReportsTableColumns } from './latestReports.utils';
import ScheduledReportEditComponent from '../scheduledReportEdit/ScheduledReportEdit.component';
import { useGetApiInterceptor } from '../../../../hooks/useGetApiInterceptor';

import './latestReports.css';

const baseInputParams = {
    search_param: '',
    sort_field: 'run_date',
    page_size: 10,
    sort_order: 'DESC',
    page_number: 1,
  },
  sortColumns = [
    'campaign_name',
    'report_id',
    'report_name',
    'report_type',
    'run_date',
    'advertiser_name',
  ];

const LatestReports = forwardRef((props, ref) => {
  // Fetching client and advertiser information from redux store
  const client = useSelector((state) => state.client),
    advertiser = useSelector((state) => state.advertiser),
    role = useSelector((state) => state.user.role);

  const dispatch = useDispatch();

  const [columns, setColumns] = useState([]);

  const [params, setParams] = useState(null),
    [isMounted, setIsMounted] = useState(false),
    [inputParams, setInputParams] = useState(null),
    [searchInput, setSearchInput] = useState(''),
    [isClientOrAdvertiserUpdated, setIsClientOrAdvertiserUpdated] =
      useState(true),
    [isCountAtClientOrAdvLevel, setIsCountAtClientOrAdvLevel] = useState(false),
    [rowCountAtClientOrAdvLevel, setRowCountAtClientOrAdvLevel] = useState(0),
    [showEditModal, setShowEditModal] = useState(false),
    [selectedReportDetails, setSelectedReportDetails] = useState(null),
    [tableData, setTableData] = useState([]),
    [rowNumber, setRowNumber] = useState(null),
    [selectedTableSize, setSelectedTableSize] = useState(Number(localStorage.getItem('reportsPageSize')) || 10);

  useImperativeHandle(ref, () => ({
    runLatestReport() {
      setParams({ ...params });
    },
  }));

  const { data, isLoading, isError, status } = useGetApiInterceptor(
    isMounted,
    params,
    baseEndpoints.reports
  );
  const advertiserId = setAdvertiserId(advertiser.selectedAdvertiser, advertiser.selectedClientsAdvertiser, role);
  useEffect(() => {
    if (inputParams) {
      setParams({
        timeZone: null,
        user_id: client.selectedClient === -1 ? null : client.selectedClient,
        advertiser_id: advertiserId,
        ...inputParams,
      });
      setIsMounted(true);
    }
  }, [inputParams]);

  useEffect(() => {
    setIsClientOrAdvertiserUpdated(true);
    setIsCountAtClientOrAdvLevel(true);
    setSearchInput('');
    setInputParams(
      !inputParams
        ? { ...baseInputParams, page_size: selectedTableSize}
        : {
            ...baseInputParams,
            sort_field: inputParams.sort_field,
            sort_order: inputParams.sort_order,
            page_size: selectedTableSize,
          }
    );
  }, [client, advertiser]);

  useEffect(() => {
    if (isCountAtClientOrAdvLevel) {
      setRowCountAtClientOrAdvLevel(data?.data_count ?? 0);
      setIsCountAtClientOrAdvLevel(false);
    }

    if (data?.data_count) {
      removeTableClass('.latestReportsWrapper');
    }
  }, [data]);

  useEffect(() => {
    if (isClientOrAdvertiserUpdated) {
      return setIsClientOrAdvertiserUpdated(false);
    }

    const timer = setTimeout(() => {
      setInputParams({
        ...inputParams,
        page_number: baseInputParams.page_number,
        search_param: searchInput,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  useEffect(() => {
    if (status === 200) {
      setColumns(
        latestReportsTableColumns(setShowEditModal, setSelectedReportDetails,setRowNumber, dispatch, role )
      );
      removeTableClass('.activeCampaignsWrapper');
    }
  }, [status]);

  useEffect(()=>{
    if(data?.table_data?.length>0){
      let temp = []
      data.table_data.map((item)=>{
        temp.push({...item, loading: false})
      })
      setTableData(temp)
    } else{
      setTableData([])
    }
  },[data?.table_data])

  useEffect(() => {
    let temp = []
      tableData?.forEach((item)=>{
        if(item?.report_id === rowNumber?.report_id){
          temp.push(rowNumber)
        }else{
          temp.push(item)
        }
        setTableData(temp)
      })
  }, [rowNumber])

  const refreshAllReports = () => {
    setIsClientOrAdvertiserUpdated(true);
    setSearchInput('');
    setInputParams({ ...baseInputParams, page_size: selectedTableSize });
  };

  const handlePageSizeChange = (pageSize) => {
    localStorage.setItem('reportsPageSize', pageSize.toString());
    setSelectedTableSize(pageSize);
  };

  useEffect(() => {
    const previousSelection = Number(localStorage.getItem('reportsPageSize') ?? 10);
    setSelectedTableSize(previousSelection);
  }, []);

  return (
    <div className='latestReportsWrapper'>
      <CommonHeadingComponent
        headingLabel={<CustomHeading refreshAllReports={refreshAllReports} />}
        url={null}
        isDisabled={true}
      />
      <CommonSearch
        placeholder={'Search through report id, name, or campaign name'}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        disabled={!rowCountAtClientOrAdvLevel || isLoading || isError}
        uniqueIdentifier='allCampaigns'
      />
      <RenderComponent
        isError={isError}
        setParams={setParams}
        params={params}
        data={data}
        columns={columns}
        isLoading={isLoading}
        inputParams={inputParams}
        setInputParams={setInputParams}
        sortColumns={sortColumns}
        baseInputParams={baseInputParams}
        tableData={tableData}
        tableSizeChange={handlePageSizeChange}
      />
      {showEditModal && (
        <ScheduledReportEditComponent
          isModalOpen={showEditModal}
          setIsModalOpen={setShowEditModal}
          selectedReportDetails={selectedReportDetails}
          onSaveSuccesss={refreshAllReports}
        />
      )}
    </div>
  );
});

export default LatestReports;

const RenderComponent = ({
  isError,
  setParams,
  params,
  data,
  columns,
  isLoading,
  inputParams,
  setInputParams,
  sortColumns,
  baseInputParams,
  tableData,
  tableSizeChange
}) => {
  if (isError) {
    return <ErrorComponent refreshAction={() => setParams({ ...params })} />;
  }

  return (
    <CommonTablesComponent
      uniqueId='latestReports'
      data={tableData}
      columns={columns}
      isLoading={isLoading}
      customTableHeader='Reports'
      isPaginationApplied={true}
      paginationOptions={{ noRowsPerPage: false }}
      totalDataCount={data?.data_count ?? 0}
      dataPerPage={inputParams?.page_size}
      setDataPerPage={(pageSize) => {
        tableSizeChange(pageSize);
        setInputParams({
          ...inputParams,
          page_number:baseInputParams.page_number,
          page_size: pageSize
        })
      }}
      pageIndex={inputParams?.page_number}
      setPageIndex={(page_number) =>
        setInputParams({ ...inputParams, page_number })
      }
      setSortData={(sortData) =>
        setInputParams({
          ...inputParams,
          sort_field: sortData?.sortField,
          sort_order: sortData?.order?.toUpperCase(),
        })
      }
      sortColumnIds={sortColumns}
      defaultSortingApplied={{
        column: baseInputParams.sort_field,
        order: baseInputParams.sort_order.toLowerCase(),
      }}
      noDataMessage='No Reports Available'
      noDataMessageFontSize={15}
      noDataCompWidth='180'
      noDataCompHeight='180'
      foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
      fromReportsPage={true}
    />
  );
};

const CustomHeading = ({ refreshAllReports }) => (
  <div className='allReportsHeadingLabel'>
    <div>Reports</div>
    {/** Refresh CTA */}
    <div className='refreshCTAIcon' onClick={refreshAllReports}></div>
  </div>
);
