import React, { useState } from 'react';
import './charts.css';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import { Container, Row, Col } from 'react-bootstrap';
import NoDataComponent from '../commonTable/NoData.component';
import { approximateNumberToMils } from '../../utils/functions';

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
  } = props;

  return (
    <g>
      <text x={cx} y={cy - 8} dy={8} textAnchor='middle' fill={'000000'}>
        {approximateNumberToMils(value)}
      </text>
      <text x={cx} y={cy + 8} dy={8} textAnchor='middle' fill={'000000'}>
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

export default function CommonPieChart({
  data,
  colors,
  label = null,
  isLoading,
  tooltip = false,
}) {
  const [state, setState] = useState({ activeIndex: -1 });
  const onPieEnter = (_, index) => {
    setState({
      activeIndex: index,
    });
  };
  const onPieLeave = (_, index) => {
    setState({
      activeIndex: -1,
    });
  };

  if (!data?.length) {
    return (
      <div style={{ flex: 1 }}>
        <div className='chart container-fluid'>
          {Boolean(label) && (
            <div className='row'>
              <div className='chart_caption'>{label}</div>
            </div>
          )}
          <div className='row'>
            <NoDataComponent type='donut' />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Container fluid className='chart'>
        {Boolean(label) && (
          <Row>
            <div className='chart_caption'>{label}</div>
          </Row>
        )}
        <Row>
          <Col lg={12} md={12} sm={12} className='chart_block'>
            <PieChart width={200} height={200}>
              <Pie
                activeIndex={state.activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx='50%'
                cy='50%'
                innerRadius={50}
                outerRadius={80}
                fill={colors}
                progressPending={isLoading}
                dataKey='value'
                onMouseEnter={onPieEnter}
                onMouseLeave={onPieLeave}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${entry}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
          </Col>
        </Row>
        <Row>
          {data?.map(function (item, i) {
            return (
              item.value > 0 && (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  key={'data_item-' + item?.name}
                  className='data_item'
                >
                  <div
                    style={{
                      backgroundColor: colors[i],
                    }}
                    className='data_item_color_code'
                  ></div>
                  {item.name.length > 20 && tooltip ? (
                    <span
                      onMouseEnter={() => onPieEnter(null, i)}
                      onMouseLeave={() => onPieEnter(null, -1)}
                      style={{
                        fontWeight: i === state.activeIndex ? 'bold' : 'normal',
                        color: i === state.activeIndex ? colors[i] : 'black',
                        cursor: 'default',
                      }}
                      title={item.name}
                    >
                      {item.name.substr(0, 17) + '...'}
                    </span>
                  ) : (
                    <span
                      onMouseEnter={() => onPieEnter(null, i)}
                      onMouseLeave={() => onPieEnter(null, -1)}
                      style={{
                        fontWeight: i === state.activeIndex ? 'bold' : 'normal',
                        color: i === state.activeIndex ? colors[i] : 'black',
                        cursor: 'default',
                      }}
                    >
                      {item.name}
                    </span>
                  )}
                </Col>
              )
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
