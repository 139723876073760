import { useState, useEffect } from 'react'
import axios from '../services/axios/axios'

/**
 * Fetch list of Advertisers
 * @param {AbortController} signal
 * @param {Object} params pageSize, pageNumber, sortField, sortOrder
 * @returns Promise
 */
const useGetApiInterceptor = (isMounted, params, APIEndpoint) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [status, setStatus] = useState(200) // status state, will be used in Error Component
  const [dataCount, setDataCount] = useState(0)

  useEffect(() => {
    if (isMounted) {
      const controller = new AbortController()
      const signal = controller.signal

      const fetchData = () => {
        setIsLoading(true)
        setIsError(false)
        try {
          axios()
            .get(APIEndpoint, { signal, params: { ...params } })
            .then(
              (res) => {
                if (res.status === 200) {
                  console.info('Request Completed !! ', APIEndpoint)

                  const data = res.data
                  setStatus(res.status)
                  setData(data)
                  setDataCount(res?.data_count ?? 0)
                } else {
                  console.error('Request is not 200')
                  setIsError(true)
                }
                setIsLoading(false)
              },
              (err) => {
                if (signal.aborted) {
                  console.info('Request was cancelled.')
                } else {
                  console.error(err)
                  setIsError(true)
                  setStatus(499)
                  setIsLoading(false)
                }
              }
            )
          console.info('Request Initiated !! ', APIEndpoint)
        } catch (error) {
          console.error(error)
          setIsError(true)
          setStatus(500)
          setIsLoading(false)
        }
      }

      fetchData()

      return () => {
        controller.abort()
      }
    }
  }, [params, APIEndpoint, isMounted])

  return { data, isLoading, isError, status, dataCount }
}

export { useGetApiInterceptor }
