import { Store } from "react-notifications-component";

// Common method to show toast notification
export const commonNotification = ({msg, type}) => {
    if(!msg){
        switch(type){
            case "danger":
                msg = "Something went wrong";
                break;
            case "success":
                msg = "success";
                break;
            default:
                msg = "Default message"
        }
    }

    Store.addNotification({
        message: msg,
        type: type,
        insert: "center",
        container: "center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { showIcon: true, duration: 2000 },
    });
}