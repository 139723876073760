import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import LoaderComponent from '../loaderComponent/Loader.component';
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.css';

const DatePickerComponent = ({
  datePickerId,
  label,
  placeholderLabel = '',
  labelWidth,
  datePickerBodyWidth,
  minDate = null,
  maxDate = null,
  className = 'inputField inputCalendar cursorPointer',
  format = 'MM/DD/YYYY',
  isLoading = false,
  isDisabled = false,
  // for date picker
  startOrEndDate = null,
  setStartOrEndDate,
  // for date-range picker
  isDateRangePicker = false,
  dateRange = [null, null],
  setDateRange,
  currentYear = new Date().getUTCFullYear(),
  startYear = new Date().getUTCFullYear() - 19,
  futureYear = new Date().getUTCFullYear() + 5,
  isMandatory = false,
  needYearSelector = false,
  futureYearNeeded = false,
  hideCalendarStyle = false,
  onChangeCallback,
  uniqueIdentifier,
}) => {
  const dateInput = useRef(null),
    dateRangeInput = useRef(null),
    inputRef = useRef(null),
    dateInputWrapper = useRef(null),
    [isDatePickerOpen, setIsDatePickerOpen] = useState(false),
    [startDate, endDate] = dateRange;
  
  const h2Class = isDisabled ? 'h2Disabled' : 'h2Enabled';
  const years = (
      currentYear > startYear
        ? Array(currentYear - startYear)
        : Array(startYear - currentYear)
    )
      .fill('')
      .map((v, idx) =>
        currentYear > startYear ? currentYear - idx : currentYear + idx
      ),
    months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

  const futureYears = Array(futureYear - startYear)
    .fill('')
    .map((_v, idx) => futureYear - idx);

  useEffect(() => {
    document.addEventListener('click', toggle);
    return () => document.removeEventListener('click', toggle);
  }, []);

  const renderDate = (date) => {
    if (date) {
      if (!isDateRangePicker) {
        return moment.utc(date).format(format);
      }
      let customDateRange = '';
      date.forEach((date, index) => {
        if (date) {
          if (index === 1) customDateRange = `${customDateRange} - `;
          customDateRange = `${customDateRange}${moment(date).format(format)}`;
        }
      });
      return customDateRange;
    }
    return '';
  };

  const toggle = (e) => {
    if (
      e &&
      e.target !== inputRef.current &&
      dateInputWrapper.current.innerHTML.indexOf(e.target.className) <= 0
    ) {
      setIsDatePickerOpen(false);
    }
  };

  const getDate = (date) => {
    return date ? new Date(moment(date).toString()) : null;
  };

  const yearOption = () => {
    return futureYearNeeded
      ? futureYears.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))
      : years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ));
  };

  const datePickerWithYear = () => {
    return !needYearSelector ? (
      <DatePicker
        className='customReactDatePicker'
        ref={dateInput}
        selected={getDate(startOrEndDate)}
        minDate={getDate(minDate)}
        maxDate={getDate(maxDate)}
        onChange={(date) => {
          const formattedDate = moment(date, 'YYYY-MM-DD').isValid() 
          ? moment(date).format('YYYY-MM-DD') 
          : null;

          if(onChangeCallback && formattedDate){
            onChangeCallback(moment(date).format('YYYY-MM-DD'))
          }
          if(formattedDate){
            setStartOrEndDate(moment(date).format('YYYY-MM-DD'));
          }
        }}
        placeholderText={placeholderLabel}
        readOnly={isDisabled}
      />
    ) : (
      <DatePicker
        className='customReactDatePicker'
        ref={dateInput}
        selected={getDate(startOrEndDate)}
        minDate={getDate(minDate)}
        maxDate={getDate(maxDate)}
        placeholderText={placeholderLabel}
        readOnly={isDisabled}
        onChange={(date) => {
          if(moment(date, 'MM/DD/YYYY', true).isValid()){
            setStartOrEndDate(moment(date).format('YYYY-MM-DD'));
          } 
        }}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <RenderCustomHeader
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            yearOption={yearOption}
            months={months}
          />
        )}
      />
    );
  };

  return (
    <div id={datePickerId} className='datePickerWrapper'>
      <div className='dropdownLabel' style={labelWidth}>
        <span>
          {label}
          {isMandatory && <span className='madatory-icon'>*</span>}
        </span>
      </div>
      <div className='datePickerBody' style={datePickerBodyWidth}>
        <div
          ref={dateInputWrapper}
          className='calendarDisable'
          style={hideCalendarStyle ? datePickerBodyWidth : null}
        >
          {!isDateRangePicker ? (
            datePickerWithYear()
          ) : (
            <div id="dateRangeContainer">
              <DatePicker
                className={`${className} customReactDatePicker ${isDisabled ? ' calendarDisable' : ''}`}
                id={uniqueIdentifier ? uniqueIdentifier + 'DateField' : undefined}
                ref={dateRangeInput}
                startDate={getDate(startDate)}
                endDate={getDate(endDate)}
                minDate={getDate(minDate)}
                maxDate={getDate(maxDate)}
                selected={getDate(startDate)}
                placeholderText={'Start Date'}
                readOnly={isDisabled}
                onClick={() => {
                  if (!isDatePickerOpen) {
                    setIsDatePickerOpen(true);
                    if (!isDateRangePicker) {
                      dateInput.current.setOpen(true);
                      return;
                    }
                    dateRangeInput.current.setOpen(true);
                  } else {
                    setIsDatePickerOpen(false);
                    if (!isDateRangePicker) {
                      dateInput.current.setOpen(false);
                      return;
                    }
                    dateRangeInput.current.setOpen(false);
                  }
                }}
                onChange={(date) => {
                  const formattedDate = moment(date, 'YYYY-MM-DD').isValid() 
                  ? moment(date).format('YYYY-MM-DD') 
                  : null;
                  if(formattedDate){
                    setDateRange([formattedDate, endDate]);
                  }
                }}
                renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <RenderCustomHeader
                  date={date}
                  changeYear={changeYear}
                  changeMonth={changeMonth}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                  yearOption={yearOption}
                  months={months}
                />
              )}
              />
              <h2 className={h2Class}>&nbsp;-&nbsp;</h2>
              <DatePicker
                className={`${className} customReactDatePicker ${isDisabled ? ' calendarDisable' : ''}`}
                id={uniqueIdentifier ? uniqueIdentifier + 'DateField' : undefined}
                ref={dateRangeInput}
                startDate={getDate(startDate)}
                endDate={getDate(endDate)}
                minDate={getDate(startDate)}
                maxDate={getDate(maxDate)}
                selected={getDate(endDate)}
                placeholderText={'End Date'}
                readOnly={isDisabled}
                onClick={() => {
                  if (!isDatePickerOpen) {
                    setIsDatePickerOpen(true);
                    if (!isDateRangePicker) {
                      dateInput.current.setOpen(true);
                      return;
                    }
                    dateRangeInput.current.setOpen(true);
                  } else {
                    setIsDatePickerOpen(false);
                    if (!isDateRangePicker) {
                      dateInput.current.setOpen(false);
                      return;
                    }
                    dateRangeInput.current.setOpen(false);
                  }
                }}
                onChange={(date) => {
                  const formattedDate = moment(date, 'YYYY-MM-DD').isValid() 
                  ? moment(date).format('YYYY-MM-DD') 
                  : null;
                  if(formattedDate){
                    setDateRange([startDate, formattedDate]);
                  }
                }}
                renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <RenderCustomHeader
                  date={date}
                  changeYear={changeYear}
                  changeMonth={changeMonth}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                  yearOption={yearOption}
                  months={months}
                />
              )}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading && (
        <div className='datePickerLoaderWrapper'>
          <LoaderComponent importedClassNames='dropdownsAndDates' />
        </div>
      )}
    </div>
  );
};

export default DatePickerComponent;

const RenderCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  yearOption,
  months,
}) => (
  <div className='customHeaderDatePicker'>
    <button
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
      className='react-datepicker__navigation react-datepicker__navigation--previous'
    >
      <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'>
        Previous Month
      </span>
    </button>
    <select
      value={date.getFullYear()}
      onChange={({ target: { value } }) => changeYear(value)}
      className='datePickerYearSelector'
    >
      {yearOption()}
    </select>

    <select
      value={months[date.getMonth()]}
      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <button
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
      className='react-datepicker__navigation react-datepicker__navigation--next'
    >
      <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--next'>
        Next Month
      </span>
    </button>
  </div>
);
