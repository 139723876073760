import React, { useState, useEffect, useRef } from "react";
import {
  CommonHeadingComponent,
  ErrorComponent,
  LoaderComponent,
  MultiselectDropdownComponent,
  NoDataComponent,
  CommonButtonComponent,
} from '../../../../../../components';
import { useGetApiInterceptor } from '../../../../../../hooks/useGetApiInterceptor';
import { baseEndpoints } from '../../../../../../constants';
import {
  formatNumberInThousands,
  formatUsDollars,
  dateFormat,
  setPlaceHolderLabel,
  checkSelectAll,
  checkCondition,
  exportCSV,
} from "../../../../../../utils/functions";
import "./style.css";

const determineSummaryHeaderLoading = (parametersIsLoading) => {
  const { campaign, mediaPartner } = parametersIsLoading;
  return campaign || mediaPartner;
};

const ComparisionTableLift = ({ 
  campaign,
  mediaPartner,
  mediaPartnerList,
  startDate,
  endDate,
  parametersIsLoading,
  reportName
}) => {

  const [tableHeader, setTableHeader] = useState([]);
  const [tableHeaderSet, setTableHeaderSet] = useState(false);
  const [partnerList, setPartnerList] = useState(null),
    [selectedPartner, setSelectedPartner] = useState(null);
  const [showPartnerDropdown, setShowPartnerDropdown] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [params, setParams] = useState(null);
  const [endpoint, setEndpoint] = useState(null);
  const [goalsCollapsed, setGoalsCollapsed] = useState({});
  const [renderIndex, setRenderIndex] = useState(1);

  const partnerListRef = useRef(null)

  const { data, isLoading, isError } = useGetApiInterceptor(
    isMounted,
    params,
    endpoint
  );

  useEffect(() => {
    if (campaign) {
      setParams({
        campaign_id: campaign?.campaign_id,
        from: dateFormat(startDate, 'YYYY-MM-DD'),
        to: dateFormat(endDate, 'YYYY-MM-DD'),
      });
      setEndpoint(`${baseEndpoints.reports}/lift-tab/comparison`);
      setIsMounted(true);
    }
  }, [campaign, startDate, endDate]);

  useEffect(() => {
    if (mediaPartner?.length) {
      setPartnerList(mediaPartner);
      setSelectedPartner(mediaPartner);
    } else {
      setPartnerList(null);
      setSelectedPartner(null);
    }
    partnerListRef.current = mediaPartner?.length ? [...mediaPartner] : null;
  }, [mediaPartner]);

  useEffect(() => {
    if (data?.goalsComparison) {
      const tmp = data.goalsComparison.data.reduce((acc, item) => {
        acc[item.id] = true;
        return acc;
      }, {});
      setGoalsCollapsed(tmp);
    }
  }, [data]);

  const toggleRootExpandCollapse = (rootId) => {
    const tmp = goalsCollapsed;
    tmp[rootId] = !tmp[rootId];
    setGoalsCollapsed(tmp);
    setRenderIndex(renderIndex + 1);
  };

  const RenderRow = (dataElement, header, level, parentKey, rootId) => {
    const key = parentKey + "_" + dataElement.id + "_" + level;
    if (!tableHeaderSet) {
      setTableHeaderSet(true);
      setTableHeader(header);
    }
    return (
      <>
        <tr className="cp_tree-table_row">
          {header.map((headerCol) => {
            return (
              <td
                onClick={
                  level == 0 && headerCol.data_key === 'name'
                    ? () => toggleRootExpandCollapse(dataElement.id)
                    : () => {}
                }
                key={key + headerCol.data_key}
                className={
                  headerCol.format === 'currency' ||
                  headerCol.format === 'integer' ||
                  headerCol.format === 'decimal'
                    ? 'cp_tree-table_cell cp_tree-table_cell-numeric '
                    : headerCol.data_key === 'name'
                    ? 'cp_tree-table_cell cp_tree-table_cell-main'
                    : 'cp_tree-table_cell'
                }
                style={
                  headerCol.data_key === 'name'
                    ? {
                        paddingLeft: level + 'em',
                      }
                    : {
                        fontWeight:
                          dataElement.entity === 'goal' ? 'bold' : 'normal',
                      }
                }
              >
                {dataElement?.items?.length &&
                headerCol.data_key === 'name' &&
                level == 0 ? (
                  <span
                    className={
                      (renderIndex &&
                        !goalsCollapsed[rootId] &&
                        'show-down-arrow') ||
                      'show-right-arrow'
                    }
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-caret-right-fill'
                      viewBox='0 0 16 16'
                    >
                      <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                    </svg>
                  </span>
                ) : (
                  <></>
                )}

                {isNaN(dataElement[headerCol.data_key])
                  ? dataElement[headerCol.data_key]
                  : headerCol.format === 'currency' &&
                    typeof dataElement[headerCol.data_key] === 'number'
                  ? formatUsDollars(
                      (
                        Math.round(dataElement[headerCol.data_key] * 100) / 100
                      ).toFixed(2)
                    )
                  : headerCol.format === 'decimal'
                  ? (
                      Math.round(dataElement[headerCol.data_key] * 100) / 100
                    ).toFixed(2)
                  : headerCol.format === 'integer'
                  ? formatNumberInThousands(dataElement[headerCol.data_key])
                  : dataElement[headerCol.data_key]}
              </td>
            );
          })}
        </tr>
        {renderIndex && !goalsCollapsed[rootId] ? (
          dataElement?.items?.map((childElement) => {
            return RenderRow(childElement, header, level + 1, key, rootId);
          })
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
   <div className='comparisonTableWrapper lift'>
        
      <div className='rac-title'>
        <CommonHeadingComponent 
          headingLabel={'Goals comparison'}
          ifDropdown={true}
          headingCTALabel={
            <RenderHeaderRightElement
              campaign={campaign}
              partnerList={partnerList}
              setPartnerList={setPartnerList}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
              partnerListRef={partnerListRef}
              isLoading={isLoading}
              parametersIsLoading={parametersIsLoading}
              filteredData={data}
              tableHeader={tableHeader}
              reportName={reportName}
              startDate={startDate}
              endDate={endDate}
              setShowPartnerDropdown={setShowPartnerDropdown}
            />
          }
        />
        
      </div>
      <div className='rac-table-wrapper'>
        {data?.goalsComparison?.data &&
        data?.goalsComparison?.data.length > 0 ? (
          <table className='rac-tree-table'>
            <thead>
              <tr>
                {data?.goalsComparison?.headers.map((headerCol) => {
                  return (
                    <th
                      className={
                        headerCol.format === 'currency' ||
                        headerCol.format === 'integer' ||
                        headerCol.format === 'decimal'
                          ? 'cp_tree-table_header-cell cp_tree-table_header-cell-numeric'
                          : 'cp_tree-table_header-cell'
                      }
                    >
                      {headerCol.header_label?.toLowerCase()}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data?.goalsComparison?.data.map((dataElement) => {
                return RenderRow(
                  dataElement,
                  data?.goalsComparison?.headers,
                  0,
                  '',
                  dataElement.id
                );
              })}
            </tbody>
          </table>
        ) : isLoading ? (
          <LoaderComponent />
        ) : isError ? (
          <ErrorComponent />
        ) : (
          <NoDataComponent
            type='table'
            width='180'
            height='180'
            messageTextFontSize={15}
            foregnObj={{ x: '22', y: '90', width: '80%', height: '50' }}
            messageText='No Data Available'
          />
        )}
      </div>
    </div>
  );
};

export default ComparisionTableLift;

const RenderHeaderRightElement = ({
  campaign,
  partnerList,
  setPartnerList,
  selectedPartner,
  setSelectedPartner,
  partnerListRef,
  isLoading,
  parametersIsLoading,
  filteredData,
  tableHeader,
  reportName,
  startDate,
  endDate,
  setShowPartnerDropdown,
  showPartnerDropdown,
}) => {
  const formattedStartDate = dateFormat(startDate),
    formattedEndDate = dateFormat(endDate);

  return (
    <div className='headerRightElementWrapper'>
      <div className='downloadTagsWrapper'>
        <CommonButtonComponent
          label={checkCondition(
            isLoading || determineSummaryHeaderLoading(parametersIsLoading),
            <LoaderComponent importedClassNames='downloadFilesButtonLoader' />,
            'Export to Excel'
          )}
          appendWrapperClassName='downloadTagsBtnWrapper'
          appendButtonClassName='downloadTagsBtnBody'
          onClickCTA={() =>
            exportCSV(
              filteredData?.goalsComparison?.data,
              reportName +
                '-Comparison Table-' +
                formattedStartDate +
                '-' +
                formattedEndDate,
              'liftGoalsComparison',
              tableHeader
            )
          }
          isDisabled = { 
            !filteredData?.goalsComparison?.data?.length ||
            !partnerList?.length || 
            isLoading || 
            showPartnerDropdown || 
            determineSummaryHeaderLoading(parametersIsLoading)
          }
          buttonUI='attrPartnerComparison'
        />
      </div>
    </div>
  );
};