import React from 'react';
import {
  NoDataComponent,
  ErrorComponent,
  LoaderComponent,
} from '../../../../../../components';
import { approximateNumberToMils } from '../../../../../../utils/functions';
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts';
import { Container, Row, Col } from 'react-bootstrap';

import './impressionSection.css'

export default function CumulativeImpressionsReport({
  data,
  isLoading,
  isError,
  status,
  getImpressions,
}) {

  const checkLoadingComponent = () => {
    return isLoading ? <LoaderComponent /> : checkErrorComponent();
  };

  const checkErrorComponent = () => {
    const controller = new AbortController();
    return isError ? (
      <ErrorComponent status={status} refreshAction={() => getImpressions()} controller={controller} />
    ) : (
      renderBarGraph()
    );
  };

  const renderBarGraph = () => {
    return !data || data.length <= 0 ? (
      <>
        <NoDataComponent type='area'/>
      </>
    ) : (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={700}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis minTickGap={10} dataKey="name" />
          <YAxis
            tickCount={10}
            width={80}
            axisLine={false}
            domain={[0, (dataMax) => Math.ceil((dataMax * 1.5) / 100) * 100]}
            tickFormatter={approximateNumberToMils}
          />
          <Tooltip
            formatter={(value) => Intl.NumberFormat("en").format(value)}
          />
          <Area
            type="monotone"
            legendType="square"
            //   dot={{ stroke: "#03bdc9", strokeWidth: 3, radius: 6 }}
            activeDot={false}
            label={false}
            dataKey="Net Impressions"
            stackId="1"
            stroke="#03bdc9"
            strokeWidth={2}
            strokeOpacity={1}
            fill="#01bdc9"
          />
          <Area
            type="monotone"
            legendType="square"
            //   dot={{ stroke: "#ff7c1f", strokeWidth: 3 }}
            activeDot={false}
            label={false}
            dataKey="IVT"
            stackId="1"
            stroke="#ff7c1f"
            strokeWidth={1}
            fill="#ffefce"
          />
          <Legend height={36} />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className='cumulativeImpressionWrapper'>
      <Container fluid>
        <Row>
          <Col style={{ textAlign: 'center', marginTop: 2 + 'em' }}>
            <span>Cumulative Impressions</span>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} style={{ height: '20.5em' }}>
            {checkLoadingComponent()}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
